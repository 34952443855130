// Import react component
import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { Icon, Avatar } from 'antd'

// Load list of deliverables in deliverableList Component
class StageList extends React.Component {
	render() {
		// Deconstruct props
		const { data } = this.props
		return [
			data.length ? (
				data.map((value, index) => {
					// Stringify value object
					const result = JSON.stringify(value)
					// Deconstruct value object
					const { scopeName, deliverableLength } = value

					// unique scopeKey
					const scopeKey = `${(scopeName, index)}`

					return (
						<li key={scopeKey} data-id={result}>
							<div>
								<Avatar className="stageIcon" size="large">
									{scopeName[0].toUpperCase()}
								</Avatar>
								<span className="stageNameMarginLeft20">
									{scopeName[0].toUpperCase() + scopeName.slice(1)} - {deliverableLength}{' '}
									Deliverables
								</span>
							</div>
						</li>
					)
				})
			) : (
				// Handle empty step
				<li key={uniqueId()} style={{ backgroundColor: 'white' }}>
					<div>
						<Avatar size="large" className="emptyDeliverableList">
							<Icon type="exclamation-circle" className="emptyDeliverableListIcon" />
						</Avatar>
						<span className="stageNameMarginLeft20">No Step Found</span>
					</div>
				</li>
			),
		]
	}
}

// export deliverablelist component
export default StageList
