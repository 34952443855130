import React from 'react'
import { Table, Input, Popconfirm, Form, Button, notification, message } from 'antd'
import { withApollo } from 'react-apollo'
import {
	INSERT_DESIGN_PROCESS,
	GET_DESIGN_PROCESS,
	DELETE_DESIGN_PROCESS,
	UPLOAD_DESIGN_FILES,
} from './query'

const { TextArea } = Input

const key = 'updatable'

const EditableContext = React.createContext()

class EditableCell extends React.Component {
	getKeyInput = () => {
		return <Input placeholder={'Please Enter Key'} />
	}
	getTitleInput = () => {
		return <TextArea rows={2} placeholder={'Please Enter Title'} />
	}

	getTextArea = () => {
		return <TextArea rows={4} placeholder={'Please Enter Description'} />
	}

	renderCell = ({ getFieldDecorator }) => {
		const {
			editing,
			dataIndex,
			title,
			inputType,
			record,
			index,
			children,
			...restProps
		} = this.props
		return (
			<td {...restProps}>
				{editing ? (
					<Form.Item style={{ margin: 0 }}>
						{getFieldDecorator(dataIndex, {
							rules: [
								{
									required: true,
									message: `Please Input ${title}!`,
								},
							],
							initialValue: record[dataIndex],
						})(
							title === 'Description' || title === 'Title'
								? title === 'Description'
									? this.getTextArea()
									: this.getTitleInput()
								: this.getKeyInput()
						)}
					</Form.Item>
				) : (
					children
				)}
			</td>
		)
	}

	render() {
		return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
	}
}

class EditableTable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: this.props.processList,
			feeheadKey: this.props.feeheadKey,
			editingKey: '',
			selectedKey: '',
			uploading: false,
		}

		this.columns = [
			{
				title: 'Thumbnail',
				dataIndex: 'thumbnail',
				width: '18%',
				editable: false,
			},
			{
				title: 'Key',
				dataIndex: 'key',
				width: '10%',
				editable: true,
			},
			{
				title: 'Title',
				dataIndex: 'title',
				width: '15%',
				editable: true,
			},
			{
				title: 'Description',
				dataIndex: 'description',
				width: '25%',
				editable: true,
			},
			{
				title: 'Upload',
				dataIndex: 'upload',
				width: '10%',
				editable: false,
				render: (text, record) => {
					const { editingKey } = this.state
					const editable = this.isEditing(record)
					return (
						<span>
							<a onClick={() => this.uploadEdit(record.key)}>
								<Button
									style={{
										cursor: editable ? 'default' : 'not-allowed',
									}}
									className="uploadCardClass"
									onClick={() => this.imageUpload()}>
									<input
										onChange={this.getImage(record.key)}
										type="file"
										multiple
										accept="image/*,video/*"
										id="upload"
										ref={(ref) => (this.fileInput = ref)}
									/>
									Upload
								</Button>
							</a>
						</span>
					)
				},
			},
			{
				title: 'Operation',
				dataIndex: 'operation',
				width: '13%',
				render: (text, record) => {
					const { editingKey } = this.state
					const editable = this.isEditing(record)
					return editable ? (
						<span>
							<EditableContext.Consumer>
								{(form) => (
									<a
										onClick={() => this.save(form, record.key)}
										style={{ marginRight: 8 }}>
										Save
									</a>
								)}
							</EditableContext.Consumer>
							<Popconfirm
								title="Sure to cancel"
								onConfirm={() => this.cancel(record.key)}>
								<a style={{ marginRight: 8 }}>Cancel</a>
							</Popconfirm>
						</span>
					) : (
						<a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
							Edit
						</a>
					)
				},
			},
			{
				title: 'Action',
				dataIndex: 'action',
				width: '10%',
				editable: false,
				render: (text, record, form) => {
					return (
						<span>
							<Popconfirm
								title="Sure to delete"
								onConfirm={() => this.delete(form, record.key)}>
								<a style={{ marginRight: 8, color: 'red' }}>Delete</a>
							</Popconfirm>
						</span>
					)
				},
			},
		]
	}

	openNotification = (status, type) => {
		notification.open({
			message: status
				? type === 'insert'
					? 'Inserted Successfully...'
					: 'Updated Successfully...'
				: 'Something went wrong.Try again..',
			placement: 'bottomRight',
		})
	}

	isEditing = (record) => record.key === this.state.editingKey

	cancel = () => {
		this.setState({ editingKey: '' })
	}

	delete = async (form, key) => {
		const { data } = this.state
		const { client } = this.props

		await client.mutate({
			mutation: DELETE_DESIGN_PROCESS,
			variables: {
				processId: key,
			},
		})

		const newData = data.filter(function(obj) {
			return obj.key !== key
		})
		this.setState({ data: newData })
	}

	// image upload open image select option
	imageUpload = () => {
		document.getElementById('upload').click()
	}

	openMessage = (loading) => {
		if (loading) {
			message.loading({ content: 'Uploading...', key })
		} else {
			setTimeout(() => {
				message.success({ content: 'Uploaded!', key, duration: 2 })
			}, 1000)
		}
	}

	// selected image related data
	getImage = (key) => async (e) => {
		const { data, selectedKey, uploading } = this.state
		this.openMessage(uploading)

		if (e.target.files.length > 0) {
			const formData = e.target.files
			const { client } = this.props

			const result = await client.mutate({
				mutation: UPLOAD_DESIGN_FILES,
				variables: {
					files: formData,
				},
			})

			const { url } = result.data.uploadDesignFile

			if (url) {
				this.fileInput.value = ''

				const thumbnailType = url.split('.')[url.split('.').length - 1]

				const newData = data
				for (let index = 0; index < newData.length; index++) {
					const newKey = newData[index].key
					if (newKey === selectedKey) {
						newData[index].thumbnailUrl = url
						newData[index].thumbnail =
							thumbnailType === 'jpg' || thumbnailType === 'png' ? (
								<img
									src={url}
									width="50"
									height="50"
									style={{ marginLeft: '25%' }}
									alt={url}
								/>
							) : (
								<video
									src={url}
									width="100"
									height="100"
									controls="controls"
									autoplay="true"
								/>
							)
					}
				}
				console.log(newData)
				this.openMessage(false)
				this.setState({ data: newData, selectedKey: '', uploading: false })
			}
		}
	}

	save(form, key) {
		form.validateFields(async (error, row) => {
			if (error) {
				return
			}
			const newData = [...this.state.data]
			const index = newData.findIndex((item) => key === item.key)

			if (index > -1) {
				const item = newData[index]
				newData.splice(index, 1, {
					...item,
					...row,
				})

				const { client } = this.props

				const { thumbnailUrl, key, description, title } = newData[index]
				const result = await client.mutate({
					mutation: INSERT_DESIGN_PROCESS,
					variables: {
						processMedia: thumbnailUrl,
						processDescription: description,
						processName: title,
						processId: key,
					},
					awaitRefetchQueries: true,
					refetchQueries: [
						{
							query: GET_DESIGN_PROCESS,
						},
					],
				})

				const { status, type } = result.data.insertDesignProcess
				if (status) {
					this.openNotification(status, type)
				} else {
					this.openNotification(status, type)
				}

				this.setState({ data: newData, editingKey: '' })
			} else {
				newData.push(row)
				this.setState({ data: newData, editingKey: '' })
			}
		})
	}

	edit(key) {
		this.setState({ editingKey: key })
	}

	uploadEdit(key) {
		this.setState({ selectedKey: key, uploading: true })
	}

	handleAdd() {
		const { data, feeheadKey } = this.state

		const newData = {
			thumbnail: (
				<img
					src="https://pngimage.net/wp-content/uploads/2018/06/upload-image-icon-png-4.png"
					alt={'No Image'}
					width="50"
					height="50"
					style={{ marginLeft: '25%' }}
				/>
			),
			key:
				data.length > 0
					? feeheadKey + (parseInt(data[data.length - 1].key.substring(2)) + 1)
					: feeheadKey + `${data.length + 1}`,
			title: 'Title',
			description: 'Description',
			thumbnailUrl: '',
			upload: (
				<Button className="uploadCardClass" onClick={() => this.imageUpload()}>
					<input
						onChange={this.getImage(data.length + 1)}
						type="file"
						multiple
						accept="image/*,video/*"
						id="upload"
					/>
					Upload
				</Button>
			),
		}

		this.setState({
			data: [...data, newData],
		})
	}

	render() {
		const components = {
			body: {
				cell: EditableCell,
			},
		}

		const columns = this.columns.map((col) => {
			if (!col.editable) {
				return col
			}
			return {
				...col,
				onCell: (record) => ({
					record,
					inputType: 'text',
					dataIndex: col.dataIndex,
					title: col.title,
					editing: this.isEditing(record),
				}),
			}
		})

		return (
			<EditableContext.Provider value={this.props.form}>
				<Table
					components={components}
					bordered
					dataSource={this.state.data}
					columns={columns}
					pagination={false}
					rowClassName="editable-row"
				/>
				<Button
					onClick={() => this.handleAdd()}
					type="primary"
					style={{
						marginBottom: 16,
						marginTop: 10,
					}}>
					Add a row
				</Button>
			</EditableContext.Provider>
		)
	}
}

const EditableFormTable = Form.create()(EditableTable)

export default withApollo(EditableFormTable)
