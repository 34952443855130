/* eslint-disable no-nested-ternary */
/**
    CRM Parent Component - Entry Point Component for Enquiries in First Menu
    Child Components - CRM Modal, CRM Chat
    Functional Components - SearchEnquiryInput
    Users - Team Heads, Super Admins
* */

/**
    List of all enquiries in Kanban View
* */

import { Card, Col, Icon, Row, Spin, Tag, Tooltip, message, Typography } from 'antd' // Importing required components from ANTD
import { format, formatDistanceToNow } from 'date-fns' // Date FNS for time format functions
import update from 'immutability-helper' // Package to Mutate a copy of data without changing the original source
import PropTypes from 'prop-types'
import React from 'react'
import { Query } from 'react-apollo' // Query component of React Apollo to fetch data
import { FiPhone, FiPhoneIncoming, FiPhoneOff } from 'react-icons/fi'
import { FaPhone, FaRegCopy } from 'react-icons/fa'
import { GoGlobe } from 'react-icons/go'
import { MdUpdate, MdVerifiedUser, MdWatch, MdAttachFile } from 'react-icons/md'
import Board from 'react-trello/dist' // React Trello Board - Kanban View
import CrmModal from '../CrmModal'
import './index.css' // Override CSS + Custom Class CSS
import { CrmDetails } from './query' // Query to fetch CRM Details
import SearchEnquiryInput from './SearchEnquiryInput/index'
import styles from './styles' // Importing Custom Styles
import utils from '../../../utils'
import SomeErrorHasOccured from '../../UtilComponents'

const { truncateTime } = utils
/** ** GroupBy Function 
 Params - (object, key) 
 Input - Object, Output - Array
 Group objects based on a specific key and returns them as an array
*** */
const groupBy = (object, objectKey) => {
	return object.reduce((acc, obj) => {
		const key = obj[objectKey]
		if (!acc[key]) {
			acc[key] = []
		}
		acc[key].push(obj)
		return acc
	}, {})
}

/** ** Array Search Function
 Params - (array, valueToCheck) 
 Check the array for a given value
 if true returns array index of the given value in the array,
 else returns false
*** */
const arraySearch = (arr, val) => {
	// Check array find
	for (let i = 0; i < arr.length; i += 1) if (arr[i] === val) return i
	return false
}

class CrmBoard extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			boardData: [], // Holds the entire data array of all the lanes in the Kanban Board
			currentEnquiryId: null, // Enquiry ID of the currently active Enquiry Modal
			currentEnquiryLane: [], // Lane ID of the currently active Enquiry Modal
			currentEnquiryList: [], // Enquiry List Details of the currently active Enquiry Modal
			currentModalData: [], // Complete Emquiry Details of the currently active Enquiry Modal
			lazyLoadData: null, // Used to call the refetch of CrmDetails Query
			nextEnquiryId: null, // Enquiry ID of the next enquiry listing in Current Enquiry Lane
			onChangeRefetch: null,
			prevEnquiryId: null, // Enquiry ID of the previous enquiry listing in Current Enquiry Lane
			visible: false, // Visible state of the CRM Modal Component
			callFrom: '',
			lazyBlockers: {
				won: false,
				toContact: false,
				met: false,
				todayFollowup: false,
				fakeEnquiries: false,
				pastSevenDays: false,
				openedLastWeek: false,
				lose: false,
				inContact: false,
				proposalSent: false,
				yesterdayFollowup: false,
				notFeasible: false,
				pastOneMonth: false,
				openedLastMonth: false,
				meetingFixed: false,
				proposalSeen: false,
				pastFollowup: false,
				notResponsive: false,
				pastThreeMonths: false,
				openedLastQuarter: false,
				underNegotiation: false,
				futureFollowup: false,
				otherReasons: false,
				olderProposal: false,
				openedLongBack: false,
			},
		}
	}

	/* 
    Sets the visiblity of modal to false when called
  */
	closeModal = () => {
		this.setState({
			visible: false,
		})
	}

	/* 
    Custom Components for Kanban Board
  */

	laneBlockRetriever = (laneId) => {
		let laneToBlock
		switch (laneId) {
			case 'won':
				laneToBlock = 'won'
				break
			case 'to_contact':
				laneToBlock = 'toContact'
				break
			case 'met':
				laneToBlock = 'met'
				break
			case 'Today Followup':
				laneToBlock = 'todayFollowup'
				break
			case 'fake_enquiries':
				laneToBlock = 'fakeEnquiries'
				break
			case 'Past Seven Days':
				laneToBlock = 'pastSevenDays'
				break
			case 'Opened Today':
				laneToBlock = 'openedLastWeek'
				break
			case 'lose':
				laneToBlock = 'lose'
				break
			case 'in_contact':
				laneToBlock = 'inContact'
				break
			case 'proposal_sent':
				laneToBlock = 'proposalSent'
				break
			case 'Yesterday Followup':
				laneToBlock = 'yesterdayFollowup'
				break
			case 'not_feasible':
				laneToBlock = 'notFeasible'
				break
			case 'Past One Month':
				laneToBlock = 'pastOneMonth'
				break
			case 'Opened Three Days Back':
				laneToBlock = 'openedLastMonth'
				break
			case 'meeting_fixed':
				laneToBlock = 'meetingFixed'
				break
			case 'proposal_seen':
				laneToBlock = 'proposalSeen'
				break
			case 'Past Followup':
				laneToBlock = 'pastFollowup'
				break
			case 'not_responsive':
				laneToBlock = 'notResponsive'
				break
			case 'Past Three Months':
				laneToBlock = 'pastThreeMonths'
				break
			case 'Opened Last Week':
				laneToBlock = 'openedLastQuarter'
				break
			case 'under_negotiation':
				laneToBlock = 'underNegotiation'
				break
			case 'Future Followup':
				laneToBlock = 'futureFollowup'
				break
			case 'other_reasons':
				laneToBlock = 'otherReasons'
				break
			case 'Older Proposal':
				laneToBlock = 'olderProposal'
				break
			case 'Opened Last Month':
				laneToBlock = 'openedLongBack'
				break
			default:
				// console.log('Lane not found !')
				break
		}
		return laneToBlock
	}

	// Custom Lane Header for the Kanban Lanes
	CustomLaneHeader = ({ label, id }) => {
		const value = this.laneBlockRetriever(id)
		const { lazyBlockers } = this.state
		if (label < 10 && lazyBlockers[value] === false) {
			this.setState((prevState) => ({
				lazyBlockers: {
					...prevState.lazyBlockers,
					[value]: true,
				},
			}))
		}

		return (
			<div>
				<header status={id} style={styles.laneHeader}>
					<div style={styles.laneDiv}>{id.replace('_', ' ')}</div>
					<div style={styles.laneLabel}>{`( ${label} )`}</div>
				</header>
			</div>
		)
	}

	// Custom Card for the Kanban Lanes
	CustomCard = ({
		id,
		address,
		area,
		areaType,
		contactNo,
		// email,
		enquiryTimestamp,
		enquiryType,
		enquirySection,
		enquiryLane,
		followupDate,
		// id,
		modifiedAt,
		// modifiedBy,
		name,
		onClick,
		otpCheck,
		followupActive,
		proposalLastSeen,
		duplicateEnquiries,
		// preferredTimeToCall,
		// scope1,
		// scope2,
		// scope3,
		// scope4,
		typology,
	}) => {
		// Checks the contact no for Indian Dial Code
		// If contains '+91`, set true - Displayed as Green Globe Icon in the cards
		// Else set false
		const nri =
			(contactNo && contactNo.includes('+91')) || (contactNo && !contactNo.includes('+'))
				? false
				: !!contactNo

		// Converting enquiry timestamp to Date (Eg: May 18, 1998)
		// const dateOfEnquiry = format(new Date(enquiryTimestamp * 1000), 'MMM d, yyyy')
		// Converting enquiry timestamp to Time (Eg: 5.30 pm)
		// const timeOfEnquiry = format(new Date(enquiryTimestamp * 1000), 'h:mm a')
		// Converting lastupdated timestamp to Context (Eg: 15 mins ago, 4 hours ago etc.)
		// Shows the latest time at which a modification/action was performed in this enquiry
		const lastUpdatedAt = !modifiedAt
			? null
			: formatDistanceToNow(new Date(modifiedAt * 1000), {
					addSuffix: true,
			  })
		// Converting followupDate timestamp to Context (Eg: 15 mins left, 4 hours left etc.)
		// Shows the time remaining to followup on this enquiry
		const followupTimeLeft = formatDistanceToNow(new Date(followupDate * 1000), {
			addSuffix: true,
		})
			.replace('ago', 'crossed')
			.replace('about', '')

		// Changing enqiury name to lowercase letters to provide visual uniformity
		// CSS used at variable usage point to Capitalize initals of each word : text-trasnform(capitalize)
		// If no enquiry name is found, sets as 'Anonymous'
		const enquiryName = name ? name.toLowerCase() : 'Anonymous'

		return (
			<Card
				style={styles.cardMain}
				onClick={onClick}
				className={`crmEnquiryCard ${enquiryLane}`}>
				<header style={styles.cardHeader}>
					<Row style={styles.cardDiv} gutter={[2, 2]}>
						<Col span={12}>
							<span style={{ float: 'left' }}>
								{enquiryName.length > 12
									? `${enquiryName.substring(0, 12)}...`
									: enquiryName}
							</span>
							<span style={{ float: 'right' }}>
								{contactNo === null ? (
									<Tooltip title="Mobile number not available">
										<FiPhoneOff style={styles.mobileAndNriNotVerified} />
									</Tooltip>
								) : null}
								{contactNo && otpCheck === null ? (
									<Tooltip title="Mobile number not verified">
										<FiPhone style={styles.mobileAndNriNotVerified} />
									</Tooltip>
								) : null}
								{otpCheck === 'manual_entry' ? (
									<Tooltip title="Manual Enquiry Mobile Number">
										<FiPhoneIncoming style={styles.mobileAndNriNotVerified} />
									</Tooltip>
								) : null}
								{otpCheck === 'otp_matched' ? (
									<Tooltip title="Mobile number verified">
										<MdVerifiedUser style={styles.mobileAndNriVerified} />
									</Tooltip>
								) : null}
								{otpCheck === 'otp_mismatched' || otpCheck === 'invalid_response' ? (
									<Tooltip title="Mobile number not verified">
										<FiPhone style={styles.mobileAndNriNotVerified} />
									</Tooltip>
								) : null}
								{nri && (
									<Tooltip title="NRI Client">
										<GoGlobe style={styles.nriGlobe} />
									</Tooltip>
								)}
								{followupActive === 'yes' && (
									<Tooltip title={followupTimeLeft} placement="topRight">
										<MdWatch style={styles.activeFollowup} />
									</Tooltip>
								)}

								{duplicateEnquiries - 1 !== 0 && (
									<Tooltip
										title={`${duplicateEnquiries - 1} duplicates`}
										placement="topRight">
										<FaRegCopy style={styles.duplicatesCount} />
									</Tooltip>
								)}
								{proposalLastSeen && (
									<Tooltip
										title={`Proposal last seen @ ${format(
											new Date(proposalLastSeen * 1000),
											'MMM dd, yyyy - h:mm a'
										)}`}
										placement="topRight">
										<MdAttachFile style={styles.duplicatesCount} />
									</Tooltip>
								)}
							</span>
							{lastUpdatedAt !== null && (
								<Tooltip
									title={`Last Updated ${lastUpdatedAt}`}
									placement="bottom"
									className="lastUpdatedTooltipHeader">
									<MdUpdate
										style={{
											fontSize: 18,
											color: '#afafaf',
											marginLeft: 8,
											marginBottom: -5,
											padding: 0,
										}}
									/>
									<span style={{ color: '#afafaf', fontSize: 11, marginLeft: 2 }}>
										{lastUpdatedAt !== null
											? truncateTime(lastUpdatedAt).replace('ago', '')
											: lastUpdatedAt}
									</span>
								</Tooltip>
							)}
						</Col>
						<Col span={12} style={styles.address}>
							{address
								? address.replace(/([A-Z])/g, ' $1').trim()
								: 'Address not available'}
						</Col>
					</Row>
				</header>
				<div style={styles.cardBody}>
					<div style={styles.tagContainer}>
						<Tag style={styles.tag}>{typology}</Tag>
						<Tag style={styles.tag}>{`${area} ${areaType}`}</Tag>
						{lastUpdatedAt !== null && (
							<Tooltip
								title={`Last Updated ${lastUpdatedAt}`}
								placement="topRight"
								className="lastUpdatedTooltipFooter">
								<MdUpdate
									style={{
										fontSize: 18,
										color: '#afafaf',
										marginLeft: -4,
										marginBottom: -5,
										padding: 0,
									}}
								/>
								<span style={{ color: '#afafaf', fontSize: 11, marginLeft: 2 }}>
									{lastUpdatedAt !== null
										? truncateTime(lastUpdatedAt).replace('ago', '')
										: lastUpdatedAt}
								</span>
							</Tooltip>
						)}
					</div>
				</div>
				{/* {followupActive === 'yes' &&
				followupDate &&
				enquirySection !== 'activeProposal' ? (
					<Tooltip title="Followup status" placement="topRight">
						<div style={styles.followupCrossedBar}>
							<Typography.Text
								style={
									followupTimeLeft.includes('crossed')
										? styles.followupTimeCrossed
										: styles.followupTimeLeft
								}>
							
								{followupTimeLeft.includes(' in ')
									? `${followupTimeLeft} left`
									: followupTimeLeft}
							</Typography.Text>
						</div>
					</Tooltip>
				) : null} */}
				{/* {proposalLastSeen ? (
					<Tooltip title="Proposal Last Seen" placement="bottomRight">
            <MdAttachFile />
						<div style={styles.proposalLastSeenBar}>
							<Typography.Text style={{ color: '#fff' }}>
								{format(new Date(proposalLastSeen * 1000), 'MMM dd, yyyy - h:mm a')}
							</Typography.Text>
						</div>
					</Tooltip>
				) : null} */}
			</Card>
		)
	}

	/* 
    Methods for enquiry lazyload
  */

	// Custom delay load function for kanban lane lazyload - Check react-trello storybook
	delayedPromise = (durationInMs, resolutionPayload) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(resolutionPayload)
			}, durationInMs)
		})
	}

	// Resolves the lane number (0-3) for two functionalities currently
	// Update the scrolled lane with new cards - generateCards
	// Set state the full lane date whose modal is currently active for modal navigation - crmModalRender
	resolveLaneNumber = (laneId, enquiryStatus) => {
		switch (laneId) {
			case 'won':
			case 'to_contact':
			case 'met':
			case 'Today Followup':
			case 'fake_enquiries':
			case 'Past Seven Days':
			case 'Opened Today':
				if (enquiryStatus === 'newOpportunities' && laneId === 'met') return 3
				return 0
			case 'lose':
			case 'in_contact':
			case 'proposal_sent':
			case 'Yesterday Followup':
			case 'not_feasible':
			case 'Past One Month':
			case 'Opened Three Days Back':
				return 1
			case 'meeting_fixed':
			case 'proposal_seen':
			case 'Past Followup':
			case 'not_responsive':
			case 'Past Three Months':
			case 'Opened Last Week':
				return 2
			case 'under_negotiation':
			case 'Future Followup':
			case 'other_reasons':
			case 'Older Proposal':
			case 'Opened Last Month':
				return 3
			default:
				// console.log('Lane not found !')
				break
		}
	}

	// Custom function to initiate fetchMore from the query comp. for lazyloading of enquiries
	// Gets called when user scrolls to the bottom of a kanban lane
	// Gets called when user is navigating through enquiries via modal
	generateCards = (
		requestedPage,
		laneId, // Current stage of the enquiry such as 'to contact', 'in contact' etc.
		data, // Contains the entire board data of current enquiry page
		fetchMore, // fetchMore - react apollo query
		callFrom, // set to differentiate from modal call & scroll call, to be removed
		enquiryStatus, // Category of the enquiry page, Eg: Positive Ones, Archive etc.
		currentEnquiryLane // Current lane's data - object containing arrays
	) => {
		const cards = [] // To store the lazy fetched enquiries
		const laneNo = this.resolveLaneNumber(laneId, enquiryStatus) // We need the laneNo in order to determine in which lane (0-3) to push the lazy fetched enquiries into

		if (data.lanes[laneNo].cards.length % 10 !== 0) return false
		fetchMore({
			// fetchMore - react apollo query
			variables: {
				crmStatus: laneId, // laneId is needed so that we can fetch the enquiries based on the enquiry status
				offset: data.lanes[laneNo].cards.length, // Offset equals the length of the scrolled lane data array
				limit: 10, // fetching 10 enquiries per scroll
			},

			updateQuery: (prev, { fetchMoreResult }) => {
				// updateQuery is called to merge the newly fetched data with the existing data, and initialize a UI re-render
				if (!fetchMoreResult) return prev // if fetch list empty, return exisiting data

				if (fetchMoreResult.lanes[0].cards.length < 10) {
					let laneToBlock

					switch (fetchMoreResult.lanes[0].id) {
						case 'won':
							laneToBlock = 'won'
							break
						case 'to_contact':
							laneToBlock = 'toContact'
							break
						case 'met':
							laneToBlock = 'met'
							break
						case 'Today Followup':
							laneToBlock = 'todayFollowup'
							break
						case 'fake_enquiries':
							laneToBlock = 'fakeEnquiries'
							break
						case 'Past Seven Days':
							laneToBlock = 'pastSevenDays'
							break
						case 'Opened Today':
							laneToBlock = 'openedLastWeek'
							break
						case 'lose':
							laneToBlock = 'lose'
							break
						case 'in_contact':
							laneToBlock = 'inContact'
							break
						case 'proposal_sent':
							laneToBlock = 'proposalSent'
							break
						case 'Yesterday Followup':
							laneToBlock = 'yesterdayFollowup'
							break
						case 'not_feasible':
							laneToBlock = 'notFeasible'
							break
						case 'Past One Month':
							laneToBlock = 'pastOneMonth'
							break
						case 'Opened Three Days Back':
							laneToBlock = 'openedLastMonth'
							break
						case 'meeting_fixed':
							laneToBlock = 'meetingFixed'
							break
						case 'proposal_seen':
							laneToBlock = 'proposalSeen'
							break
						case 'Past Followup':
							laneToBlock = 'pastFollowup'
							break
						case 'not_responsive':
							laneToBlock = 'notResponsive'
							break
						case 'Past Three Months':
							laneToBlock = 'pastThreeMonths'
							break
						case 'Opened Last Week':
							laneToBlock = 'openedLastQuarter'
							break
						case 'under_negotiation':
							laneToBlock = 'underNegotiation'
							break
						case 'Future Followup':
							laneToBlock = 'futureFollowup'
							break
						case 'other_reasons':
							laneToBlock = 'otherReasons'
							break
						case 'Older Proposal':
							laneToBlock = 'olderProposal'
							break
						case 'Opened Last Month':
							laneToBlock = 'openedLongBack'
							break
						default:
							// console.log('Lane not found !')
							break
					}

					// cards.push({
					// 	id: '0000',
					// 	enquiryType: 'fake_enquiries',
					// 	name: 'END OF LIST',
					// 	address: '',
					// 	__typename: 'Cards',
					// })

					this.setState((prevState) => ({
						lazyBlockers: {
							...prevState.lazyBlockers,
							[laneToBlock]: true,
						},
					}))
				}

				cards.push(...fetchMoreResult.lanes[0].cards) // push the newly fetched data into cards array

				// this.setState((prevState) => ({
				// 	currentEnquiryLane: [
				// 		...prevState.currentEnquiryLane,
				// 		...fetchMoreResult.lanes[0].cards,
				// 	],
				// }))
				// this.setState({
				// 	currentEnquiryLane: currentEnquiryLane.concat(fetchMoreResult.lanes[0].cards),
				// currentEnquirylane contains the current lanes exisiting data
				// fetched data is grouped based on 'id' and merged along with exisitng data and reset in state
				// })

				return update(prev, {
					// a method of immutability-helper , check npm
					lanes: {
						[laneNo]: { cards: { $push: fetchMoreResult.lanes[0].cards } }, // fetched data is pushed into the appropriate lane, this consists of the whole board Dara
					},
				})
			},
		})

		return cards
	}

	// Custom function to lazyload enquiry cards into a specific lane
	// Also check react-trello storybook for more info
	// Gets called when user scrolls to the bottom of a kanban lane
	// Gets called when user is navigating through enquiries via modal
	paginate = async (
		requestedPage,
		laneId, // Current stage of the enquiry such as 'to contact', 'in contact' etc.
		data, // Contains the entire board data of current enquiry page
		fetchMore, // fetchMore - react apollo query
		callFrom, // set to differentiate from modal call & scroll call, to be removed
		enquiryStatus, // Category of the enquiry page, Eg: Positive Ones, Archive etc.
		currentEnquiryLane // Current lane's data - object containing arrays
	) => {
		const {
			lazyBlockers: {
				won,
				toContact,
				met,
				todayFollowup,
				fakeEnquiries,
				pastSevenDays,
				openedLastWeek,
				lose,
				inContact,
				proposalSent,
				yesterdayFollowup,
				notFeasible,
				pastOneMonth,
				openedLastMonth,
				meetingFixed,
				proposalSeen,
				pastFollowup,
				notResponsive,
				pastThreeMonths,
				openedLastQuarter,
				underNegotiation,
				futureFollowup,
				otherReasons,
				olderProposal,
				openedLongBack,
			},
		} = this.state

		function alertEndOfEnquiries(lane, laneClass) {
			const nodes = document.querySelectorAll(
				`.ant-card.crmEnquiryCard.${laneClass}.ant-card-bordered`
			)
			console.log(nodes)
			nodes[nodes.length - 1].style.marginBottom = '200px'

			message.info(
				<span style={{ fontWeight: 400 }}>
					No more enquiries in{' '}
					<strong style={{ textTransform: 'capitalize', color: '#1890f' }}>
						{lane.replace(/_/g, ' ')}
					</strong>{' '}
					section ...{' '}
				</span>
			)
			return null
		}

		switch (laneId) {
			case 'won':
				if (won) return alertEndOfEnquiries(laneId, 'won')
				break
			case 'to_contact':
				if (toContact) return alertEndOfEnquiries(laneId, 'to_contact')
				break
			case 'met':
				if (met) return alertEndOfEnquiries(laneId, 'met')
				break
			case 'Today Followup':
				if (todayFollowup) return alertEndOfEnquiries(laneId, 'todayFollowup')
				break
			case 'fake_enquiries':
				if (fakeEnquiries) return alertEndOfEnquiries(laneId, 'fake_enquiries')
				break
			case 'Past Seven Days':
				if (pastSevenDays) return alertEndOfEnquiries(laneId, 'pastSevenDays')
				break
			case 'Opened Today':
				if (openedLastWeek) return alertEndOfEnquiries(laneId, 'openedToday')
				break
			case 'lose':
				if (lose) return alertEndOfEnquiries(laneId, 'lose')
				break
			case 'in_contact':
				if (inContact) return alertEndOfEnquiries(laneId, 'in_contact')
				break
			case 'proposal_sent':
				if (proposalSent) return alertEndOfEnquiries(laneId, 'proposal_sent')
				break
			case 'Yesterday Followup':
				if (yesterdayFollowup) return alertEndOfEnquiries(laneId, 'yesterdayFollowup')
				break
			case 'not_feasible':
				if (notFeasible) return alertEndOfEnquiries(laneId, 'not_feasible')
				break
			case 'Past One Month':
				if (pastOneMonth) return alertEndOfEnquiries(laneId, 'pastOneMonth')
				break
			case 'Opened Three Days Back':
				if (openedLastMonth) return alertEndOfEnquiries(laneId, 'openedThreeDaysBack')
				break
			case 'meeting_fixed':
				if (meetingFixed) return alertEndOfEnquiries(laneId, 'meeting_fixed')
				break
			case 'proposal_seen':
				if (proposalSeen) return alertEndOfEnquiries(laneId, 'proposal_seen')
				break
			case 'Past Followup':
				if (pastFollowup) return alertEndOfEnquiries(laneId, 'pastFollowup')
				break
			case 'not_responsive':
				if (notResponsive) return alertEndOfEnquiries(laneId, 'not_responsive')
				break
			case 'Past Three Months':
				if (pastThreeMonths) return alertEndOfEnquiries(laneId, 'pastThreeMonths')
				break
			case 'Opened Last Week':
				if (openedLastQuarter) return alertEndOfEnquiries(laneId, 'openedLastWeek')
				break
			case 'under_negotiation':
				if (underNegotiation) return alertEndOfEnquiries(laneId, 'under_negotiation')
				break
			case 'Future Followup':
				if (futureFollowup) return alertEndOfEnquiries(laneId, 'futureFollowup')
				break
			case 'other_reasons':
				if (otherReasons) return alertEndOfEnquiries(laneId, 'other_reasons')
				break
			case 'Older Proposal':
				if (olderProposal) return alertEndOfEnquiries(laneId, 'olderProposal')
				break
			case 'Opened Last Month':
				if (openedLongBack) return alertEndOfEnquiries(laneId, 'openedLastMonth')
				break
			default:
				// console.log('Lane not found !')
				break
		}

		// Fetch enquiries lazily & then generate kanban cards
		const newCards = this.generateCards(
			requestedPage,
			laneId,
			data,
			fetchMore,
			callFrom,
			enquiryStatus,
			currentEnquiryLane
		)

		return this.delayedPromise(1000, newCards)
	}

	/* 
    Methods for modal rendering
  */

	// Render a modal containing the enquiry details on kanban card click
	// Also gets called when user searches for an enquiry & clicks on one of the search results
	crmModalRender = (cardId, visible, laneId, data, enquiryStatus, refetch, fetchMore) => {
		if (laneId && laneId !== 'duplicateEnquiryModal') {
			// If laneId is not present it means the function was called from the search enqiury component
			const laneNo = this.resolveLaneNumber(laneId, enquiryStatus) // We need the laneNo in order to determine in which lane (0-3) to push the lazy fetched enquiries into

			// const groupedData = groupBy(data.lanes[laneNo].cards, 'id') // Now we know which lane the modal is going to be active on, the specific lane's full data is grouped to provide modal navigation
			const currentLaneArray = data.lanes[laneNo].cards

			this.setState({
				boardData: data, // Contains the entire board data of current enquiry page
				lazyLoadData: fetchMore, // fetchMore - react apollo query - crmDetalls
				currentEnquiryLane: currentLaneArray,
			})

			// Retreiving the list keys of the current lane's data, i.e  set of array indexes

			const currentEnquiryKey = currentLaneArray.findIndex((p) => p.id === cardId) // Searching the enquiry id of the clicked enquiry card against the set of indexes
			const prevEnquiryKey = currentEnquiryKey - 1 // To retrieve the array index of the previous array in the list
			const nextEnquiryKey = currentEnquiryKey + 1 // To retrieve the array index of the next array in the list

			const prevEnquiryId =
				currentEnquiryKey !== 0 ? currentLaneArray[prevEnquiryKey].id : null // Fetchng the enqiuryId of previous enquiry
			const nextEnquiryId =
				nextEnquiryKey === currentLaneArray.length
					? null
					: currentLaneArray[nextEnquiryKey].id // Fetchng the enqiuryId of next enquiry

			this.setState({
				// currentEnquiryLane: currentLaneArray, // current lane's set of data
				currentEnquiryId: cardId, // currently active enqiuryID
				prevEnquiryId,
				nextEnquiryId,
			})

			const currentEnquiryData = currentLaneArray[currentEnquiryKey] // Fetching the data list using the enqiuryId

			const currentEnquiryListData = this.createEnquiryDetailsList(currentEnquiryData) // Creating a details list to render in the modal

			this.setState({
				currentEnquiryList: currentEnquiryListData,
				onChangeRefetch: refetch, // For lazyload
				visible: !visible, // Toggles the modal visibility
			})
		} else if (laneId === 'duplicateEnquiryModal') {
			// Reaches else part, if function was called from search enqiury component

			const currentEnquiryListData = this.createEnquiryDetailsList(
				visible // Some parameter issued to be fixed, should not use the visible keyword here,
			)

			this.setState({
				visible: true, // Toggle the modal visibility
				currentEnquiryLane: null, // Search enqiury modal has no navigation, so these are set to null
				prevEnquiryId: null,
				nextEnquiryId: null,
				currentEnquiryId: visible.id, // Enqiury id is required to in CRM Modal component
				currentEnquiryList: currentEnquiryListData, // Details list to render in the modal
			})
		} else {
			// Reaches else part, if function was called from search enqiury component
			const currentEnquiryListData = this.createEnquiryDetailsList(
				visible[cardId.props.index] // Some parameter issued to be fixed, should not use the visible keyword here,
			)
			this.setState({
				visible: !this.state.visible, // Toggle the modal visibility
				currentEnquiryLane: null, // Search enqiury modal has no navigation, so these are set to null
				prevEnquiryId: null,
				nextEnquiryId: null,
				currentEnquiryId: visible[cardId.props.index].id, // Enqiury id is required to in CRM Modal component
				currentEnquiryList: currentEnquiryListData, // Details list to render in the modal
			})
		}
	}

	// Open the next enquiry modal function
	openNextEnquiryModal = async (enquiryStatus) => {
		const { boardData, currentEnquiryId, currentEnquiryLane, lazyLoadData } = this.state
		if (!currentEnquiryLane) return false

		// Retreiving the list keys of the current lane's data, i.e  set of array indexes

		const currentEnquiryKey = currentEnquiryLane.findIndex(
			(p) => p.id === currentEnquiryId
		) // Searching the enqiury id of the clicked enquiry card against the set of indexes

		const nextEnquiryKey = currentEnquiryKey + 1 // To retrieve the array index of the next array in the list

		const nextEnquiryId =
			nextEnquiryKey === currentEnquiryLane.length
				? null
				: currentEnquiryLane[nextEnquiryKey].id
		const currentLaneName = this.laneBlockRetriever(
			currentEnquiryLane[currentEnquiryKey].laneId
		)
		const { lazyBlockers } = this.state
		if (nextEnquiryId > 0) {
			// The condtion is satisfied untill user reaches the end of lane data, if not goes to else to perform lazyload and then navigate
			this.setState({
				currentEnquiryId: nextEnquiryId,
				prevEnquiryId: currentEnquiryId,
				nextEnquiryId,
			})

			const currentEnquiryData = currentEnquiryLane[nextEnquiryKey]
			const currentEnquiryListData = this.createEnquiryDetailsList(currentEnquiryData)

			this.setState({
				currentEnquiryList: currentEnquiryListData,
			})
		} else {
			if (lazyBlockers[currentLaneName]) return false

			const { boardData, currentEnquiryId, currentEnquiryLane, lazyLoadData } = this.state
			if (currentEnquiryLane.length % 10 !== 0) return false
			// Reaches this part when user reaches end of a specific lane data, lazyload is perfomed and then navigation occurs
			const requestedPage = 1
			const data = boardData // Contains the entire board data of current enquiry page
			const fetchMore = lazyLoadData // fetchMore - react apollo query - crmDetalls, rename it to function or something
			const { enquiryType: laneId } = currentEnquiryLane[currentEnquiryKey] // Current stage of the enquiry such as 'to contact', 'in contact' etc.
			const callFrom = 'modalNextButton' // set to differentiate from modal call & scroll call, to be removed

			const newDataSet = await this.paginate(
				requestedPage,
				laneId,
				data,
				fetchMore,
				callFrom,
				enquiryStatus,
				currentEnquiryLane
			)

			this.setState(
				(prevState) => ({
					currentEnquiryLane: [...prevState.currentEnquiryLane, ...newDataSet],
				}),
				() => {
					// Retreiving the list keys of the updated current lane's data, i.e  set of array indexes
					const { currentEnquiryLane: updatedEnqiuryLane } = this.state

					const newCurrentEnquiryKey = updatedEnqiuryLane.findIndex(
						(p) => p.id === currentEnquiryId
					) // Searching the enqiury id of the clicked enquiry card against the updated set of indexes

					const newNextEnquiryKey = newCurrentEnquiryKey + 1 // To retrieve the array index of the next array in the updated list

					const newNextEnquiryId =
						newNextEnquiryKey === updatedEnqiuryLane.length
							? null
							: updatedEnqiuryLane[newNextEnquiryKey].id

					this.setState({
						currentEnquiryId: newNextEnquiryId,
						prevEnquiryId: currentEnquiryId,
						nextEnquiryId: newNextEnquiryId,
					})

					const newCurrentEnquiryData = updatedEnqiuryLane[newNextEnquiryKey]

					const newCurrentEnquiryListData = this.createEnquiryDetailsList(
						newCurrentEnquiryData
					)

					this.setState({
						currentEnquiryList: newCurrentEnquiryListData,
					})
				}
			)
		}
	}

	// Open the previous enquiry modal function
	openPreviousEnquiryModal = () => {
		const { currentEnquiryLane, currentEnquiryId } = this.state
		if (!currentEnquiryLane) return false

		const currentEnquiryKey = currentEnquiryLane.findIndex(
			(p) => p.id === currentEnquiryId
		)

		const previousEnquiryKey = currentEnquiryKey - 1

		const previousEnquiryId =
			currentEnquiryKey !== 0 ? currentEnquiryLane[previousEnquiryKey].id : null

		if (previousEnquiryId) {
			this.setState({
				currentEnquiryId: previousEnquiryId,
				prevEnquiryId: previousEnquiryId,
				nextEnquiryId: currentEnquiryId,
			})

			const currentEnquiryData = currentEnquiryLane[previousEnquiryKey]
			const currentEnquiryListData = this.createEnquiryDetailsList(currentEnquiryData)

			this.setState({
				currentEnquiryList: currentEnquiryListData,
			})
		} else {
			// console.log('No more to show')
		}
	}

	createEnquiryDetailsList = (crmData) => {
		// console.log(crmData)
		const {
			address,
			area,
			areaType,
			contactNo,
			duplicateEnquiries,
			email,
			enquiryTimestamp,
			enquiryType,
			followupActive,
			followupDate,
			proposalLastSeen,
			// id,
			laneId,
			modifiedAt,
			// modifiedBy,
			name,
			otpCheck,
			scope,
			typology,
			preferredTimeToCall,
		} = crmData

		const nri =
			(contactNo && contactNo.includes('+91')) || (contactNo && !contactNo.includes('+'))
				? false
				: !!contactNo

		const dateOfEnquiry = format(new Date(enquiryTimestamp * 1000), 'MMM d, yyyy')

		const timeOfEnquiry = format(
			new Date(enquiryTimestamp * 1000),
			'MMMM dd, yyyy - h:mm a'
		)

		const lastUpdatedAt = formatDistanceToNow(new Date(modifiedAt * 1000), {
			addSuffix: true,
		})

		const location = address
			? address
					.replace(/([A-Z])/g, ' $1')
					.replace(/([0-9])/g, ' $1')
					.replace(/,/g, ', ')
					.trim()
			: 'Address not available'

		const enquiryName = name || 'Anonymous'

		const currentEnquiryData = {
			contactNo,
			crmData,
			dateOfEnquiry,
			duplicateEnquiries,
			email,
			enquiryName,
			enquiryType,
			followupActive,
			proposalLastSeen,
			followupDate,
			laneId,
			lastUpdatedAt,
			location,
			nri,
			otpCheck,
			preferredTimeToCall,
			timeOfEnquiry,
		}

		const currentEnquiryListData = {
			area: `${area} ${areaType}`,
			contactNo,
			email,
			location,
			scope,
			timeOfEnquiry,
			typology,
			preferredTimeToCall,
		}

		this.setState({
			currentModalData: currentEnquiryData,
		})

		return currentEnquiryListData
	}

	updateCurrentEnquiryList = (enquiryId, updatedValue, updatedType) => {
		let updateObjectKey
		const { currentEnquiryLane } = this.state
		switch (updatedType) {
			case 'contactNo':
				updateObjectKey = 'contactNo'
				break
			case 'email':
				updateObjectKey = 'email'
				break
			case 'typology':
				updateObjectKey = 'typology'
				break
			case 'area':
				updateObjectKey = 'area'
				break
			case 'areaType':
				updateObjectKey = 'areaType'
				break
			case 'scope':
				updateObjectKey = 'scope'
				break

			default:
				break
		}

		const currentEnquiryKey = currentEnquiryLane.findIndex((p) => p.id === enquiryId)

		this.setState(
			{
				currentEnquiryLane: currentEnquiryLane.map((el) =>
					el.id === enquiryId ? { ...el, [updateObjectKey]: updatedValue } : el
				),
			},
			() => {
				console.log(this.state.currentEnquiryLane)
			}
		)
	}

	// Render method
	render() {
		const { enquiryStatus } = this.props // Current Category of the enquiry page, Eg: Positive Ones, Archive etc.
		const {
			callFrom, // set to differentiate from modal call & scroll call, to be removed
			currentEnquiryId, // Enquiry ID of the currently active Enquiry Modal
			currentEnquiryLane, // Current lane's data - object containing arrays
			currentEnquiryList, // Enquiry List Details of the currently active Enquiry Modal
			currentModalData, // Complete Emquiry Details of the currently active Enquiry Modal
			// lazyLoadData, // Used to call the refetch of CrmDetails Query
			nextEnquiryId, // Enquiry ID of the next enquiry listing in Current Enquiry Lane
			onChangeRefetch,
			prevEnquiryId, // Enquiry ID of the previous enquiry listing in Current Enquiry Lane
			visible, // Visible state of the CRM Modal Component
		} = this.state

		const handlers = {
			renderCrmModal: this.crmModalRender,
			updateCurrentEnquiryList: this.updateCurrentEnquiryList,
		}

		return (
			<div>
				{
					<Query
						query={CrmDetails}
						variables={{
							crmStatus: enquiryStatus,
							offset: 0,
							limit: 10,
						}}>
						{({ data, loading, error, refetch, fetchMore }) => {
							if (loading) {
								return (
									<Spin
										indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
										style={{ padding: '30px 52px' }}
									/>
								)
							}
							if (error) {
								return <SomeErrorHasOccured />
							}

							return (
								<Row style={{ marginTop: -10 }}>
									<SearchEnquiryInput handlers={handlers} />
									<Col style={{ overflowX: 'scroll' }} className="followupKanban">
										<Board
											data={data}
											cardDraggable={false}
											laneDraggable={false}
											components={{
												Card: this.CustomCard,
												LaneHeader: this.CustomLaneHeader,
											}}
											hideCardDeleteIcon
											laneStyle={{ backgroundColor: '#eee' }}
											style={{ backgroundColor: '#dbdbdb' }}
											onLaneScroll={(requestedPage, laneId) =>
												this.paginate(
													requestedPage,
													laneId,
													data,
													fetchMore,
													callFrom,
													enquiryStatus,
													currentEnquiryLane
												)
											}
											// laneSortFunction={(card1, card2) =>
											// 	parseInt(card2.modifiedAt) - parseInt(card1.modifiedAt)
											// }
											onCardClick={(cardId, metadata, laneId) =>
												this.crmModalRender(
													cardId,
													visible,
													laneId,
													data,
													enquiryStatus,
													refetch,
													fetchMore
												)
											}
										/>
									</Col>
								</Row>
							)
						}}
					</Query>
				}

				<CrmModal
					visible={visible}
					closeModal={this.closeModal}
					currentEnquiryId={currentEnquiryId}
					currentEnquiryList={currentEnquiryList}
					currentModalData={currentModalData}
					enquiryStatus={enquiryStatus}
					nextEnquiryId={nextEnquiryId}
					openNextEnquiryModal={() => this.openNextEnquiryModal(enquiryStatus)}
					openPreviousEnquiryModal={this.openPreviousEnquiryModal}
					prevEnquiryId={prevEnquiryId}
					refetch={onChangeRefetch}
					handlers={handlers}
				/>
			</div>
		)
	}
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components

export default CrmBoard
