import React, { useState, useEffect } from 'react'
import { Dropdown, Modal, Form, Input, Row, Col, Select, Menu } from 'antd'
import useGoogleLocationAutocomplete from 'google-autocomplete-react-hook'
import { DebounceInput } from 'react-debounce-input'
import { Query } from 'react-apollo'
import countryCode from './countryCallingCodes'
import { ActiveTeamHeads } from './query'
/**
 * @module react-debounce-input
 * React component that renders an Input, Textarea or other element with debounced onChange.
 * A Debounce function is essential to ensuring a given task doesn't fire so often that it bricks browser performance.
 */

const { Search } = Input

const { Option } = Select
const { TextArea } = Input

function AutoCompleteLocationDropdown(props) {
	const { getFieldDecorator, setFieldsValue } = props
	const locationAutocomplete = useGoogleLocationAutocomplete(
		'AIzaSyDP6ZLfCy_P0bWh8Dfh0-_iE3bEYzRYD1A'
	)
	const [searchDropdownVisible, setSearchDropdownVisible] = useState(false)
	const [locationMenuList, setLocationMenuList] = useState([])

	const locationMenu = React.useRef(
		<Menu style={{ display: 'none' }}>
			<Menu.Item>Search for places . . .</Menu.Item>
		</Menu>
	)

	const locationValue = React.useRef('')

	useEffect(() => {
		locationMenu.current = (
			<Menu
				style={{ width: 'inherit' }}
				onClick={({ item }) => {
					setSearchDropdownVisible(false)
					locationValue.current = item.props.children
					setFieldsValue({
						location: item.props.children,
					})
					setFieldsValue({
						googlePlacesId: item.props.eventKey,
					})
				}}>
				{locationMenuList ? (
					locationMenuList.map((item) => (
						<Menu.Item key={item.place_id}>{item.description}</Menu.Item>
					))
				) : (
					<Menu.Item>No results found . . .</Menu.Item>
				)}
			</Menu>
		)
	}, [locationMenuList])

	async function renderDropdownMenu(value) {
		setSearchDropdownVisible(false)
		locationAutocomplete({ input: value }).then((locationArray) => {
			setLocationMenuList(locationArray)
			setSearchDropdownVisible(true)
		})
	}

	return (
		<Dropdown
			overlay={locationMenu.current}
			trigger={['click']}
			onVisibleChange={(flag) => setSearchDropdownVisible(flag)}
			visible={searchDropdownVisible}>
			{getFieldDecorator('location', {
				rules: [{ required: true, message: 'Please select a location . . .' }],
			})(
				<DebounceInput
					element={Search}
					minLength={2}
					placeholder="Eg: Mylapore, Chennai, India"
					debounceTimeout={300}
					onChange={(event) => {
						if (!event.target.value) return
						renderDropdownMenu(event.target.value.trim())
					}}
					value={locationValue.current}
					// style={styles.searchDropdown}
				/>
			)}
		</Dropdown>
	)
}

const ManualEnquiryForm = Form.create({ name: 'form_in_modal' })(
	// eslint-disable-next-line
	class extends React.Component {
		render() {
			const { visible, onCancel, onCreate, form } = this.props
			const { getFieldDecorator, setFieldsValue } = form

			const areaTypeSelector = getFieldDecorator('areaType', {
				initialValue: 'sqft',
			})(
				<Select style={{ width: 100 }}>
					<Option value="sqft">sqft</Option>
					<Option value="sqmt">sqmt</Option>
					<Option value="acre">acre</Option>
				</Select>
			)

			const typologyList = [
				'Residence',
				'Office Space',
				'Hotels/Resorts',
				'Healthcare',
				'Commercial',
				'Campus',
				'Industrial',
				'Public Building',
				'Others',
			]

			const scopeList = ['Architecture', 'Interior', 'Landscape', 'Project Management']

			const countryCodeSelector = getFieldDecorator('countryCode', {
				initialValue: 'India (+91)',
			})(
				<Select showSearch style={{ width: 200 }}>
					{countryCode.map((item) => (
						<Option key={item.dialCode}>
							{item.name} ({item.dialCode})
						</Option>
					))}
				</Select>
			)

			return (
				<Modal
					visible={visible}
					width={850}
					title="Add New Enquiry"
					okText="Create Enquiry"
					maskClosable={false}
					onCancel={onCancel}
					onOk={onCreate}>
					<Form layout="vertical">
						<Row gutter={[48, 8]}>
							<Col span={12}>
								<Form.Item label="Client Name">
									{getFieldDecorator('name', {
										rules: [
											{ required: true, message: 'Please enter a client name . . .' },
										],
									})(<Input placeholder="Eg: John Doe" />)}
								</Form.Item>
								<Form.Item label="Email ID">
									{getFieldDecorator('email', {
										rules: [
											{
												message: 'Please enter a valid email id . . .',
												pattern: /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
											},
										],
									})(<Input type="textarea" placeholder="Eg: johndoe@gmail.com" />)}
								</Form.Item>
								<Form.Item label="Phone Number">
									{getFieldDecorator('contactNo', {
										rules: [
											{
												required: true,
												pattern: /^[0-9]*$/,
												min: 8,
												max: 15,
												message: 'Please provide a valid phone number . . .',
											},
										],
									})(
										<Input
											placeholder="Eg: 123456789"
											addonBefore={countryCodeSelector}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
								<Form.Item label="Location">
									<AutoCompleteLocationDropdown
										getFieldDecorator={getFieldDecorator}
										setFieldsValue={setFieldsValue}
									/>
								</Form.Item>
								<Form.Item style={{ display: 'none' }}>
									{getFieldDecorator('googlePlacesId')(<Input />)}
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Scope">
									{getFieldDecorator('scope', {
										rules: [{ required: true, message: 'Please select a scope . . .' }],
									})(
										<Select
											placeholder="Eg: Architecture, Interior"
											showSearch
											showArrow
											allowClear
											mode="multiple">
											{scopeList.map((item) => (
												<Option key={item}>{item}</Option>
											))}
										</Select>
									)}
								</Form.Item>
								<Form.Item label="Typology">
									{getFieldDecorator('typology', {
										rules: [
											{ required: true, message: 'Please select a typology . . .' },
										],
									})(
										<Select placeholder="Eg: Residence" allowClear showSearch>
											{typologyList.map((item) => (
												<Option key={item}>{item}</Option>
											))}
										</Select>
									)}
								</Form.Item>

								<Form.Item label="Project Area">
									{getFieldDecorator('area', {
										rules: [
											{
												required: true,
												pattern: /^[0-9]*$/,
												message: 'Please enter a valid project area . . .',
											},
										],
									})(
										<Input
											placeholder="Eg: 1000 sqft"
											addonAfter={areaTypeSelector}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
								<Form.Item label="Assign a Team Head">
									{
										<Query query={ActiveTeamHeads}>
											{({ data, loading }) => {
												if (loading) {
													return <p> loading</p>
												}

												return getFieldDecorator('teamHead', {
													rules: [
														{
															required: true,
															message: 'Please select a team head . . .',
														},
													],
												})(
													<Select
														defaultValue={data.ActiveTeamHeads.currentHead.headEmailID}
														showSearch
														allowClear
														placeholder="Eg: DQ Team Head">
														{data.ActiveTeamHeads.headList.map((values) => {
															return (
																<Option
																	key={values.headEmailID}
																	value={values.headEmailID}>
																	{values.displayName}
																</Option>
															)
														})}
													</Select>
												)
											}}
										</Query>
									}
								</Form.Item>
								<Form.Item label="Client Notes">
									{getFieldDecorator('clientNotes')(<TextArea />)}
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
			)
		}
	}
)

export default ManualEnquiryForm
