import { Card, Icon, Spin } from 'antd'

import React from 'react'
import { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import { AuthorizeGoogleDrive } from './query'
const { Meta } = Card

// const { stringDecode } = utils

class DriveAuthorize extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<>
				<Query query={AuthorizeGoogleDrive}>
					{({ data, loading, error, refetch }) => {
						if (loading) {
							return (
								<Spin
									indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
									style={{ padding: '30px 52px' }}
								/>
							)
						}
						if (data && data.authorizeGoogleDrive) {
							const { authUrl } = data.authorizeGoogleDrive
							alert(authUrl)
							if (authUrl === 'This user is already authorized') {
								return <Redirect to="/dashboard/drawings" />
							}
							if (process.env.REACT_APP_SERVER_MODE === 'development') {
								window.open(`${authUrl}&prompt=consent`)
							} else {
								window.open(`${authUrl}&prompt=consent`)
							}
							return (
								<span>
									Authorization redirect was a success. You can now close this tab.
								</span>
							)
						} else {
							return (
								<span>
									Some error has occurred. Please try again later or contact the IT Admin.
								</span>
							)
						}
					}}
				</Query>
			</>
		)
	}
}

export default DriveAuthorize
