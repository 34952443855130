import gql from 'graphql-tag'

export const MODIFY_FOLLOWUP_TIME_MUTATION = gql`
	mutation ModifyFollowupTime(
		$enquiryId: String
		$newFollowupTimestamp: Int
		$stageFrom: String
	) {
		modifyFollowupTime(
			enquiryId: $enquiryId
			newFollowupTimestamp: $newFollowupTimestamp
			stageFrom: $stageFrom
		) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
