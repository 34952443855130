// Import Statements
import { formatDistanceToNow, format, fromUnixTime } from 'date-fns'
import crypto from 'crypto'

const cypherKey = 'mySecretKey'

function decryptEnquiryId(text) {
	const decipher = crypto.createDecipher('aes-256-cbc', cypherKey)
	let dec = decipher.update(text, 'hex', 'utf8')
	dec += decipher.final('utf8')
	return dec
}

function encryptEnquiryId(text) {
	const cipher = crypto.createCipher('aes-256-cbc', cypherKey)
	let crypted = cipher.update(text, 'utf8', 'hex')
	crypted += cipher.final('hex')
	return crypted
}

/**
 * decrypts the html entity to readable format
 *
 * @param str string to decrypt
 */
function stringDecode(str) {
	return str
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&#47;/g, '/')
		.replace(/\\t/g, '')
		.replace(/&quot;/g, '"')
		.replace(/&#x27;/g, "'")
		.replace(/.&amp;nbsp;/g, ' ')
		.replace(/&amp;/g, '&')
		.replace(/&#92;/g, '\\')
}

/**
 * decrypts the html entity to readable format
 *
 * @param str string to decrypt
 */
function stringEncode(str) {
	return str
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#x27;')
		.replace(/\//g, '&#47;')
}

/** this function is used to replace the time in our required format */
function truncateTime(time) {
	return time
		.replace(/less than a\s/g, '1 ')
		.replace(/\sminutes/g, 'mins')
		.replace(/\sminute/g, 'min')
		.replace(/\sdays/g, 'd')
		.replace(/\sday/g, 'd')
		.replace(/\smonths/g, 'mos')
		.replace(/\smonth/g, 'mo')
		.replace(/\syears/g, 'y')
		.replace(/\syear/g, 'y')
		.replace(/\shours/g, 'h')
		.replace(/\shour/g, 'h')
		.replace(/about\s/g, '')
		.replace(/over\s/g, '')
		.replace(/almost\s/g, '')
}

/** this function is used to convert the timestamp into relative time */
function formatRelativetimestamp(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	const getYear = format(fromUnixTime(timestamp), 'yy')
	const currentYear = format(new Date(), 'yy')

	if (
		relativeTimeValue.includes('day') ||
		relativeTimeValue.includes('year') ||
		relativeTimeValue.includes('month')
	) {
		if (getYear === currentYear) {
			return format(fromUnixTime(timestamp), 'MMM d')
		}
		return format(fromUnixTime(timestamp), 'dd-MMM-yy')
	}
	return `${truncateTime(relativeTimeValue)} ago`
}

/** fucntion to get relative time */
function relativeTime(timestamp, addSuffix = false) {
	if (typeof timestamp === 'string') {
		timestamp = Number(timestamp)
	}
	if (typeof timestamp !== 'number') {
		return timestamp
	}
	return formatDistanceToNow(new Date(timestamp * 1000), {
		addSuffix,includeSeconds: true
	})
}

// function returns name initials from full name
function getInitials(string) {
	// if the supplied props is not of string type, return it as it is
	if (typeof string !== 'string') {
		return string
	}

	// split the names by space
	const names = string.split(' ')
	// Get first letter
	let initials = names[0].substring(0, 1).toUpperCase()

	// Get second name first letter, if there is a second name
	if (names.length > 1) {
		initials += names[1].substring(0, 1).toUpperCase()
	}

	// return the initials
	return initials
}

/** this function is used to convert array of arraus into array */
function flatten(arr) {
	return arr.reduce(function(flat, toFlatten) {
		return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
	}, [])
}

/** convert date into time and it is used in date groupBY */
function convertDatetoTime(dateData) {
	const month = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	const splittedDate = dateData.split('-')
	const currentDate = new Date()
	const date = currentDate.toJSON().slice(0, 10)
	if (dateData === date) {
		return 'Today'
	}
	return `${month[splittedDate[1] - 1]} ${splittedDate[2]}, ${splittedDate[0]}`
}

/** function is used to check the timestamp is within a day or not */
function checkADayExist(timestamp) {
	const currentTime = Math.floor(Date.now() / 1000)

	if (currentTime - Number(timestamp) <= 86400) {
		return false
	}
	return true
}

export default {
	decryptEnquiryId,
	encryptEnquiryId,
	checkADayExist,
	convertDatetoTime,
	flatten,
	getInitials,
	relativeTime,
	formatRelativetimestamp,
	truncateTime,
	stringDecode,
	stringEncode,
}
