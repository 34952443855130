import gql from 'graphql-tag'

export const SecondMenuQuery = gql`
	query getUserSecondMenu($menuId: String) {
		getUserSecondMenu(menuId: $menuId) {
			id
			text
			type
			menuLevel
			menuOrder
			componentPath
			thirdMenu
		}
	}
`
