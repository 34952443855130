import { useMutation } from '@apollo/react-hooks'
import { Col, Modal, notification, Row, Select, Typography } from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import MoveToTrashComponent from '../MoveToTrash/index'
import { ENQUIRY_STAGE_CHANGE_MUTATION } from './mutation'
import './index.css'

const { confirm } = Modal
const { Option } = Select

let stageSelectOption

export default function ChangeEnquiryStage(props) {
	const { currentEnquiryId, enquiryType, closeModal, refetch } = props
	const [enquiryStageChange] = useMutation(ENQUIRY_STAGE_CHANGE_MUTATION)
	const [defaultStage, setStage] = useState(undefined)
	const [confirmProps, setConfirmProps] = useState(true)

	async function logoutSuccess(value, option) {
		if (value === 'trash') {
			confirm({
				title: `Are you sure you want to assign this enquiry to ${option.props.children}?`,
				content: (
					<MoveToTrashComponent okButton={confirmProps} okButtonSet={setConfirmProps} />
				),
				okText: 'Confirm',
				centered: true,
				okButtonProps: {
					disabled: confirmProps,
				},
				async onOk() {
					try {
						await enquiryStageChange({
							variables: {
								enquiryId: currentEnquiryId,
								stageFrom: enquiryType,
								stageTo: value,
							},
						})
						refetch()
						notification.success({
							message: 'Enquiry stage changed',
							description: `${option.props.children}`,
							style: { marginTop: 50 },
						})
						setTimeout(() => {
							closeModal()
						}, 1000)
					} catch (error) {
						setStage(option.props.children)
					}
				},
				onCancel() {
					setStage(defaultStage)
				},
			})
		} else {
			confirm({
				title: `Are you sure you want to assign this enquiry to ${option.props.children}?`,
				content: 'You cannot revert this action.',
				okText: 'Confirm',
				centered: true,
				async onOk() {
					try {
						await enquiryStageChange({
							variables: {
								enquiryId: currentEnquiryId,
								stageFrom: enquiryType,
								stageTo: value,
							},
						})
						refetch()
						notification.success({
							message: 'Enquiry stage changed',
							description: `${option.props.children}`,
							style: { marginTop: 50 },
						})
						setTimeout(() => {
							closeModal()
						}, 1000)
					} catch (error) {
						setStage(option.props.children)
					}
				},
				onCancel() {
					setStage(defaultStage)
				},
			})
		}
	}

	switch (enquiryType) {
		case 'to_contact':
			stageSelectOption = [
				'in_contact',
				'meeting_fixed',
				'met',
				'lose',
				'fake_enquiries',
				'not_feasible',
				'not_responsive',
				'other_reasons',
			]
			break
		case 'in_contact':
			stageSelectOption = [
				'meeting_fixed',
				'met',
				'lose',
				'fake_enquiries',
				'not_feasible',
				'not_responsive',
				'other_reasons',
			]
			break
		case 'meeting_fixed':
			stageSelectOption = [
				'met',
				'lose',
				'fake_enquiries',
				'not_feasible',
				'not_responsive',
				'other_reasons',
			]
			break
		case 'met':
			stageSelectOption = [
				'lose',
				'fake_enquiries',
				'not_feasible',
				'not_responsive',
				'other_reasons',
			]
			break
		case 'proposal_sent':
			stageSelectOption = [
				'lose',
				'fake_enquiries',
				'not_feasible',
				'not_responsive',
				'other_reasons',
			]
			break
		case 'proposal_seen':
			stageSelectOption = [
				'lose',
				'fake_enquiries',
				'not_feasible',
				'not_responsive',
				'other_reasons',
			]
			break
		case 'under_negotiation':
			stageSelectOption = [
				'lose',
				'fake_enquiries',
				'not_feasible',
				'not_responsive',
				'other_reasons',
			]
			break
		case 'fake_enquiries':
			stageSelectOption = ['not_feasible', 'not_responsive', 'other_reasons']
			break
		case 'not_feasible':
			stageSelectOption = ['fake_enquiries', 'not_responsive', 'other_reasons']
			break
		case 'not_responsive':
			stageSelectOption = ['fake_enquiries', 'not_feasible', 'other_reasons']
			break
		case 'other_reasons':
			stageSelectOption = ['fake_enquiries', 'not_feasible', 'not_responsive']
			break
		default:
			stageSelectOption = []
			break
	}

	return (
		<>
			<Row style={{ marginTop: 15, marginBottom: 15 }}>
				<Col span={9} style={{ marginTop: 3 }}>
					<Typography.Text strong style={{ fontSize: 15 }}>
						Enquiry Stage :
					</Typography.Text>
				</Col>
				<Col span={15}>
					<Select
						showSearch
						className="enquiryStageDropdown"
						placeholder={enquiryType.replace('_', ' ')}
						value={defaultStage}
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						onChange={logoutSuccess}>
						{stageSelectOption.map((item) => (
							<Option key={item} style={{ textTransform: 'capitalize' }}>
								{item === 'fake_enquiries' ||
								item === 'not_feasible' ||
								item === 'not_responsive' ||
								item === 'other_reasons'
									? `Trash / ${item.replace('_', ' ')}`
									: item.replace('_', ' ')}
							</Option>
						))}
					</Select>
				</Col>
			</Row>
		</>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
ChangeEnquiryStage.propTypes = {
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
	refetch: PropTypes.func,
}

ChangeEnquiryStage.defaultProps = {
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
	refetch: PropTypes.func,
}
