/**
 * @module apollo-client
 * A fully-featured caching GraphQL client that allows us to easily build UI components that fetch data via GraphQL
 */
import { ApolloClient } from 'apollo-client'
/**
 * @module apollo-cache-memory
 * Recommended cache implementation for Apollo Client 2.0.
 * @function InMemoryCache
 * InMemoryCache is a normalized data store that supports all of Apollo Client 1.0's features without the dependency on Redux.
 */
import { InMemoryCache } from 'apollo-cache-inmemory'
/**
 * @module apollo-link-http
 * A terminating link that fetches GraphQL results from a GraphQL endpoint over an http connection.
 */
import { HttpLink } from 'apollo-link-http'
/**
 * @module apollo-link-error
 * To do some custom logic when a GraphQL or network error happens
 */
import { onError } from 'apollo-link-error'
/**
 * @module apollo-link
 * apollo-link is a standard interface for modifying control flow of GraphQL requests and fetching GraphQL results,
 */
import { ApolloLink, split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from 'apollo-upload-client'

let endpoint // To store the graphql endpoint url based on the server react app environment
let SERVER_WS_ENDPOINT // To store the graphql endpoint url based on the server react app environment
const reactAppServerMode = process.env.REACT_APP_SERVER_MODE

switch (reactAppServerMode) {
	case 'development': // localhost
		endpoint = 'http://localhost:8080/graphql'
		SERVER_WS_ENDPOINT = 'ws://localhost:8080/subscription'
		break
	case 'testing': // test.designqube.com
		endpoint = 'http://test.designqube.com/graphql'
		SERVER_WS_ENDPOINT = 'ws://test.designqube.com/subscription'
		break
	default:
		// beta.designqube.com
		endpoint = 'https://beta.designqube.com/graphql'
		SERVER_WS_ENDPOINT = 'wss://beta.designqube.com/subscription'
		break
}

/** Create a websocket link for subscriptions */
const wsLink = new WebSocketLink({
	uri: SERVER_WS_ENDPOINT,
	options: {
		reconnect: true,
	},
})

/** Create apollo link */
const httpLink = createUploadLink({
	uri: endpoint, // GraphQL endpoint
	credentials: 'include', // Tell the network interface to send the cookie along with every request
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query)
		return (
			definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
		)
	},
	wsLink,
	httpLink
)

export default new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
							locations
						)}, Path: ${path}`
					)
				)
			}
			if (networkError) {
				console.log(`[Network error]: ${networkError}`)
			}
		}),
		link,
	]),
	cache: new InMemoryCache(),
})
