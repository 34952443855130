import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const isAuthenticated = Cookies.get('dqApp-at')

	return (
		<Route
			{...rest}
			render={(props) =>
				!isAuthenticated ? (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				) : (
					<Component {...rest} />
				)
			}
		/>
	)
}

export default PrivateRoute
