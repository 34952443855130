import gql from 'graphql-tag'

export const GetFileComments = gql`
	query getFileComments($fileId: String) {
		getFileComments(fileId: $fileId) {
			id
			createdTime
			modifiedTime
			author {
				kind
				displayName
				photoLink
				me
			}
			htmlContent
			content
			deleted
			resolved
			replies {
				id
				createdTime
				modifiedTime
				author {
					kind
					displayName
					photoLink
					me
				}
				htmlContent
				content
				deleted
			}
			anchor
		}
	}
`
