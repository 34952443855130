import gql from 'graphql-tag'

export const FirstMenuQuery = gql`
	query getUserFirstMenu {
		getUserFirstMenu {
			id
			text
			type
			menuLevel
			menuOrder
			componentPath
			secondMenu {
				id
				text
				status
			}
			thirdMenu {
				id
				text
				status
			}
			displayName
			displayImage
		}
	}
`
