/**
 * Proposal payment stage configuration
 */

// import react component
import React, { Component } from 'react'
import StageConfig from './StageConfig' // Import stageConfig component
import {
	addNewStageData,
	removeNewStageData,
	addNewPercentageData,
	dragServiceStageData,
	stageError,
	addRetainerPaymentType,
} from '../utils' // Import utils file
import './index.css' // Import component css

// ServiceStepConfig component
class ServiceStepConfig extends Component {
	state = {
		retainerType: 'percentage',
	}

	// Add New Stage in serviceStageConfig Component
	/**
	 * @type - function
	 * @param - null
	 * @summary - Here using already existing serviceStageConfig and
	 *            using that stage, add new one stage in stageConfig
	 * @return - null
	 */
	addNewStage = async () => {
		// Decontruct props
		const { serviceStageConfig, setServiceStageConfig } = this.props
		const { retainerType } = this.state

		// Add new one stage in stage config List
		const data = await addNewStageData(serviceStageConfig)
		data.errorStatus = await stageError(data, retainerType)
		data.retainerType = retainerType

		// callback function
		setServiceStageConfig(data)
	}

	retainerPaymentType = async (value) => {
		// Decontruct props
		const { serviceStageConfig, setServiceStageConfig } = this.props

		// Add new one stage in stage config List
		const data = await addRetainerPaymentType(serviceStageConfig)
		data.errorStatus = await stageError(data, value)
		data.retainerType = value

		// callback function
		setServiceStageConfig(data)

		this.setState({ retainerType: value })
	}

	// Remove Stage in serviceStageConfig Component
	/**
	 * @type - function
	 * @param - value contains stage Id
	 * @summary - Remove this stageId in stageconfig component
	 * @return - null
	 */
	removeNewStage = async (value) => {
		// Decontruct props
		const {
			serviceStageConfig,
			setServiceStageConfig,
			serviceStageDeliverableList,
			setServiceDeliverableList,
		} = this.props
		const { retainerType } = this.state

		// Remove stage in stage config component
		const { result, deliverableResult } = await removeNewStageData(
			serviceStageConfig,
			serviceStageDeliverableList,
			value
		)
		result.errorStatus = await stageError(result, retainerType)
		result.retainerType = retainerType

		// callback function
		setServiceStageConfig(result)
		setServiceDeliverableList(deliverableResult)
	}

	// Add Percentage in serviceStageConfig Component
	/**
	 * @type - function
	 * @param -  value, index,evt - evt contains stagePercentage and value contains stage Id
	 * @summary - Get the percentage in stage input field and
	 *            set that input field in stageConfig Component
	 * @return - null
	 */
	addNewPercentage = (value) => async (evt) => {
		// Decontruct props
		const { serviceStageConfig, setServiceStageConfig } = this.props
		const { retainerType } = this.state

		// Add percentage in stageConfig
		const data = await addNewPercentageData(serviceStageConfig, value, evt)
		data.errorStatus = await stageError(data, retainerType)
		data.retainerType = retainerType

		// callback function
		setServiceStageConfig(data)
	}

	// Drag and drop in serviceStageConfig Component
	/**
	 * @type - function
	 * @param - value contains list of space steps
	 * @summary - Change stage selected status and add stage to stepConfig list
	 * @return - null
	 */
	dragServiceStage = async (value) => {
		const { serviceStageConfig, setServiceStageConfig } = this.props
		const { retainerType } = this.state

		// Add stage to stepConfig list
		const data = await dragServiceStageData(serviceStageConfig, value)
		data.errorStatus = await stageError(data, retainerType)
		data.retainerType = retainerType

		// callback function
		setServiceStageConfig(data)
	}

	stageDescription = (value, scopeId, index) => {
		const { setStageDescription } = this.props
		setStageDescription(value, scopeId, index)
	}

	editStageName = (value, scopeId, index) => {
		const { editStageName } = this.props
		editStageName(value, scopeId, index)
	}

	render() {
		return (
			<div className="serviceStageConfig">
				<StageConfig
					data={this.props}
					addNewStage={this.addNewStage}
					removeNewStage={this.removeNewStage}
					addNewPercentage={this.addNewPercentage}
					dragServiceStage={this.dragServiceStage}
					retainerPaymentType={this.retainerPaymentType}
					stageDescription={this.stageDescription}
					editStageName={this.editStageName}
				/>
			</div>
		)
	}
}

// export ServiceScope component
export default ServiceStepConfig
