import React, { Component } from 'react'
import { Avatar } from 'antd'
import { MdPhotoSizeSelectActual } from 'react-icons/md'
import { formatChatRelativetimestamp } from './../utils'
import styles from './styles'
import MessageDropdown from './../ChatBody/MessageDropdown'
import { ADD_OR_REMOVE_ARCHIVE } from '../query'
import { Mutation } from 'react-apollo'

class ListItem extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedMember: [],
		}
	}
	render() {
		const { item, groupId, refetch } = this.props
		const { id, name, photo, latestMessage, unreadMessagesCount } = item
		const {
			name: latestMessageName,
			message: latestMessageText,
			type,
			time,
		} = latestMessage
		const { chatListItem } = styles
		const { selectedMember } = this.state

		return (
			<Mutation mutation={ADD_OR_REMOVE_ARCHIVE}>
				{(archieveChat, { data, loading }) => (
					<div
						className="chatListItem"
						onMouseOver={() => {
							console.log(item)
							this.setState({ selectedMember: item })
						}}
						onMouseLeave={() => {
							this.setState({ selectedMember: [] })
						}}
						style={
							id === groupId
								? chatListItem.selectedBackground
								: id === selectedMember.id
								? chatListItem.selectedBackground
								: chatListItem.unselectedBackground
						}>
						<div
							style={chatListItem.chatListDiv}
							onClick={(event) => {
								this.props.getChats(event, item, refetch)
							}}>
							{photo ? (
								<img style={chatListItem.chatListProfilePic} src={photo} alt="" />
							) : (
								<Avatar size="large" style={chatListItem.chatListEmptyProfilePic}>
									{name.slice(0, 1).toUpperCase()}
								</Avatar>
							)}
						</div>
						<div
							style={chatListItem.chatSelectedList}
							onClick={(event) => {
								this.props.getChats(event, item, refetch)
							}}>
							<div style={chatListItem.chatPaddingLeft}>
								<div>
									<label>{name}</label>
								</div>

								<div style={{ display: 'flex', width: 200 }}>
									<p style={chatListItem.chatLastMessage}>
										{latestMessageName !== null ? latestMessageName + ': ' : ''}
										{latestMessageText}
									</p>
									{type === 'image' ? (
										<MdPhotoSizeSelectActual
											size={18}
											style={{ opacity: 0.5, marginLeft: 5 }}
										/>
									) : (
										''
									)}
								</div>
							</div>
						</div>
						<div style={chatListItem.chatStatus}>
							<label style={chatListItem.chatLastMessageTime}>
								{formatChatRelativetimestamp(time)}
							</label>
							{unreadMessagesCount ? (
								<span style={chatListItem.unreadMessageCount}>{unreadMessagesCount}</span>
							) : null}
							{selectedMember.id === item.id ? (
								<div style={{ margin: 'auto' }}>
									<MessageDropdown
										labelsAndContext={{
											'Archive chat': (_) => {
												const { archieveMember } = this.props
												const { selectedMember } = this.state
												archieveMember({
													archieveChat,
													refetch,
													selectedMember,
												})
											},
										}}
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				)}
			</Mutation>
		)
	}
}

export default ListItem
