import React, { Suspense, lazy } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import crypto from 'crypto'
import BreadcrumbModule from '../Home/BreadcrumbModule/index'
import FourNotFourPage from '../FourNotFour'

import AddMasterScope from '../../components/AddMasterScope'
import CreateNewService from '../../components/CreateNewService'
import NewService from '../../components/NewService'
import NewProposal from '../../components/NewProposal'
import ChatBox from '../../components/ChatBox'

import Dashboard from '../../components/Dashboard'
import CrmBoard from '../../components/CRM/CrmBoard'
import SitePhotos from '../../components/SitePhotos'
import Tickets from '../../components/Tickets'
import Drawings from '../../components/Drawings'

import DayPlanner from '../../components/DayPlanner'
import DayPlannerProgress from '../../components/DayPlannerProgress'

import AbsenceManagement from '../../components/HRM/AbsenceManagement'
import DesignProcess from '../../components/DesignProcess'

import ProjectArchived from '../../components/ProjectArchived'
import SketchUp from '../../components/SketchUp'
import FurnishView from '../../components/FurnishView'

const HowDidIDoPrivate = lazy(() => import('../../components/HowDidIDoPrivate'))

const cypherKey = 'corona'

function decryptEnquiryId(text) {
	const decipher = crypto.createDecipher('aes-256-cbc', cypherKey)
	let dec = decipher.update(text, 'hex', 'utf8')
	dec += decipher.final('utf8')
	return dec
}

export default function Routes() {
	const localPath = localStorage.getItem('previousRoute')

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				<Route exact path="/">
					{localPath ? <Redirect to={localPath} /> : <Redirect to="/dashboard/chat" />}
				</Route>
				{/* DASHBOARD ROUTES BEGINS HERE */}
				<Route exact path="/dashboard">
					<Redirect to="/dashboard/chat" />
				</Route>
				<Route exact path="/dashboard/chat">
					{/* <BreadcrumbModule levelOneBreadCrumb="Dashboard" levelTwoBreadCrumb="Chat" /> */}
					<ChatBox />
				</Route>
				<Route exact path="/dashboard/tickets">
					<BreadcrumbModule levelOneBreadCrumb="Dashboard" levelTwoBreadCrumb="Tickets" />
					<Dashboard />
				</Route>
				<Route exact path="/dashboard/review">
					<BreadcrumbModule levelOneBreadCrumb="Dashboard" levelTwoBreadCrumb="Review" />
					<Dashboard />
				</Route>
				<Route exact path="/dashboard/leads">
					<BreadcrumbModule levelOneBreadCrumb="Dashboard" levelTwoBreadCrumb="Leads" />
					<Dashboard />
				</Route>
				<Route exact path="/dashboard/my-activity">
					<BreadcrumbModule
						levelOneBreadCrumb="Dashboard"
						levelTwoBreadCrumb="My Activity"
					/>
					<DayPlanner />
				</Route>
				<Route exact path="/dashboard/day-planner">
					<BreadcrumbModule
						levelOneBreadCrumb="Dashboard"
						levelTwoBreadCrumb="Day Planner"
					/>
					<DayPlannerProgress />
				</Route>
				<Route exact path="/dashboard/drawings">
					<BreadcrumbModule
						levelOneBreadCrumb="Dashboard"
						levelTwoBreadCrumb="Drawings"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/dashboard/how-did-i-do">
					<BreadcrumbModule
						levelOneBreadCrumb="Dashboard"
						levelTwoBreadCrumb="How Did I Do ?"
					/>
					<HowDidIDoPrivate />
				</Route>
				<Route exact path="/oauth2callback">
					<Redirect to="/dashboard" />
				</Route>
				{/* DASHBOARD ROUTES ENDS HERE */}

				{/* PROJECTS ROUTES BEGINS HERE */}
				<Route exact path="/projects">
					<Redirect to="/projects/site-photos" />
				</Route>
				<Route exact path="/projects/site-photos">
					{localStorage.getItem('lastViewedProject') ? (
						<Redirect
							to={`/projects/site-photos/${localStorage.getItem('lastViewedProject')}`}
						/>
					) : (
						<>
							<BreadcrumbModule
								levelOneBreadCrumb="Projects"
								levelTwoBreadCrumb="Site Photos"
							/>
							<Dashboard />
						</>
					)}
				</Route>
				<Route exact path="/projects/site-photos/:projectName">
					<BreadcrumbModule
						levelOneBreadCrumb="Projects"
						levelTwoBreadCrumb="Site Photos"
						levelThreeBreadCrumb={window.location.pathname
							.replace('/projects/site-photos/', '')
							.replace(/&.*/, '')
							.replace(/-/g, ' ')}
					/>
					<SitePhotos
						projectUrl={
							window.location.pathname.includes('/projects/site-photos/') &&
							decryptEnquiryId(window.location.pathname.replace(/.*&/, ''))
						}
						projectName={window.location.pathname
							.replace('/projects/site-photos/', '')
							.replace(/&.*/, '')
							.replace(/-/g, ' ')}
					/>
				</Route>

				<Route exact path="/projects/tickets">
					{localStorage.getItem('lastViewedProject') ? (
						<Redirect
							to={`/projects/tickets/${localStorage.getItem('lastViewedProject')}`}
						/>
					) : (
						<>
							<BreadcrumbModule
								levelOneBreadCrumb="Projects"
								levelTwoBreadCrumb="Tickets"
							/>
							<Dashboard />
						</>
					)}
				</Route>
				<Route exact path="/projects/tickets/:projectName">
					<BreadcrumbModule
						levelOneBreadCrumb="Projects"
						levelTwoBreadCrumb="Tickets"
						levelThreeBreadCrumb={window.location.pathname
							.replace('/projects/tickets/', '')
							.replace(/&.*/, '')
							.replace(/-/g, ' ')}
					/>
					<Tickets
						projectUrl={
							window.location.pathname.includes('/projects/tickets/') &&
							decryptEnquiryId(window.location.pathname.replace(/.*&/, ''))
						}
					/>
				</Route>

				<Route exact path="/projects/drawings">
					{localStorage.getItem('lastViewedProject') ? (
						<Redirect
							to={`/projects/drawings/${localStorage.getItem('lastViewedProject')}`}
						/>
					) : (
						<>
							<BreadcrumbModule
								levelOneBreadCrumb="Projects"
								levelTwoBreadCrumb="Drawings"
							/>
							<Dashboard />
						</>
					)}
				</Route>
				<Route exact path="/projects/drawings/:projectName">
					<BreadcrumbModule
						levelOneBreadCrumb="Projects"
						levelTwoBreadCrumb="Drawings"
						levelThreeBreadCrumb={window.location.pathname
							.replace('/projects/drawings/', '')
							.replace(/&.*/, '')
							.replace(/-/g, ' ')}
					/>
					<Drawings
						projectUrl={
							window.location.pathname.includes('/projects/drawings/') &&
							decryptEnquiryId(window.location.pathname.replace(/.*&/, ''))
						}
					/>
				</Route>
				{/* PROJECTS ROUTES BEGINS HERE */}

				{/* ENQUIRIES ROUTES BEGINs HERE */}
				<Route exact path="/enquiries">
					<Redirect to="/enquiries/followup" />
				</Route>
				<Route exact path="/enquiries/followup">
					<BreadcrumbModule
						levelOneBreadCrumb="Enquiries"
						levelTwoBreadCrumb="Followup"
					/>
					<CrmBoard enquiryStatus="followup" />
				</Route>
				<Route exact path="/enquiries/opportunities">
					<BreadcrumbModule
						levelOneBreadCrumb="Enquiries"
						levelTwoBreadCrumb="Opportunities"
					/>

					<CrmBoard enquiryStatus="newOpportunities" />
				</Route>
				<Route exact path="/enquiries/positive-ones">
					<BreadcrumbModule
						levelOneBreadCrumb="Enquiries"
						levelTwoBreadCrumb="Positive Ones"
					/>

					<CrmBoard enquiryStatus="positiveOnes" />
				</Route>
				<Route exact path="/enquiries/proposal-creation">
					<BreadcrumbModule
						levelOneBreadCrumb="Enquiries"
						levelTwoBreadCrumb="Proposal Creation"
					/>

					<CrmBoard enquiryStatus="activeProposal" />
				</Route>
				<Route exact path="/enquiries/proposal-activity">
					<BreadcrumbModule
						levelOneBreadCrumb="Enquiries"
						levelTwoBreadCrumb="Proposal Activity"
					/>

					<CrmBoard enquiryStatus="proposalActivity" />
				</Route>
				<Route exact path="/enquiries/archive">
					<BreadcrumbModule levelOneBreadCrumb="Enquiries" levelTwoBreadCrumb="Archive" />

					<CrmBoard enquiryStatus="archive" />
				</Route>
				<Route exact path="/enquiries/trash">
					<BreadcrumbModule levelOneBreadCrumb="Enquiries" levelTwoBreadCrumb="Trash" />

					<CrmBoard enquiryStatus="trash" />
				</Route>
				{/* ENQUIRIES ROUTES ENDS HERE */}
				{/* RESOURCES ROUTES BEGINS HERE */}
				<Route exact path="/resources">
					<Redirect to="/resources/variant" />
				</Route>
				<Route exact path="/resources/variant">
					<BreadcrumbModule levelOneBreadCrumb="Resources" levelTwoBreadCrumb="Variant" />
					<Dashboard />
				</Route>
				<Route exact path="/resources/material">
					<BreadcrumbModule
						levelOneBreadCrumb="Resources"
						levelTwoBreadCrumb="Material"
					/>

					<Dashboard />
				</Route>
				<Route exact path="/resources/equipment">
					<BreadcrumbModule
						levelOneBreadCrumb="Resources"
						levelTwoBreadCrumb="Equipment"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/resources/labour">
					<BreadcrumbModule levelOneBreadCrumb="Resources" levelTwoBreadCrumb="Labour" />
					<Dashboard />
				</Route>
				<Route exact path="/resources/module">
					<BreadcrumbModule levelOneBreadCrumb="Resources" levelTwoBreadCrumb="Module" />
					<Dashboard />
				</Route>
				<Route exact path="/resources/brand">
					<BreadcrumbModule levelOneBreadCrumb="Resources" levelTwoBreadCrumb="Brand" />
					<Dashboard />
				</Route>
				<Route exact path="/resources/vendor">
					<BreadcrumbModule levelOneBreadCrumb="Resources" levelTwoBreadCrumb="Vendor" />
					<Dashboard />
				</Route>
				<Route exact path="/resources/pricing">
					<BreadcrumbModule levelOneBreadCrumb="Resources" levelTwoBreadCrumb="Pricing" />
					<Dashboard />
				</Route>
				{/* ENQUIRIES ROUTES ENDS HERE */}
				{/* MASTERDATA ROUTES ENDS HERE */}
				<Route exact path="/master-data">
					<Redirect to="/master-data/essentials/teams" />
				</Route>
				<Route exact path="/master-data/essentials">
					<Redirect to="/master-data/essentials/teams" />
				</Route>
				<Route exact path="/master-data/essentials/teams">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Essentials"
						levelThreeBreadCrumb="Teams"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/essentials/roles">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Essentials"
						levelThreeBreadCrumb="Roles"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/essentials/scopes">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Essentials"
						levelThreeBreadCrumb="Scopes"
					/>
					<AddMasterScope />
				</Route>
				<Route exact path="/master-data/essentials/topics">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Essentials"
						levelThreeBreadCrumb="Topics"
					/>
					<DesignProcess />
				</Route>
				<Route exact path="/master-data/essentials/products">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Essentials"
						levelThreeBreadCrumb="Products"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/contracting">
					<Redirect to="/master-data/contracting/variant-composition" />
				</Route>
				<Route exact path="/master-data/contracting/variant-composition">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Contracting"
						levelThreeBreadCrumb="Variant Composition"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/contracting/product-tasks">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Contracting"
						levelThreeBreadCrumb="Product Tasks"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/contracting/recurring-tasks">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Contracting"
						levelThreeBreadCrumb="Recurring Tasks"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/contracting/vendor-payment-split">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Contracting"
						levelThreeBreadCrumb="Vendor Payment Split"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/contracting/preferred-vendor">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Contracting"
						levelThreeBreadCrumb="Preferred Vendor"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/contracting/profit-markup">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Contracting"
						levelThreeBreadCrumb="Profit Markup"
					/>
					<Dashboard />
				</Route>
				<Route exact path="/master-data/proposals">
					<Redirect to="/master-data/proposals/services" />
				</Route>
				<Route exact path="/master-data/proposals/services">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Proposals"
						levelThreeBreadCrumb="Services"
					/>
					<NewService />
				</Route>
				<Route exact path="/master-data/proposals/terms">
					<BreadcrumbModule
						levelOneBreadCrumb="Master Data"
						levelTwoBreadCrumb="Proposals"
						levelThreeBreadCrumb="Terms"
					/>
					<SitePhotos />
				</Route>
				{/* HRM ROUTES BEGINS HERE */}
				<Route exact path="/hrm">
					<Redirect to="/hrm/absence-log" />
				</Route>
				<Route exact path="/hrm/absence-log">
					<BreadcrumbModule levelOneBreadCrumb="HRM" levelTwoBreadCrumb="Absence Log" />
					<AbsenceManagement />
				</Route>

				{/* TOOLS ROUTES BEGINS HERE */}
				<Route exact path="/tools">
					<Redirect to="/tools/sketch-up" />
				</Route>
				<Route exact path="/tools/sketch-up">
					<BreadcrumbModule levelOneBreadCrumb="Tools" levelTwoBreadCrumb="Sketch Up" />
					<SketchUp />
				</Route>

				<Route exact path="/project-archived">
					{/* <div>archived</div> */}
					<ProjectArchived />
				</Route>

				<Route exact path="/furnish_view">
					{/* <div>archived</div> */}
					<FurnishView />
				</Route>

				{/* PROJECTS ROUTES BEGINS HERE */}
				<Route component={FourNotFourPage} />
			</Switch>
		</Suspense>
	)
}
