import gql from 'graphql-tag'

export const CREATE_MANUAL_ENQUIRY_MUTATION = gql`
	mutation InsertManualEnquiry(
		$name: String
		$emailId: String
		$contactNo: String
		$location: String
		$googlePlacesId: String
		$scope: String
		$typology: String
		$area: String
		$areaType: String
		$teamHead: String
		$clientNotes: String
	) {
		InsertManualEnquiry(
			name: $name
			emailId: $emailId
			contactNo: $contactNo
			location: $location
			googlePlacesId: $googlePlacesId
			scope: $scope
			typology: $typology
			area: $area
			areaType: $areaType
			teamHead: $teamHead
			clientNotes: $clientNotes
		) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
