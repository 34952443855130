import { useMutation } from '@apollo/react-hooks'
// Importing required components from ANTD
import { Button, Col, Input, Row } from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
// Query to fetch CRM Details on load
import { ADD_NEW_COMMENT_MUTATION } from './mutation'
import utils from '../../../utils'

const { TextArea } = Input
const { stringEncode } = utils

export default function AddComments(props) {
	const { fileId, refetchComments } = props
	const [addDriveComments, { loading, error }] = useMutation(ADD_NEW_COMMENT_MUTATION)
	const [defaultNotes, setNotesValue] = useState(null)
	const [buttonActiveState, setButtonActiveState] = useState(true)

	function onNotesChange(event) {
		// document.removeEventListener('keydown', props.arrowClickEvent, false);
		if (!event.target.value) {
			setButtonActiveState(true)
			setNotesValue(null)
		} else {
			setNotesValue(event.target.value)
			setButtonActiveState(false)
		}
	}

	async function onClickAddNotesButton() {
		if (!defaultNotes || !defaultNotes.trim()) {
			setNotesValue(null)
		} else {
			await addDriveComments({
				variables: {
					fileId,
					comments: stringEncode(defaultNotes.trim()),
				},
			})
			setNotesValue(null)
			setButtonActiveState(true)
			refetchComments()
		}
	}
	return (
		<Row gutter={[16, 8]}>
			<Col span={24}>
				<TextArea
					rows={3}
					autoSize={{ minRows: 3, maxRows: 3 }}
					value={defaultNotes}
					placeholder="Start a new comment thread . . ."
					onChange={(event) => onNotesChange(event)}
					onPressEnter={() => onClickAddNotesButton()}
					// onFocusIn = { () => window.removeEventListener('keydown', arrowClickEvent, false) }
				/>
				<Button
					type="primary"
					size="default"
					disabled={buttonActiveState}
					loading={loading ? true : false}
					onClick={() => onClickAddNotesButton()}
					block
					style={{
						marginTop: 12,
						backgroundColor: buttonActiveState ? '#656565' : '',
						color: buttonActiveState ? '#fff' : '',
						width: '25%',
						float: 'right',
					}}>
					Create
				</Button>
			</Col>
		</Row>
	)
}
