import gql from 'graphql-tag'

export const GET_DUPLICATE_ENQUIRY_LIST = gql`
	query DuplicateEnquiryList($enquiryId: String, $contactNo: String, $emailId: String) {
		duplicateEnquiryList(
			enquiryId: $enquiryId
			contactNo: $contactNo
			emailId: $emailId
		) {
			contactMatch
			emailMatch
			details {
				id
				enquiryType
				enquirySection
				name
				contactNo
				otpCheck
				enquiryTimestamp
				area
				areaType
				scope
				typology
				modifiedAt
				modifiedBy
				address
				email
				followupActive
				followupDate
				duplicateEnquiries
			}
		}
	}
`
