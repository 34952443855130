/* eslint-disable no-tabs */
// import react component
import React, { Component } from 'react'
import StageConfig from './StageConfig' // Import stageConfig component
import {
	addRetainerPaymentType,
	addNewStageData,
	removeNewStageData,
	addNewPercentageData,
	dragServiceStageData,
	stageError,
} from '../utils' // Import utils file
import './index.css' // Import component css

// ProposalStepConfig component
class ProposalStepConfig extends Component {
	state = {
		retainerType: null,
	}

	componentWillMount() {
		// Deconstruct props
		const { serviceStageConfig } = this.props
		this.setState({ retainerType: serviceStageConfig.retainerType })
	}

	// Add New Stage in serviceStageConfig Component
	/**
	 * @type - function
	 * @param - null
	 * @summary - Here using already existing serviceStageConfig and using that stage,
	 *            add new one stage in stageConfig
	 * @return - null
	 */
	addNewStage = () => {
		// Decontruct props
		const { serviceStageConfig } = this.props
		const { retainerType } = this.state

		// Add new one stage in stage config List
		const data = addNewStageData(serviceStageConfig)
		data.errorStatus = stageError(data, retainerType)
		data.retainerType = retainerType

		const { setServiceStageConfig } = this.props
		// callback function
		setServiceStageConfig(data)
	}

	retainerPaymentType = (value) => {
		// Decontruct props
		const { serviceStageConfig, setServiceStageConfig } = this.props

		// Add new one stage in stage config List
		const data = addRetainerPaymentType(serviceStageConfig)
		data.errorStatus = stageError(data, value)
		data.retainerType = value

		// callback function
		setServiceStageConfig(data)

		this.setState({ retainerType: value })
	}

	// Remove Stage in serviceStageConfig Component
	/**
	 * @type - function
	 * @param - value contains stage Id
	 * @summary - Remove this stageId in stageconfig component
	 * @return - null
	 */
	removeNewStage = (value) => {
		// Decontruct props
		const {
			serviceStageConfig,
			setServiceStageConfig,
			serviceStageDeliverableList,
			setServiceDeliverableList,
		} = this.props
		const { retainerType } = this.state

		// Remove stage in stage config component
		const { result, deliverableResult } = removeNewStageData(
			serviceStageConfig,
			serviceStageDeliverableList,
			value
		)
		result.errorStatus = stageError(result, retainerType)
		result.retainerType = retainerType

		// callback function
		setServiceStageConfig(result)
		setServiceDeliverableList(deliverableResult)
	}

	// Add Percentage in serviceStageConfig Component
	/**
	 * @type - function
	 * @param -  value, index,evt - evt contains stagePercentage and value contains stage Id
	 * @summary - Get the percentage in stage input field and
	 *            set that input field in stageConfig Component
	 * @return - null
	 */
	addNewPercentage = (value) => (evt) => {
		// Decontruct props
		const { serviceStageConfig } = this.props
		const { retainerType } = this.state

		// Add percentage in stageConfig
		const data = addNewPercentageData(serviceStageConfig, value, evt)
		data.errorStatus = stageError(data, retainerType)
		data.retainerType = retainerType

		const { setServiceStageConfig } = this.props
		// callback function
		setServiceStageConfig(data)
	}

	// Drag and drop in serviceStageConfig Component
	/**
	 * @type - function
	 * @param - value contains list of space steps
	 * @summary - Change stage selected status and add stage to stepConfig list
	 * @return - null
	 */
	dragServiceStage = (value) => {
		// Decontruct props
		const { serviceStageConfig } = this.props
		const { retainerType } = this.state

		// Add stage to stepConfig list
		const data = dragServiceStageData(serviceStageConfig, value)
		data.errorStatus = stageError(data, retainerType)
		data.retainerType = retainerType

		const { setServiceStageConfig } = this.props
		// callback function
		setServiceStageConfig(data)
	}

	stageDescription = (value, scopeId, index) => {
		const { setStageDescription } = this.props
		setStageDescription(value, scopeId, index)
	}

	editStageName = (value, scopeId, index) => {
		const { editStageName } = this.props
		editStageName(value, scopeId, index)
	}

	render() {
		return (
			<div className="serviceStageConfig">
				<StageConfig
					data={this.props}
					addNewStage={this.addNewStage}
					removeNewStage={this.removeNewStage}
					addNewPercentage={this.addNewPercentage}
					dragServiceStage={this.dragServiceStage}
					stageDescription={this.stageDescription}
					retainerPaymentType={this.retainerPaymentType}
					editStageName={this.editStageName}
				/>
			</div>
		)
	}
}

// export ServiceScope component
export default ProposalStepConfig
