import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

const PublicRoute = ({ component: Component, ...rest }) => {
	const isAuthenticated = Cookies.get('dqApp-at')

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default PublicRoute
