const styles = {
	monthPickerRow: { paddingRight: 20, marginTop: -35 },
	monthPickerCol: { float: 'right' },
	monthPicker: { width: '20vw' },
	loadingBoxRow: { padding: 20, paddingTop: 5 },
	loadingBoxCol: { overflowX: 'scroll' },
	loadingBoxTitle: { textAlign: 'center', fontSize: 18 },
	tableScrollY: { y: '60vh' },
	tablePagination: { pageSize: 20 },
	mainBoxRow: { padding: 20, paddingTop: 5 },
	mainBoxCol: { overflowX: 'scroll' },
	mainBoxTitle: { textAlign: 'center', fontSize: 18 },
	xlsDownloadButton: { marginTop: -50, zIndex: 20 },
	mainBoxTable: { pageSize: 10, position: 'top' },
	tableSearchDiv: { padding: 8 },
	tableSearchInput: { width: 188, marginBottom: 8, display: 'block' },
	tableSearchButton: { width: 90, marginRight: 8 },
	tableResetButton: { width: 90 },
	highlightedText: { backgroundColor: '#ffc069', padding: 0 },
}

export default styles
