// Importing required components from ANTD
import { Button, Modal, notification, Tooltip } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import React from 'react'
import { FiCheckSquare } from 'react-icons/fi'
import { MOVE_FILE_TO_APPROVED } from './mutation'
// import GoogleDocsViewer from 'react-google-docs-viewer'
const { confirm } = Modal

export default function ApproveFiles(props) {
	const [moveToApproved] = useMutation(MOVE_FILE_TO_APPROVED)
	const {
		fileId,
		forReviewId,
		approvedId,
		thumbnailLink,
		refetchDrawings,
		modalSwitch,
		modalView,
	} = props

	async function moveFileToApproved() {
		confirm({
			title: 'Are you sure you want move this file to "Approved" ?',
			okText: 'Confirm',
			centered: true,
			zIndex: 9999,
			content: (
				<>
					{/* <GoogleDocsViewer width="600px" height="780px" fileUrl={thumbnailLink} /> */}
					<img src={thumbnailLink} />
				</>
			),
			onOk: async () => {
				try {
					await moveToApproved({
						variables: {
							fileId,
							forReviewFolderId: forReviewId,
							approvedFolderId: approvedId,
							comments: 'Approving Comments Test',
						},
					})
					// props.refetch()
					notification.success({
						message: 'File has been moved to "Approved"',
						description: 'Successfully',
						style: { marginTop: 50 },
					})
					refetchDrawings()
					if (modalView) {
						modalSwitch()
					}
				} catch (error) {
					return null
				}
			},
			onCancel() {
				// console.log('cancel')
			},
		})
	}
	return (
		<>
			<Tooltip title="Mark as 'Approved'" placement="bottom">
				<FiCheckSquare
					style={{
						fontSize: modalView ? 24 : 18,
						marginLeft: 10,
						color: modalView ? '#54ff54' : 'green',
					}}
					onClick={() => moveFileToApproved()}
				/>
			</Tooltip>
		</>
	)
}
