/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import $ from 'jquery'
import { format, formatDistanceToNow, fromUnixTime } from 'date-fns'

// Default Expanded Keys in Service Scope List
export const serviceScopeDefaultExpandKey = (serviceScope) => {
	const defaultKey = []
	for (let x = 0; x < serviceScope.length; x += 1) {
		for (let y = 0; y < serviceScope[x].children.length; y += 1) {
			defaultKey.push(`0-${x}-${y}`)
		}
		defaultKey.push(`0-${x}`)
	}
	return defaultKey
}

// Create Space default Template
export const spaceEditorHtmlTemplate = (buildingCount, floorCount, roomCount) => {
	let data = '<ul>'
	for (let x = 0; x < buildingCount; x += 1) {
		data += `<li>Building ${x + 1}`
		data += '<ul>'

		for (let y = 0; y < floorCount; y += 1) {
			data += `<li>Floor ${y + 1}</li>`
			data += '<ul>'
			for (let z = 0; z < roomCount; z += 1) {
				data += `<li>Room ${z + 1}</li>`
			}
			data += '</ul>'
		}
		data += '</ul>'
		data += '</li>'
	}
	data += '</ul>'
	return data
}

// Get editor data from DOM class editorPreview
export const editorJsonData = () => {
	function buildJSON($li) {
		const subObj = {
			name: $li
				.contents()
				.eq(0)
				.text()
				.trim(),
		}
		$li
			.children('ul')
			.children()
			// eslint-disable-next-line func-names
			.each(function() {
				if (!subObj.children) {
					subObj.children = []
				}
				subObj.children.push(buildJSON($(this)))
			})
		return subObj
	}
	function fetchChild() {
		const data = []
		// eslint-disable-next-line func-names
		$('.editorPreview > div > ul > li').each(function() {
			data.push(buildJSON($(this)))
		})

		return data
	}

	const obj = fetchChild()

	return obj
}

// Sort typology scope key using scopeKey array
const sortTypologyKey = (data) => {
	const sortResult = data
		.map((a) =>
			a.scopeKey
				.split('.')
				.map((n) => +n + 100000)
				.join('.')
		)
		.sort()
		.map((a) =>
			a
				.split('.')
				.map((n) => +n - 100000)
				.join('.')
		)

	const sortData = sortResult.map((a) => data.filter((item) => item.scopeKey === a))

	const resultArray = []
	for (let x = 0; x < sortData.length; x += 1) {
		resultArray.push(sortData[x][0])
	}

	return resultArray
}

// Here using scope deliverable, scope checked keys and scope half checked keys
// to generate a scope typology, scope typology step ans scope typology deliverable
export const selectedScopeData = async (data) => {
	// Deconstruct data object
	const { scopeDeliverable, serviceScopeCheckedKey, serviceScopeHalfCheckedKeys } = data

	// Declare scope typology array, scope typology step array, scope typology deliverable array
	const scopeTypology = []
	const scopeTypologyStep = []
	const scopeTypologyDeliverable = []
	const serviceScopeCheckedId = []

	// Loop scope deliverable
	for (let x = 0; x < scopeDeliverable.length; x += 1) {
		// Deconstruct scope deliverable
		const {
			key,
			scopeId,
			title,
			scopeKey,
			scopePercentage,
			scopeStepOrder,
			scopeOrder,
			scopePredecessor,
		} = scopeDeliverable[x]

		if (
			serviceScopeCheckedKey.includes(key.toString()) ||
			serviceScopeHalfCheckedKeys.includes(key.toString())
		) {
			// Create  scope typology object
			const dataFirst = {
				scopeId,
				scopeName: title,
				scopeHead: title,
				scopeKey,
				scopeStepOrder,
				scopeOrder,
				scopePercentage,
				scopePredecessor,
			}

			// Push scope typology object in scope typology array
			scopeTypology.push(dataFirst)
			serviceScopeCheckedId.push(scopeId)
		}
		for (let y = 0; y < scopeDeliverable[x].children.length; y += 1) {
			// Deconstruct scope deliverable
			const {
				// eslint-disable-next-line max-len
				key: childKey,
				scopeId: childScopeId,
				title: childTitle,
				scopeKey: childScopeKey,
				scopePercentage: childScopePercentage,
				scopeStepOrder: childScopeStepOrder,
				scopeOrder: childScopeOrder,
				scopePredecessor: childScopePredecessor,
			} = scopeDeliverable[x].children[y]
			if (
				serviceScopeCheckedKey.includes(childKey.toString()) ||
				serviceScopeHalfCheckedKeys.includes(childKey.toString())
			) {
				// Create  scope step object
				const dataSecond = {
					scopeId: childScopeId,
					scopeName: childTitle,
					scopeHead: scopeDeliverable[x].title,
					scopeKey: childScopeKey,
					scopePercentage: childScopePercentage,
					scopeStepOrder: childScopeStepOrder,
					scopeOrder: childScopeOrder,
					scopeStepId: scopeDeliverable[x].scopeId,
					scopePredecessor: childScopePredecessor,
				}

				// Push scope typology step object in scope typology step array
				scopeTypologyStep.push(dataSecond)
				serviceScopeCheckedId.push(childScopeId)
			}
			for (let z = 0; z < scopeDeliverable[x].children[y].children.length; z += 1) {
				// Deconstruct scope deliverable
				const {
					// eslint-disable-next-line max-len
					key: siblingKey,
					scopeId: siblingScopeId,
					title: siblingTitle,
					scopeKey: siblingScopeKey,
					scopePercentage: siblingScopePercentage,
					scopeStepOrder: siblingScopeStepOrder,
					scopeOrder: siblingScopeOrder,
					scopePredecessor: siblingScopePredecessor,
				} = scopeDeliverable[x].children[y].children[z]
				if (serviceScopeCheckedKey.includes(siblingKey.toString())) {
					// Create  scope deliverable object
					const dataThird = {
						scopeId: siblingScopeId,
						scopeName: siblingTitle,
						scopeHead: scopeDeliverable[x].title,
						scopeHeadId: scopeDeliverable[x].scopeId,
						scopeStep: scopeDeliverable[x].children[y].title,
						scopeStepId: scopeDeliverable[x].children[y].scopeId,
						scopeKey: siblingScopeKey,
						scopePercentage: siblingScopePercentage,
						scopeStepOrder: siblingScopeStepOrder,
						scopeOrder: siblingScopeOrder,
						scopePredecessor: siblingScopePredecessor,
					}

					// Push scope typology deliverable object in scope typology deliverable array
					scopeTypologyDeliverable.push(dataThird)
					serviceScopeCheckedId.push(siblingScopeId)
				}
			}
		}
	}

	// Create object and return the result
	const result = {
		scopeTypology: await sortTypologyKey(scopeTypology),
		scopeTypologyStep: await sortTypologyKey(scopeTypologyStep),
		scopeTypologyDeliverable: await sortTypologyKey(scopeTypologyDeliverable),
		serviceScopeCheckedId,
	}

	return result
}

// Service deliverable template
export const serviceDeliverableTemplateData = (result) => {
	const { scopeDeliverable } = result
	const deliverableTemplate = []

	for (let x = 0; x < scopeDeliverable.length; x += 1) {
		// Deconstruct object
		const { scopeId, title: scopeName } = scopeDeliverable[x]
		// Declare room scope array
		const roomScope = []
		// Declare building scope array
		const buildingScope = []
		// Declare floor scope array
		const floorScope = []
		for (let y = 0; y < scopeDeliverable[x].children.length; y += 1) {
			for (let z = 0; z < scopeDeliverable[x].children[y].children.length; z += 1) {
				// Deconstruct object
				const {
					scopeId: chilldScopeId,
					title: childScopeName,
					scopeType,
					spaceLevel,
					scopeKey,
				} = scopeDeliverable[x].children[y].children[z]

				const data = {
					scopeId: chilldScopeId,
					scopeName: childScopeName,
					spaceLevel,
					scopeType,
					scopeKey,
				}

				// Here check if scope level is building, push that data in building scope array
				// or else check if scope level is floor, push that data in floor scope array
				// or else check if scope level is room, push that data in room scope array
				if (spaceLevel === 'building') {
					buildingScope.push(data)
				} else if (spaceLevel === 'floor') {
					floorScope.push(data)
				} else if (spaceLevel === 'room') {
					roomScope.push(data)
				}
			}
		}
		// Generate JSON data
		const data = {
			scopeId,
			scopeName,
			scopeDeliverable: {
				buildingScope,
				roomScope,
				floorScope,
			},
		}

		// Push that data in deliverable scope array
		deliverableTemplate.push(data)
	}

	return deliverableTemplate
}

/** Generate room scope deliverable list
 * @type - function
 * @param - roomScope,buildingIndex,floorIndex,roomIndex,scopeIndex,defaultSelectedScope
 * @summary - Using this parameters generate a room scope deliverable array
 * @return - roomScopeArray
 */
const roomScopeDeliverable = (
	roomScope,
	buildingIndex,
	floorIndex,
	roomIndex,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey,
	serviceScopeTypologyDeliverable
) => {
	// Declare roomscope arrray
	const roomScopeArray = []

	for (let x = 0; x < roomScope.length; x += 1) {
		const { scopeId, scopeType, scopeName, scopeKey } = roomScope[x]
		if (deliverableSelectedScope.includes(scopeId)) {
			const newScopeDeliverable = serviceScopeTypologyDeliverable.filter((data) => {
				if (data.scopeId === scopeId) {
					return data
				}
			})

			const { scopeHead, scopeHeadId, scopeStep, scopeStepId } = newScopeDeliverable[0]
			const data = {
				key: `0-${scopeIndex}-${buildingIndex}-${floorIndex}-${roomIndex}-${x}`,
				scopeId,
				title: scopeName,
				type: scopeType,
				scopeKey,
				scopeHead,
				scopeHeadId,
				scopeStep,
				scopeStepId,
				children: [],
			}
			// push data in roomscope array
			roomScopeArray.push(data)
			// push scopeIndex,buildingIndex,floorIndex,
			// roomIndex in defaultcheckedkey array because using this data
			//  we will set the selected deliverable in rctree
			defaultCheckedKey.push(
				`0-${scopeIndex}-${buildingIndex}-${floorIndex}-${roomIndex}-${x}`
			)
		}
	}

	// return roomScopeArray
	return roomScopeArray
}

// Generate floor scope deliverable list
/**
 * @type - function
 * @param - spaceDefine,buildingIndex,floorIndex,roomIndex,scopeIndex,defaultSelectedScope
 * @summary - Using this parameters generate a floor scope deliverable array
 * @return - floorScopeArray
 */
const floorScopeDeliverable = async (
	spaceDefine,
	floorScope,
	buildingIndex,
	roomScope,
	floorIndex,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey,
	serviceScopeTypologyDeliverable
) => {
	// Declare roomscope arrray
	const floorScopeArray = []
	let x = 0
	for (; x < floorScope.length; x += 1) {
		// Deconstruct object
		const { scopeId, scopeName, scopeType, scopeKey } = floorScope[x]
		if (deliverableSelectedScope.includes(scopeId)) {
			const newScopeDeliverable = serviceScopeTypologyDeliverable.filter((data) => {
				if (data.scopeId === scopeId) {
					return data
				}
			})

			const { scopeHead, scopeHeadId, scopeStep, scopeStepId } = newScopeDeliverable[0]

			const data = {
				key: `0-${scopeIndex}-${buildingIndex}-${floorIndex}-${x}`,
				scopeId,
				title: scopeName,
				type: scopeType,
				scopeKey,
				scopeHead,
				scopeHeadId,
				scopeStep,
				scopeStepId,
				children: [],
			}

			// push data in roomscope array
			floorScopeArray.push(data)
			// push scopeIndex,buildingIndex and floorIndex in defaultcheckedkey array
			//  because using this data we will set the selected deliverable in rctree
			defaultCheckedKey.push(`0-${scopeIndex}-${buildingIndex}-${floorIndex}-${x}`)
		}
	}

	if (
		spaceDefine[buildingIndex].children[floorIndex].children !== undefined &&
		roomScope.length > 0
	) {
		for (
			let z = x;
			z < x + spaceDefine[buildingIndex].children[floorIndex].children.length;
			z += 1
		) {
			const roomIndex = z - floorScope.length

			// Create data object
			const data = {
				key: `0-${scopeIndex}-${buildingIndex}-${floorIndex}-${z}`,
				scopeId: null,
				type: null,
				title: `${spaceDefine[buildingIndex].children[floorIndex].children[roomIndex].name}`,
				children: await roomScopeDeliverable(
					roomScope,
					buildingIndex,
					floorIndex,
					roomIndex,
					deliverableSelectedScope,
					scopeIndex,
					defaultCheckedKey,
					serviceScopeTypologyDeliverable
				),
			}

			// push data in roomscope array
			floorScopeArray.push(data)
		}
	}

	// return floorScopeArray
	return floorScopeArray
}

/** Generate building scope deliverable list
 * @type - function
 * @param - spaceDefine,buildingScope,buildingIndex,floorIndex,
 *          roomIndex,scopeIndex,defaultSelectedScope
 * @summary -  Using this parameters generate a building scope deliverable array
 * @return - buildingScopeArray
 */
const buildingScopeDeliverable = async (
	spaceDefine,
	buildingScope,
	floorScope,
	roomScope,
	buildingIndex,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey,
	serviceScopeTypologyDeliverable
) => {
	// Declare buildingscope array
	const buildingScopeArray = []
	let x = 0
	for (; x < buildingScope.length; x += 1) {
		// deconstruct buildingscope object
		const { scopeId, scopeName, scopeType, scopeKey } = buildingScope[x]

		if (deliverableSelectedScope.includes(scopeId)) {
			const newScopeDeliverable = serviceScopeTypologyDeliverable.filter((data) => {
				if (data.scopeId === scopeId) {
					return data
				}
			})

			const { scopeHead, scopeHeadId, scopeStep, scopeStepId } = newScopeDeliverable[0]

			const data = {
				key: `0-${scopeIndex}-${buildingIndex}-${x}`,
				scopeId,
				title: scopeName,
				type: scopeType,
				scopeKey,
				scopeHead,
				scopeHeadId,
				scopeStep,
				scopeStepId,
				children: [],
			}

			// push data in building scope array
			buildingScopeArray.push(data)
			// push scopeIndex and buildingIndex in defaultcheckedkey array
			// because using this data we will set the selected deliverable in rctree
			defaultCheckedKey.push(`0-${scopeIndex}-${buildingIndex}-${x}`)
		}
	}

	if (spaceDefine[buildingIndex].children !== undefined && floorScope.length > 0) {
		for (let y = x; y < x + spaceDefine[buildingIndex].children.length; y += 1) {
			const floorIndex = y - buildingScope.length

			// create result object
			const result = {
				key: `0-${scopeIndex}-${buildingIndex}-${y}`,
				scopeId: null,
				type: null,
				title: `${spaceDefine[buildingIndex].children[floorIndex].name}`,
				children: await floorScopeDeliverable(
					spaceDefine,
					floorScope,
					buildingIndex,
					roomScope,
					floorIndex,
					deliverableSelectedScope,
					scopeIndex,
					defaultCheckedKey,
					serviceScopeTypologyDeliverable
				),
			}
			// push data in building scope array
			buildingScopeArray.push(result)
		}
	}

	// return buildingscopearray
	return buildingScopeArray
}

// Generate deliverable list based on RCTree structure
/**
 * @type - function
 * @param - spaceDefine,buildingScope,buildingIndex,floorScope,
 *          roomScope,scopeIndex,defaultSelectedScope
 * @summary -  Using this parameters generate a deliverable list array
 * @return - deliverable tree data array
 */
const generateDeliverableList = async (
	spaceDefine,
	buildingScope,
	floorScope,
	roomScope,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey,
	serviceScopeTypologyDeliverable
) => {
	// declare deliverableTreeList array
	const deliverableTreeList = []
	// declare selected key array
	const defaultSelectedKey = []

	for (let x = 0; x < spaceDefine.length; x += 1) {
		// Create data object
		const data = {
			key: `0-${scopeIndex}-${x}`,
			scopeId: null,
			type: null,
			title: `${spaceDefine[x].name}`,
			children: await buildingScopeDeliverable(
				spaceDefine,
				buildingScope,
				floorScope,
				roomScope,
				x,
				deliverableSelectedScope,
				scopeIndex,
				defaultCheckedKey,
				serviceScopeTypologyDeliverable
			),
		}

		// push data in deliverable tree list array
		deliverableTreeList.push(data)
		// push scopeIndex in defaultcheckedkey array because using this data
		// we will set the selected deliverable in rctree
		defaultSelectedKey.push(`0-${scopeIndex}-${x}`)
	}

	// Assign deliverableTreeList and defaultSelectedKey in result object
	const result = {
		deliverableTreeList,
		defaultSelectedKey,
	}

	// return result
	return result
}

// Generate deliverable list
const generateList = async (
	spaceDefine,
	deliverableSelectedScope,
	serviceScopeDeliverable,
	feeHeadScope,
	serviceScopeTypologyDeliverable
) => {
	// Declare deliverable list array,defaultKey array and default checked key array
	const deliverableList = []
	let defaultKey = []
	const defaultCheckedKey = []

	for (let x = 0; x < serviceScopeDeliverable.length; x += 1) {
		// deconstruct serviceScopeDeliverable object
		const { scopeId, scopeName, scopeDeliverable } = serviceScopeDeliverable[x]
		if (feeHeadScope.includes(scopeId)) {
			// deconstruct scopeDeliverable object
			const { buildingScope, floorScope, roomScope } = scopeDeliverable

			// Generate deliverable list using building scope,floor scope,
			// room scope, space and deliverable
			const { deliverableTreeList, defaultSelectedKey } = await generateDeliverableList(
				spaceDefine,
				buildingScope,
				floorScope,
				roomScope,
				deliverableSelectedScope,
				x,
				defaultCheckedKey,
				serviceScopeTypologyDeliverable
			)

			const data = {
				key: `0-${x}`,
				scopeId: null,
				type: null,
				title: `${scopeName}`,
				children: deliverableTreeList,
			}

			deliverableList.push(data)
			defaultKey.push(`0-${x}`)
			defaultKey = [...defaultKey, ...defaultSelectedKey]
		}
	}

	// Create deliverable result object using deliverable list, default key and default checked key
	const deliverableResult = {
		spaceDeliverable: deliverableList,
		serviceDeliverableExpandedKey: defaultKey,
		serviceDeliverableCheckedKey: defaultCheckedKey,
	}

	// return deliverable result
	return deliverableResult
}

// Generate service deliverable list
export const generateServiceDeliverable = async (data, scope) => {
	// deconstruct scope object
	const {
		serviceDeliverableTemplate,
		serviceScopeCheckedId,
		serviceScopeTypology,
		serviceScopeTypologyDeliverable,
	} = scope

	// Get scopeId array using service scope typology
	const feeHeadScope = Object.keys(serviceScopeTypology).map((key) => {
		const value = serviceScopeTypology[key]
		return value.scopeId
	})

	// Deconstruct data object
	const { spaceEditorJson } = data

	// Generate deliverable list
	const {
		spaceDeliverable,
		serviceDeliverableCheckedKey,
		serviceDeliverableExpandedKey,
	} = await generateList(
		spaceEditorJson,
		serviceScopeCheckedId,
		serviceDeliverableTemplate,
		feeHeadScope,
		serviceScopeTypologyDeliverable
	)

	// Create result object
	const result = {
		spaceDeliverable,
		serviceDeliverableCheckedKey,
		serviceDeliverableExpandedKey,
	}

	// Return result
	return result
}

// Generate deliverable selected id using service deliverable and deliverable checked keys
/**
 * @type     - function
 * @param    - service deliverable and deliverable checked keys
 * @function - Generate a deliverable id using service deliverable and
 *             deliverable checked keys and return it
 * @return   - deliverable id array
 */
export const serviceDeliverableCheckedScopeId = (
	serviceDeliverable,
	serviceDeliverableCheckedKey
) => {
	// Declare deliverable scope array
	const deliverableScope = []

	// Loop through the service deliverable array
	for (let x = 0; x < serviceDeliverable.length; x += 1) {
		// Declare deliverable Id array
		const deliverableId = []

		for (let y = 0; y < serviceDeliverable[x].children.length; y += 1) {
			// Loop building scope details in service deliverable array
			for (let z = 0; z < serviceDeliverable[x].children[y].children.length; z += 1) {
				// Get service building key
				const buildingKey = serviceDeliverable[x].children[y].children[z].key

				// Here We check service building deliverable key is exist in deliverable checked array
				// If exist push that data in deliverable id array otherwise not

				if (serviceDeliverableCheckedKey.includes(buildingKey)) {
					// Deconstruct service deliverable
					const {
						scopeId,
						title: scopeName,
						type: scopeType,
						scopeKey,
						scopeStepId,
						scopeStepName,
						scopeHead,
						scopeHeadId,
					} = serviceDeliverable[x].children[y].children[z]

					const { title } = serviceDeliverable[x].children[y]

					// Here we check if scopeId is not null then
					// we push that data in deliverable id array otherwise not
					if (scopeId !== null) {
						const data = {
							title,
							scopeId,
							scopeName,
							scopeType,
							scopeKey,
							scopeStepId,
							scopeStepName,
							scopeHead,
							scopeHeadId,
							type: 'building',
						}
						deliverableId.push(data)
					}
				}

				// loop floor scope details in service deliverable array
				for (
					let a = 0;
					a < serviceDeliverable[x].children[y].children[z].children.length;
					a += 1
				) {
					// Get service floor key
					const floorKey = serviceDeliverable[x].children[y].children[z].children[a].key

					// Here We check service floor deliverable key is exist in deliverable checked array
					// If exist push that data in deliverable id array otherwise not

					if (serviceDeliverableCheckedKey.includes(floorKey)) {
						// Deconstruct service deliverable
						const {
							scopeId,
							title: scopeName,
							type: scopeType,
							scopeKey,
							scopeStepId,
							scopeStepName,
							scopeHead,
							scopeHeadId,
						} = serviceDeliverable[x].children[y].children[z].children[a]
						const { title } = serviceDeliverable[x].children[y].children[z]

						// Here we check if scopeId is not null then
						//  we push that data in deliverable id array otherwise not
						if (scopeId !== null) {
							const data = {
								title,
								scopeId,
								scopeName,
								scopeType,
								scopeKey,
								scopeStepId,
								scopeStepName,
								scopeHead,
								scopeHeadId,
								type: 'floor',
							}
							deliverableId.push(data)
						}
					}

					// Loop room scope details in service deliverable array
					for (
						let b = 0;
						b < serviceDeliverable[x].children[y].children[z].children[a].children.length;
						b += 1
					) {
						// Get service room key
						const roomKey =
							serviceDeliverable[x].children[y].children[z].children[a].children[b].key

						// Here We check service room deliverable key is exist in deliverable checked array
						// If exist push that data in deliverable id array otherwise not
						if (serviceDeliverableCheckedKey.includes(roomKey)) {
							// Deconstruct service deliverable
							const {
								scopeId,
								title: scopeName,
								type: scopeType,
								scopeKey,
								scopeStepId,
								scopeStepName,
								scopeHead,
								scopeHeadId,
							} = serviceDeliverable[x].children[y].children[z].children[a].children[b]

							const { title } = serviceDeliverable[x].children[y].children[z].children[a]

							// Here we check if scopeId is not null then
							// we push that data in deliverable id array otherwise not
							if (scopeId !== null) {
								const data = {
									title,
									scopeId,
									scopeType,
									scopeName,
									scopeKey,
									scopeStepId,
									scopeStepName,
									scopeHead,
									scopeHeadId,
									type: 'room',
								}
								deliverableId.push(data)
							}
						}
					}
				}
			}
		}

		// Generate response data
		const result = {
			scopeName: serviceDeliverable[x].title,
			scopeData: deliverableId,
		}

		// Push data in deliverable scope array
		deliverableScope.push(result)
	}

	return deliverableScope
}

// Service stage config details
export const serviceStageConfigData = (data, stateData) => {
	// Deconstruct data object
	const { serviceScopeTypologyStep, serviceScopeTypology } = data
	const { serviceStageConfig, serviceDeliverable } = stateData
	const { serviceDeliverableCheckedId } = serviceDeliverable
	const { stageStepSource, errorStatus, retainerType } = serviceStageConfig

	const deliverableCheckedId = serviceDeliverableCheckedId.map(function(item) {
		return item.scopeData.map((value) => {
			return value.scopeStepId
		})
	})

	const newDeliverableCheckedId = [].concat.apply([], deliverableCheckedId)

	const stageTarget = []
	for (let x = 0; x < serviceScopeTypologyStep.length; x++) {
		const {
			scopeId,
			scopeHead,
			scopeName,
			scopePredecessor,
			scopeKey,
		} = serviceScopeTypologyStep[x]

		// Create object
		const object = {
			scopeHead,
			scopeName,
			scopeId,
			scopePredecessor,
			scopeKey,
			scopeType: 'step',
		}

		stageTarget.push(object)
		// }
	}

	if (stageStepSource.length <= 3) {
		// Declare stage source array
		const stageSource = []

		// Retainer Stage
		const retainerStage = {
			scopeSelected: true,
			scopeName: 'Retainer',
			scopeId: `stage_${1}`,
			scopeType: 'stage',
			scopeIndex: 1,
			scopePercentage: 10,
			scopeDescription: '',
			stageOrder: true,
		}

		// Push retainerStage object in stage source array
		stageSource.push(retainerStage)

		// split remaining stage percentage details
		for (let index = 0; index < serviceScopeTypology.length; index += 1) {
			const percentageArray = [80, 60, 50, 40, 30, 20]
			const object = {
				scopeSelected: false,
				scopeName: `stage ${index + 1}`,
				scopeId: `stage_${index + 2}`,
				scopeType: 'stage',
				scopeIndex: index + 2,
				scopeDescription: null,
				scopePercentage: percentageArray[serviceScopeTypology.length - index - 1],
				stageOrder: true,
			}

			// Push stage object in stage source array
			stageSource.push(object)
		}

		// Completion Stage
		const completionStage = {
			scopeSelected: true,
			scopeName: 'Completion',
			scopeId: `stage_${stageSource.length + 1}`,
			scopeType: 'stage',
			scopeIndex: stageSource.length + 1,
			scopeDescription: '',
			scopePercentage: 100,
			stageOrder: true,
		}

		// Push completionStage object in stage source array
		stageSource.push(completionStage)

		// Begin Stage
		const beginObject = {
			scopeSelected: false,
			scopeId: 'begin',
			scopeName: 'Begin Construction',
			scopeType: 'begin',
		}

		// Push beginObject object in stage source array
		stageSource.push(beginObject)

		// Create stage target and stage source object
		const result = {
			stageStepTarget: stageTarget,
			stageStepSource: stageSource,
			errorStatus,
			retainerType: 'percentage',
		}
		// return result object
		return result
	} else {
		let newStageSource = Object.assign({}, stageStepSource)
		for (let x = 0; x < stageStepSource.length; x++) {
			const { scopeName } = stageStepSource[x]

			if (x == 0 || x === stageStepSource.length - 2) {
				newStageSource[x].scopeSelected = true
			} else {
				newStageSource[x].scopeSelected = false
			}
		}

		let newErrorStatus = Object.assign({}, errorStatus)
		newErrorStatus.selectionError.error = true
		// Create stage target and stage source object
		const result = {
			stageStepTarget: stageTarget,
			stageStepSource: Object.values(newStageSource),
			errorStatus: newErrorStatus,
			retainerType,
		}
		// return result object
		return result
	}
}

// Change Retainer payment type
export const addRetainerPaymentType = (data) => {
	// Deconstruct data object
	const { stageStepSource, stageStepTarget } = data

	// Create stage target and stage source object
	const result = {
		stageStepSource,
		stageStepTarget,
	}

	// return result object
	return result
}

// Add new stage in stage list
export const addNewStageData = (data) => {
	// Deconstruct data object
	const { stageStepSource, stageStepTarget } = data

	// Get retainer and stage list
	const stageStepSourceCopy = stageStepSource.slice(0, stageStepSource.length - 2)

	// Get stage completion
	const completeObject = stageStepSource[stageStepSource.length - 2]

	// Get stage begin
	const beginObject = stageStepSource[stageStepSource.length - 1]

	// Create newly added stage object
	const object = {
		scopeSelected: false,
		scopeName: `stage ${stageStepSourceCopy.length}`,
		scopeId: `stage_${stageStepSourceCopy[stageStepSourceCopy.length - 1].scopeIndex +
			1}`,
		scopeType: 'stage',
		scopeIndex: stageStepSourceCopy[stageStepSourceCopy.length - 1].scopeIndex + 1,
		scopePercentage: 0,
		stageOrder: true,
	}

	// Push newly added object in  stage step source
	stageStepSourceCopy.push(object)

	// Add scopeId in complete object
	completeObject.scopeId = `stage_${stageStepSourceCopy[stageStepSourceCopy.length - 1]
		.scopeIndex + 1}`
	completeObject.scopeIndex =
		stageStepSourceCopy[stageStepSourceCopy.length - 1].scopeIndex + 1

	// After pushing new stage in stage source and add completion details
	stageStepSourceCopy.push(completeObject)

	// After pushing completion detains in stage source and add begin object details
	stageStepSourceCopy.push(beginObject)

	// Create result stage object
	const result = {
		stageStepSource: stageStepSourceCopy,
		stageStepTarget,
	}

	// return result
	return result
}

// Remove stage in service stage config
export const removeNewStageData = (data, deliverableList, value) => {
	// deconstruct data object
	const { stageStepSource, stageStepTarget } = data
	const { arrayStageDeliverable, objectStageDeliverable } = deliverableList
	// deconstruct value object
	const { scopeId } = value

	// filter scope Id
	const newSource = stageStepSource.filter((element, index) => {
		if (index !== stageStepSource.length) {
			return element.scopeId !== scopeId
		}
	})

	for (let y = 0; y < stageStepTarget.length; y++) {
		const { scopeType, scopeId } = stageStepTarget[y]
		if (scopeType === 'stage') {
			const newTarget = newSource.filter((data) => {
				return data.scopeId === scopeId
			})
			stageStepTarget[y].scopeDescription = newTarget[0].scopeDescription
			stageStepTarget[y].scopeName = newTarget[0].scopeName
		}
	}

	for (let x = 0; x < arrayStageDeliverable.length; x++) {
		const { scopeType, scopeId } = arrayStageDeliverable[x]
		if (scopeType === 'stage') {
			const newTarget = newSource.filter((data) => {
				return data.scopeId === scopeId
			})
			arrayStageDeliverable[x].scopeDescription = newTarget[0].scopeDescription
			arrayStageDeliverable[x].scopeName = newTarget[0].scopeName
		}
	}

	for (let y = 0; y < objectStageDeliverable.length; y++) {
		const { scopeId } = objectStageDeliverable[y]

		const newTarget = newSource.filter((data) => {
			return data.scopeId === scopeId
		})
		if (newTarget.length) {
			objectStageDeliverable[y].scopeDescription = newTarget[0].scopeDescription
			objectStageDeliverable[y].scopeName = newTarget[0].scopeName
		}
	}

	// Create result object
	const result = {
		stageStepSource: newSource,
		stageStepTarget,
	}

	const deliverableResult = {
		objectStageDeliverable,
		arrayStageDeliverable,
	}

	// return result
	return { result, deliverableResult }
}

// Add percentage in source
export const addNewPercentageData = (data, value, evt) => {
	// Deconstruct data object
	const { stageStepSource, stageStepTarget } = data

	// Deconstruct value object
	const { scopeId } = value

	// Get stage percentage

	const percentage = evt.target.value ? parseInt(evt.target.value) : 0

	// Identify the changed index and set that percentage
	const changedIndex = stageStepSource.find((element) => element.scopeId === scopeId)

	const newSource = stageStepSource

	for (let x = 0; x < newSource.length - 1; x += 1) {
		if (newSource[x].scopeId === changedIndex.scopeId) {
			newSource[x].scopePercentage = percentage
		}
	}

	// Create result object
	const result = {
		stageStepSource: newSource,
		stageStepTarget,
	}

	// return result
	return result
}

// Drag service steps
export const dragServiceStageData = (data, value) => {
	const { stageStepSource, stageStepTarget } = data

	const stageTargetData = value.map((val) => JSON.parse(val))

	// Remove duplicate stage in deliverable list
	const stageNewTarget = stageTargetData.filter((element, index) => {
		const { scopeId } = element
		const { scopeType } = element
		return scopeType === 'stage' || scopeType === 'begin'
			? index === stageTargetData.findIndex((obj) => obj.scopeId === scopeId)
			: true
	})

	// Identify the new stage comes in deliverable list using newData list
	const uniqueResultOne = stageTargetData.filter((obj) => {
		if (obj.scopeType === 'stage' || obj.scopeType === 'begin') {
			return !stageStepTarget.some((obj2) => {
				if (obj2.scopeType === 'stage' || obj.scopeType === 'begin') {
					return obj.scopeId === obj2.scopeId
				}
				return false
			})
		}
		return false
	})

	// Identify the new stage comes in deliverable list using oldData list
	const uniqueResultTwo = stageStepTarget.filter((obj) => {
		if (obj.scopeType === 'stage' || obj.scopeType === 'begin') {
			return !stageTargetData.some((obj2) => {
				if (obj2.scopeType === 'stage' || obj2.scopeType === 'begin') {
					return obj.scopeId === obj2.scopeId
				}
				return false
			})
		}
		return false
	})

	// Concate newData list and oldData list,
	// Identify the newly existing stage comes in our deliverable list
	const stepNewStage = uniqueResultOne.concat(uniqueResultTwo)
	let stageStatus = false

	if (stepNewStage.length) {
		stageStatus = !!(
			stepNewStage[0].scopeIndex === 1 ||
			stepNewStage[0].scopeIndex ===
				stageStepSource[stageStepSource.length - 2].scopeIndex
		)

		const newScopeId = stepNewStage[0].scopeId
		const stageIndex = stepNewStage[0].scopeIndex

		let status = false

		for (let y = 0; y < stageTargetData.length; y += 1) {
			const oldScopeId = stageTargetData[y].scopeId
			if (newScopeId === oldScopeId) {
				status = true
			}
		}

		for (let y = 0; y < stageStepSource.length; y += 1) {
			const sourceStageIndex = stageStepSource[y].scopeIndex
			if (stageIndex === sourceStageIndex) {
				stageStepSource[y].scopeSelected = !!status
			}
		}
	}

	const stageTarget = stageTargetData

	if (stageTarget.length) {
		let max = 0
		let status = true
		let starting = true
		for (let j = 0; j < stageTarget.length; j += 1) {
			const type = stageTarget[j].scopeType

			if (type === 'stage') {
				if (starting === true) {
					max = stageTarget[j].scopeIndex
					starting = false
				}
				if (status) {
					if (max <= stageTarget[j].scopeIndex) {
						max = stageTarget[j].scopeIndex
						stageTarget[j].stageOrder = true
					} else {
						status = false
						stageTarget[j].stageOrder = false
					}
				} else {
					stageTarget[j].stageOrder = false
				}
			}
		}
	}

	// Here if there is any changes occured update the new stage source and new target
	// Otherwise load the old stage source and stage target
	const result = {
		stageStepSource: stageStatus ? data.stageStepSource : stageStepSource,
		stageStepTarget: stageStatus ? data.stageStepTarget : stageNewTarget,
	}

	// return result
	return result
}

// Stage total percentage and stage status
const stagePercentageError = (data, retainerType) => {
	// Deconstruct data object
	const { stageStepSource } = data

	// Declare stage total percentage and stage previous percentage is zero
	let stageTotalPercentage = 0
	let stagePreviousPercentage = 0

	// Here check if stage step source is exist
	if (stageStepSource.length) {
		for (let j = 0; j < stageStepSource.length - 1; j += 1) {
			// Here check retainer type is percentage or rupee
			if (retainerType === 'percentage') {
				const currentPercentage = stageStepSource[j].scopePercentage
				if (currentPercentage !== 0) {
					const newPercentage = currentPercentage - stagePreviousPercentage
					stagePreviousPercentage = currentPercentage
					stageTotalPercentage += newPercentage
				}
			} else if (retainerType === 'rupees' && j !== 0) {
				const currentPercentage = stageStepSource[j].scopePercentage
				const newPercentage = currentPercentage - stagePreviousPercentage
				stagePreviousPercentage = currentPercentage
				stageTotalPercentage += newPercentage
			}
		}
	}

	// Here check if stage total percentage is equal to 100
	// return error status true or otherwise return false
	const result = {
		error: stageTotalPercentage !== 100,
		total: stageTotalPercentage,
		message: `Stage Percentage ${stageTotalPercentage}`,
	}

	// return result
	return result
}

// Here check stage order is mismatch or not
const stageOrderError = (data) => {
	// Deconstruct data object
	const { stageStepTarget } = data

	const stageTarget = stageStepTarget.filter(
		(element) => element.scopeType === 'stage' && element.stageOrder === false
	)

	const result = {
		error: !!stageTarget.length,
		message: stageTarget.length ? 'Stage Order Success' : 'Stage Order Mismatch',
	}

	// return result
	return result
}

// Check all stage is selected or not in stage config
const stageSelectionError = (data) => {
	// Deconstruct data object
	const { stageStepSource, stageStepTarget } = data

	const stageTarget = stageStepTarget.filter(
		(element) => element.scopeType === 'stage' || element.scopeType === 'begin'
	)

	const selectionStatus = stageStepSource.length - 2 === stageTarget.length

	const result = {
		error: !selectionStatus,
		message: selectionStatus ? 'All Stage Selected' : 'Please Select all Stage',
	}

	// return result
	return result
}

// Get the stage details and return stage error details
export const stageError = async (data, retainerType) => {
	const percentageError = await stagePercentageError(data, retainerType)
	const selectionError = await stageSelectionError(data)
	const orderError = await stageOrderError(data)

	return {
		percentageError,
		selectionError,
		orderError,
	}
}

// group scope deliverable versions
const groupByDeliverableVersion = (stageDeliverableArray) => {
	const result = stageDeliverableArray.reduce((resultArray, data) => {
		// eslint-disable-next-line no-param-reassign
		resultArray[data.scopeKey] = resultArray[data.scopeKey] || []
		resultArray[data.scopeKey].push(data)
		return resultArray
	}, Object.create(null))

	const finalArray = []

	// eslint-disable-next-line no-restricted-syntax
	// eslint-disable-next-line guard-for-in
	for (const p in result) {
		const innerObj = result[p]
		for (let k = 0; k < innerObj.length; k += 1) {
			finalArray.push(innerObj[k])
		}
	}

	// return array
	return finalArray
}

// Service deliverable list
export const serviceDeliverableList = async (data, deliverable) => {
	// Deconstruct object
	const { stageStepTarget, stageStepSource } = data
	const { serviceDeliverableCheckedId } = deliverable

	// Declare deliverable array and stage array
	let deliverableArray = []
	let lastStage
	const stageArray = []

	for (let x = 0; x < stageStepTarget.length; x += 1) {
		const {
			scopeKey,
			scopeType,
			scopeName,
			scopeHead,
			scopeId,
			scopePredecessor,
		} = stageStepTarget[x]

		if (scopeType === 'step') {
			for (let y = 0; y < serviceDeliverableCheckedId.length; y += 1) {
				for (let z = 0; z < serviceDeliverableCheckedId[y].scopeData.length; z += 1) {
					const scopeKeyDeliverable = serviceDeliverableCheckedId[y].scopeData[z].scopeKey

					if (scopeKeyDeliverable.includes(scopeKey)) {
						serviceDeliverableCheckedId[y].scopeData[z].scopeHead = scopeHead
						serviceDeliverableCheckedId[y].scopeData[z].scopeStepName = scopeName
						serviceDeliverableCheckedId[y].scopeData[z].scopeStepId = scopeId
						serviceDeliverableCheckedId[y].scopeData[
							z
						].scopePredecessor = scopePredecessor
						serviceDeliverableCheckedId[y].scopeData[z].stageId = scopeId
						deliverableArray.push(serviceDeliverableCheckedId[y].scopeData[z])
					}
				}
			}
		} else if (scopeType === 'stage') {
			const { scopeDescription } = stageStepTarget[x]

			for (let x = 0; x < deliverableArray.length; x++) {
				deliverableArray[x].stageId = scopeId
			}

			lastStage = scopeId

			const stage = {
				scopeName,
				scopeId,
				scopeDescription,
				stageDeliverable: deliverableArray,
			}
			stageArray.push(stage)
			deliverableArray = []
		}
	}

	const newResult = []

	for (let x = 0; x < deliverableArray.length; x++) {
		deliverableArray[x].stageId = lastStage
	}

	if (stageArray.length) {
		stageArray[stageArray.length - 1].stageDeliverable = [
			...stageArray[stageArray.length - 1].stageDeliverable,
			...deliverableArray,
		]

		for (let z = 0; z < stageArray.length; z += 1) {
			const { scopeName, stageDeliverable, scopeDescription, scopeId } = stageArray[z]

			// Group by deliverable version
			const deliverableList = await groupByDeliverableVersion(stageDeliverable)

			for (let x = 0; x < deliverableList.length; x += 1) {
				newResult.push(deliverableList[x])
			}

			const object = {
				scopeName,
				scopeId,
				scopeDescription,
				scopeLength: deliverableList.length,
				scopeType: 'stage',
			}

			newResult.push(object)

			stageArray[z].stageDeliverable = deliverableList
			stageArray[z].deliverableLength = deliverableList.length
		}
	}

	for (let x = 0; x < newResult.length; x++) {
		const { scopeType, scopeId } = newResult[x]
		if (scopeType === 'stage') {
			const newTarget = stageStepSource.filter((data) => {
				return data.scopeId === scopeId
			})
			newResult[x].scopeDescription = newTarget[0].scopeDescription
			newResult[x].scopeName = newTarget[0].scopeName
		}
	}

	for (let y = 0; y < stageArray.length; y++) {
		const { scopeId } = stageArray[y]

		const newTarget = stageStepSource.filter((data) => {
			return data.scopeId === scopeId
		})
		if (newTarget.length) {
			stageArray[y].scopeDescription = newTarget[0].scopeDescription
			stageArray[y].scopeName = newTarget[0].scopeName
		}
	}

	const stageResult = {
		arrayStageDeliverable: newResult,
		objectStageDeliverable: stageArray,
	}

	return stageResult
}

// Get typology name using typology id
export const getTypologyName = (typology, typologyId) => {
	// eslint-disable-next-line max-len
	const typologyName = typology.filter((obj) =>
		obj.typologyId === typologyId ? obj.typologyName : null
	)

	return typologyName[0].typologyName
}

// Validate service result
// Here check service name, fee head name, cover title, elastic price all are valid or not
const validateServiceResultInput = (data) => {
	const { serviceName, feeHeadName, coverTitle, elasticPrice } = data

	let serviceStatus = false
	let feeHeadNameStatus = false
	let coverTitleStatus = false
	let elasticStatus = false
	serviceStatus = !!serviceName
	feeHeadNameStatus = !!feeHeadName
	coverTitleStatus = !!coverTitle

	for (let x = 0; x < elasticPrice.length; x += 1) {
		const { sqft, amount, time } = elasticPrice[x]

		if (
			sqft === 0 ||
			sqft === '' ||
			sqft === undefined ||
			sqft === null ||
			amount === 0 ||
			amount === '' ||
			amount === undefined ||
			amount === null ||
			time === 0 ||
			time === '' ||
			time === undefined ||
			time === null
		) {
			elasticStatus = false
		} else {
			elasticStatus = true
		}
	}

	// return service status
	return !!(serviceStatus && elasticStatus && feeHeadNameStatus && coverTitleStatus)
}

// Add service elastic square feet
export const addServiceElasticSqft = async (data, index, evt) => {
	// Deconstruct object
	const {
		serviceName,
		elasticPrice,
		feeHeadName,
		coverTitle,
		paymentPercentage,
	} = data.serviceResult

	const newElasticPrice = elasticPrice.map((item, i) => {
		if (index !== i) return item
		return { ...item, sqft: evt.target.value }
	})

	// Create result object
	const result = {
		serviceName,
		feeHeadName,
		coverTitle,
		paymentPercentage,
		elasticPrice: newElasticPrice,
	}

	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Add service elastic amount
export const addServiceElasticAmount = async (data, index, evt) => {
	// Deconstruct object
	const {
		serviceName,
		elasticPrice,
		feeHeadName,
		coverTitle,
		paymentPercentage,
	} = data.serviceResult

	const newElasticPrice = elasticPrice.map((item, i) => {
		if (index !== i) return item
		return { ...item, amount: evt.target.value.replace(/\D/g, '') }
	})

	// Create result object
	const result = {
		serviceName,
		feeHeadName,
		coverTitle,
		paymentPercentage,
		elasticPrice: newElasticPrice,
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Add service elastic time
export const addServiceElasticTime = async (data, index, evt) => {
	// Deconstruct object
	const {
		serviceName,
		elasticPrice,
		feeHeadName,
		coverTitle,
		paymentPercentage,
	} = data.serviceResult

	const newElasticPrice = elasticPrice.map((item, i) => {
		if (index !== i) return item
		return { ...item, time: evt.target.value.replace(/\D/g, '') }
	})

	// Create result object
	const result = {
		serviceName,
		feeHeadName,
		coverTitle,
		paymentPercentage,
		elasticPrice: newElasticPrice,
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Add service name
export const addServiceName = async (data, evt) => {
	// Deconstruct object
	const { elasticPrice, feeHeadName, coverTitle, paymentPercentage } = data.serviceResult

	// Create result object
	const result = {
		serviceName: evt.target.value,
		elasticPrice,
		feeHeadName,
		coverTitle,
		paymentPercentage,
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Add service fee head name
export const addFeeHeadName = async (data, evt) => {
	// Deconstruct object
	const { elasticPrice, serviceName, coverTitle, paymentPercentage } = data.serviceResult

	// Create result object
	const result = {
		serviceName,
		elasticPrice,
		feeHeadName: evt.target.value,
		coverTitle,
		paymentPercentage,
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Change service payment percentage
export const changePaymentPercentage = async (data, evt) => {
	// Deconstruct object
	const {
		elasticPrice,
		serviceName,
		coverTitle,
		paymentPercentage,
		feeHeadName,
	} = data.serviceResult

	const { estimationCostPerSqft } = paymentPercentage

	const payment = {
		percentage: parseInt(evt.target.value.replace(/\D/g, '')),
		estimationCostPerSqft,
	}

	// Create result object
	const result = {
		serviceName,
		elasticPrice,
		coverTitle,
		feeHeadName,
		paymentPercentage: payment,
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// change service estimation cost per sqft
export const changeEstimationCostPerSqft = async (data, evt) => {
	// Deconstruct object
	const {
		elasticPrice,
		serviceName,
		coverTitle,
		paymentPercentage,
		feeHeadName,
	} = data.serviceResult

	const { percentage } = paymentPercentage

	// Here check if payment percentage is empty return zero otherwise return percentage
	const payment = {
		percentage,
		estimationCostPerSqft: evt.target.value
			? parseInt(evt.target.value.replace(/\D/g, ''))
			: 0,
	}

	// Create result object
	const result = {
		serviceName,
		elasticPrice,
		feeHeadName,
		coverTitle,
		paymentPercentage: payment,
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Add service cover title
export const addCoverTitle = async (data, evt) => {
	// Deconstruct object
	const { elasticPrice, feeHeadName, serviceName, paymentPercentage } = data.serviceResult

	// Create result object
	const result = {
		serviceName,
		elasticPrice,
		feeHeadName,
		paymentPercentage,
		coverTitle: evt.target.value,
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Remove service elastic price
export const removeServiceElasticPriceRow = async (data, rowId) => {
	// Deconstruct object
	const {
		serviceName,
		elasticPrice,
		paymentPercentage,
		feeHeadName,
		coverTitle,
	} = data.serviceResult

	// Create result object
	const result = {
		serviceName,
		paymentPercentage,
		feeHeadName,
		coverTitle,
		elasticPrice: elasticPrice.filter((element, id) => rowId !== id),
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// Add service elastic price
export const addServiceElasticPriceRow = async (data) => {
	// Deconstruct object
	const {
		serviceName,
		elasticPrice,
		paymentPercentage,
		feeHeadName,
		coverTitle,
	} = data.serviceResult

	// Create result object
	const result = {
		serviceName,
		paymentPercentage,
		feeHeadName,
		coverTitle,
		elasticPrice: elasticPrice.concat([{ sqft: '', amount: '', time: '' }]),
	}
	// Validate result object
	result.error = await validateServiceResultInput(result)

	// return result
	return result
}

// CHange deliverable list
export const changeDeliverableList = (props, data) => {
	// Deconstruct object
	const { arrayNewStageDeliverable } = data
	const {
		arrayStageDeliverable,
		objectStageDeliverable,
	} = props.serviceStageDeliverableList

	// Here check if there is any changes in deliverable or not
	const changeHappen = arrayNewStageDeliverable.length === arrayStageDeliverable.length
	if (changeHappen) {
		// Declare array
		const objectStageArray = []
		let deliverableArray = []
		for (let i = 0; i < arrayNewStageDeliverable.length; i += 1) {
			deliverableArray.push(arrayNewStageDeliverable[i])

			// Deconstruct object
			const { scopeType, scopeName, scopeId } = arrayNewStageDeliverable[i]

			// Here check only if scope type is stage
			if (scopeType === 'stage') {
				deliverableArray = deliverableArray.filter((item) => item.scopeType !== 'stage')
				const result = {
					scopeName,
					scopeId,
					deliverableLength: deliverableArray.length,
					stageDeliverable: deliverableArray,
				}

				objectStageArray.push(result)
				deliverableArray = []
			}

			if (i === arrayNewStageDeliverable.length - 1) {
				if (deliverableArray.length) {
					const objectStageLength = objectStageArray.length

					const newDeliverable = [
						...objectStageArray[objectStageLength - 1].stageDeliverable,
						...deliverableArray,
					]

					objectStageArray[objectStageLength - 1].stageDeliverable = newDeliverable
					objectStageArray[objectStageLength - 1].deliverableLength =
						newDeliverable.length
				}
			}
		}

		let tempScopeId
		let newStageId
		for (let x = 0; x < objectStageArray.length; x++) {
			const { scopeId, stageDeliverable } = objectStageArray[x]
			for (let y = 0; y < stageDeliverable.length; y++) {
				const { stageId } = stageDeliverable[y]

				if (scopeId != stageId) {
					const { scopeId: altScopeId } = objectStageArray[x].stageDeliverable[y]

					newStageId = scopeId
					tempScopeId = altScopeId

					objectStageArray[x].stageDeliverable[y].stageId = scopeId
				}
			}
		}

		for (let x = 0; x < arrayNewStageDeliverable.length; x++) {
			const { scopeId } = arrayNewStageDeliverable[x]
			if (scopeId === tempScopeId) {
				arrayNewStageDeliverable[x].stageId = newStageId
			}
		}

		// create result data object
		const resultData = {
			arrayStageDeliverable: arrayNewStageDeliverable,
			objectStageDeliverable: objectStageArray,
		}

		// return result data
		return resultData
	}

	// Create resultData object
	const resultData = {
		arrayStageDeliverable,
		objectStageDeliverable,
	}

	// return result
	return resultData
}

export const serviceStageDescription = (stageList) => {
	let status = true
	stageList.map((stage, index) => {
		if (index !== 0 && stageList.length > index + 2) {
			const { scopeDescription } = stage
			if (
				scopeDescription === '' ||
				scopeDescription === undefined ||
				scopeDescription === null
			) {
				status = false
			}
		}
	})

	return status
}

export const serviceTeamDescriptionValidate = (teamDescriptionList) => {
	let status = true
	teamDescriptionList.map((team) => {
		const { title, description } = team
		if (title === '' || description === '') {
			status = false
		}
	})

	return status
}

export const changeStageTarget = (
	serviceStageConfig,
	serviceDeliverable,
	serviceScope
) => {
	const { errorStatus, retainerType, stageStepSource } = serviceStageConfig
	const { serviceScopeTypologyStep } = serviceScope

	const { serviceDeliverableCheckedId } = serviceDeliverable
	const deliverableCheckedId = serviceDeliverableCheckedId.map(function(item) {
		return item.scopeData.map((value) => {
			return value
		})
	})

	const newDeliverableCheckedId = [].concat.apply([], deliverableCheckedId)
	const stageTarget = []

	if (!serviceStageConfig.stageStepTarget.length) {
		for (let x = 0; x < serviceScopeTypologyStep.length; x++) {
			const {
				scopeId,
				scopeHead,
				scopeName,
				scopePredecessor,
				scopeKey,
			} = serviceScopeTypologyStep[x]

			if (newDeliverableCheckedId.some((stepId) => stepId.scopeStepId === scopeId)) {
				// Create object
				const object = {
					scopeHead,
					scopeName,
					scopeId,
					scopePredecessor,
					scopeKey,
					scopeType: 'step',
				}
				stageTarget.push(object)
			}
		}
	} else {
		for (let x = 0; x < serviceStageConfig.stageStepTarget.length; x++) {
			const {
				scopeId,
				scopeHead,
				scopeName,
				scopePredecessor,
				scopeKey,
				scopeType,
			} = serviceStageConfig.stageStepTarget[x]

			if (scopeType === 'step') {
				if (newDeliverableCheckedId.some((stepId) => stepId.scopeStepId === scopeId)) {
					// Create object
					const object = {
						scopeHead,
						scopeName,
						scopeId,
						scopePredecessor,
						scopeKey,
						scopeType: 'step',
					}
					stageTarget.push(object)
				}
			} else {
				stageTarget.push(serviceStageConfig.stageStepTarget[x])
			}
		}
	}

	const data = {
		errorStatus,
		retainerType,
		stageStepSource,
		stageStepTarget: stageTarget,
	}

	return data
}

/**this function is used to replace the time in our required format */
export function truncateTime(time) {
	return time
		.replace(/half a minute/g, '30 secs')
		.replace(/less than\s/g, '')
		.replace(/less than a\s/g, ' 1')
		.replace(/\sseconds/g, ' secs')
		.replace(/\ssecond/g, ' sec')
		.replace(/\sminutes/g, ' mins')
		.replace(/\sminute/g, ' min')
		.replace(/\sdays/g, ' d')
		.replace(/\sday/g, ' d')
		.replace(/\shours/g, ' hrs')
		.replace(/\shour/g, ' hr')
		.replace(/\smonths/g, ' mo')
		.replace(/\smonth/g, ' mo')
		.replace(/\syears/g, ' yrs')
		.replace(/\syear/g, ' yr')
		.replace(/about\s/g, '')
		.replace(/over\s/g, '')
		.replace(/almost\s/g, '')
}
export function showMofiedRelativeTime(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	return `${truncateTime(relativeTimeValue)} ago`
}
/**this function is used to convert the timestamp into relative time */
export function formatRelativetimestamp(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	const getYear = format(fromUnixTime(timestamp), 'yy')
	const currentYear = format(new Date(), 'yy')
	if (
		relativeTimeValue.includes('day') ||
		relativeTimeValue.includes('year') ||
		relativeTimeValue.includes('month')
	) {
		if (getYear === currentYear) {
			return format(fromUnixTime(timestamp), 'MMM d')
		} else {
			return format(fromUnixTime(timestamp), 'dd-MMM-yy')
		}
	} else {
		return `${truncateTime(relativeTimeValue)} ago`
	}
}

/**this function is used to convert the timestamp into relative time */
export function formatChatRelativetimestamp(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	const getYear = format(fromUnixTime(timestamp), 'yy')
	const currentYear = format(new Date(), 'yy')
	if (
		relativeTimeValue.includes('day') ||
		relativeTimeValue.includes('year') ||
		relativeTimeValue.includes('month')
	) {
		if (getYear === currentYear) {
			return format(fromUnixTime(timestamp), 'MMM d')
		} else {
			return format(fromUnixTime(timestamp), 'dd-MMM-yy')
		}
	} else {
		return format(fromUnixTime(timestamp), 'hh:mm a')
	}
}
/**fucntion to get relative time */
export function relativeTime(timestamp, addSuffix = false) {
	if (typeof timestamp === 'string') {
		timestamp = Number(timestamp)
	}
	if (typeof timestamp !== 'number') {
		return timestamp
	}
	return formatDistanceToNow(new Date(timestamp * 1000), {
		addSuffix,
		includeSeconds: true,
	})
}

// Move deliverable from one stage to another stage
export const changeDeliverableStageData = (data) => {
	const {
		arrayStage,
		objectStage,
		newStageId,
		arrayIndex,
		objectIndex,
		checkBoxIndex,
	} = data

	const selectedStageScopeId = checkBoxIndex.map((data) => {
		return arrayStage[data].scopeId
	})

	const newStageArray = []
	const newStageObject = []

	for (let x = 0; x < arrayStage.length; x++) {
		const { scopeId } = arrayStage[x]

		if (checkBoxIndex.length === 0) {
			if (x !== arrayIndex && newStageId !== scopeId) {
				newStageArray.push(arrayStage[x])
			} else {
				if (newStageId === scopeId) {
					const newStageIdChange = arrayStage[arrayIndex]
					newStageIdChange.stageId = newStageId
					newStageArray.push(newStageIdChange)
					newStageArray.push(arrayStage[x])
				}
			}
		} else {
			if (!checkBoxIndex.includes(x) && newStageId !== scopeId) {
				newStageArray.push(arrayStage[x])
			}
			if (newStageId === scopeId) {
				for (let index = 0; index < checkBoxIndex.length; index++) {
					const newStageIdChange = arrayStage[checkBoxIndex[index]]
					newStageIdChange.stageId = newStageId
					newStageArray.push(newStageIdChange)
				}
				newStageArray.push(arrayStage[x])
			}
		}
	}

	for (let y = 0; y < objectStage.length; y++) {
		const { scopeName, scopeId, scopeDescription, stageDeliverable } = objectStage[y]

		const newStageDeliverable = []

		for (let z = 0; z < stageDeliverable.length; z++) {
			const { scopeId: stageScopeId } = stageDeliverable[z]
			const { scopeId: arrayScopeId } = arrayStage[arrayIndex]

			if (checkBoxIndex.length === 0) {
				if (stageScopeId !== arrayScopeId) {
					newStageDeliverable.push(stageDeliverable[z])
				}
			} else {
				if (!selectedStageScopeId.includes(stageScopeId)) {
					newStageDeliverable.push(stageDeliverable[z])
				}
			}
		}

		if (y === objectIndex) {
			if (checkBoxIndex.length === 0) {
				const newStageIdChange = arrayStage[arrayIndex]
				newStageIdChange.stageId = newStageId
				newStageDeliverable.push(newStageIdChange)
			} else {
				for (let index = 0; index < checkBoxIndex.length; index++) {
					const newStageIdChange = arrayStage[checkBoxIndex[index]]
					newStageIdChange.stageId = newStageId
					newStageDeliverable.push(newStageIdChange)
				}
			}
		}

		const newStage = {
			deliverableLength: newStageDeliverable.length,
			scopeName,
			scopeId,
			scopeDescription,
			stageDeliverable: newStageDeliverable,
		}

		newStageObject.push(newStage)
	}

	for (let x = 0; x < newStageObject.length; x++) {
		const { scopeId: objectScopeId, deliverableLength } = newStageObject[x]
		for (let y = 0; y < newStageArray.length; y++) {
			const { scopeId: arrayScopeId } = newStageArray[y]
			if (objectScopeId === arrayScopeId) {
				newStageArray[y].scopeLength = deliverableLength
			}
		}
	}

	const newStageData = {
		arrayStageDeliverable: newStageArray,
		objectStageDeliverable: newStageObject,
	}

	return newStageData
}

// Delete deliverable in deliverable list
export const deleteDeliverableInDeliverableList = (data) => {
	const { arrayIndex, arrayStage, objectStage, checkBoxIndex } = data
	const { scopeId } = arrayStage[arrayIndex]

	const selectedStageScopeId = checkBoxIndex.map((data) => {
		return arrayStage[data].scopeId
	})

	const newStageArray = []
	const newStageObject = []

	for (let x = 0; x < arrayStage.length; x++) {
		const { scopeId: allScopeId } = arrayStage[x]
		if (checkBoxIndex.length === 0) {
			if (allScopeId !== scopeId) {
				newStageArray.push(arrayStage[x])
			}
		} else {
			if (!selectedStageScopeId.includes(allScopeId)) {
				newStageArray.push(arrayStage[x])
			}
		}
	}

	for (let y = 0; y < objectStage.length; y++) {
		const { scopeName, scopeId, scopeDescription, stageDeliverable } = objectStage[y]

		const newStageDeliverable = []

		for (let z = 0; z < stageDeliverable.length; z++) {
			const { scopeId: stageScopeId } = stageDeliverable[z]
			const { scopeId: arrayScopeId } = arrayStage[arrayIndex]

			if (checkBoxIndex.length === 0) {
				if (stageScopeId !== arrayScopeId) {
					newStageDeliverable.push(stageDeliverable[z])
				}
			} else {
				if (!selectedStageScopeId.includes(stageScopeId)) {
					newStageDeliverable.push(stageDeliverable[z])
				}
			}
		}

		const newStage = {
			deliverableLength: newStageDeliverable.length,
			scopeName,
			scopeId,
			scopeDescription,
			stageDeliverable: newStageDeliverable,
		}

		newStageObject.push(newStage)
	}

	for (let x = 0; x < newStageObject.length; x++) {
		const { scopeId: objectScopeId, deliverableLength } = newStageObject[x]
		for (let y = 0; y < newStageArray.length; y++) {
			const { scopeId: arrayScopeId } = newStageArray[y]
			if (objectScopeId === arrayScopeId) {
				newStageArray[y].scopeLength = deliverableLength
			}
		}
	}

	const newStageData = {
		arrayStageDeliverable: newStageArray,
		objectStageDeliverable: newStageObject,
	}

	return newStageData
}
