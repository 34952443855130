//Import React Component
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RCTree from './RCTree';
import './index.css';

//Service Deliverable Component
class ServiceDeliverable extends Component {
  //Set service deliverable in CreateNewService component
  /**
   * @type - callback function
   * @param - data contains service deliverable details
   * @summary - get new service deliverable from rctree
   * @return - null
   */
  setServiceDeliverable = data => {
    this.props.setServiceDeliverable(data);
  };

  //Set deliverable selected and expanded keys in CreateNewService component
  /**
   * @type - callback function
   * @param - data contains rctree selected keys and expanded keys
   * @summary - get new service selected keys and checked keys from rctree
   * @return - null
   */
  setDefaultDeliverableKey = data => {
    this.props.setDefaultDeliverableKey(data);
  };

  //render serviceDeliverable component
  render() {
    //return serviceDeliverable component
    return (
      <div className={ 'serviceDeliverable' }>
        <RCTree { ...this.props } />
      </div>
    );
  }
}

//ServiceDeliverable proptypes
ServiceDeliverable.propTypes = {
  setServiceDeliverable: PropTypes.func,
  setDefaultDeliverableKey: PropTypes.func,
};

//export ServiceDeliverable Component
export default ServiceDeliverable;
