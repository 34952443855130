import React from 'react'
import { Row, Col, Result, Input } from 'antd'
import { addServiceName } from '../utils'
import { Query } from 'react-apollo'
import { MdDelete } from 'react-icons/md'
import { formatChatRelativetimestamp } from '../utils'
import { Spin, Icon, Popconfirm } from 'antd'
import { DebounceInput } from 'react-debounce-input'
import { GET_PROPOSAL_SERVICE_LIST_QUERY } from '../query' // Import GraphQL Query
import './index.css'

const stageColor = {
	Architecture: '#af4a7eb0',
	Interiors: '#25495ab0',
	Interior: '#25495ab0',
	Landscape: '#6f7d5f',
	Lighting: '#af714c',
	Acoustics: '#69ad92',
	'Facade Design': '#815daf',
}

class serviceTemplateConfig extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			serviceName: null,
			hoverService: null,
			serviceList: [],
			refetch: null,
			searchString: '',
		}
	}

	componentDidMount() {
		const { serviceResult } = this.props
		const { serviceName } = serviceResult
		this.setState({
			serviceName,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.editService !== this.props.editService) {
			const { refetch } = this.state
			if (refetch !== null) {
				this.state.refetch()
			}
		}

		if (prevProps.serviceResult.serviceName !== this.props.serviceResult.serviceName) {
			const { serviceResult } = this.props
			const { serviceName } = serviceResult
			this.setState({
				serviceName,
			})
		}
	}

	// service scope callback function
	setServiceScope = (data, type) => {
		const { setServiceScope } = this.props
		// callback function
		setServiceScope(data, type)
	}

	// Handle onchange proposal service
	onChangeService = (serviceDetails) => {
		// Deconstruct props
		const { typology, service, noOfStage } = serviceDetails

		const data = {
			typologyId: typology.id,
			typologyName: typology.name,
			noOfStage,
			serviceId: service.id,
		}

		// Deconstruct props
		const { editExistingService } = this.props
		// callback function
		editExistingService(data)
	}

	// Change service name in Proposal service
	onChangeServiceName = async (evt) => {
		this.setState({ serviceName: evt.target.value })
		const serviceResult = await addServiceName(this.props, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	render() {
		// Deconstruct props
		const { serviceStatus, serviceId, serviceResult } = this.props
		const { hoverService, serviceList, searchString, serviceName } = this.state

		return (
			<div className="serviceResultDiv">
				{serviceStatus === 'create' && serviceId === null ? (
					<Row>
						<Col span={5} />
						<Col span={16}>
							{/** Service Details */}
							<form className="serviceResult" style={{ marginTop: '2%' }}>
								{/** Service Name */}
								<h4 className="boldText">Enter Template Name</h4>

								<div className={'serviceResultInput'}>
									<input
										className={`inputBorder ${serviceName !== '' ? '' : 'error'}`}
										type="text"
										placeholder="Enter Template Name"
										value={serviceName === null ? '' : serviceName}
										onChange={this.onChangeServiceName}
									/>
								</div>
							</form>
						</Col>
					</Row>
				) : null}
				{serviceStatus === 'edit' && serviceId === null ? (
					<>
						<div
							style={{
								display: 'block',
								height: 50,
								background: '#eeebe8',
								margin: 5,
								borderRadius: 10,
							}}>
							<h1 style={{ float: 'left', margin: '12px', color: 'gray' }}>
								Template List
							</h1>
							<div
								className="chatSearch"
								style={{ position: 'relative', float: 'right' }}>
								<DebounceInput
									minLength={3}
									placeholder="Search Template . . ."
									debounceTimeout={300}
									onChange={(e) => {
										this.setState({ searchString: e.target.value })
									}}
								/>

								<img
									src={require('./../../ChatBox/images/searchicon.png')}
									alt={''}
									style={{
										width: '15px',
										position: 'absolute',
										bottom: '18px',
										left: '25px',
										top: '18px',
										fontSize: '15px',
									}}
								/>
							</div>
						</div>
						<div
							className="serviceScope"
							style={{ width: '100%', background: '#fafafa' }}>
							<Query query={GET_PROPOSAL_SERVICE_LIST_QUERY} variables={{ searchString }}>
								{({ loading, data, refetch }) => {
									if (loading) {
										return (
											// Spinner icon
											<Spin
												indicator={<Icon type="loading" className="iconSize" spin />}
												className="loaderIcon"
											/>
										)
									}

									if (!serviceList.length) {
										this.setState({
											serviceList: data.getProposalServiceList,
											refetch,
										})
									}

									return (
										<>
											{data.getProposalServiceList.length ? (
												<div>
													{data.getProposalServiceList.map((value, index) => {
														const {
															service,
															scope,
															typology,
															noOfStage,
															updatedAt,
														} = value

														return (
															<div
																id="serviceList"
																key={'data' + index}
																onMouseEnter={() => {
																	this.setState({ hoverService: value })
																}}
																onMouseLeave={() => {
																	this.setState({ hoverService: null })
																}}
																onClick={(e) => {
																	this.onChangeService(value)
																}}>
																<p title={service.name}>{service.name}</p>
																<div id="scopeList">
																	{scope.map((scopeName) => {
																		return (
																			<span
																				style={{
																					background: stageColor[scopeName],
																				}}
																				key={scopeName}>
																				{scopeName}
																			</span>
																		)
																	})}
																</div>
																<div id="line"></div>
																<div id="typology">
																	<div
																		id="typologyName"
																		title={`${noOfStage} stage - ${typology.name}`}>
																		{noOfStage} stage - {typology.name}
																	</div>
																	<span> {formatChatRelativetimestamp(updatedAt)}</span>

																	{hoverService !== null &&
																	hoverService.service.id === service.id ? (
																		<Popconfirm
																			title="Are you sure delete this template?"
																			onConfirm={(e) => {
																				e.stopPropagation()
																				const { deleteTemplate } = this.props
																				deleteTemplate(service.id)
																			}}
																			onCancel={(e) => {
																				e.stopPropagation()
																			}}
																			okText="Yes"
																			cancelText="No">
																			<div
																				id="deleteIcon"
																				onClick={(e) => {
																					e.stopPropagation()
																				}}>
																				<MdDelete size={25} color="#666" />
																			</div>
																		</Popconfirm>
																	) : null}
																</div>
															</div>
														)
													})}
												</div>
											) : (
												<div style={{ textAlign: 'center', opacity: 0.7, marginTop: 20 }}>
													<span>No template found.</span>
												</div>
											)}
										</>
									)
								}}
							</Query>
						</div>
					</>
				) : serviceStatus === 'edit' && serviceId !== null && serviceName === null ? (
					<Spin
						indicator={<Icon type="loading" className="iconSize" spin />}
						className="loaderIcon"
					/>
				) : serviceStatus === 'edit' && serviceId !== null && serviceName !== null ? (
					<Row>
						<Col span={5} />
						<Col span={16}>
							{/** Service Details */}
							<form className="serviceResult" style={{ marginTop: '2%' }}>
								{/** Service Name */}
								<h4 className="boldText">Enter Template Name</h4>

								<div className={'serviceResultInput'}>
									<input
										className={`inputBorder ${serviceName !== '' ? '' : 'error'}`}
										type="text"
										placeholder="Enter Template Name"
										value={serviceName === null ? '' : serviceName}
										onChange={this.onChangeServiceName}
									/>
								</div>
							</form>
						</Col>
					</Row>
				) : null}
			</div>
		)
	}
}

export default serviceTemplateConfig
