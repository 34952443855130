// Importing required components from ANTD
import { Button, Modal, notification, Tooltip, Spin, Icon, Drawer } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { GetFileComments } from './query'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from '../../../service'
import { FaRegCommentAlt } from 'react-icons/fa'
import CommentsList from './commentsList'

const { confirm } = Modal

export default function FetchComments(props) {
	// const [moveToRevision] = useMutation(MOVE_FILE_TO_REVISION)
	const { driveFileProps } = props

	const [showCommentsModal, setCommentsModal] = useState(true)

	async function handleOk(e) {
		console.log(e)
		setCommentsModal(false)
	}

	async function handleCancel(e) {
		console.log(e)
		setCommentsModal(false)
	}
	return (
		<>
			<Tooltip title="Comments" placement="bottom">
				<FaRegCommentAlt
					style={{ fontSize: 20, marginLeft: 10 }}
					onClick={() => setCommentsModal(!showCommentsModal)}
				/>
				<Drawer
					title="Comments"
					placement="right"
					closable={false}
					width={500}
					mask={false}
					style={{ zIndex: 5000, marginTop: 45 }}
					onClose={handleCancel}
					visible={showCommentsModal}>
					<CommentsList
						showCommentsModal={showCommentsModal}
						driveFileProps={driveFileProps}
						closeModal={handleCancel}
					/>
				</Drawer>
			</Tooltip>
		</>
	)
}
