/**
  Open team tickets Component
  This component will render display open team tickets 
* */

/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Spin, Icon, Typography, Tooltip, Divider, Popconfirm } from 'antd'

// Query component of React Apollo to fetch data
import { Query, Mutation } from 'react-apollo'

// Query to fetch Open team tickets Details
import { COMMENTS_SUBSCRIPTION, COMMENTS, DELETE_COMMENT, GET_COMMENTS } from '../query'
// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'
// Import Override CSS
import '../index.css'

// Import thumbsup image
import thumbsUp from '../../../assets/thumbs_up.png'
// Import DQ logo image
import dqLogo from '../../../assets/dq_icon_128.png'
// Import DQ logo image
import team from '../../../assets/team.png'
// import utils file
import util from '../../../utils'

import SubscriptionComment from '../subscriptionComment'
import UnresolvedCommentSection from '../childComment'

// deconstruct Text from Typography
const { Text, Paragraph } = Typography

/**
 * This class component is used to display ticket images in webapp.
 * @returns {opem team ticket component}
 */
class ModalContentDisplay extends React.Component {
	// initial states
	state = {
		userHashState:
			'e0f767d1012e9077550adfa12b256a277cb1bf25de3e15c3c9e9be061a2d830a9a4fc45e5e45d14dccd7a28c15c11791ab2a2540a148c71a5dbcb75daf7503d0',
	}

	/**
	 * This function is used to display discussion
	 * @param {* It contain Array of object} photoComments
	 * @param {* It contain Unsolved or solved} status
	 * @param {* It contain userhash} userHashState
	 * @returns {comment details}
	 */
	commentDisplay = (photoComments, status, userHashState) => (
		<div>
			{photoComments.map((data, i) => {
				const user = data.commentedBy === userHashState
				const names =
					data && data.commentedProfile && data.commentedProfile.name
						? data.commentedProfile.name
						: ''
				const name = names.split(' ')

				const box = user
					? data.commentType === 'discussion'
						? styles.lightBlue
						: styles.lightYellow
					: data.commentType === 'discussion'
					? styles.white
					: styles.darkYellow

				const deleted = 'Delete this comment'

				return (
					<div id="unresolved" key={i}>
						<Divider style={styles.openDiscussion}>
							{status === 'unresolved'
								? `Open ${data.commentType === 'discussion' ? 'Team' : 'Client'} Tickets`
								: `Closed ${
										data.commentType === 'discussion' ? 'Team' : 'Client'
								  } Tickets`}
						</Divider>

						<div className="comment_part">
							<div
								style={{
									...styles.parentComments,
									...(user ? styles.right : styles.left),
								}}>
								<div
									style={{
										...styles.parent,
										...(user ? styles.commentRight : styles.commentLeft),
										...box,
									}}>
									<div style={styles.profilePic}>
										<img
											alt="Team Chat"
											src={data.commentType === 'discussion' ? dqLogo : team}
											width="20"
											height="20"
										/>
										<div style={styles.commentNameParent}>{name[0]}</div>
									</div>

									<span>
										<span style={{ ...styles.commentDate, ...styles.commentDateParent }}>
											{util.formatRelativetimestamp(data.commentedAt)}
										</span>
										<span style={styles.commentText}>{data.text}</span>
									</span>
								</div>
							</div>
							<div>
								{data.childComments.map((datas, indexValue) => {
									const users = datas.commentedBy === userHashState
									const nameChild =
										datas && datas.commentedProfile && datas.commentedProfile.name
											? datas.commentedProfile.name
											: ''

									const namesChild = nameChild.split(',')

									const box = users
										? data.commentType === 'discussion'
											? styles.lightBlue
											: styles.lightYellow
										: data.commentType === 'discussion'
										? styles.white
										: styles.darkYellow

									if (datas.type === 'comment') {
										return (
											<div
												style={{
													...styles.parentComments,
													...(users ? styles.right : styles.left),
												}}
												key={indexValue}>
												{users ? (
													<div style={styles.childComment} className="hideShow">
														<Mutation mutation={DELETE_COMMENT}>
															{(deleteComment, { loading: changeLoading }) => {
																return (
																	<>
																		{datas.isDeleted !== 'true' ? (
																			<Popconfirm
																				placement="left"
																				title={deleted}
																				// icon={<IoMdTrash style={{ color: 'red',fontSize:'5px' }} />}
																				onConfirm={() => {
																					if (changeLoading) {
																						return
																					}
																					const variables = {
																						hash: userHashState,
																						commentId: datas.id,
																						level: 'child',
																						id: data.id,
																					}
																					deleteComment({
																						variables,
																						refetchQueries: [
																							{
																								query: GET_COMMENTS,
																								variables: {
																									hash: userHashState,
																									type: 'photo',
																									feedId: data.feedId,
																									imageId: data.imageId,
																									spaceId: data.spaceId,
																									productId: '',
																									variantId: '',
																									role: 'user',
																									status: 'unresolved',
																								},
																							},
																							{
																								query: GET_COMMENTS,
																								variables: {
																									hash: userHashState,
																									type: 'photo',
																									feedId: data.feedId,
																									imageId: data.imageId,
																									spaceId: data.spaceId,
																									productId: '',
																									variantId: '',
																									role: 'user',
																									status: 'resolved',
																								},
																							},
																						],
																					})
																				}}
																				okText="Yes"
																				cancelText="No">
																				<Tooltip
																					placement="top"
																					title="Click to delete comment">
																					<div
																						style={styles.deleteHideShow}
																						className="deleteHideShow">
																						...
																					</div>
																				</Tooltip>
																			</Popconfirm>
																		) : null}
																	</>
																)
															}}
														</Mutation>

														{datas.text !== 'ðŸ‘' ? (
															<>
																{datas.isDeleted === 'true' ? (
																	<div style={{ ...styles.deleteExtra, ...box }}>
																		<span>
																			<span style={styles.deleteColor}>
																				This comment has been deleted by you
																			</span>
																			<span
																				style={{
																					...styles.commentDate,
																					...styles.commentDateParent,
																				}}>
																				{util.formatRelativetimestamp(datas.commentedAt)}
																			</span>
																		</span>
																	</div>
																) : (
																	<div style={{ ...styles.commentRight, ...box }}>
																		<span>
																			<span style={styles.commentText}>{datas.text}</span>
																			<span
																				style={{
																					...styles.commentDate,
																					...styles.commentDateParent,
																				}}>
																				{util.formatRelativetimestamp(datas.commentedAt)}
																			</span>
																		</span>
																	</div>
																)}
															</>
														) : (
															<>
																{datas.isDeleted === 'true' ? (
																	<div style={{ ...styles.deleteExtra, ...box }}>
																		<span>
																			<span style={styles.deleteColor}>
																				This comment has been deleted by you
																			</span>
																			<span
																				style={{
																					...styles.commentDate,
																					...styles.commentDateParent,
																				}}>
																				{util.formatRelativetimestamp(datas.commentedAt)}
																			</span>
																		</span>
																	</div>
																) : (
																	<div style={styles.rightThumbs}>
																		<span style={styles.commentText}>
																			<img
																				alt="Team Chat"
																				style={styles.teamChatImage}
																				src={thumbsUp}
																				width="60"
																				height=""
																			/>
																		</span>
																		<span
																			style={{
																				...styles.commentDate,
																				...styles.commentDateParent,
																			}}>
																			{util.formatRelativetimestamp(datas.commentedAt)}
																		</span>
																	</div>
																)}
															</>
														)}
													</div>
												) : (
													<div style={{ ...styles.commentLeft, ...box }}>
														<div>
															<div style={styles.commentName}>{namesChild[0]}</div>
															<span style={styles.commentText}>
																{datas.text !== 'ðŸ‘' ? (
																	<>
																		{datas.isDeleted === 'true' ? (
																			<span style={styles.deleteColor}>
																				This comment has been deleted
																			</span>
																		) : (
																			datas.text
																		)}
																	</>
																) : (
																	<>
																		{datas.isDeleted === 'true' ? (
																			<span style={styles.deleteColor}>
																				This comment has been deleted
																			</span>
																		) : (
																			<img
																				alt="Team Chat"
																				style={styles.teamChatImage}
																				src={thumbsUp}
																				width="60"
																				height=""
																			/>
																		)}
																	</>
																)}
															</span>
															<span
																style={{
																	...styles.commentDate,
																	...styles.commentDateParent,
																}}>
																{' '}
																{util.formatRelativetimestamp(datas.commentedAt)}{' '}
															</span>
														</div>
													</div>
												)}
											</div>
										)
									}
									return (
										<div
											style={{
												...styles.commentLog,
												...(users ? styles.right : styles.left),
											}}>
											<p style={styles.logText}>
												{namesChild[0]} closed ticket{' '}
												{util.formatRelativetimestamp(datas.commentedAt)}{' '}
											</p>
										</div>
									)
								})}
								<div style={{ ...styles.parentComments, ...styles.right }}>
									<UnresolvedCommentSection
										data={data}
										status={data.status}
										teamClientStatus={data.commentType}
										userHashState={userHashState}
										projectUrl={this.props.projectUrl}
										loadCount={this.props.loadCount}
									/>
								</div>
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)

	/**
	 * It should be excecute first
	 * This is used for display open team tickets photos
	 * @returns {open team tickets page}
	 */
	/**
	 * This function is used to display a open team tickets photos.
	 * It contain card profile name, photo comment and Action event like comment.
	 * @returns {open team tickets photo}
	 */

	render() {
		// Deconstructing the props
		const {
			indexSet,
			userHashState,
			flatternArray,
			projectUrl,
			selection,
			handleCancel,
		} = this.props
		// console.log(indexSet)
		// console.log(flatternArray[indexSet])
		// console.log(flatternArray[indexSet].commentId)
		// const userDescription = flatternArray[indexSet].uploadedBy === userHashState

		return (
			<div style={styles.modalBox}>
				{/* modal image display */}
				<div style={styles.modalImageBox}>
					<div
						style={{
							background: `url(${flatternArray[indexSet].uri}) center`,
							...styles.modalImageLight,
						}}
					/>
					<div style={styles.modalImageBlur} />
					<img
						style={styles.ticketImage}
						src={flatternArray[indexSet].uri}
						alt="examples"
					/>
				</div>

				{/* modal detail display */}
				<div style={styles.modalDetail}>
					<div style={styles.profileHead}>
						<div style={styles.profileSection}>
							<div style={styles.profileNames}>
								{flatternArray[indexSet].uploadedProfile.photo ? (
									<img
										style={styles.modalProfilePic}
										src={flatternArray[indexSet].uploadedProfile.photo}
										alt="example"
										width="30"
										height="30"
									/>
								) : (
									<div style={styles.modalProfilePicNo}>
										{util.getInitials(flatternArray[indexSet].uploadedProfile.name)}
									</div>
								)}
								<Text style={styles.modalName}>
									{flatternArray[indexSet].uploadedProfile.name}
									{` @ `}
								</Text>

								<div style={styles.modalProject}>
									<Text style={styles.projectNames}>
										{flatternArray[indexSet].projectName}
									</Text>
								</div>
							</div>
						</div>

						<div>
							<div style={styles.modalTime}>
								{`${util.formatRelativetimestamp(
									flatternArray[indexSet].uploadedAt
								)} at `}
								<Tooltip placement="bottom" title="Space">
									<span style={styles.modalPostSpace}>
										{flatternArray[indexSet].space}
									</span>
								</Tooltip>
								{`  `}
								{flatternArray[indexSet].clientDisplayStatus === 'pending' ? (
									<Text code style={styles.projectName}>
										{flatternArray[indexSet].clientDisplayStatus}
									</Text>
								) : null}
							</div>
						</div>
					</div>

					{/* Comment display section */}
					<div style={styles.commentDisplay} id="commentScroll">
						<Query
							query={COMMENTS}
							variables={{
								messageId: flatternArray[indexSet].commentId,
							}}>
							{({
								data: commentData,
								loading: commentLoading,
								subscribeToMore,
								refetch: commentUnsolvedRefetch,
								error: commentUnsolvedError,
							}) => {
								if (commentLoading) {
									return (
										<Spin
											indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
											style={styles.loadIconStyle}
										/>
									)
								}
								if (!commentData || !commentData.TicketComments || commentUnsolvedError) {
									return <span>Some error </span>
								}

								const { TicketComments } = commentData
								// console.log(TicketComments[0].status)
								if (TicketComments.length === 0) {
									return null
								}

								return (
									<div>
										<SubscriptionComment
											photoComments={TicketComments}
											status={TicketComments[0].status}
											userHashState={userHashState}
											commentDisplay={this.commentDisplay}
											subscribeToNewComments={() =>
												subscribeToMore({
													document: COMMENTS_SUBSCRIPTION,
													variables: {
														feedId: flatternArray[indexSet].feedId,
														imageId: flatternArray[indexSet].imageId,
													},
													updateQuery: (prev, { subscriptionData }) => {
														if (!subscriptionData.data) return prev
														commentUnsolvedRefetch()
														return null
													},
												})
											}
										/>
									</div>
								)
							}}
						</Query>
					</div>
				</div>

				{!selection ? (
					true
				) : (
					<div style={styles.modalClose}>
						<Icon type="close" style={styles.closeIcon} onClick={handleCancel} />
					</div>
				)}
			</div>
		)
	}
}

export default ModalContentDisplay
