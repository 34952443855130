import React, { Component } from 'react';
import { Row, Col, Card, Button, Steps, Select, message, Divider } from 'antd';
import './index.css';

const { Option } = Select;
const { Step } = Steps;

const steps = [
  {
    title: 'Menu Level 1',
    content: 'First-content',
  },
  {
    title: 'Menu Level 2',
    content: 'Second-content',
  },
  {
    title: 'Menu Level 3',
    content: 'Last-content',
  },
];

const menuList = [
  {
    menuId: 'menu_1',
    menuName: 'Home',
  },
  {
    menuId: 'menu_2',
    menuName: 'Calendar',
  },
  {
    menuId: 'menu_3',
    menuName: 'Wiki',
  },
  {
    menuId: 'menu_4',
    menuName: 'Website',
  },
  {
    menuId: 'menu_5',
    menuName: 'Project',
  },
];

class AddRoleMenuMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  handleChange = value => {
    console.log(`selected ${ value }`);
  };
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    const { current } = this.state;
    const MenuDropdown = () => {
      return (
        <Select
          mode="multiple"
          style={ { width: '100%' } }
          placeholder="Please Select Menu"
          onChange={ this.handleChange }
          optionLabelProp="label"
        >
          {menuList.map((value, index) => {
            return (
              <Option value={ value.menuId } label={ value.menuName } key={ index }>
                {value.menuName}
              </Option>
            );
          })}
        </Select>
      );
    };
    return (
      <div>
        <Row>
          <Col span={ 24 }>
            <Card bordered={ false } className={ 'parentLayout' }>
              <Steps current={ current } className={ 'parentStep' }>
                {steps.map(item => (
                  <Step key={ item.title } title={ item.title } />
                ))}
              </Steps>
              <Card bordered={ false } className={ 'parentComponent' }>
                {/* <div className="steps-content">{steps[current].content}</div> */}
                <Row>
                  <Col span={ 11 }>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum passages, and
                    more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </Col>
                  <Col span={ 2 } style={ { textAlign: 'center' } }>
                    <Divider type="vertical" />
                  </Col>
                  <Col span={ 11 }>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum passages, and
                    more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </Col>
                </Row>
              </Card>

              <div className="steps-action parentStepNavigation">
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={ () => this.next() }>
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={ () => message.success('Processing complete!') }
                  >
                    Done
                  </Button>
                )}
                {current > 0 && (
                  <Button style={ { marginLeft: 8 } } onClick={ () => this.prev() }>
                    Previous
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddRoleMenuMapping;
