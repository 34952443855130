import gql from 'graphql-tag'

export const MOVE_FILE_TO_REVISION = gql`
	mutation moveFileToRevision(
		$fileId: String
		$forReviewFolderId: String
		$forRevisionFolderId: String
		$comments: String
	) {
		moveFileToRevision(
			fileId: $fileId
			forReviewFolderId: $forReviewFolderId
			forRevisionFolderId: $forRevisionFolderId
			comments: $comments
		)
	}
`
