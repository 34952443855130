/**
 * Developer : Sandhiya Malar
 */
/**
    Sketch Up Component
    This component will render upload file Read. 
    And Display in table formate & editable columns.
    And download as excel ad pdf format.
* */

/*  Import Components  */
import React from 'react'
/* Import required components from ANT DESIGN */
import { Button, Spin, Icon, notification } from 'antd'

// Query component of React Apollo to fetch data
import { Query, Mutation, withApollo } from 'react-apollo'

/* Import react-file-reader component */
import ReactFileReader from 'react-file-reader'
/*  Import xlsx Components  */
import * as XLSX from 'xlsx'
/* Import react-papaparse component */
import { readString } from 'react-papaparse'

/* Import pdfconvertor component */
import TableData from './TableData'

import { INSERT_DATA, UPLOAD_COUNT, UPLOAD_KEY, GET_DATA } from './query'

/*  Import Override CSS  */
import './index.css'

/*  Import Custom Styles  */
import styles from './styles'

/**
 * This class component is used to uploaded file content
 * @returns {project archived component}
 */
class SketchUp extends React.Component {
	/* initial states */
	state = {
		sketchUpArray: null,
		colorDropdown: [],
		uploadElement: null,
	}

	// image upload modal success notification
	imageUploadedNotification = () => {
		notification.open({
			top: 130,
			message: 'File Upload Successfully',
		})
	}

	/**
	 *  Read a uploaded file content and set in state
	 */
	handleFiles = (files, InsertData) => {
		const reader = new FileReader()
		reader.onload = async (e) => {
			/* Parse data */
			const bstr = e.target.result
			const wb = XLSX.read(bstr, { type: 'array' })
			/* Get first worksheet */
			const wsname = wb.SheetNames[0]
			const ws = wb.Sheets[wsname]
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })

			const result = readString(data, {
				header: true,
			})

			const { client } = this.props
			const { data: countData } = await client.query({
				query: UPLOAD_COUNT,
				fetchPolicy: 'network-only',
			})
			// console.log(countData)

			/* compare uploaded file maretrial colums return unique of all (array of string) */
			const colorDropdowns = result.data.reduce((unique, items) => {
				if (items.Path) {
					return unique.includes(items.Material) || items.Material === ''
						? unique
						: [...unique, items.Material]
				}
				return unique
			}, [])
			// console.log(colorDropdowns)

			/* it form a group of array of object based on 
        (upload file path colums split on '/' second one is as hash value ) 
      */
			const hashGroup = result.data.reduce((acc, initialValue) => {
				if (initialValue.Path) {
					// const [cabinetName, hash] = initialValue.Path.split('/')
					const hash = initialValue.Path.split('/')
					if (acc[hash[1]]) {
						acc[hash[1]] = [...acc[hash[1]], initialValue]
						return acc
					}
					acc[hash[1]] = [initialValue]
				}
				return acc
			}, {})

			/* it split wantes and unwanted array based on 
        (upload file instance colums start with '#' ) 
      */
			const splitArray = Object.entries(hashGroup).map(([key, value]) => {
				const wanted = value.filter((i) => !i.Instance.startsWith('#'))
				const unwanted = value.filter((i) => i.Instance.startsWith('#'))
				return { key, wanted, unwanted }
			})

			const arrayGroup = []
			let values = 0

			/* form a array of object for export file data */
			for (let index = 0; index < splitArray.length; index++) {
				const element = splitArray[index]
				const { unwanted, wanted } = element

				for (let x = 0; x < wanted.length; x++) {
					const i = wanted[x]
					const [cabinetName] = i.Path.split('/')
					values += 1
					arrayGroup.push({
						upload_key: `upload_${countData.uploadCount + 1}`,
						sno: values,
						CABINET_NAME: cabinetName,
						PANEL_TYPE: i.Instance,
						FACTORY_LABEL: '',
						PANEL_NAME: i.Definition,
						THICKNESS: i['Thickness - final'],
						LENGTH_1: i['Length - final'],
						LENGTH_2: i['Width - final'],
						// EXT_COLOR: unwanted[0].Material,
						// INT_COLOR: unwanted[1].Material,
						EXT_COLOR: unwanted.length === 0 ? '' : unwanted[0].Material,
						INT_COLOR: unwanted.length === 0 ? '' : unwanted[1].Material,
						EDGE_BAND: '',
						REMARKS_1: '',
						REMARKS_2: '',
						GRAINS_DIRECTION: '',
					})
				}
			}
			this.setState({
				sketchUpArray: arrayGroup,
				colorDropdown: colorDropdowns,
			})
			const variables = {
				arrayData: arrayGroup,
			}
			console.log(arrayGroup)
			InsertData({
				variables,
				refetchQueries: [
					{
						query: UPLOAD_KEY,
					},
				],
			})
			this.imageUploadedNotification()
		}
		//reader.readAsText(files[0])
		reader.readAsArrayBuffer(files[0])
	}

	setSelected = (elements) => {
		this.setState({
			uploadElement: elements.id,
		})
	}

	/**
	 * It should be excecute first
	 * This is used for upload file .
	 * @returns {SketchUp}
	 */

	render() {
		/* Deconstructing the state */
		const { uploadElement, sketchUpArray, colorDropdown } = this.state
		// console.log(uploadElement)

		return (
			<div style={styles.fileConvertor}>
				<div style={styles.inputDiv}>
					<Mutation mutation={INSERT_DATA}>
						{(InsertData) => {
							return (
								<>
									<ReactFileReader
										multipleFiles={false}
										fileTypes={['.ods', '.xlsx', '.csv', '.xls']}
										handleFiles={(e) => this.handleFiles(e, InsertData)}>
										<Button type="primary" style={styles.sendButton}>
											Upload File Here, (ods, xlsx, csv, xls)
										</Button>
									</ReactFileReader>
									{/* {responseData === true ? <div>hi</div> : null} */}
								</>
							)
						}}
					</Mutation>
				</div>

				<Query query={UPLOAD_KEY}>
					{({ data: uploadData, loading: uploadLoading, error: uploadError }) => {
						if (uploadLoading) {
							// loading -> it handles a loading data status
							return (
								<Spin
									indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
									style={styles.loadIconStyle}
								/>
							)
						}
						if (uploadError) {
							return <span>Some error </span>
						}

						// console.log(closedCountData.closedCounts)

						return (
							<div style={styles.ticket}>
								{uploadData.uploadKey.map((
									element,
									index // dynamically display space filter data
								) => (
									<div
										key={index}
										style={styles.uploadKey}
										onClick={() => this.setSelected(element)}>
										{element.id}
									</div>
								))}
							</div>
						)
					}}
				</Query>

				{uploadElement !== null ? (
					<Query
						query={GET_DATA}
						variables={{
							id: uploadElement,
						}}>
						{({ data: clickData, loading: clickLoading, error: clickError }) => {
							if (clickLoading) {
								// loading -> it handles a loading data status
								return (
									<Spin
										indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
										style={styles.loadIconStyle}
									/>
								)
							}
							if (clickError) {
								return <span>Some error </span>
							}

							// console.log(closedCountData.closedCounts)

							return (
								<>
									<TableData sketchUpArray={clickData.GetData} />
								</>
							)
						}}
					</Query>
				) : null
				// <div style={styles.clickData}>Click some above data</div>
				}

				{/* {sketchUpArray !== null ? (
					<TableData sketchUpArray={sketchUpArray} colorDropdown={colorDropdown} />
				) : null} */}
			</div>
		)
	}
}

export default withApollo(SketchUp)
