/* eslint-disable react/no-danger */
// Import Component
import React from 'react'
import { Form, Icon, Button, InputNumber, Spin, Select } from 'antd'
import CKEditor from 'ckeditor4-react'
import { Query } from 'react-apollo'
import { GET_MASTER_TYPOLOGY_QUERY } from '../query' // Import GraphQL Query
import { spaceEditorHtmlTemplate, editorJsonData } from '../utils' // Import utils file
import './index.css' // Import component css

// Handle antd form errors
function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some((field) => fieldsError[field])
}

// Service space config form component
class ServiceSpaceConfigForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			buttonStatus: false,
		}
		this.onEditorChange = this.onEditorChange.bind(this)
	}

	// Set document data in state before, editor is rendered in DOM
	componentWillMount() {
		// Deconstruct component props
		const { serviceSpace } = this.props
		const { spaceEditorHtml, typologyId } = serviceSpace

		// Here check typology Id is exist or not, if exist set spaceEditorHtml in state otherwise not
		if (typologyId !== null) {
			this.setState({
				data: spaceEditorHtml,
			})
		}
	}

	// Set Previous input details in form
	componentDidMount() {
		// To disabled submit button at the beginning.
		const { form, serviceSpace } = this.props
		form.validateFields()

		if (serviceSpace.typologyId !== null) {
			// Deconstruct component props
			const { typologyId, noOfBuilding, noOfFloor, noOfRoom } = serviceSpace

			// Set that input data in space form
			form.setFieldsValue({
				typologyInput: typologyId,
				noOfFloorInput: noOfFloor,
				noOfBuildingInput: noOfBuilding,
				noOfRoomInput: noOfRoom,
			})

			if (
				typologyId !== undefined &&
				noOfBuilding !== undefined &&
				noOfFloor !== undefined &&
				noOfRoom !== undefined
			) {
				this.setState({ buttonStatus: false })
			} else {
				this.setState({ buttonStatus: false })
			}
		}
	}

	onFieldsChange = () => {
		const errorData = [undefined, null, '', ' ']
		const { form } = this.props
		const data = form.getFieldsValue()

		const { typologyInput, noOfBuildingInput, noOfFloorInput, noOfRoomInput } = data

		if (
			!errorData.includes(typologyInput) &&
			!errorData.includes(noOfBuildingInput) &&
			!errorData.includes(noOfFloorInput) &&
			!errorData.includes(noOfRoomInput)
		) {
			this.setState({ buttonStatus: false })
		} else {
			this.setState({ buttonStatus: false })
		}
	}

	/**
	 * @type - function
	 * @param - e contains input field values
	 * @summary - Validate the input field and generate space template and
	 *            set that template in Editor documant
	 * @return -null
	 */
	handleSubmit = (e) => {
		e.preventDefault()
		const { form, serviceSpace, setServiceSpace } = this.props
		form.validateFields((err, values) => {
			if (!err) {
				// Deconstruct input values
				const { typologyInput, noOfBuildingInput, noOfFloorInput, noOfRoomInput } = values

				// Generate space template based on no of building, no of floor and no of room
				const spaceDefineHtml = spaceEditorHtmlTemplate(
					noOfBuildingInput,
					noOfFloorInput,
					noOfRoomInput
				)

				// Set these data in serviceScopeConfig component state
				this.setState({
					data: spaceDefineHtml,
					buttonStatus: false,
				})

				// Deconstruct serviceScopeConfig component props
				const { typology } = serviceSpace

				// Get Space editor Json Data
				const spaceDefineJson = editorJsonData()

				// create state JSON structure and data
				const data = {
					spaceEditorJson: spaceDefineJson,
					spaceEditorHtml: spaceDefineHtml,
					noOfBuilding: noOfBuildingInput,
					noOfFloor: noOfFloorInput,
					noOfRoom: noOfRoomInput,
					typologyId: typologyInput,
					typology,
				}

				// Callback function
				setServiceSpace(data)
			}
		})
	}

	/**
	 * @type - function
	 * @param - evt
	 * @summary - Trigger,When there is any changes in Editor document,
	 * @return - null
	 */
	onEditorChange = (evt) => {
		// Get Editor document HTML
		const editorHtmlData = evt.editor.getData()
		const { serviceSpace, setServiceSpace } = this.props

		// Set these data in serviceScopeConfig component state
		this.setState({ data: editorHtmlData })

		// Deconstruct props
		const { typology, typologyId, noOfBuilding, noOfFloor, noOfRoom } = serviceSpace

		// create state JSON structure and data
		const data = {
			spaceEditorJson: editorJsonData(),
			spaceEditorHtml: editorHtmlData,
			noOfBuilding,
			noOfFloor,
			noOfRoom,
			typologyId,
			typology,
		}

		// Callback function
		setServiceSpace(data)
	}

	render() {
		// Deconstruct form props

		const { form, serviceSpace, setServiceSpace, setTypologyId } = this.props
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = form

		// Deconstruct serviceScopeComponent props
		const {
			spaceEditorHtml,
			spaceEditorJson,
			noOfBuilding,
			noOfFloor,
			noOfRoom,
			typologyId,
			typology,
		} = serviceSpace

		// Deconstruct state
		const { data, buttonStatus } = this.state

		// Only show error after a field is touched.
		const typologyInput =
			isFieldTouched('typologyInput') && getFieldError('typologyInput')
		const noOfBuildingInput =
			isFieldTouched('noOfBuildingInput') && getFieldError('noOfBuildingInput')
		const noOfFloorInput =
			isFieldTouched('noOfFloorInput') && getFieldError('noOfFloorInput')
		const noOfRoomInput =
			isFieldTouched('noOfRoomInput') && getFieldError('noOfRoomInput')

		return (
			<div className="serviceSpaceConfig">
				{!typology.length ? (
					// Get master typology list
					<Query query={GET_MASTER_TYPOLOGY_QUERY}>
						{({ data: result, loading }) => {
							if (loading) {
								return (
									// Loader
									<Spin
										indicator={<Icon type="loading" className="iconSize" spin />}
										className="loaderIcon"
									/>
								)
							}
							if (!result.getMasterTypology) {
								return null
							}

							// Deconstruct master typology list result
							const { getMasterTypology } = result

							if (!typology.length) {
								const resultData = {
									spaceEditorHtml,
									spaceEditorJson,
									noOfBuilding,
									noOfFloor,
									noOfRoom,
									typologyId,
									typology: getMasterTypology,
								}

								// callback function
								setServiceSpace(resultData)
							}

							return <div />
						}}
					</Query>
				) : null}

				<Form layout="inline" onSubmit={this.handleSubmit} className="formAlign">
					<Form.Item
						label="Select typology"
						validateStatus={typologyInput ? 'error' : ''}
						help={typologyInput || ''}>
						{getFieldDecorator('typologyInput', {
							rules: [
								{
									required: true,
									message: 'Please enter space config name!',
								},
							],
						})(
							<Select
								showSearch
								onChange={(data) => {
									this.onFieldsChange()
									setTypologyId(data)
								}}
								style={{ width: 160, marginLeft: 20 }}
								placeholder="Select a Typology"
								optionFilterProp="children"
								// eslint-disable-next-line max-len
								filterOption={(input, option) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{typology.map((element) => (
									<Select.Option key={element.typologyId} value={element.typologyId}>
										{element.typologyName}
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>
					<Form.Item
						label="Enter no of building"
						validateStatus={noOfBuildingInput ? 'error' : ''}
						help={noOfBuildingInput || ''}>
						{getFieldDecorator('noOfBuildingInput', {
							rules: [
								{
									required: true,
									message: 'Please enter no of buildings!',
								},
							],
						})(
							<InputNumber
								min={1}
								max={100}
								onKeyUp={this.onFieldsChange}
								placeholder="Number of buildings"
								style={{ width: '100%' }}
							/>
						)}
					</Form.Item>
					<Form.Item
						label="Enter no of floor"
						validateStatus={noOfFloorInput ? 'error' : ''}
						help={noOfFloorInput || ''}>
						{getFieldDecorator('noOfFloorInput', {
							rules: [{ required: true, message: 'Please enter no of floors!' }],
						})(
							<InputNumber
								min={1}
								max={100}
								onKeyUp={this.onFieldsChange}
								placeholder="Number of floors"
								style={{ width: '100%' }}
							/>
						)}
					</Form.Item>
					<Form.Item
						label="Enter no of room"
						validateStatus={noOfRoomInput ? 'error' : ''}
						help={noOfRoomInput || ''}>
						{getFieldDecorator('noOfRoomInput', {
							rules: [{ required: true, message: 'Please enter no of rooms!' }],
						})(
							<InputNumber
								min={1}
								max={100}
								onKeyUp={this.onFieldsChange}
								placeholder="Number of rooms"
								style={{ width: '100%' }}
							/>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={buttonStatus}
							style={{ marginTop: 40 }}>
							Generate
						</Button>
					</Form.Item>
				</Form>
				{typologyId !== null ? (
					<div className="editorData">
						<>
							<EditorPreview data={data} />
							<CKEditorPreview data={data} onChange={this.onEditorChange} />
						</>
					</div>
				) : null}
			</div>
		)
	}
}

// CKEditor Preview in Editor
function CKEditorPreview(props) {
	const { data, onChange } = props
	return (
		<div className="editorDisplay">
			<CKEditor data={data} onChange={onChange} />
		</div>
	)
}

// Editor Preview in DOM
function EditorPreview(props) {
	const { data } = props
	return (
		<div className="editorPreview">
			<h2>Space Config Design</h2>
			<div dangerouslySetInnerHTML={{ __html: data }} />
		</div>
	)
}

const ServiceSpaceConfig = Form.create({ name: 'space_config_form' })(
	ServiceSpaceConfigForm
)

// export ServiceSpaceConfig component
export default ServiceSpaceConfig
