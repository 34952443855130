import { useMutation } from '@apollo/react-hooks'
// Importing required components from ANTD
import { Button, Col, Input, Row } from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
// Query to fetch CRM Details on load
import { ADD_ENQUIRY_NOTES_MUTATION } from './mutation'
import utils from '../../../../utils'

const { TextArea } = Input
const { stringEncode } = utils

export default function AddNotes(props) {
	const { enquiryId, enquiryType, refetchChatData } = props
	const [addEnquiryNotes] = useMutation(ADD_ENQUIRY_NOTES_MUTATION)
	const [defaultNotes, setNotesValue] = useState(null)
	const [buttonActiveState, setButtonActiveState] = useState(true)

	function onNotesChange(event) {
		// document.removeEventListener('keydown', props.arrowClickEvent, false);
		if (!event.target.value) {
			setButtonActiveState(true)
			setNotesValue(null)
		} else {
			setNotesValue(event.target.value)
			setButtonActiveState(false)
		}
	}

	async function onClickAddNotesButton() {
		if (!defaultNotes || !defaultNotes.trim()) {
			setNotesValue(null)
		} else {
			await addEnquiryNotes({
				variables: {
					enquiryId,
					stageFrom: enquiryType,
					notes: stringEncode(defaultNotes.trim()),
				},
			})
			setNotesValue(null)
			setButtonActiveState(true)
			refetchChatData()

			// window.scrollTo(0, props.myRef.current.offsetTop);
		}
	}
	return (
		<>
			<Row gutter={[16, 8]}>
				<Col span={24}>
					<TextArea
						rows={3}
						autoSize={{ minRows: 3, maxRows: 3 }}
						value={defaultNotes}
						placeholder="Enter your notes here . . ."
						onChange={(event) => onNotesChange(event)}
						onPressEnter={() => onClickAddNotesButton()}
						// onFocusIn = { () => window.removeEventListener('keydown', arrowClickEvent, false) }
					/>
					<Button
						type="secondary"
						size="default"
						disabled={buttonActiveState}
						onClick={() => onClickAddNotesButton()}
						block
						style={{
							marginTop: 12,
							backgroundColor: '#656565',
							color: '#fff',
							width: '25%',
							float: 'right',
						}}>
						Add Notes
					</Button>
				</Col>
			</Row>
		</>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
AddNotes.propTypes = {
	enquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
}

AddNotes.defaultProps = {
	enquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
}
