import { useQuery, useMutation } from '@apollo/react-hooks'
import { Button, DatePicker, Icon, Input, Modal, Select, Tooltip } from 'antd'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { isToday, isTomorrow, format } from 'date-fns'
import {
	GET_PROFILE_DATA_QUERY,
	GET_UPCOMING_TASK_QUERY,
	GET_PREVIOUS_TASK_QUERY,
	ADD_NEW_TASK_MUTATION,
	CANCEL_TASK_MUTATION,
	EDIT_UPCOMING_TASK_MUTATION,
	IS_SHARING_QUERY,
} from './query'
import CompleteTask from './CompleteTask'
import client from '../../service'

const { Option } = Select
const { confirm } = Modal
const dateFormat = 'MMMM Do, YYYY'

function timeConvert(n) {
	const num = n
	const hours = num / 60
	const rhours = Math.floor(hours)
	const minutes = (hours - rhours) * 60
	const rminutes = Math.round(minutes)
	const convertedValue = `${rhours > 0 ? `${rhours} hour${rhours > 1 ? 's' : ''}` : ''} ${
		rhours > 0 && rminutes > 0 ? 'and' : ''
	} ${rminutes > 0 ? `${rminutes} minute${rminutes > 1 ? 's' : ''}` : ''}`
	return convertedValue.trim()
}

const Root = styled.div`
	background: #fff;
	width: 100%;
	height: calc(100vh - 120px);
	padding: 10px 20px;
	overflow-x: hidden;
	overflow-y: auto;

	.error {
		font-size: smaller;
		color: red;
		padding-left: 5px;
	}
`

const HeaderContainer = styled.div`
	margin: 20px 0 10px 0;
	padding: 10px;
	width: fit-content;

	.header {
		font-size: 170%;
		color: #505050;
		font-weight: bold;
	}
`

const AddSection = styled.div`
	width: 100%;
	margin: 10px 0;
	padding: 10px;

	.header {
		font-size: 170%;
		font-weight: bold;
		color: #1890ff;
		margin-bottom: 10px;
	}

	.content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.ant-calendar-picker-input.ant-input,
		.project-name,
		.task-name,
		.ant-select-selection.ant-select-selection--single {
			height: 40px;
			font-size: 16px;
		}

		.ant-select-selection-selected-value {
			height: 40px;
			line-height: 40px;
			font-size: 16px;
		}

		.project-name {
			width: 300px;
		}

		.task-name {
			width: 350px;
		}

		.add-task-button {
			height: 40px;
			width: 40px;

			i {
				font-size: 22.5px;
			}
		}
	}
`

const UpcomingSection = styled.div`
	width: 100%;
	margin: 20px 0 10px 0;
	padding: 10px;

	.header {
		font-size: 170%;
		font-weight: bold;
		color: #1890ff;
		margin-bottom: 10px;
	}

	.content {
		display: flex;
		flex-direction: column;
		max-width: 80%;

		.task {
			display: flex;
			align-items: center;
			margin: 10px 0;
			border-radius: 2px;

			& > * {
				margin: 0 10px;
			}

			.day {
				display: flex;
				justify-content: flex-end;
				width: 100%;
				max-width: 150px;

				.chip {
					padding: 2px 10px;
					background: #32cbc2;
					font-size: 95%;
					color: #fff;
					width: fit-content;
					border-radius: 10px;

					&.today {
						background: #cb6932;
					}
				}
			}

			.task-name {
				text-align: left;
				font-size: 150%;
				font-weight: 500;
				width: fit-content;

				span {
					font-size: 70%;
					color: gray;
				}
			}

			.action {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s, opacity 10ms linear;

				.done,
				.cancel,
				.edit {
					color: #fff;
					border-radius: 10px;
					padding: 2px 10px;
					margin: 0 5px;
					cursor: pointer;
				}

				.done {
					background: #6f9e6c;
				}

				.cancel {
					background: #ba4949;
				}

				.edit {
					background: #147ee0;
				}
			}

			&:hover {
				background: #1890ff10;

				.action {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
`

const CompletedSection = styled.div`
	width: 100%;
	margin: 20px 0 10px 0;
	padding: 10px;

	.header {
		font-size: 170%;
		font-weight: bold;
		color: #1890ff;
		margin-bottom: 10px;
	}

	.content {
		display: flex;
		flex-direction: column;
		max-width: 80%;

		.task {
			display: flex;
			flex: 1;
			margin: 10px 0;
			border-radius: 2px;

			& > * {
				margin: 0 10px;
			}

			.icon {
				width: 20px;
				padding-top: 10px;

				i {
					font-size: 20px;
				}

				&.cancelled {
					i {
						color: #ba4949;
					}
				}

				&.completed {
					i {
						color: #6f9e6c;
					}
				}
			}

			.task-content {
				display: flex;
				flex-direction: column;

				& > * {
					margin: 2px 0;
				}

				.task-name {
					font-size: 150%;
					font-weight: 500;
				}

				.task-desc {
					font-size: 90%;
					color: gray;
				}

				.task-status {
					font-size: 90%;
					color: #6f9e6c;

					&.completed {
						color: #6f9e6c;
					}

					&.cancelled {
						color: #ba4949;
					}
				}
			}

			.action {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s, opacity 10ms linear;

				.edit {
					cursor: pointer;
					background: #147ee0;
					color: #fff;
					border-radius: 10px;
					padding: 2px 10px;
					margin: 0 5px;
				}
			}

			&:hover {
				background: #1890ff10;

				.action {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
`

const timeDurations = [
	{ label: '15 mins', value: 15 },
	{ label: '30 mins', value: 30 },
	{ label: '1 hour', value: 60 },
	{ label: '1 hour 30 mins', value: 90 },
	{ label: '2 hours', value: 120 },
	{ label: '3 hours', value: 180 },
]

export default function DayPlanner() {
	const [modalVisible, setModalVisible] = React.useState(null)
	const [editModalVisible, setEditModalVisible] = React.useState(null)

	const [timeDuration, setTimeDuration] = React.useState(30)
	const [taskName, setTaskName] = React.useState('')
	const [projectName, setProjectName] = React.useState('')
	const [date, setDate] = React.useState(new Date())

	const [durationInvalid, setDurationInvalid] = React.useState(false)
	const [taskNameInvalid, setTaskNameInvalid] = React.useState(false)
	const [projectNameInvalid, setProjectNameInvalid] = React.useState(false)
	const [dateInvalid, setDateInvalid] = React.useState(false)

	const {
		data: profileDataData,
		loading: profileDataLoading,
		error: profileDataError,
	} = useQuery(GET_PROFILE_DATA_QUERY)
	const {
		data: upcomingTaskData,
		loading: upcomingTaskLoading,
		error: upcomingTaskError,
	} = useQuery(GET_UPCOMING_TASK_QUERY)
	const {
		data: previousTaskData,
		loading: previousTaskLoading,
		error: previousTaskError,
	} = useQuery(GET_PREVIOUS_TASK_QUERY)

	const [addNewTask, { loading: addLoading }] = useMutation(ADD_NEW_TASK_MUTATION)

	const [cancelTask, { loading: cancelTaskLoading }] = useMutation(CANCEL_TASK_MUTATION)

	const onAddNewTask = React.useCallback(async () => {
		if (addLoading) {
			return
		}
		const trimmedProjectName = projectName.trim()
		const trimmedTaskName = taskName.trim()

		if (trimmedProjectName === '') {
			setProjectNameInvalid(true)
		} else if (projectNameInvalid) {
			setProjectNameInvalid(false)
		}
		if (trimmedTaskName === '') {
			setTaskNameInvalid(true)
		} else if (taskNameInvalid) {
			setTaskNameInvalid(false)
		}
		if (!date) {
			setDateInvalid(true)
		} else if (dateInvalid) {
			setDateInvalid(false)
		}
		if (!timeDuration) {
			setDurationInvalid(true)
		} else if (durationInvalid) {
			setDurationInvalid(false)
		}

		if (trimmedProjectName === '' || trimmedTaskName === '' || !date || !timeDuration) {
			return
		}

		const response = await addNewTask({
			variables: {
				taskDate: Math.round(new Date(date).getTime() / 1000),
				projectName: trimmedProjectName,
				taskName: trimmedTaskName,
				taskDuration: timeDuration,
			},
			refetchQueries: [{ query: GET_UPCOMING_TASK_QUERY }],
			awaitRefetchQueries: true,
		})

		if (response && response.data && response.data.addNewTask) {
			setTimeDuration(30)
			setTaskName('')
			setProjectName('')
			setDate(new Date())
		}
	}, [date, projectName, taskName, timeDuration, addNewTask])

	if (
		profileDataError ||
		previousTaskError ||
		upcomingTaskError ||
		!upcomingTaskData ||
		!previousTaskData ||
		!profileDataData
	) {
		return null
	}

	if (profileDataLoading || previousTaskLoading || upcomingTaskLoading) {
		return (
			<Root>
				<HeaderContainer>
					<h1 className="header">Loading...</h1>
				</HeaderContainer>
			</Root>
		)
	}

	return (
		<Root>
			<HeaderContainer>
				<h1 className="header">{`Hey ${profileDataData.getProfileData.profileName},`}</h1>
			</HeaderContainer>
			<AddSection>
				<div className="header">Plan Your Day</div>
				<div className="content">
					<div>
						<DatePicker
							defaultValue={moment(date, dateFormat)}
							value={moment(date, dateFormat)}
							format={dateFormat}
							onChange={(d) => setDate(d)}
							style={{ height: 40 }}
						/>
						{dateInvalid ? <div className="error">Task date is required</div> : null}
					</div>
					<div>
						<Input
							placeholder="Project Name *"
							className="project-name"
							value={projectName}
							onChange={(e) => setProjectName(e.target.value)}
						/>
						{projectNameInvalid ? (
							<div className="error">Project name is required</div>
						) : null}
					</div>
					<div>
						<Input
							placeholder="Task Name *"
							className="task-name"
							value={taskName}
							onChange={(e) => setTaskName(e.target.value)}
						/>
						{taskNameInvalid ? <div className="error">Task name is required</div> : null}
					</div>
					<div>
						<Select
							defaultValue={timeDuration}
							value={timeDuration}
							style={{ width: 120 }}
							onChange={setTimeDuration}>
							{timeDurations.map((t) => (
								<Option key={t.value} value={t.value}>
									{t.label}
								</Option>
							))}
						</Select>
						{durationInvalid ? (
							<div className="error">Task duration is required</div>
						) : null}
					</div>
					<Tooltip title="Add">
						<Button
							type="primary"
							className="add-task-button"
							loading={addLoading}
							shape="circle"
							icon="check"
							onClick={onAddNewTask}
						/>
					</Tooltip>
				</div>
			</AddSection>
			<UpcomingTaskWrapper
				upcomingTaskData={upcomingTaskData}
				setModalVisible={setModalVisible}
				setEditModalVisible={setEditModalVisible}
				cancelTaskLoading={cancelTaskLoading}
				cancelTask={cancelTask}
			/>
			<CompletedTaskWrapper
				previousTaskData={previousTaskData}
				setModalVisible={setModalVisible}
			/>
			<Modal
				width="80vw"
				title={null}
				footer={null}
				style={{ top: 80 }}
				visible={Boolean(modalVisible)}
				onOk={() => setModalVisible(false)}
				onCancel={() => setModalVisible(false)}>
				{modalVisible ? (
					<CompleteTask task={modalVisible} close={() => setModalVisible(false)} />
				) : null}
			</Modal>
			<Modal
				width="80vw"
				title="Edit task"
				footer={null}
				style={{
					top: 80,
				}}
				visible={Boolean(editModalVisible)}
				onOk={() => setEditModalVisible(false)}
				onCancel={() => setEditModalVisible(false)}>
				{editModalVisible ? (
					<EditUpcomingTask
						task={editModalVisible}
						onClose={() => setEditModalVisible(false)}
					/>
				) : null}
			</Modal>
		</Root>
	)
}

function EditUpcomingTask({ task, onClose }) {
	const [timeDuration, setTimeDuration] = React.useState(task.taskDuration)
	const [taskName, setTaskName] = React.useState(task.taskName)
	const [projectName, setProjectName] = React.useState(task.projectName)
	const [date, setDate] = React.useState(new Date(task.taskDate * 1000))

	const [durationInvalid, setDurationInvalid] = React.useState(false)
	const [taskNameInvalid, setTaskNameInvalid] = React.useState(false)
	const [projectNameInvalid, setProjectNameInvalid] = React.useState(false)
	const [dateInvalid, setDateInvalid] = React.useState(false)

	const [editUpcomingTask, { loading: editLoading }] = useMutation(
		EDIT_UPCOMING_TASK_MUTATION
	)

	const onEditTask = React.useCallback(async () => {
		if (editLoading) {
			return
		}
		const trimmedProjectName = projectName.trim()
		const trimmedTaskName = taskName.trim()

		if (trimmedProjectName === '') {
			setProjectNameInvalid(true)
		} else if (projectNameInvalid) {
			setProjectNameInvalid(false)
		}
		if (trimmedTaskName === '') {
			setTaskNameInvalid(true)
		} else if (taskNameInvalid) {
			setTaskNameInvalid(false)
		}
		if (!date) {
			setDateInvalid(true)
		} else if (dateInvalid) {
			setDateInvalid(false)
		}
		if (!timeDuration) {
			setDurationInvalid(true)
		} else if (durationInvalid) {
			setDurationInvalid(false)
		}

		if (trimmedProjectName === '' || trimmedTaskName === '' || !date || !timeDuration) {
			return
		}

		const response = await editUpcomingTask({
			variables: {
				taskId: task.taskId,
				taskDate: Math.round(new Date(date).getTime() / 1000),
				projectName: trimmedProjectName,
				taskName: trimmedTaskName,
				taskDuration: timeDuration,
			},
			refetchQueries: [{ query: GET_UPCOMING_TASK_QUERY }],
			awaitRefetchQueries: true,
		})

		if (response && response.data && response.data.editUpcomingTask) {
			setTimeDuration(30)
			setTaskName('')
			setProjectName('')
			setDate(new Date())
			onClose()
		}
	}, [date, projectName, taskName, timeDuration, editUpcomingTask])

	return (
		<AddSection>
			<div className="content">
				<div>
					<DatePicker
						defaultValue={moment(date, dateFormat)}
						value={moment(date, dateFormat)}
						format={dateFormat}
						onChange={(d) => setDate(d)}
						style={{ height: 40 }}
					/>
					{dateInvalid ? <div className="error">Task date is required</div> : null}
				</div>
				<div>
					<Input
						placeholder="Project Name *"
						className="project-name"
						value={projectName}
						onChange={(e) => setProjectName(e.target.value)}
					/>
					{projectNameInvalid ? (
						<div className="error">Project name is required</div>
					) : null}
				</div>
				<div>
					<Input
						placeholder="Task Name *"
						className="task-name"
						value={taskName}
						onChange={(e) => setTaskName(e.target.value)}
					/>
					{taskNameInvalid ? <div className="error">Task name is required</div> : null}
				</div>
				<div>
					<Select
						defaultValue={timeDuration}
						value={timeDuration}
						style={{ width: 120 }}
						onChange={setTimeDuration}>
						{timeDurations.map((t) => (
							<Option key={t.value} value={t.value}>
								{t.label}
							</Option>
						))}
					</Select>
					{durationInvalid ? (
						<div className="error">Task duration is required</div>
					) : null}
				</div>
				<Tooltip title="Update">
					<Button
						type="primary"
						className="add-task-button"
						loading={editLoading}
						shape="circle"
						icon="check"
						onClick={onEditTask}
					/>
				</Tooltip>
			</div>
		</AddSection>
	)
}

function UpcomingTask({
	task,
	isLoading,
	handleProgressed,
	cancelTaskLoading,
	cancelTask,
	setEditModalVisible,
}) {
	// eslint-disable-next-line prefer-destructuring
	let taskDate = task.taskDate * 1000
	if (isToday(taskDate)) {
		taskDate = 'Today'
	} else if (isTomorrow(taskDate)) {
		taskDate = 'Tomorrow'
	} else {
		taskDate = format(taskDate, 'MMMM d, yyyy')
	}

	return (
		<div key={task.taskId} className="task">
			<div className="day">
				<div className={`chip ${taskDate === 'Today' ? 'today' : ''}`}>{taskDate}</div>
			</div>
			<div className="task-name">
				{`${task.projectName} - ${task.taskName}`}
				<br />
				<span>{`Estimated to complete in ${timeConvert(task.taskDuration)}`}</span>
			</div>
			<div className="action">
				<div
					className="done"
					onClick={() => {
						if (isLoading) {
							return
						}
						handleProgressed({ ...task, type: 'update' })
					}}>
					<>
						{isLoading ? (
							'...'
						) : (
							<>
								<Icon type="check" style={{ marginRight: 5 }} />
								Progressed
							</>
						)}
					</>
				</div>
				<div
					className="edit"
					onClick={() => {
						if (isLoading) {
							return
						}
						setEditModalVisible(task)
					}}>
					<Icon type="edit" style={{ marginRight: 5 }} />
					Edit
				</div>
				<div
					className="cancel"
					onClick={() => {
						if (cancelTaskLoading || isLoading) {
							return
						}
						cancelTask({
							variables: {
								taskId: task.taskId,
							},
							awaitRefetchQueries: true,
							refetchQueries: [
								{ query: GET_UPCOMING_TASK_QUERY },
								{ query: GET_PREVIOUS_TASK_QUERY },
							],
						})
					}}>
					<Icon type="close" style={{ marginRight: 5 }} />
					{`Cancel${cancelTaskLoading ? 'ling...' : ''}`}
				</div>
			</div>
		</div>
	)
}

function CompletedTask({ task, setModalVisible }) {
	return (
		<div key={task.taskId} className="task">
			<div className={`icon ${task.taskStatus}`}>
				<Icon
					type={`${task.taskStatus === 'completed' ? 'check' : 'close'}-circle`}
					theme="filled"
				/>
			</div>
			<div className="task-content">
				<div className="task-name">{`${task.projectName} - ${task.taskName}`}</div>
				{task.taskNotes ? <div className="task-desc">{task.taskNotes}</div> : null}
				<div className={`task-status ${task.taskStatus}`}>
					{task.taskStatus === 'completed'
						? `${task.percentage}% Completed ${
								task.startTime > 0 && task.endTime > 0
									? `(${format(task.startTime * 1000, 'h:mm a')} - ${format(
											task.endTime * 1000,
											'h:mm a'
									  )})`
									: ''
						  }`
						: 'Cancelled'}
				</div>
			</div>
			<div className="action">
				<div className="edit" onClick={() => setModalVisible({ ...task, type: 'edit' })}>
					<Icon type="edit" style={{ marginRight: 5 }} />
					Edit
				</div>
			</div>
		</div>
	)
}

function CompletedTaskWrapper({ previousTaskData, setModalVisible }) {
	return (
		<CompletedSection>
			<div className="header">
				{`Previous Tasks ${
					previousTaskData.getCompletedWork.taskCount
						? `[${previousTaskData.getCompletedWork.taskCount} Task${
								previousTaskData.getCompletedWork.taskCount > 1 ? 's' : ''
						  }${
								timeConvert(previousTaskData.getCompletedWork.taskTotalHours) !== ''
									? `, ${timeConvert(previousTaskData.getCompletedWork.taskTotalHours)}`
									: ''
						  }]`
						: ''
				}`}
			</div>
			<div className="content">
				{previousTaskData.getCompletedWork.completedTaskDetails &&
				previousTaskData.getCompletedWork.completedTaskDetails.length > 0 ? (
					<>
						{previousTaskData.getCompletedWork.completedTaskDetails.map((task) => {
							return <CompletedTask task={task} setModalVisible={setModalVisible} />
						})}
					</>
				) : (
					<div>Yet to complete the first task.</div>
				)}
			</div>
		</CompletedSection>
	)
}

function UpcomingTaskWrapper({
	upcomingTaskData,
	setModalVisible,
	setEditModalVisible,
	cancelTaskLoading,
	cancelTask,
}) {
	const [isLoading, setLoading] = React.useState(false)

	const showConfirm = React.useCallback(
		(params) => {
			confirm({
				title: 'Do you want to delete these items?',
				content: 'When clicked the OK button, this dialog will be closed after 1 second',
				onOk() {
					setModalVisible(params)
				},
				onCancel() {},
			})
		},
		[setModalVisible]
	)

	// const handleProgressed = React.useCallback(
	// 	async (params) => {
	// 		if (upcomingTaskData.getUpcomingTask.taskCount === 1) {
	// 			setLoading(true)
	// 			const isSharingResponse = await client.query({
	// 				query: IS_SHARING_QUERY,
	// 			})

	// 			if (
	// 				isSharingResponse &&
	// 				isSharingResponse.data &&
	// 				isSharingResponse.data.isSharing != null
	// 			) {
	// 				setLoading(false)
	// 				// check for screen sharing
	// 				if (isSharingResponse.data.isSharing) {
	// 					showConfirm(params)
	// 					return
	// 				}
	// 				setModalVisible(params)
	// 			}
	// 			return
	// 		}
	// 		setModalVisible(params)
	// 	},
	// 	[setModalVisible, showConfirm]
	// )
	const handleProgressed = React.useCallback(
		async (params) => {
			setModalVisible(params)
		},
		[setModalVisible]
	)

	return (
		<UpcomingSection>
			<div className="header">
				{`Planned Tasks ${
					upcomingTaskData.getUpcomingTask.taskCount
						? `[${upcomingTaskData.getUpcomingTask.taskCount} Task${
								upcomingTaskData.getUpcomingTask.taskCount > 1 ? 's' : ''
						  }${
								timeConvert(upcomingTaskData.getUpcomingTask.taskTotalHours) !== ''
									? `, ${timeConvert(upcomingTaskData.getUpcomingTask.taskTotalHours)}`
									: ''
						  }]`
						: ''
				}`}
			</div>
			<div className="content">
				{upcomingTaskData.getUpcomingTask.taskDetails &&
				upcomingTaskData.getUpcomingTask.taskDetails.length > 0 ? (
					<>
						{upcomingTaskData.getUpcomingTask.taskDetails.map((task) => {
							return (
								<UpcomingTask
									task={task}
									isLoading={isLoading}
									handleProgressed={handleProgressed}
									setEditModalVisible={setEditModalVisible}
									cancelTaskLoading={cancelTaskLoading}
									cancelTask={cancelTask}
								/>
							)
						})}
					</>
				) : (
					<div>Yet to plan new task</div>
				)}
			</div>
		</UpcomingSection>
	)
}
