/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Icon, Tooltip } from 'antd'
// Import required components from ReactIcons
import { IoMdAddCircle, IoIosImages } from 'react-icons/io'
// Import required components from FontAwesome
import { FaRegCommentDots } from 'react-icons/fa'
// Import Custom Styles
import styles from '../styles'
// import utils file
import util from '../../../utils'
/**
 * This class component is used to Subscription for webapp photo.It should be
 * open for all site photos.
 * @returns {photo subscription}
 */

class PhotoSubscription extends React.Component {
	componentDidMount() {
		const { subscribeToNewPhotos } = this.props
		this.subscription = subscribeToNewPhotos()
	}

	componentWillUnmount() {
		if (this.subscription) {
			this.subscription()
		}
		// console.log('bye')
	}

	render() {
		const { data, flatternArray, imageUpload, setSelected, getImage } = this.props
		return (
			<>
				{/* project photos data looping start */}
				{data.projectPhotos.map((photoData, photoIndex) => (
					<div key={`photo-${photoIndex}`}>
						{photoData.feeds ? ( // empty date checking
							// Site photo particular date images display
							<div style={styles.dateFilter} key={photoIndex}>
								{/* date display */}
								{util.convertDatetoTime(photoData.date) === 'Today' ||
								photoData.feeds.length > 0 ? (
									<span style={styles.dateDisplay}>
										<Icon type="calendar" /> {util.convertDatetoTime(photoData.date)}
									</span>
								) : null}

								{util.convertDatetoTime(photoData.date) === 'Today' ? (
									<div style={styles.imageGrid}>
										{/* <Tooltip placement="right" title="You can upload one or more photos"> */}
										<div
											style={styles.uploadCard}
											className="uploadCardClass"
											onClick={() => imageUpload()}>
											<div style={styles.uploadDiv}>
												<div>
													<IoIosImages style={styles.uploadAdd} />
												</div>
												<input
													onChange={async (e) => {
														console.log('calling')
														const files = e.target.files
                            await getImage(files);
                            // e.target.files =''
                          }}
                          onClick={(e) => e.target.value=null}
													type="file"
													multiple
													accept="image/jpeg,image/png"
													id="upload"
												/>
												Upload photo(s)
												<div style={styles.mutedText}>
													{' '}
													You can upload upto ten photos
												</div>
											</div>
										</div>
										{/* </Tooltip> */}
									</div>
								) : null}

								{/* date related images add */}
								{photoData.feeds.length > 0 ? (
									<div style={styles.imageGrid}>
										{photoData.feeds.map((element, index) => (
											<div
												key={`${element.uri}-${index}`}
												onClick={() =>
													// console.log(index, element, flatternArray,flatternArray[index])
													setSelected(index, element, flatternArray)
												}
												style={{
													...styles.cardstyle,
													...(element.isViewed ? styles.addBorderColor : null),
												}}>
												<div style={styles.imageBox}>
													{/* poster name display */}
													<div style={styles.posterName}>
														{util.getInitials(element.uploadedProfile.name)}
													</div>
													{/* image display */}
													<img
														className="imageHover"
														style={styles.siteImages}
														src={
															element.thumbnailUri || element.uri || element.qualityUri
														}
														alt="Site Photos"
													/>
													{/* image description display */}
													{element.description !== '' && element.description !== null ? (
														<div style={styles.commentDetail}>
															<span style={styles.posterDescription}>
																{element.description}
															</span>

															{element.commentCount > 0 ? (
																<span style={styles.commentIcon}>
																	<FaRegCommentDots />
																</span>
															) : null}
														</div>
													) : (
														<div>
															{element.commentCount > 0 ? (
																<span style={styles.commentIconWithoutBackgroud}>
																	<FaRegCommentDots />
																</span>
															) : null}
														</div>
													)}
													{/* image description display end */}
												</div>
											</div>
										))}
									</div>
								) : null}

								{/* date related images add end */}
							</div>
						) : null}
						{/* empty date checking end */}
					</div>
				))}
			</>
		)
	}
}
export default PhotoSubscription
