import React from 'react'
import { Row, Col } from 'antd'
import { MdClose } from 'react-icons/md'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { Select } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Upload, message, Button } from 'antd'
import { CREATE_TICKET } from '../query'
import { Mutation } from 'react-apollo'
import styles from './styles'
import './index.css'

const { Option } = Select

function getBase64(img, callback) {
	const reader = new FileReader()
	reader.addEventListener('load', () => callback(reader.result))
	reader.readAsDataURL(img)
}

function beforeUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!')
	}
	const isLt2M = file.size / 1024 / 1024 < 20
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!')
	}
	return isJpgOrPng && isLt2M
}

const teamArray = [
	{ id: 'HR Admin', value: 'HR Admin' },
	{ id: 'Accounts Admin', value: 'Accounts Admin' },
	{
		id: 'Software Developer Admin',
		value: 'Software Developer Admin',
	},
	{ id: 'Management', value: 'Management' },
]

class CreateTicket extends React.Component {
	state = {
		loading: false,
		teamId: null,
		imageUrl:
			this.props.messageData.messageType === 'image'
				? this.props.messageData.content.split(',')[0]
				: null,
		caption: null,
		fileList: [],
		title:
			this.props.messageData.messageType === 'image'
				? this.props.messageData.caption
				: this.props.messageText,
	}
	handleChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true })
			return
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) =>
				this.setState({
					imageUrl,
					loading: false,
					fileList: info.fileList,
				})
			)
		}
	}
	render() {
		const { imageUrl, title, teamId, fileList } = this.state
		const {
			handleModalCancel,
			handleModalOk,
			messageId,
			groupId,
			hash,
			refetch,
		} = this.props

		const uploadButton = (
			<div>
				{this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
				<div className="ant-upload-text">Click here to upload</div>
			</div>
		)

		return (
			<Mutation mutation={CREATE_TICKET}>
				{(createTicket, { data, loading }) => (
					<Modal type="createTicket">
						<ModalHeader type="createTicket">
							<div style={styles.modalHeaderFlex}>
								<span onClick={handleModalCancel} style={styles.modalHeaderTextColor}>
									<MdClose size={25} style={styles.modalHeaderIcon} />
								</span>
								<span style={styles.modalHeaderName}>Create a ticket as</span>
							</div>
						</ModalHeader>
						<ModalButtonContainer type="createTicket">
							<div className="createTicket">
								<Row>
									<Col span={24}>
										<div style={styles.createTicket.ticketTitleDiv}>
											<h4 style={styles.fontWeightBold}>Add a title</h4>
											<input
												type="text"
												placeholder="Add a title"
												maxlength="280"
												onChange={(event) => {
													this.setState({ title: event.target.value })
												}}
												value={this.state.title}
												style={styles.createTicket.ticketName}
											/>
											<span style={{ opacity: 0.7 }}>
												{this.state.title
													? `Note: Remaining ${280 -
															this.state.title.length} characters left`
													: `Note: Ticket title can be maximum 280 characters`}
											</span>
										</div>

										<div style={styles.createTicket.ticketTitleDiv}>
											<h4 style={styles.fontWeightBold}>Select Dept</h4>
											<Select
												showSearch
												style={styles.fullWidth}
												placeholder="Select a department"
												optionFilterProp="children"
												onChange={(value) => {
													this.setState({ teamId: value })
												}}
												filterOption={(input, option) =>
													option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}>
												{teamArray.map((data) => {
													return (
														<Option key={data.id} value={data.id}>
															{data.value}
														</Option>
													)
												})}
											</Select>
										</div>
										<div style={styles.createTicket.ticketTitleDiv}>
											{imageUrl ? (
												<div
													style={styles.createTicket.deletePhoto}
													onClick={() => {
														this.setState({ imageUrl: null })
													}}>
													Delete photo
												</div>
											) : null}

											<div style={styles.fullWidth}>
												<Upload
													name="avatar"
													listType="picture-card"
													className="avatar-uploader"
													showUploadList={false}
													action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
													beforeUpload={beforeUpload}
													style={styles.fullWidth}
													onChange={this.handleChange}>
													{imageUrl ? (
														<img src={imageUrl} alt="avatar" style={styles.fullWidth} />
													) : (
														uploadButton
													)}
												</Upload>
											</div>
										</div>

										<div style={styles.createTicket.ticketUpdateDiv}>
											<Button
												style={styles.createTicket.closeTicket}
												onClick={handleModalCancel}>
												Cancel
											</Button>
											<Button
												loading={loading}
												style={styles.createTicket.sendTicket}
												onClick={async () => {
													const { data } = await createTicket({
														variables: {
															groupId: groupId,
															messageId: messageId,
															hash: hash,
															edit: title,
															spaceId: [],
															dept: teamId,
															staticImage: '',
															image: fileList,
															isStatic: false,
															mentionedTo: [],
														},
													})
													if (data.createTicket) {
														handleModalOk({ refetch, type: 'createTicket' })
													}
												}}>
												Create
											</Button>
										</div>
									</Col>
								</Row>
							</div>
						</ModalButtonContainer>
					</Modal>
				)}
			</Mutation>
		)
	}
}
export default CreateTicket
