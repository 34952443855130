import gql from 'graphql-tag'

export const GET_PROPOSAL_TIMELINE_LIST = gql`
	query ProposalTimelineList($enquiryId: String) {
		proposalTimelineList(enquiryId: $enquiryId) {
			date
			context
		}
	}
`
