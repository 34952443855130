const styles = {
	/**
	 * This styles is used to add a render fuction css.
	 */

	// loading icon css
	loadingIcon: { fontSize: 50 },
	loadingIcons: { fontSize: 30 },
	loadIconStyles: { padding: '30px 400px' },
	loadIconStyle: { padding: '30px 400px' },

	fileConvertor: {
		height: 'calc(100vh - 84px)',
		padding: '1%',
		background: 'white',
	},
	inputDiv: {
		padding: '1%',
		display: 'flex',
		justifyContent: 'center',
	},
	sendButton: {
		cursor: 'pointer',
	},
	tableHeader: {
		textAlign: 'center',
		fontSize: '14px',
		fontWeight: '500',
	},
	contentDiv: {
		padding: '1% 1% 1% 0%',
	},
	tableDiv: {
		// width: '100%',
		// height: 'calc(86vh - 84px)',
		// overflowY: 'auto !important',
		// overflowX: 'hidden',
		padding: '1% 1% 1% 0%',
		fontSize: '10px',
	},

	title: {
		fontFamily: 'arial, sans-serif',
		position: 'relative',
		top: '-9px',
	},
	tableContent: {
		textAlign: 'center',
		fontFamily: '"Trebuchet MS", Arial, Helvetica, sans-serif',
		borderCollapse: 'collapse',
		border: '3px solid #ddd',
		width: '100%',
		// height: 'calc(86vh - 84px)',
		height: 'calc(86vh - 161px)',
		display: 'block',
		overflow: 'scroll',
	},
	ShowClosedTickets: {
		fontSize: '10px',
		cursor: 'pointer',
		position: 'absolute',
		top: '193px',
		right: '160px',
	},
	exportButton: {
		fontSize: '10px',
		// float:'right',
		// marginBottom: '1%'
		position: 'relative',
		top: '6px',
		left: '89%',
	},
	filter: {
		width: '13vw',
	},
	colorfilter: {
		width: '13vw',
	},
}

export default styles
