// Importing required components from ANTD
import { Avatar, Card, Divider, Empty, Icon, Spin, Tooltip, Typography } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
// Query component of React Apollo to fetch data
import { Query } from 'react-apollo'
import urlRegex from 'url-regex'
import { AiOutlineFileProtect } from 'react-icons/ai'
import { format } from 'date-fns'
import noProfileUrlAvatar from '../../../assets/no_picture_url.png'
import utils from '../../../utils'
import './index.css'
// Query to fetch CRM Details on load
import { CrmChatData } from './query'
// Component CSS
import styles from './styles'
import AddNotes from './AddNotes/index'
import SomeErrorHasOccured from '../../UtilComponents'

const { Meta } = Card
const { stringDecode } = utils

class CrmChat extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { enquiryId, enquiryType } = this.props
		return (
			<Query
				query={CrmChatData}
				variables={{
					enquiryId,
				}}>
				{({ data, loading, error, refetch }) => {
					if (loading) {
						return (
							<Spin
								indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
								style={{ padding: '30px 52px' }}
							/>
						)
					}
					if (error) {
						return (
							<div>
								<div span={12} style={styles.chatDiv}>
									<SomeErrorHasOccured />
								</div>
							</div>
						)
					}
					if (data) {
						return (
							<div>
								<div span={12} style={styles.chatDiv}>
									{!data.crmChatDetails[0] && (
										<Empty
											style={{
												display: 'inline',
												marginBottom: '45%',
												filter: 'saturate(4)',
											}}
											description={
												<Typography.Text
													style={{ fontSize: 15, color: '#8c8c8c', fontWeight: 400 }}>
													Start adding notes . . .
												</Typography.Text>
											}
										/>
									)}
									{data.crmChatDetails.map((values, chatIndex) => {
										return (
											<div key={chatIndex}>
												<Divider
													style={{
														color: '#3c3c3c',
														marginRight: 10,
														zIndex: 12,
														position: 'sticky',
														top: 0,
														background: '#ebebeb',
													}}>
													{values.date}
												</Divider>
												{values.chat.map((crmChat, crmChatIndex) => {
													return (
														<div key={crmChatIndex}>
															{crmChat.type === 'events' && (
																<div key={crmChatIndex}>
																	<p
																		style={{
																			textAlign: 'center',
																			marginTop: 10,
																			marginLeft: 10,
																			marginRight: 10,
																			fontSize: 14,
																		}}>
																		{crmChat.context}
																	</p>
																</div>
															)}
															{crmChat.type === 'notes' && (
																<div key={crmChatIndex}>
																	{/* {crmChat.notes.match(urlRegex()) && (
																		<div
																			style={{
																				position: 'absolute',
																				color: '#000',
																				marginLeft: 14,
																				top: '70%',
																				backgroundColor: '#2d2d2d',
																				borderRadius: 50,
																				height: 42,
																				width: 42,
																			}}>
																			<a
																				href={crmChat.notes
																					.replace('?&view=client', '')
																					.match(urlRegex())}
																				style={{
																					marginLeft: 7,
																					textAlign: 'left',
																				}}
																				target="_blank"
																				rel="noopener noreferrer">
																				<Tooltip
																					title="Click to view Proposal"
																					placement="bottom">
																					<AiOutlineFileProtect
																						style={{
																							fontSize: 30,
																							color: '#fff',
																							marginTop: 7,
																						}}
																					/>
																				</Tooltip>
																			</a>
																		</div>
																	)} */}
																	<Card
																		size="small"
																		style={{
																			width: 300,
																			float: 'right',
																			backgroundColor: crmChat.notes.match(urlRegex())
																				? '#beea8a'
																				: '#b7dff3',
																			borderRadius: 5,
																			marginRight: 6,
																			marginBottom: 10,
																		}}>
																		<Meta
																			avatar={
																				crmChat.addedByPicture ? (
																					<Avatar src={crmChat.addedByPicture} />
																				) : (
																					<Avatar
																						style={{
																							color: '#fff',
																							backgroundColor: '#6b86a2',
																							fontSize: 14,
																						}}>
																						{crmChat.addedByName
																							.match(/\b(\w)/g)
																							.join('')}
																					</Avatar>
																				)
																			}
																			title={
																				<span style={{ fontSize: 15 }}>
																					{crmChat.addedByName}
																				</span>
																			}
																		/>
																		<p
																			style={{
																				textAlign: 'justify',
																				fontSize: 14,
																				marginTop: 8,
																				marginBottom: 0,
																			}}>
																			{/* {crmChat.notes &&
																			stringDecode(
																				crmChat.notes.replace(/<\/?[^>]+(>|$)/g, '')
																			)} */}
																			{stringDecode(
																				crmChat.notes.replace(/<\/?[^>]+(>|$)/g, '')
																			)}
																		</p>
																		{crmChat.notes.match(urlRegex()) && (
																			<>
																				<a
																					href={crmChat.notes
																						.replace('?&view=client', '')
																						.match(urlRegex())}
																					style={{
																						marginTop: 10,
																						textAlign: 'left',
																					}}
																					target="_blank"
																					rel="noopener noreferrer">
																					Click to view proposal
																				</a>
																			</>
																		)}
																		<p
																			style={{
																				textAlign: 'right',
																				fontSize: 12,
																				fontStyle: 'italic',
																				marginBottom: -5,
																			}}>
																			{format(new Date(crmChat.time * 1000), 'hh:mm a')}
																		</p>
																	</Card>
																</div>
															)}
														</div>
													)
												})}
											</div>
										)
									})}
								</div>

								<AddNotes
									enquiryId={enquiryId}
									enquiryType={enquiryType}
									refetchChatData={refetch}
								/>
							</div>
						)
					}
				}}
			</Query>
		)
	}
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
CrmChat.propTypes = {
	enquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
}

CrmChat.defaultProps = {
	enquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
}

export default CrmChat
