import { useMutation } from '@apollo/react-hooks'
import {
	Button,
	Col,
	DatePicker,
	Dropdown,
	Modal,
	notification,
	Row,
	TimePicker,
	Typography,
	message,
} from 'antd'
import moment from 'moment'
import { format, startOfToday, add } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { ADD_FOLLOWUP_TIME_MUTATION } from './mutation'

const { confirm } = Modal
message.config({
	top: 100,
	duration: 4,
	maxCount: 3,
})

function confirmFollowupTime(followupDateTime) {
	const { followupDate, followupTime } = followupDateTime
	if (!followupDate && !followupTime) {
		// message.warning('Please select a followup date & time . . . ')
		return false
	}
	if (!followupDate) {
		// message.warning('Please select a followup date . . . ')
		return false
	}
	if (!followupTime) {
		// message.warning('Please select a followup time . . . ')
		return false
	}

	return true
}
export default function AddToFollowup(props) {
	const {
		activeFollowupOptions,
		currentEnquiryId,
		disabledDate,
		enquiryType,
		followupActive,
	} = props

	const [followupDateTime, setFollowupDateTime] = useState({
		followupDate: null,
		followupTime: null,
	})

	const followUpData = React.useRef(followupDateTime)

	useEffect(() => {
		followUpData.current = followupDateTime
	}, [followupDateTime])

	const [addEnquiryFollowup] = useMutation(ADD_FOLLOWUP_TIME_MUTATION, {
		variables: {
			enquiryId: currentEnquiryId,
			followupTimestamp: moment(
				`${followupDateTime.followupDate} ${followupDateTime.followupTime}`,
				'MMMM Do, YYYY h:mm a'
			).unix(),
			stageFrom: enquiryType,
		},
	})

	function newFollowupTimeSelect(date, dateString) {
		setFollowupDateTime((prev) => {
			confirmFollowupTime({
				...prev,
				followupTime: dateString,
			})
			return {
				...prev,
				followupTime: dateString,
			}
		})
	}

	function newFollowupDateSelect(date, dateString) {
		setFollowupDateTime((prev) => {
			confirmFollowupTime({
				...prev,
				followupDate: dateString,
			})
			return {
				...prev,
				followupDate: dateString,
			}
		})
	}

	async function confirmAddToFollowup() {
		confirm({
			title: 'Please select the followup date & time.',
			content: (
				<div>
					<Row style={{ marginTop: 15, marginBottom: 15 }}>
						<Col span={6}>
							<Typography.Text strong style={{ fontSize: 16 }}>
								Date :
							</Typography.Text>
						</Col>
						<Col span={18}>
							<DatePicker
								placeholder={format(startOfToday(), 'MMMM do, yyyy')}
								disabledDate={disabledDate}
								onChange={(date, dateString) => newFollowupDateSelect(date, dateString)}
								format="MMMM Do, YYYY"
							/>
						</Col>
					</Row>
					<Row style={{ marginBottom: 10 }}>
						<Col span={6}>
							<Typography.Text strong style={{ fontSize: 16 }}>
								Time :
							</Typography.Text>
						</Col>
						<Col span={18}>
							<TimePicker
								placeholder={format(add(new Date(), { hours: 3 }), 'h:mm a')}
								onChange={(date, dateString) => newFollowupTimeSelect(date, dateString)}
								use12Hours
								format="h:mm a"
							/>
						</Col>
					</Row>
				</div>
			),
			okText: 'Confirm',
			centered: true,
			onOk: async () => {
				console.log(followUpData.current)
				const checkDateTime = confirmFollowupTime(followUpData.current)

				if (checkDateTime) {
					try {
						await addEnquiryFollowup()
						props.refetch()
						notification.success({
							message: 'Enquiry assigned to Followup',
							description: 'Successfully',
							style: { marginTop: 50 },
						})
						setFollowupDateTime((prev) => {
							return {
								...prev,
								followupDate: null,
								followupTime: null,
							}
						})
						setTimeout(() => {
							props.closeModal()
						}, 1000)
					} catch (error) {
						return
					}
					return
				}
				setFollowupDateTime((prev) => {
					return {
						...prev,
						followupDate: null,
						followupTime: null,
					}
				})
				message.warning('Please select a followup date & time . . . ')
				confirmAddToFollowup()
			},
			onCancel() {
				// console.log('op')
			},
		})
	}

	return (
		<>
			<Row style={{ borderTop: '1px solid #eee' }}>
				<Col span={16} style={{ marginTop: 8 }}>
					<Typography.Text strong style={{ fontSize: 16 }}>
						Followup
					</Typography.Text>
				</Col>

				<Col span={8} style={{ marginTop: 5 }}>
					{followupActive === 'yes' && (
						<Dropdown
							overlay={activeFollowupOptions}
							trigger={['click']}
							style={{ float: 'right' }}
							placement="bottomRight">
							<Button type="link" style={{ marginLeft: 15, float: 'right' }}>
								Options
							</Button>
						</Dropdown>
					)}
					{followupActive === 'no' || !followupActive ? (
						<Button type="link" style={{ marginLeft: -8 }} onClick={confirmAddToFollowup}>
							Add to followup
						</Button>
					) : null}
				</Col>
			</Row>
		</>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
AddToFollowup.propTypes = {
	activeFollowupOptions: PropTypes.func,
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	disabledDate: PropTypes.func,
	enquiryType: PropTypes.string,
	followupActive: PropTypes.string,
	refetch: PropTypes.func,
}

AddToFollowup.defaultProps = {
	activeFollowupOptions: PropTypes.func,
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	disabledDate: PropTypes.func,
	enquiryType: PropTypes.string,
	followupActive: PropTypes.string,
	refetch: PropTypes.func,
}
