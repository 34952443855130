import { Col, Collapse, Icon, Row, Spin, Button } from 'antd'
import { format } from 'date-fns' // Date FNS for time format functions
import PropTypes from 'prop-types'
import React from 'react'
import { Query } from 'react-apollo'
import { GET_DUPLICATE_ENQUIRY_LIST } from './query'

// Importing required components from ANTD

// Query to fetch CRM Details on load

// Component CSS

const { Panel } = Collapse

export default function DuplicateEnquiryList(props) {
	const { currentEnquiryId, contactNo, email, handlers } = props
	// console.log(props)

	function openDuplicateEnquiryModal(enquiryId, details) {
		const laneId = 'duplicateEnquiryModal'
		handlers.renderCrmModal(enquiryId, details, laneId)
	}
	return (
		<Query
			query={GET_DUPLICATE_ENQUIRY_LIST}
			variables={{
				enquiryId: currentEnquiryId,
				contactNo,
				emailId: email,
			}}>
			{({ data, loading }) => {
				if (loading) {
					return (
						<Spin
							indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
							style={{ padding: '30px 52px' }}
						/>
					)
				}

				return (
					<div
						style={{
							backgroundColor: '#fff',
							height: 300,
							width: '-webkit-fill-available',
							overflowY: 'scroll',
							borderRadius: 5,
							marginLeft: -38,
						}}>
						<Collapse defaultActiveKey={['0']}>
							{data.duplicateEnquiryList.map((values, panelIndex) => {
								return (
									<Panel
										key={panelIndex}
										header={
											<div>
												<Row>
													<Col>
														<span style={{ fontWeight: 500, fontSize: 16 }}>
															{values.details.name && values.details.name}
															{!values.details.name && 'Anonymous'}
														</span>
													</Col>
													<Col>
														<span>
															{values.contactMatch &&
																values.emailMatch &&
																'Email & Mobile Number matched'}
															{values.contactMatch &&
																!values.emailMatch &&
																'Mobile Number matched'}
															{values.emailMatch &&
																!values.contactMatch &&
																'Email ID matched'}
														</span>
													</Col>
													<Col>
														<span>
															{format(
																new Date(values.details.enquiryTimestamp * 1000),
																'MMM dd, yyyy - hh:mm a'
															)}
														</span>
													</Col>
												</Row>

												<Button
													onClick={() =>
														openDuplicateEnquiryModal(values.id, values.details)
													}>
													Open this enquiry
												</Button>
											</div>
										}>
										<Row>
											<Col>
												<strong> Mobile No : </strong>
												{values.details.contactNo}
											</Col>
											<Col>
												<strong> Address : </strong>
												{values.details.address}
											</Col>
											<Col>
												<strong> Email ID : </strong>
												{values.details.email}
											</Col>
											<Col>
												<strong> Scope : </strong>
												{values.details.scope}
											</Col>
											<Col>
												<strong>Typology : </strong>
												{values.details.typology}
											</Col>
											<Col>
												<strong> Area : </strong>
												{values.details.area} {values.details.areaType}
											</Col>
											<Col>
												<strong>Stage : </strong>
												{values.details.enquiryType}
											</Col>
										</Row>
									</Panel>
								)
							})}
						</Collapse>
					</div>
				)
			}}
		</Query>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
DuplicateEnquiryList.propTypes = {
	currentEnquiryId: PropTypes.string,
	contactNo: PropTypes.string,
	email: PropTypes.func,
}

DuplicateEnquiryList.defaultProps = {
	currentEnquiryId: PropTypes.string,
	contactNo: PropTypes.string,
	email: PropTypes.func,
}
