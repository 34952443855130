const styles = {
	chatDashboard: {
		display: 'flex',
		justifyContent: 'center',
		background: '#f8f9fb',
		flexGrow: '1',
		alignItems: 'center',
		height: '100%',
	},
	chatBoardDiv: { width: '500px', textAlign: 'center' },
	chatBoardImage: { width: '250px' },
}

export default styles
