import gql from 'graphql-tag'

export const UserProfileDetails = gql`
	query userProfileDetails($userHash: String) {
		userProfileDetails(userHash: $userHash) {
			profileName
			profileUrl
			pictureUrl
			personalDetails {
				title
				value
			}
			companyDetails {
				title
				value
			}
			bankDetails {
				title
				value
			}
		}
	}
`
