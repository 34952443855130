// Import react component
import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { Icon, Avatar } from 'antd'

const stageColor = {
	Architecture: '#af4a7eb0',
	Interiors: '#25495ab0',
	Landscape: '#6f7d5f',
	Lighting: '#af714c',
	Acoustics: '#69ad92',
	'Facade Design': '#815daf',
}

// Load list of deliverables in stepConfig Component
class StepList extends React.Component {
	render() {
		// Deconstruct props
		const { data } = this.props
		return [
			data.length ? (
				data.map((val) => {
					// Stringify val object
					const result = JSON.stringify(val)

					// Deconstruct val object
					const { scopeHead, scopeName, scopeId, scopeType, stageOrder } = val
					const iconColor = stageColor[scopeHead]

					// Scope type icon color

					let classStageName
					if (scopeType === 'stage' || scopeType === 'begin') {
						if (stageOrder || scopeType === 'begin') {
							classStageName = 'stageSelected'
						} else {
							classStageName = 'stageSelectedError'
						}
					} else {
						classStageName = 'null'
					}

					return (
						<li key={scopeId} data-id={result} className={classStageName}>
							{scopeType === 'step' ? (
								<div>
									<Avatar
										style={{ backgroundColor: iconColor, verticalAlign: 'middle' }}
										size="medium">
										{scopeHead[0].toUpperCase()}
									</Avatar>
									<span className="stageNameMarginLeft20">{scopeName}</span>
								</div>
							) : (
								<div>
									<span className="stageNameMarginLeft20">{scopeName}</span>
								</div>
							)}
						</li>
					)
				})
			) : (
				// Empty step handler
				<li key={uniqueId()} style={{ backgroundColor: 'white' }}>
					<div>
						<Avatar size="medium" className="emptyDeliverableList">
							<Icon type="exclamation-circle" className="emptyDeliverableListIcon" />
						</Avatar>
						<span className="stageNameMarginLeft20">No Step Found</span>
					</div>
				</li>
			),
		]
	}
}

// export stepList component
export default StepList
