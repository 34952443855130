// Importing required components from ANTD
import {
	Avatar,
	Card,
	Modal,
	Divider,
	Empty,
	Icon,
	Spin,
	Tooltip,
	Button,
	Row,
	Col,
	Comment,
	Typography,
	Collapse,
} from 'antd'
import $ from 'jquery'
import PropTypes from 'prop-types'
import React from 'react'
// Query component of React Apollo to fetch data
import { Query } from 'react-apollo'
import urlRegex from 'url-regex'
import { FiExternalLink } from 'react-icons/fi'
import { format } from 'date-fns'
// import noProfileUrlAvatar from '../../../assets/no_picture_url.png'
// import utils from '../../../utils'
import { GetFileComments } from './query'
import AddComments from '../AddComments'
import AddReplies from '../AddReplies'
// import AddNotes from './AddNotes/index'
// import SomeErrorHasOccured from '../../UtilComponents'
import './commentsList.css'
import { icons } from 'react-icons/lib/cjs'
const { Meta } = Card
const { Panel } = Collapse
class CommentsList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
		}
	}

	highlightComment(commentId) {
		if ($(`#${commentId}_comment`).css('border-color') === `rgb(255, 255, 255)`) {
			$(`#${commentId}_comment`).css('border', `2px solid #f3b300`)
		} else if ($(`#${commentId}_comment`).css('border-color') === `rgb(243, 179, 0)`) {
			$(`#${commentId}_comment`).css('border', `3px solid #fff`)
		}
	}

	render() {
		const { showCommentsModal, closeModal, driveFileProps } = this.props
		const { id, webViewLink, webContentLink, mimeType } = driveFileProps
		return (
			<div>
				<Query query={GetFileComments} variables={{ fileId: id }}>
					{({ data, loading, error, refetch }) => {
						if (loading) {
							return (
								<Spin
									indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
									style={{ padding: '30px 52px' }}
								/>
							)
						}
						if (!data) {
							return (
								<Spin
									indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
									style={{ padding: '30px 52px' }}
								/>
							)
						}
						if (data) {
							const { getFileComments } = data
							return (
								<>
									<AddComments fileId={id} refetchComments={refetch} />
									<Row>
										<Col span={24} style={{ backgroundColor: '#fff' }}>
											<div
												style={{
													overflowX: 'hidden',
													overflowY: 'scroll',
													marginBottom: '12%',
												}}>
												{getFileComments.map((values, index) => {
													if (values.anchor && values.anchor !== null) {
														const anchor = values.anchor
															.replace(/null,/g, '')
															.replace(/\[/g, '')
															.replace(/]/g, '')

														const anchorArray = anchor.split(',').map(Number)

														if ($(`#${values.id}_comment`).length === 0) {
															$('.commentsAnchorDiv').prepend(
																`<div id='${values.id}_comment' class='commentAnchorHighlight'></div>`
															)
															$(`#${values.id}_comment`).css(
																'left',
																`${anchorArray[0] * 100}%`
															)
															$(`#${values.id}_comment`).css(
																'top',
																`${anchorArray[1] * 100}%`
															)
															$(`#${values.id}_comment`).css(
																'width',
																`${anchorArray[2] * 100 - anchorArray[0] * 100}%`
															)
															$(`#${values.id}_comment`).css(
																'height',
																`${anchorArray[3] * 100 - anchorArray[1] * 100}%`
															)
														}
													}
													return (
														<>
															<Collapse
																// defaultActiveKey={['0']}
																onChange={() => this.highlightComment(values.id)}
																id={values.id}
																expandIconPosition={'right'}
																style={{
																	marginTop: 10,
																	border: values.anchor ? '2px solid #ffb300' : '',
																}}>
																<Panel
																	header={
																		<Row>
																			<Col span={2}>
																				<Avatar
																					src={values.author.photoLink}
																					alt={values.author.displayName}
																				/>
																			</Col>
																			<Col
																				span={10}
																				style={{
																					marginLeft: 18,
																					marginTop: 4,
																					fontWeight: 500,
																				}}>
																				<span>{values.author.displayName}</span>
																			</Col>
																			<Col
																				span={6}
																				style={{
																					fontSize: 11,
																					marginLeft: -18,
																					marginTop: 4,
																					fontWeight: 400,
																					fontStyle: 'italic',
																					color: '#888',
																				}}>
																				<span>
																					{format(
																						new Date(values.createdTime),
																						'MMM dd, yyyy'
																					)}
																				</span>
																			</Col>
																			<Col span={6}>
																				<AddReplies
																					refetchComments={refetch}
																					fileId={id}
																					commentId={values.id}
																					resolved={values.resolved}
																					type="resolveButton"
																				/>
																			</Col>
																			<Row>
																				<Col
																					span={20}
																					style={{
																						marginLeft: 50,
																						marginTop: 4,
																						fontWeight: 400,
																					}}>
																					<span>{values.content}</span>
																				</Col>
																			</Row>
																		</Row>
																	}
																	key={index}>
																	{values.replies.map((replyData, key) => {
																		return (
																			<Row
																				style={{
																					borderBottom: '1px solid #e6e6e6',
																					marginBottom: 5,
																					marginTop: 10,
																				}}>
																				<Col span={2}>
																					<Avatar
																						src={replyData.author.photoLink}
																						alt={replyData.author.displayName}
																					/>
																				</Col>
																				<Col
																					span={14}
																					style={{
																						marginLeft: 18,
																						marginTop: 4,
																						fontWeight: 500,
																					}}>
																					<span>{replyData.author.displayName}</span>
																				</Col>
																				<Col
																					span={8}
																					style={{
																						fontSize: 11,
																						marginLeft: -18,
																						marginTop: 4,
																						fontWeight: 400,
																						fontStyle: 'italic',
																						color: '#888',
																					}}>
																					<span>
																						{format(
																							new Date(replyData.createdTime),
																							'MMM dd, yyyy - hh:mm a'
																						)}
																					</span>
																				</Col>
																				<Row style={{ marginBottom: 9 }}>
																					<Col
																						span={20}
																						style={{
																							marginLeft: 50,
																							marginTop: 4,
																							fontWeight: 400,
																						}}>
																						<span>{replyData.content}</span>
																					</Col>
																				</Row>
																			</Row>
																		)
																	})}
																	{!values.resolved ? (
																		<AddReplies
																			refetchComments={refetch}
																			fileId={id}
																			commentId={values.id}
																			resolved={values.resolved}
																		/>
																	) : null}
																</Panel>
															</Collapse>
														</>
													)
												})}
											</div>
										</Col>
									</Row>
								</>
							)
						}
					}}
				</Query>
			</div>
		)
	}
}

export default CommentsList
