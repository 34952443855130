import gql from 'graphql-tag'

export const MOVE_FILE_TO_APPROVED = gql`
	mutation moveFileToApproved(
		$fileId: String
		$forReviewFolderId: String
		$approvedFolderId: String
		$comments: String
	) {
		moveFileToApproved(
			fileId: $fileId
			forReviewFolderId: $forReviewFolderId
			approvedFolderId: $approvedFolderId
			comments: $comments
		)
	}
`
