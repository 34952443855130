// Import React Component
import React from 'react'
import Tree from 'rc-tree'
import PropTypes from 'prop-types'
import './index.css'

// Tree height based on content
const onEnterActive = (node) => {
	return { height: node.scrollHeight }
}

// Tree expand and collapse animation effect
const motion = {
	motionName: 'node-motion',
	motionAppear: false,
	onEnterActive,
	onLeaveStart: (node) => ({ height: node.offsetHeight }),
}

/** Generate room scope deliverable list
 * @type - function
 * @param - roomScope,buildingIndex,floorIndex,roomIndex,scopeIndex,defaultSelectedScope
 * @summary - Using this parameters generate a room scope deliverable array
 * @return - roomScopeArray
 */
const roomScopeDeliverable = (
	roomScope,
	buildingIndex,
	floorIndex,
	roomIndex,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey
) => {
	// Declare roomscope arrray
	const roomScopeArray = []

	for (let x = 0; x < roomScope.length; x++) {
		const { scopeId, scopeType, scopeName } = roomScope[x]
		if (deliverableSelectedScope.includes(scopeId)) {
			const data = {
				key: `0-${scopeIndex}-${buildingIndex}-${floorIndex}-${roomIndex}-${x}`,
				scopeId,
				title: scopeName,
				type: scopeType,
				children: [],
			}
			// push data in roomscope array
			roomScopeArray.push(data)
			// push scopeIndex,buildingIndex,floorIndex,roomIndex in defaultcheckedkey array because using this data we will set the selected deliverable in rctree
			defaultCheckedKey.push(
				`0-${scopeIndex}-${buildingIndex}-${floorIndex}-${roomIndex}-${x}`
			)
		}
	}

	// return roomScopeArray
	return roomScopeArray
}

// Generate floor scope deliverable list
/**
 * @type - function
 * @param - spaceDefine,buildingIndex,floorIndex,roomIndex,scopeIndex,defaultSelectedScope
 * @summary - Using this parameters generate a floor scope deliverable array
 * @return - floorScopeArray
 */
const floorScopeDeliverable = (
	spaceDefine,
	floorScope,
	buildingIndex,
	roomScope,
	floorIndex,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey
) => {
	// Declare roomscope arrray
	const floorScopeArray = []
	for (var x = 0; x < floorScope.length; x++) {
		const { scopeId, scopeName, scopeType } = floorScope[x]
		if (deliverableSelectedScope.includes(scopeId)) {
			var data = {
				key: `0-${scopeIndex}-${buildingIndex}-${floorIndex}-${x}`,
				scopeId,
				title: scopeName,
				type: scopeType,
				children: [],
			}

			// push data in roomscope array
			floorScopeArray.push(data)
			// push scopeIndex,buildingIndex and floorIndex in defaultcheckedkey array because using this data we will set the selected deliverable in rctree
			defaultCheckedKey.push(`0-${scopeIndex}-${buildingIndex}-${floorIndex}-${x}`)
		}
	}

	if (spaceDefine[buildingIndex].children[floorIndex].children !== undefined) {
		for (
			let z = x;
			z < x + spaceDefine[buildingIndex].children[floorIndex].children.length;
			z++
		) {
			const roomIndex = z - floorScope.length
			var data = {
				key: `0-${scopeIndex}-${buildingIndex}-${floorIndex}-${z}`,
				scopeId: null,
				type: null,
				title: `${spaceDefine[buildingIndex].children[floorIndex].children[roomIndex].name}`,
				children: roomScopeDeliverable(
					roomScope,
					buildingIndex,
					floorIndex,
					roomIndex,
					deliverableSelectedScope,
					scopeIndex,
					defaultCheckedKey
				),
			}

			// push data in roomscope array
			floorScopeArray.push(data)
		}
	}

	// return floorScopeArray
	return floorScopeArray
}

/** Generate building scope deliverable list
 * @type - function
 * @param - spaceDefine,buildingScope,buildingIndex,floorIndex,roomIndex,scopeIndex,defaultSelectedScope
 * @summary -  Using this parameters generate a building scope deliverable array
 * @return - buildingScopeArray
 */
const buildingScopeDeliverable = (
	spaceDefine,
	buildingScope,
	floorScope,
	roomScope,
	buildingIndex,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey
) => {
	// Declare buildingscope array
	const buildingScopeArray = []
	for (var x = 0; x < buildingScope.length; x++) {
		// deconstruct buildingscope object
		const { scopeId, scopeName, scopeType } = buildingScope[x]
		if (deliverableSelectedScope.includes(scopeId)) {
			const data = {
				key: `0-${scopeIndex}-${buildingIndex}-${x}`,
				scopeId,
				title: scopeName,
				type: scopeType,
				children: [],
			}

			// push data in building scope array
			buildingScopeArray.push(data)
			// push scopeIndex and buildingIndex in defaultcheckedkey array because using this data we will set the selected deliverable in rctree
			defaultCheckedKey.push(`0-${scopeIndex}-${buildingIndex}-${x}`)
		}
	}

	if (spaceDefine[buildingIndex].children !== undefined) {
		for (let y = x; y < x + spaceDefine[buildingIndex].children.length; y++) {
			const floorIndex = y - buildingScope.length
			const result = {
				key: `0-${scopeIndex}-${buildingIndex}-${y}`,
				scopeId: null,
				type: null,
				title: `${spaceDefine[buildingIndex].children[floorIndex].name}`,
				children: floorScopeDeliverable(
					spaceDefine,
					floorScope,
					buildingIndex,
					roomScope,
					floorIndex,
					deliverableSelectedScope,
					scopeIndex,
					defaultCheckedKey
				),
			}
			// push data in building scope array
			buildingScopeArray.push(result)
		}
	}

	// return buildingscopearray
	return buildingScopeArray
}

// Generate deliverable list based on RCTree structure
/**
 * @type - function
 * @param - spaceDefine,buildingScope,buildingIndex,floorScope,roomScope,scopeIndex,defaultSelectedScope
 * @summary -  Using this parameters generate a deliverable list array
 * @return - deliverable tree data array
 */
const generateDeliverableList = (
	spaceDefine,
	buildingScope,
	floorScope,
	roomScope,
	deliverableSelectedScope,
	scopeIndex,
	defaultCheckedKey
) => {
	// declare deliverableTreeList array
	const deliverableTreeList = []
	// declare selected key array
	const defaultSelectedKey = []

	for (let x = 0; x < spaceDefine.length; x++) {
		const data = {
			key: `0-${scopeIndex}-${x}`,
			scopeId: null,
			type: null,
			title: `${spaceDefine[x].name}`,
			children: buildingScopeDeliverable(
				spaceDefine,
				buildingScope,
				floorScope,
				roomScope,
				x,
				deliverableSelectedScope,
				scopeIndex,
				defaultCheckedKey
			),
		}

		// push data in deliverable tree list array
		deliverableTreeList.push(data)
		// push scopeIndex in defaultcheckedkey array because using this data we will set the selected deliverable in rctree
		defaultSelectedKey.push(`0-${scopeIndex}-${x}`)
	}

	// Assign deliverableTreeList and defaultSelectedKey in result object
	const result = {
		deliverableTreeList,
		defaultSelectedKey,
	}

	// return result
	return result
}

/**
 * @type - function
 * @param -
 * @summary -
 * @return -
 */
const generateList = (
	spaceDefine,
	deliverableSelectedScope,
	serviceScopeDeliverable,
	feeHeadScope
) => {
	const deliverableList = []
	let defaultKey = []
	const defaultCheckedKey = []

	for (let x = 0; x < serviceScopeDeliverable.length; x++) {
		const { scopeId, scopeName, scopeDeliverable } = serviceScopeDeliverable[x]
		if (feeHeadScope.includes(scopeId)) {
			const { buildingScope, floorScope, roomScope } = scopeDeliverable
			const { deliverableTreeList, defaultSelectedKey } = generateDeliverableList(
				spaceDefine,
				buildingScope,
				floorScope,
				roomScope,
				deliverableSelectedScope,
				x,
				defaultCheckedKey
			)

			const data = {
				key: `0-${x}`,
				scopeId: null,
				type: null,
				title: `${scopeName}`,
				children: deliverableTreeList,
			}

			deliverableList.push(data)
			defaultKey.push(`0-${x}`)
			defaultKey = [...defaultKey, ...defaultSelectedKey]
		}
	}
	const deliverableResult = {
		deliverableList,
		defaultKey,
		defaultCheckedKey,
	}

	return deliverableResult
}

class RCTree extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			defaultExpandedKeys: [],
			defaultSelectedKeys: [],
			defaultCheckedKeys: [],
			treeData: null,
		}
	}

	componentWillMount() {
		const {
			spaceDefineJson,
			deliverableSelectedScope,
			serviceDeliverable,
			serviceScopeDeliverable,
			defaultDeliverableCheckedKeys,
			defaultDeliverableExpandedKeys,
			feeHeadScope,
		} = this.props

		const { deliverableList, defaultKey, defaultCheckedKey } = generateList(
			spaceDefineJson,
			deliverableSelectedScope,
			serviceScopeDeliverable,
			feeHeadScope
		)

		const data = {
			defaultDeliverableExpandedKeys:
				serviceDeliverable == null ? defaultKey : defaultDeliverableExpandedKeys,
			defaultDeliverableCheckedKeys:
				serviceDeliverable == null ? defaultCheckedKey : defaultDeliverableCheckedKeys,
			defaultDeliverableSelectedKeys: defaultKey,
			serviceDeliverable: deliverableList,
			deliverableChangedStatus: serviceDeliverable == null,
		}
		if (serviceDeliverable == null) {
			this.setState({
				defaultExpandedKeys: defaultKey,
				defaultCheckedKeys: defaultCheckedKey,
				defaultSelectedKeys: defaultKey,
				treeData: deliverableList,
			})
		} else {
			this.setState({
				defaultExpandedKeys: defaultDeliverableExpandedKeys,
				defaultCheckedKeys: defaultDeliverableCheckedKeys,
				defaultSelectedKeys: defaultKey,
				treeData: deliverableList,
			})
		}
		this.props.setServiceDeliverable(data)
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	onExpand = (...args) => {
		const { defaultCheckedKeys, defaultSelectedKeys, treeData } = this.state

		const data = {
			serviceDeliverable: treeData,
			defaultDeliverableExpandedKeys: args[0],
			defaultDeliverableCheckedKeys: defaultCheckedKeys,
			defaultDeliverableSelectedKeys: defaultSelectedKeys,
			deliverableChangedStatus: false,
		}
		this.props.setServiceDeliverable(data)
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	onCheck = (checkedKeys, info) => {
		const newExpandedKey = [...this.state.defaultExpandedKeys, ...info.halfCheckedKeys]

		const { treeData } = this.state

		const data = {
			serviceDeliverable: treeData,
			defaultDeliverableExpandedKeys: newExpandedKey,
			defaultDeliverableCheckedKeys: checkedKeys,
			defaultDeliverableSelectedKeys: checkedKeys,
			deliverableChangedStatus: true,
		}
		this.props.setServiceDeliverable(data)

		this.setState({ defaultExpandedKeys: newExpandedKey })
	}

	render() {
		const {
			treeData,
			defaultCheckedKeys,
			defaultSelectedKeys,
			defaultExpandedKeys,
		} = this.state

		return (
			<div className="deliverableList">
				<Tree
					showLine
					checkable
					selectable={false}
					defaultExpandAll
					onExpand={this.onExpand}
					onCheck={this.onCheck}
					defaultExpandedKeys={defaultExpandedKeys}
					defaultSelectedKeys={defaultSelectedKeys}
					defaultCheckedKeys={defaultCheckedKeys}
					treeData={treeData}
					motion={motion}
				/>
			</div>
		)
	}
}

RCTree.propTypes = {
	spaceDefineJson: PropTypes.array,
	treeData: PropTypes.array,
	serviceScopeDeliverable: PropTypes.array,
	deliverableSelectedScope: PropTypes.array,
	defaultExpandKeys: PropTypes.array,
	defaultSelectedKeys: PropTypes.array,
	defaultCheckedKeys: PropTypes.array,
	serviceDeliverable: PropTypes.array,
	setServiceDeliverable: PropTypes.func,
	feeHeadScope: PropTypes.array,
	defaultDeliverableCheckedKeys: PropTypes.array,
	defaultDeliverableExpandedKeys: PropTypes.array,
}

export default RCTree
