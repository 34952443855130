import React from 'react'
import Message from './Message'
import { MESSAGE_CREATED_SUBSCRIPTION } from '../query'
import { formatChatRelativetimestamp } from '../utils'
import styles from './styles'

class MessageList extends React.Component {
	async componentDidMount() {
		await this.props.refetch()
		await this.props.subscribeToMore({
			document: MESSAGE_CREATED_SUBSCRIPTION,
			variables: {
				groupId: this.props.groupId,
				hash: this.props.hash,
			},
			updateQuery: (prev, { subscriptionData }) => {
				this.props.refetch()
			},
		})
		await this.props.chatListRefetch()
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.groupId !== this.props.groupId) {
			await this.props.refetch()
			await this.props.chatListRefetch()
		}
	}

	render() {
		const { data, fetchMore, chatType, hash: fromHash, mediaImage } = this.props
		const {
			messageStyleLeft,
			messagesListWrapperStyleLeft,
			messageListParentDiv,
			messageListChildDiv,
		} = styles.messageList

		const messageStyleRight = {
			...messageStyleLeft,
			background: 'rgb(220, 248, 198)',
		}

		const messagesListWrapperStyleRight = {
			...messagesListWrapperStyleLeft,
			alignSelf: 'flex-end',
		}

		const showModalDialog = (data) => {
			const { showModalDialogPopup, refetch } = this.props
			showModalDialogPopup(data, refetch)
		}

		const scrollOnTop = async (fetchMore, data) => {
			const scrollTop = document.querySelector('#scroll').scrollTop
			if (scrollTop === 0) {
				fetchMore({
					variables: {
						offset: (data.getChatDetails.length / 12) * 12,
					},
					updateQuery: (prev, { fetchMoreResult }) => {
						if (!fetchMoreResult) return prev

						return {
							getChatDetails: [...prev.getChatDetails, ...fetchMoreResult.getChatDetails],
						}
					},
				})
			}
		}

		return (
			<div style={messageListParentDiv}>
				<div
					style={messageListChildDiv}
					id="scroll"
					onScroll={() => {
						scrollOnTop(fetchMore, data)
					}}>
					{data.getChatDetails.map((msg, index, self) => {
						const {
							id,
							type,
							content,
							messageType,
							isTicket,
							isDeleted,
							isForwarded,
							isMyReply,
							reply,
							caption,
							createdBy,
							createdAt,
						} = msg

						let messagesListWrapperStyle
						let messageStyle
						if (!isMyReply) {
							messagesListWrapperStyle = messagesListWrapperStyleLeft
							messageStyle = messageStyleLeft
						} else {
							messagesListWrapperStyle = messagesListWrapperStyleRight
							messageStyle = messageStyleRight
						}

						return (
							<Message
								fromHash={fromHash}
								isDeleted={isDeleted}
								key={id}
								messageId={id}
								messageStyle={messageStyle}
								messagesListWrapperStyle={messagesListWrapperStyle}
								index={index}
								text={content}
								isMyReply={isMyReply}
								chatType={chatType}
								type={type}
								isTicket={isTicket}
								createdBy={createdBy}
								messageType={messageType}
								caption={caption}
								isForwarded={isForwarded}
								showModalDialog={showModalDialog}
								reply={reply}
								timestamp={formatChatRelativetimestamp(createdAt)}
								createdAt={createdAt}
								mediaImage={mediaImage}
								messageData={msg}
							/>
						)
					})}
				</div>
			</div>
		)
	}
}

export default MessageList
