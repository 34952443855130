import { Button, Col, Modal, Row, Tag, Typography } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from '../../../../service'
import DuplicateEnquiryList from '../DuplicateEnquiryList/index'
import ProposalTimeline from '../ProposalTimeline/index'
// Importing required components from ANTD

// Query to fetch CRM Details on load

// Component CSS

export default function ListDuplicateEnquiries(props) {
	const { contactNo, currentEnquiryId, duplicateEnquiries, email, handlers } = props
	async function viewDuplicateEnquiries() {
		Modal.info({
			title: `List of Duplicate Enquiries ( ${duplicateEnquiries - 1} )`,

			content: (
				<ApolloProvider client={ApolloClient}>
					<DuplicateEnquiryList
						currentEnquiryId={currentEnquiryId}
						contactNo={contactNo}
						email={email}
						handlers={handlers}
					/>
				</ApolloProvider>
			),
			okText: 'Close',
			centered: true,
			onOk() {},
		})
	}
	async function viewProposalTimeline() {
		Modal.info({
			title: 'Proposal Timeline',

			content: (
				<ApolloProvider client={ApolloClient}>
					<ProposalTimeline currentEnquiryId={currentEnquiryId} />
				</ApolloProvider>
			),
			okText: 'Close',
			centered: true,
			onOk() {},
		})
	}
	return (
		<div style={{ borderBottom: '1px solid #eee' }}>
			<Row style={{ marginTop: 10, marginBottom: 10 }}>
				<Col span={11}>
					<Typography.Text strong style={{ fontSize: 15 }}>
						Duplicate Enquiries
					</Typography.Text>
				</Col>
				<Col span={3}>
					{duplicateEnquiries - 1 === 0 ? (
						<Tag color="#00a6ff" style={{ marginTop: 1 }}>
							{duplicateEnquiries - 1}
						</Tag>
					) : (
						<Tag color="#f50" style={{ marginTop: 1 }}>
							{duplicateEnquiries - 1}
						</Tag>
					)}
				</Col>
				<Col span={10}>
					{duplicateEnquiries - 1 !== 0 ? (
						<Button
							type="link"
							size="small"
							style={{ float: 'right' }}
							onClick={() => viewDuplicateEnquiries()}>
							View All
						</Button>
					) : null}
				</Col>
			</Row>
			{/* <Row style={{ marginTop: 10, marginBottom: 10 }}>
				<Col span={12}>
					<Typography.Text strong style={{ fontSize: 15 }}>
						Proposal Timeline
					</Typography.Text>
				</Col>

				<Col span={12}>
					<Button
						type="link"
						size="small"
						style={{ float: 'right' }}
						onClick={() => viewProposalTimeline()}>
						View Timeline
					</Button>
				</Col>
			</Row> */}
		</div>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
ListDuplicateEnquiries.propTypes = {
	duplicateEnquiries: PropTypes.string,
	currentEnquiryId: PropTypes.string,
	contactNo: PropTypes.string,
	email: PropTypes.func,
}

ListDuplicateEnquiries.defaultProps = {
	duplicateEnquiries: PropTypes.string,
	currentEnquiryId: PropTypes.string,
	contactNo: PropTypes.string,
	email: PropTypes.func,
}
