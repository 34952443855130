/* eslint-disable radix */
/* eslint-disable no-tabs */
/* eslint-disable max-classes-per-file */
import React from 'react'
import { Table, Input, Row, Col, Popover, Form, Checkbox, Radio } from 'antd'
import NumberFormat from 'react-number-format'
import './index.css'

const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
	<EditableContext.Provider value={form}>
		<tr {...props} />
	</EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

const paymentPlainOptions = [
	{ label: 'Sqft', value: 'sqft' },
	{ label: 'Percentage', value: 'percentage' },
	{ label: 'Lumpsum', value: 'lumpsum' },
]

class EditableCell extends React.Component {
	state = {
		editing: false,
	}

	toggleEdit = () => {
		const { editing } = this.state
		const edit = !editing
		this.setState({ editing: edit }, () => {
			if (edit) {
				this.input.focus()
			}
		})
	}

	save = (e) => {
		const { record, handleSave } = this.props
		this.form.validateFields((error, values) => {
			if (error && error[e.currentTarget.id]) {
				return
			}
			this.toggleEdit()
			handleSave({ ...record, ...values })
		})
	}

	renderCell = (form) => {
		this.form = form
		const { children, dataIndex, record, title } = this.props
		const { editing } = this.state
		return editing ? (
			<Form.Item style={{ margin: 0 }}>
				{form.getFieldDecorator(dataIndex, {
					rules: [
						{
							required: true,
							message: `${title} is required.`,
						},
					],
					initialValue: record[dataIndex],
				})(
					<Input
						ref={(node) => (this.input = node)}
						onPressEnter={this.save}
						onBlur={this.save}
					/>
				)}
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24 }}
				onClick={this.toggleEdit}>
				{children}
			</div>
		)
	}

	render() {
		const {
			editable,
			dataIndex,
			title,
			record,
			index,
			handleSave,
			children,
			...restProps
		} = this.props
		return (
			<td {...restProps}>
				{editable ? (
					<EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
				) : (
					children
				)}
			</td>
		)
	}
}

class ProposalFeeCalculation extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			// gstCheckedKeys: [],
			essentialVisit: 3,
			unCompromisedVisit: 20,
			discountType: 'nil',
		}
		this.columns = [
			{
				title: 'Stage Name    (Editable)',
				dataIndex: 'stageName',
				key: 'stageName',
				editable: true,
				render: (text, record) => {
					const status = record.stageName
					const columnSpan = [
						'Grand Total',
						'CGST9TN(+)',
						'SGST9TN(+)',
						'IGST(+)',
						'Discount(-)',
						'CGST6TN(+)',
						'SGST6TN(+)',
						'CGST14TN(+)',
						'Total',
						'SGST14TN(+)',
					]

					if (!columnSpan.includes(status)) {
						return (
							<div
								style={{
									fontWeight:
										status === 'Total' || status === 'Grand Total' ? 'bold' : 'normal',
									textAlign: 'center',
									cursor: columnSpan.includes(status) ? 'none' : 'pointer',
								}}>
								{text}
							</div>
						)
					}

					return {
						children: (
							<div
								style={{
									fontWeight:
										status === 'Total' || status === 'Grand Total' ? 'bold' : 'normal',
									textAlign: 'center',
									cursor: columnSpan.includes(status) ? 'none' : 'pointer',
								}}>
								{text}
							</div>
						),
						props: {
							colSpan: 2,
						},
					}
				},
			},
			{
				title: 'No of Deliverable',
				dataIndex: 'totalDeliverable',
				key: 'totalDeliverable',
				render: (text, record) => {
					const status = record.stageName
					const stageDeliverable = record.deliverableList

					const deliverableName = stageDeliverable.map((element) => element.scopeName)

					const clickContent = (
						<div
							style={{
								minHeight: '170px',
								maxHeight: '170px',
								height: '170px',
								overflow: 'scroll',
							}}>
							<ul>
								{deliverableName.map((element, index) => (
									<li key={(element, index)} style={{ padding: '10px' }}>
										{element}
									</li>
								))}
							</ul>
						</div>
					)
					const columnSpan = [
						'Grand Total',
						'CGST9TN(+)',
						'SGST9TN(+)',
						'IGST(+)',
						'Discount(-)',
						'CGST6TN(+)',
						'SGST6TN(+)',
						'CGST14TN(+)',
						'Total',
						'SGST14TN(+)',
					]
					if (!columnSpan.includes(status)) {
						return (
							<div
								style={{
									fontWeight:
										status === 'Total' || status === 'Grand Total' ? 'bold' : 'normal',
									textAlign: 'center',
									cursor: columnSpan.includes(status) ? 'none' : 'pointer',
								}}>
								{status !== 'Total' ? (
									<Popover
										content={clickContent}
										title="Deliverable List"
										trigger="hover">
										{text}
									</Popover>
								) : (
									text
								)}
							</div>
						)
					}
					return {
						children: (
							<div
								style={{
									fontWeight:
										status === 'Total' || status === 'Grand Total' ? 'bold' : 'normal',
									textAlign: 'center',
									cursor: columnSpan.includes(status) ? 'none' : 'pointer',
								}}>
								{status !== 'Total' ? (
									<Popover
										content={clickContent}
										title="Deliverable List"
										trigger="hover">
										{text}
									</Popover>
								) : (
									text
								)}
							</div>
						),
						props: { colSpan: 0 },
					}
				},
			},
			{
				title: 'Percentage (Editable)',
				dataIndex: 'totalPercentage',
				key: 'totalPercentage',
				editable: true,
				render: (text, record) => {
					const status = record.stageName
					const columnSpan = [
						'Grand Total',
						'CGST9TN(+)',
						'SGST9TN(+)',
						'IGST(+)',
						'Discount(-)',
						'CGST6TN(+)',
						'SGST6TN(+)',
						'CGST14TN(+)',
						'SGST14TN(+)',
						'Total',
						'Completion',
					]
					return (
						<div
							style={{
								fontWeight:
									status === 'Total' || status === 'Grand Total' ? 'bold' : 'normal',
								textAlign: 'center',
								cursor: columnSpan.includes(status) ? 'none' : 'pointer',
							}}>
							{text}
						</div>
					)
				},
			},
			{
				title: 'Amount',
				dataIndex: 'totalAmount',
				key: 'totalAmount',
				render: (text, record) => {
					const status = record.stageName
					const columnSpan = [
						'Grand Total',
						'CGST9TN(+)',
						'SGST9TN(+)',
						'IGST(+)',
						'Discount(-)',
						'CGST6TN(+)',
						'SGST6TN(+)',
						'CGST14TN(+)',
						'SGST14TN(+)',
						'Total',
					]

					return (
						<div
							style={{
								fontWeight:
									status === 'Total' || status === 'Grand Total' ? 'bold' : 'normal',
								textAlign: 'center',
								cursor: columnSpan.includes(status) ? 'none' : 'pointer',
							}}>
							<NumberFormat
								// eslint-disable-next-line radix
								value={parseInt(text)}
								displayType="text"
								thousandSeparator
								thousandsGroupStyle="lakh"
								prefix=" Rs."
							/>
						</div>
					)
				},
			},
		]
	}

	async componentWillMount() {
		const {
			setFeeCalculation,
			addPaymentType,
			addGstDetails,
			noOfSiteVisitInEssential,
			editLumpsumCost,
			addDiscountDetails,
			addGstStatus,
			proposalResult,
			addPerSqftRate,
			addDesignTimeDuration,
			serviceResult,
		} = this.props

		const { perSqftRate, timeDuration } = serviceResult

		const { proposalDetails } = proposalResult
		const {
			discount,
			gstDetails,
			gstStatus,
			paymentType,
			paymentPercentage,
			lumpsumAmount,
			siteVisit,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft, percentage } = paymentPercentage

		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus,
		}

		const discountData = {
			type,
			value,
		}

		await addPaymentType(paymentCheckedKeys[0])
		await addGstDetails(gstCheckedKeys)
		await editLumpsumCost(lumpsumAmount)
		await noOfSiteVisitInEssential(siteVisit)
		await addDiscountDetails(discountData)
		await addGstStatus(gstStatus)
		await addPerSqftRate(perSqftRate)
		await addDesignTimeDuration(timeDuration)

		await setFeeCalculation(result)
	}

	handleSave = (row) => {
		const { editProposalStageName } = this.props
		editProposalStageName(row)
	}

	onChangeGstDetails = async (checkedValues) => {
		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			discount,
			paymentPercentage,
			gstStatus,
			lumpsumAmount,
			paymentType,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft, percentage } = paymentPercentage
		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: checkedValues,
			discount: {
				type,
				value,
			},
			gstStatus,
		}

		const { setFeeCalculation, addGstDetails } = this.props
		await addGstDetails(checkedValues)
		await setFeeCalculation(result)
	}

	onChangeFeesPackage = async (checkedValues) => {
		const { setProposalPackage } = this.props

		if (checkedValues.length > 0) {
			const packageArray = []
			for (let x = 0; x < checkedValues.length; x++) {
				if (checkedValues[x] === 'Exclusive') {
					packageArray.push('1')
				} else if (checkedValues[x] === 'Essential') {
					packageArray.push('3')
				}
			}
			packageArray.push('2')
			setProposalPackage({
				selectedPackage: packageArray.toString(),
			})
		}
	}

	onChangePackageFeatures = ({ parentIndex, childIndex }) => {
		const { setFeesPackage, feesPackage } = this.props

		feesPackage[parentIndex].features[childIndex].has = !feesPackage[parentIndex]
			.features[childIndex].has

		setFeesPackage(feesPackage)
	}

	onChangePaymentType = async (e) => {
		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			discount,
			paymentPercentage,
			gstStatus,
			lumpsumAmount,
			paymentType,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft, percentage } = paymentPercentage

		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const result = {
			paymentType: e.target.value,
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus,
		}
		const { setFeeCalculation, addPaymentType } = this.props
		await addPaymentType(e.target.value)
		await setFeeCalculation(result)
	}

	onChangePercentage = async (e) => {
		const data = e.target.value.replace(/\D/g, '')
			? parseInt(e.target.value.replace(/\D/g, ''))
			: 0

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			discount,
			paymentPercentage,
			gstStatus,
			lumpsumAmount,
			paymentType,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft } = paymentPercentage
		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage: data,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus,
		}

		const { editPaymentPercentage, setFeeCalculation } = this.props
		await editPaymentPercentage(data)
		await setFeeCalculation(result)
	}

	onChangeEstimationCost = async (e) => {
		const data = e.target.value.replace(/\D/g, '')
			? parseInt(e.target.value.replace(/\D/g, ''))
			: 0

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			discount,
			paymentPercentage,
			gstStatus,
			lumpsumAmount,
			paymentType,
		} = proposalDetails
		const { type, value } = discount
		const { percentage } = paymentPercentage

		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: data,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus,
		}

		const { editPaymentEstimationCost, setFeeCalculation } = this.props
		await editPaymentEstimationCost(data)
		await setFeeCalculation(result)
	}

	onChangeLumpsumCost = async (e) => {
		const data = e.target.value.replace(/\D/g, '')
			? parseInt(e.target.value.replace(/\D/g, ''))
			: 0

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			discount,
			paymentPercentage,
			gstStatus,
			paymentType,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft, percentage } = paymentPercentage
		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount: data,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus,
		}
		const { setFeeCalculation, editLumpsumCost } = this.props
		await editLumpsumCost(data)
		await setFeeCalculation(result)
	}

	onChangeEssentialVisit = async (e) => {
		const data = e.target.value.replace(/\D/g, '')
			? parseInt(e.target.value.replace(/\D/g, ''))
			: 0

		const { unCompromisedVisit } = this.state
		const siteVisit = {
			essential: data,
			uncompromised: unCompromisedVisit,
		}

		const { noOfSiteVisitInEssential } = this.props
		await noOfSiteVisitInEssential(siteVisit)
		this.setState({ essentialVisit: data })
	}

	onChangeUncompromiseVisit = async (e) => {
		const data = e.target.value.replace(/\D/g, '')
			? parseInt(e.target.value.replace(/\D/g, ''))
			: 0

		const { essentialVisit } = this.state
		const siteVisit = {
			essential: essentialVisit,
			uncompromised: data,
		}

		const { noOfSiteVisitInUncompromise } = this.props
		await noOfSiteVisitInUncompromise(siteVisit)
		this.setState({ unCompromisedVisit: data })
	}

	onChangeDiscountType = async (e) => {
		const discountData = {
			type: e.target.value,
			value: 0,
		}

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			paymentPercentage,
			gstStatus,
			lumpsumAmount,
			paymentType,
		} = proposalDetails
		const { estimationCostPerSqft, percentage } = paymentPercentage

		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type: e.target.value,
				value: 0,
			},
			gstStatus,
		}

		const { addDiscountDetails, setFeeCalculation } = this.props
		await addDiscountDetails(discountData)
		await setFeeCalculation(result)
	}

	onChangeDiscountAmount = async (e) => {
		const data = e.target.value.replace(/\D/g, '')
			? parseInt(e.target.value.replace(/\D/g, ''))
			: 0

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			paymentPercentage,
			gstStatus,
			lumpsumAmount,
			paymentType,
			discount,
		} = proposalDetails
		const { estimationCostPerSqft, percentage } = paymentPercentage
		const { type } = discount

		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value: data,
			},
			gstStatus,
		}

		const discountData = {
			type,
			value: data,
		}

		const { addDiscountDetails, setFeeCalculation } = this.props
		await addDiscountDetails(discountData)
		await setFeeCalculation(result)
	}

	onChangeGstStatus = async (e) => {
		const status = e.target.checked ? 'hide' : 'show'

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			discount,
			paymentPercentage,
			lumpsumAmount,
			paymentType,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft, percentage } = paymentPercentage

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)
		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus: status,
		}

		const { addGstStatus, setFeeCalculation } = this.props
		await addGstStatus(status)
		await setFeeCalculation(result)
	}

	onChangePerSqftPrice = async (e) => {
		const totalSqft = e.target.value

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			discount,
			paymentPercentage,
			lumpsumAmount,
			paymentType,
			gstStatus,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft, percentage } = paymentPercentage

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)
		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus,
		}

		const { addPerSqftRate, setFeeCalculation } = this.props
		await addPerSqftRate(totalSqft)
		await setFeeCalculation(result)
	}

	onChangeDesignTimeDuration = async (e) => {
		const totalSqft = e.target.value

		const { proposalResult } = this.props
		const { proposalDetails } = proposalResult
		const {
			gstDetails,
			discount,
			paymentPercentage,
			lumpsumAmount,
			paymentType,
			gstStatus,
		} = proposalDetails
		const { type, value } = discount
		const { estimationCostPerSqft, percentage } = paymentPercentage

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)
		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const result = {
			paymentType: paymentCheckedKeys[0],
			percentage,
			percentagePerSqftAmount: estimationCostPerSqft,
			lumpsumAmount,
			gstDetails: gstCheckedKeys,
			discount: {
				type,
				value,
			},
			gstStatus,
		}

		const { addDesignTimeDuration, setFeeCalculation } = this.props
		await addDesignTimeDuration(totalSqft)
		await setFeeCalculation(result)
	}

	onChangeServiceName = async (e) => {
		const serviceName = e.target.value
		const { setServiceName } = this.props
		setServiceName(serviceName)
	}

	onChangeCoverTitle = async (e) => {
		const coverTitle = e.target.value
		const { setCoverTitle } = this.props
		setCoverTitle(coverTitle)
	}

	onChangeFeeheadName = async (e) => {
		const feeHeadName = e.target.value
		const { setFeeheadName } = this.props
		setFeeheadName(feeHeadName)
	}

	render() {
		const {
			proposalResult,
			clientName,
			projectLocation,
			sqft,
			paymentPercentage,
			serviceGstDetails,
			serviceResult,
			proposalPackage,
			selectedPackage,
			feesPackage,
			setFeesPackage,
		} = this.props

		const { proposalDetails } = proposalResult
		const {
			proposalData,
			discount,
			gstDetails,
			gstStatus,
			paymentType,
			lumpsumAmount,
			siteVisit,
		} = proposalDetails

		const {
			perSqftRate,
			timeDuration,
			serviceName,
			coverTitle,
			feeHeadName,
		} = serviceResult
		const { percentage, estimationCostPerSqft } = paymentPercentage
		const { essential, uncompromised } = siteVisit

		const paymentCheckedKeys = []
		paymentCheckedKeys.push(paymentType)

		const gstCheckedKeys = gstDetails.map((gst) => gst.gstId)

		const { type: discountType, value: discountAmount } = discount

		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell,
			},
		}

		const proposalPackageArray = Object.keys(proposalPackage).map((a) => {
			return a === 'uncompromised' ? 'Premium' : a.charAt(0).toUpperCase() + a.substr(1)
		})

		const selectedPackageIndex =
			typeof selectedPackage === 'number'
				? selectedPackage
						.toString()
						.split(',')
						.map((x) => +x)
				: selectedPackage.split(',').map((x) => +x)

		const selectedPackageData = []
		for (let x = 0; x < selectedPackageIndex.length; x++) {
			if (selectedPackageIndex[x] === 1) {
				selectedPackageData.push('Exclusive')
			} else if (selectedPackageIndex[x] === 2) {
				selectedPackageData.push('Premium')
			} else if (selectedPackageIndex[x] === 3) {
				selectedPackageData.push('Essential')
			}
		}

		const columns = this.columns.map((col) => {
			if (!col.editable) {
				return col
			}
			return {
				...col,
				onCell: (record) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					handleSave: this.handleSave,
				}),
			}
		})
		return (
			<div className="proposalFeeCalculation">
				<Row>
					<Col span={11} offset={1}>
						<h4 className="boldText">Project Details</h4>
						<div className="clientDetails">
							<Row>
								<Col xs={20} sm={16} md={12} lg={10} xl={7}>
									<span>Client Name</span>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}>
									<span>:</span>
								</Col>
								<Col xs={20} sm={16} md={12} lg={10} xl={16}>
									<span>{clientName || 'Nil'}</span>
								</Col>
							</Row>
							<Row>
								<Col xs={20} sm={16} md={12} lg={10} xl={7}>
									<span>Project Sqft</span>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}>
									<span>:</span>
								</Col>
								<Col xs={20} sm={16} md={12} lg={10} xl={16}>
									<span>{sqft}sqft</span>
								</Col>
							</Row>

							<Row>
								<Col xs={20} sm={16} md={12} lg={10} xl={7}>
									<span>Project Location</span>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}>
									<span>:</span>
								</Col>
								<Col xs={20} sm={16} md={12} lg={10} xl={16}>
									<span>{projectLocation || 'Nil'}</span>
								</Col>
							</Row>
						</div>

						<h4 className="boldText">Enter Template Name</h4>
						<div className="serviceName">
							<Input
								className={`inputBorder ${serviceName !== '' ? '' : 'error'}`}
								addonBefore="Template Name"
								defaultValue={serviceName}
								value={serviceName}
								onChange={this.onChangeServiceName}
								placeholder="Enter template name"
							/>
						</div>

						<h4 className="boldText">Enter Feehead Name</h4>
						<div className="feeHeadName">
							<Input
								className={`inputBorder ${feeHeadName !== '' ? '' : 'error'}`}
								addonBefore="Feehead Name"
								defaultValue={feeHeadName}
								value={feeHeadName}
								onChange={this.onChangeFeeheadName}
								placeholder="Enter feehead name"
							/>
						</div>

						<h4 className="boldText">Enter Cover Title</h4>
						<div className="coverTitle">
							<Input
								className={`inputBorder ${coverTitle !== '' ? '' : 'error'}`}
								addonBefore="Cover Title"
								defaultValue={coverTitle}
								value={coverTitle}
								onChange={this.onChangeCoverTitle}
								placeholder="Enter cover title"
							/>
						</div>
						<h4 className="boldText">GST Details</h4>
						<div className="gstDetails">
							<Checkbox.Group
								options={serviceGstDetails}
								defaultValue={gstCheckedKeys}
								value={gstCheckedKeys}
								onChange={this.onChangeGstDetails}
							/>
						</div>

						<h4 className="boldText">Payment Type</h4>
						<div className="paymentType">
							<Radio.Group
								onChange={this.onChangePaymentType}
								defaultValue="sqft"
								value={paymentType}>
								{paymentPlainOptions.map((data, index) => {
									return (
										<Radio key={`data-${index}`} value={data.value}>
											{data.label}
										</Radio>
									)
								})}
							</Radio.Group>
						</div>

						{paymentCheckedKeys[0] === 'sqft' ? (
							<div>
								<h4 className="boldText">Fees package</h4>
								<div className="gstDetails">
									<Checkbox.Group
										options={proposalPackageArray}
										defaultValue={selectedPackageData}
										value={selectedPackageData}
										onChange={this.onChangeFeesPackage}
									/>
								</div>

								{feesPackage.map((value, parentIndex) => {
									const { title, features } = value
									return (
										<>
											{selectedPackageData.includes(title) ? (
												<div>
													<h4 className="boldText">{title} package features</h4>
													<div className="gstDetails">
														{features.map((data, childIndex) => {
															return (
																<>
																	<Checkbox
																		checked={data.has}
																		onChange={() => {
																			this.onChangePackageFeatures({
																				parentIndex,
																				childIndex,
																			})
																		}}>
																		{data.text}
																	</Checkbox>
																	<br />
																</>
															)
														})}
													</div>
												</div>
											) : null}
										</>
									)
								})}

								<h4 className="boldText">Per Sqft Rate</h4>
								<div className="projectSqftDesignTime">
									<div className="projectSquareFeet">
										<Input
											addonBefore="Per sqft amount"
											defaultValue={perSqftRate}
											value={perSqftRate}
											onChange={this.onChangePerSqftPrice}
											placeholder="Per sqft amount"
										/>
									</div>
								</div>

								<h4 className="boldText">No of Site Visit</h4>
								<div className="pricing">
									<div className="essential">
										<Input
											addonBefore="Essential"
											defaultValue={essential}
											value={essential}
											onChange={this.onChangeEssentialVisit}
											placeholder="No of site visit"
										/>
									</div>
									<div className="uncompromised">
										<Input
											addonBefore="Uncompromised"
											addonAfter="+"
											defaultValue={uncompromised}
											value={uncompromised}
											onChange={this.onChangeUncompromiseVisit}
											placeholder="No of site visit"
										/>
									</div>
								</div>
							</div>
						) : null}
						{paymentCheckedKeys[0] === 'percentage' ? (
							<div>
								<h4 className="boldText">Fees package</h4>
								<div className="gstDetails">
									<Checkbox.Group
										options={proposalPackageArray}
										defaultValue={selectedPackageData}
										value={selectedPackageData}
										onChange={this.onChangeFeesPackage}
									/>
								</div>

								{feesPackage.map((value, parentIndex) => {
									const { title, features } = value
									return (
										<>
											{selectedPackageData.includes(title) ? (
												<div>
													<h4 className="boldText">{title} package features</h4>
													<div className="gstDetails">
														{features.map((data, childIndex) => {
															return (
																<>
																	<Checkbox
																		checked={data.has}
																		onChange={() => {
																			this.onChangePackageFeatures({
																				parentIndex,
																				childIndex,
																			})
																		}}>
																		{data.text}
																	</Checkbox>
																	<br />
																</>
															)
														})}
													</div>
												</div>
											) : null}
										</>
									)
								})}

								<h4 className="boldText">Design Fee in Percentage</h4>
								<div className="paymentPercentage">
									<div className="percentage">
										<Input
											addonBefore="Percentage"
											defaultValue={percentage}
											value={percentage}
											onChange={this.onChangePercentage}
											placeholder="Percentage"
										/>
									</div>
									<div className="estimationCost">
										<Input
											addonBefore="Estimation Cost per Sqft"
											defaultValue={estimationCostPerSqft}
											value={estimationCostPerSqft}
											onChange={this.onChangeEstimationCost}
											placeholder="Cost per sqft"
										/>
									</div>
								</div>

								<h4 className="boldText">No of Site Visit</h4>
								<div className="pricing">
									<div className="essential">
										<Input
											addonBefore="Essential"
											defaultValue={essential}
											value={essential}
											onChange={this.onChangeEssentialVisit}
											placeholder="No of site visit"
										/>
									</div>
									<div className="uncompromised">
										<Input
											addonBefore="Uncompromised"
											addonAfter="+"
											defaultValue={uncompromised}
											value={uncompromised}
											onChange={this.onChangeUncompromiseVisit}
											placeholder="No of site visit"
										/>
									</div>
								</div>
							</div>
						) : null}

						{paymentCheckedKeys[0] === 'lumpsum' ? (
							<div>
								<h4 className="boldText">Lumpsum amount</h4>
								<div className="paymentLumpsum">
									<div className="lumpsumAmount">
										<Input
											addonBefore="Lumpsum Amount"
											defaultValue={0}
											value={lumpsumAmount}
											onChange={this.onChangeLumpsumCost}
											placeholder="Lumpsum"
										/>
									</div>
								</div>
								<h4 className="boldText">No of Site Visit</h4>
								<div className="pricing">
									<div className="essential">
										<Input
											addonBefore="Essential"
											defaultValue={essential}
											value={essential}
											onChange={this.onChangeEssentialVisit}
											placeholder="No of site visit"
										/>
									</div>
									<div className="uncompromised">
										<Input
											addonBefore="Uncompromised"
											addonAfter="+"
											defaultValue={uncompromised}
											value={uncompromised}
											onChange={this.onChangeUncompromiseVisit}
											placeholder="No of site visit"
										/>
									</div>
								</div>
							</div>
						) : null}

						<h4 className="designTime boldText">Design Time</h4>
						<div className="estimationTime">
							<Input
								addonBefore="Design estimation time in days"
								defaultValue={timeDuration}
								value={timeDuration}
								onChange={this.onChangeDesignTimeDuration}
								placeholder="Estimation time"
							/>
						</div>
						<h4 className="boldText">Discount</h4>
						<div className="discountDetails">
							<div className="discountRadio">
								<Radio.Group onChange={this.onChangeDiscountType} value={discountType}>
									<Radio value="percentage">Percentage</Radio>
									<Radio value="amount">Amount</Radio>
									<Radio value="nil">Nil</Radio>
								</Radio.Group>
							</div>
							{discountType !== 'nil' ? (
								<div className="discountData">
									<Input
										addonAfter={discountType === 'percentage' ? '%' : ''}
										addonBefore={discountType === 'percentage' ? '' : 'Rs'}
										defaultValue={0}
										value={discountAmount}
										onChange={this.onChangeDiscountAmount}
										placeholder="Lumpsum"
									/>
								</div>
							) : null}
						</div>
						<h4 className="boldText">GST status</h4>
						<div className="gstStatus">
							<Checkbox
								onChange={this.onChangeGstStatus}
								defaultChecked={!(gstStatus === 'show')}>
								Hide GST
							</Checkbox>
						</div>
					</Col>
					<Col span={11}>
						<h4 className="boldText">Payment Details</h4>
						<div className="paymentDetails">
							<Table
								components={components}
								rowClassName={() => 'editable-row'}
								bordered
								dataSource={proposalData}
								columns={columns}
								pagination={false}
							/>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}

export default ProposalFeeCalculation
