/* eslint-disable no-tabs */
/* eslint-disable no-nested-ternary */
// Import react component
import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { Avatar, Icon, Input, Switch } from 'antd'

// Load list of stages in stageList

class StageList extends React.Component {
	changeStageName = (index, scopeId) => ({ target: { value } }) => {
		const { onChangeStageName } = this.props
		onChangeStageName(value, scopeId, index)
	}

	// retainer payment type
	retainerPaymentType = (e) => {
		// deconstruct props
		const { retainerPaymentType } = this.props
		const type = e ? 'percentage' : 'rupees'

		// callback function
		retainerPaymentType(type)
	}

	render() {
		// Deconstruct props
		const { data, addNewPercentage, removeNewStage, retainerType } = this.props

		return [
			data.length ? (
				data.map((val, key) => {
					// stringify value
					const result = JSON.stringify(val)
					return val.scopeType !== 'begin' ? (
						<li
							key={val.scopeId}
							data-id={result}
							className="stageDetails"
							style={{ color: val.scopeSelected ? 'white' : '#2c7ff9' }}>
							<div
								className="stageName"
								style={
									val.scopeSelected
										? {
												backgroundColor: '#2d7ff9',
												color: '#ffffff',
										  }
										: {
												color: '#2c7ff9',
												border: '2px solid #2c7ff9',
										  }
								}>
								<Input
									placeholder="Stage Name"
									style={{ border: 'none' }}
									value={val.scopeName}
									onChange={this.changeStageName(key, val.scopeId)}
								/>
							</div>

							{/** Add New Percentage in stage percentage */}
							<div className="stagePercentage">
								<Input
									placeholder="%"
									style={{ border: 'none' }}
									value={val.scopePercentage}
									onChange={addNewPercentage(val, key)}
								/>
							</div>
							<div
								className="removeStage"
								style={{
									cursor: val.scopeSelected ? 'not-allowed' : 'pointer',
								}}>
								{val.scopeName === 'Retainer' ? (
									<Switch
										checkedChildren="%"
										unCheckedChildren="Rs"
										checked={retainerType === 'percentage'}
										onChange={this.retainerPaymentType}
									/>
								) : val.scopeName === 'Completion' ? null : (
									<span
										onClick={() => {
											// remove already existing stage
											if (!val.scopeSelected) {
												removeNewStage(val, key)
											}
										}}>
										<Icon type="delete" />
									</span>
								)}
							</div>
						</li>
					) : (
						<li
							key={uniqueId()}
							data-id={result}
							className="stageDetails"
							style={{ color: val.scopeSelected ? 'white' : '#2c7ff9' }}>
							<div
								style={
									val.scopeSelected
										? {
												backgroundColor: '#2d7ff9',
												color: '#ffffff',
												textAlign: 'center',
												width: '100%',
												padding: '6px',
										  }
										: {
												color: '#2c7ff9',
												border: '2px solid #2c7ff9',
												textAlign: 'center',
												width: '100%',
												padding: '6px',
										  }
								}>
								<span>{val.scopeName}</span>
							</div>
						</li>
					)
				})
			) : (
				// Handle empty stage
				<li key={uniqueId()} style={{ backgroundColor: 'white' }}>
					<div>
						<Avatar size="large" className="emptyDeliverableList">
							<Icon type="exclamation-circle" className="emptyDeliverableListIcon" />
						</Avatar>
						<span className="stageNameMarginLeft20">No Stage Found</span>
					</div>
				</li>
			),
		]
	}
}

// export stageList component
export default StageList
