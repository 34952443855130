import React from 'react'
import { MdAttachFile } from 'react-icons/md'
import { FloatingMenu, MainButton, ChildButton } from 'react-floating-button-menu'
import { MdImage } from 'react-icons/md'
import { FaFilePdf } from 'react-icons/fa'
import styles from './styles'
import { isImageUrl } from 'antd/lib/upload/utils'
import './index.css'

class Attach extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
		}
	}

	filePreview = (type) => (event) => {
		const file = event.target.files
		var reader = new FileReader()
		reader.onloadend = () => {
			const { showImagePreview } = this.props
			showImagePreview({
				uri: reader.result,
				file: file,
				fileType: type,
				fileName: file[0].name,
			})
			this.setState({ isOpen: false })
		}
		reader.readAsDataURL(file[0])

		// var files = event.target.files
		// const URI_IMAGE = []
		// function readAndPreview(file) {
		// 	// Make sure `file.name` matches our extensions criteria
		// 	if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
		// 		var reader = new FileReader()
		// 		reader.addEventListener(
		// 			'load',
		// 			function() {
		// 				let fileName = file.name
		// 				let fileUrl = this.result
		// 				URI_IMAGE.push(fileUrl)
		// 			},
		// 			false
		// 		)
		// 		reader.readAsDataURL(file)
		// 	}
		// }
		// if (files) {
		// 	;[].forEach.call(files, readAndPreview)
		// }
		// const { showImagePreview } = this.props
		// showImagePreview({
		// 	uri: URI_IMAGE,
		// 	file: files,
		// 	fileType: type,
		// 	fileName: files[0].name,
		// })
		// this.setState({ isOpen: false })
	}

	render() {
		return (
			<div className={'chatAttach'}>
				<FloatingMenu
					slideSpeed={500}
					direction="down"
					spacing={8}
					isOpen={this.state.isOpen}>
					<MainButton
						iconResting={
							<MdAttachFile
								size={25}
								color="#666"
								style={styles.cursorPointer}
								onClick={() => {
									this.setState({ isOpen: true })
								}}
							/>
						}
						iconActive={
							<MdAttachFile
								size={25}
								color="#666"
								style={styles.cursorPointer}
								onClick={() => {
									this.setState({ isOpen: true })
								}}
							/>
						}
						style={styles.attach.imageFileUpload}
						onClick={() => this.setState({ isOpen: !this.state.isOpen })}
						size={56}
					/>

					<ChildButton
						icon={
							<div>
								<MdImage
									style={styles.attach.imageAndPdfUpload}
									onClick={() => this.fileImageUpload.click()}
								/>
								<input
									type="file"
									accept="image/*"
									multiple="multiple"
									ref={(fileImageUpload) => {
										this.fileImageUpload = fileImageUpload
									}}
									style={styles.visibilityHidden}
									onChange={this.filePreview('image')}
								/>
							</div>
						}
						size={40}
						title={'Image'}
					/>
					<ChildButton
						icon={
							<div>
								<FaFilePdf
									size={20}
									style={styles.attach.pdfFileUpload}
									onClick={() => this.filePdfUpload.click()}
								/>

								<input
									type="file"
									accept="application/pdf"
									ref={(filePdfUpload) => {
										this.filePdfUpload = filePdfUpload
									}}
									style={styles.visibilityHidden}
									onChange={this.filePreview('pdf')}
								/>
							</div>
						}
						size={40}
						title={'PDF'}
					/>
				</FloatingMenu>
			</div>
		)
	}
}

export default Attach
