import React from 'react'
import { MdMessage, MdMoreVert } from 'react-icons/md'
import { Menu } from './../ChatHeader/menu'

class ChatListHeader extends React.Component {
	render() {
		const { addNewConversation } = this.props
		const labelsAndContext = {
			'New group': (_) => {
				const { addGroupParticipant } = this.props
				addGroupParticipant()
			},
			'Archived chat': (_) => {
				const { showArchivedMember } = this.props
				showArchivedMember()
			},
		}
		return (
			<div
				style={{
					height: 70,
					padding: 10,
					background: '#eee',
					borderBottom: '1px solid #dddddd',
				}}>
				<div style={{ float: 'left' }}>
					{/* <img
						src="https://designqube.s3.amazonaws.com/site_photos%2FOrDNvx7KD-1586851898919.jpeg"
						style={{ width: 50, height: 50, borderRadius: '100%', marginRight: 10 }}
					/> */}
				</div>
				<div style={{ float: 'right', marginTop: 15, marginRight: 10, display: 'flex' }}>
					<div title={'New chat'}>
						<MdMessage
							size={25}
							style={{ marginLeft: 10, opacity: 0.7, cursor: 'pointer' }}
							onClick={() => {
								addNewConversation()
							}}
						/>
					</div>
					<div title={'Menu'}>
						<Menu labelsAndContext={labelsAndContext} />
					</div>
				</div>
			</div>
		)
	}
}

export default ChatListHeader
