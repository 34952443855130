//import react component
import 'rc-tree/assets/index.css';
import React from 'react';
import Tree from 'rc-tree';
import PropTypes from 'prop-types';

//Tree height based on content
const onEnterActive = node => {
  return { height: node.scrollHeight };
};

//Tree expand and collapse animation effect
const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onEnterActive,
  onLeaveStart: node => ({ height: node.offsetHeight }),
};

//RCTree component
class RCTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultExpandedKeys: [],
      defaultSelectedKeys: [],
      defaultCheckedKeys: [],
      serviceScope: null,
      feeHeadScope: null,
    };
  }

  //Generate default expanded keys
  /**
   * @type - function
   * @param - servicescope contains previously selected scope in service scope config step
   * @summary - Generate a default key selected and expanded key using service scope
   * @return - defaultKey
   */
  getDefaultExpandedKey = serviceScope => {
    var defaultKey = [];
    for (var x = 0; x < serviceScope.length; x++) {
      for (var y = 0; y < serviceScope[ x ].children.length; y++) {
        defaultKey.push(`0-${ x }-${ y }`);
      }
      defaultKey.push(`0-${ x }`);
    }
    return defaultKey;
  };

  //Component Will mount
  componentWillMount() {
    const {
      serviceScope,
      defaultExpandedKeys,
      defaultSelectedKeys,
      defaultCheckedKeys,
    } = this.props;

    //Get default expanded and selected key
    const defaultKey = this.getDefaultExpandedKey(serviceScope);

    //Here check service scope is already generated or not
    //If scope is already generated use that service scope in state
    if (serviceScope === null) {
      const data = {
        serviceScope,
        defaultScopeExpandedKeys: defaultExpandedKeys.length
          ? defaultExpandedKeys
          : defaultKey,
        defaultScopeSelectedKeys: defaultSelectedKeys,
        defaultScopeCheckedKeys: defaultCheckedKeys,
      };
      this.props.setServiceScope(data);
    }
    this.setState({
      defaultExpandedKeys: defaultExpandedKeys.length ? defaultExpandedKeys : defaultKey,
      defaultCheckedKeys: defaultCheckedKeys,
      defaultSelectedKeys: defaultSelectedKeys,
      serviceScope: serviceScope,
    });
  }

  //Trigger, When RCTree expand
  /**
   * @type - function
   * @param - args contains list is expanded ids
   * @summary - set that ids in defaultScopeExpandedKey and pass callback function
   * @return - null
   */
  onExpand = (...args) => {
    const {
      serviceScope,
      defaultCheckedKeys,
      defaultSelectedKeys,
      feeHeadScope,
    } = this.state;

    var data = {
      serviceScope: serviceScope,
      defaultScopeExpandedKeys: args[ 0 ],
      defaultScopeCheckedKeys: defaultCheckedKeys,
      defaultScopeSelectedKeys: defaultSelectedKeys,
      feeHeadScope: feeHeadScope,
    };
    this.props.setServiceScope(data);
  };

  //Get the parent scope using servicescope, checkedkeys and info
  /**
   * @type - function
   * @param - service scope, checked keys and info
   * @summary - Extract feehead scope id in our service
   * @return - parent scope id
   */

  onParent = (serviceScope, checkedKeys, info) => {
    var parentScopeArray = serviceScope.map(function(obj) {
      if (info.halfCheckedKeys.includes(obj.key) || checkedKeys.includes(obj.key)) {
        return obj.key;
      }
    });

    var parentScopeId = parentScopeArray.filter(function(el) {
      return el != undefined;
    });

    return parentScopeId;
  };

  //When there is any changes in tree, onCheck function return the checked keys
  /**
   * @type - function
   * @param - checked keys from RCTree component
   * @summary -Trigger, when RCTree deliverable checkbox changed or selected
   * @return - null
   */
  onCheck = (checkedKeys, info) => {
    const { serviceScope } = this.state;
    const parentSelectedScope = this.onParent(serviceScope, checkedKeys, info);

    var newExpandedKey = [ ...this.state.defaultExpandedKeys, ...info.halfCheckedKeys ];

    var data = {
      serviceScope: serviceScope,
      defaultScopeExpandedKeys: newExpandedKey,
      defaultScopeCheckedKeys: checkedKeys,
      defaultScopeSelectedKeys: checkedKeys,
      feeHeadScope: parentSelectedScope,
    };
    this.props.setServiceScope(data);
    this.setState({
      defaultExpandedKeys: newExpandedKey,
      feeHeadScope: parentSelectedScope,
    });
  };

  //render method
  render() {
    //deconstruct the state
    const {
      defaultExpandedKeys,
      defaultSelectedKeys,
      defaultCheckedKeys,
      serviceScope,
    } = this.state;

    //Return tree component
    return (
      <div className={ 'deliverableList' }>
        <Tree
          showLine
          checkable
          selectable={ false }
          onExpand={ this.onExpand }
          onCheck={ this.onCheck }
          treeData={ serviceScope }
          motion={ motion }
          defaultExpandedKeys={ defaultExpandedKeys }
          defaultSelectedKeys={ defaultSelectedKeys }
          defaultCheckedKeys={ defaultCheckedKeys }
        />
      </div>
    );
  }
}

//Declare proptypes
RCTree.propTypes = {
  serviceScope: PropTypes.array,
  defaultExpandedKeys: PropTypes.array,
  defaultSelectedKeys: PropTypes.array,
  defaultCheckedKeys: PropTypes.array,
  setServiceScope: PropTypes.func,
};

//export RCTree component
export default RCTree;
