const styles = {
	cardMain: { marginBottom: 6, borderRadius: 5 },
	cardHeader: {
		marginTop: -5,
		marginBottom: 6,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	cardDiv: {
		fontSize: 14,
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textTransform: 'capitalize',
	},
	cardBody: { fontSize: 14, color: '#BD3B36', marginBottom: -8 },
	cardFooter: {
		color: '#848484',
		paddingTop: 2,
		marginTop: 5,
		marginBottom: -7,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontSize: 12,
	},
	mobileAndNriVerified: {
		fontSize: 18,
		marginLeft: 3,
		marginBottom: -3,
		color: '#558749',
	},
	activeFollowup: {
		fontSize: 18,
		marginLeft: 3,
		marginBottom: -3,
		color: '#ff5882aa',
	},
	duplicatesCount: {
		fontSize: 18,
		marginLeft: 3,
		marginBottom: -3,
		color: '#607d8bba',
	},
	mobileAndNriNotVerified: {
		fontSize: 17,
		marginLeft: 3,
		marginBottom: -3,
		color: '#6f6f6f',
		transform: 'rotate(0deg)',
	},
	nriGlobe: {
		fontSize: 18,
		marginLeft: 5,
		marginBottom: -4,
		color: '#558749',
	},
	address: {
		color: '#868686',
		fontWeight: '500',
		textAlign: 'left',
		fontSize: 12,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textTransform: 'Capitalize',
	},
	dateTime: {
		fontSize: 16,
		marginTop: 2,
		fontStyle: 'italic',
		color: '#7d7d7d',
		float: 'right',
	},
	contactNo: {
		fontWeight: 500,
		fontSize: 15,
		fontStyle: 'italic',
		float: 'left',
	},
	tag: { fontSize: 12, marginLeft: -3, marginRight: 8, fontWeight: 600 },
	tagContainer: { marginBottom: 5 },
	tagFloatRight: { fontSize: 12, float: 'right' },
	laneHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	laneDiv: {
		fontSize: 16,
		fontWeight: 'bold',
		textTransform: 'capitalize',
	},
	laneLabel: { width: '30%', textAlign: 'right', fontSize: 16 },
	chatDiv: {
		backgroundColor: '#EBEBEB',
		height: 500,
		overflowY: 'scroll',
		borderRadius: 5,
		marginBottom: 10,
	},
	modalListIcon: { fontSize: 20, color: '#262626' },
	followupCrossedBar: {
		backgroundColor: '#fff',
		fontSize: 11,
		marginTop: 15,
		marginBottom: -14,
		marginLeft: -14,
		marginRight: -14,
		textAlign: 'center',
		fontWeight: 500,
		// borderRadius: '70px 70px 0px 0px',
	},
	followupLeftBar: {
		backgroundColor: '#71a0ff',
		fontSize: 11,
		marginTop: 15,
		marginBottom: -14,
		marginLeft: -14,
		marginRight: -14,
		textAlign: 'center',
		fontWeight: 500,
		borderRadius: '70px 70px 0px 0px',
	},
	followupHoursLeftBar: {
		backgroundColor: '#f95c50',
		fontSize: 11,
		marginTop: 15,
		marginBottom: -14,
		marginLeft: -14,
		marginRight: -14,
		textAlign: 'center',
		fontWeight: 500,
		borderRadius: '70px 70px 0px 0px',
	},
	followupMinutesLeftBar: {
		backgroundColor: '#ff4100',
		fontSize: 11,
		marginTop: 15,
		marginBottom: -14,
		marginLeft: -14,
		marginRight: -14,
		textAlign: 'center',
		fontWeight: 500,
		borderRadius: '70px 70px 0px 0px',
	},
	proposalLastSeenBar: {
		backgroundColor: '#1b2c32',
		fontSize: 11,
		marginTop: 15,
		marginBottom: -14,
		marginLeft: -14,
		marginRight: -14,
		textAlign: 'center',
		fontWeight: 500,
		borderRadius: '70px 70px 0px 0px',
	},
	followupTimeCrossed: {
		color: '#000',
	},
	followupTimeLeftMinutes: {
		color: 'orange',
	},
	followupTimeLeft: {
		color: '#000',
	},
}

export default styles
