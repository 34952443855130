import gql from 'graphql-tag'

export const ADD_FOLLOWUP_TIME_MUTATION = gql`
	mutation AddEnquiryFollowup(
		$enquiryId: String
		$followupTimestamp: Int
		$stageFrom: String
	) {
		addEnquiryFollowup(
			enquiryId: $enquiryId
			followupTimestamp: $followupTimestamp
			stageFrom: $stageFrom
		) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
