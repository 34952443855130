const styles = {
	feedbackSuccessContainer: {
		position: 'relative',
		paddingTop: '3vh',
		margin: '0 auto',
		width: '50%',
	},
	childContainer: {
		width: 'inherit',
		margin: '0 auto',
		border: '1px solid #c7c7c7',
		padding: 15,
		borderRadius: 8,
	},
	childContainerTitle: {
		textAlign: 'left',
		fontWeight: 300,
		fontSize: '3vmin',
		wordWrap: 'break-word',
		lineHeight: 1.5,
	},
	userContainerTitle: {
		textAlign: 'justify',
		fontWeight: 300,
		fontSize: '3vmin',
		wordWrap: 'break-word',
	},
	subPageImage: { width: 'inherit' },
}

export default styles
