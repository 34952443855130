// Importing required components from ANTD
import {
	Avatar,
	Card,
	Divider,
	Empty,
	Icon,
	Spin,
	Tooltip,
	Button,
	Row,
	Col,
	Typography,
	Tag,
} from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import React from 'react'
import $ from 'jquery'
import BottomScrollListener from 'react-bottom-scroll-listener'
// Query component of React Apollo to fetch data
import { Query } from 'react-apollo'
import urlRegex from 'url-regex'
import { FiExternalLink } from 'react-icons/fi'
import { format } from 'date-fns'
import { Lightbox } from 'react-modal-image'
// import noProfileUrlAvatar from '../../../assets/no_picture_url.png'
// import utils from '../../../utils'
import ApproveFiles from './ApproveFiles'
import ReviseFiles from './ReviseFiles'
import NoteFiles from './NoteFiles'
import FetchComments from './FetchComments'
import { GoComment } from 'react-icons/go'
// Query to fetch CRM Details on load
import { ForReviewList, CheckDriveAuth } from './query'
import './drawings.css'
// Component CSS
// import styles from './styles'
// import AddNotes from './AddNotes/index'
// import SomeErrorHasOccured from '../../UtilComponents'

import { icons } from 'react-icons/lib/cjs'
import { from } from 'apollo-link'
const { Meta } = Card

// const { stringDecode } = utils

class Drawings extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lightboxOpen: false,
			currentThumbnailLink: '',
			currentWebContentLink: '',
			currentWebViewLink: '',
			currentFileName: '',
			currentFileUser: '',
			currentFileStatus: '',
			currentDriveObject: null,
			currentDriveArray: [],
			pageToken: '',
		}
	}

	closeLightbox = (data) => {
		if (data && data.mimeType === 'application/pdf') {
			window.open(data.webViewLink)
		} else if (data) {
			this.setState(
				{
					lightboxOpen: !this.state.lightboxOpen,
					currentThumbnailLink: data.thumbnailLink,
					currentWebContentLink: data.webContentLink,
					currentWebViewLink: data.webViewLink,
					currentFileName: data.name,
					currentFileStatus: data.status,
					currentFileUser: data.lastModifyingUser.displayName,
					currentDriveObject: data,
				},
				() => {
					$('#react-modal-image-img').on('load', function() {
						// check width
						const imageWidth = $('#react-modal-image-img').width()
						const imageHeight = $('#react-modal-image-img').height()
						$('#react-modal-image-img')
							.parent('div')
							.addClass('commentsAnchorDiv')
						$('.commentsAnchorDiv').css('width', imageWidth)
						$('.commentsAnchorDiv').css('height', imageHeight)
					})
				}
			)
		} else {
			this.setState({
				lightboxOpen: !this.state.lightboxOpen,
			})
		}
	}

	handleContainerOnBottom = (dataArray) => {
		if (dataArray[0].nextPageToken !== null) {
			this.setState({
				currentDriveArray: [...this.state.currentDriveArray, ...dataArray],
			})
			this.setState({ pageToken: dataArray[0].nextPageToken })
		}
	}

	render() {
		const {
			lightboxOpen,
			currentThumbnailLink,
			currentWebContentLink,
			currentWebViewLink,
			currentFileName,
			currentFileUser,
			currentFileStatus,
			currentDriveObject,
			currentDriveArray,
			pageToken,
		} = this.state
		const { projectUrl, authToken } = this.props

		return (
			<>
				<Query query={CheckDriveAuth}>
					{({ data, loading, error, refetch }) => {
						if (loading) {
							return (
								<Spin
									indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
									style={{ padding: '30px 52px' }}
								/>
							)
						}
						if (data && data.checkDriveAuth) {
							return (
								<Query
									query={ForReviewList}
									variables={{
										projectUrl,
										pageToken,
									}}>
									{({ data, loading, error, refetch }) => {
										// if (loading) {
										// 	return (
										// 		<Spin
										// 			indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
										// 			style={{ padding: '30px 52px' }}
										// 		/>
										// 	)
										// }
										if (!data) {
											return (
												<Spin
													indicator={
														<Icon type="loading" style={{ fontSize: 24 }} spin />
													}
													style={{ padding: '30px 52px' }}
												/>
											)
										}
										if (data) {
											const { forReviewFiles } = data
											const { dataList } = forReviewFiles

											const mappedData = [...currentDriveArray, ...dataList]
											return (
												<BottomScrollListener
													onBottom={() => this.handleContainerOnBottom(dataList)}>
													{(scrollRef) => (
														<div
															ref={scrollRef}
															style={{
																height: '92%',
																marginLeft: '1.3%',
																marginRight: '1%',
																overflowX: 'hidden',
																overflowY: 'scroll',
															}}>
															{lightboxOpen && (
																<div>
																	<div
																		style={{
																			position: 'absolute',
																			color: '#fff',
																			top: 16,
																			right: 92,
																			zIndex: 9999,
																			cursor: 'pointer',
																		}}>
																		<FetchComments driveFileProps={currentDriveObject} />
																	</div>
																	<div
																		style={{
																			position: 'absolute',
																			color: '#fff',
																			top: 12,
																			right: 131,
																			zIndex: 9999,
																			cursor: 'pointer',
																		}}>
																		<Tooltip
																			title="Open in Google Drive"
																			placement="bottom">
																			<a href={currentWebViewLink} target="_blank">
																				<FiExternalLink
																					style={{ fontSize: 24, color: '#3083ff' }}
																				/>
																			</a>
																		</Tooltip>
																	</div>
																	{currentFileStatus === 'For Review' ? (
																		<div
																			style={{
																				position: 'absolute',
																				color: '#fff',
																				top: 13,
																				right: 172,
																				zIndex: 9999,
																				cursor: 'pointer',
																			}}>
																			<ReviseFiles
																				fileId={currentDriveObject.id}
																				forReviewId={currentDriveObject.forReviewFolderId}
																				forRevisionId={
																					currentDriveObject.forRevisionFolderId
																				}
																				thumbnailLink={currentDriveObject.thumbnailLink}
																				refetchDrawings={refetch}
																				modalSwitch={this.closeLightbox}
																				modalView={true}
																			/>
																		</div>
																	) : null}
																	{currentFileStatus === 'For Review' ? (
																		<div
																			style={{
																				position: 'absolute',
																				color: '#fff',
																				top: 13,
																				right: 215,
																				zIndex: 9999,
																				cursor: 'pointer',
																			}}>
																			<ApproveFiles
																				fileId={currentDriveObject.id}
																				forReviewId={currentDriveObject.forReviewFolderId}
																				approvedId={currentDriveObject.approvedFolderId}
																				thumbnailLink={currentDriveObject.thumbnailLink}
																				refetchDrawings={refetch}
																				modalSwitch={this.closeLightbox}
																				modalView={true}
																			/>
																		</div>
																	) : null}
																	{currentFileStatus === 'For Revision' ? (
																		<div
																			style={{
																				position: 'absolute',
																				color: '#fff',
																				top: 12,
																				right: 175,
																				zIndex: 9999,
																				cursor: 'pointer',
																			}}>
																			<NoteFiles
																				fileId={currentDriveObject.id}
																				notedId={currentDriveObject.notedFolderId}
																				forRevisionId={
																					currentDriveObject.forRevisionFolderId
																				}
																				thumbnailLink={currentDriveObject.thumbnailLink}
																				refetchDrawings={refetch}
																				modalSwitch={this.closeLightbox}
																				modalView={true}
																			/>
																		</div>
																	) : null}
																	<Lightbox
																		small={currentThumbnailLink}
																		large={currentWebContentLink}
																		alt={`${currentFileUser}  -  ${currentFileName}`}
																		hideDownload
																		// showRotate
																		onClose={() => this.closeLightbox()}
																		style={{
																			objectFit: 'cover',
																			width: 298,
																			height: 160,
																		}}
																	/>
																	{/* <FetchComments driveFileProps={currentDriveObject} /> */}
																</div>
															)}
															<Row gutter={16}>
																{mappedData.map((values, index) => {
																	return (
																		<Col span={8}>
																			<div
																				key={index}
																				style={{ marginTop: 10, marginBottom: 10 }}>
																				<Card
																					hoverable
																					style={{ width: 300 }}
																					cover={
																						<div style={{ position: 'relative' }}>
																							<img
																								alt={values.name}
																								// src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
																								src={
																									values.thumbnailLink ||
																									values.webContentLink
																								}
																								onClick={() => this.closeLightbox(values)}
																								onError={() =>
																									(this.src = values.webContentLink)
																								}
																								style={{
																									objectFit: 'cover',
																									width: 298,
																									height: 160,
																								}}
																							/>
																							{/* <Tag style={{position: 'absolute', color: '#fff', backgroundColor: '#4c4c4cc4', border: 'none', left: 8, bottom: 8}}>{values.lastModifyingUser.displayName}</Tag> */}
																							<Tag
																								color={
																									values.status === 'For Review'
																										? '#15A5B0'
																										: values.status === 'For Revision'
																										? '#FFA500'
																										: values.status === 'Approved'
																										? '#0A9D45'
																										: '#0A689D'
																								}
																								style={{
																									position: 'absolute',
																									right: 0,
																									bottom: 8,
																								}}>
																								{values.status}
																							</Tag>
																						</div>
																					}
																					actions={
																						values.status === 'For Review'
																							? [
																									<Tooltip
																										title="Open in Google Drive"
																										placement="bottom">
																										<a
																											id={values.id}
																											href={values.webViewLink}
																											target="_blank">
																											<FiExternalLink
																												style={{
																													fontSize: 18,
																													color: '#3083ff',
																												}}
																											/>
																										</a>
																									</Tooltip>,
																									<ReviseFiles
																										fileId={values.id}
																										forReviewId={values.forReviewFolderId}
																										forRevisionId={
																											values.forRevisionFolderId
																										}
																										thumbnailLink={values.thumbnailLink}
																										refetchDrawings={refetch}
																										modalSwitch={this.closeLightbox}
																										modalView={false}
																									/>,
																									<ApproveFiles
																										fileId={values.id}
																										forReviewId={values.forReviewFolderId}
																										approvedId={values.approvedFolderId}
																										thumbnailLink={values.thumbnailLink}
																										refetchDrawings={refetch}
																										modalSwitch={this.closeLightbox}
																										modalView={false}
																									/>,
																									// <FetchComments driveFileProps={values} />,
																							  ]
																							: values.status === 'For Revision'
																							? [
																									<Tooltip
																										title="Open in Google Drive"
																										placement="bottom">
																										<a
																											id={values.id}
																											href={values.webViewLink}
																											target="_blank">
																											<FiExternalLink
																												style={{
																													fontSize: 18,
																													color: '#3083ff',
																												}}
																											/>
																										</a>
																									</Tooltip>,
																									<NoteFiles
																										fileId={values.id}
																										notedId={values.notedFolderId}
																										forRevisionId={
																											values.forRevisionFolderId
																										}
																										thumbnailLink={values.thumbnailLink}
																										refetchDrawings={refetch}
																										modalSwitch={this.closeLightbox}
																										modalView={false}
																									/>,
																							  ]
																							: [
																									<Tooltip
																										title="Open in Google Drive"
																										placement="bottom">
																										<a
																											id={values.id}
																											href={values.webViewLink}
																											target="_blank">
																											<FiExternalLink
																												style={{
																													fontSize: 18,
																													color: '#3083ff',
																												}}
																											/>
																										</a>
																									</Tooltip>,
																									// <FetchComments driveFileProps={values} />,
																							  ]
																					}>
																					<Meta
																						title={values.name}
																						description={
																							<>
																								<Row>
																									<Col span={16}>
																										<span
																											style={{
																												fontSize: 15,
																												fontWeight: 600,
																											}}>
																											{values.lastModifyingUser
																												.displayName || 'Anonymous'}
																										</span>
																									</Col>
																									<Col span={8}>
																										<span
																											style={{
																												float: 'right',
																												fontSize: 13,
																											}}>
																											{format(
																												new Date(values.modifiedTime),
																												'MMM dd, yyyy'
																											)}
																										</span>
																									</Col>
																								</Row>
																								<Tag
																									color={
																										values.status === 'For Review'
																											? '#15A5B04A'
																											: values.status === 'For Revision'
																											? '#FFA5004A'
																											: values.status === 'Approved'
																											? '#0A9D454A'
																											: '#0A689D4A'
																									}
																									style={{ marginTop: 4, color: '#000' }}>
																									{values.projectName}
																								</Tag>
																							</>
																						}
																					/>
																				</Card>

																				{/* <ApproveFiles
														fileId={values.id}
														forReviewId={forReviewFolderId}
														approvedId={approvedFolderId}
														thumbnailLink={values.thumbnailLink}
													/>
													<ReviseFiles
														fileId={values.id}
														forReviewId={forReviewFolderId}
														forRevisionId={forRevisionFolderId}
														thumbnailLink={values.thumbnailLink}
													/>
													<FetchComments
														fileId={values.id}
														thumbnailLink={values.thumbnailLink}
													/>
                          */}

																				<br></br>
																			</div>
																		</Col>
																	)
																})}
															</Row>
														</div>
													)}
												</BottomScrollListener>
											)
										}
									}}
								</Query>
							)
						} else {
							return (
								<Link to="/authenticateGoogleDrive">
									<Button type="primary" style={{ marginLeft: '45%', marginTop: '20%' }}>
										Authorize Google Drive
									</Button>
								</Link>
							)
						}
					}}
				</Query>
			</>
		)
	}
}

export default Drawings
