import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const OAUTH_LOGIN_MUTATION = gql`
  mutation OAuthLogin($accessToken: String!) {
    oAuthLogin(accessToken: $accessToken) {
      accessToken
      refreshToken
    }
  }
`;
