// Import react component
import React from 'react'
import { Row, Col, Result, Input, Tooltip } from 'antd'
import {
	getTypologyName,
	addServiceElasticAmount,
	addServiceElasticSqft,
	addServiceElasticTime,
	addServiceName,
	addServiceElasticPriceRow,
	removeServiceElasticPriceRow,
	addFeeHeadName,
	addCoverTitle,
	changeEstimationCostPerSqft,
	changePaymentPercentage,
} from '../utils'
import './index.css'

import { IoIosInformationCircle } from 'react-icons/io'

class serviceResultConfig extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			serviceName: null,
			feeHeadName: null,
			coverTitle: null,
			typologyName: null,
			paymentPercentage: {
				percentage: 15,
				estimationCostPerSqft: 1000,
			},
			elasticPrice: [
				{ sqft: '500', amount: 500, time: 45 },
				{ sqft: '1000', amount: 450, time: 50 },
				{ sqft: '2500', amount: 400, time: 60 },
				{ sqft: '10000', amount: 300, time: 75 },
				{ sqft: '50000', amount: 200, time: 90 },
			],
		}
	}

	componentDidMount() {
		const { serviceResult, serviceSpace } = this.props
		const {
			serviceName,
			feeHeadName,
			coverTitle,
			paymentPercentage,
			elasticPrice,
		} = serviceResult

		const { typology, typologyId } = serviceSpace
		const typologyName = getTypologyName(typology, typologyId)
		this.setState({
			serviceName,
			feeHeadName,
			coverTitle,
			typologyName,
			paymentPercentage,
			elasticPrice,
		})
	}

	// Change service name in Proposal service
	onChangeServiceName = async (evt) => {
		this.setState({ serviceName: evt.target.value })
		const serviceResult = await addServiceName(this.props, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// Change fee head name in proposal service
	onChangeFeeHeadName = async (evt) => {
		this.setState({ feeHeadName: evt.target.value })
		const serviceResult = await addFeeHeadName(this.props, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// change cover title in proposal service
	onChangeCoverTitle = async (evt) => {
		this.setState({ coverTitle: evt.target.value })
		const serviceResult = await addCoverTitle(this.props, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// change service elastic price
	onChangeServiceSqft = (idx) => async (evt) => {
		const { elasticPrice } = this.state
		const newElasticPrice = elasticPrice.map((item, i) => {
			if (idx !== i) return item
			return { ...item, sqft: evt.target.value }
		})
		this.setState({ elasticPrice: newElasticPrice })

		const serviceResult = await addServiceElasticSqft(this.props, idx, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// change service elastic amount
	onChangeServiceAmount = (idx) => async (evt) => {
		const { elasticPrice } = this.state
		const newElasticPrice = elasticPrice.map((item, i) => {
			if (idx !== i) return item
			return { ...item, amount: evt.target.value }
		})
		this.setState({ elasticPrice: newElasticPrice })
		const serviceResult = await addServiceElasticAmount(this.props, idx, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// change service elastic time to complete the design
	onChangeServiceTime = (idx) => async (evt) => {
		const { elasticPrice } = this.state
		const newElasticPrice = elasticPrice.map((item, i) => {
			if (idx !== i) return item
			return { ...item, time: evt.target.value }
		})
		this.setState({ elasticPrice: newElasticPrice })
		const serviceResult = await addServiceElasticTime(this.props, idx, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// Add New price in elastic pricing
	addNewPrice = async () => {
		const { elasticPrice } = this.state
		this.setState({
			elasticPrice: elasticPrice.concat([{ sqft: null, amount: null, time: null }]),
		})
		const serviceResult = await addServiceElasticPriceRow(this.props)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// Remove already existing price in elastic pricing
	removeNewPrice = (idx) => async () => {
		const { elasticPrice } = this.state
		this.setState({ elasticPrice: elasticPrice.filter((element, id) => idx !== id) })
		const serviceResult = await removeServiceElasticPriceRow(this.props, idx)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// change estimation cost per sqft
	onChangeEstimationCost = async (evt) => {
		const { paymentPercentage } = this.state
		paymentPercentage.estimationCostPerSqft = evt.target.value
			? parseInt(evt.target.value.replace(/\D/g, ''))
			: 0
		this.setState({ paymentPercentage })
		const serviceResult = await changeEstimationCostPerSqft(this.props, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	// change payment percentage
	onChangePercentage = async (evt) => {
		const { paymentPercentage } = this.state
		paymentPercentage.percentage = evt.target.value
			? parseInt(evt.target.value.replace(/\D/g, ''))
			: 0
		this.setState({ paymentPercentage })
		const serviceResult = await changePaymentPercentage(this.props, evt)
		const { setServiceResultConfig } = this.props
		setServiceResultConfig(serviceResult)
	}

	render() {
		// Deconstruct props
		const { insertStatus, serviceStageConfig, serviceStatus } = this.props

		const {
			serviceName,
			feeHeadName,
			coverTitle,
			typologyName,
			paymentPercentage,
			elasticPrice,
		} = this.state

		// Deconstruct service stage config
		const { stageStepSource } = serviceStageConfig

		// Deconstruct payment percentage
		const { percentage, estimationCostPerSqft } = paymentPercentage

		// Get typology name

		return (
			<div>
				{!insertStatus ? (
					<div className="serviceResultDiv">
						<h1>
							{stageStepSource.length - 1} Stage - {typologyName}
						</h1>
						<Row>
							<Col span={5} />
							<Col span={16}>
								{/** Service Details */}
								<form className="serviceResult">
									{/** Service Name */}
									{/* <h4 className="boldText">Template Name</h4>

									<div className={'serviceResultInput'}>
										<input
											className={`inputBorder ${serviceName !== '' ? '' : 'error'}`}
											type="text"
											placeholder="Enter Template Name"
											value={serviceName === null ? '' : serviceName}
											onChange={this.onChangeServiceName}
										/>
										<Tooltip
											placement="bottom"
											title={'This name will be not visible to the proposal document.'}>
											<IoIosInformationCircle
												className={'serviceInputMessage'}
												color={'red'}
											/>
										</Tooltip>
									</div> */}

									{/** Fee Head Name */}
									<h4 className="boldText">Fee Head Name</h4>
									<div className={'serviceResultInput'}>
										<input
											className={`inputBorder ${feeHeadName !== '' ? '' : 'error'}`}
											type="text"
											placeholder="Enter Fee Head Name"
											value={feeHeadName === null ? '' : feeHeadName}
											onChange={this.onChangeFeeHeadName}
										/>
										<Tooltip
											placement="bottom"
											title={'This name will be visible to the proposal document.'}>
											<IoIosInformationCircle
												className={'serviceInputMessage'}
												color={'green'}
											/>
										</Tooltip>
									</div>

									{/** Cover Title */}
									<h4 className="boldText">Cover Title</h4>
									<div className={'serviceResultInput'}>
										<input
											className={`inputBorder ${coverTitle !== '' ? '' : 'error'}`}
											type="text"
											placeholder="Enter Cover Title"
											value={coverTitle === null ? '' : coverTitle}
											onChange={this.onChangeCoverTitle}
										/>
										<Tooltip
											placement="bottom"
											title={'This name will be visible to the proposal document.'}>
											<IoIosInformationCircle
												className={'serviceInputMessage'}
												color={'green'}
											/>
										</Tooltip>
									</div>

									{/** Design Fee Percentage and estimation cost */}
									<h4 className="boldText">Design Fee in Percentage</h4>
									<div className="paymentPercentage">
										<div className="percentage">
											<Input
												className={`inputBorder ${percentage !== '' ? '' : 'error'}`}
												addonBefore="Percentage"
												defaultValue={percentage}
												value={percentage}
												onChange={this.onChangePercentage}
												placeholder="Percentage"
											/>
										</div>
										<div className="estimationCost">
											<Input
												className={`inputBorder ${
													estimationCostPerSqft !== '' ? '' : 'error'
												}`}
												addonBefore="Estimation Cost per Sqft"
												defaultValue={estimationCostPerSqft}
												value={estimationCostPerSqft}
												onChange={this.onChangeEstimationCost}
												placeholder="Cost per sqft"
											/>
										</div>
									</div>

									{/** Service Elastic price details */}
									<div key="Scope" style={{ marginTop: '30px' }}>
										<Row type="flex" className="boldText">
											<Col span={5} order={1}>
												Area
											</Col>
											<Col span={6} order={2} offset={1}>
												Service Fee / sft
											</Col>
											<Col span={8} order={3} offset={1}>
												Design Time(days)
											</Col>
											<Col span={5} order={4} offset={1} />
										</Row>
									</div>
									{elasticPrice.map((price, idx) => (
										<div key={('Scope', idx)}>
											<Row type="flex">
												<Col span={5} order={1}>
													<input
														type="number"
														min={0}
														placeholder="Area"
														value={price.sqft}
														onChange={this.onChangeServiceSqft(idx)}
														className={`inputBorder ${price.sqft !== '' ? '' : 'error'}`}
													/>
												</Col>
												<Col span={5} order={2} offset={1}>
													<input
														type="number"
														min={0}
														placeholder="Fee / sqft"
														value={price.amount}
														onChange={this.onChangeServiceAmount(idx)}
														className={`inputBorder ${
															price.amount !== '' ? '' : 'error'
														}`}
													/>
												</Col>
												<Col span={5} order={3} offset={2}>
													<input
														type="number"
														min={0}
														placeholder="Design time"
														value={price.time}
														onChange={this.onChangeServiceTime(idx)}
														className={`inputBorder ${price.time !== '' ? '' : 'error'}`}
													/>
												</Col>
												<Col span={5} order={4} offset={1}>
													<button
														type="button"
														onClick={this.removeNewPrice(idx)}
														className="addPrice">
														-
													</button>
												</Col>
											</Row>
										</div>
									))}

									{/** Add New Service Price Name */}
									<button type="button" onClick={this.addNewPrice} className="addPrice">
										+ Add Price
									</button>
								</form>
							</Col>
							<Col span={7} />
						</Row>
					</div>
				) : (
					// Service Created Success message
					<div>
						<Result
							status="success"
							title={
								serviceStatus === 'create'
									? 'Template created successfully.'
									: 'Template updated successfully.'
							}
						/>
					</div>
				)}
			</div>
		)
	}
}

export default serviceResultConfig
