import gql from 'graphql-tag'

export const CrmDetails = gql`
	query lanes($crmStatus: String, $offset: Int, $limit: Int) {
		lanes(crmStatus: $crmStatus, offset: $offset, limit: $limit) {
			id
			label
			cards {
				id
				enquiryType
				enquirySection
				enquiryLane
				name
				contactNo
				otpCheck
				enquiryTimestamp
				area
				areaType
				scope
				typology
				modifiedAt
				modifiedBy
				address
				email
				followupActive
				followupDate
				proposalLastSeen
				duplicateEnquiries
				preferredTimeToCall
			}
			style {
				width
			}
		}
	}
`
