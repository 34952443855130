const styles = {
	/**
	 * This styles is used to add a render fuction css.
	 */

	// loading icon css
  loadingIcon: { fontSize: 50 },
  loadingIcons:{fontSize: 30},
  loadIconStyles:{padding: '30px 400px'},
	loadIconStyle: { padding: '30px 400px' },
  // nomore
  noMore:{
    fontSize:'14px',
    textAlign: 'center',
    padding: '0px 0px 88px 0px',
  },
  mutedText:{
    width: '80%',
    fontSize: '10px',
    textAlign: 'center',
  },
  // error 
  error:{
    color:'red',
    padding:'1%',
    marginLeft: '32%',
    marginTop: '-2%',
  },
  errorCaption:{
    color:'red',
    padding:'1%',
    marginTop: '-1%',
  },

	// uploadCard
	uploadCard: {
    
    borderStyle: 'dashed',
    borderColor: '#1890ff',
    borderWidth: '2px',
		maxHeight: '9.75rem',
		minHeight: '9.75rem',
		maxWidth: '9.75rem',
		minWidth: '9.75rem',
		// textAlign: 'center',
    // padding: '45px',
    color: '#1890ff',
    backgroundColor: 'ghostwhite',
    cursor: 'pointer',
    marginRight: '10px',
  },
  uploadDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignItems:'center',
    height:'100%',
  },
  uploadAdd: {
    fontSize: '35px',
  },
	// modal image upload
	uploadModal: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		position: 'relative',
	},
	uploadModalSpace: {
    flex: 0.1,
    display: 'flex',
    alignItems: 'center',
  },
  uploadSpaceLabel:{
    width: '50%',
    textAlign: 'center',
  },
 
	uploadModalImage: {
		flex: 0.7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
	},
	uploadModalCaption: {
		flex: 0.1,
    display: 'flex',
    alignItems: 'center',
  },
  uploadModalButton:{
    flex: 0.1,
    display: 'flex',
    flexFlow: 'row-reverse',
    alignItems: 'center',
  },
  uploadFilter: { width: '-webkit-fill-available' },
  



	siteImagesUpload: {
    maxHeight: '100%',
    maxWidth: '100%',
    transition: 'transform 0.1s ease-in-out',
  },

	// whole container of site photos
	sitePhoto: {
		margin: '0 -24px',
		boxShadow: 'inset 0px 2px 8px #00000047',
		padding: '24px 26px 0 30px',
		height: 'calc(100vh - 84px)',
		overflowY: 'auto !important',
		overflowX: 'hidden',
	},

	// backtop button style
	BackToInner: {
		height: '40px',
		width: '40px',
		lineHeight: '40px',
		borderRadius: '4px',
		color: '#9a9696',
		textAlign: 'center',
		fontSize: '20px',
		position: 'absolute',
		bottom: '10px',
		right: '10px',
		display: 'block',
		cursor: 'pointer',
	},

	// space filter div
	spaceFilter: { display: 'flex', flexDirection: 'row-reverse' },
	// space filter css
	filter: { width: 200, position: 'absolute', top: '70px' },

	// site photos date display
	dateFilter: {
		marginBottom: '2.3rem',
		paddingLeft: '1%',
	},
	dateDisplay: { marginBottom: 5, fontWeight: '600', color: 'black' },

	// particular date related image grid
	imageGrids: {
		display: 'flex',
  },
  imageGrid:{
    display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: '0.5rem',
  },
	cardstyle: { marginLeft: '2px', marginBottom: '2px' },
	// add box shadow if user already visit or not for image
	addBorderColor: {
		boxShadow: '0px 0px 0px 2px #e3f931',
	},
	imageBox: { position: 'relative', display: 'inline-block' },

	// poster name
	posterName: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row-reverse',
		left: 0,
		right: '0px',
		height: '20px',
		top: '0px',
		color: '#fff',
		paddingRight: '3px',
		fontSize: 'smaller',
	},
	// image display
	siteImages: {
		cursor: 'pointer',
		objectFit: 'cover',
		maxHeight: '9.75rem',
		minHeight: '9.75rem',
		maxWidth: '9.75rem',
		minWidth: '9.75rem',
	},
	// image description display
	commentDetail: {
		width: '100%',
		position: 'absolute',
		left: 0,
		right: 0,
		height: '20px',
		bottom: '0px',
		color: '#fff',
		background: '#0e0c0cb5',
		display: 'flex',
		justifyContent: 'space-between',
	},

	posterDescription: {
		color: 'white',
		marginBottom: '1px',
		paddingLeft: '5px',
		fontSize: 'smaller',
		// add ellipsis for description
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 1,
		WebkitBoxOrient: 'vertical',
	},
	// comment icon display
	commentIcon: {
		float: 'right',
		marginRight: '4px',
		color: '#d9d9d9',
		fontSize: 'medium',
	},
	// comment icon without background display
	commentIconWithoutBackgroud: {
		position: 'absolute',
		right: 0,
		height: '20px',
		bottom: '0px',
		color: '#fff',
		marginRight: '4px',
	},
	// full modal div
	fullModal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
	},
	// modal navigation left
	leftIcon: {
		fontSize: 'xx-large',
		position: 'absolute',
		left: '-60px',
		cursor: 'pointer',
	},
	navigationIconColor: {
		fill: 'white',
	},
	// modal navigation right
	rightIcon: {
		fontSize: 'xx-large',
		position: 'absolute',
		right: '-60px',
		cursor: 'pointer',
	},

	/**
	 * This styles is used to add a modal fuction css.
	 */

	modalBox: {
		height: '86vh',
		width: '70vw',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	// modal image display
	modalImageBox: {
		width: '50%',
		backgroundColor: 'black',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
	},
	modalImageLight: {
		backgroundSize: 'cover',
		WebkitFilter: 'blur(13px)',
		MozFilter: 'blur(13px)',
		OFilter: 'blur(13px)',
		MsFilter: 'blur(13px)',
		filter: 'blur(3px)',
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		width: '100%',
		height: '100%',
	},
	// image blur div
	modalImageBlur: {
		backgroundColor: '#00000080',
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		width: '100%',
		height: '100%',
	},
	// modal image
	siteImage: {
		maxHeight: '100%',
		maxWidth: '100%',
		position: 'absolute',
		zIndex: '9999',
		webkitFilter: 'blur(0px)',
		mozFilter: 'blur(0px)',
		oFilter: 'blur(0px)',
		msFilter: 'blur(13px)',
		filter: 'blur(0px)',
	},
	// modal detail
	modalDetail: {
		padding: '10px 10px 10px 10px',

		width: '50%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
	},
	// profile head section css
	profileHead: {
		flex: '0.1',
	},
	profileSection: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	profileName: {
		width: '100%',
		display: 'flex',
	},
	// modal profile picture
	modalProfilePic: {
		borderRadius: '50%',
		marginRight: '1%',
		marginBottom: '1%',
	},
	// user profile picture no
	modalProfilePicNo: {
		borderRadius: '50%',
		marginRight: '1%',
		marginBottom: '1%',
		backgroundColor: 'rgba(107, 107, 189, 0.45)',
		padding: '3px',
		fontSize: '12px',
		width: '25px',
		height: '25px',
		textAlign: 'center',
	},
	// modal name
	modalName: { fontWeight: '700', fontSize: '16px', marginTop: '1px' },
	// modal time
	modalTime: {
		width: '100%',
		color: '#90949c',
		fontSize: '12px',
		// textAlign: 'right',
	},
	modalPostSpace: {
		textDecoration: 'underline',
	},
	// modal project
	modalProject: {
		fontSize: '12px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 1,
		WebkitBoxOrient: 'vertical',
		marginTop: '2px',
	},
	// project name display
	projectNames: {
		fontWeight: '600',
		fontSize: '14px',
		color: '#138ec7',
		fontStyle: 'italic',
	},
	projectName: { fontWeight: '600', fontSize: '14px' },
	// description text
	descriptionName: { fontWeight: '500', fontSize: '12px' },
	// add description text css
	addDescription: {
		fontSize: '12px',
		fontWeight: '200',
		color: '#aaa',
  },
  editBlock:{
    display:'flex',
    padding:'5px 5px 9px 5px',
  },
  editBlockIcon :{
    width:'90%',
    marginBottom: '0px',
  },
  editBlockButton:{
    // width:'10%',
    marginTop:'-5px',
  },
	// comment section div
	commentDisplay: {
		backgroundColor: 'rgb(235, 235, 235)',
		// boxShadow: '0 0 4px 0px #ada9a9',
		overflowY: 'scroll',
		overflowX: 'hidden',
		borderRadius: '4px',
		marginBottom: '10px',
		flex: '0.85',
		marginLeft: '-10px',
		marginRight: '-10px',
	},
	// no ticket display
	noTicket: {
		display: 'flex',
		justifyContent: 'center',
		padding: '25px',
	},
	// show closed disscussion button
	showClosedDiscussion: {
		textDecoration: 'underline',
		textAlign: 'center',
		color: ',#2c8247',
		fontWeight: '700',
		marginBottom: '10px',
		marginTop: '15px',
		cursor: 'pointer',
	},
	// add comment section
	commentAdd: {
		display: 'flex',
		flex: '0.05',
		cursor: 'pointer',
	},
	sendButton: {
		marginLeft: '5px',
		fontSize: '20px',
	},
	sendIcon: {
		marginTop: '5px',
		marginLeft: '3px',
	},

	/**
	 * This styles is used to add a comment display fuction css.
	 */
  deleteHideShow:{
    fontSize:'25px',
    color:'rgba(0, 0, 0, 0.45)',
    marginRight: '5px',
    textAlign:'center',
    cursor:'pointer',
  },
  deleteExtra:{
    padding: '2px 11px 5px 10px',
		borderRadius: '5px',
		marginRight: '6px',
		marginBottom: '10px',
  },
  deletePop:{
    display:'flex',
  },
  deleteColor:{
    color:'rgba(0, 0, 0, 0.45)',
    fontSize: '12px',
  },
  convert: {
    marginTop: '-17px',
    display: 'flex',
    justifyContent: 'flex-end',
    cursor:'pointer',
  },
	lightBlue: {
		backgroundColor: 'rgb(183, 223, 243)',
	},
	lightYellow: {
		backgroundColor: '#ffffc9',
	},
	white: {
		backgroundColor: '#fafafa',
	},
	darkYellow: {
		backgroundColor: '#FFFF96',
	},

	openDiscussion: {
		color: 'rgb(60, 60, 60)',
	},
	parentComment: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '2%',
	},
	// comment align
	right: {
		justifyContent: 'flex-end',
	},
	left: {
		justifyContent: 'flex-start',
	},
	// only for parent comment
	parent: {
		width: '20vw',
	},
  //  Comment Right side display
  childComment:{
    display: 'flex',
  },
	commentRight: {
		padding: '2px 11px 5px 10px',
		maxWidth: '20vw',
		borderRadius: '5px',
		marginRight: '6px',
		marginBottom: '10px',
	},
	//  comment left side display
	commentLeft: {
		padding: '2px 11px 5px 10px',
		maxWidth: '20vw',
		borderRadius: '5px',
		marginLeft: '10px',
		marginBottom: '10px',
	},
	// parent comment profile pic div
	profilePic: {
		display: 'flex',
	},
	// comment name display
	commentNameParent: {
		fontSize: '11px',
		fontWeight: '700',
		marginLeft: '7px',
		marginTop: '2px',
	},

	commentName: {
		fontSize: '11px',
		fontWeight: '700',
		// marginLeft: '7px',
		marginTop: '2px',
	},
	// commeted date display
	commentDate: {
		fontSize: '10px',
		fontWeight: '600',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingLeft: '15px',
		color: 'rgba(0,0,0,.45)',
	},

	commentDateParent: {
		float: 'right',
	},
	commentText: {
		color: '#303030',
		fontSize: '12px',
	},
	// Thums up image display
	rightThumbs: {
		display: 'block',
		float: 'right',
		padding: '2px 17px 5px 10px',
	},
	// team chat image
	teamChatImage: {
		marginLeft: '-7%',
	},
	// comment log
	commentLog: {
		display: 'flex',
		padding: '2px 11px 5px 10px',
	},
	// comment log text
	logText: {
		fontSize: '12px',
		color: '#aaa',
	},

	/**
	 * extra for modal close button
	 */

	modalClose: {
		height: 50,
		width: 50,
		position: 'absolute',
		top: 14,
		right: -14,
		zIndex: 9999,
	},
	closeIcon: { fontSize: 'inherit', color: '#0c0c0c' },
}

export default styles
