import gql from 'graphql-tag'

export const CLOSED_COUNTS = gql`
	query closedCounts($projectUrl: String!, $status: String!, $spaceId: String!) {
		closedCounts(projectUrl: $projectUrl, status: $status, spaceId: $spaceId)
	}
`

// edit description
export const EDIT_DESCRIPTION = gql`
	mutation EditDescription($newDesciption: String!, $feedId: String!, $imageId: String!) {
		EditDescription(newDesciption: $newDesciption, feedId: $feedId, imageId: $imageId)
	}
`

// return photo comment data
export const GET_COMMENTS = gql`
	query photoComments(
		$hash: String!
		$type: String!
		$feedId: String
		$imageId: String
		$productId: String
		$variantId: String
		$spaceId: String
		$role: String!
		$status: String!
	) {
		photoComments(
			hash: $hash
			type: $type
			feedId: $feedId
			imageId: $imageId
			productId: $productId
			variantId: $variantId
			spaceId: $spaceId
			role: $role
			status: $status
		) {
			id
			text
			status
			type
			commentType
			feedId
			imageId
			productId
			variantId
			spaceId
			commentedAt
			commentedBy
			isDeleted
			commentedProfile {
				name
				photo
			}
			childComments {
				type
				id
				text
				isDeleted
				feedId
				imageId
				commentedAt
				commentedBy
				commentedProfile {
					name
					photo
				}
			}
		}
	}
`
// subscription data
export const COMMENTS_SUBSCRIPTION = gql`
	subscription commentAdded($feedId: String!, $imageId: String!) {
		commentAdded(feedId: $feedId, imageId: $imageId) {
			id
			text
			status
			type
			commentType
			feedId
			imageId
			productId
			variantId
			spaceId
			commentedAt
			commentedBy
			isDeleted
			commentedProfile {
				name
				photo
			}
			childComments {
				id
				text
				isDeleted
				feedId
				imageId
				commentedAt
				commentedBy
				commentedProfile {
					name
					photo
				}
			}
		}
	}
`
// get closed ticket count
export const CLOSED_COUNT = gql`
	query closedCount($feedId: String!, $status: String!, $imageId: String!) {
		closedCount(feedId: $feedId, status: $status, imageId: $imageId)
	}
`
// add parent comment
export const ADDCOMMENT = gql`
	mutation AddComment(
		$projectUrl: String!
		$hash: String!
		$text: String!
		$level: String!
		$type: String
		$commentType: String
		$productId: String
		$variantId: String
		$spaceId: String
		$feedId: String
		$imageId: String
		$parentId: String
		$mentionedTo: [String]
	) {
		AddComment(
			projectUrl: $projectUrl
			hash: $hash
			text: $text
			level: $level
			type: $type
			commentType: $commentType
			productId: $productId
			variantId: $variantId
			spaceId: $spaceId
			feedId: $feedId
			imageId: $imageId
			parentId: $parentId
			mentionedTo: $mentionedTo
		) {
			id
			text
			status
			type
			commentType
			feedId
			imageId
			productId
			variantId
			spaceId
			commentedAt
			commentedBy
			isDeleted
			commentedProfile {
				name
				photo
			}
			childComments {
				id
				text
				isDeleted
				feedId
				imageId
				commentedAt
				commentedBy
				commentedProfile {
					name
					photo
				}
			}
		}
	}
`

// add parent comment
export const COMMENTS = gql`
	query TicketComments($messageId: String!) {
		TicketComments(messageId: $messageId) {
			id
			text
			status
			type
			commentType
			feedId
			imageId
			productId
			variantId
			spaceId
			commentedAt
			commentedBy
			isDeleted
			commentedProfile {
				name
				photo
			}
			childComments {
				type
				id
				text
				isDeleted
				feedId
				imageId
				commentedAt
				commentedBy
				commentedProfile {
					name
					photo
				}
			}
		}
	}
`

export const TEAM_DISCUSSION = gql`
	query TeamDiscussion(
		$projectUrl: String!
		$status: String!
		$limit: Int!
		$offset: Int!
		$spaceId: String!
	) {
		TeamDiscussion(
			projectUrl: $projectUrl
			status: $status
			limit: $limit
			offset: $offset
			spaceId: $spaceId
		) {
      loggedInUser
			discussionCount
			discussions {
				key
				uri
				title
				type
				feedId
				imageId
				productId
				variantId
				productName
				variantName
				spaceId
				projectName
				uploadedBy
				uploadedProfile {
					name
					photo
				}
				clientDisplayStatus
				uploadedAt
				space
				thumbnailUri
				qualityUri
				commentText
				commentId
				childCommentCount
				commentedAt
				commentedProfile {
					name
					photo
				}
				childCommentsLatest {
					latestComment
					commentedAt
					profile {
						name
						photo
					}
				}
			}
		}
	}
`

export const CLIENT_DISCUSSION = gql`
	query ClientDiscussion(
		$projectUrl: String!
		$status: String!
		$limit: Int!
		$offset: Int!
		$spaceId: String!
	) {
		ClientDiscussion(
			projectUrl: $projectUrl
			status: $status
			limit: $limit
			offset: $offset
			spaceId: $spaceId
		) {
      loggedInUser
			discussionCount
			discussions {
				key
				uri
				title
				type
				feedId
				imageId
				productId
				variantId
				productName
				variantName
				spaceId
				projectName
				uploadedBy
				uploadedProfile {
					name
					photo
				}
				clientDisplayStatus
				uploadedAt
				space
				thumbnailUri
				qualityUri
				commentText
				commentId
				childCommentCount
				commentedAt
				commentedProfile {
					name
					photo
				}
				childCommentsLatest {
					latestComment
					commentedAt
					profile {
						name
						photo
					}
				}
			}
		}
	}
`

export const DELETE_COMMENT = gql`
	mutation deleteComment(
  $hash: String!,
	$commentId: String!,
	$level: String,
	$id: String
) {
		deleteComment(
      hash: $hash
			commentId: $commentId
			level: $level
			id: $id)
	}
`