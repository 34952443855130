import styles from 'styled-components'

export const Modal = styles.div(
	{
		display: 'grid',
		gridTemplateRows: '4fr 6fr',
		width: '350px',
		height: '150px',
		background: '#FFFFFF',
		boxShadow: '0 17px 50px 0 rgba(0, 0, 0, 0.19), 0 12px 15px 0 rgba(0, 0, 0, 0.24)',
		borderRadius: '4px',
		alignSelf: 'center',
		justifySelf: 'center',
		fontSize: '0.9em',
	},
	({ type }) => {
		switch (type) {
			case 'imagePreview':
				return {
					width: '80%',
					height: '80vh',
					display: 'block',
				}

			case 'createTicket':
				return {
					width: '40%',
					height: '80vh',
					display: 'block',
				}

			case 'forwardMessage':
				return {
					width: '33%',
					height: '80vh',
					display: 'block',
				}

			case 'messageInfo':
				return {
					width: '33%',
					height: '80vh',
					display: 'block',
				}

			case 'addParticipant':
				return {
					width: '30%',
					height: '80vh',
					display: 'block',
				}

			case 'addGroupParticipant':
				return {
					width: '30%',
					height: '80vh',
					display: 'block',
				}

			case 'editGroupParticipant':
				return {
					width: '30%',
					height: '75vh',
					display: 'block',
				}

			case 'addNewGroup':
				return {
					width: '30%',
					height: '80vh',
					display: 'block',
				}

			case 'editGroupInfo':
				return {
					width: '30%',
					height: '80vh',
					display: 'block',
				}

			case 'archivedMember':
				return {
					width: '30%',
					height: '80vh',
					display: 'block',
				}
		}
	}
)
export const ModalButton = styles.div(
	{
		width: '100px',
		height: '36px',
		cursor: 'pointer',
		color: '#08c65b',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		':hover': {
			boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2)',
		},
	},
	({ type }) => {
		switch (type) {
			case 'delete':
				return {
					background: '#08c65b',
					color: '#ffffff',
					':hover': {
						background: '#0cb757',
						boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2)',
					},
				}
		}
	}
)
export const ModalButtonContainer = styles.div(
	{
		display: 'grid',
		gridTemplateColumns: '5fr 5fr',
		width: '70%',
		justifySelf: 'end',
		alignSelf: 'center',
	},
	({ type }) => {
		switch (type) {
			case 'imagePreview':
				return {
					display: 'block',
					textAlign: 'center',
					width: 'inherit',
					margin: 'auto',
				}

			case 'createTicket':
				return {
					display: 'block',
					gridTemplateColumns: 'none',
					width: '100%',
					height: '67vh',
					overflow: 'scroll',
				}

			case 'forwardMessage':
				return {
					display: 'block',
					gridTemplateColumns: 'none',
					width: '100%',
					height: '67vh',
				}

			case 'messageInfo':
				return {
					display: 'block',
					gridTemplateColumns: 'none',
					width: '100%',
					height: '67vh',
				}

			case 'addParticipant':
				return {
					display: 'block',
					height: '67vh',
					width: '100%',
					margin: 'auto',
				}

			case 'editGroupParticipant':
				return {
					display: 'block',
					height: '67vh',
					width: '100%',
					margin: 'auto',
				}

			case 'addGroupParticipant':
				return {
					display: 'block',
					height: '67vh',
					width: '100%',
					margin: 'auto',
				}

			case 'addNewGroup':
				return {
					display: 'block',
					height: '67vh',
					width: '100%',
					margin: 'auto',
					overflow: 'scroll',
				}

			case 'editGroupInfo':
				return {
					display: 'block',
					height: '72vh',
					width: '100%',
					margin: 'auto',
					overflow: 'scroll',
				}

			case 'archivedMember':
				return {
					display: 'block',
					height: '67vh',
					width: '100%',
					margin: 'auto',
					overflow: 'scroll',
				}
		}
	}
)
export const ModalHeader = styles.div(
	{
		color: '#4b4b4b',
	},
	({ type }) => {
		switch (type) {
			case 'imagePreview':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'createTicket':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'forwardMessage':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'messageInfo':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'addParticipant':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'editGroupParticipant':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'addGroupParticipant':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'addNewGroup':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'editGroupInfo':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			case 'archivedMember':
				return {
					padding: '7px',
					backgroundColor: '#00bfa5',
					height: '50px',
				}

			default:
				return {
					width: '90%',
					alignSelf: 'center',
					justifySelf: 'center',
				}
		}
	}
)
export const ModalWrapper = styles.div(
	{
		top: '0',
		left: '0',
		display: 'grid',
		gridTemplateRows: '1fr',
		position: 'absolute',
		zIndex: '10',
		height: '100vh',
		width: '100vw',
	},
	({ type }) => {
		switch (type) {
			case 'imagePreview':
				return {
					zIndex: 1001,
					background: 'rgba(0, 0, 0, 0.8)',
				}

			default:
				return { zIndex: 1001, background: 'rgba(0, 0, 0, 0.8)' }
		}
	}
)
