// Import Components
import React from 'react'

import { useHistory } from 'react-router'
// Icons
import { useMutation } from '@apollo/react-hooks'
import {
	Drawer,
	PageHeader,
	Typography,
	Card,
	Avatar,
	Divider,
	Spin,
	Icon,
	List,
	Button,
	Modal,
	Collapse,
} from 'antd'
import { format } from 'date-fns'
import { Query } from 'react-apollo'
import SomeErrorHasOccured from '../../UtilComponents'
import { UserProfileDetails } from './query'
import { LOGOUT_MUTATION } from './mutation'

// Import Custom CSS
import './index.css'
import styles from './styles'

const { Text } = Typography
const { Meta } = Card
const { confirm } = Modal
const { Panel } = Collapse

// Define a ProfileDrawer Component
class ProfileDrawer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { profileDrawer, visible } = this.props

		// Profile Drawer Content

		return (
			<div>
				{/** Load Menu details */}

				{
					<Query query={UserProfileDetails}>
						{({ data, loading, error }) => {
							if (loading) {
								return (
									<Spin
										indicator={<Icon type="loading" style={styles.spinIcon} spin />}
										style={styles.spinIconStyle}
									/>
								)
							}
							if (error || !data || !data.userProfileDetails) {
								return <SomeErrorHasOccured />
							}

							const { userProfileDetails } = data

							const {
								pictureUrl,
								profileUrl,
								profileName,
								personalDetails,
								companyDetails,
								bankDetails,
							} = userProfileDetails

							if (
								personalDetails[0].value &&
								personalDetails[0].value.match(/^[0-9]+$/)
							) {
								personalDetails[0].value = format(
									new Date(personalDetails[0].value * 1000),
									'MMMM dd, yyyy'
								)
							}

							if (companyDetails[0].value && companyDetails[0].value.match(/^[0-9]+$/)) {
								companyDetails[0].value = format(
									new Date(companyDetails[0].value * 1000),
									'MMMM dd, yyyy'
								)
							}

							return (
								<div>
									<Drawer
										title={<ProfileDetailsHeader profileUrl={profileUrl} />}
										width="380"
										placement="right"
										closable
										onClose={profileDrawer}
										visible={visible}
										style={styles.drawerStyle}
										mask
										maskClosable
										maskStyle={{ backgroundColor: '#ffffff00' }}
										keyboard
										className="profileDrawer">
										{/** Profile Drawer Content */}
										{/** Load Profile Image and Profile Name */}
										<Card bordered={false}>
											{pictureUrl && <Avatar size={64} src={pictureUrl} />}
											{!pictureUrl && (
												<Avatar
													size={64}
													style={{ color: '#fff', backgroundColor: '#6b86a2' }}>
													{profileName.match(/\b(\w)/g).join('')}
												</Avatar>
											)}
											<Meta title={profileName} />
										</Card>
										<Divider />

										<List
											header={<div>Personal</div>}
											dataSource={personalDetails}
											renderItem={(item) => <ProfileDetailsList data={item} />}
										/>
										<Divider />
										<List
											header={<div>Company</div>}
											dataSource={companyDetails}
											renderItem={(item) => <ProfileDetailsList data={item} />}
										/>
										<Divider />
										<Collapse>
											<Panel header="Bank Details" key="1">
												<List
													header={<div>Bank Details</div>}
													dataSource={bankDetails}
													renderItem={(item) => <ProfileDetailsList data={item} />}
												/>
											</Panel>
										</Collapse>
										<div style={styles.bottomFixedButtonDiv}>
											<LogOutButton />
										</div>
									</Drawer>
								</div>
							)
						}}
					</Query>
				}
			</div>
		)

		// Load Notification Drawer Header
		function ProfileDetailsHeader(props) {
			return (
				<PageHeader
					title={
						<>
							<Text style={styles.profileDrawerHeaderText}>Profile Details</Text>

							<div style={styles.profileDrawerHeader}>
								<span>@{props.profileUrl} </span>
							</div>
						</>
					}
				/>
			)
		}

		function ProfileDetailsList(props) {
			const { data } = props
			return (
				<List.Item style={{ display: 'list-item' }}>
					<Typography.Text strong>{data.title}</Typography.Text>
					<br />
					<Typography.Text>{data.value}</Typography.Text>
				</List.Item>
			)
		}
		function LogOutButton() {
			const history = useHistory()
			const [logout] = useMutation(LOGOUT_MUTATION)

			async function logoutSuccess() {
				confirm({
					title: 'Are you sure that you want to logout ?',
					okText: 'Logout',
					okType: 'danger',
					cancelText: 'Cancel',
					centered: true,
					autoFocusButton: 'cancel',
					async onOk() {
						try {
							await logout()
							// eslint-disable-next-line no-empty
						} catch (error) {}
						history.push('/login')
					},
					onCancel() {
						return null
					},
				})
			}
			return (
				<>
					<Button type="danger" onClick={logoutSuccess} style={styles.bottomFixedButton}>
						Logout
					</Button>
				</>
			)
		}
	}
}

export default ProfileDrawer
