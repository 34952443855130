/* eslint-disable no-tabs */
// Import react component
import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { Icon, Avatar, Tag, Checkbox } from 'antd'
import Dropdown from './Dropdown'
import {
	changeDeliverableStageData,
	deleteDeliverableInDeliverableList,
} from './../utils'
import { MdKeyboardArrowRight, MdKeyboardArrowUp } from 'react-icons/md'
import stc from 'string-to-color'

const stageColor = {
	Architecture: '#af4a7eb0',
	Interiors: '#25495ab0',
	Landscape: '#6f7d5f',
	Lighting: '#af714c',
	Acoustics: '#69ad92',
	'Facade Design': '#815daf',
}

// Load list of deliverables in deliverableList Component
class DeliverableList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedIndex: 0,
			expandId: [],
			checkBoxIndex: [],
		}
	}

	componentDidMount() {
		const { objectData } = this.props
		const expandId = objectData.map((data) => {
			return data.scopeId
		})

		this.setState({ expandId })
	}

	addCheckBoxIndex = (index) => {
		const { checkBoxIndex } = this.state
		if (checkBoxIndex.includes(index)) {
			const newIndex = checkBoxIndex.indexOf(index)
			if (newIndex > -1) {
				checkBoxIndex.splice(newIndex, 1)
			}
		} else {
			checkBoxIndex.push(index)
		}
		this.setState(checkBoxIndex)
	}

	render() {
		// Deconstruct props
		const { data, objectData, changeDeliverableStage, deleteDeliverableList } = this.props
		const { expandId, checkBoxIndex } = this.state
		return [
			data.length ? (
				data.map((val, index) => {
					// Stringify object
					const result = JSON.stringify(val)

					// Deconstruct val object
					const {
						scopeName,
						scopeId,
						scopeType,
						title,
						type,
						scopeHead,
						scopeDescription,
						stageId,
						scopeLength,
					} = val

					const labelsAndContext = {}
					if (stageId && objectData.length > 1) {
						objectData.map((stage, objectIndex) => {
							const { scopeName, scopeId } = stage
							const { selectedIndex, checkBoxIndex } = this.state
							if (stageId !== scopeId) {
								labelsAndContext['Move to - ' + scopeName] = (_) => {
									const stageData = {
										arrayStage: data,
										objectStage: objectData,
										newStageId: scopeId,
										arrayIndex: selectedIndex,
										objectIndex,
										checkBoxIndex,
									}

									const newStageDataAfterChange = changeDeliverableStageData(stageData)
									changeDeliverableStage(newStageDataAfterChange, scopeName)
									this.setState({ checkBoxIndex: [] })
								}
							}
						})
					}
					labelsAndContext['Delete deliverable'] = (_) => {
						const { selectedIndex, checkBoxIndex } = this.state
						const selectedDeliverable = {
							arrayStage: data,
							objectStage: objectData,
							arrayIndex: selectedIndex,
							checkBoxIndex,
						}

						const newStageAfterDelete = deleteDeliverableInDeliverableList(
							selectedDeliverable
						)
						deleteDeliverableList(newStageAfterDelete)
						this.setState({ checkBoxIndex: [] })
					}

					// Scope head label color
					const scopeColor = stageColor[scopeHead]

					// deliverable type icon color
					let deliverableIcon
					if (scopeType === 'deliverable') {
						deliverableIcon = 'stageDeliverableIcon'
					} else if (scopeType === 'milestone') {
						deliverableIcon = 'stageMilestoneIcon'
					} else if (scopeType === 'sitevisit') {
						deliverableIcon = 'stageSiteVisitIcon'
					}

					// space label color
					let tagColor
					if (type === 'room') {
						tagColor = '#87d068'
					} else if (type === 'building') {
						tagColor = '#108ee9'
					} else if (type === 'floor') {
						tagColor = '#f56a00'
					}

					// create unique scope key
					const scopeKey = `${(scopeId, index)}`

					const colorCode = stageId ? stc(stageId + '1') : stc(scopeId + '1')

					return (
						<li
							key={scopeKey}
							data-id={result}
							style={{
								display: expandId.includes(stageId) ? 'none' : 'block',
								border: `2px solid ${colorCode}`,
								backgroundColor: scopeType === 'stage' ? colorCode : '#fff',
							}}
							className={scopeType === 'stage' ? 'stageSelected' : 'null'}>
							{scopeType !== 'stage' ? (
								<div
									style={{ display: 'flex' }}
									onMouseEnter={() => {
										this.setState({
											selectedIndex: index,
										})
									}}
									onMouseLeave={() => {
										this.setState({
											selectedIndex: 0,
										})
									}}>
									<Avatar
										className={deliverableIcon}
										style={{ backgroundColor: colorCode }}
										size="medium">
										{scopeType[0].toUpperCase()}
									</Avatar>
									<span className="stageNameMarginLeft20 stageDeliverableWidth">
										{scopeName}
									</span>

									<div
										style={{
											display: 'flex',
											width: '32%',
											height: 20,
											marginTop: 4,
										}}>
										<Tag color={tagColor} style={{ marginLeft: '10px' }}>
											{title}
										</Tag>

										<Tag color={scopeColor} style={{ marginLeft: '10px' }}>
											{scopeHead}
										</Tag>
									</div>

									<Checkbox
										style={{ marginLeft: '3%', marginTop: 5 }}
										checked={checkBoxIndex.includes(index)}
										onChange={() => {
											this.addCheckBoxIndex(index)
										}}></Checkbox>

									<Dropdown labelsAndContext={labelsAndContext} />
								</div>
							) : (
								<div
									onClick={() => {
										const { expandId } = this.state
										if (expandId.includes(scopeId)) {
											var index = expandId.indexOf(scopeId)
											if (index !== -1) expandId.splice(index, 1)
											this.setState({ expandId })
										} else {
											this.setState({ expandId: [...expandId, scopeId] })
										}
									}}
									style={{ display: 'flex', cursor: 'pointer' }}>
									{data[0].stageId !== undefined ? (
										<>
											{expandId.includes(scopeId) ? (
												<MdKeyboardArrowRight size={30} color="#fff" />
											) : (
												<MdKeyboardArrowUp size={30} color="#fff" />
											)}
										</>
									) : null}

									<span className="stageNameMarginLeft20" style={{ marginTop: 5 }}>
										{scopeName} - {scopeDescription}
										{scopeType === 'stage'
											? ` - ${scopeLength} deliverable${scopeLength > 1 ? 's' : ''}`
											: ''}
									</span>
								</div>
							)}
						</li>
					)
				})
			) : (
				<li key={uniqueId()} style={{ backgroundColor: 'white' }}>
					<div>
						<Avatar size="medium" className="emptyDeliverableList">
							<Icon type="exclamation-circle" className="emptyDeliverableListIcon" />
						</Avatar>
						<span className="stageNameMarginLeft20">No Step Found</span>
					</div>
				</li>
			),
		]
	}
}

// export deliverablelist component
export default DeliverableList
