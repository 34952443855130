import { useMutation } from '@apollo/react-hooks'
// Importing required components from ANTD
import { Button, Col, Input, Row, Modal } from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
// Query to fetch CRM Details on load
import { ADD_NEW_REPLY_MUTATION, RESOLVE_DRIVE_COMMENT_MUTATION } from './mutation'
import utils from '../../../utils'

const { TextArea } = Input
const { stringEncode } = utils
const { confirm } = Modal
export default function AddReplies(props) {
	const { fileId, refetchComments, commentId, resolved, type } = props
	const [addDriveReplies, { loading, error }] = useMutation(ADD_NEW_REPLY_MUTATION)
	const [resolveDriveComment] = useMutation(RESOLVE_DRIVE_COMMENT_MUTATION)
	const [defaultNotes, setNotesValue] = useState(null)
	const [buttonActiveState, setButtonActiveState] = useState(true)

	function onNotesChange(event) {
		// document.removeEventListener('keydown', props.arrowClickEvent, false);
		if (!event.target.value) {
			setButtonActiveState(true)
			setNotesValue(null)
		} else {
			setNotesValue(event.target.value)
			setButtonActiveState(false)
		}
	}

	async function onClickAddNotesButton() {
		if (!defaultNotes || !defaultNotes.trim()) {
			setNotesValue(null)
		} else {
			await addDriveReplies({
				variables: {
					fileId,
					commentId,
					comments: stringEncode(defaultNotes.trim()),
				},
			})
			setNotesValue(null)
			setButtonActiveState(true)
			refetchComments()

			// window.scrollTo(0, props.myRef.current.offsetTop);
		}
	}

	async function onClickResolveCommentButton() {
		confirm({
			title: 'Are you sure that you want to resolve this comment ?',
			okText: 'Confirm',
			centered: true,
			zIndex: 9999,
			onOk: async () => {
				try {
					await resolveDriveComment({
						variables: {
							fileId,
							commentId,
							action: 'resolve',
						},
					})
					refetchComments()
				} catch (error) {
					return
				}
			},
		})
	}

	async function onClickReOpenCommentButton() {
		confirm({
			title: 'Are you sure that you want to reopen this comment ?',
			okText: 'Confirm',
			centered: true,
			zIndex: 9999,
			onOk: async () => {
				try {
					await resolveDriveComment({
						variables: {
							fileId,
							commentId,
							action: 'reopen',
						},
					})
					refetchComments()
				} catch (error) {
					return
				}
			},
		})
	}

	return !resolved && type !== 'resolveButton' ? (
		<>
			<Row gutter={[16, 8]}>
				<Col span={24}>
					<TextArea
						rows={3}
						autoSize={{ minRows: 2, maxRows: 2 }}
						value={defaultNotes}
						placeholder="Reply to this comment . . ."
						onChange={(event) => onNotesChange(event)}
						onPressEnter={() => onClickAddNotesButton()}
						// onFocusIn = { () => window.removeEventListener('keydown', arrowClickEvent, false) }
					/>
					<Button
						type={buttonActiveState ? 'secondary' : 'primary'}
						size="default"
						disabled={buttonActiveState}
						loading={loading ? true : false}
						onClick={() => onClickAddNotesButton()}
						block
						style={{
							marginTop: 12,
							backgroundColor: buttonActiveState ? '#656565' : '',
							color: buttonActiveState ? '#fff' : '',
							width: '25%',
							float: 'right',
						}}>
						Reply
					</Button>
				</Col>
			</Row>
		</>
	) : !resolved && type === 'resolveButton' ? (
		<Button
			type="secondary"
			size="default"
			loading={loading ? true : false}
			onClick={() => onClickResolveCommentButton()}
			block
			style={{
				zIndex: 15,
			}}>
			Resolve
		</Button>
	) : resolved ? (
		<Button
			type="secondary"
			size="default"
			loading={loading ? true : false}
			onClick={() => onClickReOpenCommentButton()}
			block
			style={{
				zIndex: 15,
			}}>
			Reopen
		</Button>
	) : null
}
