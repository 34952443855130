// Importing required components from ANTD
import { Button, Modal, notification, Tooltip } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { MOVE_FILE_TO_NOTED } from './mutation'

const { confirm } = Modal

export default function NoteFiles(props) {
	const [moveToNoted] = useMutation(MOVE_FILE_TO_NOTED)
	const {
		fileId,
		notedId,
		forRevisionId,
		thumbnailLink,
		refetchDrawings,
		modalSwitch,
		modalView,
	} = props

	async function moveFileToNoted() {
		confirm({
			title: 'Are you sure you want move this file to "Noted" ?',
			okText: 'Confirm',
			centered: true,
			zIndex: 9999,
			content: (
				<>
					<img src={thumbnailLink} />
				</>
			),
			onOk: async () => {
				try {
					await moveToNoted({
						variables: {
							fileId,
							notedFolderId: notedId,
							forRevisionFolderId: forRevisionId,
							comments: 'Noted Comments Test',
						},
					})
					// props.refetch()
					notification.success({
						message: 'File has been moved to "Noted"',
						description: 'Successfully',
						style: { marginTop: 50 },
					})
					refetchDrawings()
					if (modalView) {
						modalSwitch()
					}
				} catch (error) {
					return null
				}
			},
			onCancel() {
				// console.log('cancel')
			},
		})
	}
	return (
		<>
			<Tooltip title="Mark as 'Noted'" placement="bottom">
				<FiEdit
					style={{
						fontSize: modalView ? 24 : 18,
						marginLeft: 10,
						color: modalView ? '#a27cff' : '#a900ff',
					}}
					onClick={() => moveFileToNoted()}
				/>
			</Tooltip>
		</>
	)
}
