/**
 * Developer : Sandhiya Malar
 */
/**
    Site Photos Component
    This component will render images using project url 
    User can create/close a discussion in image.
    User can edit a description for images
* */

/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import {
	Spin,
	Icon,
	Divider,
	Modal,
	Button,
	Input,
	Select,
	Typography,
	Tooltip,
	notification,
	Popconfirm,
} from 'antd'
// Import required components from ReactIcons
import {
	IoMdSend,
	IoIosArrowDroprightCircle,
	IoIosArrowDropleftCircle,
	IoIosArrowDropupCircle,
	IoMdTrash,
} from 'react-icons/io'
// Query component of React Apollo to fetch data
import { Query, Mutation, withApollo, ApolloProvider } from 'react-apollo'

import newClient from '../../service/mobileAppBackend'

// Import Override CSS
import './index.css'
// Query to fetch Site Photos Details
import {
	SitePhotosQuery,
	GET_COMMENTS,
	ADDCOMMENT,
	spaces,
	editDescription as EDIT_DESCRIPTION,
	// EDIT_DESCRIPTION,
	COMMENTS_SUBSCRIPTION,
	PHOTO_SUBSCRIPTION,
	closedCount,
	getUserHash,
	Upload,
	CHANGE_COMMENT,
	DELETE_COMMENT,
} from './query'
// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'
// Import team chat image
import teamChat from '../../assets/dq_active.png'
// Import thumbsup image
import thumbsUp from '../../assets/thumbs_up.png'
// Import client chat image
import clientChat from '../../assets/teams_active.png'
// Import DQ logo image
import dqLogo from '../../assets/dq_icon_128.png'
// Import DQ logo image
import team from '../../assets/team.png'
// import utils file
import util from '../../utils'

import SubscriptionComment from './subscriptionComment'
import PhotoSubscription from './photoSubscription'
import UnresolvedCommentSection from './resolvedUnresolvedButton'

// deconstruct option from select dropdown
const { Option } = Select
// deconstruct Text from Typography
const { Text, Paragraph } = Typography
const { TextArea } = Input

// const newClient = new ApolloClient({
// 	uri: 'http://13.235.195.186:8002/graphql',
// })

/**
 * This class component is used to display images in webapp.
 * @returns {site photos component}
 */
class SitePhotos extends React.Component {
	// initial states
	state = {
		selection: false,
		spaceSelection: 'All', // Space filter data for dynamically change the filter
		loadMore: 'no_loading', // lazy load loding state
		commentInput: '', // Comment input box value state
		closedShow: false, // closed discussion state
		modalDescription: '', // modal description value
		indexSet: 0, // index value state
		ismodalopen: false, // modal visibility
		discussionIcon: 'team', // discussion icon based on click client/team
		loadCount: 1, // lazy load count
		backTopButton: false, // backtop button visibility
		resolvedStateLength: 1,
		unresolvedStateLength: 1,
		userHashState: '',
		imageUploadModal: false,
		spaceState: null,
		formData: null,
		uploadIndexSet: 0,
		uploadSpace: {},
		descriptionArray: [],
		editButton: false,
		isLoaded: false,
		uploadInvalid: false,
		userRoleState: '',
		projectNameState: '',
		getDetails: {},
		loadings: false,
	}

	async componentDidMount() {
		const { client } = this.props
		const { data } = await client.query({
			query: getUserHash,
		})
		const tempObject = {
			hash: data.userHash.hash,
			name: data.userHash.name,
			photo: data.userHash.photo || '',
		}
		this.setState({
			userHashState: data.userHash.hash,
			userRoleState: data.userHash.role,
			getDetails: tempObject,
		})
	}

	componentDidUpdate(prevProps) {
		const { projectUrl, projectName } = this.props
		const { spaceSelection } = this.state
		if (prevProps.projectUrl !== projectUrl) {
			if (spaceSelection !== 'All') {
				this.setState({ spaceSelection: 'All', isLoaded: false })
			}
			this.setState({ isLoaded: false, projectNameState: projectName, spaceState: null })
		}
	}

	// image upload open image select option
	imageUpload = () => {
		document.getElementById('upload').click()
	}

	loadings = () => {
		const { loadings } = this.state
		if (loadings === true) {
			this.setState({
				loadings: false,
			})
		} else {
			this.setState({
				loadings: true,
			})
		}
	}

	// selected image related data
	getImage = (files) => {
		if (files.length > 0 && files.length < 11) {
			this.setState({
				imageUploadModal: true,
				formData: files,
			})
		}
	}

	// image upload description array form
	uploadCommentChange = (e, index) => {
		const { descriptionArray } = this.state
		const category = [...descriptionArray]
		category[index] = { key: index, description: e.target.value }
		this.setState({ descriptionArray: category })
	}

	uploadSpace = (value) => {
		const { spaceState } = this.state

		const names = spaceState.spaces.find((o) => o.id === value)
		this.setState({ uploadSpace: { name: names.name, id: value } })
	}

	// image upload modal close
	imageUploadModalClose = () => {
		this.setState({
			imageUploadModal: false,
			formData: null,
			uploadIndexSet: 0,
			uploadSpace: '',
			descriptionArray: [],
			uploadInvalid: false,
		})
	}

	// image upload modal success notification
	imageUploadedNotification = () => {
		notification.open({
			top: 150,
			message: 'Image Upload Successfully',
		})
	}

	// image upload modal error notification
	imageUploadedNotificationError = () => {
		notification.open({
			top: 150,
			message: 'Image Upload faliure',
		})
	}

	uploadChangeIndex = (index) => {
		this.setState({
			uploadIndexSet: index,
		})
	}

	/**
	 *  check wheather the page reach the bottom of page
	 *  And fetchmore the data
	 */
	handleScroll = (e, fetchMore, data) => {
		const element = e.target
		// Deconstructing the state
		const { loadCount, backTopButton, ismodalopen, isLoaded } = this.state

		// backtop button visible only some scroll occurs
		if (element.scrollTop > 100 && !backTopButton) {
			this.setState({ backTopButton: true })
		}

		if (
			element.scrollHeight - element.scrollTop === element.clientHeight &&
			!ismodalopen
		) {
			if (isLoaded) {
				return
			}
			const datas = loadCount + 1
			this.setState({ loadMore: 'loading', loadCount: datas })
			fetchMore({
				variables: {
					offset: data.projectPhotos.length,
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev
					if (fetchMoreResult.projectPhotos.length < 9) {
						// console.log(fetchMoreResult.projectPhotos.length)
						this.setState({ isLoaded: true })
					}
					this.setState({ loadMore: 'no_loading' })

					return {
						...prev,
						projectPhotos: [...prev.projectPhotos, ...fetchMoreResult.projectPhotos],
					}
				},
			})
		}
	}

	/**
	 *  Sets the visiblity of backtop button to false when clicked
	 */
	scrollToTop = () => {
		document.querySelector('#scroll').scrollTo({ top: 0, behavior: 'smooth' })
		const height = document.querySelector('#scroll').scrollTop - 100
		// set timeout for button invisible
		setTimeout(() => {
			this.setState({ backTopButton: false })
		}, height)
	}

	/**
	 *  Sets the space selection value when change a filter
	 */
	handleChange = (value) => {
		this.setState({ spaceSelection: value, isLoaded: false })
	}

	/**
	 * get current selected image index among all images
	 * and set it in a state
	 */
	setSelected = (index, element, flatternArray) => {
		const imageIndex = flatternArray.findIndex(
			(i) => i.uri === element.uri && i.key === element.key
		)
		this.setState({
			indexSet: imageIndex,
			modalDescription: element.description,
			ismodalopen: true,
			selection: true,
		})
	}

	/**
	 *  Sets the visiblity of modal to false when called
	 */
	handleCancel = () => {
		this.setState({
			ismodalopen: false,
			selection: false,
			closedShow: false,
			discussionIcon: 'team',
			resolvedStateLength: 1,
			unresolvedStateLength: 1,
			editButton: false,
		})
	}

	/**
	 *  Sets the index for modal navigation when called
	 */
	changeIndex = (index) => {
		this.setState({
			indexSet: index,
			commentInput: '',
			closedShow: false,
			discussionIcon: 'team',
			resolvedStateLength: 1,
			unresolvedStateLength: 1,
			editButton: false,
		})
	}

	/**
	 *  Sets to hide a closed discussion data when click hide button
	 */
	hideClosedDiscussion = () => {
		this.setState({ closedShow: false })
	}

	/**
	 *  Sets to show a closed discussion data when click show button
	 */
	showClosedDiscussion = () => {
		this.setState({ closedShow: true })
	}

	/**
	 *  Sets the discussion icon value when click a image
	 */
	discussionIcon = () => {
		this.setState((prevState) => ({
			discussionIcon: prevState.discussionIcon === 'team' ? 'client' : 'team',
		}))
	}

	/**
	 *  Sets the comment input box value when change a input values
	 */
	commentChange = (e) => {
		this.setState({ commentInput: e.target.value })
	}

	/**
	 *  Sets a parent comment input box to clear a value  when click a send button
	 */
	inputBoxClear = () => {
		this.setState({ commentInput: '' })
	}

	changeEditButton = () => {
		const { editButton } = this.state

		if (editButton === false) {
			this.setState({ editButton: true })
		}
	}

	/**
	 * This function is used to change description content.
	 * @param {* It contain string} str
	 * @param {* It contain mutation} editDescription
	 * @param {* It contain feedId} feedIdData
	 * @param {* It contain imageId} imageIdData
	 * @param {* It contain refetch} refetch
	 * @returns {refetch data}
	 */

	changeDescription = async (str, editDescription, feedIdData, imageIdData, refetch) => {
		const {
			modalDescription,
			loadCount,
			spaceSelection,
			userHashState,
			editButton,
		} = this.state
		const { projectUrl } = this.props
		if (modalDescription !== str) {
			this.setState({ modalDescription: str })
			const variables = {
				newDesciption: str,
				feedId: feedIdData,
				imageId: imageIdData,
			}

			editDescription({
				variables,
			})
			await refetch({
				projectUrl,
				hash: userHashState,
				limit: loadCount * 9,
				offset: 0,
				spaceId: spaceSelection,
				role: 'user',
			})
		}
		this.setState({ editButton: false })
	}

	/**
	 * This function is used to display discussion
	 * @param {* It contain Array of object} photoComments
	 * @param {* It contain Unsolved or solved} status
	 * @param {* It contain userhash} userHashState
	 * @returns {comment details}
	 */

	commentDisplay = (photoComments, status, userHashState) => (
		<div>
			{photoComments.map((data, i) => {
				const user = data.commentedBy === userHashState
				const names =
					data && data.commentedProfile && data.commentedProfile.name
						? data.commentedProfile.name
						: ''
				const name = names.split(' ')

				const box = user
					? data.commentType === 'discussion'
						? styles.lightBlue
						: styles.lightYellow
					: data.commentType === 'discussion'
					? styles.white
					: styles.darkYellow

				const convert =
					data.commentType === 'discussion'
						? 'convert to client ticket'
						: 'convert to team ticket'

				const deleted = 'Delete this comment'
				return (
					<div id="unresolved" key={i}>
						<Divider style={styles.openDiscussion}>
							{status === 'unresolved'
								? `Open ${data.commentType === 'discussion' ? 'Team' : 'Client'} Tickets`
								: `Closed ${
										data.commentType === 'discussion' ? 'Team' : 'Client'
								  } Tickets`}
						</Divider>

						<div className="comment_part">
							<div
								style={{
									...styles.parentComment,
									...(user ? styles.right : styles.left),
								}}>
								<div
									style={{
										...styles.parent,
										...(user ? styles.commentRight : styles.commentLeft),
										...box,
									}}>
									<div style={styles.profilePic}>
										<img
											alt="Team Chat"
											src={data.commentType === 'discussion' ? dqLogo : team}
											width="20"
											height="20"
										/>
										<div style={styles.commentNameParent}>{name[0]}</div>
									</div>

									<Mutation mutation={CHANGE_COMMENT}>
										{(changeCommentType, { loading: changeLoading }) => {
											return (
												<Popconfirm
													placement="bottom"
													title={convert}
													onConfirm={() => {
														if (changeLoading) {
															return
														}
														const variables = {
															commentId: data.id,
															type:
																data.commentType === 'discussion'
																	? 'issue'
																	: 'discussion',
														}
														changeCommentType({
															variables,
															refetchQueries: [
																{
																	query: GET_COMMENTS,
																	variables: {
																		hash: userHashState,
																		type: 'photo',
																		feedId: data.feedId,
																		imageId: data.imageId,
																		spaceId: data.spaceId,
																		productId: null,
																		variantId: null,
																		role: 'user',
																		status: 'unresolved',
																	},
																},
																{
																	query: GET_COMMENTS,
																	variables: {
																		hash: userHashState,
																		type: 'photo',
																		feedId: data.feedId,
																		imageId: data.imageId,
																		spaceId: data.spaceId,
																		productId: null,
																		variantId: null,
																		role: 'user',
																		status: 'resolved',
																	},
																},
															],
														})
													}}
													okText="Yes"
													cancelText="No">
													<Tooltip placement="topRight" title="Click to convert">
														<div style={styles.convert}>...</div>
													</Tooltip>
												</Popconfirm>
											)
										}}
									</Mutation>

									<span>
										<span style={styles.commentText}>{data.text}</span>
										<span style={{ ...styles.commentDate, ...styles.commentDateParent }}>
											{util.formatRelativetimestamp(data.commentedAt)}
										</span>
									</span>
								</div>
							</div>
							<div>
								{data.childComments.map((datas, indexValue) => {
									const users = datas.commentedBy === userHashState
									const nameChild =
										datas && datas.commentedProfile && datas.commentedProfile.name
											? datas.commentedProfile.name
											: ''

									const namesChild = nameChild.split(',')

									const box = users
										? data.commentType === 'discussion'
											? styles.lightBlue
											: styles.lightYellow
										: data.commentType === 'discussion'
										? styles.white
										: styles.darkYellow

									if (datas.type === 'comment') {
										return (
											<div
												style={{
													...styles.parentComment,
													...(users ? styles.right : styles.left),
												}}
												key={indexValue}>
												{users ? (
													<div style={styles.childComment} className="hideShow">
														<Mutation mutation={DELETE_COMMENT}>
															{(deleteComment, { loading: changeLoading }) => {
																return (
																	<>
																		{datas.isDeleted !== 'true' ? (
																			<Popconfirm
																				placement="left"
																				title={deleted}
																				// icon={<IoMdTrash style={{ color: 'red',fontSize:'5px' }} />}
																				onConfirm={() => {
																					if (changeLoading) {
																						return
																					}
																					const variables = {
																						hash: userHashState,
																						commentId: datas.id,
																						level: 'child',
																						id: data.id,
																					}
																					deleteComment({
																						variables,
																						refetchQueries: [
																							{
																								query: GET_COMMENTS,
																								variables: {
																									hash: userHashState,
																									type: 'photo',
																									feedId: data.feedId,
																									imageId: data.imageId,
																									spaceId: data.spaceId,
																									productId: null,
																									variantId: null,
																									role: 'user',
																									status: 'unresolved',
																								},
																							},
																							{
																								query: GET_COMMENTS,
																								variables: {
																									hash: userHashState,
																									type: 'photo',
																									feedId: data.feedId,
																									imageId: data.imageId,
																									spaceId: data.spaceId,
																									productId: null,
																									variantId: null,
																									role: 'user',
																									status: 'resolved',
																								},
																							},
																						],
																					})
																				}}
																				okText="Yes"
																				cancelText="No">
																				<Tooltip
																					placement="top"
																					title="Click to delete comment">
																					<div
																						style={styles.deleteHideShow}
																						className="deleteHideShow">
																						...
																					</div>
																				</Tooltip>
																			</Popconfirm>
																		) : null}
																	</>
																)
															}}
														</Mutation>

														{datas.text !== 'ðŸ‘' ? (
															<>
																{datas.isDeleted === 'true' ? (
																	<div style={{ ...styles.deleteExtra, ...box }}>
																		<span>
																			<span style={styles.deleteColor}>
																				This comment has been deleted by you
																			</span>
																			<span
																				style={{
																					...styles.commentDate,
																					...styles.commentDateParent,
																				}}>
																				{util.formatRelativetimestamp(datas.commentedAt)}
																			</span>
																		</span>
																	</div>
																) : (
																	<div style={{ ...styles.commentRight, ...box }}>
																		<span>
																			<span style={styles.commentText}>{datas.text}</span>
																			<span
																				style={{
																					...styles.commentDate,
																					...styles.commentDateParent,
																				}}>
																				{util.formatRelativetimestamp(datas.commentedAt)}
																			</span>
																		</span>
																	</div>
																)}
															</>
														) : (
															<>
																{datas.isDeleted === 'true' ? (
																	<div style={{ ...styles.deleteExtra, ...box }}>
																		<span>
																			<span style={styles.deleteColor}>
																				This comment has been deleted by you
																			</span>
																			<span
																				style={{
																					...styles.commentDate,
																					...styles.commentDateParent,
																				}}>
																				{util.formatRelativetimestamp(datas.commentedAt)}
																			</span>
																		</span>
																	</div>
																) : (
																	<div style={styles.rightThumbs}>
																		<span style={styles.commentText}>
																			<img
																				alt="Team Chat"
																				style={styles.teamChatImage}
																				src={thumbsUp}
																				width="60"
																				height=""
																			/>
																		</span>
																		<span
																			style={{
																				...styles.commentDate,
																				...styles.commentDateParent,
																			}}>
																			{util.formatRelativetimestamp(datas.commentedAt)}
																		</span>
																	</div>
																)}
															</>
														)}
													</div>
												) : (
													<div style={{ ...styles.commentLeft, ...box }}>
														<div>
															<div style={styles.commentName}>{namesChild[0]}</div>
															<span style={styles.commentText}>
																{datas.text !== 'ðŸ‘' ? (
																	<>
																		{datas.isDeleted === 'true' ? (
																			<span style={styles.deleteColor}>
																				This comment has been deleted
																			</span>
																		) : (
																			datas.text
																		)}
																	</>
																) : (
																	<>
																		{datas.isDeleted === 'true' ? (
																			<span style={styles.deleteColor}>
																				This comment has been deleted
																			</span>
																		) : (
																			<img
																				alt="Team Chat"
																				style={styles.teamChatImage}
																				src={thumbsUp}
																				width="60"
																				height=""
																			/>
																		)}
																	</>
																)}
															</span>
															<span
																style={{
																	...styles.commentDate,
																	...styles.commentDateParent,
																}}>
																{' '}
																{util.formatRelativetimestamp(datas.commentedAt)}{' '}
															</span>
														</div>
													</div>
												)}
											</div>
										)
									}
									return (
										<div
											style={{
												...styles.commentLog,
												...(users ? styles.right : styles.left),
											}}>
											<p style={styles.logText}>
												{namesChild[0]} closed ticket {`    `}
												{util.formatRelativetimestamp(datas.commentedAt)}
											</p>
										</div>
									)
								})}
								<div style={{ ...styles.parentComment, ...styles.right }}>
									<UnresolvedCommentSection
										data={data}
										status={status}
										teamClientStatus={data.commentType}
										userHashState={userHashState}
										projectUrl={this.props.projectUrl}
									/>
								</div>
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)

	/**
	 * This function is used to display modal content.
	 * @param {* It contain Array of object} flatternArray
	 * @param {* It contain refetch} refetch
	 * @returns {modal details}
	 */
	modalContentDisplay = (flatternArray, refetch) => {
		const {
			resolvedStateLength,
			unresolvedStateLength,
			indexSet,
			closedShow,
			discussionIcon,
			commentInput,
			spaceSelection,
			selection,
			loadCount,
			userHashState,
			editButton,
		} = this.state

		const { projectUrl } = this.props

		const userDescription = flatternArray[indexSet].uploadedBy === userHashState
		return (
			<div style={styles.modalBox}>
				{/* modal image display */}
				<div style={styles.modalImageBox}>
					<div
						style={{
							background: `url(${flatternArray[indexSet].uri}) no-repeat center`,
							...styles.modalImageLight,
						}}
					/>
					<div style={styles.modalImageBlur} />
					<img
						style={styles.siteImage}
						src={flatternArray[indexSet].uri}
						alt="examples"
					/>
				</div>

				{/* modal detail display */}
				<div style={styles.modalDetail}>
					<div style={styles.profileHead}>
						<div style={styles.profileSection}>
							<div style={styles.profileName}>
								{flatternArray[indexSet].uploadedProfile.photo ? (
									<img
										style={styles.modalProfilePic}
										src={flatternArray[indexSet].uploadedProfile.photo}
										alt="example"
										width="30"
										height="30"
									/>
								) : (
									<div style={styles.modalProfilePicNo}>
										{util.getInitials(flatternArray[indexSet].uploadedProfile.name)}
									</div>
								)}
								<Text style={styles.modalName}>
									{flatternArray[indexSet].uploadedProfile.name}
									{` @ `}
								</Text>

								<div style={styles.modalProject}>
									<Text style={styles.projectNames}>
										{flatternArray[indexSet].projectName}
									</Text>
								</div>
							</div>
						</div>

						<div>
							<div style={styles.modalTime}>
								{`${util.formatRelativetimestamp(flatternArray[indexSet].uploadedAt)}`}
								{flatternArray[indexSet].space !== '' ? <span>{` at `}</span> : null}
								<Tooltip placement="bottom" title="Space">
									<span style={styles.modalPostSpace}>
										{flatternArray[indexSet].space}
									</span>
								</Tooltip>
								{`  `}
								{flatternArray[indexSet].clientDisplayStatus === 'pending' ? (
									<Text code style={styles.projectName}>
										{flatternArray[indexSet].clientDisplayStatus}
									</Text>
								) : null}
							</div>
						</div>

						{/* photo description display in modal */}
						{userDescription ? (
							<>
								{flatternArray[indexSet].description !== '' &&
								flatternArray[indexSet].description !== null ? (
									<Mutation mutation={EDIT_DESCRIPTION}>
										{(editDescription) => {
											return (
												<div style={styles.editBlock}>
													<Paragraph
														style={styles.editBlockIcon}
														ellipsis={{ rows: 3, expandable: true }}
														editable={{
															onStart: this.changeEditButton,
															onChange: (str) =>
																this.changeDescription(
																	str,
																	editDescription,
																	flatternArray[indexSet].feedId,
																	flatternArray[indexSet].imageId,
																	refetch
																),
														}}>
														{flatternArray[indexSet].description}
													</Paragraph>
													{editButton ? (
														<Button style={styles.editBlockButton}>Add</Button>
													) : null}
												</div>
											)
										}}
									</Mutation>
								) : (
									<Mutation mutation={EDIT_DESCRIPTION}>
										{(editDescription) => {
											return (
												<div style={styles.editBlock}>
													<Paragraph
														style={styles.editBlockIcon}
														editable={{
															onStart: this.changeEditButton,
															onChange: (str) =>
																this.changeDescription(
																	str,
																	editDescription,
																	flatternArray[indexSet].feedId,
																	flatternArray[indexSet].imageId,
																	refetch
																),
														}}>
														<span style={styles.addDescription}>Add Caption</span>
														{flatternArray[indexSet].description}
													</Paragraph>
													{editButton ? (
														<Button style={styles.editBlockButton}>Add</Button>
													) : null}
												</div>
											)
										}}
									</Mutation>
								)}
							</>
						) : (
							<Paragraph ellipsis={{ rows: 3, expandable: true }}>
								{flatternArray[indexSet].description}
							</Paragraph>
						)}
					</div>

					{/* Comment display section */}
					<div style={styles.commentDisplay} id="commentScroll">
						{unresolvedStateLength === 0 && resolvedStateLength === 0 ? (
							<div style={styles.noTicket}>No ticket found</div>
						) : null}
						<>
							<Query
								query={GET_COMMENTS}
								variables={{
									hash: userHashState,
									type: 'photo',
									feedId: flatternArray[indexSet].feedId,
									imageId: flatternArray[indexSet].imageId,
									spaceId: flatternArray[indexSet].spaceId,
									productId: null,
									variantId: null,
									role: 'user',
									status: 'unresolved',
								}}>
								{({
									data: commentData,
									loading: commentLoading,
									subscribeToMore,
									refetch: commentUnsolvedRefetch,
									error: commentUnsolvedError,
								}) => {
									if (commentLoading) {
										return (
											<Spin
												indicator={
													<Icon type="loading" style={styles.loadingIcon} spin />
												}
												style={styles.loadIconStyle}
											/>
										)
									}
									if (
										!commentData ||
										!commentData.photoComments ||
										commentUnsolvedError
									) {
										return <span>Some error </span>
									}

									const { photoComments } = commentData
									// console.log(photoComments)
									if (photoComments.length === 0) {
										if (unresolvedStateLength !== 0) {
											this.setState({
												unresolvedStateLength: 0,
											})
										}
										return null
									}

									return (
										<div>
											<SubscriptionComment
												photoComments={photoComments}
												status="unresolved"
												userHashState={userHashState}
												commentDisplay={this.commentDisplay}
												hideClosedDiscussion={this.hideClosedDiscussion}
												subscribeToNewComments={() =>
													subscribeToMore({
														document: COMMENTS_SUBSCRIPTION,
														variables: {
															feedId: flatternArray[indexSet].feedId,
															imageId: flatternArray[indexSet].imageId,
															productId: null,
															variantId: null,
															spaceId: flatternArray[indexSet].spaceId,
														},
														updateQuery: (prev, { subscriptionData }) => {
															if (!subscriptionData.data) return prev
															commentUnsolvedRefetch()
															return null
														},
													})
												}
											/>
										</div>
									)
								}}
							</Query>

							<Query
								query={closedCount}
								variables={{
									type: 'photo',
									productId: null,
									variantId: null,
									spaceId: flatternArray[indexSet].spaceId,
									feedId: flatternArray[indexSet].feedId,
									imageId: flatternArray[indexSet].imageId,
									status: 'resolved',
								}}>
								{({ data: countData, loading: countLoading }) => {
									if (countLoading) {
										return (
											<Spin
												indicator={
													<Icon type="loading" style={styles.loadingIcon} spin />
												}
												style={styles.loadIconStyle}
											/>
										)
									}

									if (countData.closedPhotoCommentsCount < 1) {
										if (resolvedStateLength > 0) {
											this.setState({
												resolvedStateLength: 0,
											})
										}
										return null
									}

									return (
										<>
											{closedShow ? (
												<Query
													query={GET_COMMENTS}
													variables={{
														hash: userHashState,
														type: 'photo',
														feedId: flatternArray[indexSet].feedId,
														imageId: flatternArray[indexSet].imageId,
														spaceId: flatternArray[indexSet].spaceId,
														productId: null,
														variantId: null,
														role: 'user',
														status: 'resolved',
													}}>
													{({
														data: unclosedData,
														loading: unclosedLoading,
														subscribeToMore,
														refetch: commentSolvedRefetch,
														error: commentSolvedError,
													}) => {
														if (unclosedLoading) {
															return (
																<Spin
																	indicator={
																		<Icon
																			type="loading"
																			style={styles.loadingIcon}
																			spin
																		/>
																	}
																	style={styles.loadIconStyle}
																/>
															)
														}
														if (
															!unclosedData ||
															!unclosedData.photoComments ||
															commentSolvedError
														) {
															return <span>Some error </span>
														}

														const { photoComments } = unclosedData

														// console.log(data);
														if (photoComments.length !== 0) {
															return (
																<SubscriptionComment
																	photoComments={photoComments}
																	status="resolved"
																	userHashState={userHashState}
																	commentDisplay={this.commentDisplay}
																	hideClosedDiscussion={this.hideClosedDiscussion}
																	subscribeToNewComments={() =>
																		subscribeToMore({
																			document: COMMENTS_SUBSCRIPTION,
																			variables: {
																				feedId: flatternArray[indexSet].feedId,
																				imageId: flatternArray[indexSet].imageId,
																			},
																			updateQuery: (prev, { subscriptionData }) => {
																				if (!subscriptionData.data) return prev
																				commentSolvedRefetch()
																			},
																		})
																	}
																/>
															)
														}

														return <div />
													}}
												</Query>
											) : (
												<div
													style={styles.showClosedDiscussion}
													onClick={this.showClosedDiscussion}>
													Show Closed Tickets({countData.closedPhotoCommentsCount})
												</div>
											)}
										</>
									)
								}}
							</Query>
						</>
					</div>

					{/* comment add section */}
					<div style={styles.commentAdd}>
						<div onClick={() => this.discussionIcon()}>
							<img
								src={discussionIcon === 'team' ? teamChat : clientChat}
								alt="example"
								width="40"
								height="40"
							/>
						</div>

						<Mutation mutation={ADDCOMMENT}>
							{(addComment) => {
								return (
									<Input
										placeholder={`Start a new ${discussionIcon} ticket`}
										value={commentInput}
										onChange={(e) => this.commentChange(e)}
										onPressEnter={async () => {
											if (commentInput.trim() === '') {
												return
											}
											const variables = {
												projectUrl,
												hash: userHashState,
												text: commentInput,
												level: 'parent',
												type: 'photo',
												commentType: discussionIcon === 'team' ? 'discussion' : 'issue',
												productId: null,
												variantId: null,
												spaceId: flatternArray[indexSet].spaceId,
												feedId: flatternArray[indexSet].feedId,
												imageId: flatternArray[indexSet].imageId,
												parentId: '',
												mentionedTo: [],
											}
											this.inputBoxClear()
											await addComment({
												variables,
												awaitRefetchQueries: true,
												refetchQueries: [
													{
														query: GET_COMMENTS,
														variables: {
															hash: userHashState,
															type: 'photo',
															feedId: flatternArray[indexSet].feedId,
															imageId: flatternArray[indexSet].imageId,
															spaceId: flatternArray[indexSet].spaceId,
															productId: null,
															variantId: null,
															role: 'user',
															status: 'unresolved',
														},
													},
												],
											})
											await refetch({
												projectUrl,
												hash: userHashState,
												limit: loadCount * 9,
												offset: 0,
												spaceId: spaceSelection,
												role: 'user',
											})

											if (unresolvedStateLength === 0) {
												this.setState({
													unresolvedStateLength: 1,
												})
											}
										}}
									/>
								)
							}}
						</Mutation>

						<Mutation mutation={ADDCOMMENT}>
							{(addComment) => {
								return (
									<Button
										type="primary"
										shape="circle"
										style={styles.sendButton}
										onClick={async () => {
											if (commentInput.trim() === '') {
												return
											}
											const variables = {
												projectUrl,
												hash: userHashState,
												text: commentInput,
												level: 'parent',
												type: 'photo',
												commentType: discussionIcon === 'team' ? 'discussion' : 'issue',
												productId: null,
												variantId: null,
												spaceId: flatternArray[indexSet].spaceId,
												feedId: flatternArray[indexSet].feedId,
												imageId: flatternArray[indexSet].imageId,
												parentId: '',
												mentionedTo: [],
											}

											this.inputBoxClear()
											await addComment({
												variables,
												awaitRefetchQueries: true,
												refetchQueries: [
													{
														query: GET_COMMENTS,
														variables: {
															hash: userHashState,
															type: 'photo',
															feedId: flatternArray[indexSet].feedId,
															imageId: flatternArray[indexSet].imageId,
															spaceId: flatternArray[indexSet].spaceId,
															productId: null,
															variantId: null,
															role: 'user',
															status: 'unresolved',
														},
													},
													// {
													// 	query: SitePhotosQuery,
													// 	variables: {
													// 		projectUrl,
													// 		hash: userHashState,
													// 		limit: loadCount * 9,
													// 		offset: 0,
													// 		spaceId: spaceSelection,
													// 	},
													// },
												],
											})
											await refetch({
												projectUrl,
												hash: userHashState,
												limit: loadCount * 9,
												offset: 0,
												spaceId: spaceSelection,
												role: 'user',
											})

											if (unresolvedStateLength === 0) {
												this.setState({
													unresolvedStateLength: 1,
												})
											}
										}}>
										<IoMdSend style={styles.sendIcon} />
									</Button>
								)
							}}
						</Mutation>
					</div>
				</div>

				{!selection ? (
					true
				) : (
					<div style={styles.modalClose}>
						<Icon type="close" style={styles.closeIcon} onClick={this.handleCancel} />
					</div>
				)}
			</div>
		)
	}

	/**
	 * It should be excecute first
	 * This is used for display site photos
	 * @returns {site photos page}
	 */
	/**
	 * This function is used to display a site photos.
	 * It contain card profile name, photo comment and Action event like comment.
	 * @returns {site photo}
	 */

	render() {
		// Deconstructing the state
		const {
			spaceSelection,
			// selection,
			loadMore,
			ismodalopen,
			indexSet,
			backTopButton,
			userHashState,
			imageUploadModal,
			spaceState, // space filter data
			formData, // form data
			uploadIndexSet, // upload image index
			uploadSpace, // upload image space
			descriptionArray, // upload image description
			uploadInvalid,
			isLoaded,
			userRoleState,
			projectNameState,
			getDetails,
			loadings,
		} = this.state
		// console.log(getDetails)
		const { projectUrl } = this.props

		return (
			<ApolloProvider client={newClient}>
				<Query
					query={SitePhotosQuery}
					variables={{
						projectUrl,
						hash: userHashState,
						limit: 9,
						offset: 0,
						spaceId: spaceSelection,
						role: 'user',
					}}>
					{({
						data,
						loading,
						fetchMore,
						error,
						refetch,
						networkStatus,
						subscribeToMore,
					}) => {
						if (loading && networkStatus !== 2) {
							// networkstatus -> it handles a refetch data status
							return (
								<Spin
									indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
									style={styles.loadIconStyle}
								/>
							)
						}
						if (!data || !data.projectPhotos || error) {
							return <span>Some error </span>
						}
						if (data.projectPhotos.length < 9 && !isLoaded) {
							this.setState({ isLoaded: true })
						}

						//  imagearray removes the date key combines the feeds key arrays
						const imageArray = data.projectPhotos.map((item) =>
							item.feeds.map((i) => ({ ...i }))
						)
						// flattern array -> Array combine for projectphotos
						const flatternArray = util.flatten(imageArray)

						// console.log(data.projectPhotos)

						return (
							<div
								style={styles.sitePhoto}
								onScroll={(e) => this.handleScroll(e, fetchMore, data)}
								id="scroll">
								{/* backtop button add */}
								{backTopButton ? (
									<IoIosArrowDropupCircle
										style={styles.BackToInner}
										onClick={this.scrollToTop}
									/>
								) : null}

								{/* dyamically add space filter  */}
								{/* space query start */}
								<Query
									query={spaces}
									variables={{
										projectUrl,
									}}>
									{({ data: spaceData, loading: spaceLoading, error: spaceerror }) => {
										if (spaceLoading) {
											return (
												<Spin
													indicator={
														<Icon type="loading" style={styles.loadingIcon} spin />
													}
													style={styles.loadIconStyle}
												/>
											)
										}
										// console.log(data.spaces);
										if (!spaceData || !spaceData.spaces || spaceerror) {
											return <span>No data found</span>
										}
										if (spaceState === null) {
											this.setState({ spaceState: spaceData })
										}
										console.log(spaceData.spaces.length)
										if (spaceData.spaces.length <= 0) {
											return null
										}

										// Dynamically add space filter
										//  console.log(spaceData.spaces)
										// console.log(spaceState)
										return (
											<div style={styles.spaceFilter}>
												<Select
													defaultValue={spaceSelection}
													value={spaceSelection}
													style={styles.filter}
													onChange={this.handleChange}>
													<Option value="All">All spaces</Option>
													{spaceData.spaces.map((
														element,
														index // dynamically display space filter data
													) => (
														<Option key={index} value={element.id}>
															{element.name}
														</Option>
													))}
												</Select>
											</div>
										)
									}}
								</Query>
								{/* space query end */}

								<PhotoSubscription
									data={data}
									flatternArray={flatternArray}
									setSelected={this.setSelected}
									imageUpload={this.imageUpload}
									getImage={(image) => this.getImage(image)}
									subscribeToNewPhotos={() =>
										subscribeToMore({
											document: PHOTO_SUBSCRIPTION,
											variables: {
												projectUrl,
											},
											updateQuery: (prev, { subscriptionData }) => {
												if (!subscriptionData.data) return prev
												refetch()
												return null
											},
										})
									}
								/>

								{/* project photos data looping end */}
								{/* loading start */}
								{loadMore === 'loading' ? (
									<Spin
										indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
										style={styles.loadIconStyle}
									/>
								) : null}
								{isLoaded ? <div style={styles.noMore}> No more photos :) </div> : null}
								{/* loading start end */}
								{/* Image modal display */}
								<Modal
									title={null}
									header={null}
									visible={ismodalopen}
									footer={null}
									onCancel={this.handleCancel}
									wrapClassName="antmodalclass"
									style={{ overflowY: 'hidden !important' }}>
									{ismodalopen ? (
										<div style={styles.fullModal}>
											{indexSet > 0 ? (
												<div style={styles.leftIcon}>
													<IoIosArrowDropleftCircle // modal left navigation icon
														style={styles.navigationIconColor}
														onClick={() => this.changeIndex(indexSet - 1)}
													/>
												</div>
											) : null}
											{/*  modal content */}
											{this.modalContentDisplay(flatternArray, refetch)}
											{indexSet !== flatternArray.length - 1 ? (
												<div style={styles.rightIcon}>
													<IoIosArrowDroprightCircle // modal right navigation icon
														style={styles.navigationIconColor}
														onClick={() => this.changeIndex(indexSet + 1)}
													/>
												</div>
											) : null}
										</div>
									) : null}
								</Modal>

								<Modal
									title={null}
									header={null}
									visible={imageUploadModal}
									footer={null}
									onCancel={this.imageUploadModalClose}
									wrapClassName="antuploadmodalclass"
									style={{ overflowY: 'hidden !important' }}>
									{imageUploadModal ? (
										<>
											{uploadIndexSet !== 0 ? (
												<div style={styles.leftIcon}>
													<IoIosArrowDropleftCircle // modal left navigation icon
														style={styles.navigationIconColor}
														onClick={() => this.uploadChangeIndex(uploadIndexSet - 1)}
													/>
												</div>
											) : null}
											<div style={styles.uploadModal}>
												<div style={styles.uploadModalImage}>
													<img
														className="imageHover"
														style={styles.siteImagesUpload}
														src={URL.createObjectURL(formData[uploadIndexSet])}
														alt="Site Photos"
														width="300"
														height="300"
													/>
												</div>
												{spaceState.spaces.length > 0 ? (
													<>
														<div style={styles.uploadModalSpace}>
															<div style={styles.uploadSpaceLabel}>
																Select your space :{' '}
															</div>
															<Select
																// defaultValue={uploadSpace.id}
																placeholder="Select a space"
																style={styles.uploadFilter}
																onChange={this.uploadSpace}>
																{/* <Option value="">All spaces</Option> */}
																{spaceState.spaces.map((
																	element,
																	index // dynamically display space filter data
																) => (
																	<Option key={index} value={element.id}>
																		{element.name}
																	</Option>
																))}
															</Select>
														</div>
														{uploadInvalid ? (
															<div style={styles.error}>Space is required</div>
														) : null}
													</>
												) : null}

												<div style={styles.uploadModalCaption}>
													<TextArea
														autoSize={{ minRows: 2 }}
														placeholder="Add your caption"
														value={
															descriptionArray[uploadIndexSet]
																? descriptionArray[uploadIndexSet].description
																: ''
														}
														onChange={(e) => this.uploadCommentChange(e, uploadIndexSet)}
													/>
												</div>
												{/* {uploadInvalid ? (
												<div style={styles.errorCaption}>Caption is required</div>
											) : null} */}
												<div style={styles.uploadModalButton}>
													<Mutation mutation={Upload}>
														{(post, { loading: uploadLoading }) => {
															// const variables = {
															// 	projectUrl,
															//   projectName: projectNameState,
															//   photos: Array.from(formData),
															//   description: descriptionArray,
															//   spaces: [uploadSpace],
															//   topics: [],
															//   user: getDetails,
															// }
															// console.log(variables)
															return (
																// <>
																// 	{uploadLoading ? (
																// 		<Spin
																// 			indicator={
																// 				<Icon
																// 					type="loading"
																// 					style={styles.loadingIconsModal}
																// 					spin
																// 				/>
																// 			}
																// 			style={styles.loadIconStyles}
																// 		/>
																// 	) : (
																<Button
																	type="primary"
																	loading={loadings}
																	style={styles.sendButton}
																	onClick={async () => {
																		this.loadings()
																		if (uploadLoading) {
																			return
																		}
																		const variables = {
																			projectUrl,
																			projectName: projectNameState,
																			photos: Array.from(formData),
																			description: descriptionArray,
																			spaces:
																				spaceState.spaces.length > 0 ? [uploadSpace] : [],
																			topics: [],
																			user: getDetails,
																		}

																		if (
																			spaceState.spaces.length > 0 &&
																			Object.keys(uploadSpace).length === 0
																		) {
																			this.setState({ uploadInvalid: true })
																			return
																		}
																		await post({
																			variables,
																			awaitRefetchQueries: true,
																			refetchQueries: [
																				{
																					query: SitePhotosQuery,
																					variables: {
																						projectUrl,
																						hash: userHashState,
																						limit: 9,
																						offset: 0,
																						spaceId: spaceSelection,
																						role: 'user',
																					},
																				},
																			],
																		})

																		this.imageUploadModalClose()
																		this.imageUploadedNotification()
																		this.loadings()
																	}}>
																	{formData.length > 1 ? 'Upload All' : 'Upload'}
																</Button>
																// 	  )}
																// </>
															)
														}}
													</Mutation>
												</div>
												{/* <div style={styles.mutedText}>
													You can upload one or more photos
												</div> */}
											</div>
											{uploadIndexSet !== formData.length - 1 ? (
												<div style={styles.rightIcon}>
													<IoIosArrowDroprightCircle // modal right navigation icon
														style={styles.navigationIconColor}
														onClick={() => this.uploadChangeIndex(uploadIndexSet + 1)}
													/>
												</div>
											) : null}
										</>
									) : null}
								</Modal>

								{/* Image modal display */}
							</div>
						)
					}}
				</Query>
			</ApolloProvider>
		)
	}
}

export default withApollo(SitePhotos)
