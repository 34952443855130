import gql from 'graphql-tag'

export const ADD_ENQUIRY_NOTES_MUTATION = gql`
	mutation AddEnquiryNotes($enquiryId: String, $stageFrom: String, $notes: String) {
		addEnquiryNotes(enquiryId: $enquiryId, stageFrom: $stageFrom, notes: $notes) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
