// Import Components
import React from 'react'
import { Avatar, Icon } from 'antd'
import PropTypes from 'prop-types'

import styles from './styles.js'

// Define a UserProfile Component
class UserProfile extends React.Component {
	render() {
		const { profileDrawer } = this.props

		return <Avatar icon="user" onClick={profileDrawer} style={styles.profileAvatar} />
	}
}

UserProfile.propTypes = {
	profileDrawer: PropTypes.func,
}

export default UserProfile
