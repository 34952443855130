import React from 'react'
import { MdClose, MdPhotoSizeSelectActual } from 'react-icons/md'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { Row, Col, Avatar, Spin, Icon } from 'antd'
import { Query } from 'react-apollo'
import { GET_ARCHIEVED_LIST, ADD_OR_REMOVE_ARCHIVE } from '../query'
import { Mutation } from 'react-apollo'
import { Tag } from 'antd'
import { formatChatRelativetimestamp } from './../utils'
import MessageDropdown from './../ChatBody/MessageDropdown'
import styles from './styles'

class ArchivedMember extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchString: '',
			archivedMember: [],
			selectedValue: [],
		}
	}

	render() {
		const { hash, handleModalCancel, groupId, chatListRefetch } = this.props
		const { searchString, archivedMember, selectedValue } = this.state
		const lowercasedFilter = searchString.toLowerCase()
		const filteredData = archivedMember.filter((item) => {
			return item['name'].toLowerCase().includes(lowercasedFilter)
		})

		return (
			<Mutation mutation={ADD_OR_REMOVE_ARCHIVE}>
				{(archieveChat, { data, loading }) => (
					<Modal type="archivedMember">
						<ModalHeader type="archivedMember">
							<div style={styles.modalHeaderFlex}>
								<span onClick={handleModalCancel} style={styles.modalHeaderTextColor}>
									<MdClose size={25} style={styles.modalHeaderIcon} />
								</span>
								<span style={styles.modalHeaderName}>Archive group or members list</span>
							</div>
						</ModalHeader>
						<ModalButtonContainer type="archivedMember">
							<Row>
								<Col span={24}>
									<div className="chatSearch" style={styles.positionRelative}>
										<input
											type="text"
											placeholder="search"
											onChange={(e) => {
												this.setState({ searchString: e.target.value })
											}}
											value={searchString}
										/>
										<img
											src={require('./../images/searchicon.png')}
											alt={''}
											style={styles.searchIcon}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Query query={GET_ARCHIEVED_LIST} variables={{ hash: hash }}>
									{({ loading, error, data, refetch }) => {
										if (loading) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.centerAlign}>
															<Spin indicator={<Icon type="loading" spin />} />
														</div>
													</div>
												</div>
											)
										}
										if (!data || !data.getArchievedChat.length) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.addNewGroup.groupMemberNotFound}>
															<span>No archived member found</span>
														</div>
													</div>
												</div>
											)
										}

										if (!archivedMember.length) {
											this.setState({ archivedMember: data.getArchievedChat })
										}

										return (
											<>
												{filteredData.length ? (
													filteredData.map((value) => {
														const { id, name, photo, latestMessage } = value
														const {
															name: latestMessageName,
															message: latestMessageText,
															type,
															time,
														} = latestMessage
														const { archivedChat } = styles
														const labelsAndContext = {
															'Unarchive chat': (_) => {
																const { handleModalOk } = this.props
																handleModalOk({
																	archieveChat,
																	refetch,
																	selectedMember: selectedValue,
																	type: 'unArchiveMember',
																})
															},
														}

														return (
															<div
																className="chatListItem"
																key={id + name}
																onMouseOver={() => {
																	this.setState({ selectedValue: value })
																}}
																onMouseLeave={() => {
																	this.setState({ selectedValue: [] })
																}}
																style={
																	id === groupId
																		? archivedChat.selectedBackground
																		: archivedChat.unselectedBackground
																}>
																<div
																	style={archivedChat.chatListDiv}
																	onClick={(event) => {
																		this.props.getChats(
																			event,
																			value,
																			chatListRefetch,
																			'archived'
																		)
																	}}>
																	{photo ? (
																		<Avatar
																			style={archivedChat.chatListProfilePic}
																			src={photo}
																			alt=""
																		/>
																	) : (
																		<Avatar
																			size="large"
																			style={archivedChat.chatListEmptyProfilePic}>
																			{name.slice(0, 1).toUpperCase()}
																		</Avatar>
																	)}
																</div>
																<div
																	style={archivedChat.chatSelectedList}
																	onClick={(event) => {
																		this.props.getChats(
																			event,
																			value,
																			chatListRefetch,
																			'archived'
																		)
																	}}>
																	<div style={archivedChat.chatPaddingLeft}>
																		<div>
																			<label>{name}</label>
																		</div>
																		<div style={{ display: 'flex', width: 200 }}>
																			<p style={archivedChat.chatLastMessage}>
																				{latestMessageName !== null
																					? latestMessageName + ': '
																					: ''}
																				{latestMessageText}
																			</p>
																			{type === 'image' ? (
																				<MdPhotoSizeSelectActual
																					size={18}
																					style={archivedChat.chatListImageIcon}
																				/>
																			) : (
																				''
																			)}
																		</div>
																	</div>
																</div>
																<div style={archivedChat.chatStatus}>
																	<label style={archivedChat.chatLastMessageTime}>
																		{formatChatRelativetimestamp(time)}
																	</label>
																	{selectedValue.id !== value.id ? (
																		<div>
																			<Tag
																				color="default"
																				style={{
																					color: 'gray',
																					border: '1px solid lightgray',
																					background: '#e9e7e7',
																				}}>
																				ARCHIVED
																			</Tag>
																		</div>
																	) : (
																		<div style={{ margin: 'auto' }}>
																			<MessageDropdown
																				labelsAndContext={labelsAndContext}
																			/>
																		</div>
																	)}
																</div>
															</div>
														)
													})
												) : (
													<div style={styles.fullWidth}>
														<div>
															<div style={styles.addNewGroup.groupMemberNotFound}>
																<span>No member found</span>
															</div>
														</div>
													</div>
												)}
											</>
										)
									}}
								</Query>
							</Row>
						</ModalButtonContainer>
					</Modal>
				)}
			</Mutation>
		)
	}
}
export default ArchivedMember
