/**
  closed team tickets Component
  This component will render display closed team tickets 
* */

/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Spin, Icon, Typography, Modal } from 'antd'

// Import required components from FontAwesome
import { FaRegCommentDots } from 'react-icons/fa'

// Query component of React Apollo to fetch data
import { Query } from 'react-apollo'
// Import Override CSS
import './index.css'
// Query to fetch closed team tickets Details
import { TEAM_DISCUSSION } from '../query'

// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'

// Import thumbsup image
import thumbsUp from '../../../assets/thumbs_up.png'

// import utils file
import util from '../../../utils'

import ModalContentDisplay from '../modalContentDisplay'

// deconstruct Text from Typography
const { Text,Paragraph } = Typography

/**
 * This class component is used to display ticket images in webapp.
 * @returns {closed ticket component}
 */
class ClosedTeamTickets extends React.Component {
	// initial states
	state = {
		ismodalopen: false, // modal visibility
		indexSet: 0, // index value state it used for find index for modal content
		loadCount: 1,
	}

	/**
	 * get current selected image among all images
	 * and set it in a state
	 */
	setSelected = (index, element, flatternArray) => {
		const imageIndex = flatternArray.findIndex(
			(i) => i.commentId === element.commentId && i.key === element.key
		)
		this.setState({
			indexSet: imageIndex,
			ismodalopen: true,
			selection: true,
		})
	}

	/**
	 *  Sets the visiblity of modal to false when called
	 */
	handleCancel = () => {
		this.setState({
			ismodalopen: false,
			selection: false,
		})
	}

	/**
	 * It should be excecute first
	 * This is used for display closed tickets photos
	 * @returns {closed ticket page}
	 */
	/**
	 * This function is used to display a closed tickets photos.
	 * It contain card profile name, photo comment and Action event like comment.
	 * @returns {closed tickets photo}
	 */

	render() {
		// Deconstructing the state
		const { ismodalopen, indexSet, selection, loadCount } = this.state

		// Deconstructing the props
		const { projectUrl } = this.props

		return (
			<Query
				query={TEAM_DISCUSSION}
				variables={{
					projectUrl,
					status: 'resolved',
					limit: 3,
					offset: 0,
					spaceId: 'All',
				}}>
				{({
					data: closedTeamTicketData,
					loading: closedTeamTicketLoading,
					error: closedTeamTicketError,
					fetchMore,
					refetch,
				}) => {
					if (closedTeamTicketLoading) {
						// loading -> it handles a loading data status
						return (
							<Spin
								indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
								style={styles.loadIconStyle}
							/>
						)
					}
					if (
						!closedTeamTicketData ||
						!closedTeamTicketData.TeamDiscussion ||
						closedTeamTicketError
					) {
						return <span>Some error </span>
					}
					// flattern array -> Array combine for projectphotos
					const flatternArray = util.flatten(
						closedTeamTicketData.TeamDiscussion.discussions
					)

					// console.log(flatternArray)

					// console.log(closedTeamTicketData.TeamDiscussion)

					return (
						<div style={styles.closedTeamTicket}>
							<div style={styles.closedTeamTicketCount}>
								Closed Team Tickets ({closedTeamTicketData.TeamDiscussion.discussionCount}
								)
							</div>
							<div>
								{/* open team tickets photos data looping start */}
								{closedTeamTicketData.TeamDiscussion.discussions.map(
									(closedTeamData, ticketIndex) => {
										const names =
											closedTeamData &&
											closedTeamData.childCommentsLatest.profile &&
											closedTeamData.childCommentsLatest.profile.name
												? closedTeamData.childCommentsLatest.profile.name
												: ''
										const name = names.split(' ')
										return (
											<div
												style={styles.closedTeam}
												key={ticketIndex}
												className="closedTeamHover"
												onClick={() =>
													this.setSelected(ticketIndex, closedTeamData, flatternArray)
												}>
												{/* image part */}
												<div style={styles.imagePart}>
													{/* poster name display */}
													<div style={styles.posterName}>
														{util.getInitials(closedTeamData.uploadedProfile.name)}
													</div>
													{/* image display */}
													<img
														style={styles.Images}
														src={
															closedTeamData.thumbnailUri ||
															closedTeamData.uri ||
															closedTeamData.qualityUri
														}
														alt="Photos"
													/>
													{/* icon display */}
													{closedTeamData.childCommentCount > 0 ? (
														<span style={styles.commentIconWithoutBackgroud}>
															<FaRegCommentDots />
														</span>
													) : null}
												</div>
												{/* content part */}
												<div style={styles.contentPart}>
													<div style={styles.parentComment}>
														<div style={styles.profile}>
															<Text style={styles.profileName}>
																{closedTeamData.commentedProfile.name}
															</Text>
															<Text style={styles.profileTime}>
																{util.formatRelativetimestamp(closedTeamData.commentedAt)}
															</Text>
														</div>
														<div style={styles.ProfileText}>
															{closedTeamData.commentText}
														</div>
													</div>
													{closedTeamData.childCommentCount > 0 ? (
														<div style={styles.childProfile}>
															{closedTeamData.childCommentsLatest.latestComment ===
															'ðŸ‘' ? (
																<>
																	<Text style={styles.childProfileName}>
																		{`${name[0]} : `}
																	</Text>
																	<img
																		alt="Team Chat"
																		style={styles.childProfileText}
																		src={thumbsUp}
																		width="20"
																		height=""
																	/>
																</>
															) : (
																<Paragraph ellipsis style={styles.childProfileText}>
																	{`${name[0]}: ${closedTeamData.childCommentsLatest.latestComment}`}
																</Paragraph>
															)}
															{/* <Text style={styles.childProfileTime}>
																	{util.formatRelativetimestamp(
																		closedTeamData.childCommentsLatest.commentedAt
																	)}
																</Text> */}
														</div>
													) : null}
												</div>
											</div>
										)
									}
								)}
							</div>
							{closedTeamTicketData.TeamDiscussion.discussionCount ===
							closedTeamTicketData.TeamDiscussion.discussions.length ? (
								<div style={styles.noTicketShow}>No more tickets to show :)</div>
							) : (
								<div
									style={styles.ticketShow}
									onClick={() =>
										fetchMore({
											variables: {
												offset: closedTeamTicketData.TeamDiscussion.discussions.length,
											},
											updateQuery: (prev, { fetchMoreResult }) => {
												if (!fetchMoreResult) return prev
												this.setState({ loadCount: loadCount + 1 })
												return {
													...prev,
													TeamDiscussion: {
														...prev.TeamDiscussion,
														discussions: [
															...prev.TeamDiscussion.discussions,
															...fetchMoreResult.TeamDiscussion.discussions,
														],
													},
												}
											},
										})
									}>
									Show more
								</div>
							)}

							{/* Image modal display */}
							<Modal
								title={null}
								header={null}
								visible={ismodalopen}
								footer={null}
								onCancel={this.handleCancel}
								wrapClassName="antmodalclass"
								style={{ overflowY: 'hidden !important' }}>
								{ismodalopen ? (
									<div style={styles.fullModal}>
										{/*  modal content */}
										<ModalContentDisplay
											indexSet={indexSet}
											status="resolved"
											loadCount={loadCount}
											userHashState={closedTeamTicketData.TeamDiscussion.loggedInUser}
											flatternArray={flatternArray}
											projectUrl={projectUrl}
											handleCancel={this.handleCancel}
											selection={selection}
										/>
									</div>
								) : null}
							</Modal>
						</div>
					)
				}}
			</Query>
		)
	}
}

export default ClosedTeamTickets
