import React from 'react'
import { MdClose, MdGroupAdd } from 'react-icons/md'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { Spin, Icon, Row, Col, Avatar } from 'antd'
import { Query } from 'react-apollo'
import { GET_PEOPLE } from '../query'
import styles from './styles'

class AddParticipant extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			caption: '',
			teamMembers: [],
			searchString: '',
		}
	}
	render() {
		const { handleModalCancel, hash, handleModalOk } = this.props
		const { teamMembers, searchString } = this.state
		const lowercasedFilter = searchString.toLowerCase()
		const filteredData = teamMembers.filter((item) => {
			return item['name'].toLowerCase().includes(lowercasedFilter)
		})

		return (
			<Modal type="addParticipant">
				<ModalHeader type="addParticipant">
					<div style={styles.modalHeaderFlex}>
						<span onClick={handleModalCancel} style={styles.modalHeaderTextColor}>
							<MdClose size={25} style={styles.modalHeaderIcon} />
						</span>
						<span style={styles.modalHeaderName}>Select Team Member</span>
					</div>
				</ModalHeader>
				<ModalButtonContainer type="addParticipant">
					<Row>
						<Col span={24}>
							<div className="chatSearch" style={styles.positionRelative}>
								<input
									type="text"
									placeholder="Search"
									onChange={(e) => {
										this.setState({ searchString: e.target.value })
									}}
									value={searchString}
								/>
								<img
									src={require('./../images/searchicon.png')}
									alt={''}
									style={styles.searchIcon}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Query query={GET_PEOPLE} variables={{ hash: hash }}>
							{({ loading, error, data }) => {
								if (loading) {
									return (
										<div style={styles.fullWidth}>
											<div>
												<div style={styles.centerAlign}>
													<Spin indicator={<Icon type="loading" spin />} />
												</div>
											</div>
										</div>
									)
								}
								if (!data || !data.getPeople.length) {
									return (
										<div style={styles.fullWidth}>
											<div>
												<div style={styles.addParticipant.participantNotFound}>
													<span>No team members found.</span>
												</div>
											</div>
										</div>
									)
								}

								if (teamMembers.length === 0) {
									this.setState({ teamMembers: data.getPeople })
								}
								return (
									<div style={styles.addParticipant.listParticipantDiv}>
										<ul style={styles.addParticipant.unorderedListParticipant}>
											{filteredData.length ? (
												<>
													{filteredData.map((people) => {
														return (
															<li
																style={styles.addParticipant.addNewGroup}
																onClick={() => {
																	handleModalOk({ item: people, type: 'addParticipant' })
																}}
																key={people.hash}>
																<Avatar>{people.name[0]}</Avatar>
																<span
																	style={styles.addParticipant.participantName}
																	title={people.name}>
																	{people.name}
																</span>
															</li>
														)
													})}
												</>
											) : (
												<li style={styles.addParticipant.searchParticipantDiv}>
													<span style={styles.addParticipant.searchParticipant}>
														{'No members found'}
													</span>
												</li>
											)}
										</ul>
									</div>
								)
							}}
						</Query>
					</Row>
				</ModalButtonContainer>
			</Modal>
		)
	}
}
export default AddParticipant
