// Import statement
import gql from 'graphql-tag';

// Get proposal master scope data
export const getMasterScope = gql`
	query getMasterScope {
		getMasterScope {
			scopeDataHtml
			scopeDataJson
		}
	}
`;

// Insert proposal master scope data
export const insertProposalMasterScope = gql`
	mutation insertMasterScope($editorJson: String!, $editorHtml: String!) {
		insertMasterScope(editorJson: $editorJson, editorHtml: $editorHtml)
	}
`;
