import React from 'react'
import { GET_TASK_PROGRESS, ADD_FEEDBACK, GET_FEEDBACK_CHAT } from './query'
import { Spin, Icon, Tag, Input, notification, Avatar, Comment, Tooltip } from 'antd'
import styled from 'styled-components'
import { Query } from 'react-apollo'
import { Row, Col, Modal } from 'antd'
import { withApollo } from 'react-apollo'
import { MdMessage, MdSend } from 'react-icons/md'
import { formatRelativetimestamp } from './utils'
import { format, formatDistanceToNow, startOfDay, endOfDay } from 'date-fns' /// Date FNS for time format functions
const { TextArea } = Input

const defaultState = {
	modalVisible: false,
	modalIndex: 0,
	modalPosition: 0,
	headName: null,
	taskId: null,
	taskName: null,
	taskDescription: null,
	feedbackChatList: [],
	loadCount: 0,
	lazyLoad: false,
}

const Root = styled.div`
	background: rgb(255, 255, 255);
	margin: 0px;
	height: 85vh;
	overflow: scroll;

	.headName {
		padding: 2%;
		font-size: 170%;
		color: rgb(80, 80, 80);
		font-weight: bold;
	}

	.taskTitle {
		margin: 2%;
		margin-left: 4%;
	}

	.taskUser {
		color: #1890ff;
		font-size: 19px;
	}

	.taskList {
		margin: 2%;
		padding: 1%;
	}

	.taskList:hover {
		background: #b0c4ce36;
	}

	.taskDuration {
		margin: 2%;
		margin-bottom: 7%;
	}

	.taskList:hover .feedBack {
		display: inline-block;
	}

	.loaderIcon {
		margin-left: 50%;
	}

	.feedBack {
		display: none;
		cursor: pointer;
		color: rgb(255, 255, 255);
		background: rgb(20, 126, 224);
		border-radius: 10px;
		padding: 2px 10px;
		margin: 0px 5px;
	}

	.workingOnline {
		color: #66c964;
		font-weight: bold;
		font-size: 19px;
		margin-left: 10px;
	}

	.workingOffline {
		color: #ba4c4c;
		font-weight: bold;
		font-size: 19px;
		margin-left: 10px;
	}

	.taskHeading {
		color: #505050;
	}

	.taskProgress {
		color: #7cc6be;
	}

	.taskFile {
		color: #395aca;
	}
`

class DayPlannerProgress extends React.Component {
	constructor(props) {
		super(props)
		this.state = defaultState
	}

	addTaskFeedback = async ({ taskId, taskName, position, index }) => {
		const { client } = this.props

		const feedbackResult = await client.query({
			query: GET_FEEDBACK_CHAT,
			variables: {
				taskId,
			},
		})

		this.setState({
			taskId,
			taskName,
			modalPosition: position,
			modalIndex: index,
			feedbackChatList: feedbackResult.data.getFeedbackChat,
		})
	}

	addFeedback = ({ target: { value } }) => {
		this.setState({ taskDescription: value })
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			const { taskDescription } = this.state
			if (taskDescription !== null) {
				this.addFeedbackDescription()
			}
		}
	}

	handleScroll = (e, fetchMore, data, taskEnd, teamCount) => {
		const element = e.target
		if (element.scrollHeight - element.scrollTop === element.clientHeight && !taskEnd) {
			const loadCount = Number(data.length / teamCount)
			const time = new Date(Date.now() - loadCount * 24 * 60 * 60 * 1000)
			const startTime = startOfDay(time).getTime() / 1000
			const endTime = parseInt(endOfDay(time).getTime() / 1000)
			this.setState({ lazyLoad: true })
			fetchMore({
				variables: {
					startTime,
					endTime,
					loadCount: data.length,
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev
					this.setState({ lazyLoad: false })
					return {
						getTaskProgress: {
							__typename: 'taskProgressData',
							headName: fetchMoreResult.getTaskProgress.headName,
							teamCount: fetchMoreResult.getTaskProgress.teamCount,
							taskEnd: fetchMoreResult.getTaskProgress.taskEnd,
							taskProgress: [
								...prev.getTaskProgress.taskProgress,
								...fetchMoreResult.getTaskProgress.taskProgress,
							],
						},
					}
				},
			})
		}
	}

	addFeedbackDescription = async () => {
		const { taskId, taskDescription } = this.state
		const { client } = this.props

		const feedbackResult = await client.mutate({
			mutation: ADD_FEEDBACK,
			variables: {
				taskId,
				taskDescription,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_FEEDBACK_CHAT,
					variables: {
						taskId,
					},
				},
			],
		})

		if (feedbackResult.data.addTaskFeedback) {
			const feedbackResult = await client.query({
				query: GET_FEEDBACK_CHAT,
				variables: {
					taskId,
				},
			})

			this.setState({
				feedbackChatList: feedbackResult.data.getFeedbackChat,
				taskDescription: null,
			})
		}
	}

	render() {
		const { feedbackChatList, loadCount, lazyLoad } = this.state
		const time = new Date(Date.now() - loadCount * 24 * 60 * 60 * 1000)
		const startTime = startOfDay(time).getTime() / 1000
		const endTime = parseInt(endOfDay(time).getTime() / 1000)

		return (
			<Query query={GET_TASK_PROGRESS} variables={{ startTime, endTime, loadCount }}>
				{({ data, loading, fetchMore }) => {
					if (loading) {
						return (
							// Spinner icon
							<Root>
								<Spin
									indicator={<Icon type="loading" className="iconSize" spin />}
									className="loaderIcon"
								/>
							</Root>
						)
					}
					if (!data) {
						return null
					}

					const { getTaskProgress } = data
					const { taskProgress, headName, teamCount, taskEnd } = getTaskProgress

					return (
						<Root
							onScroll={(e) =>
								this.handleScroll(e, fetchMore, taskProgress, taskEnd, teamCount)
							}>
							<h1 className="headName">{`Hello ${headName},`}</h1>
							<div className="taskDuration">
								{taskProgress.length ? (
									<div>
										{taskProgress.map((taskData) => {
											const { value, position: taskPosition, progressStatus } = taskData
											const uniqueList = `list${taskPosition}`
											const time = parseInt(
												new Date(
													Date.now() - taskPosition * 24 * 60 * 60 * 1000
												).getTime() / 1000
											)
											return (
												<div key={uniqueList}>
													<h1 style={{ fontWeight: 'bold', marginLeft: '17px' }}>
														{progressStatus === true
															? taskPosition === 0
																? 'Today'
																: formatRelativetimestamp(time)
															: null}
													</h1>

													{value.map((data, position) => {
														const {
															userName,
															plannedHours,
															taskLists,
															workingStatus,
														} = data
														const plannedHour = Math.floor(parseInt(plannedHours) / 60)
														const plannedMinute = parseInt(plannedHours) % 60
														return (
															<>
																{taskLists.length ? (
																	<div className="taskTitle">
																		<Row>
																			<Col span={12} className="taskUser">
																				{userName} (
																				{plannedHour !== 0
																					? `${plannedHour}${
																							plannedHour > 1 ? ' hours' : ' hour'
																					  }`
																					: null}
																				{plannedMinute !== 0
																					? ` ${plannedMinute}${
																							plannedMinute > 1 ? ' minutes' : ' minute'
																					  }`
																					: null}
																				)
																			</Col>
																			<Col
																				span={6}
																				className={
																					workingStatus === 'start'
																						? 'workingOnline'
																						: 'workingOffline'
																				}>
																				{taskPosition === 0
																					? workingStatus === 'start'
																						? 'Working Now'
																						: 'Offline'
																					: null}
																				{}
																			</Col>
																		</Row>

																		{taskLists.map((value, index) => {
																			const {
																				taskId,
																				taskName,
																				taskNotes,
																				taskPercentage,
																				attachment,
																				taskImages,
																				addedAt,
																				taskDuration,
																				projectName,
																				feedbackCount,
																			} = value

																			const taskHours = Math.floor(
																				parseInt(taskDuration) / 60
																			)
																			const taskMinutes = parseInt(taskDuration) % 60
																			const uniqueTask = `task${index}`
																			return (
																				<div className="taskList" key={uniqueTask}>
																					<Row style={{ fontSize: '17px' }}>
																						<Col span={12}>
																							<Row>
																								<Col span={24} className="taskHeading">
																									{projectName} - {taskName} (
																									{taskHours !== 0
																										? `Estimated ${taskHours}${
																												taskHours > 1 ? 'hours' : 'hour'
																										  }`
																										: null}
																									{taskMinutes !== 0
																										? `${
																												taskHours !== 0 ? '' : 'Estimated'
																										  } ${taskMinutes}${
																												taskMinutes > 1
																													? 'minutes'
																													: 'minute'
																										  }`
																										: null}
																									)
																								</Col>
																							</Row>
																							<Row>
																								<Col span={24}>{taskNotes}</Col>
																							</Row>
																							<Row>
																								<Col span={17} className="taskProgress">
																									{taskPercentage !== 0
																										? `${taskPercentage}% Progressed, last update at ${format(
																												new Date(addedAt * 1000),
																												'h:mm a'
																										  )}`
																										: null}
																								</Col>
																								<Col span={7} className="taskFile">
																									{attachment !== '' ? (
																										<a
																											href={attachment}
																											target="_blank"
																											style={{
																												textDecoration: 'underline',
																											}}>
																											View attachment
																										</a>
																									) : null}
																								</Col>
																							</Row>
																						</Col>
																						<Col span={5}>
																							<Row>
																								{taskImages !== null
																									? taskImages.map((imageSrc, index) => {
																											const uniqueImage = `image${index}`
																											return (
																												<Col
																													span={2}
																													offset={2}
																													key={uniqueImage}>
																													<a
																														href={imageSrc}
																														target="_blank">
																														<img
																															src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png"
																															width="25"
																															height="25"
																														/>
																													</a>
																												</Col>
																											)
																									  })
																									: null}
																							</Row>
																						</Col>

																						<Col span={4} offset={1}>
																							<Tag
																								className="feedBack"
																								onClick={() => {
																									this.setState({
																										modalVisible: true,
																									})
																									this.addTaskFeedback({
																										taskId,
																										taskName,
																										position,
																										index,
																									})
																								}}>
																								<MdMessage
																									style={{
																										position: 'absolute',
																										fontSize: '20px',
																									}}
																								/>
																								<span
																									style={{
																										marginLeft: '25px',
																									}}>{`Feedback${
																									feedbackCount !== 0 &&
																									feedbackCount !== null
																										? `(${feedbackCount})`
																										: ''
																								}`}</span>
																							</Tag>
																						</Col>
																					</Row>
																				</div>
																			)
																		})}
																	</div>
																) : null}
															</>
														)
													})}
												</div>
											)
										})}
										{lazyLoad ? (
											<Spin
												indicator={<Icon type="loading" className="iconSize" spin />}
												className="loaderIcon"
											/>
										) : null}
										{taskEnd ? (
											<div style={{ textAlign: 'center' }}>
												<span>No more task :)</span>
											</div>
										) : null}
									</div>
								) : (
									<div>
										<h1 style={{ textAlign: 'center' }}>No tasks available.</h1>
									</div>
								)}
							</div>
							<Modal
								title={this.state.taskName}
								centered
								style={{ marginTop: '25px' }}
								onCancel={() => {
									this.setState({ modalVisible: false })
								}}
								footer={[]}
								visible={this.state.modalVisible}>
								<div>
									<div style={{ height: '300px', overflow: 'scroll' }}>
										{feedbackChatList.length ? (
											feedbackChatList.map((chat, index) => {
												const { addedBy, addedAt, profileUrl, description } = chat
												const uniqueFeedback = `feedback${index}`
												return (
													<Comment
														key={uniqueFeedback}
														author={<a>{addedBy}</a>}
														avatar={
															profileUrl !== null ? (
																<Avatar src={profileUrl} alt={addedBy} />
															) : (
																<Avatar
																	style={{
																		color: '#f56a00',
																		backgroundColor: '#fde3cf',
																	}}>
																	{addedBy[0]}
																</Avatar>
															)
														}
														content={<p>{description}</p>}
														datetime={
															<Tooltip
																title={format(
																	new Date(addedAt * 1000),
																	'MMM dd, yyyy - h:mm a'
																)}>
																<span>
																	{formatDistanceToNow(new Date(addedAt * 1000), {
																		addSuffix: true,
																	})}
																</span>
															</Tooltip>
														}
													/>
												)
											})
										) : (
											<span style={{ margin: '33%' }}>No feedback found.</span>
										)}
									</div>
									<div style={{ display: 'flex' }}>
										<TextArea
											value={this.state.taskDescription}
											onChange={this.addFeedback}
											placeholder="Enter Task Feedback"
											autoSize={{ minRows: 3, maxRows: 5 }}
											onKeyPress={this.handleKeyPress}
										/>
										<MdSend
											style={{
												fontSize: '40px',
												margin: '20px',
												color: '#198efb',
												borderRadius: '10px',
												cursor: 'pointer',
											}}
											onClick={() => {
												const { taskDescription } = this.state
												if (taskDescription !== null) {
													this.addFeedbackDescription()
												}
											}}
										/>
									</div>
								</div>
							</Modal>
						</Root>
					)
				}}
			</Query>
		)
	}
}

export default withApollo(DayPlannerProgress)
