const styles = {
	/**
	 * This styles is used to add a render fuction css.
	 */
	/**
	 * open client Ticket section
	 */
	// loading icon css
	loadingIcon: { fontSize: 50 },
	loadIconStyle: { padding: '30px 400px' },

	openClientTicket: {
    width: '50vw',
    marginBottom: '2%',
	},
	// openClientTicketCount
	openClientTicketCount: {
		fontSize: '18px',
		fontWeight: '500',
		padding: '0% 1% 1%',
	},
	openClient: {
		display: 'flex',
		width: '50vw',
		flexDirection: 'row',
		flex: 1,
		marginBottom: '2%',
		padding: '1%',
		backgroundColor: '#f5f5f5',
		// backgroundColor:'#f5f5f5',
		// backgroundColor: 'white',
		borderRadius: '5px',
    cursor: 'pointer',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 'thin',
	},
	// image part
	imagePart: {
		width: '22%',
		position: 'relative',
		display: 'inline-block',
		borderRadius: '5px',
	},
	// poster name
	posterName: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row-reverse',
		left: 0,
		right: '2px',
		height: '20px',
		top: '1px',
		color: '#fff',
		paddingRight: '3px',
		fontSize: 'smaller',
	},
	// image display
	Images: {
		cursor: 'pointer',
		objectFit: 'cover',
		maxHeight: '6.50rem',
		minHeight: '6.50rem',
		maxWidth: '9.05rem',
		minWidth: '9.05rem',
		borderRadius: '5px',
	},
	// comment icon without background display
	commentIconWithoutBackgroud: {
		position: 'absolute',
		right: '0px',
		height: '20px',
		bottom: '6px',
		color: '#fff',
		marginRight: '4px',
	},

	// content part
	contentPart: {
		width: '80%',
		paddingLeft: '2%',
	},
	// parent comment part
	parentComment: {
		paddingBottom: '5%',
	},
	profile: {
		paddingBottom: '1%',
	},
	profileName: {
		fontSize: '14px',
		fontWeight: '600',
		paddingBottom: '1%',
	},
	profileTime: {
		float: 'right',
		fontSize: '14px',
		color: 'rgba(0, 0, 0, 0.45)',
	},
	ProfileText: {
    fontSize: '14px',
    display: 'block',
		display: '-webkit-box',
		maxWidth: '100%',
		height: '29px',
		margin: '0 auto',
		lineHeight: '1',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	// child comment part
	// childComment: {
	// 	color: 'gray',
	// },
	childProfile: {
		paddingBottom: '0%',
	},
	childProfileName: {
		color: 'gray',
		fontSize: '12px',
		// fontWeight: '700',
		paddingBottom: '1%',
	},
	childProfileTime: {
		color: 'gray',
		float: 'right',
		fontSize: '12px',
		// color: 'rgba(0, 0, 0, 0.45)',
	},
	childProfileText: {
		color: 'gray',
		fontSize: '12px',
		// display: 'block',
		// display: '-webkit-box',
		// maxWidth: '100%',
		// height: '29px',
		// margin: '0 auto',
		// lineHeight: '1',
		// WebkitLineClamp: '1',
		// WebkitBoxOrient: 'vertical',
		// overflow: 'hidden',
		// textOverflow: 'ellipsis',
	},

	// show more button
	ticketShow: {
		textDecoration: 'underline',
		textAlign: 'center',
		color: '#40a9ff',
		cursor: 'pointer',
		fontSize: '16px',
		padding: '0 1% 1%',
	},
	// no more text
	noTicketShow: {
		fontSize: '14px',
		textAlign: 'center',
	},

	/**
	 * This styles is used to add a modal fuction css.
	 */

	modalBox: {
		height: '86vh',
		width: '70vw',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	// modal image display
	modalImageBox: {
		width: '50%',
		backgroundColor: 'black',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
	},
	// image background same image
	modalImageLight: {
		backgroundSize: 'cover',
		WebkitFilter: 'blur(13px)',
		MozFilter: 'blur(13px)',
		OFilter: 'blur(13px)',
		MsFilter: 'blur(13px)',
		filter: 'blur(3px)',
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		width: '100%',
		height: '100%',
	},
	// image blur div
	modalImageBlur: {
		backgroundColor: '#00000080',
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		width: '100%',
		height: '100%',
	},
	// modal image
	ticketImage: {
		maxHeight: '100%',
		maxWidth: '100%',
		position: 'absolute',
		zIndex: '9999',
		webkitFilter: 'blur(0px)',
		mozFilter: 'blur(0px)',
		oFilter: 'blur(0px)',
		msFilter: 'blur(13px)',
		filter: 'blur(0px)',
	},

	// modal detail
	modalDetail: {
		padding: '10px 10px 10px 10px',
		width: '50%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
	},
	// profile head section css
	profileHead: {
		flex: '0.1',
	},
	profileSection: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	profileNames: {
		width: '100%',
		display: 'flex',
	},
	// modal profile picture
	modalProfilePic: {
		borderRadius: '50%',
		marginRight: '1%',
		marginBottom: '1%',
	},
	// user profile picture no
	modalProfilePicNo: {
		borderRadius: '50%',
		marginRight: '1%',
		marginBottom: '1%',
		backgroundColor: 'rgba(107, 107, 189, 0.45)',
		padding: '3px',
		fontSize: '12px',
		width: '25px',
		height: '25px',
		textAlign: 'center',
	},
	// modal name
	modalName: { fontWeight: '700', fontSize: '16px', marginTop: '1px' },
	// modal project
	modalProject: {
		fontSize: '12px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 1,
		WebkitBoxOrient: 'vertical',
		marginTop: '2px',
	},
	// project name display
	projectNames: {
		fontWeight: '600',
		fontSize: '14px',
		color: '#138ec7',
		fontStyle: 'italic',
	},
	// modal time
	modalTime: {
		width: '100%',
		color: '#90949c',
		fontSize: '12px',
	},
	modalPostSpace: {
		textDecoration: 'underline',
	},
	// project name
	projectName: { fontWeight: '600', fontSize: '14px' },
	// edit section
	editBlock: {
		display: 'flex',
		padding: '5px 5px 9px 5px',
	},
	editBlockIcon: {
		width: '90%',
		marginBottom: '0px',
	},
	editBlockButton: {
		marginTop: '-5px',
	},
	// comment section div
	commentDisplay: {
		backgroundColor: 'rgb(235, 235, 235)',
		// boxShadow: '0 0 4px 0px #ada9a9',
		overflowY: 'scroll',
		overflowX: 'hidden',
		borderRadius: '4px',
		marginBottom: '10px',
		flex: '0.85',
		marginLeft: '-10px',
		marginRight: '-10px',
	},
	// no ticket display
	noTicket: {
		display: 'flex',
		justifyContent: 'center',
		padding: '25px',
	},
	// show closed disscussion button
	showClosedDiscussion: {
		textDecoration: 'underline',
		textAlign: 'center',
		color: ',#2c8247',
		fontWeight: '700',
		marginBottom: '10px',
		marginTop: '15px',
		cursor: 'pointer',
	},
	// add comment section
	commentAdd: {
		display: 'flex',
		flex: '0.05',
		cursor: 'pointer',
	},
	sendButton: {
		marginLeft: '5px',
		fontSize: '20px',
	},
	sendIcon: {
		marginTop: '5px',
		marginLeft: '3px',
	},
	modalClose: {
		height: 50,
		width: 50,
		position: 'absolute',
		top: 14,
		right: -14,
		zIndex: 9999,
	},
	closeIcon: { fontSize: 'inherit', color: '#0c0c0c' },

	/**
	 * This styles is used to add a comment display fuction css.
	 */
	openDiscussion: {
		color: 'rgb(60, 60, 60)',
	},
	lightBlue: {
		backgroundColor: 'rgb(183, 223, 243)',
	},
	lightYellow: {
		backgroundColor: '#ffffc9',
	},
	white: {
		backgroundColor: '#fafafa',
	},
	darkYellow: {
		backgroundColor: '#FFFF96',
	},
	parentComments: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '2%',
	},
	// comment align
	right: {
		justifyContent: 'flex-end',
	},
	left: {
		justifyContent: 'flex-start',
	},
	// only for parent comment
	parent: {
		width: '20vw',
	},
	//  Comment Right side display
	commentRight: {
		padding: '2px 11px 5px 10px',
		maxWidth: '20vw',
		borderRadius: '5px',
		marginRight: '6px',
		marginBottom: '10px',
	},
	//  comment left side display
	commentLeft: {
		padding: '2px 11px 5px 10px',
		maxWidth: '20vw',
		borderRadius: '5px',
		marginLeft: '10px',
		marginBottom: '10px',
	},
	// parent comment profile pic div
	profilePic: {
		display: 'flex',
	},
	// comment name display
	commentNameParent: {
		fontSize: '11px',
		fontWeight: '700',
		marginLeft: '7px',
		marginTop: '2px',
	},
	// commeted date display
	commentDate: {
		fontSize: '10px',
		fontWeight: '600',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingLeft: '15px',
		color: 'rgba(0,0,0,.45)',
	},
	commentDateParent: {
		float: 'right',
	},
	commentText: {
		color: '#303030',
		fontSize: '12px',
	},
	// Thums up image display
	rightThumbs: {
		display: 'block',
		float: 'right',
		padding: '2px 17px 5px 10px',
	},
	// team chat image
	teamChatImage: {
		marginLeft: '-7%',
	},
	commentName: {
		fontSize: '11px',
		fontWeight: '700',
		marginTop: '2px',
	},
	// comment log
	commentLog: {
		display: 'flex',
		padding: '2px 11px 5px 10px',
	},
	// comment log text
	logText: {
		fontSize: '12px',
		color: '#aaa',
	},
}

export default styles
