import React from 'react'
import { Avatar } from 'antd'
import Attach from './attach'
import { Menu } from './menu'
import styles from './styles'
import { GET_LAST_SEEN } from './../query'
import { withApollo } from 'react-apollo'
import { formatRelativetimestamp } from './../utils'

class ChatHeader extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lastSeen: null,
			messageType: null,
		}
	}
	showImagePreview = (data) => {
		const { showImagePreview } = this.props
		showImagePreview(data)
	}

	async componentDidMount() {
		const { client, groupId, hash, selectedContact } = this.props
		const { type } = selectedContact
		const { data } = await client.mutate({
			mutation: GET_LAST_SEEN,
			variables: {
				groupId,
				hash,
			},
		})

		const { getLastSeen } = data
		this.setState({
			lastSeen:
				getLastSeen === 'online'
					? 'online'
					: getLastSeen === ''
					? ''
					: `last seen at ${formatRelativetimestamp(getLastSeen)}`,
			messageType: type,
		})
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedContact.id !== this.props.selectedContact.id) {
			const { client, groupId, hash, selectedContact } = this.props
			const { type } = selectedContact
			const { data } = await client.mutate({
				mutation: GET_LAST_SEEN,
				variables: {
					groupId,
					hash,
				},
			})

			const { getLastSeen } = data
			this.setState({
				lastSeen:
					getLastSeen === 'online'
						? 'online'
						: getLastSeen === ''
						? ''
						: `last seen at ${formatRelativetimestamp(getLastSeen)}`,
				messageType: type,
			})
		}
	}

	render() {
		const { lastSeen, messageType } = this.state
		const { selectedContact } = this.props
		const { id, photo, name, type } = selectedContact
		const labelsAndContext = {
			'Group Info':
				type !== 'individual'
					? (_) => {
							const { showModalDialogPopup } = this.props
							showModalDialogPopup({ selectedContact, type: 'editGroupInfo' })
					  }
					: undefined,
			'Contact Info':
				type === 'individual'
					? (_) => {
							const { showModalDialogPopup } = this.props
							showModalDialogPopup({ selectedContact, type: 'editGroupInfo' })
					  }
					: undefined,
		}

		Object.keys(labelsAndContext).forEach((key) =>
			labelsAndContext[key] === undefined ? delete labelsAndContext[key] : {}
		)

		return (
			<div className="chatHeader" style={{ cursor: 'pointer' }}>
				<header key={id}>
					<div
						className="chatHeaderPhoto"
						onClick={() => {
							const { showModalDialogPopup } = this.props
							showModalDialogPopup({
								selectedContact,
								type: 'editGroupInfo',
							})
						}}>
						{photo ? (
							<img src={photo} style={styles.profilePic} alt={'Profile picture'} />
						) : (
							<Avatar size="large" style={styles.profileAvatarPic}>
								{name.slice(0, 1).toUpperCase()}
							</Avatar>
						)}

						<div>
							<div style={styles.profileName}>{name}</div>
							{messageType === 'individual' ? (
								<label style={styles.fontSize}>{lastSeen}</label>
							) : null}
						</div>
					</div>

					<div style={styles.profileNavbar}>
						<div title={'Attach'}>
							<Attach showImagePreview={this.showImagePreview} title={'Attach'} />
						</div>

						<div title={'Menu'}>
							<Menu labelsAndContext={labelsAndContext} title={'Menu'} />
						</div>
					</div>
				</header>
			</div>
		)
	}
}

export default withApollo(ChatHeader)
