import { format, formatDistanceToNow, fromUnixTime } from 'date-fns'

/**this function is used to replace the time in our required format */
export function truncateTime(time) {
	return time
		.replace(/half a minute/g, '30 secs')
		.replace(/less than\s/g, '')
		.replace(/less than a\s/g, ' 1')
		.replace(/\sseconds/g, ' secs')
		.replace(/\ssecond/g, ' sec')
		.replace(/\sminutes/g, ' mins')
		.replace(/\sminute/g, ' min')
		.replace(/\sdays/g, ' d')
		.replace(/\sday/g, ' d')
		.replace(/\shours/g, ' hrs')
		.replace(/\shour/g, ' hr')
		.replace(/\smonths/g, ' mo')
		.replace(/\smonth/g, ' mo')
		.replace(/\syears/g, ' yrs')
		.replace(/\syear/g, ' yr')
		.replace(/about\s/g, '')
		.replace(/over\s/g, '')
		.replace(/almost\s/g, '')
}

/**fucntion to get relative time */
export function relativeTime(timestamp, addSuffix = false) {
	if (typeof timestamp === 'string') {
		timestamp = Number(timestamp)
	}
	if (typeof timestamp !== 'number') {
		return timestamp
	}
	return formatDistanceToNow(new Date(timestamp * 1000), {
		addSuffix,
		includeSeconds: true,
	})
}

/**this function is used to convert the timestamp into relative time */
export function formatRelativetimestamp(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	const getYear = format(fromUnixTime(timestamp), 'yy')
	const currentYear = format(new Date(), 'yy')
	if (
		relativeTimeValue.includes('day') ||
		relativeTimeValue.includes('year') ||
		relativeTimeValue.includes('month')
	) {
		if (getYear === currentYear) {
			return format(fromUnixTime(timestamp), 'dd-MMM-yy')
		} else {
			return format(fromUnixTime(timestamp), 'dd-MMM-yy')
		}
	} else {
		return `${truncateTime(relativeTimeValue)} ago`
	}
}
