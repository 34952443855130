/**
    Table data Component 
    And Display in table formate & editable columns.
    And download as excel format.
* */

/*  Import Components  */
import React from 'react'
/* Import required components from ANT DESIGN */
import { Input, Button, Select, Divider } from 'antd'

// Query component of React Apollo to fetch data
import { Query, Mutation, withApollo } from 'react-apollo'

/*  Import file-saver Components  */
import * as FileSaver from 'file-saver'
/*  Import xlsx Components  */
import * as XLSX from 'xlsx'

/* Import Override CSS */
import '../index.css'

/* Import Custom Styles */

import styles from '../styles'
/* Import Antd CSS */

import 'antd/dist/antd.css'

// Query to fetch Site Photos Details
import { GET_DATA } from '../query'

// deconstruct option from select dropdown
const { Option } = Select

const fileType =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

/**
 * This class component is used to displayed it as table formate ,download those table.
 * @returns {project archived component}
 */
class TableData extends React.Component {
	/* initial states */
	state = {
		exportArray: null,
		colorDropdowns: [],
	}

	componentDidUpdate(prevProps) {
		const { sketchUpArray } = this.props
		if (prevProps.sketchUpArray !== sketchUpArray) {
			this.setState({ exportArray: null })
		}
	}

	/* This functio render header data */
	renderTableHeader = () => {
		// Deconstructing the state
		const { exportArray } = this.state

		const header = Object.keys(exportArray[0])
		return header.map((key, index) => {
			if (key !== '__typename') {
				return <th key={index}>{key.toUpperCase().replace('_', ' ')}</th>
			}
			return null
		})
	}

	/* input change */
	handleChange = (index, dataType, value) => {
		const { exportArray } = this.state

		const newState = exportArray.map((item, i) => {
			if (i === index) {
				return { ...item, [dataType]: value }
			}
			return item
		})

		this.setState({
			exportArray: newState,
		})
	}

	/* input change */
	handleChanges = (index, value) => {
		const { exportArray } = this.state

		const newState = exportArray.map((item, i) => {
			if (i === index) {
				return { ...item, EDGE_BAND: value }
			}
			return item
		})

		this.setState({
			exportArray: newState,
		})
	}

	extColorChanges = (index, value) => {
		const { exportArray } = this.state

		const newState = exportArray.map((item, i) => {
			if (i === index) {
				return { ...item, EXT_COLOR: value }
			}
			return item
		})

		this.setState({
			exportArray: newState,
		})
	}

	intColorChanges = (index, value) => {
		const { exportArray } = this.state

		const newState = exportArray.map((item, i) => {
			if (i === index) {
				return { ...item, INT_COLOR: value }
			}
			return item
		})

		this.setState({
			exportArray: newState,
		})
	}

	/* This functio render row data */
	renderTableData = () => {
		/* Deconstructing the state */
		const { exportArray, colorDropdowns } = this.state

		// console.log(exportArray)
		return exportArray.map((data, index) => {
			return (
				<tr key={data.sno}>
					<td>{data.sno}</td>
					<td>{data.CABINET_NAME}</td>
					<td>{data.PANEL_TYPE}</td>
					<td>{data.FACTORY_LABEL}</td>
					<td>{data.PANEL_NAME}</td>
					<td>{data.THICKNESS}</td>
					<td>{data.LENGTH_1}</td>
					<td>{data.LENGTH_2}</td>
					<td>{data.EXT_COLOR}</td>
					<td>{data.INT_COLOR}</td>

					<td>{data.EDGE_BAND}</td>
					<td>{data.REMARKS_1}</td>
					<td>{data.REMARKS_2}</td>
					<td>{data.GRAINS_DIRECTION}</td>
				</tr>
			)
		})
	}

	/**
	 * This function is used to download excel format.
	 * @returns { SketchUp}
	 */
	exportToCSV = (csvData, fileName) => {
		const ws = XLSX.utils.json_to_sheet(csvData)
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
		const data = new Blob([excelBuffer], { type: fileType })
		FileSaver.saveAs(data, fileName + fileExtension)
	}

	/**
	 * It should be excecute first
	 * This is used for upload file and display it as editable table.
	 * @returns {SketchUp}
	 */
	/**
	 * This function is used to display it as editable table ui.
	 * @returns { SketchUp}
	 */

	render() {
		/* Deconstructing the props  */
		const { sketchUpArray } = this.props

		/* Deconstructing the state  */
		const { exportArray } = this.state
		// console.log(colorDropdowns)
		// console.log(exportArray)

		if (exportArray === null) {
			this.setState({
				exportArray: sketchUpArray,
			})
		}

		return (
			<>
				{exportArray !== null ? (
					<div style={styles.tableDiv}>
						<Divider />
						<div style={styles.title} id="title">
							Clicked File Content :)
						</div>

						<table style={styles.tableContent} id="tableContent">
							<tbody>
								<tr>{this.renderTableHeader()}</tr>
								{this.renderTableData()}
							</tbody>
						</table>
					</div>
				) : null}
			</>
		)
	}
}

export default TableData
