import gql from 'graphql-tag'

export const GET_DATA_QUERY = gql`
	query GetData {
		getProfileData {
			profileName
			taskCount
		}

		getUpcomingTask {
			taskDetails {
				taskId
				taskName
				taskDate
				taskDuration
			}
			taskCount
			taskTotalHours
		}

		getCompletedWork {
			completedTaskDetails {
				taskId
				taskName
				projectName
				percentage
				startTime
				endTime
				taskNotes
				taskStatus
			}
			taskCount
			taskTotalHours
		}
	}
`

export const GET_PROFILE_DATA_QUERY = gql`
	query GetProfileData {
		getProfileData {
			profileName
			taskCount
		}
	}
`

export const GET_UPCOMING_TASK_QUERY = gql`
	query GetUpcomingTask {
		getUpcomingTask {
			taskDetails {
				taskId
				taskName
				taskDate
				taskDuration
				projectName
			}
			taskCount
			taskTotalHours
		}
	}
`

export const GET_PREVIOUS_TASK_QUERY = gql`
	query GetCompletedWork {
		getCompletedWork {
			completedTaskDetails {
				taskId
				taskName
				projectName
				percentage
				startTime
				endTime
				taskNotes
				taskStatus
				attachment
			}
			taskCount
			taskTotalHours
		}
	}
`

export const GET_SCREENSHOT_QUERY = gql`
	query GetScreenshot($limit: Int, $offset: Int, $type: String!, $taskId: String) {
		getScreenshot(limit: $limit, offset: $offset, type: $type, taskId: $taskId) {
			imageId
			imageUrl
			time
			isSelected
		}
	}
`

export const IS_SHARING_QUERY = gql`
	query IsSharing {
		isSharing
	}
`

export const ADD_NEW_TASK_MUTATION = gql`
	mutation AddNewTask(
		$taskDate: Float!
		$projectName: String!
		$taskName: String!
		$taskDuration: Int!
	) {
		addNewTask(
			taskDate: $taskDate
			projectName: $projectName
			taskName: $taskName
			taskDuration: $taskDuration
		)
	}
`

export const EDIT_UPCOMING_TASK_MUTATION = gql`
	mutation EditUpcomingTask(
		$taskId: String!
		$taskDate: Float!
		$projectName: String!
		$taskName: String!
		$taskDuration: Int!
	) {
		editUpcomingTask(
			taskId: $taskId
			taskDate: $taskDate
			projectName: $projectName
			taskName: $taskName
			taskDuration: $taskDuration
		)
	}
`

export const UPDATE_TASK_STATUS_MUTATION = gql`
	mutation UpdateTaskStatus(
		$taskId: String!
		$taskStatus: String!
		$percentage: Int!
		$notes: String!
		$startTime: Float!
		$endTime: Float!
		$screenshot: [screenshot]
		$files: [Upload!]!
		$filesDeleted: Boolean!
	) {
		updateTaskStatus(
			taskId: $taskId
			taskStatus: $taskStatus
			percentage: $percentage
			notes: $notes
			startTime: $startTime
			endTime: $endTime
			screenshot: $screenshot
			files: $files
			filesDeleted: $filesDeleted
		)
	}
`

export const CANCEL_TASK_MUTATION = gql`
	mutation CancelTask($taskId: String!) {
		cancelTask(taskId: $taskId)
	}
`
