const styles = {
	cardMain: { marginBottom: 12, borderRadius: 5, marginRight: 5 },
	cardHeader: {
		borderBottom: '1px solid #eee',
		paddingBottom: 6,
		marginBottom: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	cardDiv: {
		fontSize: 17,
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textTransform: 'capitalize',
	},
	cardBody: { fontSize: 14, color: '#BD3B36' },
	cardFooter: {
		borderTop: '1px solid #eee',
		color: '#848484',
		paddingTop: 6,
		marginTop: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	mobileAndNriVerified: {
		fontSize: 18,
		marginLeft: 8,
		marginBottom: -3,
		color: '#558749',
		float: 'right',
	},
	duplicatesCount: {
		fontSize: 18,
		marginLeft: 3,
		marginBottom: -3,
		color: '#607d8bba',
		float: 'right',
	},
	mobileAndNriNotVerified: {
		fontSize: 18,
		marginLeft: 8,
		marginBottom: -3,
		color: '#6f6f6f',
		float: 'right',
	},
	nriGlobe: {
		fontSize: 18,
		marginLeft: 6,
		marginBottom: -4,
		color: '#558749',
		float: 'right',
	},
	address: {
		color: '#868686',
		fontWeight: '500',
		textAlign: 'left',
		fontSize: 14,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	dateTime: {
		fontSize: 16,
		marginTop: 2,
		fontStyle: 'italic',
		color: '#7d7d7d',
		float: 'right',
	},
	contactNo: { fontWeight: 400, fontStyle: 'italic', float: 'right' },
	tag: { fontSize: 14 },
	tagContainer: { marginBottom: 5 },
	tagFloatRight: { fontSize: 14, float: 'right' },
	laneHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	laneDiv: {
		fontSize: 20,
		fontWeight: 'bold',
		textTransform: 'capitalize',
	},
	laneLabel: { width: '30%', textAlign: 'right', fontSize: 16 },
	chatDiv: {
		backgroundColor: '#EBEBEB',
		height: 500,
		overflowY: 'scroll',
		borderRadius: 5,
		marginBottom: 10,
	},
	modalListIcon: { fontSize: 20, color: '#7d7d7d' },
	activeFollowup: {
		fontSize: 18,
		marginLeft: 3,
		marginBottom: -3,
		color: '#ff5882aa',
		float: 'right',
	},
}

export default styles
