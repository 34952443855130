const styles = {
	/**
	 * This styles is used to add a render fuction css.
	 */
	// open team Ticket section

	// loading icon css
	loadingIcon: { fontSize: 50 },
	loadIconStyle: { padding: '30px 400px' },

	openTeamTicket: {
		width: '50vw',
		marginBottom: '2%',
	},
	// openTeamTicketCount
	openTeamTicketCount: {
		fontSize: '18px',
		fontWeight: '500',
		padding: '0% 1% 1%',
	},
	openTeam: {
		display: 'flex',
		width: '50vw',
		flexDirection: 'row',
		flex: 1,
		marginBottom: '2%',
		padding: '1%',
		backgroundColor: '#f5f5f5',
		// backgroundColor:'#e6ffe9',
		// backgroundColor: 'white',
		borderRadius: '5px',
    cursor: 'pointer',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 'thin',
	},
	// image part
	imagePart: {
		width: '22%',
		position: 'relative',
		display: 'inline-block',
		borderRadius: '5px',
	},
	// poster name
	posterName: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row-reverse',
		left: 0,
		right: '2px',
		height: '20px',
		top: '1px',
		color: '#fff',
		paddingRight: '3px',
		fontSize: 'smaller',
	},
	// image display
	Images: {
		cursor: 'pointer',
		objectFit: 'cover',
		maxHeight: '6.50rem',
		minHeight: '6.50rem',
		maxWidth: '9.05rem',
		minWidth: '9.05rem',
		borderRadius: '5px',
	},
	// comment icon without background display
	commentIconWithoutBackgroud: {
		position: 'absolute',
		right: '0px',
		height: '20px',
		bottom: '6px',
		color: '#fff',
		marginRight: '4px',
	},

	// content part
	contentPart: {
		width: '80%',
		paddingLeft: '2%',
	},
	// parent comment part
	parentComment: {
		paddingBottom: '5%',
	},
	profile: {
		paddingBottom: '1%',
	},
	profileName: {
		fontSize: '14px',
		fontWeight: '600',
		paddingBottom: '1%',
	},
	profileTime: {
		float: 'right',
		fontSize: '14px',
		color: 'rgba(0, 0, 0, 0.45)',
	},
	ProfileText: {
		fontSize: '14px',
		display: 'block',
		display: '-webkit-box',
		maxWidth: '100%',
		height: '29px',
		margin: '0 auto',
		lineHeight: '1',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	// child comment part
	// childComment: {
	// 	color: 'gray',
	// },
	childProfile: {
		paddingBottom: '0%',
	},
	childProfileName: {
		color: 'gray',
		fontSize: '12px',
		// fontWeight: '700',
		paddingBottom: '1%',
	},
	childProfileTime: {
		color: 'gray',
		float: 'right',
		fontSize: '12px',
		// color: 'rgba(0, 0, 0, 0.45)',
	},
	childProfileText: {
		color: 'gray',
    fontSize: '12px',
    // display: 'block',
		// display: '-webkit-box',
		// maxWidth: '100%',
		// height: '29px',
		// margin: '0 auto',
		// lineHeight: '1',
		// WebkitLineClamp: '1',
		// WebkitBoxOrient: 'vertical',
		// overflow: 'hidden',
		// textOverflow: 'ellipsis',
	},

	// show more button
	ticketShow: {
		textDecoration: 'underline',
		textAlign: 'center',
		color: '#40a9ff',
		cursor: 'pointer',
		fontSize: '16px',
		padding: '0 1% 1%',
	},
	// no more text
	noTicketShow: {
		fontSize: '14px',
		textAlign: 'center',
	},
}

export default styles
