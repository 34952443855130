/**
 * Developer : Sandhiya Malar
 */
/**
    project Archived Component
    This component will render listing projects using project url 
    User can Archived a project and rearchived.
* */

/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Divider, Input } from 'antd'
// Query component of React Apollo to fetch data
import { Query, Mutation, withApollo } from 'react-apollo'

// Import Override CSS
import './index.css'

// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'

import UnArchived from './UnArchived'
import Archived from './Archived'

/**
 * This class component is used to display project and Archived a project when not in use.
 * @returns {project archived component}
 */
class projectArchived extends React.Component {
	// initial states
	state = {
		searchString: '',
	}

	/**
	 * It should be excecute first
	 * This is used for display project.
	 * @returns {project listing}
	 */
	/**
	 * This function is used to display a project and Archived a projects.
	 * @returns {project Archived}
	 */

	render() {
		// Deconstructing the state
		const { searchString } = this.state

		return (
			<>
				<div className="chatSearch" style={styles.positionRelative}>
					<Input
						// type="text"
						// className="inputBox"
						placeholder="Search Projects"
						onChange={(e) => {
							this.setState({ searchString: e.target.value })
						}}
						value={searchString}
					/>
					<img
						src={require('./images/searchicon.png')}
						alt={''}
						style={styles.searchIcon}
					/>
				</div>
				<div style={styles.projectArchived}>
					<UnArchived searchString={searchString} />
					<Divider style={styles.dividerStyle} type="vertical" />
					<Archived searchString={searchString} />
				</div>
			</>
		)
	}
}

export default withApollo(projectArchived)
