// import react component
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Spin, Icon } from 'antd';
import { GET_MASTER_SCOPE_QUERY } from '../query';
import RCTree from './RCTree';
import './index.css';

//Service scope component
class ServiceScope extends Component {
  //service scope callback function
  setServiceScope = data => {
    this.props.setServiceScope(data);
  };

  render() {
    //Deconstruct props
    const {
      serviceScope,
      defaultScopeExpandedKeys,
      defaultScopeSelectedKeys,
      defaultScopeCheckedKeys,
    } = this.props;

    return (
      <div className={ 'serviceScope' }>
        {/**If service scope is null, fetch master scope from backend otherwise use already existing service scope*/}
        {serviceScope === null ? (
          //Fetch master scope data from backend using Query component
          <Query query={ GET_MASTER_SCOPE_QUERY }>
            {({ data, loading, error }) => {
              if (loading) {
                return (
                  //Spinner icon
                  <Spin
                    indicator={ <Icon type="loading" className={ 'iconSize' } spin /> }
                    className={ 'loaderIcon' }
                  />
                );
              }
              if (!data.getServiceMasterScope) {
                return null;
              }
              const { getServiceMasterScope } = data;
              // load service scope tree
              return (
                <RCTree
                  serviceScope={ getServiceMasterScope }
                  defaultExpandedKeys={ defaultScopeExpandedKeys }
                  defaultSelectedKeys={ defaultScopeSelectedKeys }
                  defaultCheckedKeys={ defaultScopeCheckedKeys }
                  setServiceScope={ this.setServiceScope }
                />
              );
            }}
          </Query>
        ) : (
          // load service scope tree
          <RCTree
            serviceScope={ serviceScope }
            defaultExpandedKeys={ defaultScopeExpandedKeys }
            defaultSelectedKeys={ defaultScopeSelectedKeys }
            defaultCheckedKeys={ defaultScopeCheckedKeys }
            setServiceScope={ this.setServiceScope }
          />
        )}
      </div>
    );
  }
}

//Declare proptypes
ServiceScope.propTypes = {
  defaultScopeCheckedKeys: PropTypes.array,
  serviceScope: PropTypes.array,
  defaultScopeSelectedKeys: PropTypes.array,
  defaultScopeExpandedKeys: PropTypes.array,
  setServiceScope: PropTypes.func,
};

//export ServiceScope component
export default ServiceScope;
