import gql from 'graphql-tag'

export const TEAM_HEAD_CHANGE_MUTATION = gql`
	mutation TeamHeadChange(
		$teamHeadEmail: String
		$enquiryId: String
		$enquiryStage: String
	) {
		teamHeadChange(
			teamHeadEmail: $teamHeadEmail
			enquiryId: $enquiryId
			enquiryStage: $enquiryStage
		) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
