import React from 'react'
import { MdClose, MdPersonAdd, MdCheck } from 'react-icons/md'
import { FaCamera } from 'react-icons/fa'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { Row, Col, Avatar, Spin, Icon } from 'antd'
import { Query } from 'react-apollo'
import {
	LIST_GROUP_MEMBER,
	MAKE_GROUP_ADMIN,
	REMOVE_GROUP_MEMBER,
	GET_GROUP_DESCRIPTION,
	GET_MEDIA,
	COMMAN_GROUP,
} from '../query'
import { Mutation, withApollo } from 'react-apollo'
import Loader from 'react-loader-spinner'
import MessageDropdown from '../ChatBody/MessageDropdown'
import styles from './styles'
import Lightbox from 'react-image-lightbox'

class EditGroupInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			groupName: this.props.selectedContact.name,
			groupDescription: '',
			imageUri: this.props.selectedContact.photo,
			file: [],
			memberHash: null,
			iconStatus: false,
			groupDescriptionEditStatus: false,
			groupNameEditStatus: false,
			media: false,
			groupId: this.props.selectedContact.id,
			mediaImage: [],
			mediaIndex: 0,
			imageShow: false,
			editType: this.props.selectedContact.type,
		}
	}

	filePreview = () => (event) => {
		const file = event.target.files
		var reader = new FileReader()
		reader.onloadend = () => {
			this.setState({ imageUri: reader.result, groupPicture: file })
		}
		reader.readAsDataURL(file[0])
	}

	async componentDidMount() {
		const { client, selectedContact } = this.props
		const { data } = await client.mutate({
			mutation: GET_GROUP_DESCRIPTION,
			variables: {
				groupId: selectedContact.id,
			},
		})
		this.setState({ groupDescription: data.groupDesc })
	}

	render() {
		const {
			hash,
			handleModalOk,
			handleModalCancel,
			selectedContact,
			showModalDialogPopup,
			loader,
		} = this.props
		const { id: groupId } = selectedContact
		const {
			groupName,
			groupDescription,
			imageUri,
			iconStatus,
			memberHash,
			groupNameEditStatus,
			groupDescriptionEditStatus,
			mediaImage,
			editType,
			mediaIndex,
		} = this.state

		return (
			<>
				<Mutation mutation={MAKE_GROUP_ADMIN}>
					{(makeGroupAdmin, { data, loading }) => (
						<Modal type="editGroupInfo">
							<ModalHeader type="editGroupInfo">
								<div style={styles.modalHeaderFlex}>
									<span
										onClick={() => {
											handleModalCancel({ modalType: 'addGroupParticipant' })
										}}
										style={styles.modalHeaderTextColor}>
										<MdClose size={25} style={styles.modalHeaderIcon} />
									</span>
									<span style={styles.modalHeaderName}>
										{editType === 'individual' ? 'Contact info' : 'Edit Group Info'}
									</span>
								</div>
							</ModalHeader>
							<ModalButtonContainer type="editGroupInfo">
								<Row>
									<Col span={24}>
										<div
											onClick={() => this.filePdfUpload.click()}
											style={{
												...styles.editGroupInfo.groupImageDiv,
												pointerEvents: editType === 'individual' ? 'none' : '',
											}}>
											{!imageUri ? (
												<div>
													<FaCamera size={25} />
													<div>
														{editType === 'individual'
															? 'ADD PROFILE PICTURE'
															: 'ADD GROUP ICON'}
													</div>
													<input
														type="file"
														accept="application/pdf"
														ref={(filePdfUpload) => {
															this.filePdfUpload = filePdfUpload
														}}
														style={{ visibility: 'hidden' }}
														onChange={this.filePreview()}
													/>
												</div>
											) : (
												<>
													<img
														src={imageUri}
														style={styles.editGroupInfo.groupImageSize}
													/>
													<input
														type="file"
														accept="image/*"
														ref={(filePdfUpload) => {
															this.filePdfUpload = filePdfUpload
														}}
														style={{ visibility: 'hidden' }}
														onChange={this.filePreview()}
													/>
												</>
											)}
										</div>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<div
											style={{
												...styles.editGroupInfo.groupDetailsDiv,
												pointerEvents: editType === 'individual' ? 'none' : '',
											}}>
											<h4 style={styles.fontWeightBold}>
												{editType === 'individual' ? 'Name' : 'Group Name'}
											</h4>
											<input
												type="text"
												placeholder="Enter group name"
												onChange={(event) => {
													this.setState({
														groupName: event.target.value,
														groupNameEditStatus: true,
													})
												}}
												value={groupName}
												style={styles.editGroupInfo.groupInputField}
											/>
										</div>
									</Col>
								</Row>
								{editType !== 'individual' ? (
									<Row>
										<Col span={24}>
											<div style={styles.editGroupInfo.groupDetailsDiv}>
												<h4 style={styles.fontWeightBold}>Group Description</h4>
												<input
													type="text"
													placeholder="Enter group description"
													onChange={(event) => {
														this.setState({
															groupDescription: event.target.value,
															groupDescriptionEditStatus: true,
														})
													}}
													value={groupDescription}
													style={styles.editGroupInfo.groupInputField}
												/>
											</div>
										</Col>
									</Row>
								) : null}
								<Row>
									<Col span={24}>
										<div style={styles.editGroupInfo.groupDetailsDiv}>
											<h4 style={styles.fontWeightBold}>
												{editType === 'individual' ? 'Media' : 'Group Media'}
											</h4>
											<div>
												<Query query={GET_MEDIA} variables={{ groupId: groupId }}>
													{({ loading, error, data }) => {
														if (loading) {
															return (
																<div style={styles.fullWidth}>
																	<div>
																		<div style={styles.centerAlign}>
																			<Spin indicator={<Icon type="loading" spin />} />
																		</div>
																	</div>
																</div>
															)
														}
														if (!data || !data.getMediaForGroup.length) {
															return (
																<div style={styles.fullWidth}>
																	<div>
																		<div
																			style={styles.editGroupInfo.groupMembersNotFound}>
																			<span>No media found.</span>
																		</div>
																	</div>
																</div>
															)
														}
														if (!mediaImage.length) {
															this.setState({ mediaImage: data.getMediaForGroup })
														}
														return (
															<Row
																style={{
																	display: 'flex',
																	overflowX: 'scroll',
																	marginBottom: 10,
																}}>
																{mediaImage.map((value, index) => {
																	const { thumbnailUri, uri, caption } = value
																	return (
																		<Col span={6}>
																			<img
																				src={
																					thumbnailUri === null
																						? uri.split(',')[0]
																						: thumbnailUri
																				}
																				height="60"
																				width="60"
																				style={{ margin: 5, cursor: 'pointer' }}
																				onClick={() => {
																					this.setState({
																						imageShow: true,
																						media: true,
																						mediaIndex: index,
																					})
																				}}
																			/>
																		</Col>
																	)
																})}
															</Row>
														)
													}}
												</Query>
											</div>
										</div>
									</Col>
								</Row>

								{editType !== 'individual' ? (
									<Mutation mutation={REMOVE_GROUP_MEMBER}>
										{(removeGroupMember, { data, loading }) => {
											return (
												<Query
													query={LIST_GROUP_MEMBER}
													variables={{ hash: hash, groupId: groupId }}>
													{({ loading, error, data }) => {
														if (loading) {
															return (
																<div style={styles.fullWidth}>
																	<div>
																		<div style={styles.centerAlign}>
																			<Spin indicator={<Icon type="loading" spin />} />
																		</div>
																	</div>
																</div>
															)
														}
														if (!data || !data.listGroupMembers.length) {
															return (
																<div style={styles.fullWidth}>
																	<div>
																		<div
																			style={styles.editGroupInfo.groupMembersNotFound}>
																			<span>No members found.</span>
																		</div>
																	</div>
																</div>
															)
														}

														const loggedInUser = data.listGroupMembers.filter(
															(i) => i.hash === hash
														)
														return (
															<>
																<Row>
																	<Col span={24}>
																		<div style={styles.editGroupInfo.groupDetailsDiv}>
																			<h4 style={styles.fontWeightBold}>
																				No of participants :{' '}
																				{data.listGroupMembers.length}
																			</h4>
																			{loggedInUser[0].isAdmin === 'true' ? (
																				<Row
																					style={styles.editGroupInfo.addGroupMemberDiv}>
																					<Col span={24}>
																						<div
																							style={styles.editGroupInfo.addGroupMember}
																							onClick={() => {
																								handleModalOk({
																									groupMember: data.listGroupMembers,
																									type: 'editGroupParticipant',
																								})
																							}}>
																							<MdPersonAdd size={25} />
																							<span
																								style={
																									styles.editGroupInfo.addParticipant
																								}
																								title={'Add participants'}>
																								Add participants
																							</span>
																						</div>
																					</Col>
																				</Row>
																			) : null}

																			{data.listGroupMembers.map((value) => {
																				const { isAdmin } = value

																				const labelsAndContext = {
																					'Remove from Admin':
																						isAdmin === 'true'
																							? (_) => {
																									const groupDetails = {
																										adminStatus:
																											isAdmin === 'true'
																												? 'false'
																												: 'true',
																										groupId: groupId,
																										hash: hash,
																										members: [value.hash],
																									}
																									showModalDialogPopup({
																										makeGroupAdmin,
																										groupDetails,
																										type: 'makeGroupAdmin',
																									})
																							  }
																							: undefined,
																					'Make Admin':
																						isAdmin === 'false'
																							? (_) => {
																									const groupDetails = {
																										adminStatus:
																											isAdmin === 'true'
																												? 'false'
																												: 'true',
																										groupId: groupId,
																										hash: hash,
																										members: [value.hash],
																									}
																									showModalDialogPopup({
																										makeGroupAdmin,
																										groupDetails,
																										type: 'makeGroupAdmin',
																									})
																							  }
																							: undefined,
																					'Remove from Group': (_) => {
																						const groupDetails = {
																							groupId: groupId,
																							hash: hash,
																							members: [value.hash],
																						}
																						showModalDialogPopup({
																							removeGroupMember,
																							groupDetails,
																							type: 'removeGroupMember',
																						})
																					},
																				}

																				Object.keys(labelsAndContext).forEach((key) =>
																					labelsAndContext[key] === undefined
																						? delete labelsAndContext[key]
																						: {}
																				)

																				return (
																					<Row
																						style={styles.editGroupInfo.groupMemberList}
																						key={value.hash}
																						onMouseEnter={() => {
																							this.setState({
																								iconStatus: true,
																								memberHash: value.hash,
																							})
																						}}
																						onMouseLeave={() => {
																							this.setState({
																								iconStatus: false,
																							})
																						}}>
																						<Col span={3}>
																							<Avatar>
																								{value.name[0].toUpperCase()}
																							</Avatar>
																						</Col>
																						<Col span={10}>
																							<div
																								style={
																									styles.editGroupInfo.groupMemberName
																								}>
																								{loggedInUser[0].hash === value.hash
																									? 'You'
																									: value.name}
																							</div>
																						</Col>
																						{
																							<Col span={8}>
																								<div
																									style={
																										isAdmin === 'true'
																											? styles.editGroupInfo
																													.groupAdminStyle
																											: styles.editGroupInfo
																													.notGroupAdminStyle
																									}>
																									Group admin
																								</div>
																							</Col>
																						}

																						<Col span={3}>
																							{loggedInUser[0].isAdmin === 'true' ? (
																								<div
																									style={
																										iconStatus &&
																										memberHash === value.hash
																											? styles.editGroupInfo
																													.showGroupInfoDropdown
																											: styles.editGroupInfo
																													.hideGroupInfoDropdown
																									}>
																									<MessageDropdown
																										labelsAndContext={labelsAndContext}
																									/>
																								</div>
																							) : null}
																						</Col>
																					</Row>
																				)
																			})}
																		</div>
																	</Col>
																</Row>
																{groupNameEditStatus || groupDescriptionEditStatus ? (
																	<Row>
																		<Col span={24}>
																			<div
																				style={styles.editGroupInfo.updateGroupInfo}
																				onClick={() => {
																					handleModalOk({
																						groupName,
																						groupId,
																						groupDescription,
																						groupNameStatus: groupNameEditStatus,
																						groupDescriptionStatus: groupDescriptionEditStatus,
																						type: 'updateGroupData',
																					})

																					setTimeout(
																						function() {
																							this.setState({
																								groupNameEditStatus: false,
																								groupDescriptionEditStatus: false,
																							})
																						}.bind(this),
																						200
																					)
																				}}>
																				{loader ? (
																					<div style={styles.editGroupInfo.updateLoader}>
																						<Loader
																							type="TailSpin"
																							color="#ffffff"
																							height={25}
																							width={25}
																							radius={4}
																						/>
																					</div>
																				) : (
																					<MdCheck
																						size={30}
																						style={styles.editGroupInfo.updateIcon}
																					/>
																				)}
																			</div>
																		</Col>
																	</Row>
																) : null}
															</>
														)
													}}
												</Query>
											)
										}}
									</Mutation>
								) : (
									<div style={styles.editGroupInfo.groupDetailsDiv}>
										<h4 style={styles.fontWeightBold}>Groups in common :</h4>
										<Query
											query={COMMAN_GROUP}
											variables={{ fromHash: hash, toHash: groupId }}>
											{({ loading, error, data }) => {
												if (loading) {
													return (
														<div style={styles.fullWidth}>
															<div>
																<div style={styles.centerAlign}>
																	<Spin indicator={<Icon type="loading" spin />} />
																</div>
															</div>
														</div>
													)
												}
												if (!data || !data.commanGroups.length) {
													return (
														<div style={styles.fullWidth}>
															<div>
																<div style={styles.editGroupInfo.groupMembersNotFound}>
																	<span>No common group found.</span>
																</div>
															</div>
														</div>
													)
												}
												return (
													<>
														{data.commanGroups.map((value) => {
															return (
																<Row
																	style={styles.editGroupInfo.groupMemberList}
																	key={value.groupId}
																	onClick={() => {
																		handleModalOk({
																			item: {
																				id: value.groupId,
																				photo: value.picture,
																				name: value.name,
																			},
																			type: 'changeSelectedGroup',
																		})
																	}}>
																	<Col span={3}>
																		{value.picture !== null ? (
																			<Avatar src={value.picture} />
																		) : (
																			<Avatar>{value.name[0].toUpperCase()}</Avatar>
																		)}
																	</Col>
																	<Col span={21}>
																		<div style={styles.editGroupInfo.groupMemberName}>
																			{value.name}
																		</div>
																	</Col>
																</Row>
															)
														})}
													</>
												)
											}}
										</Query>
									</div>
								)}
							</ModalButtonContainer>
						</Modal>
					)}
				</Mutation>

				{this.state.imageShow && (
					<Lightbox
						mainSrc={mediaImage[mediaIndex].uri.split(',')[0]}
						nextSrc={
							mediaIndex < mediaImage.length - 1
								? mediaImage[(mediaIndex + 1) % mediaImage.length].uri.split(',')[0]
								: undefined
						}
						prevSrc={
							mediaIndex !== 0
								? mediaImage[
										(mediaIndex + mediaImage.length - 1) % mediaImage.length
								  ].uri.split(',')[0]
								: undefined
						}
						onCloseRequest={() => this.setState({ imageShow: false })}
						onMovePrevRequest={() =>
							this.setState({
								mediaIndex: (mediaIndex + mediaImage.length - 1) % mediaImage.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								mediaIndex: (mediaIndex + 1) % mediaImage.length,
							})
						}
					/>
				)}
			</>
		)
	}
}
export default withApollo(EditGroupInfo)
