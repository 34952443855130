import gql from 'graphql-tag'

export const ForReviewList = gql`
	query forReviewFiles($projectUrl: String, $pageToken: String) {
		forReviewFiles(projectUrl: $projectUrl, pageToken: $pageToken) {
			dataList {
				id
				name
				mimeType
				description
				webViewLink
				webContentLink
				thumbnailLink
				modifiedTime
				teamDriveId
				forReviewFolderId
				approvedFolderId
				forRevisionFolderId
				notedFolderId
				projectName
				projectUrl
				status
				lastModifyingUser {
					kind
					displayName
					photoLink
					me
					permissionId
					emailAddress
				}
				nextPageToken
			}
		}
	}
`

export const CheckDriveAuth = gql`
	query checkDriveAuth {
		checkDriveAuth
	}
`
