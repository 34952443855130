import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoadStageConfig from './LoadStageConfig'
import './index.css'

class ServiceStageConfig extends Component {
	constructor(props) {
		super(props)
		this.state = {
			noOfStage: 0,
			stageDeliverableTarget: [],
			stageDeliverableSource: [],
			feeHeadScope: [],
		}
	}

	// Generate no of stages JSON data
	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	generateNoOfStage = (deliverableSelectedId) => {
		const stageData = []
		for (let y = 0; y < deliverableSelectedId.length; y++) {
			const scopeData = []
			for (let x = 0; x < deliverableSelectedId.length; x++) {
				const data = {
					scopeId: `stage_${x + 1}`,
					scopeName: `stage ${x + 1}`,
					scopeType: 'stage',
					scopeSelected: false,
					scopeIndex: x + 1,
					scopePercentage: 100 / deliverableSelectedId.length,
					status: 'success',
				}
				scopeData.push(data)
			}

			const result = {
				scopeType: 'stage',
				scopeName: `${deliverableSelectedId[y].scopeName}`,
				scopeData,
			}

			stageData.push(result)
		}

		return stageData
	}

	componentDidMount() {
		const {
			stageDeliverableSource,
			stageDeliverableTarget,
			deliverableSelectedId,
			deliverableChangedStatus,
		} = this.props

		if (!deliverableChangedStatus && stageDeliverableSource !== null) {
			this.setState(
				{
					noOfStage: stageDeliverableSource[0].scopeData.length,
					stageDeliverableSource,
					stageDeliverableTarget,
				},
				() => {
					this.props.setServiceStage(this.state)
				}
			)
		} else {
			const getServiceStageSource = this.generateNoOfStage(deliverableSelectedId)

			this.setState(
				{
					stageDeliverableSource: getServiceStageSource,
					stageDeliverableTarget,
					noOfStage: getServiceStageSource[0].scopeData.length,
				},
				() => {
					this.props.setServiceStage(this.state)
				}
			)
		}
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	checkStagePercentage = (stageDeliverableSource) => {
		let stageTotalPercentage = 0

		if (stageDeliverableSource.length) {
			for (let i = 0; i < 1; i++) {
				for (let j = 0; j < stageDeliverableSource[i].scopeData.length; j++) {
					const stagePercentage = stageDeliverableSource[i].scopeData[j].scopePercentage
					stageTotalPercentage += stagePercentage
				}
			}
		}
		return stageTotalPercentage === 100
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	checkSelectedStage = (stageDeliverableSource) => {
		let scopeStageLength = 0
		let scopeSelectedCount = 0
		if (stageDeliverableSource.length) {
			scopeStageLength = stageDeliverableSource[0].scopeData.length
			for (let i = 0; i < 1; i++) {
				for (let j = 0; j < stageDeliverableSource[i].scopeData.length; j++) {
					const { scopeSelected } = stageDeliverableSource[i].scopeData[j]
					if (scopeSelected) {
						scopeSelectedCount += 1
					}
				}
			}
		}

		return scopeSelectedCount === scopeStageLength
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	checkStageOrder = (stageDeliverableTarget) => {
		const stageTarget = stageDeliverableTarget
		let stageErrorStatus = true

		if (stageTarget.length) {
			for (let i = 0; i < stageTarget.length; i++) {
				let max = 0
				let status = true
				let starting = true
				for (let j = 0; j < stageTarget[i].scopeData.length; j++) {
					const type = stageTarget[i].scopeData[j].scopeType

					if (type == 'stage') {
						if (starting == true) {
							max = stageTarget[i].scopeData[j].scopeIndex
							starting = false
						}
						if (status) {
							if (max <= stageTarget[i].scopeData[j].scopeIndex) {
								max = stageTarget[i].scopeData[j].scopeIndex
								stageTarget[i].scopeData[j].status = 'success'
							} else {
								status = false
								stageErrorStatus = false
								stageTarget[i].scopeData[j].status = 'error'
							}
						} else {
							stageErrorStatus = false
							stageTarget[i].scopeData[j].status = 'error'
						}
					}
				}
			}
		}

		const data = {
			stageErrorStatus,
			stageTarget,
		}
		return data
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleStageError = (stageDeliverableSource, stageDeliverableTarget, noOfStage) => {
		const { stageErrorStatus } = this.checkStageOrder(stageDeliverableTarget)

		const stagePercentageError = this.checkStagePercentage(stageDeliverableSource)

		const stageSelectedError = this.checkSelectedStage(stageDeliverableSource)

		const data = {
			stageDeliverableSource,
			stageDeliverableTarget,
			noOfStage,
		}

		this.props.setStageErrorHandle(
			stageErrorStatus,
			stagePercentageError,
			stageSelectedError
		)
		this.props.setServiceStage(data)
	}

	render() {
		const { stageDeliverableTarget, stageDeliverableSource, noOfStage } = this.state

		/**
		 * @type - function
		 * @param -
		 * @summary -
		 * @return -
		 */
		const reRenderDeliverableTarget = () => {
			const oldStageScope = { ...stageDeliverableSource }
			const oldDeliverableScope = { ...stageDeliverableTarget }

			for (let x = 0; x < stageDeliverableSource.length; x++) {
				const stageScopeData = stageDeliverableSource[x].scopeData
				const stageDeliverableData = stageDeliverableTarget[x].scopeData
				for (let y = 0; y < stageScopeData.length; y++) {
					oldStageScope[x].scopeData[y].scopeName = `Stage ${y + 1}`
					for (let z = 0; z < stageDeliverableData.length; z++) {
						const stageScopeId = stageScopeData[y].scopeId
						const stageDeliverableId = stageDeliverableData[z].scopeId
						if (stageScopeId == stageDeliverableId) {
							oldDeliverableScope[x].scopeData[z].scopeName = `Stage ${y + 1}`
						}
					}
				}
			}

			this.setState(
				{
					stageDeliverableSource: Object.values(oldStageScope),
					stageDeliverableTarget: Object.values(oldDeliverableScope),
				},
				() => {
					const { stageTarget } = this.checkStageOrder(stageDeliverableTarget)
					this.handleStageError(stageDeliverableSource, stageTarget, noOfStage)
				}
			)
		}

		// Drag stage to deliverable list and drag deliverable to stage list
		/**
		 * @type - function
		 * @param -
		 * @summary -
		 * @return -
		 */
		const dragDeliverableStageTarget = (items, key) => {
			const oldData = stageDeliverableTarget[key].scopeData
			const newData = items.map(function(val) {
				return JSON.parse(val)
			})

			// Remove duplicate stage in deliverable list
			const removeDuplicate = newData.filter((thing, index) => {
				const scopeId = JSON.stringify(thing.scopeId)
				const scopeType = JSON.stringify(thing.scopeType)

				if (scopeType === '"stage"') {
					return (
						index ===
						newData.findIndex((obj) => {
							return JSON.stringify(obj.scopeId) === scopeId
						})
					)
				}
				return true
			})

			// Identify the new stage comes in deliverable list using newData list
			const uniqueResultOne = newData.filter(function(obj) {
				if (obj.scopeType === 'stage') {
					return !oldData.some(function(obj2) {
						if (obj2.scopeType === 'stage') {
							return obj.scopeId == obj2.scopeId
						}
						return false
					})
				}
				return false
			})

			// Identify the new stage comes in deliverable list using oldData list
			const uniqueResultTwo = oldData.filter(function(obj) {
				if (obj.scopeType === 'stage') {
					return !newData.some(function(obj2) {
						if (obj2.scopeType === 'stage') {
							return obj.scopeId == obj2.scopeId
						}
						return false
					})
				}
				return false
			})

			// Concate newData list and oldData list, Identify the newly existing stage comes in our deliverable list
			const newResult = uniqueResultOne.concat(uniqueResultTwo)

			// Remove stage duplicate in deliverable list and set the new state(stageDeliverableTarget)
			const newTarget = { ...stageDeliverableTarget }
			newTarget[key].scopeData = removeDuplicate
			this.setState({
				stageDeliverableTarget: Object.values(newTarget),
			})

			// Here if any new stage is comes in deliverable list, change the stage stage value and color in stage list
			if (newResult.length) {
				const stageDragId = newResult[0].scopeId
				const stageIndex = newResult[0].scopeIndex

				let status = false
				for (var x = 0; x < stageDeliverableTarget.length; x++) {
					const { scopeData } = stageDeliverableTarget[x]
					for (var y = 0; y < scopeData.length; y++) {
						const stageScopeId = stageDeliverableTarget[x].scopeData[y].scopeId
						if (stageDragId === stageScopeId) {
							status = true
						}
					}
				}

				if (status) {
					var newSource = { ...stageDeliverableSource }
					for (var x = 0; x < stageDeliverableSource.length; x++) {
						const { scopeData } = stageDeliverableSource[x]
						for (var y = 0; y < scopeData.length; y++) {
							const sourceStageIndex = newSource[x].scopeData[y].scopeIndex
							if (stageIndex === sourceStageIndex) {
								newSource[x].scopeData[y].scopeSelected = status
							}
						}
					}
					this.setState({
						stageDeliverableSource: Object.values(newSource),
					})
				} else {
					var newSource = { ...stageDeliverableSource }
					for (var x = 0; x < stageDeliverableSource.length; x++) {
						const { scopeData } = stageDeliverableSource[x]
						for (var y = 0; y < scopeData.length; y++) {
							const sourceStageIndex = newSource[x].scopeData[y].scopeIndex
							if (stageIndex === sourceStageIndex) {
								newSource[x].scopeData[y].scopeSelected = status
							}
						}
					}
					this.setState({
						stageDeliverableSource: Object.values(newSource),
					})
				}
			}

			const { stageTarget } = this.checkStageOrder(stageDeliverableTarget)
			this.handleStageError(stageDeliverableSource, stageTarget, noOfStage)
		}

		/**
		 * @type - function
		 * @param -
		 * @summary -
		 * @return -
		 */
		const addNewPercentage = (val, key) => (evt) => {
			const newAmount = this.state.stageDeliverableSource.map(
				(scopePercentage, index) => {
					return scopePercentage.scopeData.map((scopeData, i) => {
						if (key !== i) return scopeData
						return {
							...scopeData,
							scopePercentage: evt.target.value ? parseInt(evt.target.value) : 0,
						}
					})
				}
			)

			const oldStageScope = { ...stageDeliverableSource }
			for (let x = 0; x < stageDeliverableSource.length; x++) {
				oldStageScope[x].scopeData = newAmount[0]
			}

			this.setState(
				{
					stageDeliverableSource: Object.values(oldStageScope),
					inputStageFocus: key,
				},
				() => {
					const { stageTarget } = this.checkStageOrder(stageDeliverableTarget)
					this.handleStageError(stageDeliverableSource, stageTarget, noOfStage)
				}
			)
		}

		// Add New Stage in proposal stage configuration
		/**
		 * @type - function
		 * @param -
		 * @summary -
		 * @return -
		 */
		const addNewStage = () => {
			const newTarget = { ...stageDeliverableSource }

			const lastIndex = newTarget[0].scopeData.length
			const data = {
				scopeId: `stage_${newTarget[0].scopeData[lastIndex - 1].scopeIndex + 1}`,
				scopeName: `stage ${newTarget[0].scopeData.length + 1}`,
				scopeType: 'stage',
				scopeSelected: false,
				scopeIndex: newTarget[0].scopeData[lastIndex - 1].scopeIndex + 1,
				scopePercentage: 0,
				status: 'success',
			}

			for (let x = 0; x < stageDeliverableSource.length; x++) {
				newTarget[x].scopeData[lastIndex] = data
			}

			this.setState(
				{
					stageDeliverableSource: Object.values(newTarget),
					noOfStage: newTarget[0].scopeData.length,
				},
				() => {
					this.handleStageError(stageDeliverableSource, stageDeliverableTarget, noOfStage)
				}
			)
		}

		/**
		 * @type - function
		 * @param -
		 * @summary -
		 * @return -
		 */
		const removeNewStage = (val, index) => {
			const stageId = val.scopeId
			const oldStageScope = { ...stageDeliverableSource }
			const oldDeliverableScope = { ...stageDeliverableTarget }

			const newStageScope = stageDeliverableSource.map(function(obj) {
				return obj.scopeData.filter(function(element) {
					return element.scopeId !== stageId
				})
			})

			const newDeliverableScope = stageDeliverableTarget.map(function(obj) {
				return obj.scopeData.filter(function(element) {
					return element.scopeId !== stageId
				})
			})

			for (let x = 0; x < stageDeliverableSource.length; x++) {
				oldStageScope[x].scopeData = newStageScope[0]
				oldDeliverableScope[x].scopeData = newDeliverableScope[0]
			}

			this.setState(
				{
					stageDeliverableSource: Object.values(oldStageScope),
					stageDeliverableTarget: Object.values(oldDeliverableScope),
				},
				() => {
					reRenderDeliverableTarget()
				}
			)
		}

		return (
			<div className="serviceStageConfig">
				<LoadStageConfig
					stageDeliverableSource={stageDeliverableSource}
					stageDeliverableTarget={stageDeliverableTarget}
					addNewPercentage={addNewPercentage}
					removeNewStage={removeNewStage}
					addNewStage={addNewStage}
					dragDeliverableStageTarget={dragDeliverableStageTarget}
				/>
			</div>
		)
	}
}

ServiceStageConfig.propTypes = {
	noOfStage: PropTypes.number,
	stageDeliverableTarget: PropTypes.array,
	stageDeliverableSource: PropTypes.array,
	feeHeadScope: PropTypes.array,
	setServiceStage: PropTypes.func,
	deliverableSelectedId: PropTypes.array,
	setStageErrorHandle: PropTypes.func,
	deliverableChangedStatus: PropTypes.bool,
}

export default ServiceStageConfig
