import gql from 'graphql-tag'

export const GetReviewUserDetails = gql`
	query GetReviewUserDetails($reviewUserName: String, $reactionType: String) {
		GetReviewUserDetails(reviewUserName: $reviewUserName, reactionType: $reactionType) {
			branch
			designation
			dateOfJoining
			userEmail
			displayName
			profileUrl
			displayImageUrl
		}
	}
`
