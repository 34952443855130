import gql from 'graphql-tag'

export const ENQUIRY_STAGE_CHANGE_MUTATION = gql`
	mutation EnquiryStageChange($enquiryId: String, $stageFrom: String, $stageTo: String) {
		enquiryStageChange(enquiryId: $enquiryId, stageFrom: $stageFrom, stageTo: $stageTo) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
