import emptySearchResults from '../../../../assets/empty_search_results.png'

const styles = {
	startSearchingImage: { height: 100 },
	startSearchingText: { whiteSpace: 'pre-wrap', width: '16vw' },
	noMatchingResultImage: {
		height: 100,
		backgroundImage: `url(${emptySearchResults})`,
	},
	noMatchingResultText: { whiteSpace: 'pre-wrap', width: '16vw' },
	searchEnquiryResultsDiv: {
		height: '44vh',
		width: '20vw',
		overflow: 'overlay',
	},
	searchEnquiryResultsMenu: { borderBottom: '1px solid #eee', height: 100 },
	searchEnquiryResultsMenuDiv: { width: '18vw', marginRight: 5 },
	searchResultHeading: {
		fontSize: 14,
		fontWeight: 'bold',
		marginBottom: -15,
	},
	serachResultTag: { float: 'right', marginTop: 10 },
	searchResultAddress: {
		fontSize: 12,
		fontStyle: 'italic',
		marginBottom: -15,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	searchResultEmail: { fontSize: 12, marginTop: -17 },
	searchDropdown: {
		width: '20vw',
		float: 'right',
		marginRight: 20,
		marginTop: -25,
	},
}

export default styles
