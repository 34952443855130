import gql from 'graphql-tag'

export const ADD_NEW_REPLY_MUTATION = gql`
	mutation AddDriveReplies($fileId: String, $commentId: String, $comments: String) {
		addDriveReplies(fileId: $fileId, commentId: $commentId, comments: $comments)
	}
`
export const RESOLVE_DRIVE_COMMENT_MUTATION = gql`
	mutation ResolveDriveComment($fileId: String, $commentId: String, $action: String) {
		resolveDriveComment(fileId: $fileId, commentId: $commentId, action: $action)
	}
`
