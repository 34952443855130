// Import React Component
import React, { Component } from 'react'
import Tree from 'rc-tree'
import './index.css'

// Tree height based on content
const onEnterActive = (node) => ({ height: node.scrollHeight })

// Tree expand and collapse animation effect
const motion = {
	motionName: 'node-motion',
	motionAppear: false,
	onEnterActive,
	onLeaveStart: (node) => ({ height: node.offsetHeight }),
}

// Service Deliverable Component
class ProposalDeliverableConfig extends Component {
	componentDidMount() {
		// Deconstruct props
		const { serviceDeliverable } = this.props
		const {
			spaceDeliverable,
			serviceDeliverableCheckedKey,
			serviceDeliverableExpandedKey,
			serviceDeliverableCheckedId,
		} = serviceDeliverable

		// Here check if serviceDeliverableCheckedId is exists or not, if exist call a callback function
		// if (!serviceDeliverableCheckedId.length) {
		const data = {
			spaceDeliverable,
			serviceDeliverableExpandedKey,
			serviceDeliverableCheckedKey,
		}

		const { setServiceDeliverable } = this.props
		// callback function
		setServiceDeliverable(data)
		// }
	}

	// Trigger, When Tree expand
	/**
	 * @type - function
	 * @param - args contains list is expanded ids
	 * @summary - set that ids in defaultScopeExpandedKey and pass callback function
	 * @return - null
	 */
	onExpand = (...args) => {
		// deconstruct the props
		const { serviceDeliverable } = this.props

		// create JSON structure and data
		const data = {
			spaceDeliverable: serviceDeliverable.spaceDeliverable,
			serviceDeliverableExpandedKey: args[0],
			serviceDeliverableCheckedKey: serviceDeliverable.serviceDeliverableCheckedKey,
		}

		const { setServiceDeliverable } = this.props
		// callback function
		setServiceDeliverable(data)
	}

	// When there is any changes in tree, onCheck function return the checked keys
	/**
	 * @type - function
	 * @param - checked keys from RCTree component
	 * @summary -Trigger, when RCTree deliverable checkbox changed or selected
	 * @return - null
	 */
	onCheck = (checkedKeys, info) => {
		// deconstruct the props
		const { serviceDeliverable } = this.props

		const newExpandedKey = [
			...serviceDeliverable.serviceDeliverableExpandedKey,
			...info.halfCheckedKeys,
		]

		// create JSON structure and data
		const data = {
			spaceDeliverable: serviceDeliverable.spaceDeliverable,
			serviceDeliverableExpandedKey: newExpandedKey,
			serviceDeliverableCheckedKey: checkedKeys,
		}

		const { setServiceDeliverable } = this.props

		// callback function
		setServiceDeliverable(data)
	}

	// render serviceDeliverable component
	render() {
		// Decontruct props
		const { serviceDeliverable } = this.props
		const {
			spaceDeliverable,
			serviceDeliverableCheckedKey,
			serviceDeliverableExpandedKey,
		} = serviceDeliverable

		// Return tree component
		return (
			<div className="serviceDeliverable">
				<Tree
					showLine
					checkable
					selectable={false}
					onExpand={this.onExpand}
					onCheck={this.onCheck}
					treeData={spaceDeliverable}
					motion={motion}
					defaultExpandedKeys={serviceDeliverableExpandedKey}
					defaultCheckedKeys={serviceDeliverableCheckedKey}
				/>
			</div>
		)
	}
}

// export ServiceDeliverable Component
export default ProposalDeliverableConfig
