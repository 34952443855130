import gql from 'graphql-tag'

export const ActiveTeamHeads = gql`
	query ActiveTeamHeads {
		ActiveTeamHeads {
			currentHead {
				headEmailID
				displayName
			}
			headList {
				headEmailID
				displayName
			}
		}
	}
`
