/**
 * Developer : Sandhiya Malar
 */
/**
    Ticket Component
    This component will render display open closed tickets details
    User can create/close a discussion in image.
    User can edit a description for images
* */

/*  Import Components  */
import React from 'react'

// Import required components from ANT DESIGN
import { Spin, Icon } from 'antd'

// Query component of React Apollo to fetch data
import { Query, withApollo } from 'react-apollo'
// Import Override CSS
import './index.css'
// Query to fetch Site Photos Details
import { CLOSED_COUNTS } from './query'
// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'

import OpenClientTickets from './OpenClientTickets'
import OpenTeamTickets from './OpenTeamTickets'
import ClosedTeamTickets from './ClosedTeamTickets'
import ClosedClientTickets from './ClosedClientTickets'

/**
 * This class component is used to display ticket images in webapp.
 * @returns {ticket component}
 */
class Tickets extends React.Component {
	// initial states
	state = {
		selection: false,
	}

	/**
	 *  Sets to show a closed discussion data when click show button
	 */
	showClosedTickets = () => {
		this.setState({ selection: true })
	}

	/**
	 *  Sets to hide a closed discussion data when click hide button
	 */
	hideClosedTickets = () => {
		this.setState({ selection: false })
	}

	/**
	 * It should be excecute first
	 * This is used for display tickets photos
	 * @returns {ticket page}
	 */
	/**
	 * This function is used to display a tickets photos.
	 * It contain card profile name, photo comment and Action event like comment.
	 * @returns {tickets photo}
	 */

	render() {
		// Deconstructing the state
		const { selection } = this.state
		// Deconstructing the props
		const { projectUrl } = this.props

		return (
			<Query
				query={CLOSED_COUNTS}
				variables={{
					projectUrl,
					status: 'resolved',
					spaceId: 'All',
				}}>
				{({
					data: closedCountData,
					loading: closedCountLoading,
					error: closedCountError,
				}) => {
					if (closedCountLoading) {
						// loading -> it handles a loading data status
						return (
							<Spin
								indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
								style={styles.loadIconStyle}
							/>
						)
					}
					if (closedCountError) {
						return <span>Some error </span>
					}

					// console.log(closedCountData.closedCounts)

					return (
						<div style={styles.ticket}>
							<OpenClientTickets projectUrl={projectUrl} />
							<OpenTeamTickets projectUrl={projectUrl} />
							{closedCountData.closedCounts !== 0 ? (
								<>
									{!selection ? (
										<div style={styles.ShowClosedTicketsDiv}>
											<div
												style={styles.ShowClosedTickets}
												onClick={this.showClosedTickets}>
												Show Closed Tickets({closedCountData.closedCounts})
											</div>
										</div>
									) : (
										<>
											<ClosedClientTickets projectUrl={projectUrl} />
											<ClosedTeamTickets projectUrl={projectUrl} />
											<div style={styles.ShowClosedTicketsDiv}>
												<div
													style={styles.ShowClosedTickets}
													onClick={this.hideClosedTickets}>
													Hide Closed Tickets
												</div>
											</div>
										</>
									)}
								</>
							) : null}
						</div>
					)
				}}
			</Query>
		)
	}
}

export default withApollo(Tickets)
