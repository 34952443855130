/*  Import Components  */
import React from 'react'
// Import Custom Styles
import styles from './styles'

/**
 * This class component is used to Subscription for webapp comments.It should be
 * open for all unsolved & solved discussion.
 * @returns {comment subscription}
 */

class SubscriptionComment extends React.Component {
	componentDidMount() {
		// to set comment section scroll always bottom of div
		document.getElementById('commentScroll').scrollTop = document.getElementById(
			'commentScroll'
		).scrollHeight

		const { subscribeToNewComments } = this.props
		this.subscription = subscribeToNewComments()
	}

	// at the time of comment post scroll always at bottom of div
	componentDidUpdate() {
		// find the scroll height and set to id 'commentScroll' scrolltop
		// document.getElementById('commentScroll').scrollTop = document.getElementById(
		// 	'commentScroll'
		// ).scrollHeight
	}

	componentWillUnmount() {
		if (this.subscription) {
			this.subscription()
		}
		// console.log('bye')
	}

	render() {
		const {
			status,
			commentDisplay,
			photoComments,
			hideClosedDiscussion,
			userHashState,
		} = this.props
		return (
			<div>
				{commentDisplay(photoComments, status, userHashState)}
				{status === 'unresolved' ? null : (
					<div onClick={hideClosedDiscussion} style={styles.showClosedDiscussion}>
						Hide Closed Tickets
					</div>
				)}
			</div>
		)
	}
}
export default SubscriptionComment
