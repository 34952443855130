import { useMutation } from '@apollo/react-hooks'
import {
	Col,
	Icon,
	List,
	Modal,
	Input,
	Row,
	Select,
	Tooltip,
	Typography,
	notification,
	message,
} from 'antd'
import * as EmailValidator from 'email-validator'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { FiPhoneForwarded } from 'react-icons/fi'
import styles from '../styles'
import './index.css'
import { EDIT_ENQUIRY_DETAILS_MUTATION } from './mutation'

// Importing required components from ANTD

// Query to fetch CRM Details on load

// Component CSS

const { confirm } = Modal
const { Option } = Select

message.config({
	top: 100,
	duration: 4,
	maxCount: 3,
})
export default function ListEnquiryDetails(props) {
	const { currentEnquiryId, currentEnquiryList, refetch, handlers } = props
	const [editEnquiryDetails] = useMutation(EDIT_ENQUIRY_DETAILS_MUTATION)

	const [defaultEnquiryList, setEnquiryList] = useState(currentEnquiryList)

	const [newvalues, setNewValues] = useState({
		newMobileNumber: defaultEnquiryList.contactNo,
		newEmailId: defaultEnquiryList.email,
		newAreaValue: defaultEnquiryList.area.split(' ')[0],
	})

	useEffect(() => {
		setEnquiryList(currentEnquiryList)
	}, [currentEnquiryList])

	const updatedValues = React.useRef(newvalues)

	useEffect(() => {
		console.log(updatedValues.current)
		updatedValues.current = newvalues
	}, [newvalues])

	async function editMobileNumber(oldValue, detailType) {
		const oldNumber = oldValue

		confirm({
			title: 'Updating Mobile Number . . .',
			content: (
				<>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={8}>
							<strong> Old Number : </strong>
						</Col>
						<Col span={20}>
							<span style={{ fontWeight: 400 }}> {oldNumber} </span>
						</Col>
					</Row>

					<Row gutter={[16, 8]}>
						<Col span={9}>
							<strong> New Number : </strong>
						</Col>
						<Col span={20}>
							<Input
								placeholder="Enter the new mobile number . . ."
								maxLength={15}
								allowClear
								defaultValue={oldNumber}
								onKeyUp={(e) => {
									if (e.keyCode === 107) {
										const count = (e.target.value.match(/[+]/g) || []).length
										if (count !== 0) e.preventDefault()
									}
									if (
										e.keyCode === 8 ||
										e.keyCode === 9 ||
										e.keyCode === 13 ||
										e.keyCode === 18 ||
										e.keyCode === 27 ||
										e.keyCode === 37 ||
										e.keyCode === 38 ||
										e.keyCode === 39 ||
										e.keyCode === 40 ||
										e.keyCode === 107 ||
										(e.keyCode > 47 && e.keyCode < 58) ||
										(e.keyCode > 95 && e.keyCode < 106)
									) {
										setNewValues({
											...newvalues,
											newMobileNumber: e.target.value,
										})
										// setEnquiryList({ ...defaultEnquiryList, contactNo: e.target.value })
										return true
									}

									e.preventDefault()
								}}
								// onPressEnter={(e) => onEnter(e)}
							/>
						</Col>
					</Row>
				</>
			),
			okText: 'Confirm',
			centered: true,
			maskClosable: true,
			keyboard: true,
			async onOk() {
				const newNumber = updatedValues.current.newMobileNumber
				console.log(updatedValues.current.newMobileNumber)
				console.log(oldNumber)
				if (newNumber === oldNumber) {
					message.warning('Please enter a different mobile number . . .')
					editMobileNumber(oldValue, 'contactNo')
					return null
				}
				if (newNumber.length > 15 || newNumber.length < 8) {
					message.warning('Please enter a valid mobile number . . .')
					return null
				}

				try {
					await editEnquiryDetails({
						variables: {
							enquiryId: currentEnquiryId,
							enquiryDetailType: detailType,
							oldValue: oldNumber,
							newValue: newNumber,
						},
					})
				} catch (error) {
					return null
				}
				await handlers.updateCurrentEnquiryList(currentEnquiryId, newNumber, 'contactNo')
				setEnquiryList({ ...defaultEnquiryList, contactNo: newNumber })

				refetch()
				setNewValues({
					...newvalues,
					newMobileNumber: null,
				})
				notification.success({
					message: `Mobile Number - ${newNumber}`,
					description: 'Updated Successfully !',
					style: { marginTop: 50 },
				})
			},
			onCancel() {
				setNewValues({
					...newvalues,
					newMobileNumber: null,
				})
			},
		})

		async function onEnter(e) {
			const oldNumber = defaultEnquiryList.contactNo
			const newNumber = e.target.value

			if (newNumber === oldNumber) {
				message.error('Update failed. You have entered the same mobile number . . .')
				return null
			}
			if (newNumber.length > 15 || newNumber.length < 8) {
				message.warning('Please enter a valid mobile number . . .')
				return null
			}
			try {
				await editEnquiryDetails({
					variables: {
						enquiryId: currentEnquiryId,
						enquiryDetailType: detailType,
						oldValue: oldNumber,
						newValue: newNumber,
					},
				})
			} catch (error) {}
			await handlers.updateCurrentEnquiryList(currentEnquiryId, newNumber, 'contactNo')
			setEnquiryList({ ...defaultEnquiryList, contactNo: newNumber })

			refetch()
			notification.success({
				message: 'Mobile Number',
				description: 'Updated Successfully !',
				style: { marginTop: 50 },
			})
			Modal.destroyAll()
		}
	}

	async function editEmailId(oldValue, detailType) {
		const oldEmail = oldValue.trim()

		confirm({
			title: 'Updating Email ID . . .',
			content: (
				<>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={8}>
							<strong> Old Email ID : </strong>
						</Col>
						<Col span={20}>
							<span style={{ fontWeight: 400 }}> {oldEmail} </span>
						</Col>
					</Row>
					<Row gutter={[16, 8]}>
						<Col span={9}>
							<strong> New Email ID : </strong>
						</Col>
						<Col span={20}>
							<Input
								placeholder="Enter the new mobile number . . ."
								allowClear
								defaultValue={oldEmail}
								onKeyUp={(e) =>
									setNewValues({
										...newvalues,
										newEmailId: e.target.value,
									})
								}
								// onPressEnter={(e) => onEnter(e)}
							/>
						</Col>
					</Row>
				</>
			),
			okText: 'Confirm',
			centered: true,
			maskClosable: true,
			keyboard: true,
			async onOk() {
				const newEmail = updatedValues.current.newEmailId
				console.log(newEmail)
				console.log(oldEmail)
				if (newEmail === oldEmail) {
					message.warning('Please enter a different email id . . .')
					editEmailId(oldValue, 'email')
					return null
				}

				if (!EmailValidator.validate(newEmail)) {
					message.warning('Please enter a valid email id . . .')
					editEmailId(oldValue, 'email')
					return null
				}
				try {
					await editEnquiryDetails({
						variables: {
							enquiryId: currentEnquiryId,
							enquiryDetailType: detailType,
							oldValue: oldEmail,
							newValue: newEmail,
						},
					})
				} catch (error) {}
				await handlers.updateCurrentEnquiryList(currentEnquiryId, newEmail, 'email')
				setEnquiryList({ ...defaultEnquiryList, email: newEmail })

				refetch()
				setNewValues({
					...newvalues,
					newEmailId: null,
				})
				notification.success({
					message: `Email ID - ${newEmail}`,
					description: 'Updated Successfully !',
					style: { marginTop: 50 },
				})
			},
			onCancel() {
				// setEnquiryList({ ...defaultEnquiryList, email: oldEmail })
				setNewValues({
					...newvalues,
					newEmailId: null,
				})
			},
		})
	}
	async function editTypology(oldValue, detailType) {
		let newValue
		const typologyList = [
			'Residence',
			'Office Space',
			'Hotels/Resorts',
			'Healthcare',
			'Commercial',
			'Campus',
			'Industrial',
			'Public Building',
			'Others',
		]
		confirm({
			title: 'Updating Typology . . .',
			content: (
				<>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={10}>
							<strong> Old Typology : </strong>
						</Col>
						<Col span={20}>
							<span style={{ fontWeight: 400 }}> {oldValue} </span>
						</Col>
					</Row>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={10}>
							<strong> New Typology : </strong>
						</Col>
						<Col span={20}>
							<Select
								className="changeTypologyDropdown"
								showSearch
								placeholder="Select new Typology . . ."
								onChange={(value) => (newValue = value)}>
								{typologyList.map(
									(item) =>
										item !== oldValue && (
											<Option key={item} style={{ textTransform: 'capitalize' }}>
												{item}
											</Option>
										)
								)}
							</Select>
						</Col>
					</Row>
				</>
			),
			okText: 'Confirm',
			centered: true,
			maskClosable: true,
			keyboard: true,
			async onOk() {
				if (!newValue || !newValue.length) {
					message.warning('Please select a typology . . .')
					editTypology(oldValue, 'typology')
					return
				}
				try {
					await editEnquiryDetails({
						variables: {
							enquiryId: currentEnquiryId,
							enquiryDetailType: detailType,
							oldValue,
							newValue,
						},
					})
				} catch (error) {}
				await handlers.updateCurrentEnquiryList(currentEnquiryId, newValue, 'typology')
				setEnquiryList({ ...defaultEnquiryList, typology: newValue })

				refetch()
				notification.success({
					message: `Typology - ${newValue}`,
					description: 'Updated Successfully !',
					style: { marginTop: 50 },
					duration: 4,
				})
			},
		})
	}
	async function editArea(oldValue, detailType) {
		const oldArea = oldValue.replace(/[^\d]/g, '')

		confirm({
			title: 'Updating Project Area . . .',
			content: (
				<>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={8}>
							<strong> Old Area : </strong>
						</Col>
						<Col span={20}>
							<span style={{ fontWeight: 400 }}> {oldArea} </span>
						</Col>
					</Row>

					<Row gutter={[16, 8]}>
						<Col span={9}>
							<strong> New Area : </strong>
						</Col>
						<Col span={20}>
							<Input
								placeholder="Enter the new mobile number . . ."
								maxLength={15}
								allowClear
								defaultValue={oldArea}
								onKeyUp={(e) => {
									// if (e.target.value.match(/^[0-9]+$/)) {
									// 	e.preventDefault()
									// 	return
									// }

									if (
										e.keyCode === 8 ||
										e.keyCode === 9 ||
										e.keyCode === 13 ||
										e.keyCode === 18 ||
										e.keyCode === 27 ||
										e.keyCode === 37 ||
										e.keyCode === 38 ||
										e.keyCode === 39 ||
										e.keyCode === 40 ||
										(e.keyCode > 47 && e.keyCode < 58) ||
										(e.keyCode > 95 && e.keyCode < 106)
									) {
										setNewValues({
											...newvalues,
											newAreaValue: e.target.value,
										})
										// setEnquiryList({ ...defaultEnquiryList, contactNo: e.target.value })
										return true
									}

									e.preventDefault()
								}}
								// onPressEnter={(e) => onEnter(e)}
							/>
						</Col>
					</Row>
				</>
			),
			okText: 'Confirm',
			centered: true,
			maskClosable: true,
			keyboard: true,
			async onOk() {
				const newArea = updatedValues.current.newAreaValue
				if (!newArea) {
					message.warning('Please enter an area value . . .')
					return null
				}
				if (newArea === oldArea) {
					message.warning('Please enter a different value . . .')
					return null
				}
				try {
					await editEnquiryDetails({
						variables: {
							enquiryId: currentEnquiryId,
							enquiryDetailType: detailType,
							oldValue: oldArea,
							newValue: newArea,
						},
					})
				} catch (error) {
					return null
				}
				await handlers.updateCurrentEnquiryList(currentEnquiryId, newArea, 'area')
				setEnquiryList({
					...defaultEnquiryList,
					area: `${newArea} ${defaultEnquiryList.area.split(' ')[1]}`,
				})

				refetch()
				setNewValues({
					...newvalues,
					newAreaValue: null,
				})
				notification.success({
					message: `Area Value - ${newArea}`,
					description: 'Updated Successfully !',
					style: { marginTop: 50 },
					duration: 4,
				})
			},
		})
	}
	async function editAreaType(oldValue) {
		let newValue
		const areaTypeList = ['sqft', 'sqmt', 'acre']
		confirm({
			title: 'Updating Area Type . . .',
			content: (
				<>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={10}>
							<strong> Old Area Type : </strong>
						</Col>
						<Col span={20}>
							<span style={{ fontWeight: 500 }}> {oldValue} </span>
						</Col>
					</Row>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={10}>
							<strong> New Area Type : </strong>
						</Col>
						<Col span={20}>
							<Select
								className="changeAreaTypeDropdown"
								showSearch
								placeholder="Select new Area Type . . ."
								onChange={(value) => (newValue = value)}>
								{areaTypeList.map(
									(item) =>
										item !== oldValue && (
											<Option key={item} style={{ textTransform: 'capitalize' }}>
												{item}
											</Option>
										)
								)}
							</Select>
						</Col>
					</Row>
				</>
			),
			okText: 'Confirm',
			maskClosable: true,
			centered: true,
			maskClosable: true,
			keyboard: true,
			async onOk() {
				if (!newValue) return
				try {
					await editEnquiryDetails({
						variables: {
							enquiryId: currentEnquiryId,
							enquiryDetailType: 'areaType',
							oldValue,
							newValue,
						},
					})
				} catch (error) {}
				await handlers.updateCurrentEnquiryList(currentEnquiryId, newValue, 'areaType')
				setEnquiryList({
					...defaultEnquiryList,
					area: `${defaultEnquiryList.area.split(' ')[0]} ${newValue}`,
				})

				refetch()
				notification.success({
					message: `Area Type - ${newValue}`,
					description: 'Updated Successfully !',
					style: { marginTop: 50 },
					duration: 4,
				})
			},
		})
	}
	async function editScope(oldValue, detailType) {
		console.log(oldValue)
		let newValue
		const scopeList = ['Architecture', 'Interior', 'Landscape', 'Project Management']
		confirm({
			title: 'Updating Scope . . .',
			content: (
				<>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={8}>
							<strong> Old Scope : </strong>
						</Col>
						<Col span={20}>
							<span style={{ fontWeight: 400 }}>{oldValue.replace(/ {3}/g, ' - ')} </span>
						</Col>
					</Row>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={8}>
							<strong> New Scope : </strong>
						</Col>
						<Col span={20}>
							<Select
								showSearch
								allowClear
								className="changeScopeDropdown"
								mode="multiple"
								placeholder="Select New Scope . . ."
								onChange={(value) => (newValue = value)}>
								{scopeList.map((item) => (
									<Option key={item} style={{ textTransform: 'capitalize' }}>
										{item}
									</Option>
								))}
							</Select>
						</Col>
					</Row>
				</>
			),
			okText: 'Confirm',
			centered: true,
			maskClosable: true,
			keyboard: true,
			async onOk() {
				console.log(newValue)
				if (!newValue || !newValue.length) {
					message.warning('Please select atleast one scope . . .')
					editScope(oldValue, 'scope')
					return
				}
				try {
					await editEnquiryDetails({
						variables: {
							enquiryId: currentEnquiryId,
							enquiryDetailType: detailType,
							oldValue,
							newValue: newValue.join(' '),
						},
					})
				} catch (error) {
					message.warning('Unable to update scope. Please try again . . .')
				}
				await handlers.updateCurrentEnquiryList(
					currentEnquiryId,
					newValue.join(' '),
					'scope'
				)
				setEnquiryList({
					...defaultEnquiryList,
					scope: newValue.join(' '),
				})

				refetch()
				notification.success({
					message: `Scope - ${newValue}`,
					description: 'Updated Successfully !',
					style: { marginTop: 50 },
					duration: 4,
				})
			},
		})
	}
	return (
		<List size="small" style={{ borderBottom: '1px solid #eee' }} split={false}>
			<List.Item style={{ display: 'list-item' }}>
				<Typography.Text>
					<Tooltip placement="left" title="Mobile No.">
						<Icon type="phone" rotate={90} style={styles.modalListIcon} />
					</Tooltip>
				</Typography.Text>
				<Typography.Text
					className="editDetailsList"
					style={{ marginLeft: 10, marginRight: 5 }}
					editable={{
						onStart: () =>
							editMobileNumber(
								defaultEnquiryList.contactNo,
								Object.keys(currentEnquiryList)[
									Object.values(currentEnquiryList).indexOf(currentEnquiryList.contactNo)
								]
							),
					}}>
					{defaultEnquiryList.contactNo}
				</Typography.Text>
			</List.Item>

			<List.Item style={{ display: 'list-item' }}>
				<Typography.Text>
					<Tooltip placement="left" title="Email ID">
						<Icon type="mail" style={styles.modalListIcon} />
					</Tooltip>
				</Typography.Text>
				<Typography.Text
					className="editDetailsList"
					style={{ marginLeft: 10, marginRight: 5 }}
					editable={{
						onStart: () =>
							editEmailId(
								defaultEnquiryList.email,
								Object.keys(currentEnquiryList)[
									Object.values(currentEnquiryList).indexOf(currentEnquiryList.email)
								]
							),
					}}>
					{defaultEnquiryList.email}
				</Typography.Text>
			</List.Item>

			<List.Item style={{ display: 'list-item' }}>
				<Typography.Text>
					<Tooltip placement="left" title="Typology">
						<Icon type="home" style={styles.modalListIcon} />
					</Tooltip>
				</Typography.Text>
				<Typography.Text
					className="editDetailsList"
					style={{ marginLeft: 10, marginRight: 5 }}
					editable={{
						onStart: () =>
							editTypology(
								currentEnquiryList.typology,
								Object.keys(currentEnquiryList)[
									Object.values(currentEnquiryList).indexOf(currentEnquiryList.typology)
								]
							),
					}}>
					{defaultEnquiryList.typology}
				</Typography.Text>
			</List.Item>

			<List.Item style={{ display: 'list-item' }}>
				<Typography.Text>
					<Tooltip placement="left" title="Area">
						<Icon type="number" style={styles.modalListIcon} />
					</Tooltip>
				</Typography.Text>
				<Typography.Text
					className="editDetailsList"
					style={{ marginLeft: 10, marginRight: 5 }}
					editable={{
						onStart: () =>
							editArea(
								currentEnquiryList.area.split(' ')[0],
								Object.keys(currentEnquiryList)[
									Object.values(currentEnquiryList).indexOf(currentEnquiryList.area)
								]
							),
					}}>
					{defaultEnquiryList.area.split(' ')[0]}
				</Typography.Text>
				<Typography.Text
					className="editDetailsList"
					style={{ marginLeft: 10, marginRight: 5 }}
					editable={{
						onStart: () => editAreaType(currentEnquiryList.area.split(' ')[1]),
					}}>
					{defaultEnquiryList.area.split(' ')[1]}
				</Typography.Text>
			</List.Item>

			<List.Item style={{ display: 'list-item' }}>
				<Typography.Text>
					<Tooltip placement="left" title="Scope">
						<Icon type="info-circle" style={styles.modalListIcon} />
					</Tooltip>
				</Typography.Text>
				<Typography.Text
					className="editDetailsList"
					style={{ marginLeft: 10, marginRight: 5 }}
					editable={{
						onStart: () =>
							editScope(
								defaultEnquiryList.scope,
								Object.keys(currentEnquiryList)[
									Object.values(currentEnquiryList).indexOf(currentEnquiryList.scope)
								]
							),
					}}>
					{defaultEnquiryList.scope}
				</Typography.Text>
			</List.Item>

			<List.Item style={{ display: 'list-item' }}>
				<Typography.Text>
					<Tooltip placement="left" title="Address">
						<Icon type="environment" style={styles.modalListIcon} />
					</Tooltip>
				</Typography.Text>
				<Typography.Text style={{ marginLeft: 10, marginRight: 5 }}>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`https://www.google.com/maps/search/${currentEnquiryList.location}`}>
						{defaultEnquiryList.location}
					</a>
				</Typography.Text>
			</List.Item>

			<List.Item style={{ display: 'list-item' }}>
				<Typography.Text>
					<Tooltip placement="left" title="Enquiry received at">
						<Icon type="clock-circle" style={styles.modalListIcon} />
					</Tooltip>
				</Typography.Text>
				<Typography.Text style={{ marginLeft: 10, marginRight: 5 }}>
					{currentEnquiryList.timeOfEnquiry}
				</Typography.Text>
			</List.Item>

			{currentEnquiryList.preferredTimeToCall ? (
				<List.Item style={{ display: 'list-item' }}>
					<Typography.Text>
						<Tooltip placement="left" title="Preferred Time to Call">
							<FiPhoneForwarded style={styles.modalListIcon} />
						</Tooltip>
					</Typography.Text>
					<Typography.Text style={{ marginLeft: 10, marginRight: 5 }}>
						{currentEnquiryList.preferredTimeToCall.replace('_', ' ')}
					</Typography.Text>
				</List.Item>
			) : null}
		</List>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
ListEnquiryDetails.propTypes = {
	currentEnquiryId: PropTypes.string,
}

ListEnquiryDetails.defaultProps = {
	currentEnquiryId: PropTypes.string,
}
