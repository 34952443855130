import React from 'react'
import HeaderMenu from '../../Header/HeaderMenu'
import pageNotFound from '../assets/pageNotFound.png'
import fetchingDetails from '../assets/fetchingDetails.png'
import feedbackDone from '../assets/feedbackDone.png'

import { Row, Col, Typography, Divider, Avatar } from 'antd'
import { AiOutlineUser } from 'react-icons/ai'
import styles from './styles'
const { Title } = Typography

export default function SubPage(props) {
	const { pageType, feedbackData, context } = props
	let handlerImage
	if (pageType === 'errorHandler') {
		handlerImage = pageNotFound
	} else if (pageType === 'feedbackSuccess') {
		handlerImage = feedbackDone
	} else if (pageType === 'loaderView') {
		handlerImage = fetchingDetails
	}

	if (!feedbackData) {
		return (
			<>
				<HeaderMenu showUserProfile={true} />
				<Row style={styles.feedbackSuccessContainer}>
					<Col span={24}>
						<div style={styles.childContainer}>
							<Title style={styles.childContainerTitle}>{context}</Title>
							<img src={handlerImage} style={styles.subPageImage} />
						</div>
					</Col>
				</Row>
			</>
		)
	} else {
		const { displayName, displayImageUrl, finalRating } = feedbackData

		// const displayName = 'Ganesh Partheeban'
		// const displayImageUrl =
		// 	'https://designqube.s3.amazonaws.com/site_photos%2FwUpoV1U8Z-1591253727093.jpeg'
		// const finalRating = 'wow'

		return (
			<>
				<HeaderMenu showUserProfile={true} />
				<Row
					style={{
						padding: 20,
						overflowY: 'scroll',
						overflowX: 'hidden',
						height: '93vh',
					}}>
					<Col
						xs={{ span: 0 }}
						sm={{ span: 1 }}
						md={{ span: 2 }}
						lg={{ span: 2 }}
						xl={{ span: 3 }}
						xxl={{ span: 4 }}></Col>
					<Col
						xs={{ span: 24 }}
						sm={{ span: 22 }}
						md={{ span: 20 }}
						lg={{ span: 20 }}
						xl={{ span: 18 }}
						xxl={{ span: 16 }}>
						<div style={styles.childContainer}>
							<Row>
								<Col span={20}>
									<Title style={styles.childContainerTitle}>
										You have given <br></br>
										<span style={{ fontWeight: 500 }}>{displayName}</span> a
										<span
											style={{
												fontWeight: 500,
												color:
													finalRating === 'bad'
														? 'red'
														: finalRating === 'ok'
														? '#ff6a00'
														: finalRating === 'good'
														? '#3ebb3e'
														: '#28b128',
											}}>
											{finalRating === 'bad'
												? ' unhappy '
												: finalRating === 'ok'
												? ' satisfactory '
												: finalRating === 'good'
												? ' happy '
												: ' wow '}
										</span>
										rating.
									</Title>
								</Col>
								<Col span={4}>
									<img
										src={require(`../assets/${finalRating}.png`)}
										style={{ width: '10vmin', height: '10vmin', float: 'right' }}
									/>
								</Col>
							</Row>
							{/* <img src={handlerImage} style={styles.subPageImage} /> */}
							<Divider />
							<Row>
								<Col span={6}>
									{displayImageUrl ? (
										<img
											src={displayImageUrl}
											style={{ width: '15vmin', height: '15vmin', borderRadius: 10 }}
										/>
									) : (
										<AiOutlineUser
											style={{ width: '15vmin', height: '15vmin', borderRadius: 10 }}
										/>
									)}
								</Col>
								<Col span={18}>
									<Title style={styles.userContainerTitle}>
										Thanks for taking the time to give me feedback!
										{finalRating === 'bad' || finalRating === 'ok'
											? ' I will make sure to provide you with a better service next time.'
											: finalRating === 'good' || finalRating === 'wow'
											? ' It helps me to keep providing you with great service.'
											: ' It helps me to keep providing you with great service.'}
									</Title>
									<Title style={styles.userContainerTitle}>- {displayName}</Title>
								</Col>
							</Row>
						</div>
						<div
							style={{
								width: 200,
								margin: '0 auto',
								marginTop: 30,
								textAlign: 'center',
							}}>
							<a href="https://g.page/designqube/review" target="_blank">
								Review us on Google
							</a>
						</div>
					</Col>
					<Col
						xs={{ span: 0 }}
						sm={{ span: 1 }}
						md={{ span: 2 }}
						lg={{ span: 2 }}
						xl={{ span: 3 }}
						xxl={{ span: 4 }}></Col>
				</Row>
			</>
		)
	}
}
