import React from 'react'
import { Row, Col, Button } from 'antd' // Import antd

class ServiceDashboard extends React.Component {
	render() {
		const { serviceStatus, changeServiceStatus } = this.props

		return (
			<div>
				{serviceStatus === 'default' ? (
					<div style={{ marginTop: '100px' }}>
						<Row style={{ background: '#fafafa' }}>
							<Col span={9} offset={2}>
								<Button
									type="primary"
									style={{
										width: '100%',
										height: '115px',
										fontSize: '140%',
									}}
									onClick={() => {
										changeServiceStatus('create')
									}}>
									Create Template
								</Button>
							</Col>
							<Col span={9} offset={1}>
								<Button
									type="primary"
									style={{
										width: '100%',
										height: '115px',
										fontSize: '140%',
									}}
									onClick={() => {
										changeServiceStatus('edit')
									}}>
									Edit Template
								</Button>
							</Col>
						</Row>
					</div>
				) : null}
			</div>
		)
	}
}

export default ServiceDashboard
