/* eslint-disable no-nested-ternary */
// Import react component
import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { Avatar, Icon, Input, Switch } from 'antd'

// Load list of stages in stageList

class StageList extends React.Component {
	retainerPaymentType = (e) => {
		const { retainerPaymentType } = this.props
		const type = e ? 'percentage' : 'rupees'
		retainerPaymentType(type)
	}

	changeStageName = (index, scopeId) => ({ target: { value } }) => {
		const { onChangeStageName } = this.props
		onChangeStageName(value, scopeId, index)
	}

	render() {
		// Deconstruct props
		const { data, addNewPercentage, removeNewStage } = this.props

		return [
			data.length ? (
				data.map((val, key) => {
					const result = JSON.stringify(val)
					const { scopeType, scopeId, scopeSelected, scopeName, scopePercentage } = val
					return scopeType !== 'begin' ? (
						<li
							key={scopeId}
							data-id={result}
							className="stageDetails"
							style={{ color: scopeSelected ? 'white' : '#2c7ff9' }}>
							<div
								className="stageName"
								style={
									scopeSelected
										? {
												backgroundColor: '#2d7ff9',
												color: '#ffffff',
										  }
										: {
												color: '#2c7ff9',
												border: '2px solid #2c7ff9',
										  }
								}>
								<Input
									placeholder="Stage Name"
									style={{ border: 'none' }}
									value={scopeName}
									onChange={this.changeStageName(key, scopeId)}
								/>
							</div>

							{/** Add stage percentage */}
							<div className="stagePercentage">
								<Input
									placeholder="%"
									style={{ border: 'none', marginTop: 5 }}
									value={scopePercentage}
									onChange={addNewPercentage(val, key)}
								/>
							</div>

							{/** Remove stage */}
							<div
								className="removeStage"
								style={{
									cursor: scopeSelected ? 'not-allowed' : 'pointer',
								}}>
								{scopeName === 'Retainer' ? (
									<Switch
										checkedChildren="%"
										unCheckedChildren="Rs"
										defaultChecked
										onChange={this.retainerPaymentType}
									/>
								) : scopeName === 'Completion' ? null : (
									<span
										onClick={() => {
											if (!scopeSelected) {
												removeNewStage(val, key)
											}
										}}>
										<Icon type="delete" />
									</span>
								)}
							</div>
						</li>
					) : (
						<li
							key={uniqueId()}
							data-id={result}
							className="stageDetails"
							style={{ color: scopeSelected ? 'white' : '#2c7ff9' }}>
							<div
								style={
									scopeSelected
										? {
												backgroundColor: '#2d7ff9',
												color: '#ffffff',
												textAlign: 'center',
												width: '100%',
												padding: '6px',
										  }
										: {
												color: '#2c7ff9',
												border: '2px solid #2c7ff9',
												textAlign: 'center',
												width: '100%',
												padding: '6px',
										  }
								}>
								<span>{scopeName}</span>
							</div>
						</li>
					)
				})
			) : (
				// No stage Found message
				<li key={uniqueId()} style={{ backgroundColor: 'white' }}>
					<div>
						<Avatar size="large" className="emptyDeliverableList">
							<Icon type="exclamation-circle" className="emptyDeliverableListIcon" />
						</Avatar>
						<span className="stageNameMarginLeft20">No Stage Found</span>
					</div>
				</li>
			),
		]
	}
}

// export stageList component
export default StageList
