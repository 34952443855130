/* eslint-disable react/no-danger */
// Import Components
import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import CKEditor from 'ckeditor4-react';
import { Spin, Icon, Button, notification } from 'antd';
import $ from 'jquery';
import { getMasterScope, insertProposalMasterScope } from './query';

// Import custom CSS
import './index.css';

// Add Proposal Master Scope Component
class AddMasterScope extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorHtml: null, // Editor Html Document
			editorJson: null, // Editor Json Document
			status: false, // button loading status
		};
	}

	// If any changes occur in editor, it will update the state value
	onEditorChange = (evt) => {
		// get editor document html
		const editorHtmlData = evt.editor.getData();

		// Set these data in serviceScopeConfig component state
		this.setState({ editorHtml: editorHtmlData }, () => {
			// Get editor document Json
			const data = this.editorJsonData();
			this.setState({ editorJson: data });
		});
	};

	// Convert HTML dom content to JSON structure
	editorJsonData = () => {
		// Editor Json data
		function buildJSON($li) {
			const subObj = {
				name: $li
					.contents()
					.eq(0)
					.text()
					.trim(),
			};
			$li
				.children('ul')
				.children()
				// eslint-disable-next-line func-names
				.each(function() {
					if (!subObj.children) {
						subObj.children = [];
					}
					subObj.children.push(buildJSON($(this)));
				});
			return subObj;
		}
		function fetchChild() {
			const data = [];
			// eslint-disable-next-line func-names
			$('.editorPreview > div > ul > li').each(function() {
				data.push(buildJSON($(this)));
			});

			return data;
		}

		// Get child in document
		const obj = fetchChild();

		// return child in the form of JSON
		return obj;
	};

	render() {
		// Deconstruct state
		const { status, editorHtml, editorJson } = this.state;

		return (
			<div className="addMasterScope">
				{/** Get master scope from backend */}
				<Query query={getMasterScope}>
					{({ data, loading }) => {
						// Loading status
						if (loading) {
							return (
								<Spin
									indicator={<Icon type="loading" className="iconSize" spin />}
									className="loaderIcon"
								/>
							);
						}

						// error status
						if (!data || !data.getMasterScope) {
							return null;
						}

						// deconstruct master scope
						const { scopeDataJson, scopeDataHtml } = data.getMasterScope;

						// Here check if editor json is null, set the scope data json data in editor json or otherwise not
						if (editorJson === null) {
							this.setState({
								editorJson: JSON.parse(scopeDataJson),
								editorHtml: scopeDataHtml,
								status: true,
							});
						}

						return (
							// Add master scope editor
							<div className="editorPreviewData">
								{/** Editor Preview */}
								<EditorPreview editorHtml={editorHtml} />
								{/** Editor Document */}
								<CKEditorPreview
									data={scopeDataHtml}
									onChange={this.onEditorChange}
									status={status}
									editorJson={editorJson}
									editorHtml={editorHtml}
								/>
							</div>
						);
					}}
				</Query>
			</div>
		);
	}
}

// Load editor component
function CKEditorPreview(props) {
	// Open notification popup
	const openNotification = (type) => {
		notification[type]({
			message: type === 'success' ? 'Changes Saved.' : 'Something went wrong.',
			duration: 100,
			placement: 'bottomRight',
		});
	};

	// Deconstruct props
	const { data, onChange, status, editorHtml, editorJson } = props;

	return (
		// Editor Document
		<div className="editorData">
			{/** Load Editor Document */}
			<CKEditor data={data} onChange={onChange} />

			{/** Save button */}
			{status ? (
				<Mutation
					// eslint-disable-next-line
					mutation={insertProposalMasterScope}
					variables={{
						editorJson: JSON.stringify(editorJson),
						editorHtml,
					}}>
					{(insertMasterScope, { loading }) => (
						<div className="saveMasterScope">
							<Button
								type="primary"
								onClick={async () => {
									const result = await insertMasterScope();
									if (result.data.insertMasterScope) {
										openNotification('success');
									} else {
										openNotification('error');
									}
								}}>
								{!loading ? 'Save Changes' : <Icon type="loading" />}
							</Button>
						</div>
					)}
				</Mutation>
			) : null}
		</div>
	);
}

// Editor Preview
function EditorPreview(props) {
	const { editorHtml } = props;
	return (
		<div className="editorPreview">
			<div dangerouslySetInnerHTML={{ __html: editorHtml }} />
		</div>
	);
}

// export addMasterScope component
export default AddMasterScope;
