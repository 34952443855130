import gql from 'graphql-tag'

// type - Mutation
// purpose - Create New service
export const CREATE_NEW_SERVICE_MUTATION = gql`
	mutation createNewService(
		$serviceScopeConfig: String!
		$serviceSpaceConfig: String!
		$serviceDeliverableConfig: String!
		$serviceStageConfig: String!
		$serviceDeliverableList: String!
		$servicePriceConfig: String!
		$serviceTeamDescription: String!
		$serviceTermsAndCondition: String!
		$serviceId: String
	) {
		createNewService(
			serviceScopeConfig: $serviceScopeConfig
			serviceSpaceConfig: $serviceSpaceConfig
			serviceDeliverableConfig: $serviceDeliverableConfig
			serviceStageConfig: $serviceStageConfig
			serviceDeliverableList: $serviceDeliverableList
			servicePriceConfig: $servicePriceConfig
			serviceTeamDescription: $serviceTeamDescription
			serviceTermsAndCondition: $serviceTermsAndCondition
			serviceId: $serviceId
		)
	}
`

// type - Query
// purpose - get typology list
export const GET_MASTER_TYPOLOGY_QUERY = gql`
	query getMasterTypology {
		getMasterTypology {
			typologyId
			typologyName
		}
	}
`

// type - Query
// purpose -  get scope list
export const GET_MASTER_SCOPE_QUERY = gql`
	query getServiceMasterScope {
		getServiceMasterScope {
			scopeId
			key
			title
			scopeType
			spaceLevel
			scopeKey
			scopeOrder
			scopePercentage
			scopeStepOrder
			scopePredecessor
			children {
				scopeId
				key
				title
				scopeType
				spaceLevel
				scopeKey
				scopeOrder
				scopePercentage
				scopeStepOrder
				scopePredecessor
				children {
					scopeId
					key
					title
					scopeType
					spaceLevel
					scopeKey
					scopeOrder
					scopePercentage
					scopeStepOrder
					scopePredecessor
				}
			}
		}
	}
`

export const GET_TERMS_AND_CONDITIONS = gql`
	query getTermsAndCondition {
		getTermsAndCondition {
			termsHtmlData
			termsJsonData {
				name
				children {
					name
				}
			}
		}
	}
`

export const GET_TEAM_DESCRIPTION = gql`
	query getTeamDescription {
		getTeamDescription {
			description
			title
		}
	}
`

export const GET_PROPOSAL_TYPOLOGY_LIST_QUERY = gql`
	query getProposalTypologyList {
		getProposalTypologyList {
			typologyId
			typologyName
			noOfStage
		}
	}
`

export const GET_PROPOSAL_SERVICE_LIST_QUERY = gql`
	query getProposalServiceList($searchString: String) {
		getProposalServiceList(searchString: $searchString) {
			service {
				id
				name
			}
			scope
			typology {
				id
				name
			}
			noOfStage
			updatedAt
		}
	}
`

export const GET_PROPOSAL_SERVICE_DETAILS_QUERY = gql`
	query getProposalServiceDetails($serviceId: String!) {
		getProposalServiceDetails(serviceId: $serviceId) {
			serviceScope
			serviceSpace
			serviceDeliverable
			serviceStageConfig
			serviceStageDeliverableList
			serviceResult
			serviceTeamDescription
			serviceTermsAndCondition
		}
	}
`
export const DELETE_TEMPLATE = gql`
	mutation deleteService($serviceId: String!) {
		deleteService(serviceId: $serviceId)
	}
`
