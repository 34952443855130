// import react component
import React from 'react'
import { Spin, Icon, Popconfirm, Input } from 'antd' // Import antd
import { Query } from 'react-apollo'
import { GET_PROPOSAL_SERVICE_LIST_QUERY } from '../query' // Import Query
import { formatChatRelativetimestamp } from '../utils'
import { DebounceInput } from 'react-debounce-input'
import './index.css' // Import CSS

const { Search } = Input

const stageColor = {
	Architecture: '#af4a7eb0',
	Interiors: '#25495ab0',
	Interior: '#25495ab0',
	Landscape: '#6f7d5f',
	Lighting: '#af714c',
	Acoustics: '#69ad92',
	'Facade Design': '#815daf',
}

// Decalre ProposalServiceConfig class
class ProposalServiceConfig extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			serviceName: null,
			hoverService: null,
			serviceList: [],
			refetch: null,
			searchString: '',
		}
	}

	// Handle onchange proposal service
	onChangeService = (serviceDetails) => {
		// Deconstruct props
		const { typology, service, noOfStage } = serviceDetails

		const data = {
			typologyId: typology.id,
			typologyName: typology.name,
			noOfStage,
			serviceId: service.id,
		}

		// Deconstruct props
		const { setServiceConfig } = this.props
		// callback function
		setServiceConfig(data)
	}

	render() {
		// Deconstruct props
		const { hoverService, serviceList, searchString } = this.state

		return (
			<div className="serviceScope" style={{ width: '100%', background: '#fafafa' }}>
				<div
					style={{
						display: 'block',
						height: 50,
						background: '#eeebe8',
						margin: 5,
						borderRadius: 10,
					}}>
					<h3 style={{ float: 'left', margin: '12px', color: 'gray' }}>Template List</h3>
					<div className="chatSearch" style={{ position: 'relative', float: 'right' }}>
						<DebounceInput
							minLength={3}
							placeholder="Search Template . . ."
							debounceTimeout={300}
							onChange={(e) => {
								this.setState({ searchString: e.target.value })
							}}
						/>

						<img
							src={require('./../../ChatBox/images/searchicon.png')}
							alt={''}
							style={{
								width: '15px',
								position: 'absolute',
								bottom: '18px',
								left: '25px',
								top: '18px',
								fontSize: '15px',
							}}
						/>
					</div>
				</div>
				<Query query={GET_PROPOSAL_SERVICE_LIST_QUERY} variables={{ searchString }}>
					{({ loading, data, refetch }) => {
						if (loading) {
							return (
								// Spinner icon
								<Spin
									indicator={<Icon type="loading" className="iconSize" spin />}
									className="loaderIcon"
								/>
							)
						}

						if (!serviceList.length) {
							this.setState({
								serviceList: data.getProposalServiceList,
								refetch,
							})
						}

						return (
							<>
								{data.getProposalServiceList.length ? (
									<div>
										{data.getProposalServiceList.map((value, index) => {
											const { service, scope, typology, noOfStage, updatedAt } = value

											return (
												<div
													id="serviceList"
													key={'data' + index}
													onMouseEnter={() => {
														this.setState({ hoverService: value })
													}}
													onMouseLeave={() => {
														this.setState({ hoverService: null })
													}}
													onClick={(e) => {
														this.onChangeService(value)
													}}>
													<p title={service.name}>{service.name}</p>
													<div id="scopeList">
														{scope.map((scopeName) => {
															return (
																<span
																	style={{
																		background: stageColor[scopeName],
																	}}>
																	{scopeName}
																</span>
															)
														})}
													</div>
													<div id="line"></div>
													<div id="typology">
														<div
															id="typologyName"
															title={`${noOfStage} stage - ${typology.name}`}>
															{noOfStage} stage - {typology.name}
														</div>
														<span style={{ float: 'right' }}>
															{formatChatRelativetimestamp(updatedAt)}
														</span>
													</div>
												</div>
											)
										})}
									</div>
								) : (
									<div style={{ textAlign: 'center', opacity: 0.7, marginTop: 20 }}>
										<span>No template found.</span>
									</div>
								)}
							</>
						)
					}}
				</Query>
			</div>
		)
	}
}

// export proposal service config
export default ProposalServiceConfig
