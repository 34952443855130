//  next class

const styles = {
	// ticket close button
	commentCloseButton: {
		padding: '0px 0px',
		width: '83px',
	},
	commentReplay: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	closebutton: {
		paddingRight: '3px',
	},
	replayInput: {
		width: 'fit-content',
		marginLeft: '5px',
	},
	thumsup: {
		paddingLeft: '6px',
		fontSize: 'x-large',
	},
	// send icon align
	sendIcon: {
		marginTop: '5px',
		marginLeft: '3px',
	},

	thumsUpBackground: {
		fontSize: '20px',
	},
	reopen: {
		padding: '0px',
		width: '75px',
	},
}

export default styles
