/* eslint-disable no-tabs */
// Import React Components
import React from 'react'
import { Steps, Button, message, Modal, Input, Icon, Tooltip, notification } from 'antd'
import { withApollo } from 'react-apollo'
import ProposalDeliverableConfig from './ProposalDeliverableConfig'
import ProposalSpaceConfig from './ProposalSpaceConfig'
import ProposalStageConfig from './ProposalStageConfig'
import ProposalScopeConfig from './ProposalScopeConfig'
import ProposalServiceConfig from './ProposalServiceConfig'
import ProposalFeeCalculation from './ProposalFeeCalculation'
import ProposalDeliverableList from './ProposalDeliverableList'
import ProposalTeamDescription from './ProposalTeamDescription'
import ProposalTermsAndCondition from './ProposalTermsAndConditions'
import ProposalSwot from './ProposalSwot'
import {
	GET_PROPOSAL_SERVICE_DETAILS_QUERY,
	GENERATE_NEW_PROPOSAL,
	GET_GST_DETAILS,
	GET_ENQUIRY_PROPOSAL_DETAILS,
	GET_CLIENT_DETAILS,
	CREATE_NEW_SERVICE_MUTATION,
	GET_PROPOSAL_SERVICE_LIST_QUERY,
} from './query' // Import GraphQl Query
import {
	serviceScopeDefaultExpandKey,
	selectedScopeData,
	generateServiceDeliverable,
	serviceDeliverableTemplateData,
	serviceDeliverableCheckedScopeId,
	serviceStageConfigData,
	serviceDeliverableList,
	proposalFeeCalculation,
	proposalResultDetails,
	proposalSwotTemplate,
	serviceTeamDescriptionValidate,
	serviceStageDescription,
	calculateDesignTimeAndAmount,
	changeStageTarget,
} from './utils' // Import Utils
import { IoIosInformationCircle } from 'react-icons/io'

import './index.css'
import utils from '../../utils'
// Import CSS
const { Step } = Steps
const { decryptEnquiryId } = utils
// Set default state in NewService component

const feesPackageList = [
	{
		title: 'Exclusive',
		percentage: 180,
		features: [
			{ text: 'Unlimited design revisions', has: true, high: true },
			{ text: 'Infinite site visits', has: true, high: true, siteVisit: true },
			{ text: 'Realistic 3D visualisation', has: true },
			{ text: 'End-to-end support till moving in', has: true },
			{ text: 'Walkthrough animation', has: true },
		],
	},
	{
		title: 'Premium',
		percentage: 100,
		features: [
			{ text: 'Progressive design revisions', has: true },
			{ text: `Regular site visits`, has: true, siteVisit: true },
			{ text: 'Realistic 3D visualisation', has: true },
			{ text: 'End-to-end support till moving in', has: true },
			{ text: 'Walkthrough animation', has: false },
		],
	},
	{
		title: 'Essential',
		percentage: 90,
		features: [
			{ text: 'Design revision', has: false },
			{ text: `Site visits`, has: true, siteVisit: true },
			{ text: 'Indicative 3D visualisation', has: true, low: true },
			{ text: 'Only design support', has: true, low: true },
			{ text: 'Walkthrough animation', has: false },
		],
	},
]

const defaultState = {
	current: 0,
	modalVisible: false,
	generateStatus: false,
	insertStatus: false,
	typologyName: null,
	typologyId: null,
	noOfStage: null,
	serviceId: null,
	enquiryId: null,
	selectedPackage: '2',
	proposalLink: null,
	serviceStatus: false,
	totalSqft: 0,
	serviceScope: {
		scopeDeliverable: [],
		serviceScopeCheckedKey: [],
		serviceScopeExpandedKey: [],
		serviceScopeCheckedId: [],
		serviceScopeTypology: [],
		serviceScopeTypologyStep: [],
		serviceScopeTypologyDeliverable: [],
		serviceScopeDeliverable: [],
		serviceScopeHalfCheckedKeys: [],
	},
	serviceSpace: {
		spaceEditorJson: [],
		spaceEditorHtml: null,
		noOfBuilding: 0,
		noOfFloor: 0,
		noOfRoom: 0,
		typologyId: null,
		typology: [],
	},
	serviceDeliverable: {
		spaceDeliverable: [],
		serviceDeliverableCheckedKey: [],
		serviceDeliverableExpandedKey: [],
		serviceDeliverableCheckedId: [],
	},
	serviceStageConfig: {
		stageStepTarget: [],
		stageStepSource: [],
		errorStatus: {
			percentageError: {
				error: false,
				message: null,
			},
			selectionError: {
				error: true,
				message: null,
			},
			orderError: {
				error: true,
				message: null,
			},
		},
		retainerType: 'Percentage',
	},
	serviceStageDeliverableList: {
		stageDeliverable: [],
	},
	serviceResult: {
		serviceName: null,
		paymentPercentage: {
			percentage: 0,
			estimationCostPerSqft: 0,
		},

		elasticPrice: [
			{ sqft: '500', amount: '', time: '' },
			{ sqft: '1000', amount: '', time: '' },
			{ sqft: '2500', amount: '', time: '' },
			{ sqft: '10000', amount: '', time: '' },
			{ sqft: '50000', amount: '', time: '' },
		],
	},
	serviceSwot: {
		swotEditorHtml: '',
		swotEditorJson: [],
	},
	serviceGstDetails: {
		gstDetails: [],
	},
	serviceTeamDescription: {
		teamDescription: [],
	},
	proposalResult: {
		proposalDetails: {
			proposalData: [],
		},
	},
	serviceTermsAndCondition: {
		termsHtmlData: '',
		termsJsonData: [],
	},
	serviceClientDetails: {
		name: '',
		location: '',
	},
	saveServiceResult: {},
	serviceType: 'edit',
	feesPackage: feesPackageList,
}

// Create Service Component
class NewProposal extends React.Component {
	constructor(props) {
		super(props)
		this.state = defaultState
	}

	componentDidMount() {
		this.init()
	}

	// Using enquiryId to get previously generated proposal details
	init = async () => {
		// Deconstruct state

		const pathName = window.location.pathname.replace('/generate-proposal/', '')
		const urlEnquiryId = `enquiry_${decryptEnquiryId(pathName)}`
		this.setState({ enquiryId: urlEnquiryId })

		// Deconstruct props
		const { client } = this.props

		// Get proposal details using enquiryId
		const serviceDetails = await client.query({
			query: GET_ENQUIRY_PROPOSAL_DETAILS,
			variables: {
				enquiryId: urlEnquiryId,
			},
		})

		// Get gst details
		const gstDetails = await client.query({
			query: GET_GST_DETAILS,
			variables: {},
		})

		// Get client details
		const clientDetails = await client.query({
			query: GET_CLIENT_DETAILS,
			variables: {
				enquiryId: urlEnquiryId,
			},
		})

		const result = serviceDetails.data.getEnquiryProposalDetails
		const { getClientDetails } = clientDetails.data
		const { name, address: location, area } = getClientDetails

		if (result.proposalStatus === 'true') {
			// Deconstruct result object
			const {
				serviceScope,
				serviceSpace,
				serviceDeliverable,
				serviceStageConfig,
				serviceStageDeliverableList,
				serviceResult,
				serviceSwot,
				serviceId,
				versionId,
				typologyId,
				noOfStage,
				servicePriceConfig,
				typologyName,
				serviceTeamDescription,
				selectedPackage,
				proposalPackage,
				serviceTermsAndCondition,
				feesPackage,
			} = result

			// set result object data in component state
			this.setState({
				serviceScope: JSON.parse(serviceScope),
				serviceSpace: JSON.parse(serviceSpace),
				serviceDeliverable: JSON.parse(serviceDeliverable),
				serviceStageConfig: JSON.parse(serviceStageConfig),
				serviceStageDeliverableList: JSON.parse(serviceStageDeliverableList),
				proposalResult: JSON.parse(serviceResult),
				serviceResult: JSON.parse(servicePriceConfig),
				saveServiceResult: JSON.parse(servicePriceConfig),
				serviceGstDetails: gstDetails.data.getGstDetails,
				serviceSwot: JSON.parse(serviceSwot),
				serviceTeamDescription: JSON.parse(serviceTeamDescription),
				serviceId,
				versionId,
				typologyId,
				noOfStage,
				typologyName,
				selectedPackage,
				proposalPackage: JSON.parse(proposalPackage),
				current: 2,
				totalSqft: area,
				serviceTermsAndCondition: JSON.parse(serviceTermsAndCondition),
				serviceClientDetails: {
					name,
					location,
				},
				feesPackage:
					feesPackage !== '' && feesPackage !== undefined && feesPackage !== null
						? JSON.parse(feesPackage)
						: feesPackageList,
			})
		} else {
			this.setState({
				totalSqft: area,
				proposalPackage: { uncompromised: 50000, essential: 100000, exclusive: 40000 },
				serviceClientDetails: {
					name,
					location,
				},
				proposalResult: {
					proposalDetails: {
						discount: {
							type: 'nil',
							value: 0,
						},

						gstDetails: [],
						lumpsumAmount: 0,
						gstStatus: 'hide',
						paymentType: 'sqft',
						siteVisit: {
							essential: 3,
							uncompromised: 20,
						},
						paymentPercentage: {
							estimationCostPerSqft: 1000,
							percentage: 15,
						},
					},
				},
				feesPackage: feesPackageList,
			})
		}
	}

	// set service config details
	setServiceConfig = async (data) => {
		// Deconstruct props
		const { typologyId, typologyName, noOfStage, serviceId } = data
		// Deconstruct props
		const { client } = this.props
		this.setState(
			{
				typologyId,
				typologyName,
				noOfStage,
				serviceId,
			},
			async () => {
				if (serviceId !== null) {
					// Get proposal service details using serviceId
					const serviceDetails = await client.query({
						query: GET_PROPOSAL_SERVICE_DETAILS_QUERY,
						variables: {
							serviceId,
						},
					})

					// Get gst details
					const gstDetails = await client.query({
						query: GET_GST_DETAILS,
						variables: {},
					})

					const result = serviceDetails.data.getProposalServiceDetails

					if (result) {
						// Deconstruct result object
						const {
							serviceScope,
							serviceSpace,
							serviceDeliverable,
							serviceStageConfig,
							serviceStageDeliverableList,
							serviceResult,
							serviceTeamDescription,
							serviceTermsAndCondition,
						} = result
						// set result object data in component state
						this.setState(
							{
								serviceScope: JSON.parse(serviceScope),
								serviceSpace: JSON.parse(serviceSpace),
								serviceDeliverable: JSON.parse(serviceDeliverable),
								serviceStageConfig: JSON.parse(serviceStageConfig),
								serviceStageDeliverableList: JSON.parse(serviceStageDeliverableList),
								serviceResult: JSON.parse(serviceResult),
								serviceGstDetails: gstDetails.data.getGstDetails,
								serviceSwot: proposalSwotTemplate(),
								serviceTeamDescription: JSON.parse(serviceTeamDescription),
								serviceTermsAndCondition: JSON.parse(serviceTermsAndCondition),
								serviceId,
								current: 1,
							},
							() => {
								const data = JSON.parse(serviceResult)
								const { totalSqft } = this.state
								const { timeDuration, perSqftAmount } = calculateDesignTimeAndAmount(
									data,
									totalSqft
								)
								const result = { ...data }
								result.perSqftRate = perSqftAmount
								result.timeDuration = timeDuration
								this.setState({ serviceResult: result })
							}
						)
					}
				}
			}
		)
	}

	/**
	 * When there is any changes in serviceScopeConfig component
	 * update that changes in parent component
	 */
	setServiceScope = async (data, type) => {
		if (type === 'edit') {
			// Deconstruct data
			const { serviceScopeExpandedKey } = data
			const newExpandedKey = serviceScopeExpandedKey.length ? serviceScopeExpandedKey : []
			data.serviceScopeExpandedKey = newExpandedKey

			// Get selected scope typology , stypology step and typology deliverable data from util file
			const {
				scopeTypology,
				scopeTypologyStep,
				scopeTypologyDeliverable,
				serviceScopeCheckedId,
			} = await selectedScopeData(data)

			// Using these master data, generate a service scope deliverable template based on master space
			const scopeDeliverableTemplate = serviceDeliverableTemplateData(data)

			data.serviceScopeTypology = scopeTypology
			data.serviceScopeTypologyStep = scopeTypologyStep
			data.serviceScopeCheckedId = serviceScopeCheckedId
			data.serviceScopeTypologyDeliverable = scopeTypologyDeliverable
			data.serviceDeliverableTemplate = scopeDeliverableTemplate

			// Using these master data, generate a stage config details
			const serviceStageConfig = serviceStageConfigData(data, this.state)

			// Set these data in NewService component state
			this.setState({ serviceScope: data, serviceStageConfig })

			// Deconstruct NewService component state
			const { serviceScope, serviceSpace } = this.state

			// Here check if space editor json is exist or not,
			//  if exist generate a service deliverable otherwise not
			// if (serviceSpace.spaceEditorJson !== undefined) {
			// Generate a deliverable list based on service space and service scope
			const { serviceDeliverable } = this.state
			const {
				serviceDeliverableCheckedKey: serviceDeliverableOldCheckedKey,
			} = serviceDeliverable

			const {
				serviceDeliverableExpandedKey,
				serviceDeliverableCheckedKey,
				spaceDeliverable,
			} = generateServiceDeliverable(serviceSpace, serviceScope)

			// Create Component state structure
			const serviceDeliverableData = {
				serviceDeliverableExpandedKey,
				serviceDeliverableCheckedKey: serviceDeliverableCheckedKey,
				spaceDeliverable,
				serviceDeliverableCheckedId: serviceDeliverable.serviceDeliverableCheckedId,
			}

			// Set these data in NewService component state
			this.setState({
				serviceDeliverable: serviceDeliverableData,
			})
			// }
		} else {
			const { scopeDeliverable, serviceScopeExpandedKey } = data
			const newExpandedKey = serviceScopeExpandedKey.length
				? serviceScopeExpandedKey
				: await serviceScopeDefaultExpandKey(scopeDeliverable)
			data.serviceScopeExpandedKey = newExpandedKey

			// Get selected scope typology , stypology step and typology deliverable data from util file
			const {
				scopeTypology,
				scopeTypologyStep,
				scopeTypologyDeliverable,
				serviceScopeCheckedId,
			} = await selectedScopeData(data)

			// Using these master data, generate a service scope deliverable template based on master space
			const scopeDeliverableTemplate = await serviceDeliverableTemplateData(data)

			data.serviceScopeTypology = scopeTypology
			data.serviceScopeTypologyStep = scopeTypologyStep
			data.serviceScopeCheckedId = serviceScopeCheckedId
			data.serviceScopeTypologyDeliverable = scopeTypologyDeliverable
			data.serviceDeliverableTemplate = scopeDeliverableTemplate

			this.setState({ serviceScope: data })
		}
	}

	/**
	 * When there is any changes in serviceSpace component update that changes in parent component
	 */

	setServiceSpace = (data) => {
		// Deconstruct NewService component state
		const { serviceScope, serviceDeliverable } = this.state

		// Here check if space editor json is exist or not,
		// if exist generate a service deliverable otnerwise not
		if (data.spaceEditorJson !== undefined) {
			// Generate a deliverable list based on service space and service scope
			const {
				serviceDeliverableExpandedKey,
				serviceDeliverableCheckedKey,
				spaceDeliverable,
			} = generateServiceDeliverable(data, serviceScope)

			// create state JSON structure and data
			const serviceDeliverableData = {
				serviceDeliverableExpandedKey,
				serviceDeliverableCheckedKey,
				spaceDeliverable,
				serviceDeliverableCheckedId: serviceDeliverable.serviceDeliverableCheckedId,
			}
			// Set these data in NewService component state
			this.setState({
				serviceDeliverable: serviceDeliverableData,
			})
		}

		// Set these data in NewService component state
		this.setState({ serviceSpace: data })
	}

	/**
	 * When there is any changes in service deliverable component update
	 * that changes in parent component
	 */

	setServiceDeliverable = (data) => {
		// Deconstruct data
		const { spaceDeliverable, serviceDeliverableCheckedKey } = data

		// Using space deliverable and deliverable checked keys,
		// generate a deliverable scope name, scope id etc
		const serviceDeliverableCheckedId = serviceDeliverableCheckedScopeId(
			spaceDeliverable,
			serviceDeliverableCheckedKey
		)
		data.serviceDeliverableCheckedId = serviceDeliverableCheckedId

		// Set these data in NewService component state
		this.setState({ serviceDeliverable: data }, async () => {
			const { serviceStageConfig, serviceDeliverable, serviceScope } = this.state

			// Generate deliverable list based on stage config
			const stageDeliverableData = await serviceDeliverableList(
				serviceStageConfig,
				serviceDeliverable
			)

			const serviceStageConfigData = await changeStageTarget(
				serviceStageConfig,
				data,
				serviceScope
			)

			// Set these data in NewService component state
			this.setState({
				serviceStageDeliverableList: stageDeliverableData,
				serviceStageConfig,
				serviceStageConfig: serviceStageConfigData,
			})
		})
	}

	/**
	 * When there is any changes in service stage component update that changes in parent component
	 */
	setServiceStageConfig = (data) => {
		// Deconstruct NewService component state
		const { serviceDeliverable } = this.state

		// Generate deliverable list based on stage config
		const stageDeliverableData = serviceDeliverableList(data, serviceDeliverable)

		// Set these data in NewService component state
		this.setState({
			serviceStageConfig: data,
			serviceStageDeliverableList: stageDeliverableData,
		})
	}

	setServiceDeliverableList = (data) => {
		// Set these data in NewService component state
		this.setState({ serviceStageDeliverableList: data })
	}

	// Calculate Fees in proposal
	setFeeCalculation = (data) => {
		// Deconstruct state
		const { proposalResult, serviceResult, serviceGstDetails } = this.state
		const { totalDuration, proposalFeeData } = proposalFeeCalculation(
			this.state,
			data,
			serviceGstDetails
		)
		const proposalData = proposalResultDetails(
			proposalResult,
			serviceResult,
			proposalFeeData,
			totalDuration
		)
		this.setState({ proposalResult: proposalData })
	}

	// Edit stage name in generate proposal
	editProposalStageName = (rowData) => {
		// Deconstruct state
		const { proposalResult, serviceStageConfig, serviceStageDeliverableList } = this.state
		const { arrayStageDeliverable, objectStageDeliverable } = serviceStageDeliverableList
		const { proposalDetails } = proposalResult
		const { proposalData } = proposalDetails

		const {
			stageStepSource,
			stageStepTarget,
			retainerType,
			errorStatus,
		} = serviceStageConfig

		const newData = [...proposalData]
		const index = newData.findIndex((item) => rowData.key === item.key)
		const item = newData[index]
		newData.splice(index, 1, {
			...item,
			...rowData,
		})

		for (let i = 0; i < stageStepSource.length; i++) {
			const { scopeId } = stageStepSource[i]
			if (scopeId === newData[index].scopeId) {
				stageStepSource[i].scopeName = newData[index].stageName
				stageStepSource[i].scopePercentage = parseInt(newData[index].totalPercentage)
			}
		}

		for (let x = 0; x < stageStepTarget.length; x++) {
			const { scopeId } = stageStepTarget[x]
			if (scopeId === newData[index].scopeId) {
				stageStepTarget[x].scopeName = newData[index].stageName
			}
		}

		let newArrayStageDescription = Object.assign({}, arrayStageDeliverable)
		for (let x = 0; x < arrayStageDeliverable.length; x++) {
			const { scopeId } = arrayStageDeliverable[x]
			if (scopeId === newData[index].scopeId) {
				newArrayStageDescription[x].scopeName = newData[index].stageName
			}
		}

		let newObjectStageDescription = Object.assign({}, objectStageDeliverable)
		for (let x = 0; x < objectStageDeliverable.length; x++) {
			const { scopeId } = objectStageDeliverable[x]
			if (scopeId === newData[index].scopeId) {
				newObjectStageDescription[x].scopeName = newData[index].stageName
			}
		}

		const newDescription = {
			objectStageDeliverable: Object.values(newObjectStageDescription),
			arrayStageDeliverable: Object.values(newArrayStageDescription),
		}

		const newServiceStageConfig = {
			stageStepSource,
			stageStepTarget,
			retainerType,
			errorStatus,
		}

		this.setState(
			{
				serviceStageConfig: newServiceStageConfig,
				serviceStageDeliverableList: newDescription,
			},
			() => {
				const { proposalResult, serviceGstDetails, serviceResult } = this.state
				const { proposalDetails } = proposalResult

				const { totalDuration, proposalFeeData } = proposalFeeCalculation(
					this.state,
					proposalDetails,
					serviceGstDetails
				)
				const proposalDataResult = proposalResultDetails(
					proposalResult,
					serviceResult,
					proposalFeeData,
					totalDuration
				)

				this.setState({ proposalResult: proposalDataResult })
			}
		)
	}

	// Add percentage in proposal Result
	editPaymentPercentage = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }
		result.proposalDetails.paymentPercentage.percentage = parseInt(data)
		this.setState({ proposalResult: result })
	}

	// Add percentage estimation cost in proposal Result
	editPaymentEstimationCost = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }
		result.proposalDetails.paymentPercentage.estimationCostPerSqft = parseInt(data)
		this.setState({ proposalResult: result })
	}

	// Add No of site visits in Essential
	noOfSiteVisitInEssential = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }
		result.proposalDetails.siteVisit = data
		this.setState({ proposalResult: result })
	}

	// Add No of site visits in uncompromise
	noOfSiteVisitInUncompromise = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }

		result.proposalDetails.siteVisit = data
		this.setState({ proposalResult: result })
	}

	// Add Lumpsum cost in proposal Result
	editLumpsumCost = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }
		result.proposalDetails.lumpsumAmount = parseInt(data)
		this.setState({ proposalResult: result })
	}

	// Add GST details
	addGstDetails = (data) => {
		// Deconstruct state
		const { proposalResult, serviceGstDetails } = this.state
		const gstResult = []
		for (let gst = 0; gst < serviceGstDetails.length; gst += 1) {
			if (data.includes(serviceGstDetails[gst].gstId)) {
				const gstObject = {
					gstId: serviceGstDetails[gst].gstId,
					gstPercentage: serviceGstDetails[gst].percent,
				}

				gstResult.push(gstObject)
			}
		}
		const result = { ...proposalResult }
		result.proposalDetails.gstDetails = gstResult
		this.setState({ proposalResult: result })
	}

	// Add GST details
	addPaymentType = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }
		result.proposalDetails.paymentType = data
		this.setState({ proposalResult: result })
	}

	// Add GST Status
	addGstStatus = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }
		result.proposalDetails.gstStatus = data
		this.setState({ proposalResult: result })
	}

	// Add discount details
	addDiscountDetails = (data) => {
		// Deconstruct state
		const { proposalResult } = this.state
		const result = { ...proposalResult }
		result.proposalDetails.discount = data
		this.setState({ proposalResult: result })
	}

	addPerSqftRate = (data) => {
		const { serviceResult } = this.state
		const result = { ...serviceResult }
		result.perSqftRate = Number(data)
		this.setState({ serviceResult: result })
	}

	addDesignTimeDuration = (data) => {
		const { serviceResult } = this.state
		const result = { ...serviceResult }
		result.timeDuration = data
		this.setState({ serviceResult: result })
	}

	setServiceSwot = (data) => {
		this.setState({ serviceSwot: data })
	}

	// Stage description callback function
	setStageDescription = async (data, scopeOldId, index) => {
		const { serviceStageConfig, serviceStageDeliverableList } = this.state
		const { arrayStageDeliverable, objectStageDeliverable } = serviceStageDeliverableList
		const newState = { ...serviceStageConfig }
		newState.stageStepSource[index].scopeDescription = data

		let newArrayStageDescription = Object.assign({}, arrayStageDeliverable)
		for (let x = 0; x < arrayStageDeliverable.length; x++) {
			const { scopeId } = arrayStageDeliverable[x]
			if (scopeId === scopeOldId) {
				newArrayStageDescription[x].scopeDescription = data
			}
		}

		let newObjectStageDescription = Object.assign({}, objectStageDeliverable)
		for (let x = 0; x < objectStageDeliverable.length; x++) {
			const { scopeId } = objectStageDeliverable[x]
			if (scopeId === scopeOldId) {
				newObjectStageDescription[x].scopeDescription = data
			}
		}

		const newDescription = {
			objectStageDeliverable: Object.values(newObjectStageDescription),
			arrayStageDeliverable: Object.values(newArrayStageDescription),
		}

		this.setState({
			serviceStageConfig: newState,
			serviceStageDeliverableList: newDescription,
		})
	}

	// Team title callback function
	setTeamTitle = async (data, index) => {
		const { serviceTeamDescription } = this.state
		const newState = { ...serviceTeamDescription }
		newState.teamDescription[index].title = data
		this.setState({ serviceTeamDescription: newState })
	}

	// Team description callback function
	setTeamDescription = async (data, index) => {
		const { serviceTeamDescription } = this.state
		const newState = { ...serviceTeamDescription }
		newState.teamDescription[index].description = data
		this.setState({ serviceTeamDescription: newState })
	}

	// Insert new service data in backend
	/**
	 * @type    - function
	 * @param   - null
	 * @summary - Insert newly created service in DB
	 * @return  - null
	 */
	generateNewProposal = async () => {
		// Deconstruct NewProposal state
		const {
			typologyName,
			serviceId,
			enquiryId,
			serviceScope,
			serviceDeliverable,
			serviceStageDeliverableList,
			serviceSpace,
			serviceStageConfig,
			serviceSwot,
			totalSqft,
			typologyId,
			noOfStage,
			proposalResult,
			serviceResult,
			serviceTeamDescription,
			selectedPackage,
			serviceTermsAndCondition,
			feesPackage,
		} = this.state

		const { client } = this.props

		// Mutation
		// Create new service mutation
		const generateProposal = await client.mutate({
			mutation: GENERATE_NEW_PROPOSAL,
			variables: {
				typologyId,
				typologyName,
				serviceId,
				enquiryId,
				totalSqft,
				noOfStage,
				selectedPackage,
				serviceScopeConfig: JSON.stringify(serviceScope),
				serviceDeliverableConfig: JSON.stringify(serviceDeliverable),
				serviceSpaceConfig: JSON.stringify(serviceSpace),
				serviceStageConfig: JSON.stringify(serviceStageConfig),
				serviceDeliverableList: JSON.stringify(serviceStageDeliverableList),
				proposalResult: JSON.stringify(proposalResult),
				serviceSwot: JSON.stringify(serviceSwot),
				servicePriceConfig: JSON.stringify(serviceResult),
				serviceTeamDescription: JSON.stringify(serviceTeamDescription),
				serviceTermsAndCondition: JSON.stringify(serviceTermsAndCondition),
				feesPackageFeatures: JSON.stringify(feesPackage),
			},
		})

		if (generateProposal.data) {
			// Set changes in NewProposal state
			const { generateNewProposal } = generateProposal.data
			this.setState({
				insertStatus: true,
				generateStatus: false,
				proposalLink: `http://proposal-preview.designqube.com.s3-website.ap-south-1.amazonaws.com/${generateNewProposal}`,
			})
			window.open(
				`http://proposal-preview.designqube.com.s3-website.ap-south-1.amazonaws.com/${generateNewProposal}`,
				'_blank'
			)
		}
	}

	setServiceTermsAndCondition = (data) => {
		this.setState({ serviceTermsAndCondition: data })
	}

	setServiceName = (data) => {
		const { serviceResult, proposalResult } = this.state
		const result = { ...serviceResult }
		const copyResult = { ...proposalResult }
		result.serviceName = data
		copyResult.proposalDetails.serviceName = data
		this.setState({ serviceResult: result, proposalResult: copyResult })
	}

	setCoverTitle = (data) => {
		const { serviceResult, proposalResult } = this.state
		const result = { ...serviceResult }
		const copyResult = { ...proposalResult }
		result.coverTitle = data
		copyResult.proposalDetails.coverTitle = data
		this.setState({ serviceResult: result, proposalResult: copyResult })
	}

	setFeeheadName = (data) => {
		const { serviceResult, proposalResult } = this.state
		const result = { ...serviceResult }
		const copyResult = { ...proposalResult }
		result.feeHeadName = data
		copyResult.proposalDetails.feeHeadName = data
		this.setState({ serviceResult: result, proposalResult: copyResult })
	}

	saveServiceTemplate = async () => {
		this.setState({ modalVisible: true })
	}

	handleOk = () => {
		this.setState({
			modalVisible: false,
		})
	}

	handleCancel = () => {
		this.setState({
			modalVisible: false,
		})
	}

	// Insert new service data in backend
	/**
	 * @type    - function
	 * @param   - null
	 * @summary - Insert newly created service in DB
	 * @return  - null
	 */
	createNewService = async () => {
		// Deconstruct CreateNewService state
		const { client } = this.props
		const {
			serviceScope,
			serviceDeliverable,
			serviceStageDeliverableList,
			serviceSpace,
			serviceStageConfig,
			saveServiceResult,
			serviceTeamDescription,
			serviceTermsAndCondition,
		} = this.state

		if (
			saveServiceResult.elasticPrice === undefined ||
			saveServiceResult.paymentPercentage === undefined
		) {
			saveServiceResult.elasticPrice = [
				{ sqft: '500', amount: 500, time: 45 },
				{ sqft: '1000', amount: 450, time: 50 },
				{ sqft: '2500', amount: 400, time: 60 },
				{ sqft: '10000', amount: 300, time: 75 },
				{ sqft: '50000', amount: 200, time: 900 },
			]
			saveServiceResult.paymentPercentage = {
				percentage: 15,
				estimationCostPerSqft: 1000,
			}
		}

		this.setState({ serviceStatus: true })

		// Mutation
		// Create new service mutation
		const serviceMutation = await client.mutate({
			mutation: CREATE_NEW_SERVICE_MUTATION,
			variables: {
				serviceScopeConfig: JSON.stringify(serviceScope),
				serviceDeliverableConfig: JSON.stringify(serviceDeliverable),
				serviceSpaceConfig: JSON.stringify(serviceSpace),
				serviceStageConfig: JSON.stringify(serviceStageConfig),
				servicePriceConfig: JSON.stringify(saveServiceResult),
				serviceDeliverableList: JSON.stringify(serviceStageDeliverableList),
				serviceTeamDescription: JSON.stringify(serviceTeamDescription),
				serviceTermsAndCondition: JSON.stringify(serviceTermsAndCondition),
				serviceId: null,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_PROPOSAL_SERVICE_LIST_QUERY,
					variables: {
						searchString: '',
					},
				},
			],
		})

		const { createNewService } = serviceMutation.data
		if (createNewService) {
			// Set changes in CreateNewService state
			message.success('Template saved successfully.')
			this.setState({ serviceStatus: false, modalVisible: false })
		} else {
			message.error('Something went wrong.Try again.')
		}
	}

	changeDeliverableStage = (data, scopeName) => {
		this.setState({ serviceStageDeliverableList: data }, () => {
			notification.success({
				message: `Deliverable moved to ${scopeName}.`,
				placement: 'bottomLeft',
			})
		})
	}

	editProposalDocument = () => {
		this.setState({ insertStatus: false })
	}

	// Stage description callback function
	editStageName = async (data, scopeOldId, index) => {
		const { serviceStageConfig, serviceStageDeliverableList } = this.state
		const { arrayStageDeliverable, objectStageDeliverable } = serviceStageDeliverableList
		const newState = { ...serviceStageConfig }
		newState.stageStepSource[index].scopeName = data

		let newArrayStageDescription = Object.assign({}, arrayStageDeliverable)
		for (let x = 0; x < arrayStageDeliverable.length; x++) {
			const { scopeId } = arrayStageDeliverable[x]
			if (scopeId === scopeOldId) {
				newArrayStageDescription[x].scopeName = data
			}
		}

		let newObjectStageDescription = Object.assign({}, objectStageDeliverable)
		for (let x = 0; x < objectStageDeliverable.length; x++) {
			const { scopeId } = objectStageDeliverable[x]
			if (scopeId === scopeOldId) {
				newObjectStageDescription[x].scopeName = data
			}
		}

		for (let x = 0; x < serviceStageConfig.stageStepTarget.length; x++) {
			const { scopeId } = serviceStageConfig.stageStepTarget[x]
			if (scopeId === scopeOldId) {
				newState.stageStepTarget[x].scopeName = data
			}
		}

		const newDescription = {
			objectStageDeliverable: Object.values(newObjectStageDescription),
			arrayStageDeliverable: Object.values(newArrayStageDescription),
		}

		this.setState({
			serviceStageConfig: newState,
			serviceStageDeliverableList: newDescription,
		})
	}

	deleteDeliverableList = (data) => {
		this.setState({ serviceStageDeliverableList: data }, () => {
			notification.success({
				message: `Deliverable deleted.`,
				placement: 'bottomLeft',
			})
		})
	}

	// New Service Component Next Button
	next() {
		const { current } = this.state
		const currentIndex = current + 1
		// Set changes in NewProposal state
		this.setState({ current: currentIndex })
	}

	moveToStepTwo() {
		this.setState({ current: 2 })
	}

	// New Service Component Previous Button
	prev() {
		const { current } = this.state
		const currentIndex = current - 1

		// Set changes in NewProposal state
		this.setState({ current: currentIndex })
	}

	setTypologyId = (data) => {
		const { serviceSpace } = this.state
		const { typology } = serviceSpace

		const typologyDetails = typology.filter((value) => {
			return value.typologyId === data
		})

		const { typologyId, typologyName } = typologyDetails[0]
		serviceSpace.typologyId = typologyId

		this.setState({ typologyId, typologyName, serviceSpace })
	}

	setProposalPackage = ({ selectedPackage }) => {
		this.setState({ selectedPackage })
	}

	setFeesPackage = (feesPackage) => {
		this.setState({ feesPackage })
	}

	render() {
		// Deconstruct NewProposal state
		const {
			current,
			typologyId,
			insertStatus,
			generateStatus,
			typologyName,
			serviceId,
			noOfStage,
			serviceScope,
			serviceDeliverable,
			serviceStageDeliverableList,
			serviceSpace,
			serviceStageConfig,
			serviceResult,
			serviceSwot,
			totalSqft,
			proposalResult,
			serviceGstDetails,
			serviceTeamDescription,
			serviceClientDetails,
			serviceTermsAndCondition,
			serviceStatus,
			proposalLink,
			proposalPackage,
			selectedPackage,
			modalVisible,
			feesPackage,
		} = this.state

		const { proposalDetails } = proposalResult
		const { name: clientName, location: projectLocation } = serviceClientDetails
		const { coverTitle, feeHeadName } = proposalDetails
		const { serviceName, paymentPercentage } = serviceResult

		// Create New Service step
		const steps = [
			{
				title: 'Choose service',
				content: (
					<ProposalServiceConfig
						typologyId={typologyId}
						noOfStage={noOfStage}
						typologyName={typologyName}
						serviceId={serviceId}
						setServiceConfig={this.setServiceConfig}
					/>
				),
			},

			{
				title: 'Choose Scope',
				content: (
					<ProposalScopeConfig
						serviceScope={serviceScope}
						setServiceScope={this.setServiceScope}
					/>
				),
			},
			{
				title: 'Configure Space',
				content: (
					<ProposalSpaceConfig
						setTypologyId={this.setTypologyId}
						serviceSpace={serviceSpace}
						setServiceSpace={this.setServiceSpace}
					/>
				),
			},
			{
				title: 'Choose Deliverable',
				content: (
					<ProposalDeliverableConfig
						serviceDeliverable={serviceDeliverable}
						setServiceDeliverable={this.setServiceDeliverable}
					/>
				),
			},
			{
				title: 'Configure stage',
				content: (
					<ProposalStageConfig
						serviceStageConfig={serviceStageConfig}
						serviceResult={serviceResult}
						totalSqft={totalSqft}
						setServiceStageConfig={this.setServiceStageConfig}
						setStageDescription={this.setStageDescription}
						serviceStageDeliverableList={serviceStageDeliverableList}
						setServiceDeliverableList={this.setServiceDeliverableList}
						editStageName={this.editStageName}
					/>
				),
			},
			{
				title: 'Deliverable List',
				content: (
					<ProposalDeliverableList
						setFeeCalculation={this.setFeeCalculation}
						serviceStageDeliverableList={serviceStageDeliverableList}
						setServiceDeliverableList={this.setServiceDeliverableList}
						changeDeliverableStage={this.changeDeliverableStage}
						deleteDeliverableList={this.deleteDeliverableList}
					/>
				),
			},
			{
				title: 'Fee Calculation',
				content: (
					<ProposalFeeCalculation
						serviceResult={serviceResult}
						proposalPackage={proposalPackage}
						selectedPackage={selectedPackage}
						setProposalPackage={this.setProposalPackage}
						proposalResult={proposalResult}
						setFeeCalculation={this.setFeeCalculation}
						editProposalStageName={this.editProposalStageName}
						editPaymentPercentage={this.editPaymentPercentage}
						editPaymentEstimationCost={this.editPaymentEstimationCost}
						editLumpsumCost={this.editLumpsumCost}
						clientName={clientName}
						projectLocation={projectLocation}
						coverTitle={coverTitle}
						feeHeadName={feeHeadName}
						serviceName={serviceName}
						sqft={totalSqft}
						paymentPercentage={paymentPercentage}
						serviceGstDetails={serviceGstDetails}
						addGstDetails={this.addGstDetails}
						addPaymentType={this.addPaymentType}
						noOfSiteVisitInEssential={this.noOfSiteVisitInEssential}
						noOfSiteVisitInUncompromise={this.noOfSiteVisitInUncompromise}
						addDiscountDetails={this.addDiscountDetails}
						addGstStatus={this.addGstStatus}
						addPerSqftRate={this.addPerSqftRate}
						addDesignTimeDuration={this.addDesignTimeDuration}
						setFeeheadName={this.setFeeheadName}
						setCoverTitle={this.setCoverTitle}
						setServiceName={this.setServiceName}
						feesPackage={feesPackage}
						setFeesPackage={this.setFeesPackage}
					/>
				),
			},
			{
				title: 'Team Description',
				content: (
					<ProposalTeamDescription
						serviceTeamDescription={serviceTeamDescription}
						setTeamTitle={this.setTeamTitle}
						setTeamDescription={this.setTeamDescription}
					/>
				),
			},
			{
				title: 'SWOT',
				content: (
					<ProposalSwot serviceSwot={serviceSwot} setServiceSwot={this.setServiceSwot} />
				),
			},
			{
				title: 'Terms and Conditions',
				content: (
					<ProposalTermsAndCondition
						proposalLink={proposalLink}
						insertStatus={insertStatus}
						serviceTermsAndCondition={serviceTermsAndCondition}
						setServiceTermsAndCondition={this.setServiceTermsAndCondition}
						editProposalDocument={this.editProposalDocument}
					/>
				),
			},
		]

		// Based on the component data enable next button
		const NextButtonComponent = () => {
			if (current < steps.length - 1) {
				if (current === 0) {
					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								onClick={() => this.moveToStepTwo()}
								disabled={!serviceId}>
								Next
							</Button>
						</>
					)
				}
				if (current === 1) {
					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								onClick={() => this.next()}
								disabled={!serviceScope.serviceScopeTypology.length}>
								Next
							</Button>
							{!serviceScope.serviceScopeTypology.length ? (
								<div className="hintDiv">
									<span className={'hintSpan'}>Hint * - Please select scope</span>
								</div>
							) : null}
						</>
					)
				}
				if (current === 2) {
					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								onClick={() => this.next()}
								disabled={!serviceSpace.spaceEditorJson.length}>
								Next
							</Button>
							{!serviceSpace.spaceEditorJson.length ? (
								<div className="hintDiv">
									<span className={'hintSpan'}>Hint * - Please generate space</span>
								</div>
							) : null}
						</>
					)
				}
				if (current === 3) {
					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								onClick={() => this.next()}
								disabled={!serviceDeliverable.serviceDeliverableCheckedKey.length}>
								Next
							</Button>
							{!serviceDeliverable.serviceDeliverableCheckedKey.length ? (
								<div className="hintDiv">
									<span className={'hintSpan'}>Hint * - Please select deliverable</span>
								</div>
							) : null}
						</>
					)
				}
				if (current === 4) {
					const {
						orderError,
						percentageError,
						selectionError,
					} = serviceStageConfig.errorStatus

					const { stageStepSource } = serviceStageConfig
					const stageStatus = serviceStageDescription(stageStepSource)

					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								onClick={() => this.next()}
								disabled={
									!(
										!orderError.error &&
										!percentageError.error &&
										!selectionError.error
									) || !stageStatus
								}>
								Next
							</Button>
							{!(!orderError.error && !percentageError.error && !selectionError.error) ||
							!stageStatus ? (
								<div className="hintDiv">
									<span className={'hintSpan'}>
										{!!percentageError.error
											? 'Hint * - Completion stage must be 100%'
											: !!selectionError.error
											? 'Hint * - Please drag and drop unselected stages in steps list'
											: !!orderError.error
											? 'Hint * - Stage order must be ascending order in steps list'
											: !stageStatus
											? 'Hint * - Please enter stage description'
											: null}
									</span>
								</div>
							) : null}
						</>
					)
				}

				if (current === 6) {
					const { proposalDetails } = proposalResult
					const { coverTitle, feeHeadName, serviceName } = proposalDetails

					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								disabled={
									serviceName === null ||
									serviceName === '' ||
									feeHeadName === null ||
									feeHeadName === '' ||
									coverTitle === null ||
									coverTitle === ''
								}
								onClick={() => this.next()}>
								Next
							</Button>

							<div className="hintDiv">
								<span className={'hintSpan'}>
									{serviceName === null || serviceName === ''
										? 'Hint * - Please enter template name'
										: feeHeadName === null || feeHeadName === ''
										? 'Hint * - Please enter feehead name'
										: coverTitle === null || coverTitle === ''
										? 'Hint * - Please enter cover title'
										: null}
								</span>
							</div>
						</>
					)
				}

				if (current === 7) {
					const { teamDescription } = serviceTeamDescription
					const teamStatus = serviceTeamDescriptionValidate(teamDescription)

					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								disabled={!teamStatus}
								onClick={() => this.next()}>
								Next
							</Button>
							{!teamStatus ? (
								<div className="hintDiv">
									<span className={'hintSpan'}>
										Hint * - Please fill all title and description
									</span>
								</div>
							) : null}
						</>
					)
				}

				if (current === 8) {
					const { swotEditorJson } = serviceSwot

					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								disabled={!swotEditorJson.length}
								onClick={() => this.next()}>
								Next
							</Button>
							{!swotEditorJson.length ? (
								<div className="hintDiv">
									<span className={'hintSpan'}>Hint * - Please enter SWOT details</span>
								</div>
							) : null}
						</>
					)
				}

				if (current === 9) {
					const { termsJsonData } = serviceTermsAndCondition

					return (
						// Next Button
						<>
							<Button
								className="nextButton"
								type="primary"
								disabled={!termsJsonData.length}
								onClick={() => this.next()}>
								Next
							</Button>
							{!termsJsonData.length ? (
								<div className="hintDiv">
									<span className={'hintSpan'}>
										Hint * - Please enter terms and conditions
									</span>
								</div>
							) : null}
						</>
					)
				}

				return (
					// Next Button
					<Button className="nextButton" type="primary" onClick={() => this.next()}>
						Next
					</Button>
				)
			}
			if (!insertStatus) {
				// Create New Service button
				return (
					<div>
						<Button
							className="nextButton"
							type="primary"
							disabled={generateStatus}
							loading={generateStatus}
							onClick={() => {
								this.setState({ generateStatus: true }, () => {
									this.generateNewProposal()
								})
							}}>
							View preview and publish
						</Button>
						<Button
							className="nextButton"
							type="primary"
							loading={serviceStatus}
							onClick={() => {
								this.saveServiceTemplate()
							}}
							style={{ marginRight: 20 }}>
							Save as template
						</Button>
					</div>
				)
			}
			return null
		}

		return (
			// Create New Service div
			<div className="createNewProposal" style={{ margin: '2%' }}>
				{/** Service Steps list */}
				<Steps current={current}>
					{steps.map((item) => (
						<Step key={item.title} title={item.title} />
					))}
				</Steps>
				{/** Service Steps content */}
				<div className="steps-content">{steps[current].content}</div>

				{/** Service Steps action */}
				<div className="steps-action">
					{current > 0 && !insertStatus && (
						<Button className="previousButton" onClick={() => this.prev()}>
							Previous
						</Button>
					)}

					<NextButtonComponent />
					<Modal
						title="Save as template"
						visible={modalVisible}
						okText={
							<span>
								{serviceStatus ? <Icon type="loading" /> : null}
								&nbsp;&nbsp;Save
							</span>
						}
						onOk={this.createNewService}
						onCancel={this.handleCancel}>
						<div>
							<div className={'serviceResultInput'}>
								<Input
									addonBefore="Template Name"
									defaultValue={this.state.saveServiceResult.serviceName}
									value={this.state.saveServiceResult.serviceName}
									placeholder={'Enter template name'}
									onChange={(e) => {
										const { saveServiceResult } = this.state
										saveServiceResult.serviceName = e.target.value
										this.setState({ saveServiceResult })
									}}
								/>
								<Tooltip
									placement="bottom"
									title={'This name will be not visible to the proposal document.'}>
									<IoIosInformationCircle
										className={'serviceInputMessage'}
										color={'red'}
									/>
								</Tooltip>
							</div>
							<div className={'serviceResultInput'}>
								<Input
									addonBefore="Feehead Name"
									placeholder={'Enter feehead name'}
									defaultValue={this.state.saveServiceResult.feeHeadName}
									value={this.state.saveServiceResult.feeHeadName}
									onChange={(e) => {
										const { saveServiceResult } = this.state
										saveServiceResult.feeHeadName = e.target.value
										this.setState({ saveServiceResult })
									}}
								/>
								<Tooltip
									placement="bottom"
									title={'This name will be visible to the proposal document.'}>
									<IoIosInformationCircle
										className={'serviceInputMessage'}
										color={'green'}
									/>
								</Tooltip>
							</div>
							<div className={'serviceResultInput'}>
								<Input
									addonBefore="Cover Title"
									placeholder={'Enter cover title'}
									defaultValue={this.state.saveServiceResult.coverTitle}
									value={this.state.saveServiceResult.coverTitle}
									onChange={(e) => {
										const { saveServiceResult } = this.state
										saveServiceResult.coverTitle = e.target.value
										this.setState({ saveServiceResult })
									}}
								/>
								<Tooltip
									placement="bottom"
									title={'This name will be visible to the proposal document.'}>
									<IoIosInformationCircle
										className={'serviceInputMessage'}
										color={'green'}
									/>
								</Tooltip>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		)
	}
}

// export NewService component
export default withApollo(NewProposal)
