/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Button, Input } from 'antd'
// Import required components from ReactIcons
import { IoMdThumbsUp, IoMdSend } from 'react-icons/io'

// Query component of React Apollo to fetch data
import { Mutation } from 'react-apollo'

// Query to fetch Site Photos Details
import { CHANGECOMMENTSTATUS, REOPENADDCOMMENT } from './query'
import { GET_COMMENTS, ADDCOMMENT, closedCount } from '../query'

// Import Custom Styles
import styles from './styles'

/**
 * This class component is used to reply input section display for webapp.It should be
 * open for all unsolved & solved discussion.
 * @returns {comment reply section}
 */

class UnresolvedCommentSection extends React.Component {
	// initial state
	state = {
		unresolvedReplay: false, // unresolved reply section open state
		resolvedReplay: false, //  resolved reply section open state
		childComment: '', //  unresolved input value
		childCommentResolved: '', // resolved input value
	}

	unresolvedReplay = () => {
		this.setState({ unresolvedReplay: true })
	}

	unresolvedCommentChange = (e) => {
		const { childComment } = this.state
		this.setState({ childComment: e.target.value })
		if (childComment.length === 1) {
			this.setState({ unresolvedReplay: true })
		}
	}

	clearUnsolvedInput = () => {
		this.setState({ childComment: '' })
	}

	resolvedReplay = () => {
		this.setState({ resolvedReplay: true })
	}

	resolvedCommentChange = (e) => {

    const { childCommentResolved } = this.state
    this.setState({ childCommentResolved: e.target.value })
    if (childCommentResolved.length === 1) {
			this.setState({ resolvedReplay: true })
    }
	}

	clearResolvedInput = () => {
		this.setState({ childCommentResolved: '' })
	}

	resolvedBacktoReplay = () => {
		this.setState({ unresolvedReplay: false })
	}

	backtoReplay = () => {
		this.setState({ unresolvedReplay: false })
	}

	render() {
		// Deconstructing a state
		const {
			unresolvedReplay,
			resolvedReplay,
			childComment,
			childCommentResolved,
		} = this.state
    const { status, data, teamClientStatus, userHashState, projectUrl } = this.props
    
    const checkUnresloved = childComment.trim() !== ''
    const checkResolved = childCommentResolved.trim() !== ''
		return (
			<>
				{status === 'unresolved' ? (
					<div style={styles.commentReplay}>
						{checkUnresloved === true ? null : (
							<div style={styles.closebutton}>
								<Mutation mutation={CHANGECOMMENTSTATUS}>
									{(changeCommentStatus, { loading: deleteLoading }) => {
										return (
											<Button
												type="primary"
												style={styles.commentCloseButton}
												onClick={async () => {
													if (deleteLoading) {
														return
													}
													const variables = {
														hash: userHashState,
														status: 'resolved',
														commentId: data.id,
													}
                          // console.log(data);

													await changeCommentStatus({
														variables,
														awaitRefetchQueries: true,
														refetchQueries: [
															{
																query: closedCount,
																variables: {
																	type: 'photo',
																	productId: null,
																	variantId: null,
																	spaceId: data.spaceId,
																	feedId: data.feedId,
																	imageId: data.imageId,
																	status: 'resolved',
																},
															},
															{
																query: GET_COMMENTS,
																variables: {
																	hash: userHashState,
																	type: 'photo',
																	feedId: data.feedId,
																	imageId: data.imageId,
																	spaceId: data.spaceId,
																	productId: null,
																	variantId: null,
																	role: 'user',
																	status: 'unresolved',
																},
															},
															{
																query: GET_COMMENTS,
																variables: {
																	hash: userHashState,
																	type: 'photo',
																	feedId: data.feedId,
																	imageId: data.imageId,
																	spaceId: data.spaceId,
																	productId: null,
																	variantId: null,
																	role: 'user',
																	status: 'resolved',
																},
															},
														],
													})
												}}>
												Close Ticket
											</Button>
										)
									}}
								</Mutation>
							</div>
						)}

						<div>
							<Mutation mutation={ADDCOMMENT}>
								{(addComment, { loading: deleteLoading }) => {
									return (
										<Input
											placeholder="Reply to ticket"
											style={styles.replayInput}
											value={childComment}
											onChange={(e) => this.unresolvedCommentChange(e)}
											// onClick={(e) => this.unresolvedReplay(e)}
											onPressEnter={() => {
												if (childComment.trim() === '') {
													return
												}
												if (deleteLoading) {
													return
												}
												const variables = {
													projectUrl,
													hash: userHashState,
													text: childComment,
													level: 'child',
													type: 'photo',
													commentType:
														teamClientStatus === 'team' ? 'discussion' : 'issue',
													productId: null,
													variantId: null,
													spaceId: data.spaceId,
													feedId: data.feedId,
													imageId: data.imageId,
													parentId: data.id,
													mentionedTo: [],
												}

												addComment({
													variables,
													refetchQueries: [
														{
															query: GET_COMMENTS,
															variables: {
																hash: userHashState,
																type: 'photo',
																feedId: data.feedId,
																imageId: data.imageId,
																spaceId: data.spaceId,
																productId: null,
																variantId: null,
																role: 'user',
																status: 'unresolved',
															},
														},
													],
												})
												this.backtoReplay()
												this.clearUnsolvedInput()
											}}
										/>
									)
								}}
							</Mutation>
						</div>

						{checkUnresloved === true ? (
							<div style={styles.thumsup}>
								<Mutation mutation={ADDCOMMENT}>
									{(addComment, { loading: deleteLoading }) => {
										return (
											<Button
												type="primary"
												shape="circle"
												style={styles.thumsUpBackground}
												onClick={() => {
													if (childComment.trim() === '') {
														return
													}
													if (deleteLoading) {
														return
													}
													const variables = {
														projectUrl,
														hash: userHashState,
														text: childComment,
														level: 'child',
														type: 'photo',
														commentType:
															teamClientStatus === 'team' ? 'discussion' : 'issue',
														productId: null,
														variantId: null,
														spaceId: data.spaceId,
														feedId: data.feedId,
														imageId: data.imageId,
														parentId: data.id,
														mentionedTo: [],
													}

													addComment({
														variables,
														refetchQueries: [
															{
																query: GET_COMMENTS,
																variables: {
																	hash: userHashState,
																	type: 'photo',
																	feedId: data.feedId,
																	imageId: data.imageId,
																	spaceId: data.spaceId,
																	productId: null,
																	variantId: null,
																	role: 'user',
																	status: 'unresolved',
																},
															},
														],
													})
													this.backtoReplay()
													this.clearUnsolvedInput()
												}}>
												<IoMdSend style={styles.sendIcon} />
											</Button>
										)
									}}
								</Mutation>
							</div>
						) : (
							<div style={styles.thumsup}>
								<Mutation mutation={ADDCOMMENT}>
									{(addComment, { loading: deleteLoading }) => {
										return (
											<Button
												type="primary"
												shape="circle"
												style={styles.thumsUpBackground}
												onClick={() => {
													if (deleteLoading) {
														return
													}
													const variables = {
														projectUrl,
														hash: userHashState,
														text: 'ðŸ‘',
														level: 'child',
														type: 'photo',
														commentType:
															teamClientStatus === 'team' ? 'discussion' : 'issue',
														productId: null,
														variantId: null,
														spaceId: data.spaceId,
														feedId: data.feedId,
														imageId: data.imageId,
														parentId: data.id,
														mentionedTo: [],
													}

													addComment({
														variables,
														refetchQueries: [
															{
																query: GET_COMMENTS,
																variables: {
																	hash: userHashState,
																	type: 'photo',
																	feedId: data.feedId,
																	imageId: data.imageId,
																	spaceId: data.spaceId,
																	productId: null,
																	variantId: null,
																	role: 'user',
																	status: 'unresolved',
																},
															},
														],
													})
												}}>
												<IoMdThumbsUp />
											</Button>
										)
									}}
								</Mutation>
							</div>
						)}
					</div>
				) : (
					<div style={styles.commentReplay}>
						<div>
							<Mutation mutation={REOPENADDCOMMENT}>
								{(reopenComment, { loading: deleteLoading }) => {
									return (
										<Input
											placeholder="Type to reopen"
											style={styles.replayInput}
											value={childCommentResolved}
											onChange={(e) => this.resolvedCommentChange(e)}
											// onClick={(e) => this.resolvedReplay(e)}
											onPressEnter={() => {
												if (childCommentResolved.trim() === '') {
													return
												}
												if (deleteLoading) {
													return
												}
												const variables = {
													projectUrl,
													hash: userHashState,
													text: childCommentResolved,
													level: 'child',
													type: 'photo',
													commentType:
														teamClientStatus === 'team' ? 'discussion' : 'issue',
													productId: null,
													variantId: null,
													spaceId: data.spaceId,
													feedId: data.feedId,
													imageId: data.imageId,
													parentId: data.id,
													status: 'unresolved',
													mentionedTo: [],
												}

												reopenComment({
													variables,
													refetchQueries: [
                            {
                              query: closedCount,
                              variables: {
                                type: 'photo',
                                productId: null,
                                variantId: null,
                                spaceId: data.spaceId,
                                feedId: data.feedId,
                                imageId: data.imageId,
                                status: 'resolved',
                              },
                            },
														{
															query: GET_COMMENTS,
															variables: {
																hash: userHashState,
																type: 'photo',
																feedId: data.feedId,
																imageId: data.imageId,
																spaceId: data.spaceId,
																productId: null,
																variantId: null,
																role: 'user',
																status: 'unresolved',
															},
														},
														{
															query: GET_COMMENTS,
															variables: {
																hash: userHashState,
																type: 'photo',
																feedId: data.feedId,
																imageId: data.imageId,
																spaceId: data.spaceId,
																productId: null,
																variantId: null,
																role: 'user',
																status: 'resolved',
															},
														},
													],
												})
												this.resolvedBacktoReplay()
												this.clearResolvedInput()
											}}
										/>
									)
								}}
							</Mutation>
						</div>

						{checkResolved === true ? (
							<div style={styles.thumsup}>
								<Mutation mutation={REOPENADDCOMMENT}>
									{(reopenComment, { loading: deleteLoading }) => {
										return (
											<Button
												type="primary"
												style={styles.reopen}
												onClick={() => {
													if (childCommentResolved.trim() === '') {
														return
													}
													if (deleteLoading) {
														return
													}
													const variables = {
														projectUrl,
														hash: userHashState,
														text: childCommentResolved,
														level: 'child',
														type: 'photo',
														commentType:
															teamClientStatus === 'team' ? 'discussion' : 'issue',
														productId: null,
														variantId: null,
														spaceId: data.spaceId,
														feedId: data.feedId,
														imageId: data.imageId,
														parentId: data.id,
														status: 'unresolved',
														mentionedTo: [],
													}

													reopenComment({
														variables,
														refetchQueries: [
                              {
																query: closedCount,
																variables: {
																	type: 'photo',
																	productId: null,
																	variantId: null,
																	spaceId: data.spaceId,
																	feedId: data.feedId,
																	imageId: data.imageId,
																	status: 'resolved',
																},
															},
															{
																query: GET_COMMENTS,
																variables: {
																	hash: userHashState,
																	type: 'photo',
																	feedId: data.feedId,
																	imageId: data.imageId,
																	spaceId: data.spaceId,
																	productId: null,
																	variantId: null,
																	role: 'user',
																	status: 'unresolved',
																},
															},
															{
																query: GET_COMMENTS,
																variables: {
																	hash: userHashState,
																	type: 'photo',
																	feedId: data.feedId,
																	imageId: data.imageId,
																	spaceId: data.spaceId,
																	productId: null,
																	variantId: null,
																	role: 'user',
																	status: 'resolved',
																},
															},
														],
													})
													this.resolvedBacktoReplay()
													this.clearResolvedInput()
												}}>
												Reopen
											</Button>
										)
									}}
								</Mutation>
							</div>
						) : (
							<div style={styles.thumsup}>
								<Mutation mutation={ADDCOMMENT}>
									{(addComment, { loading: deleteLoading }) => {
										return (
											<Button
												type="primary"
												shape="circle"
												style={styles.thumsUpBackground}
												onClick={() => {
													if (deleteLoading) {
														return
													}
													const variables = {
														projectUrl,
														hash: userHashState,
														text: 'ðŸ‘',
														level: 'child',
														type: 'photo',
														commentType:
															teamClientStatus === 'team' ? 'discussion' : 'issue',
														productId: null,
														variantId: null,
														spaceId: data.spaceId,
														feedId: data.feedId,
														imageId: data.imageId,
														parentId: data.id,
														mentionedTo: [],
													}

													addComment({
														variables,
														refetchQueries: [
															{
																query: GET_COMMENTS,
																variables: {
																	hash: userHashState,
																	type: 'photo',
																	feedId: data.feedId,
																	imageId: data.imageId,
																	spaceId: data.spaceId,
																	productId: null,
																	variantId: null,
																	role: 'user',
																	status: 'resolved',
																},
															},
														],
													})
												}}>
												<IoMdThumbsUp />
											</Button>
										)
									}}
								</Mutation>
							</div>
						)}
					</div>
				)}
			</>
		)
	}
}

export default UnresolvedCommentSection
