const styles = {
	chatSearchNotFound: {
		fontSize: 14,
		textAlign: 'center',
		opacity: 0.5,
		marginTop: 10,
	},
	positionRelative: {
		position: 'relative',
	},
	list: {
		addNewConversation: {
			position: 'absolute',
			marginLeft: '22%',
			marginTop: '-17%',
			background: '#00bfa5',
			padding: 6,
			borderRadius: 50,
			height: 40,
			width: 40,
			cursor: 'pointer',
			top: '120vh',
		},
	},
	chatListSearch: {
		searchIcon: {
			width: '15px',
			position: 'absolute',
			bottom: '18px',
			left: '20px',
			fontSize: '15px',
		},
	},
	chatListItem: {
		selectedBackground: {
			background: '#f1f1f1',
		},
		unselectedBackground: {
			background: '#ffffff',
		},
		chatListDiv: {
			display: 'grid',
			gridTemplateColumns: '100%',
			margin: 'auto',
		},
		chatListProfilePic: {
			borderRadius: '50%',
			width: '50px',
			height: '50px',
			alignSelf: 'center',
			justifySelf: 'center',
			objectFit: 'fill',
		},
		chatListEmptyProfilePic: {
			height: 50,
			width: 50,
			padding: 5,
			fontSize: 22,
			verticalAlign: 'middle',
		},
		chatSelectedList: {
			width: '100%',
			alignSelf: 'center',
			justifySelf: 'start',
			':hover': {
				cursor: 'pointer',
			},
		},
		chatPaddingLeft: { paddingLeft: '10px' },
		chatLastMessage: {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			color: 'rgba(0, 0, 0, 0.6)',
			fontSize: '0.9em',
			margin: '0px',
		},
		chatStatus: {
			color: 'rgba(0, 0, 0, 0.4)',
			fontSize: '0.8em',
			display: 'grid',
			height: '50%',
			width: '100%',
			alignSelf: 'center',
		},
		chatLastMessageTime: {
			alignSelf: 'start',
			justifySelf: 'center',
		},
		unreadMessageCount: {
			width: 25,
			height: 25,
			borderRadius: '50%',
			fontSize: 10,
			color: '#fff',
			lineHeight: 2.5,
			textAlign: 'center',
			background: '#06d755',
			placeSelf: 'center',
		},
		chatListImageIcon: {
			opacity: 0.5,
		},
	},
}

export default styles
