// Import Components
import React from 'react'
import { Layout, Tooltip } from 'antd'

// import NotificationPopover from '../NotificationPopover'
import UserProfile from '../UserProfile'
import ProfileDrawer from '../ProfileDrawer'
// import NotificationDrawer from '../NotificationDrawer'
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'
// Import Logo and Icon
import LogoName from '../../../assets/dq_full_logo.png'

// Import Custom CSS

import styles from './styles'

const { Header } = Layout

class HeaderMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			profileDrawer: false,
			notificationDrawer: false,
			notificationPopoverState: false,
		}
	}

	// Change profile drawer state
	// Close notification details drawer and popover, show profile details drawer
	profileDrawer = () => {
		this.setState((prevValue) => ({
			notificationDrawer: false,
			notificationPopoverState: false,
			profileDrawer: !prevValue.profileDrawer,
		}))
	}

	// Change notification drawer state
	// Close profile details drawer and notification popover, show notification details drawer
	notificationDrawer = () => {
		this.setState((prevValue) => ({
			profileDrawer: false,
			notificationPopoverState: false,
			notificationDrawer: !prevValue.notificationDrawer,
		}))
	}

	// Change notification popover state
	// Show and hide notification popover
	notificationPopover = () => {
		const { notificationPopoverState } = this.state
		this.setState({
			notificationPopoverState: !notificationPopoverState,
		})
	}

	// Close notification and profile details drawer
	closeAllDrawer = () => {
		this.setState({
			notificationDrawer: false,
			profileDrawer: false,
		})
	}

	render() {
		const { profileDrawer } = this.state
		const { showUserProfile, menuCollapse, firstMenuCollapsed } = this.props

		return (
			// Header Component
			<Header
				style={
					!showUserProfile ? styles.privateHeaderMenuStyle : styles.publicHeaderMenuStyle
				}>
				{/* DesignQube logo */}
				{!showUserProfile ? (
					<>
						<a href="https://designqube.com/" target="_blank">
							<img
								width={160}
								height={50}
								src={LogoName}
								alt="logo"
								style={styles.orgLogo}
								id="dq_logo"
							/>
						</a>
						<Tooltip
							title={!firstMenuCollapsed ? 'Hide Menu' : 'Show Menu'}
							placement="right">
							{!firstMenuCollapsed ? (
								<AiOutlineMenuFold
									style={{
										marginBottom: -4,
										marginLeft: 46,
										fontSize: 24,
										cursor: 'pointer',
									}}
									onClick={() => menuCollapse()}
								/>
							) : (
								<AiOutlineMenuUnfold
									style={{
										marginBottom: -4,
										marginLeft: 46,
										fontSize: 24,
										cursor: 'pointer',
									}}
									onClick={() => menuCollapse()}
								/>
							)}
						</Tooltip>
					</>
				) : (
					<div style={{ width: 200, margin: '0 auto' }}>
						<a href="https://designqube.com/" target="_blank">
							<img
								width={190}
								height={60}
								src={LogoName}
								alt="logo"
								style={styles.publicOrgLogo}
								id="dq_logo"
							/>
						</a>
					</div>
				)}
				{/* Profile Icon */}
				{!showUserProfile ? <UserProfile profileDrawer={this.profileDrawer} /> : null}

				{/* Profile Details Drawer */}
				{profileDrawer && (
					<ProfileDrawer visible={profileDrawer} profileDrawer={this.profileDrawer} />
				)}

				{/* Notification popover */}
				{/* <NotificationPopover
          closeAllDrawer={ this.closeAllDrawer }
          notificationDrawer={ this.notificationDrawer }
          notificationPopover={ this.notificationPopover }
          visible={ notificationPopoverState }
        /> */}

				{/* Notification Details Drawer */}
				{/* <NotificationDrawer
          visible={ notificationDrawer }
          notificationDrawer={ this.notificationDrawer }
        /> */}
			</Header>
		)
	}
}

export default HeaderMenu
