import React from 'react'
import { Row, Col, Spin, Icon, Avatar } from 'antd'
import { MdClose, MdDoneAll } from 'react-icons/md'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { MESSAGE_INFO } from '../query'
import { Query } from 'react-apollo'
import styles from './styles'
import pdfImage from '../images/pdf-image.jpeg'
import './index.css'

class MessageInfo extends React.Component {
	render() {
		const { handleModalCancel, hash, messageData, loader, groupId } = this.props
		const { createdAt, messageType, caption, content } = messageData

		return (
			<Modal type="messageInfo">
				<ModalHeader type="messageInfo">
					<div style={styles.modalHeaderFlex}>
						<span onClick={handleModalCancel} style={styles.modalHeaderTextColor}>
							<MdClose size={25} style={styles.modalHeaderIcon} />
						</span>
						<span style={styles.modalHeaderName}>Message Info</span>
					</div>
				</ModalHeader>
				<ModalButtonContainer type="messageInfo">
					<div className="messageInfo">
						<div>
							{messageType === 'text' ? (
								<div
									style={{
										backgroundColor: 'rgb(220, 248, 198)',
										marginLeft: '45%',
										padding: '5px',
										borderRadius: '8px',
										marginTop: 10,
										marginRight: '5%',
										maxHeight: 97,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}>
									{content}
								</div>
							) : null}
							{messageType === 'image' ? (
								<div
									style={{
										backgroundColor: 'rgb(220, 248, 198)',
										marginLeft: '45%',
										padding: '5px',
										borderRadius: '8px',
										marginTop: 10,
										marginRight: '5%',
									}}>
									<img src={content.split(',')[0]} width="100%" />
								</div>
							) : null}
							{messageType === 'pdf' ? (
								<div
									style={{
										backgroundColor: 'rgb(220, 248, 198)',
										marginLeft: '45%',
										padding: '5px',
										borderRadius: '8px',
										marginTop: 10,
										marginRight: '5%',
									}}>
									<div style={styles.messageInfo.messagePdfDiv}>
										<img src={pdfImage} height="30" width="35" style={{ marginTop: 8 }} />
										<div style={styles.messageInfo.messagePdfCaption}>{caption}</div>
									</div>
								</div>
							) : null}
						</div>

						<div
							style={{
								margin: 10,
								borderTop: '1px solid lightgray',
								padding: '15px',
							}}>
							<div style={{ float: 'left' }}>Read By</div>
							<div style={{ float: 'right' }}>
								<MdDoneAll size={25} />
							</div>
						</div>
						<Row>
							<div style={styles.messageInfo.forwardMessageModalHeight}>
								<Query
									query={MESSAGE_INFO}
									variables={{
										hash: hash,
										groupId,
										time: createdAt,
									}}>
									{({ loading, error, data }) => {
										if (loading) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.centerAlign}>
															<Spin indicator={<Icon type="loading" spin />} />
														</div>
													</div>
												</div>
											)
										}
										if (!data || !data.messageInfo.length) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.messageInfo.membersNotFound}>
															<h3></h3>
														</div>
													</div>
												</div>
											)
										}

										return (
											<div style={styles.messageInfo.listMembersDiv}>
												{data.messageInfo.length ? (
													<>
														{data.messageInfo.map((people) => {
															const { hash, photo, name } = people

															return (
																<Row key={hash}>
																	<Col span={24}>
																		<div style={styles.messageInfo.listMembers}>
																			{photo ? (
																				<Avatar src={photo} alt="" />
																			) : (
																				<Avatar>{name.slice(0, 1).toUpperCase()}</Avatar>
																			)}

																			<h3
																				style={styles.messageInfo.listMemberName}
																				title={name}>
																				{name}
																			</h3>
																		</div>
																	</Col>
																</Row>
															)
														})}
													</>
												) : (
													<Row>
														<Col span={24} style={styles.messageInfo.listMembersNotFound}>
															No members found
														</Col>
													</Row>
												)}
											</div>
										)
									}}
								</Query>
							</div>
						</Row>
					</div>
				</ModalButtonContainer>
			</Modal>
		)
	}
}
export default MessageInfo
