const styles = {
	displayFlex: {
		display: 'flex',
	},
	displayGrid: { display: 'grid' },
	messageList: {
		messageStyleLeft: {
			fontSize: '0.9em',
			borderRadius: '8px',
			color: 'rgb(38, 38, 38)',
			padding: '5px',
			background: '#FFF',
			display: 'grid',
			gridTemplateColumns: 'auto',
		},
		messagesListWrapperStyleLeft: {
			padding: '2px 70px',
			maxWidth: '400px',
			alignSelf: 'flex-start',
		},
		messageListParentDiv: {
			height: '64vh',
			width: '100%',
		},
		messageListChildDiv: {
			height: '72vh',
			overflow: 'auto',
			display: 'flex',
			flexFlow: 'column-reverse',
			marginTop: 5,
		},
	},
	messageDropdown: {
		messageDropdownButton: {
			border: 'none',
			borderRadius: '50%',
			cursor: 'pointer',
			outline: 'none',
			background: 'transparent',
		},
		menuItemDiv: {
			padding: '0px 20px',
			width: '140px',
			textAlign: 'left',
		},
		menuItemPadding: {
			padding: '10px 0px',
		},
		menuItem: {
			color: 'rgb(68, 68, 68)',
			fontSize: '0.9em',
			cursor: 'pointer',
			width: '100%',
		},
	},
	message: {
		messageNameStyle: {
			background: 'rgb(255, 255, 255)',
			borderRadius: 10,
			padding: 0,
		},
		messageTextStyle: {
			display: 'flex',
			justifyContent: 'space-between',
			cursor: 'pointer',
			height: 28,
		},
		messageForwardIcon: { fontSize: 20, opacity: 0.5 },
		messageForwardText: { opacity: 0.5, fontStyle: 'italic' },
		messageReplyText: {
			marginBottom: 5,
			borderRadius: 5,
			padding: '10px 5px',
			overflowWrap: 'anywhere',
		},
		messageReplyPdfDiv: {
			display: 'flex',
			background: 'white',
			padding: 5,
			maxWidth: 235,
		},
		messageReplyPdfCaption: {
			margin: 5,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		messageImageDiv: { cursor: 'pointer', marginBottom: 10 },
		messageImage: { borderRadius: 5, marginTop: 3, objectFit: 'contain' },
		messagePdfDiv: {
			height: 50,
			background: 'white',
			display: 'flex',
			cursor: 'pointer',
			marginBottom: 10,
			borderRadius: 5,
		},
		messagePdfCaption: { wordBreak: 'break-word', margin: 5 },
		messageBreakWord: {
			wordWrap: 'break-word',
			overflow: 'hidden',
		},
		messageDoNotDisturbIcon: { fontSize: 16, marginRight: 5 },
		messageImageCaption: { whiteSpace: 'pre-wrap', marginRight: 50 },
		messagePdfCaptionText: { whiteSpace: 'pre-wrap', marginRight: 50 },
		messageDropdownList: {
			fontSize: '0.8em',
			alignSelf: 'end',
			justifySelf: 'end',
			color: 'rgba(0, 0, 0, 0.45)',
			marginTop: '-20px',
		},
		messageTime: {
			marginTop: -13,
			fontSize: '0.8em',
			alignSelf: 'end',
			justifySelf: 'end',
			color: 'rgba(0, 0, 0, 0.45)',
			cursor: 'pointer',
		},
		messageDeleteTime: {
			marginBottom: '-4px',
			fontSize: '0.8em',
			alignSelf: 'end',
			justifySelf: 'end',
			color: 'rgba(0, 0, 0, 0.45)',
		},
		messageIsTicket: {
			marginTop: '10px',
			background: '#ff0000ab',
			padding: '2px',
			color: 'white',
			fontSize: '12px',
			borderLeft: '2px solid gray',
		},
		messageLogDiv: {
			textAlign: 'center',
			margin: '10px auto',
			width: '80%',
		},
		messageLog: {
			backgroundColor: '#e1f5feeb',
			margin: 'auto',
			borderRadius: 7.5,
			padding: 4,
			width: 'fit-content',
		},
		messageLogText: {
			padding: '5px 12px 6px 12px',
			wordWrap: 'break-word',
			whiteSpace: 'pre-wrap',
		},
	},
}

export default styles
