const styles = {
	modalHeaderName: {
		fontSize: 18,
		color: 'white',
		margin: 5,
	},
	modalHeaderIcon: {
		margin: 5,
		cursor: 'pointer',
	},
	modalHeaderTextColor: {
		color: 'white',
	},
	modalHeaderFlex: {
		display: 'flex',
	},
	searchIcon: {
		width: '15px',
		position: 'absolute',
		bottom: '18px',
		left: '25px',
		top: '18px',
		fontSize: '15px',
	},
	fontWeightBold: { fontWeight: 'bold' },
	displayFlex: {
		display: 'flex',
	},
	centerAlign: {
		textAlign: 'center',
	},
	visibilityHidden: { visibility: 'hidden' },
	positionRelative: { position: 'relative' },
	fullWidth: { width: '100%' },

	addGroupParticipant: {
		groupSelectedMemberList: {
			margin: '10px 5px',
			minHeight: 20,
			maxHeight: 114,
			overflow: 'scroll',
			paddingBottom: 10,
			borderBottom: '1px solid lightgray',
		},
		groupSelectedMemberName: {
			display: 'inline-flex',
			width: 'fit-content',
			background: '#d8e2dd',
			borderRadius: 20,
			padding: '5px 12px',
			color: 'black',
			margin: 2,
		},
		groupSelectedMemberCloseIcon: {
			marginLeft: 7,
			background: 'lightgray',
			borderRadius: 10,
			cursor: 'pointer',
		},
		groupMemberNotFound: { textAlign: 'center', padding: 5, opacity: 0.5 },
		listGroupMember: {
			minHeight: '16vh',
			maxHeight: '45vh',
			overflow: 'scroll',
		},
		unorderedListGroupMember: { listStyle: 'none', margin: 0, padding: 0 },
		groupMember: {
			width: '90%',
			padding: '10px 10px',
			boxSizing: 'border-box',
			background: '#ffffff',
			margin: 'auto',
			marginBottom: 4,
			color: '#5d5858',
			fontSize: 15,
			display: 'flex',
			cursor: 'pointer',
			borderBottom: '0.5px solid lightgray',
		},
		groupMemberName: {
			marginLeft: 20,
			marginTop: 3,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		searchGroupParticipant: {
			width: '90%',
			padding: '10px 10px',
			boxSizing: 'border-box',
			background: '#ffffff',
			margin: 'auto',
			justifyContent: 'center',
			color: '#5d5858',
			fontSize: 15,
			display: 'flex',
			cursor: 'pointer',
		},
		searchResult: {
			marginLeft: 20,
			marginTop: 3,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		addNewParticipant: {
			bottom: 30,
			position: 'absolute',
			marginLeft: '75%',
			background: '#09e85e',
			color: 'white',
			height: 50,
			width: 50,
			borderRadius: '50%',
			cursor: 'pointer',
		},
		updateIcon: { margin: '10px' },
	},
	addNewGroup: {
		groupType: {
			marginBottom: 10,
			width: '100%',
		},
		groupImageDiv: {
			height: 150,
			width: 150,
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			textAlign: 'center',
			background: 'gray',
			borderRadius: '50%',
			margin: 'auto',
			color: 'white',
			cursor: 'pointer',
			marginBottom: 25,
			marginTop: 10,
		},
		groupImage: {
			height: 150,
			width: 150,
			borderRadius: '50%',
			cursor: 'pointer',
		},
		groupDetailsDiv: { padding: '0px 50px' },
		groupInputField: {
			display: 'block',
			width: '100%',
			fontSize: 13,
			padding: '10px 10px',
			margin: '10px 0px',
			borderRadius: 5,
			border: '1px solid #ddd',
			marginRight: 10,
			height: '32px',
		},
		groupMemberNotFound: { textAlign: 'center', padding: 5, opacity: 0.5 },
		groupMemberNameDiv: { marginBottom: 10, marginTop: 10 },
		groupMemberName: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		createNewGroup: {
			background: '#09e85e',
			color: 'white',
			height: 50,
			width: 50,
			borderRadius: '50%',
			margin: 'auto',
			cursor: 'pointer',
			marginTop: 10,
		},
		updateIcon: { margin: '10px' },
		updateLoader: {
			padding: 12,
		},
	},
	addParticipant: {
		participantNotFound: {
			textAlign: 'center',
			padding: 5,
			opacity: 0.5,
		},
		listParticipantDiv: { height: '62vh', overflow: 'scroll' },
		unorderedListParticipant: { listStyle: 'none', margin: 0, padding: 0 },
		addNewGroup: {
			width: '90%',
			padding: '10px 10px',
			boxSizing: 'border-box',
			background: '#ffffff',
			margin: 'auto',
			marginBottom: 4,
			color: '#5d5858',
			fontSize: 15,
			display: 'flex',
			cursor: 'pointer',
			borderBottom: '0.5px solid lightgray',
		},
		addNewGroupList: {
			marginTop: 3,
			marginLeft: 20,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		participantName: {
			marginLeft: 20,
			marginTop: 3,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		searchParticipantDiv: {
			width: '90%',
			padding: '10px 10px',
			boxSizing: 'border-box',
			background: '#ffffff',
			margin: 'auto',
			justifyContent: 'center',
			color: '#5d5858',
			fontSize: 15,
			display: 'flex',
			cursor: 'pointer',
			opacity: 0.5,
		},
		searchParticipant: {
			marginLeft: 20,
			marginTop: 3,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	createTicket: {
		ticketTitleDiv: { padding: '10px 50px' },
		ticketName: {
			display: 'block',
			width: '100%',
			fontSize: 13,
			padding: '5px 10px',
			margin: '10px 0px',
			borderRadius: 5,
			border: '1px solid #ddd',
			marginRight: 10,
		},
		ticketUpdateDiv: { padding: '10px 50px', textAlign: 'center' },
		closeTicket: { margin: '0px 25px' },
		sendTicket: {
			margin: '0px 25px',
			background: '#50c0a5',
			color: 'white',
			border: 'none',
		},
		deletePhoto: {
			textDecoration: 'underline',
			color: 'red',
			marginLeft: '82%',
			marginTop: '-17px',
			marginBottom: '10px',
			cursor: 'pointer',
		},
	},
	deleteMessage: {
		deleteMessageText: {
			fontSize: 15,
		},
	},
	editGroupInfo: {
		carouselClose: {
			position: 'absolute',
			top: 70,
			left: '90%',
			cursor: 'pointer',
		},
		groupImageDiv: {
			height: 150,
			width: 150,
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			textAlign: 'center',
			background: 'gray',
			borderRadius: '50%',
			margin: 'auto',
			color: 'white',
			cursor: 'pointer',
			marginBottom: 25,
			marginTop: 10,
		},
		groupImageSize: {
			height: 150,
			width: 150,
			borderRadius: '50%',
			cursor: 'pointer',
		},
		groupDetailsDiv: { padding: '0px 50px' },
		groupInputField: {
			display: 'block',
			width: '100%',
			fontSize: 13,
			padding: '10px 10px',
			margin: '10px 0px',
			borderRadius: 5,
			border: '1px solid #ddd',
			marginRight: 10,
			height: '32px',
		},
		groupMembersNotFound: {
			textAlign: 'center',
			padding: 5,
			opacity: 0.5,
			marginBottom: 10,
		},
		addGroupMemberDiv: {
			borderBottom: '1px solid lightgray',
			paddingBottom: 5,
			paddingTop: 10,
		},
		addGroupMember: { display: 'flex', cursor: 'pointer' },
		addParticipant: {
			marginTop: 3,
			marginLeft: 10,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		groupMemberList: {
			borderBottom: '1px solid lightgray',
			paddingBottom: 5,
			paddingTop: 10,
			cursor: 'pointer',
		},
		groupMemberName: {
			margin: '7px 5px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		groupAdminStyle: {
			border: '1px solid green',
			textAlign: 'center',
			color: 'green',
			borderRadius: 6,
			margin: '5px 0px',
			marginLeft: '10px',
		},

		notGroupAdminStyle: {
			border: '1px solid white',
			textAlign: 'center',
			color: 'white',
			borderRadius: 6,
			margin: '5px 0px',
			marginLeft: '10px',
		},
		showGroupInfoDropdown: {
			margin: '5px 14px',
			opacity: 1,
		},
		hideGroupInfoDropdown: {
			margin: '5px 14px',
			opacity: 0,
		},
		updateGroupInfo: {
			background: '#09e85e',
			color: 'white',
			height: 50,
			width: 50,
			borderRadius: '50%',
			margin: 'auto',
			cursor: 'pointer',
			marginTop: 10,
		},
		updateIcon: { margin: '10px' },
		updateLoader: {
			padding: 12,
		},
	},
	editGroupParticipant: {
		membersNotFound: { textAlign: 'center', padding: 5, opacity: 0.5 },
		listMembersDiv: {
			minHeight: '16vh',
			maxHeight: '60vh',
			overflow: 'scroll',
		},
		unorderedListMember: { listStyle: 'none', margin: 0, padding: 0 },
		listGroupMember: {
			width: '90%',
			padding: '10px 10px',
			boxSizing: 'border-box',
			background: '#ffffff',
			margin: 'auto',
			marginBottom: 4,
			color: '#5d5858',
			fontSize: 15,
			display: 'flex',
			cursor: 'pointer',
			borderBottom: '0.5px solid lightgray',
		},
		listMemberName: {
			width: '70%',
			marginLeft: 20,
			marginTop: 3,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		searchListMemberDiv: {
			width: '90%',
			padding: '10px 10px',
			boxSizing: 'border-box',
			background: '#ffffff',
			margin: 'auto',
			justifyContent: 'center',
			color: '#5d5858',
			fontSize: 15,
			display: 'flex',
			cursor: 'pointer',
		},
		searchListMemberResult: {
			marginLeft: 20,
			marginTop: 3,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		updateGroupDetails: {
			bottom: 20,
			position: 'absolute',
			marginLeft: '75%',
			background: '#09e85e',
			color: 'white',
			height: 50,
			width: 50,
			borderRadius: '50%',
			cursor: 'pointer',
		},
		updateIcon: { margin: '10px' },
		updateLoader: {
			padding: 12,
		},
	},
	forwardMessage: {
		forwardMessageModalHeight: { height: '60vh', overflow: 'scroll' },
		membersNotFound: {
			textAlign: 'center',
			margin: 30,
			opacity: 0.5,
		},
		listMembersDiv: { margin: '5% 7%' },
		listMembers: {
			margin: '10px',
			paddingBottom: 8,
			borderBottom: '1px solid #eeeeee',
			display: 'flex',
		},
		listMemberName: {
			margin: '6px 10px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		listMembersNotFound: {
			textAlign: 'center',
			opacity: 0.7,
			fontSize: 14,
		},
		forwardButton: {
			marginTop: 10,
			height: '8vh',
			color: 'white',
			background: '#50c0a5',
		},
		selectedMember: {
			color: 'white',
			padding: 17,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		forwardButtonStyle: {
			height: 50,
			width: 50,
			background: '#09e85e',
			textAlign: 'center',
			marginTop: '-25px',
			borderRadius: '50%',
			cursor: 'pointer',
		},
		forwardSendIcon: { margin: '9px 13px' },
		forwardSendLoader: {
			padding: 12,
		},
	},
	messageInfo: {
		messagePdfDiv: {
			height: 50,
			background: 'white',
			display: 'flex',
			cursor: 'pointer',
			borderRadius: 5,
		},
		messagePdfCaption: { wordBreak: 'break-word', margin: 5 },
		forwardMessageModalHeight: { height: '58vh', width: '100%', overflow: 'scroll' },
		membersNotFound: {
			margin: 0,
		},
		listMembersDiv: { margin: '5% 7%' },
		listMembers: {
			margin: '10px',
			paddingBottom: 8,
			borderBottom: '1px solid #eeeeee',
			display: 'flex',
		},
		listMemberName: {
			margin: '6px 10px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		listMembersNotFound: {
			textAlign: 'center',
			opacity: 0.7,
			fontSize: 14,
		},
		forwardButton: {
			marginTop: 10,
			height: '8vh',
			color: 'white',
			background: '#50c0a5',
		},
		selectedMember: {
			color: 'white',
			padding: 17,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		forwardButtonStyle: {
			height: 50,
			width: 50,
			background: '#09e85e',
			textAlign: 'center',
			marginTop: '-25px',
			borderRadius: '50%',
			cursor: 'pointer',
		},
		forwardSendIcon: { margin: '9px 13px' },
		forwardSendLoader: {
			padding: 12,
		},
	},
	imagePreview: {
		imageHeight: {
			width: '100%',
			objectFit: 'contain',
			marginTop: 15,
		},
		pdfDiv: {
			border: '2px solid gray',
			padding: 10,
			textAlign: 'initial',
			marginTop: '10%',
		},
		imageDescription: {
			outline: 0,
			borderWidth: '0 0 2px',
			borderColor: '#50c0a5',
			width: '100%',
			marginTop: '14px',
			fontSize: '17px',
		},
		imageSendIcon: {
			margin: '25px 0px',
			cursor: 'pointer',
		},
	},
	archivedChat: {
		selectedBackground: {
			background: '#f1f1f1',
		},
		unselectedBackground: {
			background: '#ffffff',
		},
		chatListDiv: {
			display: 'grid',
			gridTemplateColumns: '100%',
		},
		chatListProfilePic: {
			borderRadius: '50%',
			width: '50px',
			height: '50px',
			alignSelf: 'center',
			justifySelf: 'center',
		},
		chatListEmptyProfilePic: {
			height: 50,
			width: 50,
			padding: 5,
			fontSize: 22,
			verticalAlign: 'middle',
		},
		chatSelectedList: {
			width: '100%',
			alignSelf: 'center',
			justifySelf: 'start',
			':hover': {
				cursor: 'pointer',
			},
		},
		chatPaddingLeft: { paddingLeft: '10px' },
		chatLastMessage: {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			color: 'rgba(0, 0, 0, 0.6)',
			fontSize: '0.9em',
			margin: '0px',
		},
		chatStatus: {
			color: 'rgba(0, 0, 0, 0.4)',
			fontSize: '0.8em',
			display: 'grid',
			height: '50%',
			width: '100%',
			alignSelf: 'center',
		},
		chatLastMessageTime: {
			alignSelf: 'start',
			justifySelf: 'center',
		},
		unreadMessageCount: {
			justifySelf: 'center',
			background: '#06d755',
			color: 'white',
			height: 25,
			width: 25,
			borderRadius: 100,
			fontSize: 12,
			padding: '4px 10px',
		},
		chatListImageIcon: {
			opacity: 0.5,
			marginLeft: 5,
		},
	},
}

export default styles
