import gql from 'graphql-tag'

export const MARK_AS_UNFOLLOW_MUTATION = gql`
	mutation MarkAsUnfollowed($enquiryId: String, $stageFrom: String) {
		markAsUnfollowed(enquiryId: $enquiryId, stageFrom: $stageFrom) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
