import gql from 'graphql-tag'

export const GenerateDriveToken = gql`
	query generateDriveToken($authCode: String) {
		generateDriveToken(authCode: $authCode) {
			authToken
			token
			querySuccess
		}
	}
`
