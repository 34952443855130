const styles = {
	/**
	 * This styles is used to add a render fuction css.
	 */
	// Ticket section
	ticket: {
		margin: '0',
		boxShadow: 'inset 0px 2px 8px #00000047',
		padding: '24px 26px 30px 30px',
		height: 'calc(100vh - 84px)',
		overflowY: 'auto !important',
    overflowX: 'hidden',
    backgroundColor: 'white',
  },
  // ShowClosedTicketsDiv
  ShowClosedTicketsDiv:{
    width: '50vw',
  },
  // show closed tickets
  ShowClosedTickets:{
    textDecoration: 'underline',
		// textAlign: 'center',
		color: ',#2c8247',
    fontWeight: '500',
    fontSize: '18px',
		marginBottom: '4%',
		marginTop: '15px',
    cursor: 'pointer',
    // paddingLeft: '25%',
    textAlign: 'center',
  },
}

export default styles
