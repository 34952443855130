// import react component
import React, { Component } from 'react'
import { Result, Typography } from 'antd'
import TermsAndConditions from './TermsAndConditions'
import './index.css' // Import component CSS

const { Paragraph } = Typography

// Service scope component
class ServiceScopeConfig extends Component {
	// service scope callback function
	setTerms = (data) => {
		const { setServiceTermsAndCondition } = this.props
		// callback function
		setServiceTermsAndCondition(data)
	}

	render() {
		// Deconstruct props
		const {
			serviceTermsAndCondition,
			insertStatus,
			proposalLink,
			editProposalDocument,
		} = this.props

		// Deconstruct service scope object

		return (
			<div className="proposalTermsAndConditions">
				{!insertStatus ? (
					<TermsAndConditions
						termsAndCondition={serviceTermsAndCondition}
						setTerms={this.setTerms}
					/>
				) : (
					<Result status="success" title="New Proposal Successfully created.">
						<div className="desc" style={{ textAlign: 'center', fontSize: 20 }}>
							<Paragraph>
								Click to&nbsp;&nbsp;
								<a
									href={proposalLink}
									target="_blank"
									style={{ textDecoration: 'underline' }}>
									View Proposal
								</a>
							</Paragraph>
							<Paragraph>
								Click to&nbsp;&nbsp;
								<span
									onClick={() => {
										editProposalDocument()
									}}
									style={{
										textDecoration: 'underline',
										cursor: 'pointer',
										color: '#1890ff',
									}}>
									Edit Proposal
								</span>
							</Paragraph>
						</div>
					</Result>
				)}
			</div>
		)
	}
}

// export ServiceScopeConfig component
export default ServiceScopeConfig
