import gql from 'graphql-tag'

export const GET_CHAT_LIST = gql`
	query newGetChatList(
		$hash: String!
		$limit: Int!
		$offset: Int!
		$searchString: String!
	) {
		newGetChatList(
			hash: $hash
			limit: $limit
			offset: $offset
			searchString: $searchString
		) {
			id
			name
			type
			unreadMessagesCount
			latestMessage {
				name
				time
				message
				type
			}
			photo
		}
	}
`

export const GET_CHAT_DETAILS = gql`
	query getChatDetails($hash: String!, $groupId: String!, $limit: Int!, $offset: Int!) {
		getChatDetails(hash: $hash, groupId: $groupId, limit: $limit, offset: $offset) {
			id
			type
			content
			messageType
			isTicket
			isDeleted
			isForwarded
			isMyReply
			reply {
				id
				content
				isDeleted
				messageType
				caption
				createdBy {
					name
					hash
				}
				createdAt
			}
			caption
			createdBy {
				name
				hash
			}
			createdAt
		}
	}
`

export const POST_MESSAGE = gql`
	mutation createMessage(
		$from: String!
		$to: String!
		$groupId: String
		$message: String!
		$mentionedTo: [String!]
		$messageType: String!
		$replied: String
		$feed: [imageType]
		$pdfCaption: String
	) {
		createMessage(
			from: $from
			to: $to
			groupId: $groupId
			message: $message
			mentionedTo: $mentionedTo
			messageType: $messageType
			replied: $replied
			feed: $feed
			pdfCaption: $pdfCaption
		) {
			message {
				id
				type
				content
				messageType
				isTicket
				isDeleted
				isForwarded
				isMyReply
				reply {
					id
					content
					isDeleted
					messageType
					caption
					createdBy {
						name
						hash
						photo
						memberGroupId
					}
					createdAt
				}
				caption
				createdBy {
					name
					hash
					photo
					memberGroupId
				}
				createdAt
			}
			chat {
				id
				name
				type
				unreadMessagesCount
				latestMessage {
					name
					time
					message
					type
				}
				photo
			}
		}
	}
`

export const CREATE_TICKET = gql`
	mutation createTicket(
		$groupId: String!
		$messageId: String!
		$hash: String!
		$edit: String
		$spaceId: [String!]
		$dept: String
		$staticImage: String
		$image: [Upload!]
		$isStatic: Boolean
		$mentionedTo: [String!]
	) {
		createTicket(
			groupId: $groupId
			messageId: $messageId
			hash: $hash
			edit: $edit
			spaceId: $spaceId
			dept: $dept
			staticImage: $staticImage
			image: $image
			isStatic: $isStatic
			mentionedTo: $mentionedTo
		) {
			message {
				id
				type
				content
				messageType
				isTicket
				isDeleted
				isForwarded
				isMyReply
				reply {
					id
					content
					isDeleted
					messageType
					caption
					createdBy {
						name
						hash
						photo
						memberGroupId
					}
					createdAt
				}
				caption
				createdBy {
					name
					hash
					photo
					memberGroupId
				}
				createdAt
			}
			log {
				id
				type
				content
				messageType
				isTicket
				isDeleted
				isForwarded
				isMyReply
				reply {
					id
					content
					isDeleted
					messageType
					caption
					createdBy {
						name
						hash
						photo
						memberGroupId
					}
					createdAt
				}
				caption
				createdBy {
					name
					hash
					photo
					memberGroupId
				}
				createdAt
			}
		}
	}
`

export const MESSAGE_CREATED_SUBSCRIPTION = gql`
	subscription messageCreated($groupId: String!, $hash: String!) {
		messageCreated(groupId: $groupId, hash: $hash) {
			id
			type
			content
			messageType
			isTicket
			isDeleted
			isForwarded
			isMyReply
			reply {
				id
				content
				isDeleted
				messageType
				caption
				createdAt
			}
			caption
			createdBy {
				memberGroupId
				name
				hash
				photo
			}
			createdAt
		}
	}
`

export const GET_USER_HASH = gql`
	query userHash {
		userHash {
			name
			hash
			photo
			role
		}
	}
`

export const MESSAGE_RECEIVED_SUBSCRIPTION = gql`
	subscription messageReceived($hash: String!) {
		messageReceived(hash: $hash) {
			hash
		}
	}
`

export const UPLOAD_CHAT_IMAGE = gql`
	mutation uploadChatImage($files: [Upload!]!) {
		uploadChatImage(files: $files)
	}
`
export const GET_PEOPLE = gql`
	query getPeople($hash: String!) {
		getPeople(hash: $hash) {
			name
			hash
			groupId
		}
	}
`

export const MESSAGE_FORWARD = gql`
	mutation forwardMessage(
		$type: String
		$content: String
		$hash: String
		$forwardGroup: [ForwardGroup]
		$pdfCaption: String
	) {
		forwardMessage(
			type: $type
			content: $content
			hash: $hash
			forwardGroup: $forwardGroup
			pdfCaption: $pdfCaption
		)
	}
`

export const CREATE_NEW_GROUP = gql`
	mutation addGroupMembers(
		$hash: String!
		$members: [String!]!
		$isNew: Boolean!
		$groupId: String
		$groupName: String
		$groupType: String
		$groupDesc: String
		$projectUrl: String
		$groupPicture: [Upload!]
	) {
		addGroupMembers(
			hash: $hash
			members: $members
			isNew: $isNew
			groupId: $groupId
			groupName: $groupName
			groupType: $groupType
			groupDesc: $groupDesc
			projectUrl: $projectUrl
			groupPicture: $groupPicture
		)
	}
`

export const GET_USER_PROJECT = gql`
	query getProjects($hash: String!) {
		getProjects(hash: $hash) {
			url
			name
		}
	}
`

export const DELETE_MESSAGE = gql`
	mutation deleteMessage($hash: String!, $messageId: String!) {
		deleteMessage(hash: $hash, messageId: $messageId)
	}
`

export const LIST_GROUP_MEMBER = gql`
	query listGroupMember($hash: String, $groupId: String!) {
		listGroupMembers(hash: $hash, groupId: $groupId) {
			hash
			name
			photo
			isAdmin
			memberGroupId
			lastSeen
		}
	}
`

export const MAKE_GROUP_ADMIN = gql`
	mutation makeAdmin(
		$hash: String!
		$members: [String!]!
		$groupId: String!
		$adminStatus: String!
	) {
		makeAdmin(
			hash: $hash
			members: $members
			groupId: $groupId
			adminStatus: $adminStatus
		)
	}
`

export const REMOVE_GROUP_MEMBER = gql`
	mutation removeGroupMembers($hash: String!, $members: [String!]!, $groupId: String!) {
		removeGroupMembers(hash: $hash, members: $members, groupId: $groupId)
	}
`

export const ADD_OR_EDIT_GROUP_DESCRIPTION = gql`
	mutation addOrEditGroupDescription(
		$groupId: String!
		$description: String!
		$hash: String!
	) {
		addOrEditGroupDescription(groupId: $groupId, description: $description, hash: $hash)
	}
`
export const EDIT_GROUP_NAME = gql`
	mutation groupNameEdit($groupId: String!, $groupName: String, $hash: String) {
		groupNameEdit(groupId: $groupId, groupName: $groupName, hash: $hash)
	}
`

export const GET_GROUP_DESCRIPTION = gql`
	query groupDesc($groupId: String!) {
		groupDesc(groupId: $groupId)
	}
`
export const GET_LAST_SEEN = gql`
	query getLastSeen($groupId: String, $hash: String) {
		getLastSeen(groupId: $groupId, hash: $hash)
	}
`
export const GET_ARCHIEVED_LIST = gql`
	query getArchievedChat($hash: String!) {
		getArchievedChat(hash: $hash) {
			id
			name
			type
			unreadMessagesCount
			latestMessage {
				name
				time
				message
				type
			}
			photo
		}
	}
`

export const ADD_OR_REMOVE_ARCHIVE = gql`
	mutation archieveChat($groupId: String!, $archiveValue: String!, $hash: String!) {
		archieveChat(groupId: $groupId, archiveValue: $archiveValue, hash: $hash)
	}
`

export const FORWARD_MEMBERS = gql`
	query forwardMembers($hash: String) {
		forwardMembers(hash: $hash) {
			hash
			name
			photo
			memberGroupId
			timestamp
			lastSeen
		}
	}
`

export const GET_MEDIA = gql`
	query getMediaForGroup($groupId: String!) {
		getMediaForGroup(groupId: $groupId) {
			uri
			thumbnailUri
			caption
			msgId
		}
	}
`

export const COMMAN_GROUP = gql`
	query commanGroups($fromHash: String!, $toHash: String!) {
		commanGroups(fromHash: $fromHash, toHash: $toHash) {
			name
			picture
			groupId
		}
	}
`

export const INSERT_ONE_SIGNAL_TOKEN = gql`
	mutation insertChatOneSignalToken($token: String!) {
		insertChatOneSignalToken(token: $token)
	}
`

export const MESSAGE_INFO = gql`
	query messageInfo($hash: String!, $groupId: String!, $time: Int!) {
		messageInfo(hash: $hash, groupId: $groupId, time: $time) {
			hash
			name
			photo
		}
	}
`
