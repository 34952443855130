import React from 'react'

import DeleteMessage from './deleteMessage'
import ImagePreview from './imagePreview'
import CreateTicket from './createTicket'
import AddParticipant from './addParticipant'
import ForwardMessage from './forwardMessage'
import AddNewGroup from './addNewGroup'
import AddGroupParticipant from './addGroupParticipant'
import EditGroupInfo from './editGroupInfo'
import EditGroupParticipant from './editGroupParticipant'
import ArchivedMember from './archivedMember'
import MessageInfo from './messageInfo'
import { ModalWrapper } from './utils'

const ModalDialog = ({
	type,
	imageDataUrl,
	fileType,
	fileName,
	handleModalCancel,
	handleModalOk,
	messageText,
	messageCaption,
	messageId,
	groupId,
	refetch,
	hash,
	toHash,
	loader,
	handleModalBack,
	handleModalNext,
	messageData,
	groupMember,
	selectedContact,
	showModalDialogPopup,
	getChats,
	chatListRefetch,
}) => {
	const props = {
		imageDataUrl,
		fileType,
		fileName,
		handleModalCancel,
		handleModalOk,
		messageText,
		messageCaption,
		messageId,
		groupId,
		refetch,
		hash,
		toHash,
		loader,
		handleModalBack,
		handleModalNext,
		messageData,
		groupMember,
		selectedContact,
		showModalDialogPopup,
		getChats,
		chatListRefetch,
	}
	switch (type) {
		case 'deleteMessage':
			return (
				<ModalWrapper>
					<DeleteMessage {...props} />
				</ModalWrapper>
			)

		case 'imagePreview':
			return (
				<ModalWrapper type={'imagePreview'}>
					<ImagePreview {...props} />
				</ModalWrapper>
			)

		case 'createTicket':
			return (
				<ModalWrapper type={'createTicket'}>
					<CreateTicket {...props} />
				</ModalWrapper>
			)

		case 'addParticipant':
			return (
				<ModalWrapper type={'addParticipant'}>
					<AddParticipant {...props} />
				</ModalWrapper>
			)

		case 'forwardMessage':
			return (
				<ModalWrapper type={'forwardMessage'}>
					<ForwardMessage {...props} />
				</ModalWrapper>
			)

		case 'addGroupParticipant':
			return (
				<ModalWrapper type={'addGroupParticipant'}>
					<AddGroupParticipant {...props} />
				</ModalWrapper>
			)

		case 'addNewGroup':
			return (
				<ModalWrapper type={'addNewGroup'}>
					<AddNewGroup {...props} />
				</ModalWrapper>
			)

		case 'editGroupInfo':
			return (
				<ModalWrapper type={'editGroupInfo'}>
					<EditGroupInfo {...props} />
				</ModalWrapper>
			)

		case 'editGroupParticipant':
			return (
				<ModalWrapper type={'editGroupParticipant'}>
					<EditGroupParticipant {...props} />
				</ModalWrapper>
			)

		case 'archivedMember':
			return (
				<ModalWrapper type={'archivedMember'}>
					<ArchivedMember {...props} />
				</ModalWrapper>
			)

		case 'messageInfo':
			return (
				<ModalWrapper type={'messageInfo'}>
					<MessageInfo {...props} />
				</ModalWrapper>
			)
	}
}
export default ModalDialog
