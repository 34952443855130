const styles = {
	/**
	 * This styles is used to add a render fuction css.
	 */

	// loading icon css
	loadingIcon: { fontSize: 50 },
	loadingIcons: { fontSize: 30 },
	loadIconStyles: { padding: '30px 400px' },
	loadIconStyle: { padding: '30px 400px' },

	// project archived css
	projectArchived: {
		display: 'flex',
		flexDirection: 'row',
		background: '#eeeeee',
	},
	dividerStyle: {
		height: 'calc(100vh - 84px)',
	},
	searchIcon: {
		width: '15px',
		position: 'absolute',
		bottom: '18px',
		left: '20px',
		fontSize: '15px',
  },
  
	positionRelative: { position: 'relative' },
}

export default styles
