import { useMutation } from '@apollo/react-hooks'
import { Col, Icon, Modal, notification, Row, Select, Spin, Typography } from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { TEAM_HEAD_CHANGE_MUTATION } from './mutation'
import { TeamHeadsList } from './query'
import './index.css'

const { confirm } = Modal
const { Option } = Select

export default function ChangeTeamHead(props) {
	const { closeModal, currentEnquiryId, laneId, refetch } = props
	const [teamHeadChange] = useMutation(TEAM_HEAD_CHANGE_MUTATION)
	const [defaultHead, setHead] = useState(undefined)

	async function logoutSuccess(value, option, teamHeadRefetch) {
		confirm({
			title: `Are you sure you want to assign this enquiry to ${option.props.children}?`,
			content: 'You can undo this action later.',
			okText: 'Confirm',
			centered: true,
			async onOk() {
				try {
					await teamHeadChange({
						variables: {
							teamHeadEmail: value,
							enquiryId: currentEnquiryId,
							enquiryStage: laneId,
						},
					})
					teamHeadRefetch()
					refetch()
					notification.success({
						message: 'Team Head changed',
						description: option.props.children,
						style: { marginTop: 50 },
					})
					setTimeout(() => {
						closeModal()
					}, 1000)
				} catch (error) {
					setHead(option.props.children)
				}
			},
			onCancel() {
				setHead(defaultHead)
			},
		})
	}
	return (
		<>
			{
				<Query query={TeamHeadsList} variables={{ enquiryId: currentEnquiryId }}>
					{({ data, loading, error, refetch }) => {
						if (loading) {
							return (
								<Spin
									indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
									style={{ padding: '30px 52px' }}
								/>
							)
						}
						if (error) {
							return (
								<Spin
									indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
									style={{ padding: '30px 52px' }}>
									{' '}
									Some error has occured
								</Spin>
							)
						}

						const { teamHeadsList } = data
						const { currentHead, headList } = teamHeadsList

						return (
							<Row style={{ marginTop: 15, marginBottom: 15 }}>
								<Col span={7} style={{ marginTop: 3 }}>
									<Typography.Text strong style={{ fontSize: 15 }}>
										Team Head :
									</Typography.Text>
								</Col>
								<Col span={17}>
									<Select
										showSearch
										className="enquiryTeamHeadDropdown"
										placeholder={currentHead ? currentHead.displayName : '-'}
										optionFilterProp="children"
										value={defaultHead}
										filterOption={(input, option) =>
											option.props.indexOf(input.toLowerCase()) >= 0
										}
										onChange={(value, option) => logoutSuccess(value, option, refetch)}>
										{headList.map((values, chatIndex) => {
											return (
												<Option key={chatIndex} value={values.headEmailID}>
													{values.displayName}
												</Option>
											)
										})}
									</Select>
								</Col>
							</Row>
						)
					}}
				</Query>
			}
		</>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
ChangeTeamHead.propTypes = {
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	laneId: PropTypes.string,
	refetch: PropTypes.func,
}

ChangeTeamHead.defaultProps = {
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	laneId: PropTypes.string,
	refetch: PropTypes.func,
}
