// Import react component
import React from 'react'
import Sortable from 'react-sortablejs'
import { Button, Row, Col, Input } from 'antd'
import StageList from './StageList'
import StepList from './StepList'

const { TextArea } = Input

// Load list of deliverables in proposal stage configuration
class StageConfig extends React.Component {
	onChangeStageDescription = (index, scopeId) => ({ target: { value } }) => {
		const { stageDescription } = this.props
		stageDescription(value, scopeId, index)
	}

	onChangeStageName = (value, scopeId, index) => {
		const { editStageName } = this.props
		editStageName(value, scopeId, index)
	}

	render() {
		// Deconstruct props
		const {
			data,
			addNewStage,
			addNewPercentage,
			removeNewStage,
			dragServiceStage,
			retainerPaymentType,
		} = this.props

		// Deconstruct data object
		const { serviceStageConfig } = data

		// Deconstruct service stage config
		const { stageStepTarget, stageStepSource } = serviceStageConfig
		const stageLength = stageStepSource.length

		return (
			<Row style={{ paddingBottom: 50, margin: 25 }}>
				<Col span={7}>
					<div className="headerHeight">
						<div style={{ display: 'flex' }}>
							<h1>Stages list</h1>
							{/** Here maximum 7 stage is allowed including retainer and completion */}
							{stageStepSource.length < 13 ? (
								<Button
									type="primary"
									icon="plus"
									shape="round"
									className="addNewStage"
									onClick={() => {
										addNewStage()
									}}>
									Stage
								</Button>
							) : null}
						</div>

						<span style={{ fontStyle: 'italic' }}>
							(min 1 - max 10 stages excluding retainer and completion)
						</span>
					</div>

					{/** Stage List Details */}
					<Sortable
						options={{
							animation: 150,
							group: {
								pull: true,
								put: true,
							},
							sort: false,
						}}
						className="block-list"
						style={{ marginTop: '30px' }}
						onChange={() => {}}
						tag="ul">
						{/** Stage list component */}
						<StageList
							data={stageStepSource}
							retainerPaymentType={retainerPaymentType}
							addNewPercentage={addNewPercentage}
							removeNewStage={removeNewStage}
							onChangeStageName={this.onChangeStageName}
						/>
					</Sortable>
				</Col>
				<Col span={8} offset={1}>
					<div className="headerHeight">
						<h1>Steps List</h1>
						<span>Drag and drop stages </span>
					</div>
					{/** Steps list details */}
					<Sortable
						options={{
							animation: 150,
							group: {
								pull: true,
								put: true,
							},
						}}
						style={{ marginTop: '30px' }}
						className="block-list"
						onChange={(items) => {
							dragServiceStage(items)
						}}
						tag="ul">
						{/** Step list component */}
						<StepList data={stageStepTarget} />
					</Sortable>
				</Col>
				<Col span={7} offset={1}>
					<div className="stageDescription">
						<div className="headerHeight">
							<h1 className="stageHead">Stage Description</h1>
							<span className="stageMessage">
								(Only Allowed Maximum 100 characters for each description)
							</span>
						</div>

						<form className="stageResultDescription">
							{stageStepSource.map((item, index) => {
								const { scopeName, scopeDescription, scopeId } = item
								const serviceKey = `${item + index}`
								const placeholder = `Enter ${scopeName} description...`
								if (index !== 0 && stageLength > index + 2) {
									return (
										<div key={serviceKey}>
											<h4 className="boldText"> {scopeName}</h4>
											<TextArea
												placeholder={placeholder}
												rows={2}
												value={scopeDescription}
												onChange={this.onChangeStageDescription(index, scopeId)}
												maxLength={100}
												className={`scopeDescription ${
													scopeDescription !== '' ? '' : 'error'
												}`}
											/>
										</div>
									)
								}
								return null
							})}
						</form>
					</div>
				</Col>
			</Row>
		)
	}
}

// export stageConfig component
export default StageConfig
