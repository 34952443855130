//Import React Components
import React from 'react'
import { Steps, Button } from 'antd'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'
import ServiceDeliverable from './ServiceDeliverable'
import ServiceSpaceConfig from './ServiceSpaceConfig'
import ServiceStageConfig from './ServiceStageConfig'
import ServiceScope from './ServiceScope'
import ServiceResult from './ServiceResult'
import { CREATE_NEW_SERVICE_MUTATION } from './query'
import './index.css'

const { Step } = Steps

//Set default state
const defaultState = {
	current: 0,
	serviceScope: null,
	defaultScopeExpandedKeys: [],
	defaultScopeSelectedKeys: [],
	defaultScopeCheckedKeys: [],
	defaultDeliverableExpandedKeys: [],
	defaultDeliverableCheckedKeys: [],
	defaultDeliverableSelectedKeys: [],
	spaceDefineJson: null,
	spaceDefineHtml: null,
	typologyId: null,
	noOfBuilding: 0,
	noOfFloor: 0,
	noOfRoom: 0,
	feeHeadScope: [],
	deliverableSelectedScope: [],
	serviceDeliverable: null,
	deliverableSelectedId: null,
	noOfStage: 0,
	stageDeliverableTarget: null,
	stageDeliverableSource: null,
	serviceScopeDeliverable: null,
	serviceName: '',
	elasticPrice: [
		{ sqft: '500', amount: '' },
		{ sqft: '1000', amount: '' },
		{ sqft: '2500', amount: '' },
		{ sqft: '10000', amount: '' },
		{ sqft: '50000', amount: '' },
	],
	insertStatus: false,
	typology: [],
	spaceConfigStatus: false,
	stageErrorStatus: true,
	serviceFormStatus: false,
	serviceErrorStatus: false,
	stagePercentageStatus: true,
	stageSelectedError: false,
	deliverableChangedStatus: true,
}

// Create Service Component
class CreateNewService extends React.Component {
	constructor(props) {
		super(props)
		this.state = defaultState
	}

	//Set ServiceScope component details to CreateNewService component state
	/**
   * @type    - callback function
   * @param   - data - contains service scope,scope checked keys,scope expanded keys and fee head scope
   * @summary - Get selected scope details from ServiceScope component and
                Set that serviceScope data to CreateNewService component state
   * @return  - null
   */
	setServiceScope = (data) => {
		const {
			serviceScope,
			defaultScopeExpandedKeys,
			defaultScopeSelectedKeys,
			defaultScopeCheckedKeys,
			feeHeadScope,
		} = data

		// set changes in CreateNewService state
		this.setState({
			serviceScope,
			defaultScopeCheckedKeys,
			defaultScopeExpandedKeys,
			defaultScopeSelectedKeys,
		})

		this.selectedScopeDeliverable(serviceScope, feeHeadScope, defaultScopeCheckedKeys)
	}

	// Using serviceScope and feeHeadScope to Generate a deliverable list for ServiceDeliverable component
	/**
   * @type    - function
   * @param   - service scope,fee head scope and scope checked keys
   * @summary - Generate a deliverable list based on building,floor and room using serviceScope and feeHeadScope.
                After generate that deliverable list and set that deliverable list in createNewService component state
   * @return  - null
   */

  selectedScopeDeliverable = (
    serviceScope,
    feeHeadScope,
    defaultScopeCheckedKeys
  ) => {
    var deliverableSelectedScope = [];
    var feeHeadScopeArray = [];
    var deliverableScope = [];

    //Loop service scope parent array
    for (var x = 0; x < serviceScope.length; x++) {
      const scopeId = serviceScope[x].scopeId;
      const scopeParentKey = serviceScope[x].key;

      //Here check if feehead scope is selected or not, If selected push that scope id in feeHeadScope array or otherwise not
      if (feeHeadScope !== null) {
        if (feeHeadScope.includes(scopeParentKey)) {
          feeHeadScopeArray.push(scopeId);
        }
      }

      //Declare room scope array
      var roomScope = [];
      //Declare building scope array
      var buildingScope = [];
      //Declare floor scope array
      var floorScope = [];

      //Loop service scope children array
      for (var y = 0; y < serviceScope[x].children.length; y++) {
        //Loop service scope sibling arrray
        for (var z = 0; z < serviceScope[x].children[y].children.length; z++) {
          const {
            scopeId: siblingScopeId,
            title: scopeName,
            scopeType,
            spaceLevel
          } = serviceScope[x].children[y].children[z];

          var data = {
            scopeId: siblingScopeId,
            scopeName,
            spaceLevel,
            scopeType
          };

          // Here check if scope level is building, push that data in building scope array
          // or else check if scope level is floor, push that data in floor scope array
          // or else check if scope level is room, push that data in room scope array
          if (spaceLevel == 'building') {
            buildingScope.push(data);
          } else if (spaceLevel == 'floor') {
            floorScope.push(data);
          } else if (spaceLevel == 'room') {
            roomScope.push(data);
          }

          const scopeSiblingKey = serviceScope[x].children[y].children[z].key;

          if (defaultScopeCheckedKeys.includes(scopeSiblingKey)) {
            deliverableSelectedScope.push(siblingScopeId);
          }
        }
      }

      //Generate JSON data
      var data = {
        scopeId,
        scopeName: serviceScope[x].title,
        scopeDeliverable: {
          buildingScope: buildingScope,
          roomScope: roomScope,
          floorScope: floorScope
        }
      };

      //Push that data in deliverable scope array
      deliverableScope.push(data);
    }

    // Set changes in CreateNewService state
    this.setState({
      deliverableSelectedScope,
      feeHeadScope: feeHeadScopeArray,
      serviceScopeDeliverable: deliverableScope
    });
  };

  // Set ServiceSpaceConfig component details to CreateNewService component state
  /**
   * @type    - callback function
   * @param   - data contains no of building, no of floor, no of room, typology id,
                space editor json and space editor html
   * @summary - Get ServiceSpaceConfig component data through callback function and 
                Set that data to CreateNewService component state 
   * @return  - null
   */
  setServiceSpaceDetails = (data) => {
    const {
      typologyInput,
      noOfBuildingInput,
      noOfFloorInput,
      spaceDefineJson,
      spaceDefineHtml,
      noOfRoomInput
    } = data;

    //Set changes in CreateNewService state
    this.setState({
      typologyId: typologyInput,
      noOfBuilding: noOfBuildingInput,
      noOfFloor: noOfFloorInput,
      noOfRoom: noOfRoomInput,
      spaceDefineJson,
      spaceDefineHtml
    });
  };

  // Set ServiceStageConfig component data to CreateNewService Component state
  /**
   * @type    - callback function
   * @param   - data contains editor component json and editor component html
   * @summary - 1. There is any changes occured in ServiceSpaceConfig editor component,
   *               Reflect that editor data in CreateNewService component state.
   *            2. Here spaceConfigStatus changed to true because if there is any changes occured in editor,
   *               we will move to next step(ServiceDeliverable) otherwise disable the next button
   * @return  - null
   */
  setServiceEditorSpace = (data) => {
    const { spaceDefineJson, spaceDefineHtml } = data;

    //Set changes in CreateNewService state
    this.setState({
      spaceDefineJson,
      spaceDefineHtml,
      spaceConfigStatus: true
    });
  };

  //Set ServiceDeliverable component details to CreateNewService component state
  /**
   * @type    - callback function
   * @param   - data contains deliverable selected scope id and deliverable expanded keys
   * @summary - 1) Using serviceDeliverable and deliverableChecked keys, we generate a deliverable selected id
   *            2) Using serviceDeliverable and newly generated deliverable selected id, we generate a deliverable stage list
   *            3) After generating these details and set that details in CreateNewService component state
   * @return  - null
   */
  setServiceDeliverable = (data) => {
    const {
      serviceDeliverable,
      defaultDeliverableExpandedKeys,
      defaultDeliverableCheckedKeys,
      defaultDeliverableSelectedKeys,
      deliverableChangedStatus
    } = data;

    const { stageDeliverableTarget } = this.state;

    //Using service deliverable and deliverable checked keys, we generate a deliverable Id
    const deliverableId = this.selectedDeliverableId(
      serviceDeliverable,
      defaultDeliverableCheckedKeys
    );

    //Using service deliverable and newly generated deliverable Id, we generate a deliverable stage Id
    const deliverableStageId = this.deliverableStageId(
      serviceDeliverable,
      deliverableId
    );

    //Set changes in CreateNewService state
    this.setState({
      serviceDeliverable,
      defaultDeliverableExpandedKeys,
      defaultDeliverableCheckedKeys,
      defaultDeliverableSelectedKeys,
      deliverableSelectedId: deliverableId,
      stageDeliverableTarget:
        deliverableChangedStatus === true
          ? deliverableStageId
          : stageDeliverableTarget,
      deliverableChangedStatus
    });
  };

  //Generate deliverable stage id using service deliverable and deliverable id
  /**
   * @type     - function
   * @param    - service deliverable and deliverable id
   * @function - Generate a deliverable stage data using service deliverable and deliverable id and return it
   * @return   - deliverable stage array
   */
  deliverableStageId = (serviceDeliverable, deliverableId) => {
    var stageDeliverableDetails = [];

    // deconstruct state
    const { deliverableSelectedScope } = this.state;

    for (var x = 0; x < serviceDeliverable.length; x++) {
      var stageDeliverable = [];
      for (var y = 0; y < deliverableSelectedScope.length; y++) {
        for (var z = 0; z < deliverableId[x].scopeData.length; z++) {
          if (
            deliverableSelectedScope[y] ===
            deliverableId[x].scopeData[z].scopeId
          ) {
            stageDeliverable.push(deliverableId[x].scopeData[z]);
          }
        }
      }

      //Generate response data
      var result = {
        scopeName: serviceDeliverable[x].title,
        scopeData: stageDeliverable
      };

      //Push response data in stage deliverable details array
      stageDeliverableDetails.push(result);
    }

    return stageDeliverableDetails;
  };

  //Generate deliverable selected id using service deliverable and deliverable checked keys
  /**
   * @type     - function
   * @param    - service deliverable and deliverable checked keys
   * @function - Generate a deliverable id using service deliverable and deliverable checked keys and return it
   * @return   - deliverable id array
   */
  selectedDeliverableId = (
    serviceDeliverable,
    defaultDeliverableCheckedKeys
  ) => {
    //Declare deliverable scope array
    var deliverableScope = [];

    //Loop through the service deliverable array
    for (var x = 0; x < serviceDeliverable.length; x++) {
      //Declare deliverable Id array
      var deliverableId = [];

      for (var y = 0; y < serviceDeliverable[x].children.length; y++) {
        //Loop building scope details in service deliverable array
        for (
          var z = 0;
          z < serviceDeliverable[x].children[y].children.length;
          z++
        ) {
          // Get service building key
          const buildingKey = serviceDeliverable[x].children[y].children[z].key;

          //Here We check service building deliverable key is exist in deliverable checked array
          //If exist push that data in deliverable id array otherwise not

          if (defaultDeliverableCheckedKeys.includes(buildingKey)) {
            // Deconstruct service deliverable
            const {
              scopeId,
              title: scopeName,
              type: scopeType
            } = serviceDeliverable[x].children[y].children[z];

            const { title } = serviceDeliverable[x].children[y];

            //Here we check if scopeId is not null then we push that data in deliverable id array otherwise not
            if (scopeId !== null) {
              const data = {
                title,
                scopeId,
                scopeName,
                scopeType,
                type: 'building'
              };
              deliverableId.push(data);
            }
          }

          //loop floor scope details in service deliverable array
          for (
            var a = 0;
            a < serviceDeliverable[x].children[y].children[z].children.length;
            a++
          ) {
            // Get service floor key
            const floorKey =
              serviceDeliverable[x].children[y].children[z].children[a].key;

            //Here We check service floor deliverable key is exist in deliverable checked array
            //If exist push that data in deliverable id array otherwise not

            if (defaultDeliverableCheckedKeys.includes(floorKey)) {
              // Deconstruct service deliverable
              const {
                scopeId,
                title: scopeName,
                type: scopeType
              } = serviceDeliverable[x].children[y].children[z].children[a];
              const { title } = serviceDeliverable[x].children[y].children[z];

              //Here we check if scopeId is not null then we push that data in deliverable id array otherwise not
              if (scopeId !== null) {
                const data = {
                  title,
                  scopeId,
                  scopeName,
                  scopeType,
                  type: 'floor'
                };
                deliverableId.push(data);
              }
            }

            //Loop room scope details in service deliverable array
            for (
              var b = 0;
              b <
              serviceDeliverable[x].children[y].children[z].children[a].children
                .length;
              b++
            ) {
              // Get service room key
              const roomKey =
                serviceDeliverable[x].children[y].children[z].children[a]
                  .children[b].key;

              //Here We check service room deliverable key is exist in deliverable checked array
              //If exist push that data in deliverable id array otherwise not
              if (defaultDeliverableCheckedKeys.includes(roomKey)) {
                // Deconstruct service deliverable
                const {
                  scopeId,
                  title: scopeName,
                  type: scopeType
                } = serviceDeliverable[x].children[y].children[z].children[
                  a
                ].children[b];

                const { title } = serviceDeliverable[x].children[y].children[
                  z
                ].children[a];

                //Here we check if scopeId is not null then we push that data in deliverable id array otherwise not
                if (scopeId !== null) {
                  const data = {
                    title,
                    scopeId,
                    scopeType,
                    scopeName,
                    type: 'room'
                  };
                  deliverableId.push(data);
                }
              }
            }
          }
        }
      }

      //Generate response data
      var result = {
        scopeName: serviceDeliverable[x].title,
        scopeData: deliverableId
      };

      //Push data in deliverable scope array
      deliverableScope.push(result);
    }

    return deliverableScope;
  };

  // Set ServiceStage component details to CreateNewService component state
  /**
   * @type    - callback function
   * @param   - data contains no of stage, stage deliverable source and stage deliverable target
   * @summary - Get data from ServiceStage component and Set that data in CreateNewService component
   * @return  - null
   */
  setServiceStage = (data) => {
    // Deconstruct data
    const { noOfStage, stageDeliverableSource, stageDeliverableTarget } = data;

    //Set changes in CreateNewService state
    this.setState({
      noOfStage,
      stageDeliverableSource,
      stageDeliverableTarget,
      deliverableChangedStatus: false
    });
  };

  //validate serviceResult component
  /**
   * @type    - function
   * @param   - null
   * @summary - validate service name and elastic price input
   * @return  - null
   */
  setServiceValidationStatus = () => {
    //Here validate service name and elastic pricing input
    const validateStatus = this.validateServiceInput();
    //if validate status is true, enable createNewService button
    //otherwise disable createNewService button
    if (validateStatus) {
      //Set changes in CreateNewService state
      this.setState({ serviceErrorStatus: true });
    } else {
      //Set changes in CreateNewService state
      this.setState({ serviceErrorStatus: false });
    }
  };

  //Set service name in CreateNewService component state
  /**
   * @type    - callback function
   * @param   - service name
   * @summary - Set service name in CreateNewService component and validate the input field
   * @return  - null
   */
  setServiceName = (serviceName) => {
    //Set changes in CreateNewService state
    this.setState({ serviceName: serviceName }, () => {
      //Validate input
      this.setServiceValidationStatus();
    });
  };

  //Set service per sqft in CreateNewService component state
  /**
   * @type    - callback function
   * @param   - service per sqft
   * @summary - Set service per sqft in CreateNewService component and validate the input field
   * @return  - null
   */
  setServiceSqft = (newSqft) => {
    //Set changes in CreateNewService state
    this.setState({ elasticPrice: newSqft }, () => {
      //Validate input
      this.setServiceValidationStatus();
    });
  };

  //Set service per sqft amount in CreateNewService component state
  /**
   * @type    - callback function
   * @param   - service per sqft amount
   * @summary - Set service per sqft amount in CreateNewService component and validate the input field
   * @return  - null
   */
  setServiceAmount = (newAmount) => {
    //Set changes in CreateNewService state
    this.setState({ elasticPrice: newAmount }, () => {
      //Validate input
      this.setServiceValidationStatus();
    });
  };

  //Add new elastic price in CreateNewService component state
  /**
   * @type    - callback function
   * @param   - null
   * @summary - Add new elastic price
   * @return  - null
   */
  addServiceElasticPrice = () => {
    //Set changes in CreateNewService state
    this.setState(
      {
        elasticPrice: this.state.elasticPrice.concat([ { sqft: '', amount: '' } ])
      },
      () => {
        //Validate input
        this.setServiceValidationStatus();
      }
    );
  };

  //Remove elastic price in CreateNewService component state
  /**
   * @type    - callback function
   * @param   - elasticPriceId
   * @summary - Remove elastic price
   * @return  - null
   */
  removeServiceElasticPrice = (elasticPriceId) => {
    //Set changes in CreateNewService state
    this.setState(
      {
        elasticPrice: this.state.elasticPrice.filter(
          (element, id) => elasticPriceId !== id
        )
      },
      () => {
        //Validate input
        this.setServiceValidationStatus();
      }
    );
  };

  //Validate Input field
  /**
   * @type    - function
   * @param   - null
   * @summary - validate service name and elastic price per sqft amount
   * @return  - boolean
   */
  validateServiceInput = () => {
    const { serviceName, elasticPrice } = this.state;
    var serviceStatus = true;
    var elasticStatus = true;
    serviceStatus = serviceName ? true : false;

    for (var x = 0; x < elasticPrice.length; x++) {
      const { sqft, amount } = elasticPrice[x];

      if (
        sqft === 0 ||
        sqft === '' ||
        sqft === undefined ||
        sqft === null ||
        amount === 0 ||
        amount === '' ||
        amount === undefined ||
        amount === null
      ) {
        elasticStatus = false;
      }
    }

    return serviceStatus && elasticStatus ? true : false;
  };

  //Set master typology in CreateNewService component state
  /**
   * @type    - callback function
   * @param   - data contains typology array
   * @summary - Set typology list array in state
   * @return  - null
   */
  setMasterTypology = (data) => {
    this.setState({ typology: data });
  };

  //Set stage error status in CreateNewService component
  /**
   * @type    - callback function
   * @param   - stage status, stage percentage, stage selected status
   * @summary - Set error status in CreateNewService component
   * @return  - null
   */
  setStageErrorHandle = (
    stageErrorStatus,
    stagePercentageStatus,
    stageSelectedError
  ) => {
    //Set changes in CreateNewService state
    this.setState({
      stageErrorStatus: stageErrorStatus,
      stagePercentageStatus: stagePercentageStatus,
      stageSelectedError: stageSelectedError
    });
  };

  //Insert new service data in backend
  /**
   * @type    - function
   * @param   - null
   * @summary - Insert newly created service in DB
   * @return  - null
   */
  createNewService = async () => {
    //Deconstruct CreateNewService state
    const {
      serviceName,
      elasticPrice,
      typologyId,
      noOfBuilding,
      noOfFloor,
      noOfStage,
      serviceScopeDeliverable,
      feeHeadScope,
      deliverableSelectedScope,
      spaceDefineHtml,
      spaceDefineJson,
      stageDeliverableTarget,
      stageDeliverableSource,
      serviceScope,
      defaultScopeCheckedKeys,
      defaultDeliverableCheckedKeys
    } = this.state;

    //Mutation
    //Create new service mutation
    const serviceMutation = await this.props.client.mutate({
      mutation: CREATE_NEW_SERVICE_MUTATION,
      variables: {
        serviceName: serviceName,
        elasticPrice: JSON.stringify(elasticPrice),
        typologyId: typologyId,
        noOfBuilding: noOfBuilding.toString(),
        noOfFloor: noOfFloor.toString(),
        noOfStage: noOfStage.toString(),
        deliverableSelectedScope: JSON.stringify(deliverableSelectedScope),
        spaceDefineJson: JSON.stringify(spaceDefineJson),
        spaceDefineHtml: spaceDefineHtml,
        stageDeliverableTarget: JSON.stringify(stageDeliverableTarget),
        stageDeliverableSource: JSON.stringify(stageDeliverableSource),
        serviceScopeDeliverable: JSON.stringify(serviceScopeDeliverable),
        feeHeadScope: JSON.stringify(feeHeadScope),
        deliverableScope: JSON.stringify(serviceScope),
        deliverableScopeSelectedId: JSON.stringify(defaultScopeCheckedKeys),
        deliverableSelectedId: JSON.stringify(defaultDeliverableCheckedKeys)
      }
    });

    const { createNewService } = serviceMutation.data;
    if (createNewService) {
      //Set changes in CreateNewService state
      this.setState({ insertStatus: true });
    }
  };

  //Create New Service button
  createService() {
    //Set changes in CreateNewService state
    this.setState({ serviceFormStatus: true });
    const validateStatus = this.validateServiceInput();
    if (validateStatus) {
      this.createNewService();
    }
  }

  //Create Service Component Next Button
  next() {
    const current = this.state.current + 1;
    //Set changes in CreateNewService state
    this.setState({ current });
  }

  //Create Service Component Previous Button
  prev() {
    const current = this.state.current - 1;

    //Set changes in CreateNewService state
    this.setState({ current });
  }

  render() {
    //Deconstruct CreateNewService state
    const {
      current,
      serviceScope,
      defaultScopeExpandedKeys,
      defaultScopeSelectedKeys,
      defaultScopeCheckedKeys,
      spaceDefineJson,
      spaceDefineHtml,
      typologyId,
      noOfBuilding,
      noOfFloor,
      serviceDeliverable,
      feeHeadScope,
      deliverableSelectedScope,
      defaultDeliverableExpandedKeys,
      defaultDeliverableCheckedKeys,
      defaultDeliverableSelectedKeys,
      noOfStage,
      stageDeliverableSource,
      stageDeliverableTarget,
      deliverableSelectedId,
      serviceScopeDeliverable,
      serviceName,
      elasticPrice,
      insertStatus,
      typology,
      spaceConfigStatus,
      stageErrorStatus,
      stagePercentageStatus,
      stageSelectedError,
      serviceFormStatus,
      serviceErrorStatus,
      deliverableChangedStatus
    } = this.state;

    console.log(this.state);

    //Create New Service step
    const steps = [
      {
        title: 'Choose Scope',
        content: (
          <ServiceScope
            serviceScope={serviceScope}
            defaultScopeExpandedKeys={defaultScopeExpandedKeys}
            defaultScopeSelectedKeys={defaultScopeSelectedKeys}
            defaultScopeCheckedKeys={defaultScopeCheckedKeys}
            setServiceScope={this.setServiceScope}
          />
        )
      },
      {
        title: 'Configure Space',
        content: (
          <ServiceSpaceConfig
            typology={typology}
            spaceDefineJson={spaceDefineJson}
            spaceDefineHtml={spaceDefineHtml}
            typologyId={typologyId}
            noOfBuilding={noOfBuilding}
            noOfFloor={noOfFloor}
            setServiceSpaceDetails={this.setServiceSpaceDetails}
            setServiceEditorSpace={this.setServiceEditorSpace}
            setMasterTypology={this.setMasterTypology}
          />
        )
      },
      {
        title: 'Choose Deliverabes',
        content: (
          <ServiceDeliverable
            spaceDefineJson={spaceDefineJson}
            serviceDeliverable={serviceDeliverable}
            serviceScopeDeliverable={serviceScopeDeliverable}
            feeHeadScope={feeHeadScope}
            deliverableSelectedScope={deliverableSelectedScope}
            defaultDeliverableExpandedKeys={defaultDeliverableExpandedKeys}
            defaultDeliverableCheckedKeys={defaultDeliverableCheckedKeys}
            defaultDeliverableSelectedKeys={defaultDeliverableSelectedKeys}
            setServiceDeliverable={this.setServiceDeliverable}
          />
        )
      },
      {
        title: 'Configure Stage',
        content: (
          <ServiceStageConfig
            noOfStage={noOfStage}
            stageDeliverableSource={stageDeliverableSource}
            stageDeliverableTarget={stageDeliverableTarget}
            feeHeadScope={feeHeadScope}
            setServiceStage={this.setServiceStage}
            setStageErrorHandle={this.setStageErrorHandle}
            deliverableSelectedScope={deliverableSelectedScope}
            deliverableSelectedId={deliverableSelectedId}
            deliverableChangedStatus={deliverableChangedStatus}
          />
        )
      },
      {
        title: 'Create New Service',
        content: (
          <ServiceResult
            noOfStage={noOfStage}
            serviceName={serviceName}
            elasticPrice={elasticPrice}
            typologyId={typologyId}
            typology={typology}
            setServiceName={this.setServiceName}
            setServiceSqft={this.setServiceSqft}
            setServiceAmount={this.setServiceAmount}
            addServiceElasticPrice={this.addServiceElasticPrice}
            removeServiceElasticPrice={this.removeServiceElasticPrice}
            insertStatus={insertStatus}
            serviceFormStatus={serviceFormStatus}
          />
        )
      }
    ];

    //Based on the component input field enable next button
    const NextButtonComponent = () => {
      if (current < steps.length - 1) {
        if (current === 1 && !spaceConfigStatus) {
          return (
            //Next Button
            <Button
              className={'nextButton'}
              type='primary'
              onClick={() => this.next()}
              disabled
            >
              Next
            </Button>
          );
        } else if (current === 2) {
          return (
            //Next Button
            <Button
              className={'nextButton'}
              type='primary'
              onClick={() => this.next()}
              disabled={!defaultDeliverableCheckedKeys.length}
            >
              Next
            </Button>
          );
        } else if (current === 3) {
          return (
            //Next Button
            <Button
              className={'nextButton'}
              type='primary'
              onClick={() => this.next()}
              disabled={
                !stageErrorStatus ||
                !stagePercentageStatus ||
                !stageSelectedError
              }
            >
              Next
            </Button>
          );
        } else {
          return (
            //Next Button
            <Button
              className={'nextButton'}
              type='primary'
              onClick={() => this.next()}
              disabled={!defaultScopeCheckedKeys.length}
            >
              Next
            </Button>
          );
        }
      } else {
        if (!insertStatus) {
          //Create New Service button
          return (
            <Button
              className={'nextButton'}
              type='primary'
              onClick={() => this.createService()}
              disabled={!serviceErrorStatus}
            >
              Create New Service
            </Button>
          );
        } else {
          return null;
        }
      }
    };

    return (
      //Create New Service div
      <div className={'createNewService'}>
        {/** Service Steps list */}
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        {/** Service Steps content */}
        <div className='steps-content'>{steps[current].content}</div>

        {/** Service Steps action */}
        <div className='steps-action'>
          {current > 0 && !insertStatus && (
            <Button className={'previousButton'} onClick={() => this.prev()}>
              Previous
            </Button>
          )}

          <NextButtonComponent />
          {/**After create new service show done button */}
          {current === steps.length - 1 && insertStatus && (
            <Button
              type='primary'
              className={'nextButton'}
              onClick={() => {
                this.setState(defaultState);
              }}
            >
              Done
            </Button>
          )}
        </div>
      </div>
    );
  }
}

//Declare proptypes
CreateNewService.propTypes = {
	client: PropTypes.object,
}

//export createNewService component
export default withApollo(CreateNewService)
