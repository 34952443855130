// import react component
import React from 'react'
import Tree from 'rc-tree'

// Tree height based on content
const onEnterActive = (node) => ({ height: node.scrollHeight })

// Tree expand and collapse animation effect
const motion = {
	motionName: 'node-motion',
	motionAppear: false,
	onEnterActive,
	onLeaveStart: (node) => ({ height: node.offsetHeight }),
}

// ScopeTree component
class ScopeTree extends React.Component {
	// Trigger, When ScopeTree expand
	/**
	 * @type - function
	 * @param - args contains list is expanded ids
	 * @summary - set that ids in defaultScopeExpandedKey and pass callback function
	 * @return - null
	 */
	onExpand = (...args) => {
		// deconstruct the props
		const { serviceScope, setServiceScope } = this.props

		// create state JSON structure and data
		const data = {
			scopeDeliverable: serviceScope.scopeDeliverable,
			serviceScopeExpandedKey: args[0],
			serviceScopeCheckedKey: serviceScope.serviceScopeCheckedKey,
			serviceScopeHalfCheckedKeys: serviceScope.serviceScopeHalfCheckedKeys,
		}

		// callback function
		setServiceScope(data, 'expand')
	}

	// When there is any changes in tree, onCheck function return the checked keys
	/**
	 * @type - function
	 * @param - checked keys from ScopeTree component
	 * @summary -Trigger, when ScopeTree deliverable checkbox changed or selected
	 * @return - null
	 */
	onCheck = (checkedKeys, info) => {
		// deconstruct the props
		const { serviceScope, setServiceScope } = this.props

		const newExpandedKey = [
			...serviceScope.serviceScopeExpandedKey,
			...info.halfCheckedKeys,
		]

		// create state JSON structure and data
		const data = {
			scopeDeliverable: serviceScope.scopeDeliverable,
			serviceScopeExpandedKey: newExpandedKey,
			serviceScopeCheckedKey: checkedKeys,
			serviceScopeHalfCheckedKeys: info.halfCheckedKeys,
		}

		// callback function
		setServiceScope(data, 'edit')
	}

	// render method
	render() {
		// deconstruct the props
		const { serviceScope } = this.props
		const {
			scopeDeliverable,
			serviceScopeCheckedKey,
			serviceScopeExpandedKey,
		} = serviceScope

		// Return scope tree component
		return (
			<div className="deliverableList">
				<Tree
					showLine
					checkable
					selectable={false}
					onExpand={this.onExpand}
					onCheck={this.onCheck}
					treeData={scopeDeliverable}
					motion={motion}
					defaultExpandedKeys={serviceScopeExpandedKey}
					defaultCheckedKeys={serviceScopeCheckedKey}
				/>
			</div>
		)
	}
}

// export ScopeTree component
export default ScopeTree
