const styles = {
	mainContentArea: {
		paddingTop: 10,
		background: '#DBDBDB',
		minHeight: 280,
		zIndex: 0,
	},
}

export default styles
