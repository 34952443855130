import gql from 'graphql-tag'

export const AbsenceDetailsQuery = gql`
	query AbsenceDetails($monthToFetch: String) {
		AbsenceDetails(monthToFetch: $monthToFetch) {
			absenceArray {
				id
				employeeName
				employeeBranch
				employeeTeam
				absenceCount
				absenceDate
			}
			teamListArray {
				text
				value
			}
		}
	}
`
