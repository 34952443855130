const styles = {
	chatView: {
		display: 'block',
		flexGrow: '1',
	},
	chatBody: {
		height: '69vh',
		width: '100%',
	},
	chatReverse: {
		overFlow: 'auto',
		height: '69vh',
		background: 'rgb(229, 221, 213)',
		display: 'flex',
		flexFlow: 'column-reverse',
	},
	chatAlignCenter: {
		textAlign: 'center',
	},
	chatYetToStart: {
		padding: 5,
		backgroundColor: '#e1f5feeb',
		borderRadius: 10,
	},
}

export default styles
