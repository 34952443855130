import gql from 'graphql-tag'

export const ThirdMenuQuery = gql`
	query getUserThirdMenu($menuId: String) {
		getUserThirdMenu(menuId: $menuId) {
			id
			text
			type
			menuLevel
			menuOrder
			componentPath
		}
	}
`

export const projects = gql`
	query projectDataListing {
		projectDataListing {
			id
			text
			type
			menuLevel
			menuOrder
			componentPath
			thirdMenu
		}
	}
`
