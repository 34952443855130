/**
    DesignQube React App - Login Page 
    If there are no cookies or no valid cookies, the user is redirected to this page
    Google OAuth is implemented to signIn / login
    Check OAuth & server.js in server folder for more details
* */

import React from 'react'
// Importing Redirect to route user accordingly after login
import { Redirect } from 'react-router-dom'
// For Google OAuth Popup
import { GoogleLogin } from 'react-google-login'
// Importing from apollo to perform mutation
import { useMutation } from '@apollo/react-hooks'
// Importing required components from Ant Design UI
import { Layout, Row, Col, Button, Card, message } from 'antd'
// Importing Custom Styles
import styles from './styles'
// Importing Background Image for the page
import designQubeLogo from '../../assets/home_logo.png'
// Importing Custom Styles./mutation
import { OAUTH_LOGIN_MUTATION } from './mutation'
// Override CSS + Custom Class CSS
import './index.css'

const { Content } = Layout

const errorMessage = () => {
	message.error('Unauthorized User . . .')
}

let googleClientId

if (process.env.REACT_APP_SERVER_MODE === 'development') {
	googleClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID
} else {
	googleClientId =
		'537830546855-nnb6rfu97diqa0vd2vrnnev77k4gnrs1.apps.googleusercontent.com'
}

export default function Login(props) {
	const { location } = props
	const [redirectToReferrer, setRedirectToReferrer] = React.useState(false)

	const { from } = location.state || { from: { pathname: '/' } }
	if (redirectToReferrer === true) {
		return <Redirect to={from} />
	}
	return <OAuthLogin setRedirectToReferrer={setRedirectToReferrer} />
}

function OAuthLogin(props) {
	const [oAuthLogin, { loading }] = useMutation(OAUTH_LOGIN_MUTATION)

	function responseError(err) {
		console.log('Error ', err)
		errorMessage()
	}

	async function responseGoogle(response) {
		if (loading) {
			return
		}
		const {
			tokenObj: { access_token: accessToken },
		} = await response

		try {
			await oAuthLogin({
				variables: {
					accessToken,
				},
			})
			props.setRedirectToReferrer(true)
		} catch (error) {
			errorMessage()
		}
	}
	return (
		<div>
			<Layout>
				<Content style={styles.mainDiv}>
					<Row style={styles.contentRow}>
						<Col style={styles.root}>
							<Card style={styles.designQubeLogoContainer}>
								<div>
									<img
										src={designQubeLogo}
										alt="designQubelogo"
										style={styles.designQubeLogoImg}
									/>
								</div>
							</Card>
							<GoogleLogin
								clientId={googleClientId}
								render={(renderProps) => (
									<div style={styles.oAuthContainer}>
										<Button
											className={`googleBtn${loading ? ' disabled' : ''}`}
											onClick={renderProps.onClick}
											disabled={renderProps.disabled || loading}
											style={styles.oAuthButton}
											size="large">
											<Row>
												<Col span={4}>
													<OAuthSvg loading={loading} />
												</Col>
												<Col span={20}>
													<p style={styles.oAuthButtonText}>Login with Google</p>
												</Col>
											</Row>
										</Button>
									</div>
								)}
								buttonText="Login"
								onSuccess={responseGoogle}
								onFailure={responseError}
								cookiePolicy="single_host_origin"
							/>
						</Col>
					</Row>
				</Content>
			</Layout>
		</div>
	)
}

function OAuthSvg(props) {
	const { loading } = props
	return (
		<svg
			viewBox="0 0 18 18"
			role="presentation"
			aria-hidden="true"
			focusable="true"
			style={styles.oAuthGoogleSvg}>
			<g fill="none" fillRule="evenodd">
				<path
					d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
					fill={`${loading ? '#fff' : '#EA4335'}`}
				/>
				<path
					d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
					fill={`${loading ? '#fff' : '#4285F4'}`}
				/>
				<path
					d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
					fill={`${loading ? '#fff' : '#FBBC05'}`}
				/>
				<path
					d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
					fill={`${loading ? '#fff' : '#34A853'}`}
				/>
				<path d="M0 0h18v18H0V0z" />
			</g>
		</svg>
	)
}
