import gql from 'graphql-tag'

export const GET_PROPOSAL_SERVICE_DETAILS_QUERY = gql`
	query getProposalServiceDetails($serviceId: String!) {
		getProposalServiceDetails(serviceId: $serviceId) {
			serviceScope
			serviceSpace
			serviceDeliverable
			serviceStageConfig
			serviceStageDeliverableList
			serviceResult
			serviceTeamDescription
			serviceTermsAndCondition
		}
	}
`

export const GET_ENQUIRY_PROPOSAL_DETAILS = gql`
	query getEnquiryProposalDetails($enquiryId: String!) {
		getEnquiryProposalDetails(enquiryId: $enquiryId) {
			serviceScope
			serviceSpace
			serviceDeliverable
			serviceStageConfig
			serviceStageDeliverableList
			serviceResult
			serviceSwot
			versionId
			serviceId
			proposalStatus
			typologyId
			noOfStage
			servicePriceConfig
			typologyName
			serviceTeamDescription
			selectedPackage
			serviceTermsAndCondition
			proposalPackage
			feesPackage
		}
	}
`

export const GET_PROPOSAL_TYPOLOGY_LIST_QUERY = gql`
	query getProposalTypologyList {
		getProposalTypologyList {
			typologyId
			typologyName
			noOfStage
		}
	}
`

export const GET_PROPOSAL_SERVICE_LIST_QUERY = gql`
	query getProposalServiceList($searchString: String) {
		getProposalServiceList(searchString: $searchString) {
			service {
				id
				name
			}
			scope
			typology {
				id
				name
			}
			noOfStage
			updatedAt
		}
	}
`

export const GET_GST_DETAILS = gql`
	query getGstDetails {
		getGstDetails {
			label
			gstId
			value
			percent
		}
	}
`

export const GENERATE_NEW_PROPOSAL = gql`
	mutation generateNewProposal(
		$typologyName: String!
		$typologyId: String!
		$serviceId: String!
		$enquiryId: String!
		$totalSqft: Int!
		$proposalResult: String!
		$serviceScopeConfig: String!
		$serviceSpaceConfig: String!
		$serviceDeliverableConfig: String!
		$serviceStageConfig: String!
		$serviceDeliverableList: String!
		$serviceSwot: String!
		$noOfStage: Int!
		$selectedPackage: String!
		$servicePriceConfig: String!
		$serviceTeamDescription: String!
		$serviceTermsAndCondition: String!
		$feesPackageFeatures: String!
	) {
		generateNewProposal(
			typologyName: $typologyName
			typologyId: $typologyId
			serviceId: $serviceId
			enquiryId: $enquiryId
			totalSqft: $totalSqft
			proposalResult: $proposalResult
			serviceScopeConfig: $serviceScopeConfig
			serviceSpaceConfig: $serviceSpaceConfig
			serviceDeliverableConfig: $serviceDeliverableConfig
			serviceStageConfig: $serviceStageConfig
			serviceDeliverableList: $serviceDeliverableList
			serviceSwot: $serviceSwot
			noOfStage: $noOfStage
			servicePriceConfig: $servicePriceConfig
			selectedPackage: $selectedPackage
			serviceTeamDescription: $serviceTeamDescription
			serviceTermsAndCondition: $serviceTermsAndCondition
			feesPackageFeatures: $feesPackageFeatures
		)
	}
`

export const GET_CLIENT_DETAILS = gql`
	query getClientDetails($enquiryId: String!) {
		getClientDetails(enquiryId: $enquiryId) {
			name
			email
			pincode
			address
			area
		}
	}
`

// type - Mutation
// purpose - Create New service
export const CREATE_NEW_SERVICE_MUTATION = gql`
	mutation createNewService(
		$serviceScopeConfig: String!
		$serviceSpaceConfig: String!
		$serviceDeliverableConfig: String!
		$serviceStageConfig: String!
		$serviceDeliverableList: String!
		$servicePriceConfig: String!
		$serviceTeamDescription: String!
		$serviceTermsAndCondition: String!
		$serviceId: String
	) {
		createNewService(
			serviceScopeConfig: $serviceScopeConfig
			serviceSpaceConfig: $serviceSpaceConfig
			serviceDeliverableConfig: $serviceDeliverableConfig
			serviceStageConfig: $serviceStageConfig
			serviceDeliverableList: $serviceDeliverableList
			servicePriceConfig: $servicePriceConfig
			serviceTeamDescription: $serviceTeamDescription
			serviceTermsAndCondition: $serviceTermsAndCondition
			serviceId: $serviceId
		)
	}
`
