const styles = {
	spinIcon: { fontSize: 24 },
	spinIconStyle: { padding: '30px 52px' },
	profileDrawerHeaderText: { color: '#3a3a3a' },
	profileDrawerHeader: {
		cursor: 'pointer',
		color: '#909090',
		fontSize: 13,
		marginTop: -10,
		fontWeight: '500',
	},
	bottomFixedButtonDiv: {
		position: 'absolute',
		left: 0,
		bottom: 60,
		width: '100%',
		borderTop: '1px solid #e9e9e9',
		padding: '10px 16px',
		background: '#eee',
		textAlign: 'center',
	},
	bottomFixedButton: { marginRight: 8 },
	drawerStyle: { marginTop: '60px' },
}

export default styles
