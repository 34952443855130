import gql from 'graphql-tag'

export const INSERT_DATA = gql`
	mutation InsertData($arrayData: [arrayData!]) {
		InsertData(arrayData: $arrayData)
	}
`

export const UPLOAD_COUNT = gql`
	query uploadCount {
		uploadCount
	}
`
export const UPLOAD_KEY = gql`
	query uploadKey {
		uploadKey{
      id
    }
	}
`

export const GET_DATA = gql`
	query GetData($id: String!) {
		GetData (id: $id) {
			sno
			CABINET_NAME
			PANEL_TYPE
			FACTORY_LABEL
			PANEL_NAME
			THICKNESS
			LENGTH_1
			LENGTH_2
			EXT_COLOR
			INT_COLOR
			EDGE_BAND
			REMARKS_1
			REMARKS_2
			GRAINS_DIRECTION
		}
	}
`
