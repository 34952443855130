import {
	Button,
	Col,
	Icon,
	Menu,
	Modal,
	Row,
	Select,
	Tooltip,
	Typography,
	Input,
	message,
	notification,
} from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { FiPhone, FiPhoneOff, FiPhoneIncoming } from 'react-icons/fi'
import { GoGlobe } from 'react-icons/go'
import { FaPhone } from 'react-icons/fa'
import { MdVerifiedUser, MdWatch, MdAttachFile } from 'react-icons/md'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { format, formatDistanceToNow } from 'date-fns' // Date FNS for time format functions
import moment from 'moment'
import { Redirect, Link } from 'react-router-dom'
import AddToFollowup from './AddToFollowup/index'
import ChangeEnquiryStage from './ChangeEnqiuryStage/index'
import ChangeTeamHead from './ChangeTeamHead/index'
import CrmChat from '../CrmChat'
import ListDuplicateEnquiries from './ListDuplicateEnquiries/index'

import ListEnquiryDetails from './ListEnquiryDetails/index'
import MarkAsUnfollowed from './MarkAsUnfollowed/index'
import ModifyFollowupTime from './ModifyFollowupTime/index'
import styles from './styles'
import { EDIT_ENQUIRY_DETAILS_MUTATION } from './ListEnquiryDetails/mutation'
import utils from '../../../utils'
// Importing required components from ANTD
import './index.css'
// Query to fetch CRM Details on load
const { encryptEnquiryId } = utils
// Component CSS
const { confirm } = Modal
const { Option } = Select
const { Title } = Typography
// Function for search filter in Select Component
const children = []
for (let i = 10; i < 36; i += 1) {
	children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
}

function disabledDate(current) {
	// Can not select days before today and today
	return current && current < moment().startOf('day')
}

function EnquiryNameComponent(props) {
	const { enquiryName, currentEnquiryId, refetch, closeModal } = props

	const [newEnquiryName, setNewEnquiryName] = useState(null)
	const [confirmButtonState, setConfirmButtonState] = useState(false)

	const updatedName = React.useRef(newEnquiryName)

	useEffect(() => {
		updatedName.current = newEnquiryName
	}, [newEnquiryName])

	function validateName() {
		const oldEnquiryNameValue = enquiryName.trim()
		const newEnquiryNameValue = updatedName.current
			? updatedName.current.trim()
			: updatedName.current

		if (!updatedName.current || oldEnquiryNameValue === newEnquiryNameValue) {
			message.warning('Please enter a valid name . . . ')
			editEnquiryName()
			return false
		}

		return true
	}

	const [editEnquiryDetails] = useMutation(EDIT_ENQUIRY_DETAILS_MUTATION, {
		variables: {
			enquiryId: currentEnquiryId,
			enquiryDetailType: 'enquiryName',
			oldValue: enquiryName,
			newValue: newEnquiryName,
		},
	})

	async function editEnquiryName() {
		confirm({
			title: 'Updating Enquiry Name . . .',
			content: (
				<>
					<Row gutter={[16, 8]} style={{ marginTop: 10 }}>
						<Col span={8}>
							<strong> Old Name :</strong>
						</Col>
						<Col span={20}>
							<span style={{ fontWeight: 400 }}> {enquiryName} </span>
						</Col>
					</Row>
					<Row gutter={[16, 8]}>
						<Col span={9}>
							<strong> New Name :</strong>
						</Col>
						<Col span={20}>
							<Input
								placeholder="Enter the new enquiry name . . ."
								allowClear
								defaultValue={enquiryName}
								onChange={(e) => setNewEnquiryName(e.target.value)}
								// onPressEnter={(e) => validateName()}
							/>
						</Col>
					</Row>
				</>
			),
			okText: 'Confirm',
			centered: true,
			maskClosable: true,
			keyboard: true,
			// okButtonProps: { disabled: true },
			async onOk() {
				const validateCheck = validateName()
				if (validateCheck) {
					try {
						await editEnquiryDetails()
						refetch()
						notification.success({
							message: 'Enquiry Name Updated . . .',
							description: 'Successfully',
							style: { marginTop: 50 },
						})
						setTimeout(() => {
							closeModal()
						}, 1000)
					} catch (error) {
						return null
					}
				}
			},
		})
	}
	return (
		<Typography.Text
			className="editDetailsList"
			editable={{
				onStart: () => editEnquiryName(),
			}}>
			{enquiryName ? enquiryName.toLowerCase() : 'Anonymous'}
		</Typography.Text>
	)
}
class CrmModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount(props) {
		document.addEventListener(
			'keydown',
			(event) => this.arrowClickEvent(event, props),
			false
		)
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.arrowClickEvent, false)
	}

	arrowClickEvent(event) {
		if (
			event.target.type &&
			(event.target.type === 'textarea' || event.target.type === 'text')
		)
			return
		const { enquiryStatus, openNextEnquiryModal, openPreviousEnquiryModal } = this.props
		if (event.keyCode === 39) {
			openNextEnquiryModal(enquiryStatus)
		} else if (event.keyCode === 37) {
			openPreviousEnquiryModal()
		}
	}

	render() {
		const {
			closeModal,
			currentEnquiryId,
			currentEnquiryList,
			currentModalData,
			// enquiryStatus,
			nextEnquiryId,
			openNextEnquiryModal,
			openPreviousEnquiryModal,
			prevEnquiryId,
			refetch,
			visible,
			handlers,
		} = this.props

		const {
			contactNo,
			dateOfEnquiry,
			duplicateEnquiries,
			email,
			enquiryName,
			enquiryType,
			followupActive,
			proposalLastSeen,
			followupDate,
			laneId,
			nri,
			otpCheck,
			// timeOfEnquiry,
		} = currentModalData

		let followupDatePick
		let followupTimePick
		let followupTimeLeft

		if (followupDate) {
			followupDatePick = format(new Date(followupDate * 1000), 'MMMM do, yyyy')
			followupTimePick = format(new Date(followupDate * 1000), 'h:mm a')
			followupTimeLeft = formatDistanceToNow(new Date(followupDate * 1000), {
				addSuffix: true,
			})
				.replace('ago', 'crossed')
				.replace('about', '')
		}

		const activeFollowupOptions = (
			<Menu>
				<Menu.Item key="0">
					<ModifyFollowupTime
						currentEnquiryId={currentEnquiryId}
						enquiryType={enquiryType}
						refetch={refetch}
						closeModal={closeModal}
						disabledDate={disabledDate}
					/>
				</Menu.Item>
				<Menu.Item key="1">
					<MarkAsUnfollowed
						currentEnquiryId={currentEnquiryId}
						enquiryType={enquiryType}
						refetch={refetch}
						closeModal={closeModal}
					/>
				</Menu.Item>
			</Menu>
		)

		return (
			<div>
				<Modal
					style={{ top: 80 }}
					closable
					closeIcon={
						<p style={{ marginTop: -15, marginLeft: -36, fontSize: 14 }}> Close </p>
					}
					maskClosable
					visible={visible}
					width={850}
					onCancel={closeModal}
					footer={[
						<Row>
							<Col span={4}>
								{prevEnquiryId && (
									<Button
										key="prev_enquiry"
										type="secondary"
										onClick={openPreviousEnquiryModal}
										style={{ float: 'left', fontWeight: 600 }}>
										<Icon type="left" />
										Previous enquiry
									</Button>
								)}
							</Col>
							<Col span={8}>
								<div
									style={{
										margin: '-1%',
										textAlign: 'center',

										marginTop: 6,
										fontSize: 12,
									}}>
									<span
										style={{
											marginTop: 5,
											marginLeft: '-25%',
											fontWeight: 400,
											color: '#636363',
											display: prevEnquiryId ? 'block' : 'none',
										}}>
										Press left arrow or click
									</span>
								</div>
							</Col>
							<Col span={8}>
								<div
									style={{
										margin: '-1%',
										textAlign: 'center',

										marginTop: 6,
										fontSize: 12,
									}}>
									<span
										style={{
											marginTop: 5,
											fontWeight: 400,
											color: '#636363',
											marginRight: '-39%',
										}}>
										Press right arrow or click
									</span>
								</div>
							</Col>
							<Col span={4}>
								{nextEnquiryId && (
									<Button
										key="next_enquiry"
										type="secondary"
										style={{ fontWeight: 600 }}
										onClick={openNextEnquiryModal}>
										Next Enquiry
										<Icon type="right" />
									</Button>
								)}
							</Col>
						</Row>,
					]}>
					<Row gutter={[16, 8]}>
						<Col span={10}>
							{/* Enquiry Details List */}

							<Title level={4}>
								<Row style={{ borderBottom: '1px solid #eee' }}>
									<Col
										span={24}
										style={{
											fontSize: 16,
											textTransform: 'capitalize',
											marginTop: -8,
											marginBottom: 5,
										}}>
										<EnquiryNameComponent
											enquiryName={enquiryName}
											currentEnquiryId={currentEnquiryId}
											refetch={refetch}
											closeModal={closeModal}
										/>

										{proposalLastSeen && (
											<Tooltip
												title={`Proposal last seen @ ${format(
													new Date(proposalLastSeen * 1000),
													'MMM dd, yyyy - h:mm a'
												)}`}
												placement="topRight">
												<MdAttachFile style={styles.duplicatesCount} />
											</Tooltip>
										)}

										{followupActive === 'yes' && (
											<Tooltip title={followupTimeLeft} placement="topRight">
												<MdWatch style={styles.activeFollowup} />
											</Tooltip>
										)}

										{nri && (
											<Tooltip title="NRI Client">
												<GoGlobe style={styles.nriGlobe} />
											</Tooltip>
										)}
										{contactNo === null ? (
											<Tooltip title="Mobile number not available">
												<FiPhoneOff style={styles.mobileAndNriNotVerified} />
											</Tooltip>
										) : null}

										{contactNo && otpCheck === 'otp_matched' ? (
											<Tooltip title="Mobile number verified">
												<MdVerifiedUser style={styles.mobileAndNriVerified} />
											</Tooltip>
										) : null}

										{contactNo && otpCheck === 'manual_entry' ? (
											<Tooltip title="Manual Enquiry Mobile Number">
												<FiPhoneIncoming style={styles.mobileAndNriNotVerified} />
											</Tooltip>
										) : null}

										{(contactNo && otpCheck === 'otp_mismatched') ||
										(contactNo && otpCheck === null) ||
										(contactNo && otpCheck === 'invalid_response') ||
										(contactNo && !otpCheck) ? (
											<Tooltip title="Mobile number not verified">
												<FiPhone style={styles.mobileAndNriNotVerified} />
											</Tooltip>
										) : (
											''
										)}
									</Col>
								</Row>
							</Title>

							{/* List Enquiry Details */}

							<ListEnquiryDetails
								currentEnquiryId={currentEnquiryId}
								currentEnquiryList={currentEnquiryList}
								refetch={refetch}
								handlers={handlers}
							/>

							{/* Duplicate Enquiries */}

							<ListDuplicateEnquiries
								contactNo={contactNo}
								currentEnquiryId={currentEnquiryId}
								duplicateEnquiries={duplicateEnquiries}
								email={email}
								handlers={handlers}
							/>

							{/* Team Head Selection Dropdown */}

							<ChangeTeamHead
								closeModal={closeModal}
								currentEnquiryId={currentEnquiryId}
								enquiryType={enquiryType}
								laneId={laneId}
								refetch={refetch}
							/>

							{/* Enquiry Stage Selection Dropdown */}

							<ChangeEnquiryStage
								closeModal={closeModal}
								currentEnquiryId={currentEnquiryId}
								enquiryType={enquiryType}
								refetch={refetch}
							/>

							{/* Followup Active/Inactive Swicth */}

							<AddToFollowup
								activeFollowupOptions={activeFollowupOptions}
								closeModal={closeModal}
								currentEnquiryId={currentEnquiryId}
								disabledDate={disabledDate}
								enquiryType={enquiryType}
								followupActive={followupActive}
								refetch={refetch}
							/>

							{/* Followup Date Selection Dropdown */}

							{followupActive === 'yes' && (
								<Row style={{ marginTop: 15, marginBottom: 15 }}>
									<Col span={4}>
										<Typography.Text strong style={{ fontSize: 15 }}>
											Date :
										</Typography.Text>
									</Col>
									<Col span={20}>
										<Typography.Text style={{ fontSize: 16 }}>
											{followupDatePick}
										</Typography.Text>
									</Col>
								</Row>
							)}

							{followupActive === 'yes' && (
								<Row style={{ marginBottom: 10 }}>
									<Col span={4}>
										<Typography.Text strong style={{ fontSize: 15 }}>
											Time :
										</Typography.Text>
									</Col>
									<Col span={20}>
										<Typography.Text style={{ fontSize: 16 }}>
											{followupTimePick}
										</Typography.Text>
									</Col>
								</Row>
							)}

							{enquiryType === 'met' ||
							enquiryType === 'proposal_sent' ||
							enquiryType === 'proposal_seen' ||
							enquiryType === 'under_negotiation' ? (
								<Row style={{ marginTop: 10, borderTop: '1px solid #eee' }}>
									<Col style={{ marginTop: 20 }} span={24}>
										<Link
											to={`/generate-proposal/${encryptEnquiryId(currentEnquiryId)}`}
											target="_blank">
											<Button type="primary">
												{enquiryType === 'met' ? 'Generate Proposal' : 'Edit Proposal'}
											</Button>
										</Link>
									</Col>
								</Row>
							) : null}
						</Col>

						<Col span={14}>
							<CrmChat
								arrowClickEvent={this.arrowClickEvent}
								dateOfEnquiry={dateOfEnquiry}
								enquiryId={currentEnquiryId}
								enquiryType={enquiryType}
							/>
						</Col>
					</Row>
				</Modal>
			</div>
		)
	}
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components

export default CrmModal
