/**
    Pdf covertor Component
    This component will render pdf downloader. 
* */

/*  Import Components  */
import React from 'react'
/* Import required components from ANT DESIGN  */
import { Button } from 'antd'

/* Import react print componet  */
import ReactToPrint from 'react-to-print'

/* Import react print componet  */
import TableData from '../TableData'

/* Import Override CSS */
import '../index.css'

/* Import Custom Styles */

import styles from '../styles'
/* Import Antd CSS */

import 'antd/dist/antd.css'

class PdfConvertor extends React.Component {
	render() {
		/* Deconstructing the props  */
		const { sketchUpArray,colorDropdown } = this.props

		return (
			<div>
				<ReactToPrint
					trigger={() => {
						// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
						// to the root node of the returned component as it will be overwritten.
						return <Button style={styles.ShowClosedTickets}>Download As Pdf</Button>
					}}
					// onBeforePrint={() => }
					content={() => this.componentRef}
				/>
				<TableData sketchUpArray={sketchUpArray} colorDropdown={colorDropdown} ref={(el) => (this.componentRef = el)} />
			</div>
		)
	}
}

export default PdfConvertor
