// Import React Component
import React, { useEffect } from 'react'

import { ApolloProvider } from 'react-apollo'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Analytics from 'react-router-ga'
import { hotjar } from 'react-hotjar'
import client from './service'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import NewProposal from './components/NewProposal'

import Home from './pages/Home'
import Login from './pages/Login'
import FourNotFourPage from './pages/FourNotFour'
import CrmBoard from './components/CRM/CrmBoard'
import HowDidIDoPublic from './components/HowDidIDoPublic'
import DriveAuthorize from './components/Drawings/DriveAuthorize'
import DriveTokenize from './components/Drawings/DriveTokenize'

// Define App Component
export default function App() {
	useEffect(() => {
		hotjar.initialize('1675580', 6)
	}, [])
	return (
		<ApolloProvider client={client}>
			{/* Create url based pages (components) */}
			<Router>
				<Analytics id="UA-157894351-1" debug>
					<Switch>
						{/* Login Page */}
						<PublicRoute exact path="/login" component={Login} />
						{/* Home page or Initial Page */}
						<PrivateRoute
							exact
							path="/authenticateGoogleDrive"
							component={DriveAuthorize}
						/>
						<PrivateRoute
							exact
							path="/oauth2callback"
							component={
								new URL(window.location.href).searchParams.get('code')
									? DriveTokenize
									: Home
							}
						/>
						<PrivateRoute
							exact
							path="/generate-proposal/:enquiryId"
							component={NewProposal}
						/>
						<Route exact path="/how-did-i-do/:userName/:reaction">
							<HowDidIDoPublic />
						</Route>
						<PrivateRoute component={Home} />
						{/* All other urls will show the 404 Error page */}
						{/* <Route component={FourNotFourPage} /> */}
					</Switch>
				</Analytics>
			</Router>
		</ApolloProvider>
	)
}
