import React from 'react'
import { Row, Col, Result } from 'antd'
import PropTypes from 'prop-types'
import './index.css'

class serviceResult extends React.Component {
	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleServiceNameChange = (evt) => {
		this.props.setServiceName(evt.target.value)
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleServiceSqftChange = (idx) => (evt) => {
		const newSqft = this.props.elasticPrice.map((sqft, sidx) => {
			if (idx !== sidx) return sqft
			return { ...sqft, sqft: evt.target.value }
		})

		this.props.setServiceSqft(newSqft)
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleServiceAmountChange = (idx) => (evt) => {
		const newAmount = this.props.elasticPrice.map((amount, sidx) => {
			if (idx !== sidx) return amount
			return { ...amount, amount: evt.target.value }
		})

		this.props.setServiceAmount(newAmount)
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	validateInput = () => {
		const { serviceName, elasticPrice } = this.props
		let serviceStatus = true
		let elasticStatus = true
		serviceStatus = !!serviceName

		for (let x = 0; x < elasticPrice.length; x++) {
			const { sqft, amount } = elasticPrice[x]

			if (
				sqft === 0 ||
				sqft === '' ||
				sqft === undefined ||
				sqft === null ||
				amount === 0 ||
				amount === '' ||
				amount === undefined ||
				amount === null
			) {
				elasticStatus = false
			}
		}

		return !!(serviceStatus && elasticStatus)
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleAddNewPrice = () => {
		this.props.addServiceElasticPrice()
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleRemovePrice = (idx) => () => {
		this.props.removeServiceElasticPrice(idx)
	}

	render() {
		const {
			typologyId,
			typology,
			insertStatus,
			serviceName,
			elasticPrice,
			serviceFormStatus,
		} = this.props

		const typologyName = this.getTypologyName(typology, typologyId)

		return (
			<div>
				{!insertStatus ? (
					<div className="serviceResultDiv">
						<h1>8 Stage - {typologyName}</h1>
						<Row>
							<Col span={7} />
							<Col span={10}>
								<form className="serviceResult">
									<h4>Service Name</h4>

									<input
										type="text"
										placeholder="Enter Service Name"
										value={serviceName}
										className={
											!serviceFormStatus
												? ''
												: serviceFormStatus && serviceName === ''
												? 'error'
												: ''
										}
										onChange={this.handleServiceNameChange}
									/>

									<h4>Service Price</h4>

									{elasticPrice.map((price, idx) => (
										<div className="price">
											<input
												type="text"
												className={
													!serviceFormStatus
														? ''
														: serviceFormStatus &&
														  (price.sqft === '' ||
																price.sqft === 0 ||
																price.sqft === undefined ||
																price.sqft === null)
														? 'error'
														: ''
												}
												placeholder="Enter sqft"
												value={price.sqft}
												onChange={this.handleServiceSqftChange(idx)}
											/>
											<input
												type="text"
												className={
													!serviceFormStatus
														? ''
														: serviceFormStatus &&
														  (price.amount === '' ||
																price.amount === 0 ||
																price.amount === undefined ||
																price.amount === null)
														? 'error'
														: ''
												}
												placeholder="Enter Elastic Price"
												value={price.amount}
												onChange={this.handleServiceAmountChange(idx)}
											/>
											<button
												type="button"
												onClick={this.handleRemovePrice(idx)}
												className="addPrice">
												-
											</button>
										</div>
									))}
									<button
										type="button"
										onClick={this.handleAddNewPrice}
										className="addPrice">
										+ Add Price
									</button>
								</form>
							</Col>
							<Col span={7} />
						</Row>
					</div>
				) : (
					<div>
						<Result status="success" title="New Service Successfully created." />
					</div>
				)}
			</div>
		)
	}
}

serviceResult.propTypes = {
	typologyId: PropTypes.string,
	typology: PropTypes.array,
	insertStatus: PropTypes.bool,
	createNewService: PropTypes.func,
	serviceName: PropTypes.string,
	elasticPrice: PropTypes.array,
	setServiceName: PropTypes.func,
	setServiceSqft: PropTypes.func,
	setServiceAmount: PropTypes.func,
	addServiceElasticPrice: PropTypes.func,
	removeServiceElasticPrice: PropTypes.func,
	serviceFormStatus: PropTypes.bool,
}

export default serviceResult
