const styles = {
  userWelcomeCard: { width: 105, background: '#E2E2E2', height: 87 },
  welcomeCardBody: { paddingLeft: 12 },
  welcomeUserName: { fontWeight: 'bold', textAlign: 'center' },
  loadingIcon: { fontSize: 24 },
  loadIconStyle: { padding: '30px 52px' },
  firstMenuStyle: {
    borderRight: '1px solid #fcfcfc',
    overflowY: 'overlay',
    overflowX: 'hidden',
    height: '80vh',
  },
};

export default styles;
