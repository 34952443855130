// import react component
import React, { Component } from 'react'
import ScopeTree from './ScopeTree' // Import Scope Tree component
import './index.css' // Import component CSS

// Service scope component
class ServiceScopeConfig extends Component {
	// service scope callback function
	setServiceScope = (data, type) => {
		const { setServiceScope } = this.props
		// callback function
		setServiceScope(data, type)
	}

	render() {
		// Deconstruct props
		const { serviceScope } = this.props

		return (
			<div className="serviceScope">
				{/** load service scope tree */}
				<ScopeTree serviceScope={serviceScope} setServiceScope={this.setServiceScope} />
			</div>
		)
	}
}

// export ServiceScopeConfig component
export default ServiceScopeConfig
