/**
  Open client tickets Component
  This component will render display open client tickets 
* */

/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Spin, Icon, Typography, Modal } from 'antd'

// Import required components from FontAwesome
import { FaRegCommentDots } from 'react-icons/fa'

// Query component of React Apollo to fetch data
import { Query } from 'react-apollo'
// Import Override CSS
import './index.css'
// Query to fetch open client ticket Details
import { CLIENT_DISCUSSION } from '../query'

// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'

// Import thumbsup image
import thumbsUp from '../../../assets/thumbs_up.png'

// import utils file
import util from '../../../utils'

import ModalContentDisplay from '../modalContentDisplay'

// deconstruct Text from Typography
const { Text,Paragraph} = Typography

/**
 * This class component is used to display open client ticket images in webapp.
 * @returns {open client ticket component}
 */
class OpenClientTickets extends React.Component {
	// initial states
	state = {
		ismodalopen: false, // modal visibility
		indexSet: 0, // index value state it used for find index for modal content
    selection: false,
    loadCount:1,
	}

	/**
	 * get current selected image among all images
	 * and set it in a state
	 */
	setSelected = (index, element, flatternArray) => {
		const imageIndex = flatternArray.findIndex(
			(i) => i.commentId === element.commentId && i.key === element.key
		)
		this.setState({
			indexSet: imageIndex,
			ismodalopen: true,
			selection: true,
		})
	}

	/**
	 *  Sets the visiblity of modal to false when called
	 */
	handleCancel = () => {
		this.setState({
			ismodalopen: false,
			selection: false,
		})
	}


	/**
	 * It should be excecute first
	 * This is used for display open client tickets photos.
	 * @returns {open client ticket page}
	 */
	/**
	 * This function is used to display a open client tickets photos.
	 * It contain card profile name, photo comment and Action event like comment.
	 * @returns {open client ticket photo}
	 */

	render() {
		// Deconstructing the state
		const { ismodalopen,indexSet,selection,loadCount } = this.state
		// Deconstructing the props
		const { projectUrl } = this.props

		return (
			<Query
				query={CLIENT_DISCUSSION}
				variables={{
					projectUrl,
					status: 'unresolved',
					limit: 3,
					offset: 0,
					spaceId: 'All',
				}}>
				{({
					data: openClientTicketData,
					loading: openClientTicketLoading,
					error: openClientTicketError,
					fetchMore,
					refetch,
				}) => {
					if (openClientTicketLoading) {
						// loading -> it handles a loading data status
						return (
							<Spin
								indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
								style={styles.loadIconStyle}
							/>
						)
					}
					if (
						!openClientTicketData ||
						!openClientTicketData.ClientDiscussion ||
						openClientTicketError
					) {
						return <span>Some error </span>
					}
					// flattern array -> Array combine for projectphotos
					const flatternArray = util.flatten(
						openClientTicketData.ClientDiscussion.discussions
					)

					// console.log(openClientTicketData.ClientDiscussion.discussions.length)
					// console.log(openClientTicketData.ClientDiscussion)

					return (
						<div style={styles.openClientTicket}>
							<div style={styles.openClientTicketCount}>
								Open Client Tickets (
								{openClientTicketData.ClientDiscussion.discussionCount})
							</div>
							<div>
								{/* open client tickets photos data looping start */}
								{openClientTicketData.ClientDiscussion.discussions.map(
									(openClientData, ticketIndex) => {
										const names =
											openClientData &&
											openClientData.childCommentsLatest.profile &&
											openClientData.childCommentsLatest.profile.name
												? openClientData.childCommentsLatest.profile.name
												: ''
										const name = names.split(' ')
										return (
											<div
												style={styles.openClient}
												key={ticketIndex}
												className="openClientHover"
												onClick={() =>
													this.setSelected(
														ticketIndex,
														openClientData,
														flatternArray
													)
												}>
												{/* image part */}
												<div style={styles.imagePart}>
													{/* poster name display */}
													<div style={styles.posterName}>
														{util.getInitials(openClientData.uploadedProfile.name)}
													</div>
													{/* image display */}
													<img
														style={styles.Images}
														src={
															openClientData.thumbnailUri ||
															openClientData.uri ||
															openClientData.qualityUri
														}
														alt="Photos"
													/>
													{/* icon display */}
													{openClientData.childCommentCount > 0 ? (
														<span style={styles.commentIconWithoutBackgroud}>
															<FaRegCommentDots />
														</span>
													) : null}
												</div>
												{/* content part */}
												<div style={styles.contentPart}>
													<div style={styles.parentComment}>
														<div style={styles.profile}>
															<Text style={styles.profileName}>
																{openClientData.commentedProfile.name}
															</Text>
															<Text style={styles.profileTime}>
																{util.formatRelativetimestamp(openClientData.commentedAt)}
															</Text>
														</div>
														<div style={styles.ProfileText}>
															{openClientData.commentText}
														</div>
													</div>
													{openClientData.childCommentCount > 0 ? (
														<div style={styles.childProfile}>
															
															{openClientData.childCommentsLatest.latestComment ===
															'ðŸ‘' ? (
                                 <>
                                <Text style={styles.childProfileName}>
																{`${name[0]} : `}
															</Text>

																<img
																	alt="client Chat"
																	style={styles.childProfileText}
																	src={thumbsUp}
																	width="20"
																	height=""
																/>
                                </>
															) : (
																<Paragraph ellipsis style={styles.childProfileText}>
                                  {`${name[0]}: ${openClientData.childCommentsLatest.latestComment}`}
																
																</Paragraph>
															)}
															{/* <Text style={styles.childProfileTime}>
																	{util.formatRelativetimestamp(
																		openClientData.childCommentsLatest.commentedAt
																	)}
																</Text> */}
														</div>
													) : null}
												</div>
											</div>
										)
									}
								)}
							</div>
							{openClientTicketData.ClientDiscussion.discussionCount ===
							openClientTicketData.ClientDiscussion.discussions.length ? (
								<div style={styles.noTicketShow}>No more tickets to show :)</div>
							) : (
								<div
									style={styles.ticketShow}
									onClick={() =>
										fetchMore({
											variables: {
												offset: openClientTicketData.ClientDiscussion.discussions.length,
											},
											updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev
                        this.setState({ loadCount: loadCount+1 })
												return {
													...prev,
													ClientDiscussion: {
														...prev.ClientDiscussion,
														discussions: [
															...prev.ClientDiscussion.discussions,
															...fetchMoreResult.ClientDiscussion.discussions,
														],
													},
												}
											},
										})
									}>
									Show more
								</div>
							)}

							{/* Image modal display */}
							<Modal
								title={null}
								header={null}
								visible={ismodalopen}
								footer={null}
								onCancel={this.handleCancel}
								wrapClassName="antmodalclass"
								style={{ overflowY: 'hidden !important' }}>
								{ismodalopen ? (
									<div style={styles.fullModal}>
										{/*  modal content */}
                    <ModalContentDisplay
                        indexSet={indexSet}
                        status="unresolved"
                        loadCount={loadCount}
												userHashState={openClientTicketData.ClientDiscussion.loggedInUser}
												flatternArray={flatternArray}
                        projectUrl={projectUrl}
                        handleCancel={this.handleCancel}
                        selection={selection}
											/>
									</div>
								) : null}
							</Modal>
						</div>
					)
				}}
			</Query>
		)
	}
}

export default OpenClientTickets
