import React, { useState, useEffect } from 'react'
import { detect } from 'detect-browser'
import {
	Dropdown,
	Modal,
	Typography,
	Button,
	Form,
	Input,
	Row,
	Col,
	Select,
	Menu,
	notification,
} from 'antd'

import { Mutation } from 'react-apollo'
import { SUBMIT_USER_FEEDBACK } from './mutation'
import utils from '../../../utils'

const { stringEncode } = utils
const { Option } = Select
const { TextArea, Search } = Input
const { Title } = Typography
const browser = detect()

const ResponseForm = Form.create({ name: 'form_in_modal' })(
	// eslint-disable-next-line
	class extends React.Component {
		handleCreate = (mutate, userName, initialRating, finalRating) => {
			this.props.form.validateFields((err, values) => {
				if (err) {
					return
				}

				const { name, feedbackMessage } = values

				mutate({
					variables: {
						userName,
						feedbackUserName: stringEncode(name.trim()),
						feedbackMessage: stringEncode(feedbackMessage ? feedbackMessage.trim() : ''),
						initialRating,
						finalRating,
						feedbackOs: browser ? browser.os : 'Unknown',
						feedbackBrowser: browser ? `${browser.name},${browser.version}` : 'Unknown',
					},
				})

				this.props.form.resetFields()
			})
		}

		handleSuccess = (
			displayName,
			displayImageUrl,
			finalRating,
			setFeedback,
			setFeedbackObject
		) => {
			setFeedback(true)
			setFeedbackObject({
				displayName: displayName,
				displayImageUrl: displayImageUrl,
				finalRating: finalRating,
			})
		}

		render() {
			const {
				userName,
				initialRating,
				finalRating,
				setFeedback,
				setFeedbackObject,
				displayName,
				displayImageUrl,
			} = this.props
			const { getFieldDecorator, setFieldsValue } = this.props.form
			console.log(finalRating)
			let feedbackPlaceholder
			switch (finalRating) {
				case 'bad':
					feedbackPlaceholder = 'Please let me know how I can improve my service . . .'
				case 'ok':
					feedbackPlaceholder = 'Please letdasdasd improve my service . . .'
				case 'good':
					feedbackPlaceholder = 'Please let me know how I can improve my service . . .'
				case 'wow':
					feedbackPlaceholder = 'Please dasdasdasd rvice . . .'
					break
				default:
					break
			}
			return (
				<Form layout="vertical">
					<Mutation mutation={SUBMIT_USER_FEEDBACK}>
						{(SubmitUserFeedback, { loading, error, data }) => (
							<>
								<Form.Item>
									{getFieldDecorator('name', {
										rules: [
											{
												required: true,
												pattern: /^(?!\s*$).+/,
												message: 'Please provide a name ...',
											},
										],
									})(
										<Input
											size="large"
											maxLength={40}
											style={{ fontSize: 16 }}
											placeholder="Enter your name here . . ."
										/>
									)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator('feedbackMessage')(
										<TextArea
											style={{ fontSize: 16 }}
											rows={3}
											autoSize={{ minRows: 5, maxRows: 8 }}
											placeholder={
												finalRating === 'bad'
													? 'Please let me know how I can improve my services the next time. '
													: finalRating === 'ok'
													? 'Please let me know how I can improve my services the next time. '
													: finalRating === 'good'
													? 'Which part of my service did you like the most ?'
													: 'Which part of my service did you like the most ?'
											}
											onPressEnter={() =>
												this.handleCreate(
													SubmitUserFeedback,
													userName,
													initialRating,
													finalRating
												)
											}
										/>
									)}
								</Form.Item>
								<Button
									type="primary"
									size="large"
									loading={loading}
									// disabled={buttonActiveState}
									onClick={() =>
										this.handleCreate(
											SubmitUserFeedback,
											userName,
											initialRating,
											finalRating
										)
									}
									block
									style={{
										borderRadius: 5,
										textAlign: 'center',
										height: 40,

										bottom: 0,
									}}>
									Give Feedback
								</Button>
								{loading && console.log(loading)}
								{data &&
									this.handleSuccess(
										displayName,
										displayImageUrl,
										finalRating,
										setFeedback,
										setFeedbackObject
									)}
							</>
						)}
					</Mutation>
				</Form>
			)
		}
	}
)

export default ResponseForm
