import React, { Component } from 'react'
import ChatListSearch from './ChatListSearch'
import { GET_CHAT_LIST } from './../query'
import ChatListHeader from './ChatListHeader'
import { Query } from 'react-apollo'
import { Spin, Icon } from 'antd'
import List from './List'
import styles from './styles'
import './index.css'

class ChatList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			searchString: '',
		}
	}
	render() {
		const {
			hash,
			groupId,
			conversationType,
			addGroupParticipant,
			archieveMember,
		} = this.props

		const { searchString } = this.state

		const addNewConversation = () => {
			const { addNewConversation } = this.props
			addNewConversation()
		}

		const openFirstChat = (data) => {
			const { openFirstChat } = this.props
			openFirstChat(data)
		}

		const getChats = (event, item, refetch) => {
			const { getChats } = this.props
			this.setState({ searchString: '' })
			getChats(event, item, refetch)
		}

		const getSearchString = (data) => {
			const { searchString } = data
			this.setState({ searchString })
		}

		const showArchivedMember = () => {
			const { showArchivedMember } = this.props
			showArchivedMember()
		}
		return (
			<div className="chatListParent">
				<ChatListHeader
					addNewConversation={addNewConversation}
					addGroupParticipant={addGroupParticipant}
					showArchivedMember={showArchivedMember}
				/>
				<ChatListSearch searchString={searchString} getSearchString={getSearchString} />
				<Query
					query={GET_CHAT_LIST}
					variables={{
						hash: hash,
						limit: 12,
						offset: 0,
						searchString: searchString,
					}}>
					{({ data, loading, fetchMore, subscribeToMore, refetch, client }) => {
						if (loading) {
							return <Spin indicator={<Icon type="loading" spin />} />
						}
						if (!data || !data.newGetChatList.length) {
							return <h3 style={styles.chatSearchNotFound}>No chat available.</h3>
						}

						return (
							<List
								contacts={data.newGetChatList}
								getChats={getChats}
								fetchMore={fetchMore}
								subscribeToMore={subscribeToMore}
								refetch={refetch}
								addNewConversation={addNewConversation}
								openFirstChat={openFirstChat}
								data={data}
								groupId={groupId}
								hash={hash}
								conversationType={conversationType}
								client={client}
								archieveMember={archieveMember}
							/>
						)
					}}
				</Query>
			</div>
		)
	}
}

export default ChatList
