import React from 'react'
import uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import Sortable from 'react-sortablejs'
import { Button, Row, Col } from 'antd'
import StageList from './StageList'
import DeliverableList from './DeliverableList'

//Load list of deliverables in proposal stage configuration
class LoadStageConfig extends React.Component {
	render() {
		const { stageDeliverableSource, stageDeliverableTarget } = this.props
		console.log(this.props)

		return stageDeliverableSource.map((sourceElement, key) => {
			const targetElement = stageDeliverableTarget[key]

			return (
				<Row key={uniqueId} style={{ paddingBottom: 50 }}>
					<Col span={6} offset={4}>
						<div style={{ display: 'flex' }}>
							<h1>{sourceElement.scopeName} Stage List</h1>
							<Button
								type="primary"
								icon="plus"
								shape="round"
								className={'addNewStage'}
								onClick={() => {
									this.props.addNewStage()
								}}>
								Stage
							</Button>
						</div>

						<Sortable
							options={{
								animation: 150,
								group: {
									pull: true,
									put: true,
								},
							}}
							className="block-list"
							onChange={(items) => {}}
							tag="ul">
							<StageList
								data={sourceElement.scopeData}
								index={key}
								addNewPercentage={this.props.addNewPercentage}
								removeNewStage={this.props.removeNewStage}
							/>
						</Sortable>
					</Col>
					<Col span={8} offset={2}>
						<h1>{targetElement.scopeName} Deliverable List</h1>
						<Sortable
							options={{
								animation: 150,
								group: {
									pull: true,
									put: true,
								},
							}}
							className="block-list"
							onChange={(items) => {
								this.props.dragDeliverableStageTarget(items, key)
							}}
							tag="ul">
							<DeliverableList data={targetElement.scopeData} />
						</Sortable>
					</Col>
				</Row>
			)
		})
	}
}

LoadStageConfig.propTypes = {
	stageDeliverableSource: PropTypes.array,
	stageDeliverableTarget: PropTypes.array,
	addNewStage: PropTypes.func,
	addNewPercentage: PropTypes.func,
	removeNewStage: PropTypes.func,
	dragDeliverableStageTarget: PropTypes.func,
}

export default LoadStageConfig
