import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button, InputNumber, Spin, Select } from 'antd'
import CKEditor from 'ckeditor4-react'
import $ from 'jquery'
import { Query } from 'react-apollo'
import { GET_MASTER_TYPOLOGY_QUERY } from '../query'
import './index.css'

function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some((field) => fieldsError[field])
}

class ServiceSpaceConfigForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			typologyId: null,
			noOfBuilding: 0,
			noOfFloor: 0,
			noOfRoom: 0,
			status: false,
			typology: [],
		}

		this.handleChange = this.handleChange.bind(this)
		this.onEditorChange = this.onEditorChange.bind(this)
	}

	componentDidMount() {
		// To disabled submit button at the beginning.
		this.props.form.validateFields()

		if (this.state.typologyId != null) {
			this.props.form.setFieldsValue({
				typologyInput: this.state.typologyId,
				noOfFloorInput: this.state.noOfFloor,
				noOfBuildingInput: this.state.noOfBuilding,
				noOfRoomInput: this.state.noOfRoom,
			})
		}
	}

	componentWillMount() {
		const { spaceDefineHtml, typologyId, noOfBuilding, noOfFloor, typology } = this.props
		if (typologyId !== null) {
			this.setState({
				data: spaceDefineHtml,
				typologyId,
				noOfFloor,
				noOfBuilding,
				status: true,
			})
		}

		this.setState({ typology })
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleSubmit = (e) => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { typologyInput, noOfBuildingInput, noOfFloorInput, noOfRoomInput } = values
				const spaceDefineHtml = this.generateSpaceDefineTemplate(
					noOfBuildingInput,
					noOfFloorInput,
					noOfRoomInput
				)

				this.setState({
					status: true,
					data: spaceDefineHtml,
					typologyId: typologyInput,
					noOfBuilding: noOfBuildingInput,
					noOfFloor: noOfFloorInput,
					noOfRoom: noOfRoomInput,
				})

				const spaceDefineJson = this.editorJsonData()

				this.props.setServiceSpaceDetails({
					typologyInput,
					noOfBuildingInput,
					noOfFloorInput,
					noOfRoomInput,
					spaceDefineHtml,
					spaceDefineJson,
				})
			}
		})
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	generateSpaceDefineTemplate = (buildingCount, floorCount, roomCount) => {
		let temp = '<ul>'

		for (let x = 0; x < buildingCount; x++) {
			temp += `<li>Building ${x + 1}`
			temp += '<ul>'
			for (let y = 0; y < floorCount; y++) {
				temp += `<li>Floor ${y + 1}</li>`
				temp += '<ul>'
				for (let z = 0; z < roomCount; z++) {
					temp += `<li>Room ${z + 1}</li>`
				}
				temp += '</ul>'
			}
			temp += '</ul>'

			temp += '</li>'
		}
		temp += '</ul>'

		return temp
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	onEditorChange = (evt) => {
		const editorHtmlData = evt.editor.getData()
		this.setState({ data: editorHtmlData })
		const result = {
			spaceDefineHtml: editorHtmlData,
			spaceDefineJson: this.editorJsonData(),
		}

		this.props.setServiceEditorSpace(result)
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	editorJsonData = () => {
		function FetchChild() {
			const data = []
			$('.editorPreview > div > ul > li').each(function() {
				data.push(buildJSON($(this)))
			})

			return data
		}
		function buildJSON($li) {
			const subObj = {
				name: $li
					.contents()
					.eq(0)
					.text()
					.trim(),
			}
			$li
				.children('ul')
				.children()
				.each(function() {
					if (!subObj.children) {
						subObj.children = []
					}
					subObj.children.push(buildJSON($(this)))
				})
			return subObj
		}
		const obj = FetchChild()

		return obj
	}

	/**
	 * @type - function
	 * @param -
	 * @summary -
	 * @return -
	 */
	handleChange(changeEvent) {
		this.setState({
			data: changeEvent.target.value,
		})
	}

	render() {
		const {
			getFieldDecorator,
			getFieldsError,
			getFieldError,
			isFieldTouched,
		} = this.props.form

		const { data, status, typology } = this.state

		// Only show error after a field is touched.
		const typologyInput =
			isFieldTouched('typologyInput') && getFieldError('typologyInput')
		const noOfBuildingInput =
			isFieldTouched('noOfBuildingInput') && getFieldError('noOfBuildingInput')
		const noOfFloorInput =
			isFieldTouched('noOfFloorInput') && getFieldError('noOfFloorInput')
		const noOfRoomInput =
			isFieldTouched('noOfRoomInput') && getFieldError('noOfRoomInput')

		return (
			<div className="serviceSpaceConfig">
				{!typology.length ? (
					<Query query={GET_MASTER_TYPOLOGY_QUERY}>
						{({ data: result, loading, error }) => {
							if (loading) {
								return (
									<Spin
										indicator={<Icon type="loading" className="iconSize" spin />}
										className="loaderIcon"
									/>
								)
							}
							if (!result.getMasterTypology) {
								return null
							}
							const { getMasterTypology } = result

							if (!typology.length) {
								this.setState({
									typology: getMasterTypology,
								})
								this.props.setMasterTypology(getMasterTypology)
							}

							return <div />
						}}
					</Query>
				) : null}

				<Form layout="inline" onSubmit={this.handleSubmit} className="formAlign">
					<Form.Item
						validateStatus={typologyInput ? 'error' : ''}
						help={typologyInput || ''}>
						{getFieldDecorator('typologyInput', {
							rules: [
								{
									required: true,
									message: 'Please enter space config name!',
								},
							],
						})(
							<Select
								showSearch
								style={{ width: 200 }}
								placeholder="Select a Typology"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{typology.map((element) => (
									<Select.Option key={element.typologyId} value={element.typologyId}>
										{element.typologyName}
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>
					<Form.Item
						validateStatus={noOfBuildingInput ? 'error' : ''}
						help={noOfBuildingInput || ''}>
						{getFieldDecorator('noOfBuildingInput', {
							rules: [
								{
									required: true,
									message: 'Please enter no of buildings!',
								},
							],
						})(
							<InputNumber
								min={1}
								max={100}
								placeholder="Enter No of Buildings"
								style={{ width: '100%' }}
							/>
						)}
					</Form.Item>
					<Form.Item
						validateStatus={noOfFloorInput ? 'error' : ''}
						help={noOfFloorInput || ''}>
						{getFieldDecorator('noOfFloorInput', {
							rules: [{ required: true, message: 'Please enter no of floors!' }],
						})(
							<InputNumber
								min={1}
								max={100}
								placeholder="Enter No of Floors"
								style={{ width: '100%' }}
							/>
						)}
					</Form.Item>
					<Form.Item
						validateStatus={noOfRoomInput ? 'error' : ''}
						help={noOfRoomInput || ''}>
						{getFieldDecorator('noOfRoomInput', {
							rules: [{ required: true, message: 'Please enter no of rooms!' }],
						})(
							<InputNumber
								min={1}
								max={100}
								placeholder="Enter No of Rooms"
								style={{ width: '100%' }}
							/>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={hasErrors(getFieldsError())}>
							Generate
						</Button>
					</Form.Item>
				</Form>

				<div className="editorData">
					{status ? (
						<>
							<EditorPreview data={data} />
							<CKEditorPreview data={data} onChange={this.onEditorChange} />
						</>
					) : null}
				</div>
			</div>
		)
	}
}

class CKEditorPreview extends Component {
	render() {
		const { data, onChange } = this.props
		return (
			<div className="editorDisplay">
				<CKEditor data={data} onChange={onChange} />
			</div>
		)
	}
}

class EditorPreview extends Component {
	render() {
		const { data } = this.props
		return (
			<div className="editorPreview">
				<h2>Space Config Design</h2>
				<div dangerouslySetInnerHTML={{ __html: data }} />
			</div>
		)
	}
}

const ServiceSpaceConfig = Form.create({ name: 'space_config_form' })(
	ServiceSpaceConfigForm
)

ServiceSpaceConfigForm.propTypes = {
	form: PropTypes.object,
	setServiceEditorSpace: PropTypes.func,
	setServiceSpaceDetails: PropTypes.func,
	spaceDefineHtml: PropTypes.string,
	typologyId: PropTypes.string,
	noOfBuilding: PropTypes.number,
	noOfFloor: PropTypes.number,
	noOfRoomInput: PropTypes.number,
	setMasterTypology: PropTypes.func,
	typology: PropTypes.array,
}

CKEditorPreview.propTypes = {
	data: PropTypes.string,
	onChange: PropTypes.func,
}

EditorPreview.propTypes = {
	data: PropTypes.string,
}

export default ServiceSpaceConfig
