import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Icon, Input, Slider, Upload, notification } from 'antd'
import { format } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import {
	GET_UPCOMING_TASK_QUERY,
	GET_PREVIOUS_TASK_QUERY,
	GET_SCREENSHOT_QUERY,
	UPDATE_TASK_STATUS_MUTATION,
} from './query'

export const ConfirmRoot = styled.div`
	.error {
		color: red;
		font-size: smaller;
	}

	.row1 {
		display: flex;
		flex: 1;
		justify-content: space-between;
		padding: 0 20px;

		.completion-level,
		.attachment {
			flex: 0.4;

			.header {
				font-size: 170%;
				font-weight: bold;
				color: #505050;
				margin-bottom: 10px;
			}
		}

		.attachment {
			.ant-upload-select-text {
				margin-top: 10px;
			}
		}
		.completion-level {
			.slider-wrapper {
				margin-top: 20px;
				width: 72vw;

				.slider-indicator {
					width: 100%;
					display: flex;
					justify-content: space-between;
					position: relative;
					top: -5px;
				}
			}
		}
	}

	.notes,
	.screenshots {
		flex: 0.5;

		.header {
			font-size: 170%;
			font-weight: bold;
			color: #505050;
			margin-bottom: 10px;

			span {
				font-size: 70%;
				font-weight: normal;
				font-style: italic;
			}
		}
	}

	.notes {
		margin-top: 15px;
		padding: 0 20px;
		.task-notes {
			height: 40px;
			font-size: 16px;
		}
	}

	.screenshots {
		margin-top: 25px;
		.header {
			padding: 0 20px;
		}

		.loadmore {
			width: 100% !important;
			max-width: 100% !important;
			text-align: center;

			.loadmore-btn {
				border: 1px solid #1890ff;
				background: #ffffff;
				color: #1890ff;
				outline: none;
				padding: 2px 10px;
				border-radius: 8px;
				transition: all 0.5ms ease;

				&:hover {
					background: #1890ff20;
				}
			}
		}

		.preview-image {
			padding: 15px 20px;
			max-width: 100%;
			height: 100%;
			width: 100%;
		}

		.image-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			max-height: 30vw;
			overflow: auto;

			& > * {
				margin: 35px 15px 30px 15px;
				max-width: 10vw;
				max-height: 10vw;
				height: 100%;
				width: 100%;
				position: relative;
				cursor: pointer;

				img {
					max-width: 10vw;
					max-height: 10vw;
					height: 100%;
					width: 100%;
				}

				.time-overlay {
					position: absolute;
					top: -25px;
					left: 0;
					right: 0;
					height: 10px;
					text-align: center;
					color: #505050;
					font-weight: bold;
				}

				.select-overlay {
					position: absolute;
					bottom: -10px;
					left: 0;
					right: 0;
					height: 10px;
					text-align: center;

					& > i {
						font-size: 25px;
						color: #6f9e6c;
					}
				}
			}
		}
	}

	.update-wrapper {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		padding: 0 20px;
		margin-top: 60px;

		button {
			background: #1890ff;
			outline: none;
			cursor: pointer;
			border: none;
			font-size: 150%;
			font-weight: 500;
			color: #fff;
			height: 60px;
			border-radius: 10px;
			padding: 0 20px;
			transition: all 0.5ms ease;

			&:hover {
				background: #0685fb;
			}
		}
	}
`

const openNotification = (message) => {
	notification.error({
		message: 'Error',
		description: message,
		placement: 'bottomRight',
		className: 'error-notification',
		style: {
			background: '#ba4949',
			color: '#fff',
		},
		icon: null,
	})
}

export default function CompleteTask({ task, close }) {
	const initialState = {
		fileList: [],
		fileInvalid: false,
		screenshots: [],
		percentage: 0,
		isPercentageInvalid: false,
		notes: '',
		screenshotsInvalid: false,
	}

	if (task.type === 'edit') {
		initialState.percentage = task.percentage
		initialState.notes = task.taskNotes
		if (task.attachment) {
			initialState.fileList = [
				{
					uid: '1',
					name: 'File 1',
					status: 'done',
					url: task.attachment,
				},
			]
		}
	}

	const [isFileListUpdated, setFileListUpdated] = React.useState(false)
	const [initFileDeleted, setInitFileDeleted] = React.useState(false)
	const [fileList, setFileList] = React.useState(initialState.fileList)
	const [fileInvalid, setFileInvalid] = React.useState(initialState.fileInvalid)
	const [screenshotsInvalid, setScreenshotsInvalid] = React.useState(
		initialState.screenshotsInvalid
	)

	const [screenshots, setScreenshots] = React.useState(initialState.screenshots)
	const [screenshotsUpdated, setScreenshotsUpdated] = React.useState(false)

	const [percentage, setPercentage] = React.useState(initialState.percentage)
	const [isPercentageInvalid, setPercentageInvalid] = React.useState(
		initialState.isPercentageInvalid
	)

	const [notes, setNotes] = React.useState(initialState.notes)
	const [previewImage, setPreviewImage] = React.useState('')
	const [isAllLoaded, setAllLoaded] = React.useState(false)

	const { data, loading, error, fetchMore, networkStatus } = useQuery(
		GET_SCREENSHOT_QUERY,
		{
			variables: {
				offset: 0,
				limit: 18,
				type: task.type,
				taskId: task.taskId,
			},
			notifyOnNetworkStatusChange: true,
		}
	)

	const [updateTaskStatus, { loading: updateLoading }] = useMutation(
		UPDATE_TASK_STATUS_MUTATION
	)

	const handleUpload = React.useCallback(async () => {
		if (updateLoading || percentage < 1) {
			if (percentage < 1) {
				setPercentageInvalid(true)
				openNotification('Please add the completion percentage')
			}
			// if (screenshots.length < 1) {
			// 	setScreenshotsInvalid(true)
			// 	openNotification('Please select some screenshots (Min. of 1 is required)')
			// }
			return
		}

		let startTime = 0
		let endTime = 0
		if (screenshots.length > 0) {
			startTime = screenshots.reduce((acc, curr) => {
				return acc < curr.time ? acc : curr.time
			}, screenshots[0].time)
			endTime = screenshots.reduce((acc, curr) => {
				return acc > curr.time ? acc : curr.time
			}, screenshots[0].time)
		}

		const uploadScreenshots = screenshots.map((i) => ({ imageId: i.imageId }))

		const isUpdated = await updateTaskStatus({
			variables: {
				taskId: task.taskId,
				taskStatus: 'completed',
				percentage,
				notes: notes.trim(),
				startTime,
				endTime,
				screenshot: uploadScreenshots,
				files:
					task.type === 'update' || (task.type === 'edit' && isFileListUpdated)
						? fileList
						: [],
				filesDeleted: task.type === 'edit' && initFileDeleted && !isFileListUpdated,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{ query: GET_UPCOMING_TASK_QUERY },
				{ query: GET_PREVIOUS_TASK_QUERY },
				{
					query: GET_SCREENSHOT_QUERY,
					variables: {
						offset: 0,
						limit: 18,
						type: task.type,
						taskId: task.taskId,
					},
				},
			],
		})
		if (isUpdated && isUpdated.data && isUpdated.data.updateTaskStatus) {
			close()
		} else {
			// eslint-disable-next-line no-alert
			alert('error updating task')
		}
	}, [
		percentage,
		notes,
		screenshots,
		updateTaskStatus,
		fileList,
		isFileListUpdated,
		initFileDeleted,
	])

	if (error) {
		return null
	}

	if (
		data &&
		data.getScreenshot &&
		data.getScreenshot.length > 0 &&
		!screenshotsUpdated
	) {
		const alreadySelected = data.getScreenshot.filter((f) => f.isSelected)
		if (alreadySelected.length > 0 && screenshots.length < 1) {
			setScreenshots(
				alreadySelected.map((s) => {
					const { time, imageId } = s
					return {
						time,
						imageId,
					}
				})
			)
		}
	}

	const props = {
		multiple: false,
		onRemove: (file) => {
			setFileList((state) => {
				const index = state.indexOf(file)
				const newFileList = state.slice()
				newFileList.splice(index, 1)
				setInitFileDeleted(
					initialState.fileList.findIndex((f) => f.url === file.url) >= 0
				)
				return newFileList
			})
		},
		beforeUpload: (file) => {
			const fileSizeInMB = Math.round(file.size / 1024 / 1024)
			if (fileSizeInMB > 50) {
				setFileInvalid(true)
				return false
			}
			if (task.type === 'edit') {
				setFileListUpdated(true)
			}
			setFileList([file])
			setFileInvalid(false)
			return false
		},
		fileList,
	}

	return (
		<ConfirmRoot>
			<div className="row1">
				<div className="completion-level">
					<div className="header">Task Completion Percentage</div>
					<div className="slider-wrapper">
						<Slider
							defaultValue={percentage}
							step={10}
							onAfterChange={(value) => {
								if (value > 0) {
									if (isPercentageInvalid) {
										setPercentageInvalid(false)
									}
								} else if (!isPercentageInvalid) {
									setPercentageInvalid(true)
								}
								setPercentage(value)
							}}
						/>
						<div className="slider-indicator">
							<div>0%</div>
							<div>100%</div>
						</div>
						{isPercentageInvalid ? (
							<span className="error">Completion percentage is required</span>
						) : null}
					</div>
				</div>
				{/* <div className="attachment">
					<div className="header">Attachments</div> */}
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				{/* <Upload {...props}>
						<Button>
							<Icon type="upload" /> Upload
						</Button>
					</Upload> */}
				{/* {fileInvalid ? (
						<span className="error">Max file size should be 50MB</span>
					) : null} */}
				{/* </div> */}
			</div>
			<div className="notes">
				<div className="header">
					Notes <span>(optional)</span>
				</div>
				<Input
					placeholder="Notes"
					className="task-notes"
					value={notes}
					onChange={(e) => setNotes(e.target.value)}
				/>
			</div>
			<div className="screenshots">
				{/* <div className="header">
					{`Map your work with the screenshots `}
					<span className={screenshotsInvalid ? 'error' : ''}>
						(Select minimum 1 screenshot)
					</span>
				</div> */}
				{loading ? <div style={{ textAlign: 'center' }}>Loading...</div> : null}
				{!loading && error ? <div style={{ textAlign: 'center' }}>Error...</div> : null}
				{/* eslint-disable-next-line no-nested-ternary */}
				{!loading &&
				!error &&
				data &&
				data.getScreenshot &&
				data.getScreenshot.length > 0 ? (
					<>
						<img
							className="preview-image"
							src={previewImage || data.getScreenshot[0].imageUrl}
							alt="Preview of the screenshot you hovered"
						/>
						<div className="image-wrapper">
							{data.getScreenshot.map((image) => {
								const isSelected = screenshots.find((i) => i.imageId === image.imageId)
								return (
									<div
										key={image.imageId}
										onClick={() => {
											setScreenshots((prev) => {
												let newState = prev
												if (isSelected) {
													newState = prev.filter((i) => i.imageId !== image.imageId)
												} else {
													newState = [
														...prev,
														{ time: image.time, imageId: image.imageId },
													]
												}

												if (newState.length < 1 && !screenshotsInvalid) {
													setScreenshotsInvalid(true)
												}
												if (newState.length >= 1 && screenshotsInvalid) {
													setScreenshotsInvalid(false)
												}

												if (!screenshotsUpdated) {
													setScreenshotsUpdated(true)
												}

												return newState
											})
										}}>
										<img
											src={image.imageUrl}
											alt={image.imageId}
											onMouseOver={() => setPreviewImage(image.imageUrl)}
											onFocus={() => setPreviewImage(image.imageUrl)}
										/>
										<div className="time-overlay">
											{format(image.time * 1000, 'h:mm a')}
										</div>
										{isSelected ? (
											<div className="select-overlay">
												<Icon type="check" />
											</div>
										) : null}
									</div>
								)
							})}
							<div className="loadmore">
								{!isAllLoaded && data.getScreenshot.length >= 18 ? (
									<button
										type="button"
										className="loadmore-btn"
										onClick={() => {
											if (loading && networkStatus === 4) {
												return
											}
											fetchMore({
												variables: {
													offset: data.getScreenshot.length,
												},
												updateQuery: (prev, { fetchMoreResult }) => {
													if (!fetchMoreResult) return prev
													if (fetchMoreResult.getScreenshot.length < 18) {
														setAllLoaded(true)
													}
													if (fetchMoreResult.getScreenshot.length < 1) {
														return prev
													}
													return {
														...prev,
														getScreenshot: [
															...prev.getScreenshot,
															...fetchMoreResult.getScreenshot,
														],
													}
												},
											})
										}}>
										{`${loading && networkStatus === 4 ? 'Loading...' : 'Load more'}`}
									</button>
								) : (
									<div>No more screenshots</div>
								)}
							</div>
						</div>
					</>
				) : null}
				{/* {!loading &&
				!error &&
				data &&
				data.getScreenshot &&
				data.getScreenshot.length < 1 ? (
					<div style={{ textAlign: 'center' }}>No screenshots yet!</div>
				) : null} */}
			</div>
			<div className="update-wrapper">
				<button type="button" onClick={handleUpload}>
					Update Task
				</button>
			</div>
		</ConfirmRoot>
	)
}
