import gql from 'graphql-tag'

export const MOVE_FILE_TO_NOTED = gql`
	mutation moveFileToNoted(
		$fileId: String
		$notedFolderId: String
		$forRevisionFolderId: String
		$comments: String
	) {
		moveFileToNoted(
			fileId: $fileId
			notedFolderId: $notedFolderId
			forRevisionFolderId: $forRevisionFolderId
			comments: $comments
		)
	}
`
