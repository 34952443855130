/**
 * Developer : Sandhiya Malar
 */
/**
    Sketch Up Component
    This component will render upload file Read. 
    And Display in table formate & editable columns.
    And download as excel ad pdf format.
* */

/*  Import Components  */
import React from 'react'
/* Import required components from ANT DESIGN */
import { Button } from 'antd'

/* Import react-file-reader component */
import ReactFileReader from 'react-file-reader'
/*  Import xlsx Components  */
import * as XLSX from 'xlsx'
/* Import react-papaparse component */
import { readString } from 'react-papaparse'

/* Import pdfconvertor component */
import PdfConvertor from './PdfConvertor'

/*  Import Override CSS  */
import './index.css'

/*  Import Custom Styles  */
import styles from './styles'

/**
 * This class component is used to uploaded file content
 * @returns {project archived component}
 */
class SketchUp extends React.Component {
	/* initial states */
	state = {
		sketchUpArray: null,
		colorDropdown: [],
	}

	/**
	 *  Read a uploaded file content and set in state
	 */
	handleFiles = (files) => {
		const reader = new FileReader()
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result
			const wb = XLSX.read(bstr, { type: 'array' })
			/* Get first worksheet */
			const wsname = wb.SheetNames[0]
			const ws = wb.Sheets[wsname]
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })

			const result = readString(data, {
				header: true,
			})
			// console.log(result.data)
			// const result = readString(reader.result, {
			// 	header: true,
			// })

			/* compare uploaded file maretrial colums return unique of all (array of string) */
			const colorDropdowns = result.data.reduce((unique, items) => {
				if (items.Path) {
					return unique.includes(items.Material) || items.Material === ''
						? unique
						: [...unique, items.Material]
				}
				return unique
			}, [])
			// console.log(colorDropdowns)

			/* it form a group of array of object based on 
        (upload file path colums split on '/' second one is as hash value ) 
      */
			const hashGroup = result.data.reduce((acc, initialValue) => {
				if (initialValue.Path) {
					// const [cabinetName, hash] = initialValue.Path.split('/')
					const hash = initialValue.Path.split('/')
					if (acc[hash[1]]) {
						acc[hash[1]] = [...acc[hash[1]], initialValue]
						return acc
					}
					acc[hash[1]] = [initialValue]
				}
				return acc
			}, {})

			/* it split wantes and unwanted array based on 
        (upload file instance colums start with '#' ) 
      */
			const splitArray = Object.entries(hashGroup).map(([key, value]) => {
				const wanted = value.filter((i) => !i.Instance.startsWith('#'))
				const unwanted = value.filter((i) => i.Instance.startsWith('#'))
				return { key, wanted, unwanted }
			})

			const arrayGroup = []
			let values = 0

			/* form a array of object for export file data */
			for (let index = 0; index < splitArray.length; index++) {
				const element = splitArray[index]
				const { unwanted, wanted } = element

				for (let x = 0; x < wanted.length; x++) {
					const i = wanted[x]
					const [cabinetName] = i.Path.split('/')
					values += 1
					arrayGroup.push({
						sno: values,
						CABINET_NAME: cabinetName,
						PANEL_TYPE: i.Instance,
						FACTORY_LABEL: '',
						PANEL_NAME: i.Definition,
						THICKNESS: i['Thickness - final'],
						LENGTH_1: i['Length - final'],
						LENGTH_2: i['Width - final'],
						// EXT_COLOR: unwanted[0].Material,
						// INT_COLOR: unwanted[1].Material,
						EXT_COLOR: unwanted.length === 0 ? '' : unwanted[0].Material,
						INT_COLOR: unwanted.length === 0 ? '' : unwanted[1].Material,
						EDGE_BAND: '',
						REMARKS_1: '',
						REMARKS_2: '',
						GRAINS_DIRECTION: '',
					})
				}
			}
			this.setState({
				sketchUpArray: arrayGroup,
				colorDropdown: colorDropdowns,
			})
		}
		//reader.readAsText(files[0])
		reader.readAsArrayBuffer(files[0])
	}

	/**
	 * It should be excecute first
	 * This is used for upload file .
	 * @returns {SketchUp}
	 */

	render() {
		/* Deconstructing the state */
		const { sketchUpArray, colorDropdown } = this.state
		// console.log(sketchUpArray)
		return (
			<div style={styles.fileConvertor}>
				<div style={styles.inputDiv}>
					<ReactFileReader
						multipleFiles={false}
						fileTypes={['.ods', '.xlsx', '.csv', '.xls']}
						handleFiles={(e) => this.handleFiles(e)}>
						<Button type="primary" style={styles.sendButton}>
							Upload File Here (ods, xlsx, csv, xls)
						</Button>
					</ReactFileReader>
				</div>
				{sketchUpArray !== null ? (
					<PdfConvertor sketchUpArray={sketchUpArray} colorDropdown={colorDropdown} />
				) : null}
			</div>
		)
	}
}

export default SketchUp
