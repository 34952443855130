import React from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { Avatar, Icon, Input } from 'antd';

// Load list of stages in proposal stage configuration
/**
 * @type - function
 * @param -
 * @summary -
 * @return -
 */

class StageList extends React.Component {
  render() {
    const { data } = this.props;

    return [
      data.length ? (
        data.map((val, key) => {
          const result = JSON.stringify(val);
          return (
            <li
              key={ uniqueId() }
              data-id={ result }
              className={ 'stageDetails' }
              style={ { color: val.scopeSelected ? 'white' : '#2c7ff9' } }
            >
              <div
                className={ 'stageName' }
                style={
                  val.scopeSelected
                    ? {
                        backgroundColor: '#2d7ff9',
                        color: '#ffffff'
                      }
                    : {
                        color: '#2c7ff9',
                        border: '2px solid #2c7ff9'
                      }
                }
              >
                <span>{val.scopeName}</span>
              </div>

              <div className={ 'stagePercentage' }>
                <Input
                  placeholder='%'
                  style={ { border: 'none' } }
                  value={ val.scopePercentage }
                  onChange={ this.props.addNewPercentage(val, key) }
                />
              </div>
              <div
                className={ 'removeStage' }
                style={ {
                  cursor: val.scopeSelected ? 'not-allowed' : 'pointer'
                } }
              >
                <span
                  style={
                    val.scopeSelected
                      ? { pointerEvents: 'none' }
                      : { pointerEvents: 'default' }
                  }
                  onClick={ () => {
                    this.props.removeNewStage(val, key);
                  } }
                >
                  <Icon type='delete' />
                </span>
              </div>
            </li>
          );
        })
      ) : (
        <li key={ uniqueId() } style={ { backgroundColor: 'white' } }>
          <div>
            <Avatar size='large' className={ 'emptyDeliverableList' }>
              <Icon
                type='exclamation-circle'
                className={ 'emptyDeliverableListIcon' }
              />
            </Avatar>
            <span className={ 'stageNameMarginLeft20' }>No Stage Found</span>
          </div>
        </li>
      )
    ];
  }
}

StageList.propTypes = {
  data: PropTypes.array,
  addNewPercentage: PropTypes.func,
  removeNewStage: PropTypes.func
};

export default StageList;
