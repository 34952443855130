import React, { Component } from 'react'
import styles from './styles'

class ViewDesignQube extends Component {
	render() {
		return (
			<div className="chatDashboard" style={styles.chatDashboard}>
				<div style={styles.chatBoardDiv}>
					<img
						src={require('./../../../assets/dq_full_logo.png')}
						alt={'whatsapp key point'}
						style={styles.chatBoardImage}
					/>
					<h2>Let's start a conversation.</h2>
				</div>
			</div>
		)
	}
}

export default ViewDesignQube
