import gql from 'graphql-tag'

export const CrmChatData = gql`
	query crmChatDetails($enquiryId: String) {
		crmChatDetails(enquiryId: $enquiryId) {
			date
			chat {
				type
				date
				time
				timestamp
				enquiryId
				stageFrom
				stageTo
				assignedBy
				assignedByName
				addedByPicture
				navigationType
				stage
				addedBy
				notes
				addedByName
				isSelfNote
				context
			}
		}
	}
`
