import React, { Component, Fragment } from 'react'
import ModalDialog from '../ModalDialog'
import MessageList from './MessageList'

class ChatBody extends Component {
	render() {
		const {
			chatListRefetch,
			data,
			groupId,
			subscribeToMore,
			fetchMore,
			chatType,
			showModalDialog,
			hash,
			toHash,
			refetch,
			type,
			imageDataUrl,
			messageData,
			fileType,
			fileName,
			messageText,
			loader,
			groupMember,
			selectedContact,
			messageCaption,
			messageId,
			handleModalCancel,
			handleModalOk,
			handleModalBack,
			handleModalNext,
			showModalDialogPopup,
			getChats,
			mediaImage,
		} = this.props

		return (
			<>
				<MessageList
					chatListRefetch={chatListRefetch}
					data={data}
					groupId={groupId}
					subscribeToMore={subscribeToMore}
					fetchMore={fetchMore}
					chatType={chatType}
					showModalDialogPopup={showModalDialogPopup}
					hash={hash}
					toHash={toHash}
					refetch={refetch}
					mediaImage={mediaImage}
				/>
				<Fragment>
					{showModalDialog ? (
						<ModalDialog
							type={type}
							handleModalCancel={handleModalCancel}
							handleModalOk={handleModalOk}
							handleModalBack={handleModalBack}
							handleModalNext={handleModalNext}
							imageDataUrl={imageDataUrl}
							messageData={messageData}
							fileType={fileType}
							fileName={fileName}
							messageText={messageText}
							messageCaption={messageCaption}
							messageId={messageId}
							groupId={groupId}
							refetch={refetch}
							toHash={toHash}
							loader={loader}
							hash={hash}
							groupMember={groupMember}
							selectedContact={selectedContact}
							showModalDialogPopup={showModalDialogPopup}
							getChats={getChats}
							chatListRefetch={chatListRefetch}
						/>
					) : null}
				</Fragment>
			</>
		)
	}
}

export default ChatBody
