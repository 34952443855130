import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import { generateUserNameColor } from '../utils'
import {
	GET_CHAT_LIST,
	GET_CHAT_DETAILS,
	POST_MESSAGE,
	LIST_GROUP_MEMBER,
} from '../query'
import pdfImage from '../images/pdf-image.jpeg'
import { notification, Avatar } from 'antd'
import { MdClose, MdInsertEmoticon, MdSend } from 'react-icons/md'
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react'
import styles from './styles'
import './index.css'

const SmilieBoard = (props) => (
	<MdInsertEmoticon
		style={styles.emojiAndCloseIcon}
		size={30}
		color="#989B9C"
		{...props}
	/>
)

const CloseSmilieBoard = (props) => (
	<MdClose style={styles.emojiAndCloseIcon} size={30} color="#989B9C" {...props} />
)

class ChatMessageInput extends Component {
	render() {
		const { inputValue, handleInputChange, handleInputKeyDown, inputRef } = this.props

		return (
			<div style={styles.centerAlign}>
				<input
					ref={inputRef}
					style={styles.chatMessageInput}
					type="text"
					placeholder="Type a message"
					value={inputValue}
					onChange={handleInputChange}
					onKeyPress={handleInputKeyDown}
					onKeyDown={handleInputKeyDown}
				/>
			</div>
		)
	}
}

const ChatAudioOrSend = ({ inputStatus, inputValue, handleChatSend }) => {
	return (
		<>
			{
				<MdSend
					style={{
						...styles.sendMessage,
						pointerEvents: inputStatus ? 'none' : 'auto',
					}}
					size={30}
					color="#989B9C"
					onClick={handleChatSend}
				/>
			}
		</>
	)
}

class ChatFooter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			inputValue: this.props.footerInput,
			groupId: this.props.groupId,
			mentionStatus: false,
			groupMembers: [],
			chatType: this.props.chatType,
			emojiStatus: false,
			chosenEmoji: null,
			setChosenEmoji: null,
			inputStatus: false,
		}
	}

	async componentDidMount() {
		const { client, hash } = this.props
		const { groupId } = this.state

		const { data } = await client.query({
			query: LIST_GROUP_MEMBER,
			variables: {
				hash,
				groupId,
			},
		})

		this.setState({
			groupMembers: data.listGroupMembers,
		})
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.groupId !== this.props.groupId) {
			const { client, hash, groupId, chatType } = this.props

			const { data } = await client.query({
				query: LIST_GROUP_MEMBER,
				variables: {
					hash,
					groupId,
				},
			})

			this.setState({
				groupMembers: data.listGroupMembers,
				inputValue: '',
				chatType,
				emojiStatus: false,
			})
		}

		if (prevProps.copyPasteStatus !== this.props.copyPasteStatus) {
			if (this.props.copyPasteStatus) {
				this.setState({ inputValue: '' })
			}
		}
	}

	handleInputChange = ({ target }) => {
		this.setState({ inputValue: target.value })
	}

	postMessage = async (createMessage, variables, groupId, hash) => {
		this.setState({ inputStatus: true })
		if (groupId) {
			this.setState({ inputValue: '' })
			await createMessage({
				variables,
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_CHAT_DETAILS,
						variables: {
							hash: hash,
							limit: 12,
							offset: 0,
							groupId: groupId,
						},
					},
					{
						query: GET_CHAT_LIST,
						variables: {
							hash: hash,
							limit: 12,
							offset: 0,
							searchString: '',
						},
					},
				],
			})
			notification.success({
				message: `Message sent.`,
				placement: 'bottomLeft',
			})
		} else {
			this.setState({ inputValue: '' })
			const { data } = await createMessage({
				variables,
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_CHAT_LIST,
						variables: {
							hash: hash,
							limit: 12,
							offset: 0,
							searchString: '',
						},
					},
				],
			})
			const { setGroupId } = this.props
			setGroupId({ item: data.createMessage.chat })

			notification.success({
				message: `Message sent.`,
				placement: 'bottomLeft',
			})
		}
		const { closeSelectedReply } = this.props
		closeSelectedReply()
		this.setState({ inputValue: '', inputStatus: false })
	}

	render() {
		const { groupId, hash, toHash, messageData, reply } = this.props
		const {
			inputValue,
			mentionStatus,
			groupMembers,
			chatType,
			emojiStatus,
			chosenEmoji,
			EmojiData,
			inputStatus,
		} = this.state

		const variables = {
			from: hash,
			to: toHash || '',
			groupId: groupId,
			message: inputValue.trim(),
			mentionedTo: [],
			messageType: 'text',
			replied: reply ? messageData.id : '',
			feed: [],
			pdfCaption: '',
		}

		const onEmojiClick = (event, emojiObject) => {
			const { inputValue } = this.state
			this.setState({ inputValue: inputValue + emojiObject.emoji })
		}

		return (
			<Mutation mutation={POST_MESSAGE}>
				{(createMessage) => {
					return (
						<div className="chatFooter">
							{emojiStatus ? (
								<div>
									<Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_NEUTRAL} />
									{chosenEmoji && <EmojiData chosenEmoji={chosenEmoji} />}
								</div>
							) : null}
							{mentionStatus ? (
								<div style={styles.mentionStatusDiv}>
									<ul style={styles.unorderedListMentionStatus} className="mentionList">
										{groupMembers.map((member) => {
											const { name, photo, hash } = member
											return (
												<li
													style={{ padding: 5 }}
													onClick={() => {
														const { inputValue } = this.state
														const mentionName = name.replace(/ /g, '_')
														this.setState({
															inputValue: inputValue + mentionName,
															mentionStatus: false,
														})
													}}>
													{photo !== null ? (
														<Avatar src={photo} style={{ marginRight: 5 }} />
													) : (
														<Avatar style={{ marginRight: 5 }}>{name[0]}</Avatar>
													)}

													<span>{name}</span>
												</li>
											)
										})}
									</ul>
								</div>
							) : null}
							{reply ? (
								<div style={styles.replyMessageDiv}>
									<div
										style={{
											...styles.replyMessage,
											borderLeft: `5px solid ${generateUserNameColor(
												messageData.createdBy.hash
											)}`,
										}}>
										<h4
											style={{
												color: generateUserNameColor(messageData.createdBy.hash),
											}}>
											{messageData.createdBy.name}
										</h4>
										{messageData.messageType === 'text' ? (
											<span>{messageData.content}</span>
										) : messageData.messageType === 'image' ? (
											<img
												src={messageData.content.split(',')[0]}
												height={30}
												width={30}
											/>
										) : (
											<div style={styles.replyMessageTypeImage}>
												<img src={pdfImage} height="30" width="35" />
												<span style={{ margin: 5 }}>{messageData.caption}</span>
											</div>
										)}
									</div>
									<div style={styles.closeReplyMessage}>
										<MdClose
											size={20}
											onClick={() => {
												const { closeSelectedReply } = this.props
												closeSelectedReply()
											}}
										/>
									</div>
								</div>
							) : null}

							<div style={styles.chatFooter}>
								<div>
									{!emojiStatus ? (
										<SmilieBoard
											onClick={() => {
												this.setState({ emojiStatus: true })
											}}
										/>
									) : (
										<CloseSmilieBoard
											onClick={() => {
												this.setState({ emojiStatus: false })
											}}
										/>
									)}
								</div>
								<div>
									<ChatMessageInput
										inputRef={(el) => (this.inputElement = el)}
										inputValue={this.state.inputValue}
										handleInputChange={this.handleInputChange}
										handleInputKeyDown={(event) => {
											if (event.charCode === 64) {
												if (chatType !== 'individual') {
													this.props.closeSelectedReply()
													this.setState({ mentionStatus: true })
												}
											} else {
												this.setState({ mentionStatus: false, reply: false })
											}
											if (event.keyCode === 13 && inputValue.trim() !== '') {
												this.postMessage(createMessage, variables, groupId, hash)
												this.setState({ mentionStatus: false, emojiStatus: false })
											}
										}}
									/>
								</div>
								<div>
									<ChatAudioOrSend
										inputStatus={inputStatus}
										inputValue={this.state.inputValue}
										handleChatSend={() => {
											if (inputValue.trim() !== '') {
												this.postMessage(createMessage, variables, groupId, hash)
												this.setState({ mentionStatus: false, emojiStatus: false })
											}
										}}
									/>
								</div>
							</div>
						</div>
					)
				}}
			</Mutation>
		)
	}
}

export default withApollo(ChatFooter)
