import React, { Component } from 'react'
import ChatListItem from './ChatListItem'
import { MESSAGE_RECEIVED_SUBSCRIPTION } from '../query'
import { MdAdd } from 'react-icons/md'
import styles from './styles'

class List extends Component {
	componentDidMount() {
		this.props.subscribeToMore({
			document: MESSAGE_RECEIVED_SUBSCRIPTION,
			variables: {
				hash: this.props.hash,
			},
			updateQuery: (prev, { subscriptionData }) => {
				this.props.refetch()
			},
		})
	}

	render() {
		const {
			fetchMore,
			data,
			addNewConversation,
			groupId,
			openFirstChat,
			conversationType,
			client,
			hash,
			refetch,
			archieveMember,
		} = this.props

		let listItem = this.props.contacts.map((item, index) => {
			if (index === 0 && conversationType !== 'new') {
				openFirstChat({ item, refetch })
			}
			return (
				<ChatListItem
					item={item}
					key={item.id}
					groupId={groupId}
					getChats={this.props.getChats}
					client={client}
					hash={hash}
					refetch={refetch}
					archieveMember={archieveMember}
				/>
			)
		})

		const scrollOnTop = (e, fetchMore, data) => {
			const elementTarget = e.target
			if (
				elementTarget.scrollHeight - elementTarget.scrollTop ===
				elementTarget.clientHeight
			) {
				fetchMore({
					variables: {
						offset: (data.newGetChatList.length / 12) * 12,
					},
					updateQuery: (prev, { fetchMoreResult }) => {
						if (!fetchMoreResult) return prev
						return {
							...prev,
							newGetChatList: [...prev.newGetChatList, ...fetchMoreResult.newGetChatList],
						}
					},
				})
			}
		}
		return (
			<div
				className="chatList"
				id="chatListScroll"
				onScroll={(e) => {
					scrollOnTop(e, fetchMore, data)
				}}>
				{listItem}
			</div>
		)
	}
}

export default List
