import { useMutation } from '@apollo/react-hooks'
import { Button, Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { MARK_AS_UNFOLLOW_MUTATION } from './mutation'

// Importing required components from ANTD

// Query to fetch CRM Details on load

// Component CSS

const { confirm } = Modal

export default function MarkAsUnfollowed(props) {
	const [markAsUnfollowed] = useMutation(MARK_AS_UNFOLLOW_MUTATION)

	async function onclickUnfollow() {
		confirm({
			title: 'Are you sure you want unfollow this enquiry ?',
			okText: 'Confirm',
			centered: true,
			onOk: async () => {
				try {
					await markAsUnfollowed({
						variables: {
							enquiryId: props.currentEnquiryId,
							stageFrom: props.enquiryType,
						},
					})
					props.refetch()
					notification.success({
						message: 'Enquiry has been unfollowed',
						description: 'Successfully',
						style: { marginTop: 50 },
					})
					setTimeout(() => {
						props.closeModal()
					}, 1000)
				} catch (error) {
					return null
				}
			},
			onCancel() {
				// console.log('cancel')
			},
		})
	}
	return (
		<>
			<Button size="small" type="link" onClick={onclickUnfollow}>
				Unfollow
			</Button>
		</>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
MarkAsUnfollowed.propTypes = {
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
	refetch: PropTypes.func,
}

MarkAsUnfollowed.defaultProps = {
	closeModal: PropTypes.func,
	currentEnquiryId: PropTypes.string,
	enquiryType: PropTypes.string,
	refetch: PropTypes.func,
}
