const styles = {
	/**
	 * This styles is used to add a render fuction css.
	 */

	// loading icon css
	loadingIcon: { fontSize: 50 },
	loadingIcons: { fontSize: 30 },
	loadIconStyles: { padding: '30px 400px' },
	loadIconStyle: { padding: '30px 400px' },

	// project archived css
	unArchived: {
		width: '50vw',
		height: 'calc(86vh - 84px)',
		overflowY: 'auto !important',
		overflowX: 'hidden',
		background: 'white',
		margin: '0px 0px 10px 10px',
	},
	header: {
		textAlign: 'center',
		fontSize: '16px',
		fontWeight: 'bold',
		padding: '1%',
	},
	content: {
		padding: '2%',
	},
	unarchivedData: {
		display: 'flex',
		padding: '1%',
		cursor: 'pointer',
	},
	noprojectShow: {
		textAlign: 'center',
		fontSize: '14px',
		padding: '0px 0px 88px 0px',
	},
	sendButton: {
		position: 'relative',
		float: 'right',
		top: '8px',
  },
  buttonDiv:{
    position: 'fixed',
    bottom: '3%',
    left: '33%',
    
  },
  
}

export default styles
