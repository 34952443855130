const styles = {
	messageDropdown: {
		messageDropdownButton: {
			border: 'none',
			cursor: 'pointer',
			outline: 'none',
			background: 'transparent',
			padding: 0,
			margin: 0,
			height: 23,
			marginTop: 6,
			marginLeft: 10,
		},
		menuItemDiv: {
			padding: '0px 20px',
			width: 'auto',
			textAlign: 'left',
			maxHeight: '200px',
			overflow: 'scroll',
		},
		menuItemPadding: {
			padding: '10px 0px',
		},
		menuItem: {
			color: 'rgb(68, 68, 68)',
			fontSize: '0.9em',
			cursor: 'pointer',
			width: '100%',
		},
	},
}

export default styles
