// import react component
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Spin, Icon, Popconfirm } from 'antd'
import { GET_MASTER_SCOPE_QUERY, GET_PROPOSAL_SERVICE_LIST_QUERY } from '../query' // Import GraphQL Query
import ScopeTree from './ScopeTree' // Import Scope Tree component
import { MdDelete } from 'react-icons/md'
import { formatChatRelativetimestamp } from '../utils'
import { DebounceInput } from 'react-debounce-input'
import './index.css' // Import component CSS

const stageColor = {
	Architecture: '#af4a7eb0',
	Interiors: '#25495ab0',
	Interior: '#25495ab0',
	Landscape: '#6f7d5f',
	Lighting: '#af714c',
	Acoustics: '#69ad92',
	'Facade Design': '#815daf',
}

// Service scope component
class ServiceScopeConfig extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hoverService: null,
			serviceList: [],
			refetch: null,
			searchString: '',
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.editService !== this.props.editService) {
			const { refetch } = this.state
			if (refetch !== null) {
				this.state.refetch()
			}
		}
	}
	// service scope callback function
	setServiceScope = (data, type) => {
		const { setServiceScope } = this.props
		// callback function
		setServiceScope(data, type)
	}

	// Handle onchange space config
	onChangeSpaceConfig = (index, data) => {
		// Deconstruct object
		const { typologyId, typologyName, noOfStage } = data[index]
		// Deconstruct props
		const { serviceId } = this.props
		const result = {
			typologyId,
			typologyName,
			noOfStage: parseInt(noOfStage),
			serviceId,
		}
		const { editExistingService } = this.props
		// Call back function
		editExistingService(result)
	}

	// Handle onchange proposal service
	onChangeService = (serviceDetails) => {
		// Deconstruct props
		const { typology, service, noOfStage } = serviceDetails

		const data = {
			typologyId: typology.id,
			typologyName: typology.name,
			noOfStage,
			serviceId: service.id,
		}

		// Deconstruct props
		const { editExistingService } = this.props
		// callback function
		editExistingService(data)
	}

	render() {
		// Deconstruct props
		const { serviceScope, setServiceScope, serviceStatus } = this.props
		const { hoverService, serviceList, searchString } = this.state

		// Deconstruct service scope object
		const {
			scopeDeliverable,
			serviceScopeCheckedKey,
			serviceScopeExpandedKey,
			serviceScopeHalfCheckedKeys,
		} = serviceScope

		return (
			<div>
				{serviceStatus === 'create' ? (
					<div className="serviceScope" style={{ width: '65%' }}>
						{!scopeDeliverable.length ? (
							// Get master scope details from backend
							<Query query={GET_MASTER_SCOPE_QUERY}>
								{({ data, loading }) => {
									if (loading) {
										return (
											// Spinner icon
											<Spin
												indicator={<Icon type="loading" className="iconSize" spin />}
												className="loaderIcon"
											/>
										)
									}
									if (!data.getServiceMasterScope) {
										return null
									}
									const { getServiceMasterScope } = data
									// Check service scope is exists or not
									if (!serviceScope.scopeDeliverable.length) {
										const result = {
											scopeDeliverable: getServiceMasterScope,
											serviceScopeCheckedKey,
											serviceScopeExpandedKey,
											serviceScopeHalfCheckedKeys,
										}
										setServiceScope(result, 'edit')
									}
									// load service scope tree
									return (
										<ScopeTree
											serviceScope={serviceScope}
											setServiceScope={this.setServiceScope}
										/>
									)
								}}
							</Query>
						) : (
							// load service scope tree
							<ScopeTree
								serviceScope={serviceScope}
								setServiceScope={this.setServiceScope}
							/>
						)}
					</div>
				) : null}
				{scopeDeliverable.length && serviceStatus === 'edit' ? (
					<div className="serviceScope" style={{ width: '65%' }}>
						<ScopeTree
							serviceScope={serviceScope}
							setServiceScope={this.setServiceScope}
						/>
					</div>
				) : serviceStatus === 'edit' ? (
					<>
						<div
							style={{
								display: 'block',
								height: 50,
								background: '#eeebe8',
								margin: 5,
								borderRadius: 10,
							}}>
							<h1 style={{ float: 'left', margin: '12px', color: 'gray' }}>
								Template List
							</h1>
							<div
								className="chatSearch"
								style={{ position: 'relative', float: 'right' }}>
								<DebounceInput
									minLength={3}
									placeholder="Search Template . . ."
									debounceTimeout={300}
									onChange={(e) => {
										this.setState({ searchString: e.target.value })
									}}
								/>

								<img
									src={require('./../../ChatBox/images/searchicon.png')}
									alt={''}
									style={{
										width: '15px',
										position: 'absolute',
										bottom: '18px',
										left: '25px',
										top: '18px',
										fontSize: '15px',
									}}
								/>
							</div>
						</div>
						<div
							className="serviceScope"
							style={{ width: '100%', background: '#fafafa' }}>
							<Query query={GET_PROPOSAL_SERVICE_LIST_QUERY} variables={{ searchString }}>
								{({ loading, data, refetch }) => {
									if (loading) {
										return (
											// Spinner icon
											<Spin
												indicator={<Icon type="loading" className="iconSize" spin />}
												className="loaderIcon"
											/>
										)
									}

									if (!serviceList.length) {
										this.setState({
											serviceList: data.getProposalServiceList,
											refetch,
										})
									}

									return (
										<>
											<div>
												{data.getProposalServiceList.map((value, index) => {
													const { service, scope, typology, noOfStage, updatedAt } = value

													return (
														<div
															id="serviceList"
															key={'data' + index}
															onMouseEnter={() => {
																this.setState({ hoverService: value })
															}}
															onMouseLeave={() => {
																this.setState({ hoverService: null })
															}}
															onClick={(e) => {
																this.onChangeService(value)
															}}>
															<p title={service.name}>{service.name}</p>
															<div id="scopeList">
																{scope.map((scopeName) => {
																	return (
																		<span style={{ background: stageColor[scopeName] }}>
																			{scopeName}
																		</span>
																	)
																})}
															</div>
															<div id="line"></div>
															<div id="typology">
																<div
																	id="typologyName"
																	title={`${noOfStage} stage - ${typology.name}`}>
																	{noOfStage} stage - {typology.name}
																</div>
																<span> {formatChatRelativetimestamp(updatedAt)}</span>

																{hoverService !== null &&
																hoverService.service.id === service.id ? (
																	<Popconfirm
																		title="Are you sure delete this template?"
																		onConfirm={(e) => {
																			e.stopPropagation()
																			const { deleteTemplate } = this.props
																			deleteTemplate(service.id)
																		}}
																		onCancel={(e) => {
																			e.stopPropagation()
																		}}
																		okText="Yes"
																		cancelText="No">
																		<div
																			id="deleteIcon"
																			onClick={(e) => {
																				e.stopPropagation()
																			}}>
																			<MdDelete size={25} color="#666" />
																		</div>
																	</Popconfirm>
																) : null}
															</div>
														</div>
													)
												})}
											</div>
										</>
									)
								}}
							</Query>
						</div>
					</>
				) : null}

				{/** Here check if scope deliverable is already exist load the
				 *  previously existing data in scope tree component or otherwise
				 * get the data from backend and load that data in scopeTree component */}
			</div>
		)
	}
}

// export ServiceScopeConfig component
export default ServiceScopeConfig
