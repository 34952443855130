const styles = {
	privateHeaderMenuStyle: {
		top: '0',
		background: '#fff',
		padding: 0,
		width: '-webkit-fill-available',
		height: 60,
		borderBottom: '1px solid #dddddd',
		boxShadow: '3px 1px 4px 2px #c1c1c1',
	},
	publicHeaderMenuStyle: {
		background: '#fff',
	},
	orgLogo: { marginTop: -10, marginLeft: 10 },
	publicOrgLogo: { marginTop: 0 },
}

export default styles
