const styles = {
	cursorPointer: {
		cursor: 'pointer',
	},
	visibilityHidden: {
		visibility: 'hidden',
	},
	fontSize: {
		fontSize: 13,
	},
	profilePic: {
		width: '50px',
		height: '50px',
		borderRadius: '100%',
		marginRight: '10px',
	},
	profileAvatarPic: {
		height: 50,
		width: 50,
		padding: 5,
		fontSize: 22,
		marginRight: '10px',
		verticalAlign: 'middle',
	},
	profileName: { placeSelf: 'center start' },
	profileNavbar: {
		width: '120px',
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: '10px',
	},
	attach: {
		imageFileUpload: { boxShadow: 'none', marginTop: -13, marginBottom: 6 },
		imageAndPdfUpload: { fontSize: 25, marginTop: 5 },
		pdfFileUpload: { marginTop: 5 },
	},
	menu: {
		menuButton: {
			border: 'none',
			borderRadius: '50%',
			cursor: 'pointer',
			outline: 'none',
			background: 'transparent',
		},
		menuItemDiv: {
			padding: '0px 20px',
			width: '140px',
			textAlign: 'left',
		},
		menuItemPadding: {
			padding: '10px 0px',
		},
		menuItem: {
			color: 'rgb(68, 68, 68)',
			fontSize: '0.9em',
			cursor: 'pointer',
			width: '100%',
		},
	},
}

export default styles
