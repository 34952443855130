import React from 'react'
import { Row, Col, Spin, Icon, Avatar } from 'antd'
import { MdClose, MdSend } from 'react-icons/md'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { MESSAGE_FORWARD, FORWARD_MEMBERS } from '../query'
import { Mutation, Query } from 'react-apollo'
import Checkbox from 'react-simple-checkbox'
import Loader from 'react-loader-spinner'
import { selectedNameToString } from '../utils'
import styles from './styles'
import './index.css'

class ForwardMessage extends React.Component {
	state = {
		selectedId: [],
		selectedName: [],
		selectedFullNameString: null,
		teamMembers: [],
		searchString: '',
	}

	render() {
		const {
			selectedId,
			selectedName,
			selectedFullNameString,
			teamMembers,
			searchString,
		} = this.state

		const {
			handleModalCancel,
			handleModalOk,
			refetch,
			hash,
			messageData,
			loader,
		} = this.props

		const lowercasedFilter = searchString.toLowerCase()
		const filteredData = teamMembers.filter((item) => {
			return item['name'].toLowerCase().includes(lowercasedFilter)
		})

		return (
			<Mutation mutation={MESSAGE_FORWARD}>
				{(forwardMessage, { data, loading }) => (
					<Modal type="forwardMessage">
						<ModalHeader type="forwardMessage">
							<div style={styles.modalHeaderFlex}>
								<span onClick={handleModalCancel} style={styles.modalHeaderTextColor}>
									<MdClose size={25} style={styles.modalHeaderIcon} />
								</span>
								<span style={styles.modalHeaderName}>Forward messages to</span>
							</div>
						</ModalHeader>
						<ModalButtonContainer type="forwardMessage">
							<div className="forwardMessage">
								<Row>
									<Col span={24}>
										<div className="chatSearch" style={styles.positionRelative}>
											<input
												type=""
												placeholder="search..."
												onChange={(e) => {
													this.setState({ searchString: e.target.value })
												}}
												value={searchString}
											/>
											<img
												src={require('./../images/searchicon.png')}
												alt={''}
												style={styles.searchIcon}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<div style={styles.forwardMessage.forwardMessageModalHeight}>
										<Query
											query={FORWARD_MEMBERS}
											variables={{
												hash: hash,
											}}>
											{({ loading, error, data }) => {
												if (loading) {
													return (
														<div style={styles.fullWidth}>
															<div>
																<div style={styles.centerAlign}>
																	<Spin indicator={<Icon type="loading" spin />} />
																</div>
															</div>
														</div>
													)
												}
												if (!data || !data.forwardMembers.length) {
													return (
														<div style={styles.fullWidth}>
															<div>
																<div style={styles.forwardMessage.membersNotFound}>
																	<h3>No team members or groups found</h3>
																</div>
															</div>
														</div>
													)
												}
												if (!teamMembers.length) {
													this.setState({ teamMembers: data.forwardMembers })
												}
												return (
													<div style={styles.forwardMessage.listMembersDiv}>
														{filteredData.length ? (
															<>
																{filteredData.map((people, index) => {
																	const {
																		memberGroupId: peopleGroupId,
																		hash: peopleHash,
																		name: groupName,
																	} = people
																	let checkedStatus = selectedId.filter(function(item) {
																		return item.id === (peopleGroupId || peopleHash)
																	})

																	return (
																		<Row key={'Forward' + peopleHash + index}>
																			<Col span={4}>
																				<div
																					style={{
																						margin: 10,
																					}}>
																					<Checkbox
																						id={peopleHash}
																						size={2}
																						tickSize={2}
																						checked={checkedStatus.length ? true : false}
																						color="#00bfa5"
																						onChange={() => {
																							let alreadySelectedStatus = selectedId.filter(
																								function(item) {
																									return (
																										item.id ===
																										(peopleGroupId || peopleHash)
																									)
																								}
																							)

																							if (alreadySelectedStatus.length) {
																								const filterSelectedId = selectedId.filter(
																									(item) => {
																										if (item.idType === 'group') {
																											return item.id !== peopleGroupId
																										} else {
																											return item.id !== peopleHash
																										}
																									}
																								)

																								const filterSelectedName = selectedName.filter(
																									(item) => item.name !== groupName
																								)

																								const {
																									selectedFullNameString,
																								} = selectedNameToString(
																									filterSelectedName
																								)

																								this.setState({
																									selectedId: filterSelectedId,
																									selectedName: filterSelectedName,
																									selectedFullNameString,
																								})
																							} else {
																								const newSelectedId = {
																									id:
																										peopleGroupId === '' ||
																										peopleGroupId === null
																											? peopleHash
																											: peopleGroupId,
																									idExist: peopleGroupId !== null,
																									idType:
																										peopleGroupId === '' ||
																										peopleGroupId === null
																											? 'hash'
																											: 'group',
																								}

																								const newSelectedName = {
																									hash: peopleHash,
																									name: people.name,
																								}

																								const {
																									selectedFullNameString,
																								} = selectedNameToString([
																									...selectedName,
																									newSelectedName,
																								])

																								this.setState({
																									selectedId: [
																										...selectedId,
																										newSelectedId,
																									],
																									selectedName: [
																										...selectedName,
																										newSelectedName,
																									],
																									selectedFullNameString,
																								})
																							}
																						}}
																					/>
																				</div>
																			</Col>
																			<Col span={20}>
																				<div style={styles.forwardMessage.listMembers}>
																					<Avatar>{groupName[0]}</Avatar>
																					<h3
																						style={styles.forwardMessage.listMemberName}
																						title={groupName}>
																						{groupName}
																					</h3>
																				</div>
																			</Col>
																		</Row>
																	)
																})}
															</>
														) : (
															<Row>
																<Col
																	span={24}
																	style={styles.forwardMessage.listMembersNotFound}>
																	No members found
																</Col>
															</Row>
														)}
													</div>
												)
											}}
										</Query>
									</div>
								</Row>
								{selectedId.length ? (
									<div style={styles.forwardMessage.forwardButton}>
										<Row>
											<Col span={20}>
												<h3
													style={styles.forwardMessage.selectedMember}
													title={selectedFullNameString}>
													{selectedFullNameString}
												</h3>
											</Col>
											<Col span={4}>
												<div
													style={styles.forwardMessage.forwardButtonStyle}
													onClick={async () => {
														this.setState({ loading: true })
														handleModalOk({
															forwardMessage,
															messageData,
															refetch,
															selectedId: selectedId.map(({ id, idExist }) => ({
																id,
																idExist,
															})),
															type: 'forwardMessage',
														})
													}}>
													{loader ? (
														<div style={styles.forwardMessage.forwardSendLoader}>
															<Loader
																type="TailSpin"
																color="#ffffff"
																height={25}
																width={25}
																radius={4}
															/>
														</div>
													) : (
														<MdSend
															size={30}
															style={styles.forwardMessage.forwardSendIcon}
														/>
													)}
												</div>
											</Col>
										</Row>
									</div>
								) : null}
							</div>
						</ModalButtonContainer>
					</Modal>
				)}
			</Mutation>
		)
	}
}
export default ForwardMessage
