import React from 'react'
import { Modal, ModalButton, ModalButtonContainer, ModalHeader } from './utils'
import { DELETE_MESSAGE } from '../query'
import { Mutation } from 'react-apollo'
import styles from './styles'

class DeleteMessage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
		}
	}
	render() {
		const { handleModalCancel, handleModalOk, messageId, hash, refetch } = this.props

		return (
			<Mutation mutation={DELETE_MESSAGE}>
				{(deleteMessage, { data, loading }) => (
					<Modal>
						<ModalHeader>
							<span style={styles.deleteMessage.deleteMessageText}>
								Are you sure want to delete message?
							</span>
						</ModalHeader>
						<ModalButtonContainer>
							<ModalButton type="cancel" onClick={handleModalCancel}>
								CANCEL
							</ModalButton>
							<ModalButton
								type="delete"
								onClick={async () => {
									this.setState({ loading: true })
									const { data } = await deleteMessage({ variables: { messageId, hash } })
									if (data.deleteMessage) {
										this.setState({ loading: false })
										handleModalOk({ refetch, type: 'deleteMessage' })
									}
								}}>
								{loading ? 'DELETING' : 'DELETE'}
							</ModalButton>
						</ModalButtonContainer>
					</Modal>
				)}
			</Mutation>
		)
	}
}
export default DeleteMessage
