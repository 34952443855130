import gql from 'graphql-tag'

export const EDIT_ENQUIRY_DETAILS_MUTATION = gql`
	mutation EditEnquiryDetails(
		$enquiryId: String
		$enquiryDetailType: String
		$oldValue: String
		$newValue: String
	) {
		editEnquiryDetails(
			enquiryId: $enquiryId
			enquiryDetailType: $enquiryDetailType
			oldValue: $oldValue
			newValue: $newValue
		) {
			updateEnquiryDetails
			insertEnquiryNavigation
		}
	}
`
