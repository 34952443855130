import coverImage from '../../assets/loginPage.jpg'

const styles = {
	mainDiv: {
		backgroundImage: `url(${coverImage})`,
		backgroundSize: 'cover',
		overflow: 'hidden',
		height: '100vh',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'fixed',
		backgroundColor: '#464646',
		backgroundPosition: 'center center',
	},
	root: {
		overflow: 'hidden',
		height: '100vh',
	},
	contentRow: { marginTop: '30vh' },
	designQubeLogoContainer: {
		marginBottom: 50,
		backgroundColor: '#0000',
		border: 'none',
		width: '300px',
		marginLeft: 'auto',
		marginRight: 'auto',
		borderRadius: '4vw',
		filter: 'brightness(1.3)',
	},
	designQubeLogoImg: { marginLeft: 10, filter: 'drop-shadow(6px 4px 2px #000)' },
	oAuthContainer: {
		width: '250px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	oAuthButton: {
		width: 'inherit',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: 'auto',
		background: '#ffffff9e',
		color: '#000',
		border: 'none',
		transition: 'color 0.8s, background 0.5s',
	},
	oAuthGoogleSvg: {
		height: '50px',
		width: '30px',
		display: 'block',
	},
	oAuthButtonText: {
		fontSize: 20,
		margin: 10,
		whiteSpace: 'normal',
		display: 'inline-block',
	},
}

export default styles
