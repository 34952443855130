import React, { Component } from 'react'
import { Input, Divider } from 'antd'
import './index.css'

const { TextArea } = Input

// eslint-disable-next-line react/prefer-stateless-function
class ServiceTeamDescription extends Component {
	onChangeTeamDescription = (index) => ({ target: { value } }) => {
		const { setTeamDescription } = this.props
		setTeamDescription(value, index)
	}

	onChangeTeamTitle = (index) => ({ target: { value } }) => {
		const { setTeamTitle } = this.props
		setTeamTitle(value, index)
	}

	render() {
		const { serviceTeamDescription, ServiceTeamDescriptionTemplate } = this.props
		const { teamDescription } = serviceTeamDescription

		let finalTeamDescription =
			teamDescription.length > 0
				? teamDescription
				: ServiceTeamDescriptionTemplate.teamDescription

		return (
			<div className="descriptionDetails">
				<div className="teamDescription">
					<h1 className="teamHead">Team Description</h1>
					<span className="teamMessage">
						Notes : Only allowed maximum 200 characters for each description
					</span>

					<form className="stageResultDescription">
						{/** Service Name */}

						{finalTeamDescription.map((teamData, index) => {
							const { title, description } = teamData
							const placeHolderTitle = `Enter title...`
							const placeHolderDescription = `Enter ${title} description...`
							const teamKey = `team${index}`
							return (
								<div className="teamDetailsList" key={teamKey}>
									<Divider orientation="left">{title}</Divider>
									{/* <h4 className="boldText">Retainer</h4> */}
									<Input
										value={title}
										onChange={this.onChangeTeamTitle(index)}
										placeholder={placeHolderTitle}
										className={`teamDesignation ${title !== '' ? ' ' : 'error'}`}
									/>
									<TextArea
										value={description}
										placeholder={placeHolderDescription}
										className={`teamDescriptionList ${
											description !== '' ? ' ' : 'error'
										}`}
										onChange={this.onChangeTeamDescription(index)}
										rows={2}
										maxLength={200}
									/>
								</div>
							)
						})}
					</form>
				</div>
			</div>
		)
	}
}

export default ServiceTeamDescription
