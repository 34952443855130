import React from 'react'
import { MdArrowForward, MdArrowBack, MdCheckCircle } from 'react-icons/md'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { Spin, Icon, Row, Col, Avatar } from 'antd'
import { Query, Mutation } from 'react-apollo'
import { GET_PEOPLE, CREATE_NEW_GROUP } from '../query'
import Loader from 'react-loader-spinner'
import styles from './styles'

class EditGroupParticipant extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			groupMember: this.props.groupMember,
			selectedMember: [],
			teamMembers: [],
			searchString: '',
		}
	}
	render() {
		const { groupMember, teamMembers, selectedMember, searchString } = this.state
		const { hash, handleModalOk, handleModalBack, selectedContact, loader } = this.props
		const lowercasedFilter = searchString.toLowerCase()
		const filteredData = teamMembers.filter((item) => {
			return item['name'].toLowerCase().includes(lowercasedFilter)
		})

		return (
			<Mutation mutation={CREATE_NEW_GROUP}>
				{(addNewGroup, { data, loading }) => (
					<Modal type="editGroupParticipant">
						<ModalHeader type="editGroupParticipant">
							<div style={styles.modalHeaderFlex}>
								<span
									onClick={() => {
										handleModalBack({ modalType: 'editGroupInfo' })
									}}
									style={styles.modalHeaderTextColor}>
									<MdArrowBack size={25} style={styles.modalHeaderIcon} />
								</span>
								<span style={styles.modalHeaderName}>Add group participants</span>
							</div>
						</ModalHeader>
						<ModalButtonContainer type="editGroupParticipant">
							<Row>
								<Col span={24}>
									<div className="chatSearch" style={styles.positionRelative}>
										<input
											type="text"
											placeholder="search..."
											onChange={(e) => {
												this.setState({ searchString: e.target.value })
											}}
											value={searchString}
										/>
										<img
											src={require('./../images/searchicon.png')}
											alt={''}
											style={styles.searchIcon}
										/>
									</div>
								</Col>
							</Row>

							<Row>
								<Query query={GET_PEOPLE} variables={{ hash: hash }}>
									{({ loading, error, data }) => {
										if (loading) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.centerAlign}>
															<Spin indicator={<Icon type="loading" spin />} />
														</div>
													</div>
												</div>
											)
										}
										if (!data || !data.getPeople.length) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.editGroupParticipant.membersNotFound}>
															<span>No team members found.</span>
														</div>
													</div>
												</div>
											)
										}
										if (!teamMembers.length) {
											this.setState({ teamMembers: data.getPeople })
										}
										return (
											<div style={styles.editGroupParticipant.listMembersDiv}>
												<ul style={styles.editGroupParticipant.unorderedListMember}>
													{filteredData.length ? (
														<>
															{filteredData.map((people) => {
																const {
																	name: peopleName,
																	hash: peopleHash,
																	groupId: peopleGroupId,
																} = people

																let checkedStatus = selectedMember.filter(function(item) {
																	return item.hash === peopleHash
																})

																let oldCheckedStatus = groupMember.filter(function(item) {
																	return item.hash === peopleHash
																})

																return (
																	<>
																		{!oldCheckedStatus.length ? (
																			<li
																				key={peopleHash}
																				style={
																					styles.editGroupParticipant.listGroupMember
																				}
																				onClick={() => {
																					let alreadySelectedStatus = selectedMember.filter(
																						function(item) {
																							return item.hash === peopleHash
																						}
																					)

																					if (alreadySelectedStatus.length) {
																						const filterSelectedMember = selectedMember.filter(
																							(item) => item.hash !== peopleHash
																						)

																						this.setState({
																							selectedMember: filterSelectedMember,
																						})
																					} else {
																						const newSelectedMember = {
																							memberGroupId:
																								peopleGroupId === null
																									? peopleHash
																									: peopleGroupId,
																							hash: peopleHash,
																						}

																						this.setState({
																							selectedMember: [
																								newSelectedMember,
																								...this.state.selectedMember,
																							],
																						})
																					}
																				}}>
																				<Avatar>{peopleName[0]}</Avatar>
																				<span
																					style={
																						styles.editGroupParticipant.listMemberName
																					}
																					title={peopleName}>
																					{peopleName}
																				</span>
																				{checkedStatus.length ||
																				oldCheckedStatus.length ? (
																					<MdCheckCircle size={25} color={'green'} />
																				) : null}
																			</li>
																		) : null}
																	</>
																)
															})}
															<div key={'NoMoreAvailable'}>
																<li style={{ padding: 10, textAlign: 'center' }}>
																	<span style={{ color: 'gray' }}>
																		No more members :)
																	</span>
																</li>
															</div>
														</>
													) : (
														<li style={styles.editGroupParticipant.searchListMemberDiv}>
															<span
																style={
																	styles.editGroupParticipant.searchListMemberResult
																}>
																No members found
															</span>
														</li>
													)}
												</ul>
												<div
													style={styles.editGroupParticipant.updateGroupDetails}
													onClick={() => {
														const {
															name: groupName,
															id: groupId,
															type: groupType,
														} = selectedContact
														const { selectedMember } = this.state

														const newGroupMember = []
														for (let index = 0; index < selectedMember.length; index++) {
															const { hash } = selectedMember[index]
															newGroupMember.push({
																hash: hash,
															})
														}

														handleModalOk({
															addNewGroup,
															groupName,
															groupDesc: '',
															groupType: '',
															projectUrl: '',
															groupMember: newGroupMember,
															groupPicture: [],
															groupId,
															type: 'updateGroupParticipant',
														})
													}}>
													{loader ? (
														<div style={styles.editGroupParticipant.updateLoader}>
															<Loader
																type="TailSpin"
																color="#ffffff"
																height={25}
																width={25}
																radius={4}
															/>
														</div>
													) : (
														<MdArrowForward
															size={30}
															style={styles.editGroupParticipant.updateIcon}
														/>
													)}
												</div>
											</div>
										)
									}}
								</Query>
							</Row>
						</ModalButtonContainer>
					</Modal>
				)}
			</Mutation>
		)
	}
}
export default EditGroupParticipant
