const styles = {
	emojiAndCloseIcon: {
		margin: '13px 12px 0px 12px',
		cursor: 'pointer',
	},
	centerAlign: {
		textAlign: 'center',
	},
	chatMessageInput: {
		width: '95%',
		padding: '10px',
		border: '1px solid rgba(0, 0, 0, 0.05)',
		height: '40px',
		color: '#7f7f7f',
		fontSize: '1em',
		fontWeight: '500',
		letterSpacing: '0.5px',
		marginTop: '8px',
		borderRadius: '30px',
		outline: 'none',
		':focus': {
			color: '#545454',
		},
	},
	mentionStatusDiv: {
		position: 'absolute',
		bottom: 40,
		background: '#f5f1ee',
		width: '100%',
		minHeight: '15%',
		maxHeight: '40%',
		zIndex: 1,
		overflow: 'scroll',
	},
	unorderedListMentionStatus: {
		listStyleType: 'none',
		marginLeft: '1%',
		width: '50%',
		marginTop: '1%',
	},
	replyMessageDiv: {
		position: 'absolute',
		bottom: 55,
		zIndex: 1,
		background: '#f5f1ee',
		width: '100%',
		display: 'flex',
	},
	replyMessage: {
		margin: '8px 9px 0px 68px',
		padding: '5px 20px',
		borderRadius: 10,
		background: '#d3d3d375',
		width: '44%',
	},
	replyMessageTypeImage: { display: 'flex', background: 'white', padding: 5 },
	closeReplyMessage: { marginTop: 25, cursor: 'pointer' },
	chatFooter: {
		background: '#F5F1EE',
		padding: '6',
		bottom: 0,
		display: 'grid',
		gridTemplateColumns: '3% 48% 6%',
		position: 'fixed',
		width: '100%',
		zIndex: 1,
	},
	sendMessage: { margin: '10px -8px', cursor: 'pointer' },
}

export default styles
