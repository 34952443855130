// import React, { useState } from 'react'
// import { Redirect } from 'react-router-dom'
// export default function DriveAuthorize(props) {
// 	var url_string = window.location.href
// 	var url = new URL(url_string)
// 	var c = url.searchParams.get('code')

// 	return <span>Hi</span>
// }

// Importing required components from ANTD
import { Card, Icon, Spin } from 'antd'

import React from 'react'
import { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import { GenerateDriveToken } from './query'
const { Meta } = Card

// const { stringDecode } = utils

class DriveTokenize extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		var url_string = window.location.href
		var url = new URL(url_string)
		var authCode = url.searchParams.get('code')

		return (
			<>
				<Query
					query={GenerateDriveToken}
					variables={{
						authCode,
					}}>
					{({ data, loading, error, refetch }) => {
						if (loading) {
							return (
								<Spin
									indicator={<Icon type="loading" style={{ fontSize: 52 }} spin />}
									style={{ marginLeft: '45%', marginTop: '20%' }}
								/>
							)
						}
						if (data && data.generateDriveToken) {
							return <Redirect to="/dashboard/drawings" />
						} else {
							return <Redirect to="/dashboard/chat" />
						}
					}}
				</Query>
			</>
		)
	}
}

export default DriveTokenize
