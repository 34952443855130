import { format, formatDistanceToNow, fromUnixTime } from 'date-fns'
import Resizer from 'react-image-file-resizer'

export const hashCode = (str) => {
	var hash = 0
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	return 100 * hash
}

export const intToRGB = (i) => {
	var c = (i & 0x00ffffff).toString(16).toUpperCase()
	return '00000'.substring(0, 6 - c.length) + c
}

export const generateUserNameColor = (userHash) => {
	return '#' + intToRGB(hashCode(userHash))
}

export const detectUrlInMessage = (text) => {
	var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
	return text.replace(urlRegex, function(url) {
		return '<a target="_blank" href="' + url + '">' + url + '</a>'
	})

	// const mentionPerson = newUrl.split(/(\s+)/)
	// const startsWithMention = mentionPerson.filter((person) => person.startsWith('@'))
	// const data = { mentionKeyWord: startsWithMention, text: newUrl }

	// return data
}

/**this function is used to replace the time in our required format */
export function truncateTime(time) {
	return time
		.replace(/half a minute/g, '30 secs')
		.replace(/less than\s/g, '')
		.replace(/less than a\s/g, ' 1')
		.replace(/\sseconds/g, ' secs')
		.replace(/\ssecond/g, ' sec')
		.replace(/\sminutes/g, ' mins')
		.replace(/\sminute/g, ' min')
		.replace(/\sdays/g, ' d')
		.replace(/\sday/g, ' d')
		.replace(/\shours/g, ' hrs')
		.replace(/\shour/g, ' hr')
		.replace(/\smonths/g, ' mo')
		.replace(/\smonth/g, ' mo')
		.replace(/\syears/g, ' yrs')
		.replace(/\syear/g, ' yr')
		.replace(/about\s/g, '')
		.replace(/over\s/g, '')
		.replace(/almost\s/g, '')
}
export function showMofiedRelativeTime(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	return `${truncateTime(relativeTimeValue)} ago`
}
/**this function is used to convert the timestamp into relative time */
export function formatRelativetimestamp(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	const getYear = format(fromUnixTime(timestamp), 'yy')
	const currentYear = format(new Date(), 'yy')
	if (
		relativeTimeValue.includes('day') ||
		relativeTimeValue.includes('year') ||
		relativeTimeValue.includes('month')
	) {
		if (getYear === currentYear) {
			return format(fromUnixTime(timestamp), 'MMM d')
		} else {
			return format(fromUnixTime(timestamp), 'dd-MMM-yy')
		}
	} else {
		return `${truncateTime(relativeTimeValue)} ago`
	}
}

/**this function is used to convert the timestamp into relative time */
export function formatChatRelativetimestamp(timestamp) {
	const relativeTimeValue = relativeTime(timestamp)
	const getYear = format(fromUnixTime(timestamp), 'yy')
	const currentYear = format(new Date(), 'yy')
	if (
		relativeTimeValue.includes('day') ||
		relativeTimeValue.includes('year') ||
		relativeTimeValue.includes('month')
	) {
		if (getYear === currentYear) {
			return format(fromUnixTime(timestamp), 'MMM d')
		} else {
			return format(fromUnixTime(timestamp), 'dd-MMM-yy')
		}
	} else {
		return format(fromUnixTime(timestamp), 'hh:mm a')
	}
}
/**fucntion to get relative time */
export function relativeTime(timestamp, addSuffix = false) {
	if (typeof timestamp === 'string') {
		timestamp = Number(timestamp)
	}
	if (typeof timestamp !== 'number') {
		return timestamp
	}
	return formatDistanceToNow(new Date(timestamp * 1000), {
		addSuffix,
		includeSeconds: true,
	})
}
// function returns name initials from full name
export function getInitials(string) {
	// if the supplied props is not of string type, return it as it is
	if (typeof string !== 'string') {
		return string
	}
	// split the names by space
	const names = string.split(' ')
	// Get first letter
	let initials = names[0].substring(0, 1).toUpperCase()
	// Get second name first letter, if there is a second name
	if (names.length > 1) {
		initials += names[1].substring(0, 1).toUpperCase()
	}
	// return the initials
	return initials
}
/**this function is used to convert array of arraus into array */
export function flatten(arr) {
	return arr.reduce(function(flat, toFlatten) {
		return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
	}, [])
}
/**convert date into time and it is used in date groupBY */
export const convertDatetoTime = (dateData) => {
	var month = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	var splittedDate = dateData.split('-')
	const currentDate = new Date()
	const date = currentDate.toJSON().slice(0, 10)
	if (dateData === date) {
		return 'Today'
	}
	return `${month[splittedDate[1] - 1]} ${splittedDate[2]}, ${splittedDate[0]}`
}
/**function is used to check the timestamp is within a day or not */
export const checkADayExist = (timestamp) => {
	const currentTime = Math.floor(Date.now() / 1000)
	if (currentTime - Number(timestamp) <= 86400) {
		return false
	}
	return true
}

function srcToFile(src, fileName, mimeType) {
	return fetch(src)
		.then(function(res) {
			return res.arrayBuffer()
		})
		.then(function(buf) {
			return new File([buf], fileName, { type: mimeType })
		})
}

export const optimizeImageResize = async (images) => {
	const qualityImageArray = []
	const thumbnailImageArray = []
	for (let x = 0; x < images.length; x++) {
		const qualityImage = await new Promise((resolve) => {
			Resizer.imageFileResizer(
				images[x],
				600,
				800,
				'JPEG',
				100,
				0,
				(uri) => {
					resolve(uri)
				},
				'base64'
			)
		})
		const thumbnailImage = await new Promise((resolve) => {
			Resizer.imageFileResizer(
				images[x],
				300,
				400,
				'JPEG',
				100,
				0,
				(uri) => {
					resolve(uri)
				},
				'base64'
			)
		})

		const convertQualityFile = await srcToFile(qualityImage, images[x].name, 'image/jpeg')
		const convertThumbnailFile = await srcToFile(
			thumbnailImage,
			images[x].name,
			'image/jpeg'
		)

		qualityImageArray.push(convertQualityFile)
		thumbnailImageArray.push(convertThumbnailFile)
	}

	return { qualityImageArray, thumbnailImageArray }
}

export const selectedNameToString = (data) => {
	var selectedFullNameString = data
		.map(function(val) {
			return val.name
		})
		.reverse()
		.join(',')

	var selectedHalfNameString = data
		.map(function(val) {
			return val.name.split(' ')[0]
		})
		.reverse()
		.join(',')

	return { selectedFullNameString, selectedHalfNameString }
}

export const deleteTimeOver = (createdAt) => {
	const HOUR = 1000 * 60 * 60
	const anHourAgo = Date.now() - HOUR
	return Number(createdAt) > Number(anHourAgo) / 1000
}
