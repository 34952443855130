import gql from 'graphql-tag'

export const SUBMIT_USER_FEEDBACK = gql`
	mutation SubmitFeedback(
		$userName: String
		$feedbackUserName: String
		$feedbackMessage: String
		$feedbackBrowser: String
		$feedbackOs: String
		$initialRating: String
		$finalRating: String
	) {
		SubmitFeedback(
			userName: $userName
			feedbackUserName: $feedbackUserName
			feedbackMessage: $feedbackMessage
			feedbackBrowser: $feedbackBrowser
			feedbackOs: $feedbackOs
			initialRating: $initialRating
			finalRating: $finalRating
		)
	}
`
