import React from 'react'
import { IoMdShareAlt } from 'react-icons/io'
import { MdDoNotDisturb, MdSend, MdClose } from 'react-icons/md'
import MessageDropdown from './MessageDropdown'
import { generateUserNameColor, detectUrlInMessage, deleteTimeOver } from '../utils'
import { notification } from 'antd'
import Lightbox from 'react-image-lightbox'
import pdfImage from '../images/pdf-image.jpeg'
import styles from './styles'

const { message } = styles

class Message extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			messageId: null,
			iconStatus: false,
			imageShow: false,
			imageUrl: null,
			messageText: null,
			messageCaption: null,
			photoIndex: 0,
		}
	}

	render() {
		const {
			messageStyle,
			index,
			messagesListWrapperStyle,
			text,
			timestamp,
			type,
			isDeleted,
			chatType,
			isMyReply,
			createdBy,
			messageType,
			caption,
			isForwarded,
			messageId,
			isTicket,
			messageData,
			reply,
			fromHash,
			createdAt,
			mediaImage,
		} = this.props
		const { photoIndex } = this.state
		const { messageNameStyle, messageTextStyle } = message

		const deleteStatus = deleteTimeOver(createdAt)

		const labelsAndContext = {
			Copy:
				messageType === 'text'
					? (_) => {
							const { messageId, messageText, messageCaption } = this.state
							var textField = document.createElement('textarea')
							textField.innerText = messageText
							document.body.appendChild(textField)
							textField.select()
							document.execCommand('copy')
							textField.remove()

							notification.success({
								message: 'Message copied.',
								placement: 'bottomLeft',
							})
					  }
					: undefined,
			'Create Ticket': (_) => {
				const { messageId, messageText, messageCaption, messageData } = this.state
				const { showModalDialog } = this.props
				showModalDialog({
					messageId,
					messageText,
					messageCaption,
					messageData,
					type: 'createTicket',
				})
			},
			Forward: (_) => {
				const { messageId, messageData } = this.state
				const { showModalDialog } = this.props
				showModalDialog({
					messageId,
					messageData,
					type: 'forwardMessage',
				})
			},
			Reply: (_) => {
				const { messageData } = this.state
				const { showModalDialog } = this.props
				showModalDialog({ messageData, type: 'showMessageReply' })
			},
			'Message Info': isMyReply
				? (_) => {
						const { messageData } = this.state
						const { showModalDialog } = this.props
						showModalDialog({ messageData, type: 'showMessageInfo' })
				  }
				: undefined,
			Delete:
				deleteStatus && isMyReply
					? (_) => {
							const { messageId } = this.state
							const { showModalDialog } = this.props
							showModalDialog({
								messageId,
								type: 'deleteMessage',
							})
					  }
					: undefined,
		}

		Object.keys(labelsAndContext).forEach((key) =>
			labelsAndContext[key] === undefined ? delete labelsAndContext[key] : {}
		)

		const { name, hash } = createdBy
		const deleteStyle = isDeleted
			? {
					fontStyle: 'italic',
					opacity: 0.7,
			  }
			: ''

		const messageNameColor =
			chatType !== 'individual' && !isMyReply && !isDeleted
				? {
						color: generateUserNameColor(hash),
						margin: 5,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
				  }
				: ''

		return (
			<>
				{type === 'text' ? (
					<div
						style={{
							...deleteStyle,
							...messagesListWrapperStyle,
							marginBottom: index === 0 ? '20px' : '5px',
						}}>
						<div
							style={{
								...messageNameStyle,
								background: isMyReply ? 'rgb(220, 247, 198)' : 'rgb(255,255,255)',
							}}
							onMouseEnter={() => {
								this.setState({
									messageId,
									messageText: text,
									messageCaption: caption,
									iconStatus: true,
									messageData: messageData,
								})
							}}
							onMouseLeave={() => {
								this.setState({ iconStatus: false })
							}}>
							{chatType !== 'individual' && !isMyReply && !isDeleted ? (
								<div style={messageTextStyle}>
									<span style={{ ...messageNameColor }}>{name}</span>
									<span
										style={{
											margin: 5,
											opacity: this.state.iconStatus ? 1 : 0,
										}}>
										<MessageDropdown labelsAndContext={labelsAndContext} />
									</span>
								</div>
							) : (
								''
							)}
							{isForwarded && !isDeleted ? (
								<div style={styles.displayFlex}>
									<IoMdShareAlt style={message.messageForwardIcon} />
									<span
										style={{
											...message.messageForwardText,
											fontSize: chatType !== 'individual' ? 11 : 11,
											marginTop: chatType !== 'individual' ? 3 : 3,
										}}>
										Forwarded
									</span>
								</div>
							) : null}

							<div style={messageStyle}>
								<div>
									{reply !== null && !isDeleted ? (
										<div
											style={{
												...message.messageReplyText,
												borderLeft: `3px solid ${generateUserNameColor(
													reply.createdBy.hash
												)}`,
												background: isMyReply ? '#cfe9ba' : '#f1f1f1',
											}}>
											<h4
												style={{
													color: generateUserNameColor(reply.createdBy.hash),
												}}>
												{reply.createdBy.hash === fromHash ? 'You' : reply.createdBy.name}
											</h4>
											{reply.messageType === 'text' ? (
												<span>{reply.content}</span>
											) : reply.messageType === 'image' ? (
												<img src={reply.content.split(',')[0]} height={30} width={30} />
											) : (
												<div style={message.messageReplyPdfDiv}>
													<img src={pdfImage} height="30" width="35" />
													<span
														style={message.messageReplyPdfCaption}
														title={reply.caption}>
														{reply.caption}
													</span>
												</div>
											)}
										</div>
									) : null}
								</div>
								{messageType === 'image' && !isDeleted ? (
									<div
										onClick={() => {
											const photoIndex = mediaImage.findIndex(
												(x) => x.msgId === messageId
											)

											this.setState({
												imageShow: true,
												photoIndex:
													photoIndex === -1 ? mediaImage.length - 1 : photoIndex,
												imageUrl:
													text.split(',')[1] === null
														? text.split(',')[1]
														: text.split(',')[0],
											})
										}}
										style={message.messageImageDiv}>
										<img
											src={
												text.split(',')[1] === null
													? text.split(',')[1]
													: text.split(',')[0]
											}
											height="auto"
											width="250"
											style={message.messageImage}
										/>
									</div>
								) : null}
								{messageType === 'pdf' && !isDeleted ? (
									<div
										style={message.messagePdfDiv}
										onClick={() => {
											window.open(text, '_blank')
										}}>
										<img src={pdfImage} height="30" width="35" style={{ marginTop: 8 }} />
										<div style={message.messagePdfCaption}>{caption}</div>
									</div>
								) : null}
								<div style={message.messageBreakWord}>
									{isDeleted ? (
										!isMyReply ? (
											<div style={styles.displayFlex}>
												<MdDoNotDisturb style={message.messageDoNotDisturbIcon} />
												<span> This message was deleted.</span>
											</div>
										) : (
											<div style={styles.displayFlex}>
												<MdDoNotDisturb style={message.messageDoNotDisturbIcon} />
												<span> You deleted this message.</span>
											</div>
										)
									) : messageType === 'image' && !isDeleted ? (
										<div
											style={message.messageImageCaption}
											dangerouslySetInnerHTML={{
												__html: detectUrlInMessage(caption),
											}}></div>
									) : messageType !== 'pdf' ? (
										<div
											style={message.messagePdfCaptionText}
											dangerouslySetInnerHTML={{
												__html: detectUrlInMessage(text),
											}}></div>
									) : null}
								</div>
								{!isDeleted && (isMyReply || chatType === 'individual') ? (
									<div style={styles.displayGrid}>
										<span
											style={{
												...message.messageDropdownList,
												opacity: this.state.iconStatus ? 1 : 0,
											}}>
											<MessageDropdown labelsAndContext={labelsAndContext} />
										</span>

										<span
											style={{
												...message.messageTime,
												opacity: this.state.iconStatus ? 0 : 1,
											}}>
											{timestamp}
										</span>
									</div>
								) : !isDeleted ? (
									<span style={message.messageDeleteTime}>{timestamp}</span>
								) : null}
								{isTicket ? (
									<span style={message.messageIsTicket}>
										This message is created as ticket
									</span>
								) : null}
							</div>
						</div>
					</div>
				) : type === 'log' ? (
					<div style={message.messageLogDiv}>
						<div style={message.messageLog}>
							<span style={message.messageLogText}>{text}</span>
						</div>
					</div>
				) : null}

				{this.state.imageShow && (
					<Lightbox
						style={{ zIndex: 999 }}
						mainSrc={mediaImage[photoIndex].uri.split(',')[0]}
						nextSrc={
							photoIndex < mediaImage.length - 1
								? mediaImage[
										(photoIndex + mediaImage.length - 1) % mediaImage.length
								  ].uri.split(',')[0]
								: undefined
						}
						prevSrc={
							photoIndex !== 0
								? mediaImage[(photoIndex + 1) % mediaImage.length].uri.split(',')[0]
								: undefined
						}
						onCloseRequest={() => this.setState({ imageShow: false })}
						onMovePrevRequest={() =>
							this.setState({
								photoIndex: (photoIndex + mediaImage.length - 1) % mediaImage.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								photoIndex: (photoIndex + 1) % mediaImage.length,
							})
						}
					/>
				)}
			</>
		)
	}
}

export default Message
