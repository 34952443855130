import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { MenuList, MenuItem, MenuButton, Dropdown } from 'react-menu-list'
import styles from './styles'

const { messageDropdown } = styles

const MenuItems = ({ labelsAndContext }) => {
	return (
		<div style={messageDropdown.menuItemDiv}>
			{Object.keys(labelsAndContext).map((label) => (
				<MenuItem key={label} style={messageDropdown.menuItemPadding}>
					<label style={messageDropdown.menuItem} onClick={labelsAndContext[label]}>
						{label}
					</label>
				</MenuItem>
			))}
		</div>
	)
}

class MessageDropdown extends React.Component {
	render() {
		const { labelsAndContext } = this.props

		return (
			<div>
				<MenuButton
					style={messageDropdown.messageDropdownButton}
					menu={
						<Dropdown>
							<MenuList>
								<MenuItems labelsAndContext={labelsAndContext} />
							</MenuList>
						</Dropdown>
					}>
					<IoIosArrowDown size={20} color="#666" />
				</MenuButton>
			</div>
		)
	}
}

export default MessageDropdown
