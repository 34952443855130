import React, { Component } from 'react'
import styles from './styles'

class ChatListSearch extends Component {
	render() {
		const { getSearchString, searchString } = this.props
		return (
			<div className="chatSearch" style={styles.positionRelative}>
				<input
					type="text"
					placeholder="Search using group/person name"
					onChange={(e) => {
						getSearchString({ searchString: e.target.value })
					}}
					value={searchString}
				/>
				<img
					src={require('./../images/searchicon.png')}
					alt={''}
					style={styles.chatListSearch.searchIcon}
				/>
			</div>
		)
	}
}

export default ChatListSearch
