import gql from 'graphql-tag'

export const GET_TASK_PROGRESS = gql`
	query getTaskProgress($startTime: Int!, $endTime: Int!, $loadCount: Int!) {
		getTaskProgress(startTime: $startTime, endTime: $endTime, loadCount: $loadCount) {
			headName
			taskEnd
			teamCount
			taskProgress {
				position
				progressStatus
				value {
					userName
					workingStatus
					plannedHours
					completedTask
					noOfTask
					taskLists {
						feedbackCount
						taskId
						projectName
						taskDuration
						taskName
						taskNotes
						taskStatus
						taskPercentage
						attachment
						taskImages
						addedAt
					}
					lastUpdate
				}
			}
		}
	}
`
export const ADD_FEEDBACK = gql`
	mutation addTaskFeedback($taskId: String!, $taskDescription: String!) {
		addTaskFeedback(taskId: $taskId, taskDescription: $taskDescription)
	}
`

export const GET_FEEDBACK_CHAT = gql`
	query feedbackChat($taskId: String!) {
		getFeedbackChat(taskId: $taskId) {
			addedBy
			addedAt
			profileUrl
			description
		}
	}
`
