// import react component
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Spin, Icon } from 'antd'
import TermsAndConditions from './TermsAndConditions'
import { GET_TERMS_AND_CONDITIONS } from '../query' // Import GraphQL Query
import './index.css' // Import component CSS

// Service scope component
class ServiceScopeConfig extends Component {
	// service scope callback function
	setTerms = (data) => {
		const { setServiceTermsAndCondition } = this.props
		// callback function
		setServiceTermsAndCondition(data)
	}

	render() {
		// Deconstruct props
		const { serviceTermsAndCondition, setServiceTermsAndCondition } = this.props

		const { termsJsonData } = serviceTermsAndCondition

		// Deconstruct service scope object

		return (
			<div className="serviceTermsAndConditions">
				{/** Here check if scope deliverable is already exist load the
				 *  previously existing data in scope tree component or otherwise
				 * get the data from backend and load that data in scopeTree component */}
				{!termsJsonData.length ? (
					// Get master scope details from backend
					<Query query={GET_TERMS_AND_CONDITIONS}>
						{({ data, loading }) => {
							if (loading) {
								return (
									// Spinner icon
									<Spin
										indicator={<Icon type="loading" className="iconSize" spin />}
										className="loaderIcon"
									/>
								)
							}
							if (!data.getTermsAndCondition) {
								return null
							}
							const { getTermsAndCondition } = data

							// Check service scope is exists or not
							if (!termsJsonData.length) {
								const { termsHtmlData, termsJsonData: jsonData } = getTermsAndCondition
								const result = {
									termsHtmlData,
									termsJsonData: jsonData,
								}
								setServiceTermsAndCondition(result)
							}
							// load service scope tree
							return (
								<TermsAndConditions
									termsAndCondition={getTermsAndCondition}
									setTerms={this.setTerms}
								/>
							)
						}}
					</Query>
				) : (
					// load service scope tree
					<TermsAndConditions
						termsAndCondition={serviceTermsAndCondition}
						setTerms={this.setTerms}
					/>
				)}
			</div>
		)
	}
}

// export ServiceScopeConfig component
export default ServiceScopeConfig
