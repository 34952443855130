import gql from 'graphql-tag';

// type - Mutation
// purpose - Create New service
export const CREATE_NEW_SERVICE_MUTATION = gql`
	mutation createNewService(
		$serviceName: String!
		$elasticPrice: String!
		$typologyId: String!
		$noOfBuilding: String!
		$noOfFloor: String!
		$noOfStage: String!
		$deliverableSelectedScope: String!
		$spaceDefineHtml: String!
		$spaceDefineJson: String!
		$stageDeliverableTarget: String!
		$stageDeliverableSource: String!
		$feeHeadScope: String!
		$serviceScopeDeliverable: String!
		$deliverableScope: String!
		$deliverableSelectedId: String!
		$deliverableScopeSelectedId: String!
	) {
		createNewService(
			serviceName: $serviceName
			elasticPrice: $elasticPrice
			typologyId: $typologyId
			noOfFloor: $noOfFloor
			noOfStage: $noOfStage
			noOfBuilding: $noOfBuilding
			deliverableSelectedScope: $deliverableSelectedScope
			spaceDefineHtml: $spaceDefineHtml
			spaceDefineJson: $spaceDefineJson
			stageDeliverableTarget: $stageDeliverableTarget
			stageDeliverableSource: $stageDeliverableSource
			feeHeadScope: $feeHeadScope
			serviceScopeDeliverable: $serviceScopeDeliverable
			deliverableScope: $deliverableScope
			deliverableSelectedId: $deliverableSelectedId
			deliverableScopeSelectedId: $deliverableScopeSelectedId
		)
	}
`;

//type - Query
//purpose - get typology list
export const GET_MASTER_TYPOLOGY_QUERY = gql`
	query getMasterTypology {
		getMasterTypology {
			typologyId
			typologyName
		}
	}
`;

//type - Query
//purpose -  get scope list
export const GET_MASTER_SCOPE_QUERY = gql`
	query getServiceMasterScope {
		getServiceMasterScope {
			scopeId
			key
			title
			scopeType
			spaceLevel
			scopeKey
			children {
				scopeId
				key
				title
				scopeType
				spaceLevel
				scopeKey
				children {
					scopeId
					key
					title
					scopeType
					spaceLevel
					scopeKey
				}
			}
		}
	}
`;
