/**
  Archived Component
  This component will render display Archived project data 
* */

/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Spin, Icon, Button, Checkbox, notification } from 'antd'

// Query component of React Apollo to fetch data
import { Query, Mutation } from 'react-apollo'
// Import Override CSS
import './index.css'
// Query to fetch archived project Details
import { GET_PROJECT, PROJECT_STATUS_CHANGE } from '../query'

// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'

/**
 * This class component is used to display archived project data in webapp.
 * @returns {Archived project}
 */
class Archived extends React.Component {
	// initial states
	state = {
		projectUrl: [],
		loadCount: 1,
	}

	/**
	 *  Sets the selection value when change a check box
	 */
	handleChange = (value) => {
		// Deconstructing the state
		const { projectUrl } = this.state
		if (projectUrl.includes(value)) {
			const filteredArray = projectUrl.filter((item) => item !== value)
			this.setState({ projectUrl: filteredArray })
		} else {
			this.setState({ projectUrl: [...projectUrl, value] })
		}
	}

	// archived button close
	buttonClose = () => {
		this.setState({
			projectUrl: [],
		})
	}

	// image upload modal success notification
	archivedNotification = () => {
		notification.success({
			placement: 'bottomRight',
			message: 'projects Unarchived Successfully',
		})
	}

	/**
	 *  check wheather the page reach the bottom of page
	 *  And fetchmore the data
	 */
	handleScroll = (e, fetchMore, data) => {
		const element = e.target
		// Deconstructing the state
		const { loadCount, isLoaded } = this.state

		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			if (isLoaded) {
				return
			}
			const datas = loadCount + 1
			this.setState({ loadCount: datas })
			fetchMore({
				variables: {
					offset: data.GetProject.length,
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev
					if (fetchMoreResult.GetProject.length < 50) {
						this.setState({ isLoaded: true })
					}
					return {
						...prev,
						GetProject: [...prev.GetProject, ...fetchMoreResult.GetProject],
					}
				},
			})
		}
	}

	/**
	 * It should be excecute first
	 * This is used for display unarchived data.
	 * @returns {unarchived data}
	 */
	/**
	 * This function is used to display a unarchived data.
	 * It contain project name and unarchived data.
	 * @returns {unarchived data}
	 */

	render() {
		// Deconstructing the state
		const { projectUrl, isLoaded, loadCount } = this.state

		// Deconstructing the props
		const { searchString } = this.props

		return (
			<Query
				query={GET_PROJECT}
				variables={{
					statusVisibilty: 'hide',
					limit: 50,
					offset: 0,
					searchString,
				}}>
				{({
					data: archivedData,
					loading: archivedLoading,
					error: archivedError,
					fetchMore,
					refetch,
					networkStatus,
				}) => {
					if (archivedLoading && networkStatus !== 2) {
						// loading -> it handles a loading data status
						return (
							<Spin
								indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
								style={styles.loadIconStyle}
							/>
						)
					}
					if (archivedError) {
						return <span>Some error </span>
					}

					// console.log(archivedData)

					return (
						<div
							style={styles.archived}
							onScroll={(e) => this.handleScroll(e, fetchMore, archivedData)}>
							{/* {projectUrl.length === 0 ? null : (
								
              )} */}
							<div style={styles.header}> Archived Projects </div>
							<div style={styles.content}>
								{/* archived data looping start */}
								{archivedData.GetProject.filter(
									(temp) =>
										temp.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
								).map((data, index) => {
									return (
										<div
											className="archivedDataHover"
											style={styles.archivedData}
											key={index}>
											<Checkbox
												value={data.url}
												checked={!projectUrl.includes(data.url)}
												onChange={() => this.handleChange(data.url)}>
												{data.name}
											</Checkbox>
										</div>
									)
								})}
							</div>
							{isLoaded && loadCount !== 0 ? (
								<div style={styles.noprojectShow}>No more active project to show.</div>
							) : null}
							{projectUrl.length === 0 ? (
								<div style={styles.buttonDiv}>
									<Button
										type="primary"
										disabled
										style={styles.sendButton}
										onChange={() => this.popOver()}>
										Remove From Archive
									</Button>
								</div>
							) : (
								<Mutation mutation={PROJECT_STATUS_CHANGE}>
									{(ProjectStatusChange, { loading: changeLoading }) => {
										return (
											<div style={styles.buttonDiv}>
												<Button
													type="primary"
													loading={changeLoading}
													style={styles.sendButton}
													onClick={async () => {
														if (changeLoading) {
															return
														}
														const variables = {
															projectUrls: projectUrl,
															newStatus: 'show',
														}
														await ProjectStatusChange({
															variables,
															// awaitRefetchQueries: true,
															// refetchQueries: [
															// 	{
															// 		query: GET_PROJECT,
															// 		variables: {
															// 			statusVisibilty: 'show',
															// 			limit: 25 * loadCount,
															// 			offset: 0,
															// 			searchString,
															// 		},
															// 	},
															// 	{
															// 		query: GET_PROJECT,
															// 		variables: {
															// 			statusVisibilty: 'hide',
															// 			limit: 25 * loadCount,
															// 			offset: 0,
															// 			searchString,
															// 		},
															// 	},
															// ],
														})
														await refetch({
															statusVisibilty: 'show',
															limit: 50 * loadCount,
															offset: 0,
															searchString,
														})
														await refetch({
															statusVisibilty: 'hide',
															limit: 50 * loadCount,
															offset: 0,
															searchString,
														})
														this.buttonClose()
														this.archivedNotification()
													}}>
													Remove From Archive
												</Button>
											</div>
										)
									}}
								</Mutation>
							)}
						</div>
					)
				}}
			</Query>
		)
	}
}

export default Archived
