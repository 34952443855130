import gql from 'graphql-tag'

export const GET_DESIGN_PROCESS = gql`
	query getDesignProcess {
		getDesignProcess {
			name
			feeheadKey
			processList {
				processId
				processName
				processMedia
				processDescription
			}
		}
	}
`

export const DELETE_DESIGN_PROCESS = gql`
	mutation deleteDesignProcess($processId: String!) {
		deleteDesignProcess(processId: $processId)
	}
`

export const UPLOAD_DESIGN_FILES = gql`
	mutation uploadDesignFile($files: [Upload!]!) {
		uploadDesignFile(files: $files) {
			url
		}
	}
`

export const INSERT_DESIGN_PROCESS = gql`
	mutation insertDesignProcess(
		$processId: String!
		$processName: String!
		$processDescription: String!
		$processMedia: String!
	) {
		insertDesignProcess(
			processId: $processId
			processName: $processName
			processDescription: $processDescription
			processMedia: $processMedia
		) {
			status
			type
		}
	}
`
