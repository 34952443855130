const styles = {
	rateButtonStyle: {
		height: window.innerWidth < 991 ? '16vmin' : '10vmin',
		width: window.innerWidth < 991 ? '18vmin' : '12vmin',
		borderColor: '#eee0',
		borderRadius: 10,
	},
	rateImage: {
		height: window.innerWidth < 991 ? '15vmin' : '10vmin',
		width: window.innerWidth < 991 ? '15vmin' : '10vmin',
	},
	rateButtonText: {
		textAlign: 'center',
		color: '#4c4c4c',
		marginTop: 15,
		textTransform: 'capitalize',
	},
}

export default styles
