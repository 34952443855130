import gql from 'graphql-tag'

export const getUserHash = gql`
	# query userHash {
	# 	userHash
	# }
  query userHash {
		userHash{
      name
      hash
      photo
      role
    }
	}
`

// return project photo data
export const SitePhotosQuery = gql`
	query ProjectPhotos(
		$projectUrl: String!
		$hash: String!
		$limit: Int!
		$offset: Int!
		$role: String!
		$spaceId: String!
	) {
		projectPhotos(
			projectUrl: $projectUrl
			hash: $hash
			limit: $limit
			offset: $offset
			spaceId: $spaceId
			role: $role
		) {
			date
			feeds {
				key
				uri
				title
				description
				feedId
				imageId
				projectName
				uploadedBy
				uploadedAt
				clientDisplayStatus
				space
				spaceId
				thumbnailUri
				qualityUri
				commentCount
        isViewed
				uploadedProfile {
					name
					photo
				}
			}
		}
	}
`


// return photo comment data
export const GET_COMMENTS = gql`
	query photoComments(
		$hash: String!
		$type: String!
		$feedId: String
		$imageId: String
		$productId: String
		$variantId: String
		$spaceId: String
		$role: String!
		$status: String!
	) {
		photoComments(
			hash: $hash
			type: $type
			feedId: $feedId
			imageId: $imageId
			productId: $productId
			variantId: $variantId
			spaceId: $spaceId
			role: $role
			status: $status
		) {
			id
			text
			status
			type
			commentType
			feedId
			imageId
			productId
			variantId
			spaceId
			commentedAt
			commentedBy
			isDeleted
			commentedProfile {
				name
				photo
			}
			childComments {
				type
				id
				text
				isDeleted
				feedId
				imageId
				commentedAt
				commentedBy
				commentedProfile {
					name
					photo
				}
			}
		}
	}
`

export const spaces = gql`
	query spaces($projectUrl: String!) {
		spaces(projectUrl: $projectUrl) {
			id
			name
		}
	}
`

export const closedCount = gql`
	query closedPhotoCommentsCount(
  $type:String!,
  $productId:String,
  $variantId:String,
  $spaceId: String,
  $feedId: String!, 
  $imageId: String!,
  $status: String!, 
) {
		closedPhotoCommentsCount(
      type:$type,
      productId:$productId,
			variantId:$variantId,
  		spaceId: $spaceId,
      feedId: $feedId,  
      imageId: $imageId,
      status: $status)
	}
`

export const ADDCOMMENT = gql`
	mutation addComment(
		$projectUrl: String!
		$hash: String!
		$text: String!
		$level: String!
		$type: String
		$commentType: String
		$productId: String
		$variantId: String
		$spaceId: String
		$feedId: String
		$imageId: String
		$parentId: String
		$mentionedTo: [String]
	) {
		addComment(
			projectUrl: $projectUrl
			hash: $hash
			text: $text
			level: $level
			type: $type
			commentType: $commentType
			productId: $productId
			variantId: $variantId
			spaceId: $spaceId
			feedId: $feedId
			imageId: $imageId
			parentId: $parentId
			mentionedTo: $mentionedTo
		) {
			id
			text
			status
			type
			commentType
			feedId
			imageId
			productId
			variantId
			spaceId
			commentedAt
			commentedBy
			isDeleted
			commentedProfile {
				name
				photo
			}
			childComments {
				id
				text
				isDeleted
				feedId
				imageId
				commentedAt
				commentedBy
				commentedProfile {
					name
					photo
				}
			}
		}
	}
`


export const COMMENTS_SUBSCRIPTION = gql`
	
  subscription commentCreated(
  $feedId: String, 
  $imageId: String,
	$productId: String,
  $variantId: String,
  $spaceId: String
) {
		commentCreated(
      feedId: $feedId, 
      imageId: $imageId,
      productId:  $productId,
      variantId: $variantId,
      spaceId: $spaceId
    ) {
			id
			text
			status
			type
			commentType
			feedId
			imageId
			productId
			variantId
			spaceId
			commentedAt
			commentedBy
			isDeleted
			commentedProfile {
				name
				photo
			}
			childComments {
				id
				text
				isDeleted
				feedId
				imageId
				commentedAt
				commentedBy
				commentedProfile {
					name
					photo
				}
			}
		}
	}
`

export const PHOTO_SUBSCRIPTION = gql`
  subscription photosSubscription(
    $projectUrl: String, 
  ) {
      photosSubscription(
        projectUrl: $projectUrl
      ) {
        projectUrl
      }
    }
  `



export const editDescription = gql`
	mutation editDescription($newDesciption: String!, $feedId: String!, $imageId: String!) {
		editDescription(newDesciption: $newDesciption, feedId: $feedId, imageId: $imageId)
	}
`

export const Upload = gql`
mutation post(
		$projectUrl: String!
		$projectName: String!
		$photos: [Upload!]!
		$description: [Description]
		$spaces: [Space!]
		$topics: [Topic!]
		$user: User!
	) {
		post(
			projectUrl: $projectUrl
			projectName: $projectName
			photos: $photos
			description: $description
			spaces: $spaces
			topics: $topics
			user: $user
		) {
			key
			uri
			title
			description
			feedId
			imageId
			projectName
			uploadedBy
			uploadedAt
			clientDisplayStatus
			space
			spaceId
			thumbnailUri
			qualityUri
			commentCount
			uploadedProfile {
				name
				photo
			}
		}
	}
`

export const CHANGE_COMMENT = gql`
	mutation changeCommentType($commentId: String!, $type: String!) {
		changeCommentType(commentId: $commentId, type: $type)
	}
`

export const DELETE_COMMENT = gql`
	mutation deleteComment(
  $hash: String!,
	$commentId: String!,
	$level: String,
	$id: String
) {
		deleteComment(
      hash: $hash
			commentId: $commentId
			level: $level
			id: $id)
	}
`
