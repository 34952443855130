/**
 * DesignQube - Chat web app
 */
import React, { Component, Fragment } from 'react'
import { ApolloProvider } from 'react-apollo'
import ChatList from './ChatList'
import ChatHeader from './ChatHeader'
import ChatFooter from './ChatFooter'
import ChatBody from './ChatBody'
import ViewDesignQube from './presentational/ViewDesignQube'
import newClient from '../../service/mobileAppBackend'
import ModalDialog from './ModalDialog'
import { Query, withApollo } from 'react-apollo'
import {
	GET_CHAT_DETAILS,
	POST_MESSAGE,
	GET_USER_HASH,
	UPLOAD_CHAT_IMAGE,
	GET_CHAT_LIST,
	LIST_GROUP_MEMBER,
	ADD_OR_EDIT_GROUP_DESCRIPTION,
	EDIT_GROUP_NAME,
	GET_GROUP_DESCRIPTION,
	GET_ARCHIEVED_LIST,
	INSERT_ONE_SIGNAL_TOKEN,
	GET_MEDIA,
} from './query'
import { Spin, Icon } from 'antd'
import { optimizeImageResize } from './utils'
import { notification } from 'antd'
import 'react-image-lightbox/style.css'
import styles from './styles'
import './index.css'

//Create ChatBox class component
class ChatBox extends Component {
	constructor(props) {
		super(props)
		this.state = {
			chatHistoryVisible: false,
			chatListRefetch: null,
			groupId: null,
			selectedContactChat: [],
			chatType: null,
			showModalDialog: false,
			modalDialogType: null,
			imageDataUrl: null,
			fileType: null,
			fileName: null,
			hash: null,
			file: [],
			toHash: null,
			loader: false,
			searchString: '',
			conversationType: null,
			messageData: null,
			groupMember: [],
			reply: false,
			footerInput: '',
			isArchived: false,
			copyPasteStatus: false,
			mediaImage: [],
		}

		this.insertOneSignalToken = this.insertOneSignalToken.bind(this)
	}

	//Initially get userhash and set that hash in state
	async componentWillMount() {
		// Deconstruct props
		const { client } = this.props

		// Query -  Get user hash query
		const { data } = await client.query({
			query: GET_USER_HASH,
		})

		//setState
		this.setState({
			hash: data.userHash.hash,
		})
	}

	//Here check if notification is already enabled or not
	/**
	 * If notification is not enable,
	 * Generate a onesignal token using appId and subdomain name and insert in DB or
	 * Otherwise not
	 */
	componentDidMount() {
		let that = this

		// Get notification status in localStorage
		const enableNotification = localStorage.getItem('enableNotification')
		if (!enableNotification) {
			var OneSignal = window.OneSignal || []
			OneSignal.push(function() {
				//Initialise OneSignal
				OneSignal.init({
					appId: 'd4f6c132-3a90-4b8a-a13d-0a69914ad806',
					subdomainName: 'designqube-app',
				})
				//Get userId or token from oneSignal
				OneSignal.getUserId(async (userId) => {
					if (userId) {
						that.insertOneSignalToken(userId)
					}
				})
			})
		}
	}

	//on paste screenshot image
	onPasteScreenShotImage = function(e) {
		if (e.clipboardData) {
			let items = e.clipboardData.items
			if (!items) return

			//access data directly

			for (let i = 0; i < items.length; i++) {
				if (items[i].type.indexOf('image') !== -1) {
					//image
					const { name, type } = items[i].getAsFile()
					const selectedFile = items[i].getAsFile()
					const fileType = ['image/jpeg', 'application/pdf', 'image/png']
					if (fileType.includes(type)) {
						let reader = new FileReader()
						reader.onloadend = () => {
							const fileExtension = type.split('/')[1]
							const fileExtensionArray = ['jpeg', 'jpg', 'png']
							//Generate a JSON object
							const data = {
								uri: reader.result,
								fileType: fileExtensionArray.includes(fileExtension) ? 'image' : 'pdf',
								fileName: name,
								file: [selectedFile],
							}

							this.setState({ copyPasteStatus: true })
							// Show image preview modal
							this.showImagePreview(data)
						}
						reader.readAsDataURL(selectedFile)
					}
				}
			}
		}
	}

	/**
	 * Function    - insertOneSignalToken()
	 * Description - Insert onesignal token or userId in DB
	 * Params      - contains userId or token
	 * Return      - null
	 *  */
	insertOneSignalToken = async (userId) => {
		// Deconstruct props
		const { client } = this.props

		//Mutation - Insert one signal userId or token mutation
		const { data } = await client.mutate({
			mutation: INSERT_ONE_SIGNAL_TOKEN,
			variables: {
				token: userId,
			},
		})
		if (data.insertChatOneSignalToken) {
			// Set notification status in localStorage
			localStorage.setItem('enableNotification', true)
		}
	}

	/**
	 * Function    - showImagePreview()
	 * Description - Show image preview modal
	 * Params      - contains uri, file type, file name and image file details
	 * Return      - null
	 *  */
	showImagePreview = (data) => {
		// Deconstruct object
		const { uri, fileType, fileName, file } = data

		// setState
		this.setState({
			showModalDialog: true,
			modalDialogType: 'imagePreview',
			imageDataUrl: uri,
			fileType,
			fileName,
			file,
		})
	}

	/**
	 * Function    - showCreateTicketModal()
	 * Description - Show create ticket modal preview
	 * Params      - contains ticket message,ticket caption, message id and message data
	 * Return      - null
	 *  */
	showCreateTicketModal = (data) => {
		// Deconstruct params
		const { messageText, messageCaption, messageId, messageData } = data
		// setState
		this.setState({
			showModalDialog: true,
			modalDialogType: 'createTicket',
			messageText,
			messageCaption,
			messageId,
			messageData,
		})
	}

	/**
	 * Function    - showForwardMessageModal()
	 * Description - Show message forward modal
	 * Params      - contains message id and message data
	 * Return      - null
	 *  */
	showForwardMessageModal = (data) => {
		// Deconstruct params
		const { messageId, messageData } = data
		// setState
		this.setState({
			showModalDialog: true,
			messageData,
			modalDialogType: 'forwardMessage',
			messageId,
		})
	}

	/**
	 * Function    - showMessageInfo()
	 * Description - View message info
	 * Params      - contains message id and message data
	 * Return      - null
	 *  */
	showMessageInfo = (data) => {
		// Deconstruct params
		const { messageId, messageData } = data
		// setState
		this.setState({
			showModalDialog: true,
			messageData,
			modalDialogType: 'messageInfo',
			messageId,
		})
	}

	/**
	 * Function    - showDeleteMessageModal()
	 * Description - Show delete message popup(Confirmation popup)
	 * Params      - contains message id
	 * Return      - null
	 *  */
	showDeleteMessageModal = (data) => {
		// Deconstruct params
		const { messageId } = data
		// setState
		this.setState({
			showModalDialog: true,
			modalDialogType: 'deleteMessage',
			messageId,
		})
	}

	/**
	 * Function    - showMessageReply()
	 * Description - Show message reply modal
	 * Params      - contains message data
	 * Return      - null
	 *  */
	showMessageReply = (data) => {
		// Deconstruct params
		const { messageData } = data
		// setState
		this.setState({
			reply: true,
			messageData,
		})
	}

	/**
	 * Function    - editGroupInfo()
	 * Description - Edit group information
	 * Params      - contains group details
	 * Return      - null
	 *  */
	editGroupInfo = (data) => {
		// Deconstruct params
		const { selectedContact } = data
		// setState
		this.setState({
			showModalDialog: true,
			modalDialogType: 'editGroupInfo',
			selectedContactChat: selectedContact,
		})
	}

	/**
	 * Function    - makeGroupAdmin()
	 * Description - Add group admin mutation
	 * Params      - contains group details and makeGroupAdmin mutation
	 * Return      - null
	 *  */
	makeGroupAdmin = async (value) => {
		// Deconstruct params
		const { groupDetails, makeGroupAdmin } = value
		const { hash } = this.state
		const { groupId, adminStatus, members } = groupDetails

		// Mutation - Make group admin mutation
		const { data } = await makeGroupAdmin({
			variables: {
				hash: hash,
				members: members,
				groupId,
				adminStatus,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: LIST_GROUP_MEMBER,
					variables: {
						hash: hash,
						groupId: groupId,
					},
				},
				{
					query: GET_CHAT_DETAILS,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						groupId,
					},
				},
			],
		})

		if (data.makeAdmin) {
			// Group admin added or removed notification
			notification.success({
				message: adminStatus === 'true' ? `Group admin added.` : ' Group admin removed.',
				placement: 'bottomLeft',
			})
		}
	}

	/**
	 * Function    - removeGroupMember()
	 * Description - Remove group members in Group
	 * Params      - contains group details and removeGroupMember mutation
	 * Return      - null
	 *  */
	removeGroupMember = async (value) => {
		// Deconstruct params
		const { groupDetails, removeGroupMember } = value
		const { hash } = this.state
		const { groupId, members } = groupDetails

		// Mutation -  Remove group members mutation
		const { data } = await removeGroupMember({
			variables: {
				hash: hash,
				members: members,
				groupId,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: LIST_GROUP_MEMBER,
					variables: {
						hash: hash,
						groupId: groupId,
					},
				},
				{
					query: GET_CHAT_DETAILS,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						groupId,
					},
				},
			],
		})

		if (data.removeGroupMembers) {
			// Group member removed success notification
			notification.success({
				message: `Group member removed.`,
				placement: 'bottomLeft',
			})
		}
	}

	/**
	 * Function    - showModalDialogPopup()
	 * Description - Show popup or modal based on type(createTicket,forwardMessage,deleteMessage,showMessageReply...)
	 * Params      - contains which type of modal is show
	 * Return      - modalPopup
	 *  */
	showModalDialogPopup = (data) => {
		// Deconstruct params
		const { type } = data

		switch (type) {
			case 'createTicket':
				return this.showCreateTicketModal(data)

			case 'forwardMessage':
				return this.showForwardMessageModal(data)

			case 'deleteMessage':
				return this.showDeleteMessageModal(data)

			case 'showMessageReply':
				return this.showMessageReply(data)

			case 'editGroupInfo':
				return this.editGroupInfo(data)

			case 'makeGroupAdmin':
				return this.makeGroupAdmin(data)

			case 'removeGroupMember':
				return this.removeGroupMember(data)

			case 'showMessageInfo':
				return this.showMessageInfo(data)
		}
	}

	/**
	 * Function    - handleModalCancel()
	 * Description - Close modal popup
	 * Params      - null
	 * Return      - null
	 *  */
	handleModalCancel = () => {
		// setState
		this.setState({
			showModalDialog: false,
			modalDialogType: null,
			imageDataUrl: null,
			fileType: null,
			fileName: null,
			groupMember: [],
			copyPasteStatus: false,
		})
	}

	/**
	 * Function    - createNewTicket()
	 * Description - Refetch mutation after create new ticket
	 * Params      - contains refetch data
	 * Return      - null
	 *  */
	createNewTicket = (data) => {
		// Deconstruct params
		const { refetch } = data
		refetch()
		// setState
		this.setState({
			showModalDialog: false,
			modalDialogType: null,
			file: [],
		})

		// Create ticket success notification
		notification.success({
			message: `Ticket created.`,
			placement: 'bottomLeft',
		})
	}

	/**
	 * Function    - startNewConversation()
	 * Description - Set new conversation user details in state
	 * Params      - contains user details
	 * Return      - null
	 *  */
	startNewConversation = (data) => {
		// Deconstruct params
		const { item } = data
		const { groupId, hash } = item

		// setState
		this.setState({
			groupId: groupId,
			toHash: hash,
			selectedContactChat: item,
			chatType: 'individual',
			modalDialogType: null,
			showModalDialog: false,
			conversationType: 'new',
		})
	}

	/**
	 * Function    - uploadChatFile()
	 * Description - Upload chat file(Image or PDF)
	 * Params      - contains file details and file caption
	 * Return      - null
	 *  */
	uploadChatFile = async (data) => {
		//setState
		this.setState({ loader: true })
		// Deconstruct params
		const { file, groupId, hash, toHash } = this.state
		const { refetch, caption, fileType } = data

		//Here check if file is exist or not
		if (file.length) {
			if (fileType === 'image') {
				const { qualityImageArray, thumbnailImageArray } = await optimizeImageResize(file)

				// Generate Quality image url
				const qualityImage = await newClient.mutate({
					mutation: UPLOAD_CHAT_IMAGE,
					variables: {
						files: qualityImageArray,
					},
				})

				// Generate thumbnail image url
				const thumbnailImage = await newClient.mutate({
					mutation: UPLOAD_CHAT_IMAGE,
					variables: {
						files: thumbnailImageArray,
					},
				})

				//Get image url
				const uploadedQualityImage = qualityImage.data.uploadChatImage
				const uploadedThumbnailImage = thumbnailImage.data.uploadChatImage

				const feed = uploadedQualityImage.map((i, index) => ({
					thumbnailImage: uploadedThumbnailImage[index],
					qualityUri: uploadedQualityImage[index],
					description: caption,
					spaces: [],
				}))

				const variables = {
					from: hash,
					to: toHash || '',
					groupId,
					message: '',
					mentionedTo: [],
					messageType: 'image',
					replied: '',
					feed,
					pdfCaption: '',
				}

				//Mutation - Post image message
				const messageResult = await newClient.mutate({
					mutation: POST_MESSAGE,
					variables: variables,
				})

				//Here check message send status
				if (messageResult.data.createMessage.message) {
					refetch()

					const { message } = messageResult.data.createMessage
					const { caption, content, id: msgId } = message
					const newMediaObject = {
						thumbnailUri: content.split(',')[1],
						uri: content.split(',')[0],
						caption,
						msgId,
					}

					const { mediaImage } = this.state
					const newMediaImage = [...mediaImage, newMediaObject]

					// setState
					this.setState({
						showModalDialog: false,
						modalDialogType: null,
						copyPasteStatus: false,
						mediaImage: newMediaImage,
					})

					// Image sent success notification
					notification.success({
						message: `Image Sent.`,
						placement: 'bottomLeft',
					})
				}
			} else {
				// Generate PDF url
				const pdfUrl = await newClient.mutate({
					mutation: UPLOAD_CHAT_IMAGE,
					variables: {
						files: file,
					},
				})

				const variables = {
					from: hash,
					to: toHash || '',
					groupId,
					message: pdfUrl.data.uploadChatImage[0],
					mentionedTo: [],
					messageType: 'pdf',
					replied: '',
					feed: [],
					pdfCaption: file[0].name,
				}

				// Mutation - Post pdf message
				const messageResult = await newClient.mutate({
					mutation: POST_MESSAGE,
					variables: variables,
				})

				//Here check message send status
				if (messageResult.data.createMessage.message) {
					refetch()
					// setState
					this.setState({
						showModalDialog: false,
						modalDialogType: null,
						copyPasteStatus: false,
					})

					// PDF sent success notification
					notification.success({
						message: `PDF Sent.`,
						placement: 'bottomLeft',
					})
				}
			}
		}
		//setState
		this.setState({ loader: false })
	}

	/**
	 * Function    - addGroupParticipant()
	 * Description - Add group participation modal
	 * Params      - null
	 * Return      - null
	 *  */
	addGroupParticipant = () => {
		// setState
		this.setState({
			modalDialogType: 'addGroupParticipant',
			showModalDialog: true,
		})
	}

	/**
	 * Function    - deleteMessage()
	 * Description - Delete message after refetch
	 * Params      - contains refetch data
	 * Return      - null
	 *  */

	deleteMessage = (data) => {
		// Deconstruct params
		const { refetch } = data
		refetch()
		// setState
		this.setState({
			showModalDialog: false,
			modalDialogType: null,
		})
		// Delete message notification
		notification.success({
			message: `Message deleted.`,
			placement: 'bottomLeft',
		})
	}

	/**
	 * Function    - forwardMessage()
	 * Description - Message forward to group or individual
	 * Params      - contains forward message details
	 * Return      - null
	 *  */

	forwardMessage = async (value) => {
		this.setState({ loader: true })
		// Deconstruct state
		const { hash } = this.state
		// Deconstruct params
		const { forwardMessage, messageData, selectedId } = value
		const { messageType, content, pdfCaption } = messageData

		// Mutation - forward message mutation
		const { data } = await forwardMessage({
			variables: {
				type: messageType,
				content,
				pdfCaption,
				forwardGroup: selectedId,
				hash,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_CHAT_LIST,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						searchString: '',
					},
				},
			],
		})

		if (data.forwardMessage) {
			// setState
			this.setState({
				showModalDialog: false,
				modalDialogType: null,
				loader: false,
			})

			// Forward message sent notification
			notification.success({
				message: `Message forwarded.`,
				placement: 'bottomLeft',
			})
		}
	}

	/**
	 * Function    - addNewGroup()
	 * Description - Create New group in chat
	 * Params      - contains group name, group description, group type, project url,group members, group picture
	 * Return      - null
	 *  */
	addNewGroup = async (value) => {
		this.setState({ loader: true })
		// Deconstruct state
		const { hash } = this.state
		// Deconstruct params
		const {
			addNewGroup,
			groupName,
			groupDesc,
			groupType,
			projectUrl,
			groupMember,
			groupPicture,
		} = value

		// Mutation -  create new group mutation
		const { data } = await addNewGroup({
			variables: {
				hash,
				members: groupMember.map((a) => a.hash),
				isNew: true,
				groupId: '',
				groupName,
				groupDesc,
				groupType,
				projectUrl,
				groupPicture,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_CHAT_LIST,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						searchString: '',
					},
				},
			],
		})
		if (data.addGroupMembers) {
			// setState
			this.setState({
				showModalDialog: false,
				modalDialogType: null,
				loader: false,
			})

			//Group create success notification
			notification.success({
				message: `Group created.`,
				placement: 'bottomLeft',
			})
		}
	}

	/**
	 * Function    - handleModalBack()
	 * Description - Change previous modal popup
	 * Params      - contains modalType
	 * Return      - null
	 *  */
	handleModalBack = (data) => {
		// Deconstruct params
		const { modalType } = data
		// setState
		this.setState({ modalDialogType: modalType })
	}

	/**
	 * Function    - showAddNewGroupModalDialog()
	 * Description - SHow new group creating modal popup
	 * Params      - contains modalType and group members
	 * Return      - null
	 *  */
	showAddNewGroupModalDialog = (data) => {
		// Deconstruct params
		const { groupMember, type } = data
		// setState
		this.setState({
			modalDialogType: type,
			groupMember,
		})
	}

	/**
	 * Function    - editGroupParticipant()
	 * Description - Edit group members
	 * Params      - contains group members
	 * Return      - null
	 *  */
	editGroupParticipant = (data) => {
		// Deconstruct params
		const { groupMember } = data
		// setState
		this.setState({
			showModalDialog: true,
			modalDialogType: 'editGroupParticipant',
			groupMember,
		})
	}

	/**
	 * Function    - updateGroupParticipant()
	 * Description - Update group members
	 * Params      - contains group members and group id
	 * Return      - null
	 *  */
	updateGroupParticipant = async (value) => {
		// setState
		this.setState({ loader: true })

		// Deconstruct state
		const { hash } = this.state
		// Deconstruct params
		const { addNewGroup, groupMember, groupId } = value

		//Mutation - create new group mutation
		const { data } = await addNewGroup({
			variables: {
				hash,
				members: groupMember.map((a) => a.hash),
				isNew: false,
				groupId,
				groupName: '',
				groupDesc: '',
				groupType: '',
				groupPicture: [],
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_CHAT_LIST,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						searchString: '',
					},
				},
				{
					query: GET_CHAT_DETAILS,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						groupId,
					},
				},
				{
					query: LIST_GROUP_MEMBER,
					variables: {
						hash,
						groupId,
					},
				},
			],
		})
		if (data.addGroupMembers) {
			// setState
			this.setState({
				showModalDialog: true,
				modalDialogType: 'editGroupInfo',
				loader: false,
			})

			// Group members added success notification
			notification.success({
				message: `Group members added.`,
				placement: 'bottomLeft',
			})
		}
	}

	/**
	 * Function    - handleModalNext()
	 * Description - Change next modal popup
	 * Params      - contains modalType
	 * Return      - null
	 *  */
	handleModalNext = (data) => {
		// Deconstruct params
		const { type } = data
		switch (type) {
			case 'addNewGroup':
				return this.showAddNewGroupModalDialog(data)
		}
	}

	/**
	 * Function    - updateGroupData()
	 * Description - Update group details
	 * Params      - contains group description, group id, group name
	 * Return      - null
	 *  */
	updateGroupData = async (data) => {
		this.setState({ loader: true })
		// Deconstruct params
		const {
			groupName,
			groupDescription,
			groupId,
			groupNameStatus,
			groupDescriptionStatus,
		} = data
		// Deconstruct state
		const { hash } = this.state

		if (groupNameStatus) {
			//Mutation - Update group details mutation
			const updateGroupName = await newClient.mutate({
				mutation: EDIT_GROUP_NAME,
				variables: {
					groupId,
					groupName,
					hash,
				},
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_CHAT_DETAILS,
						variables: {
							hash: hash,
							limit: 12,
							offset: 0,
							groupId,
						},
					},
					{
						query: GET_CHAT_LIST,
						variables: {
							hash: hash,
							limit: 12,
							offset: 0,
							searchString: '',
						},
					},
				],
			})

			if (updateGroupName.data.groupNameEdit) {
				// setState
				this.setState({ loader: false })

				// Group name updated success notification
				notification.success({
					message: `Group name updated.`,
					placement: 'bottomLeft',
				})
			}
		}
		if (groupDescriptionStatus) {
			//Mutation - Update group description mutation
			const updateGroupDescription = await newClient.mutate({
				mutation: ADD_OR_EDIT_GROUP_DESCRIPTION,
				variables: {
					groupId,
					description: groupDescription,
					hash,
				},
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_GROUP_DESCRIPTION,
						variables: {
							groupId: groupId,
						},
					},
					{
						query: GET_CHAT_DETAILS,
						variables: {
							hash: hash,
							limit: 12,
							offset: 0,
							groupId,
						},
					},
				],
			})

			if (updateGroupDescription.data.addOrEditGroupDescription) {
				// setState
				this.setState({
					loader: false,
				})

				// Group description updated success notification
				notification.success({
					message: `Group description updated.`,
					placement: 'bottomLeft',
				})
			}
		}
	}

	/**
	 * Function    - archieveMember()
	 * Description - Archieve group or individual
	 * Params      - contains selected group or individual, refetch
	 * Return      - null
	 *  */
	archieveMember = async (value) => {
		// Deconstruct params
		const { refetch, selectedMember, archieveChat } = value
		const { id: groupId } = selectedMember
		// Deconstruct state
		const { hash } = this.state

		//Mutation - Archive chat mutation
		const { data } = await archieveChat({
			variables: {
				hash: hash,
				archiveValue: 'true',
				groupId,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_CHAT_LIST,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						searchString: '',
					},
				},
				{
					query: GET_ARCHIEVED_LIST,
					variables: {
						hash,
					},
				},
			],
		})
		if (data.archieveChat) {
			// setState
			this.setState({
				showModalDialog: false,
				modalDialogType: null,
			})

			// Archived success notification
			notification.success({
				message: `Archived successfully`,
				placement: 'bottomLeft',
			})
		}
	}

	/**
	 * Function    - unArchiveMember()
	 * Description - Unarchieve group or individual
	 * Params      - contains selected group or individual, refetch
	 * Return      - null
	 *  */
	unArchiveMember = async (value) => {
		// Deconstruct state
		const { hash } = this.state
		// Deconstruct params
		const { refetch, selectedMember, archieveChat } = value
		const { id: groupId } = selectedMember

		//Mutation - Unarchive chat or group mutation
		const { data } = await archieveChat({
			variables: {
				hash: hash,
				archiveValue: 'false',
				groupId,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_CHAT_LIST,
					variables: {
						hash: hash,
						limit: 12,
						offset: 0,
						searchString: '',
					},
				},
				{
					query: GET_ARCHIEVED_LIST,
					variables: {
						hash,
					},
				},
			],
		})

		if (data.archieveChat) {
			// setState
			this.setState({
				showModalDialog: false,
				modalDialogType: null,
			})

			// CHat unarchived success notification
			notification.success({
				message: `Unarchived successfully`,
				placement: 'bottomLeft',
			})
		}
	}

	/**
	 * Function    - changeSelectedGroup()
	 * Description - Change chat list
	 * Params      - contains group id or individual
	 * Return      - null
	 *  */
	changeSelectedGroup = async (value) => {
		// Deconstruct params
		const { item } = value
		// setState

		// Query -  Get user media
		const { data } = await newClient.query({
			query: GET_MEDIA,
			variables: {
				groupId: item.id,
			},
		})

		const mediaImage = data.getMediaForGroup

		this.setState({
			groupId: item.id,
			selectedContactChat: item,
			showModalDialog: false,
			modalDialogType: null,
			mediaImage: mediaImage.reverse(),
		})
	}

	/**
	 * Function    - handleModalOk()
	 * Description - Handle modal OK button
	 * Params      - contains modal type
	 * Return      - null
	 *  */
	handleModalOk = (data) => {
		// Deconstruct params
		const { type } = data
		switch (type) {
			case 'createTicket':
				return this.createNewTicket(data)

			case 'imagePreview':
				return this.uploadChatFile(data)

			case 'addParticipant':
				return this.startNewConversation(data)

			case 'addGroupParticipant':
				return this.addGroupParticipant(data)

			case 'deleteMessage':
				return this.deleteMessage(data)

			case 'forwardMessage':
				return this.forwardMessage(data)

			case 'addNewGroup':
				return this.addNewGroup(data)

			case 'editGroupParticipant':
				return this.editGroupParticipant(data)

			case 'updateGroupParticipant':
				return this.updateGroupParticipant(data)

			case 'updateGroupData':
				return this.updateGroupData(data)

			case 'unArchiveMember':
				return this.unArchiveMember(data)

			case 'changeSelectedGroup':
				return this.changeSelectedGroup(data)
		}
	}

	/**
	 * Function    - getChats()
	 * Description - Get particular chat details
	 * Params      - contains chat id and chat type
	 * Return      - null
	 *  */
	getChats = async (event, item, refetch, chatType) => {
		event.preventDefault()
		// Deconstruct params
		const { id, type } = item
		// Deconstruct state
		const { modalDialogType, showModalDialog } = this.state

		// Query -  Get user hash query
		const { data } = await newClient.query({
			query: GET_MEDIA,
			variables: {
				groupId: item.id,
			},
		})

		const mediaImage = data.getMediaForGroup

		// setState
		this.setState({
			chatHistoryVisible: true,
			groupId: id,
			selectedContactChat: item,
			chatType: type,
			chatListRefetch: refetch,
			footerInput: '',
			modalDialogType: chatType !== 'archived' ? modalDialogType : null,
			showModalDialog: chatType !== 'archived' ? showModalDialog : false,
			mediaImage: mediaImage.reverse(),
		})
	}

	/**
	 * Function    - addNewConversation()
	 * Description - Add new conversation
	 * Params      - null
	 * Return      - null
	 *  */
	addNewConversation = () => {
		// setState
		this.setState({
			showModalDialog: true,
			modalDialogType: 'addParticipant',
		})
	}

	/**
	 * Function    - showArchivedMember()
	 * Description - Show archived member modal
	 * Params      - null
	 * Return      - null
	 *  */
	showArchivedMember = () => {
		// setState
		this.setState({
			showModalDialog: true,
			modalDialogType: 'archivedMember',
		})
	}

	/**
	 * Function    - openFirstChat()
	 * Description - Open first chat modal
	 * Params      - contains group id and group type
	 * Return      - null
	 *  */
	openFirstChat = async (value) => {
		// Deconstruct state
		const { groupId, conversationType } = this.state
		// Deconstruct params
		const { item, refetch } = value

		if (groupId === null && conversationType !== 'new') {
			// Query -  Get user hash query
			const { data } = await newClient.query({
				query: GET_MEDIA,
				variables: {
					groupId: item.id,
				},
			})

			const mediaImage = data.getMediaForGroup
			// setState
			this.setState({
				selectedContactChat: item,
				groupId: item.id,
				chatHistoryVisible: false,
				chatType: item.type,
				chatListRefetch: refetch,
				mediaImage: mediaImage.reverse(),
			})
		}
	}

	/**
	 * Function    - setGroupId()
	 * Description - Set group id in state
	 * Params      - contains group details
	 * Return      - null
	 *  */
	setGroupId = (data) => {
		// Deconstruct params
		const { item } = data
		const { id } = item
		// setState
		this.setState({ selectedContactChat: item, groupId: id })
	}

	/**
	 * Function    - closeSelectedReply()
	 * Description - Hide selected reply UI in DOM
	 * Params      - contains data
	 * Return      - null
	 *  */
	closeSelectedReply = (data) => {
		// setState
		this.setState({ messageData: null, reply: false })
	}

	/**
	 * Function    - onDragFile()
	 * Description - Detect dragged file in Chat Box
	 * Params      - contains file details
	 * Return      - null
	 *  */

	onDragFile = (file) => {
		// Deconstruct file
		const { name, type } = file[0]
		const fileType = ['image/jpeg', 'application/pdf', 'image/png']
		if (fileType.includes(type)) {
			var reader = new FileReader()
			reader.onloadend = () => {
				const fileExtension = type.split('/')[1]
				const fileExtensionArray = ['jpeg', 'jpg', 'png']
				const data = {
					uri: reader.result,
					fileType: fileExtensionArray.includes(fileExtension) ? 'image' : 'pdf',
					fileName: name,
					file,
				}
				// Show image preview
				this.showImagePreview(data)
			}
			reader.readAsDataURL(file[0])
		}
	}

	render() {
		// Deconstruct state
		const {
			contacts,
			selectedContactChat,
			groupId,
			chatType,
			showModalDialog,
			imageDataUrl,
			modalDialogType,
			fileType,
			fileName,
			messageCaption,
			messageId,
			messageText,
			hash,
			toHash,
			loader,
			conversationType,
			messageData,
			groupMember,
			reply,
			chatListRefetch,
			footerInput,
			copyPasteStatus,
			mediaImage,
		} = this.state

		return (
			<ApolloProvider client={newClient}>
				<div
					className="app chatApp"
					onPaste={(event) => {
						if (modalDialogType !== 'createTicket') {
							this.onPasteScreenShotImage(event)
						}
					}}
					onDrop={(event) => {
						const file = event.dataTransfer.files
						if (modalDialogType !== 'createTicket') {
							this.onDragFile(file)
						}
						event.preventDefault()
						event.stopPropagation()
					}}
					onDragOver={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}
					onDragEnter={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}>
					<ChatList
						contacts={contacts}
						getChats={this.getChats}
						hash={hash}
						groupId={groupId}
						addNewConversation={this.addNewConversation}
						addGroupParticipant={this.addGroupParticipant}
						openFirstChat={this.openFirstChat}
						conversationType={conversationType}
						showArchivedMember={this.showArchivedMember}
						archieveMember={this.archieveMember}
					/>
					<div className="chatView" style={styles.chatView}>
						{groupId || toHash ? (
							<>
								<ChatHeader
									selectedContact={selectedContactChat}
									toHash={toHash}
									hash={hash}
									groupId={groupId}
									showImagePreview={this.showImagePreview}
									showModalDialogPopup={this.showModalDialogPopup}
								/>
								{groupId !== null ? (
									<Query
										query={GET_CHAT_DETAILS}
										variables={{
											hash: hash,
											limit: 12,
											offset: 0,
											groupId: groupId,
										}}>
										{({
											loading,
											error,
											data,
											subscribeToMore,
											fetchMore,
											refetch,
											client,
										}) => {
											if (loading) {
												return (
													<div style={styles.ChatBody}>
														<div style={styles.chatReverse}>
															<div style={styles.chatAlignCenter}>
																<Spin indicator={<Icon type="loading" spin />} />
															</div>
														</div>
													</div>
												)
											}
											if (!data || !data.getChatDetails.length) {
												return (
													<div style={styles.ChatBody}>
														<div style={styles.chatReverse}>
															<div style={styles.chatAlignCenter}>
																<span style={styles.chatYetToStart}>
																	Yet to start a conversation 😀
																</span>
															</div>
														</div>
													</div>
												)
											}

											return (
												<div>
													<ChatBody
														chatListRefetch={chatListRefetch}
														data={data}
														groupId={groupId}
														subscribeToMore={subscribeToMore}
														fetchMore={fetchMore}
														chatType={chatType}
														showModalDialog={showModalDialog}
														showModalDialogPopup={this.showModalDialogPopup}
														hash={hash}
														toHash={toHash}
														refetch={refetch}
														type={modalDialogType}
														handleModalCancel={this.handleModalCancel}
														handleModalOk={this.handleModalOk}
														handleModalBack={this.handleModalBack}
														handleModalNext={this.handleModalNext}
														imageDataUrl={imageDataUrl}
														messageData={messageData}
														fileType={fileType}
														fileName={fileName}
														messageText={messageText}
														messageCaption={messageCaption}
														messageId={messageId}
														loader={loader}
														groupMember={groupMember}
														selectedContact={selectedContactChat}
														getChats={this.getChats}
														mediaImage={mediaImage}
													/>
												</div>
											)
										}}
									</Query>
								) : (
									<div style={styles.ChatBody}>
										<div style={styles.chatReverse}>
											<div style={styles.chatAlignCenter}>
												<span style={styles.chatYetToStart}>
													Yet to start a conversation 😀
												</span>
											</div>
										</div>
									</div>
								)}

								<ChatFooter
									groupId={groupId}
									hash={hash}
									chatType={chatType}
									toHash={toHash}
									setGroupId={this.setGroupId}
									messageData={messageData}
									reply={reply}
									footerInput={footerInput}
									copyPasteStatus={copyPasteStatus}
									closeSelectedReply={this.closeSelectedReply}
								/>
							</>
						) : (
							<ViewDesignQube />
						)}
					</div>

					<Fragment>
						{showModalDialog && ['addParticipant'].includes(modalDialogType) ? (
							<ModalDialog
								type={modalDialogType}
								handleModalCancel={this.handleModalCancel}
								handleModalOk={this.handleModalOk}
								handleModalBack={this.handleModalBack}
								handleModalNext={this.handleModalNext}
								showModalDialogPopup={this.showModalDialogPopup}
								hash={hash}
							/>
						) : null}
					</Fragment>
				</div>
			</ApolloProvider>
		)
	}
}

export default withApollo(ChatBox)
