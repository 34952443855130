import { Icon, Spin, Button, Timeline, Empty } from 'antd'
import { format } from 'date-fns' // Date FNS for time format functions

import React from 'react'
import { Query } from 'react-apollo'
import { GET_PROPOSAL_TIMELINE_LIST } from './query'

export default function ProposalTimeline(props) {
	const { currentEnquiryId } = props

	return (
		<Query
			query={GET_PROPOSAL_TIMELINE_LIST}
			variables={{
				enquiryId: currentEnquiryId,
			}}>
			{({ data, loading }) => {
				if (loading) {
					return (
						<Spin
							indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
							style={{ padding: '30px 52px' }}
						/>
					)
				}

				if (!data || !data.length || !data.proposalTimelineList) {
					return <Empty description="No activity yet . . ." />
				}
				return (
					<div
						style={{
							backgroundColor: '#fff',
							height: 300,
							width: '-webkit-fill-available',
							overflowY: 'scroll',
							borderRadius: 5,
							marginLeft: -32,
							marginTop: 30,
							display: 'flex',
							flexDirection: 'column-reverse',
						}}>
						<Timeline>
							{data.proposalTimelineList.map((values, panelIndex) => {
								return (
									<Timeline.Item>
										<p>{values.date}</p>
										<p>{values.context}</p>
									</Timeline.Item>
								)
							})}
						</Timeline>
					</div>
				)
			}}
		</Query>
	)
}
