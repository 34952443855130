import React from 'react'
import { Collapse, Spin, Icon, Upload, Button } from 'antd'
import { MdPlayArrow } from 'react-icons/md'
import EditableTable from './EditableTable'
import { Query, withApollo } from 'react-apollo'
import { GET_DESIGN_PROCESS } from './query'
import './index.css'

const { Panel } = Collapse

class DesignProcess extends React.Component {
	render() {
		return (
			<div className={'designProcess'}>
				<Query query={GET_DESIGN_PROCESS}>
					{({ data, loading }) => {
						if (loading) {
							return (
								// Spinner icon
								<div>
									<Spin
										indicator={<Icon type="loading" className="iconSize" spin />}
										className="loaderIcon"
									/>
								</div>
							)
						}
						if (!data) {
							return null
						}

						const { getDesignProcess } = data

						return (
							<div>
								{getDesignProcess.map((data, index) => {
									const { name, feeheadKey, processList } = data
									const processData = processList.map((value) => {
										const {
											processId,
											processName,
											processDescription,
											processMedia,
										} = value

										const processUrl =
											processMedia === ''
												? 'https://pngimage.net/wp-content/uploads/2018/06/upload-image-icon-png-4.png'
												: processMedia
										const thumbnailType = processUrl.split('.')[
											processUrl.split('.').length - 1
										]

										return {
											thumbnail:
												thumbnailType === 'jpg' || thumbnailType === 'png' ? (
													<img
														src={processUrl}
														width="50"
														height="50"
														style={{ marginLeft: '25%' }}
														alt={processUrl}
													/>
												) : (
													<video
														src={processUrl}
														width="100"
														height="100"
														controls="controls"
														autoplay="true"
													/>
												),
											key: processId,
											title: processName,
											description: processDescription,
											thumbnailUrl: processUrl,
											upload: <Button>Upload</Button>,
										}
									})
									return (
										<Collapse
											bordered={false}
											key={index}
											expandIcon={({ isActive }) => <MdPlayArrow />}
											className="site-collapse-custom-collapse">
											<Panel header={name} className="site-collapse-custom-panel">
												<p>
													<EditableTable
														processList={processData}
														feeheadKey={feeheadKey}
														key={`panel${index}`}
													/>
												</p>
											</Panel>
										</Collapse>
									)
								})}
							</div>
						)
					}}
				</Query>
			</div>
		)
	}
}

export default withApollo(DesignProcess)
