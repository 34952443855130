import React from 'react'
import { MenuList, MenuItem, MenuButton, Dropdown } from 'react-menu-list'
import { MdMoreVert } from 'react-icons/md'
import styles from './styles'

const MenuItems = ({ labelsAndContext }) => {
	return (
		<div style={styles.menu.menuItemDiv}>
			{Object.keys(labelsAndContext).map((label) => (
				<MenuItem key={label} style={styles.menu.menuItemPadding}>
					<label style={styles.menu.menuItem} onClick={labelsAndContext[label]}>
						{label}
					</label>
				</MenuItem>
			))}
		</div>
	)
}

export const Menu = ({ labelsAndContext, buttonStyle }) => {
	return (
		<div>
			<MenuButton
				style={styles.menu.menuButton}
				menu={
					<Dropdown>
						<MenuList>
							<MenuItems labelsAndContext={labelsAndContext} />
						</MenuList>
					</Dropdown>
				}>
				<MdMoreVert size={25} color="#666" {...buttonStyle} />
			</MenuButton>
		</div>
	)
}
