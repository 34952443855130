/* eslint-disable no-tabs */
// Import Component
import React from 'react'
import CKEditor from 'ckeditor4-react'
import { editorJsonData } from '../utils'
import './index.css' // Import component css

// Service space config form component
class ProposalSwot extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
		}
		this.onEditorChange = this.onEditorChange.bind(this)
	}

	// Set document data in state before, editor is rendered in DOM
	componentWillMount() {
		// Deconstruct component props
		const { serviceSwot } = this.props
		const { swotEditorHtml } = serviceSwot

		// Here set spaceEditorHtml in state
		this.setState({
			data: swotEditorHtml,
		})
	}

	/**
	 * @type - function
	 * @param - evt
	 * @summary - Trigger,When there is any changes in Editor document,
	 * @return - null
	 */
	onEditorChange = (evt) => {
		// Get Editor document HTML
		const editorHtmlData = evt.editor.getData()

		// Set these data in serviceScopeConfig component state
		this.setState({ data: editorHtmlData })

		// Deconstruct props

		const { setServiceSwot } = this.props

		// create state JSON structure and data
		const data = {
			swotEditorJson: editorJsonData(),
			swotEditorHtml: editorHtmlData,
		}

		// // Callback function
		setServiceSwot(data)
	}

	render() {
		// Deconstruct state
		const { data } = this.state

		return (
			<div className="serviceSwot">
				<div className="editorData">
					<>
						<EditorPreview data={data} />
						<CKEditorPreview data={data} onChange={this.onEditorChange} />
					</>
				</div>
			</div>
		)
	}
}

// CKEditor Preview in Editor
function CKEditorPreview(props) {
	const { data, onChange } = props
	return (
		<div className="editorDisplay">
			<CKEditor data={data} onChange={onChange} />
		</div>
	)
}

// Editor Preview in DOM
function EditorPreview(props) {
	const { data } = props
	return (
		<div className="editorPreview">
			<h2>SWOT Analysis</h2>
			<div dangerouslySetInnerHTML={{ __html: data }} />
		</div>
	)
}

// export ProposalSpaceConfig component
export default ProposalSwot
