// Import react component
import React, { Component } from 'react'
import Sortable from 'react-sortablejs'
import { Row, Col } from 'antd'
import DeliverableList from './DeliverableList'
import StageList from './StageList'
import { changeDeliverableList } from '../utils'
import './index.css' // Import component CSS

// ServiceDeliverableList component
class ServiceDeliverableList extends Component {
	// Drag and drop in serviceDeliverableList Component
	/**
	 * @type - function
	 * @param - value contains list of deliverables
	 * @summary - Change deliverable order
	 * @return - null
	 */
	dragServiceStage = (value, objectStageDeliverable) => {
		// Parse deliverable list
		const arrayStageDeliverable = value.map((val) => JSON.parse(val))

		// Create JSON structure and data
		const data = {
			objectNewStageDeliverable: objectStageDeliverable,
			arrayNewStageDeliverable: arrayStageDeliverable,
		}

		const result = changeDeliverableList(this.props, data)

		const { setServiceDeliverableList } = this.props
		// callback function
		setServiceDeliverableList(result)
	}

	render() {
		// Decontruct props
		const {
			serviceStageDeliverableList,
			changeDeliverableStage,
			deleteDeliverableList,
		} = this.props
		// Deconstruct service stage deliverable list object
		const { arrayStageDeliverable, objectStageDeliverable } = serviceStageDeliverableList

		return (
			<div className="serviceStageConfig">
				<Row style={{ paddingBottom: 50 }}>
					{/* <Col span={6} offset={1} style={{ position: 'fixed', height: '65vh' }}>
						<Sortable
							options={{
								animation: 150,
								group: {
									pull: true,
									put: true,
								},
								sort: false,
							}}
							className="block-list"
							onChange={(items) => {
								this.dragServiceStage(items, objectStageDeliverable)
							}}
							tag="ul"
							style={{ maxHeight: '100%', height: '100%', margin: 'auto' }}>
							<StageList data={objectStageDeliverable} />
						</Sortable>
					</Col> */}
					<Col span={20} offset={2} className="deliverableList">
						<Sortable
							key={'deliverableList'}
							options={{
								animation: 150,
								group: {
									pull: true,
									put: true,
								},
							}}
							className="block-list"
							onChange={(items) => {
								this.dragServiceStage(items, objectStageDeliverable)
							}}
							tag="ul"
							style={{ maxHeight: '100%', height: '100%', margin: 'auto' }}>
							{/** Deliverable list */}
							<DeliverableList
								data={arrayStageDeliverable}
								objectData={objectStageDeliverable}
								changeDeliverableStage={changeDeliverableStage}
								deleteDeliverableList={deleteDeliverableList}
							/>
						</Sortable>
					</Col>
				</Row>
			</div>
		)
	}
}

// export serviceDeliverableList component
export default ServiceDeliverableList
