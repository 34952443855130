import gql from 'graphql-tag'

export const SearchEnquiries = gql`
	query searchEnquiries($searchKey: String) {
		searchEnquiries(searchKey: $searchKey) {
			id
			enquiryType
			enquirySection
			name
			contactNo
			otpCheck
			enquiryTimestamp
			area
			areaType
			scope
			typology
			modifiedAt
			modifiedBy
			address
			email
			followupActive
			followupDate
			proposalLastSeen
			duplicateEnquiries
		}
	}
`
