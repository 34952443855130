const styles = {
  profileAvatar: {
    fontSize: 16,
    float: 'right',
    marginTop: 14,
    marginRight: 15,
    cursor: 'pointer',
  },
};

export default styles;
