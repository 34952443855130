import React from 'react'
import { MdArrowForward, MdClose } from 'react-icons/md'
import { IoIosClose } from 'react-icons/io'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { Spin, Icon, Row, Col, Avatar } from 'antd'
import { Query } from 'react-apollo'
import { GET_PEOPLE } from '../query'
import styles from './styles'

class AddGroupParticipant extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			groupMember: this.props.groupMember,
			teamMembers: [],
			searchString: '',
		}
	}
	render() {
		const { groupMember, teamMembers, searchString } = this.state
		const { hash, handleModalCancel, handleModalNext } = this.props
		const lowercasedFilter = searchString.toLowerCase()
		const filteredData = teamMembers.filter((item) => {
			return item['name'].toLowerCase().includes(lowercasedFilter)
		})

		return (
			<Modal type="addGroupParticipant">
				<ModalHeader type="addGroupParticipant">
					<div style={styles.modalHeaderFlex}>
						<span
							onClick={() => {
								handleModalCancel()
							}}
							style={styles.modalHeaderTextColor}>
							<MdClose size={25} style={styles.modalHeaderIcon} />
						</span>
						<span style={styles.modalHeaderName}>Add group participants</span>
					</div>
				</ModalHeader>
				<ModalButtonContainer type="addGroupParticipant">
					<Row>
						<Col span={24}>
							<div className="chatSearch" style={styles.positionRelative}>
								<input
									type="text"
									placeholder="Search"
									onChange={(e) => {
										this.setState({ searchString: e.target.value })
									}}
									value={searchString}
								/>
								<img
									src={require('./../images/searchicon.png')}
									alt={''}
									style={styles.searchIcon}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<>
								{groupMember.length ? (
									<div style={styles.addGroupParticipant.groupSelectedMemberList}>
										{groupMember.map((member) => {
											const { name, hash } = member
											return (
												<span style={styles.addGroupParticipant.groupSelectedMemberName}>
													{name}
													<IoIosClose
														size={17}
														style={
															styles.addGroupParticipant.groupSelectedMemberCloseIcon
														}
														onClick={() => {
															const filterSelectedName = groupMember.filter(
																(item) => item.hash !== hash
															)
															this.setState({ groupMember: filterSelectedName })
														}}
													/>
												</span>
											)
										})}
									</div>
								) : null}
							</>
						</Col>
					</Row>
					<Row>
						<Query query={GET_PEOPLE} variables={{ hash: hash }}>
							{({ loading, error, data }) => {
								if (loading) {
									return (
										<div style={styles.fullWidth}>
											<div>
												<div style={styles.centerAlign}>
													<Spin indicator={<Icon type="loading" spin />} />
												</div>
											</div>
										</div>
									)
								}
								if (!data || !data.getPeople.length) {
									return (
										<div style={styles.fullWidth}>
											<div>
												<div style={styles.addGroupParticipant.groupMemberNotFound}>
													<span>No team members found.</span>
												</div>
											</div>
										</div>
									)
								}

								if (!teamMembers.length) {
									this.setState({ teamMembers: data.getPeople })
								}
								return (
									<div style={styles.addGroupParticipant.listGroupMember}>
										<ul style={styles.addGroupParticipant.unorderedListGroupMember}>
											{filteredData.length ? (
												<>
													{filteredData.map((people) => {
														const { name: peopleName, hash: peopleHash } = people

														let alreadySelectedStatus = groupMember.filter(function(
															item
														) {
															return item.hash === peopleHash
														})

														return (
															<div key={peopleHash}>
																{alreadySelectedStatus.length === 0 ? (
																	<li
																		style={styles.addGroupParticipant.groupMember}
																		onClick={() => {
																			const newSelectedName = {
																				hash: peopleHash,
																				name: peopleName,
																			}

																			this.setState({
																				groupMember: [...groupMember, newSelectedName],
																			})
																		}}>
																		<Avatar>{peopleName[0]}</Avatar>
																		<span
																			style={styles.addGroupParticipant.groupMemberName}
																			title={peopleName}>
																			{peopleName}
																		</span>
																	</li>
																) : null}
															</div>
														)
													})}
													<div key={'NoMoreAvailable'}>
														<li style={{ padding: 10, textAlign: 'center' }}>
															<span style={{ color: 'gray' }}>No more members :)</span>
														</li>
													</div>
												</>
											) : (
												<li style={styles.addGroupParticipant.searchGroupParticipant}>
													<span style={styles.addGroupParticipant.searchResult}>
														No members found
													</span>
												</li>
											)}
										</ul>
										{groupMember.length ? (
											<div
												style={styles.addGroupParticipant.addNewParticipant}
												onClick={() => {
													handleModalNext({ groupMember, type: 'addNewGroup' })
												}}>
												<MdArrowForward
													size={30}
													style={styles.addGroupParticipant.updateIcon}
												/>
											</div>
										) : null}
									</div>
								)
							}}
						</Query>
					</Row>
				</ModalButtonContainer>
			</Modal>
		)
	}
}
export default AddGroupParticipant
