// Import React Components

import React from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Breadcrumb } from 'antd'
import { Route, Redirect, Switch } from 'react-router-dom'
import FirstMenu from '../../components/Menu/FirstMenu'
import SecondMenu from '../../components/Menu/SecondMenu'
import ThirdMenu from '../../components/Menu/ThirdMenu'
import HeaderMenu from '../../components/Header/HeaderMenu'
import FourNotFourPage from '../FourNotFour'
import Routes from '../Routes'
import AddRoleMenuMapping from '../../components/AddRoleMenuMapping'
import AddMasterScope from '../../components/AddMasterScope'
import CreateNewService from '../../components/CreateNewService'
// import GenerateNewProposal from '../../components/GenerateNewProposal'
import NewService from '../../components/NewService'
import NewProposal from '../../components/NewProposal'

import Dashboard from '../../components/Dashboard'
import CrmBoard from '../../components/CRM/CrmBoard'
import BreadcrumbModule from './BreadcrumbModule/index'
// import SitePhotos from '../../components/SitePhotos'
// Import Antd CSS
import 'antd/dist/antd.css'

// Import Custom CSS
import '../../index.css'
import styles from './styles'

import dqFavIcon from './dq_favicon.png'
const { Content } = Layout

// This is the root component
// Here we add header, sidebar and content of the page
class Home extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			firstMenuCollapsed: false, // main menu status
			secondMenuCollapsed: false, // submenu status
			thirdMenuCollapse: true, // submenu child status
			menuId: !localStorage.getItem('lastActiveFirstMenuId')
				? 'menu_8'
				: localStorage.getItem('lastActiveFirstMenuId'),
			menuName: !localStorage.getItem('lastActiveFirstMenuName')
				? 'Enquiries'
				: localStorage.getItem('lastActiveFirstMenuName'),
			subMenuId: !localStorage.getItem('lastActiveSecondMenuId')
				? 'menu_9'
				: localStorage.getItem('lastActiveSecondMenuId'),
			subMenuName: !localStorage.getItem('lastActiveSecondMenuName')
				? 'Followup'
				: localStorage.getItem('lastActiveSecondMenuName'),
			subMenuChildId: localStorage.getItem('lastActiveThirdMenuId'),
			subMenuChildName: localStorage.getItem('lastActiveThirdMenuName'),
			componentName: !localStorage.getItem('lastActiveComponentProp')
				? 'followup'
				: localStorage.getItem('lastActiveComponentProp'),
			componentText: !localStorage.getItem('lastActiveComponent')
				? 'CrmBoard'
				: localStorage.getItem('lastActiveComponent'),
		}
	}

	collapseMenu = () => {
		const { firstMenuCollapsed, secondMenuCollapsed, thirdMenuCollapse } = this.state
		const pathArray = window.location.pathname.split('/')
		this.setState({
			firstMenuCollapsed: pathArray[1] ? !firstMenuCollapsed : firstMenuCollapsed,
			secondMenuCollapsed: pathArray[2] ? !secondMenuCollapsed : secondMenuCollapsed,
			thirdMenuCollapse: pathArray[3] ? !thirdMenuCollapse : thirdMenuCollapse,
		})
	}

	onClickFirstMenu = (menuStatus) => {
		const {
			secondMenu,
			thirdMenu,
			menuId,
			menuName,
			subMenuId,
			subMenuName,
			subMenuChildId,
			subMenuChildName,
			firstMenuPath,
		} = menuStatus

		localStorage.setItem('lastActiveFirstMenuId', menuId)
		localStorage.setItem('lastActiveFirstMenuName', menuName)
		localStorage.setItem('previousRoute', window.location.pathname)
		localStorage.setItem('lastActiveSecondMenuId', subMenuId)
		localStorage.setItem('lastActiveSecondMenuName', subMenuName)

		localStorage.setItem('lastActiveThirdMenuId', subMenuChildId)
		localStorage.setItem('lastActiveThirdMenuName', subMenuChildName)

		this.setState({
			secondMenuCollapsed: !secondMenu,
			thirdMenuCollapse: !thirdMenu,
			menuId,
			menuName,
			subMenuId,
			subMenuName,
			subMenuChildId,
			subMenuChildName,
		})
	}

	onClickSecondMenu = (menuStatus) => {
		const {
			thirdMenu,
			subMenuId,
			subMenuName,
			componentName,
			componentText,
			secondMenuPath,
		} = menuStatus
		localStorage.setItem('previousRoute', window.location.pathname)
		localStorage.setItem('lastActiveSecondMenuId', subMenuId)
		localStorage.setItem('lastActiveSecondMenuName', subMenuName)

		this.setState({
			thirdMenuCollapse: !thirdMenu,
			subMenuId,
			subMenuName,
			componentName,
			componentText,
		})
	}

	onClickThirdMenu = (menuStatus) => {
		const { subMenuChildName, componentName, componentText, thirdMenuPath } = menuStatus
		localStorage.setItem('previousRoute', window.location.pathname)
		this.setState({
			subMenuChildName,
			componentName,
			componentText,
		})
	}

	setSubMenuId = (data) => {
		if (data !== undefined) {
			this.setState({
				subMenuId: data.id,
			})
		}
	}

	updateActiveMenuKeys = (keyArray) => {
		const { activeFirstMenuKey, activeSecondMenuKey } = this.state

		if (this.state.activeFirstMenuKey !== keyArray[0]) {
			this.setState({
				activeFirstMenuKey: keyArray[0],
			})
		}
		// if (this.state.activeSecondMenuKey !== keyArray[1]) {
		// 	this.setState({
		// 		activeSecondMenuKey: keyArray[1],
		// 	})
		// }
		console.log(activeFirstMenuKey, activeSecondMenuKey)
	}

	setSecondMenuStatus = (value) => {
		this.setState({
			secondMenuCollapsed: value,
		})
	}

	render() {
		const {
			firstMenuCollapsed,
			secondMenuCollapsed,
			thirdMenuCollapse,
			menuId,
			menuName,
			enquiryStatus,
			subMenuId,
			subMenuName,
			subMenuChildName,
		} = this.state
		const pathArray = window.location.pathname.split('/')
		let currentPath = ''
		if (pathArray[1] === 'projects') {
			currentPath = `${pathArray[2]} | ${pathArray[3]}`
		} else if (pathArray[3] && pathArray[1] !== 'projects') {
			currentPath = `${pathArray[3]} | ${pathArray[2]}`
		} else {
			currentPath = `${pathArray[2]} | ${pathArray[1]}`
		}
		const currentRoute = currentPath
			.replace('-', ' ')
			.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
		return (
			<Layout>
				{/** Header Component */}
				<HeaderMenu
					menuCollapse={this.collapseMenu}
					firstMenuCollapsed={firstMenuCollapsed}
				/>
				<Layout>
					{/** Main Menu Component */}
					<FirstMenu
						collapsed={firstMenuCollapsed}
						onClickFirstMenu={this.onClickFirstMenu}
						menuId={menuId}
					/>
					{/** Sub Menu Component */}
					<SecondMenu
						collapsed={secondMenuCollapsed}
						onClickSecondMenu={this.onClickSecondMenu}
						menuId={menuId}
						menuName={menuName}
						setSubMenuId={this.setSubMenuId}
					/>
					{/** Sub Menu Child Component */}
					<ThirdMenu
						collapsed={thirdMenuCollapse}
						onClickThirdMenu={this.onClickThirdMenu}
						menuId={subMenuId}
						subMenuName={subMenuName}
					/>
					{/** Dynamic Content Component */}
					<Content style={styles.mainContentArea}>
						<Helmet>
							<link rel="shortcut icon" href={dqFavIcon} />
							<title>{currentRoute}</title>
						</Helmet>
						<Routes />
					</Content>
				</Layout>
			</Layout>
		)
	}
}

export default Home
