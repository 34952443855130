import React from 'react'
import { MdClose, MdSend } from 'react-icons/md'
import { Modal, ModalButton, ModalButtonContainer, ModalHeader } from './utils'
import pdfImage from '../images/pdf-image.jpeg'
import { IoIosSend } from 'react-icons/io'
import styles from './styles'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'

class ImagePreview extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			caption: '',
		}
	}
	render() {
		const {
			imageDataUrl,
			fileType,
			fileName,
			handleModalCancel,
			handleModalOk,
			refetch,
			loader,
		} = this.props

		const { caption } = this.state
		return (
			<Modal type="imagePreview">
				<ModalHeader type="imagePreview">
					<div style={styles.modalHeaderFlex}>
						<span onClick={handleModalCancel} style={styles.modalHeaderTextColor}>
							<MdClose size={25} style={styles.modalHeaderIcon} />
						</span>
						<span style={styles.modalHeaderName}>Preview</span>
					</div>
				</ModalHeader>
				<ModalButtonContainer type="imagePreview">
					{fileType === 'image' ? (
						<div style={{ maxHeight: '55vh', overflowY: 'scroll', marginTop: 10 }}>
							<img src={imageDataUrl} style={styles.imagePreview.imageHeight} />
						</div>
					) : (
						// <div style={{ marginTop: 10 }} className={'imagePreview'}>
						// 	<AwesomeSlider animation="cubeAnimation" bullets={false}>
						// 		{/* <div data-src={} /> */}
						// 	</AwesomeSlider>
						// </div>
						<div style={styles.imagePreview.pdfDiv}>
							<img src={pdfImage} height="50" width="50" />
							<span style={styles.fontWeightBold}>{fileName}</span>
						</div>
					)}

					<div style={styles.displayFlex}>
						<input
							type="text"
							placeholder="Add a caption..."
							value={caption}
							style={styles.imagePreview.imageDescription}
							onChange={(e) => {
								this.setState({ caption: e.target.value })
							}}
						/>

						{!loader ? (
							<MdSend
								style={styles.imagePreview.imageSendIcon}
								size={30}
								color="#989B9C"
								onClick={() => {
									handleModalOk({ refetch, caption, fileType, type: 'imagePreview' })
								}}
							/>
						) : (
							<div style={styles.displayFlex}>
								<IoIosSend
									style={styles.imagePreview.imageSendIcon}
									size={30}
									color="#989B9C"
								/>
								<span style={{ marginTop: 30 }}>Sending...</span>
							</div>
						)}
					</div>
				</ModalButtonContainer>
			</Modal>
		)
	}
}
export default ImagePreview
