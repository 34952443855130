import React from 'react'
import { MdArrowBack, MdCheck } from 'react-icons/md'
import { FaCamera } from 'react-icons/fa'
import { Modal, ModalButtonContainer, ModalHeader } from './utils'
import { Row, Col, Select, Avatar, Spin, Icon } from 'antd'
import { Query } from 'react-apollo'
import { GET_USER_PROJECT, CREATE_NEW_GROUP } from '../query'
import Loader from 'react-loader-spinner'
import { Mutation } from 'react-apollo'
import styles from './styles'

const { Option } = Select

class AddNewGroup extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			groupName: null,
			groupDescription: '',
			projectUrl: '',
			groupType: 'Team',
			imageUri: null,
			file: [],
		}
	}

	filePreview = () => (event) => {
		const file = event.target.files
		var reader = new FileReader()
		reader.onloadend = () => {
			this.setState({ imageUri: reader.result, groupPicture: file })
		}
		reader.readAsDataURL(file[0])
	}

	render() {
		const { hash, handleModalOk, handleModalBack, groupMember, loader } = this.props

		const {
			groupName,
			groupDescription,
			projectUrl,
			groupType,
			imageUri,
			file,
		} = this.state

		return (
			<Mutation mutation={CREATE_NEW_GROUP}>
				{(addNewGroup, { data, loading }) => (
					<Modal type="addNewGroup">
						<ModalHeader type="addNewGroup">
							<div style={styles.modalHeaderFlex}>
								<span
									onClick={() => {
										handleModalBack({ modalType: 'addGroupParticipant' })
									}}
									style={styles.modalHeaderTextColor}>
									<MdArrowBack size={25} style={styles.modalHeaderIcon} />
								</span>
								<span style={styles.modalHeaderName}>Add New Group</span>
							</div>
						</ModalHeader>
						<ModalButtonContainer type="addNewGroup">
							<Row>
								<Col span={24}>
									<div
										onClick={() => this.filePdfUpload.click()}
										style={styles.addNewGroup.groupImageDiv}>
										{!imageUri ? (
											<div>
												<FaCamera size={25} />
												<div>ADD GROUP ICON</div>
												<input
													type="file"
													accept="application/pdf"
													ref={(filePdfUpload) => {
														this.filePdfUpload = filePdfUpload
													}}
													style={styles.visibilityHidden}
													onChange={this.filePreview()}
												/>
											</div>
										) : (
											<>
												<img src={imageUri} style={styles.addNewGroup.groupImage} />
												<input
													type="file"
													accept="image/*"
													ref={(filePdfUpload) => {
														this.filePdfUpload = filePdfUpload
													}}
													style={styles.visibilityHidden}
													onChange={this.filePreview()}
												/>
											</>
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<div style={styles.addNewGroup.groupDetailsDiv}>
										<h4 style={styles.fontWeightBold}>Group Name</h4>
										<input
											type="text"
											placeholder="Enter group name"
											onChange={(event) => {
												this.setState({ groupName: event.target.value })
											}}
											value={groupName}
											style={styles.addNewGroup.groupInputField}
										/>
									</div>
								</Col>
							</Row>

							<Row>
								<Col span={24}>
									<div style={styles.addNewGroup.groupDetailsDiv}>
										<h4 style={styles.fontWeightBold}>Group Description</h4>
										<input
											type="text"
											placeholder="Enter group description"
											onChange={(event) => {
												this.setState({ groupDescription: event.target.value })
											}}
											value={groupDescription}
											style={styles.addNewGroup.groupInputField}
										/>
									</div>
								</Col>
							</Row>

							<Row>
								<Col span={24}>
									<div style={styles.addNewGroup.groupDetailsDiv}>
										<h4 style={styles.fontWeightBold}>Select Group Type</h4>
										<Select
											showSearch
											style={styles.addNewGroup.groupType}
											placeholder="Select group type"
											optionFilterProp="children"
											defaultValue={'Team'}
											onChange={(value) => {
												this.setState({ groupType: value })
											}}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}>
											<Option value="Team">Team</Option>
											<Option value="Project">Project</Option>
										</Select>
									</div>
								</Col>
							</Row>

							{groupType === 'Project' ? (
								<Query query={GET_USER_PROJECT} variables={{ hash: hash }}>
									{({ loading, error, data }) => {
										if (loading) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.centerAlign}>
															<Spin indicator={<Icon type="loading" spin />} />
														</div>
													</div>
												</div>
											)
										}
										if (!data || !data.getProjects.length) {
											return (
												<div style={styles.fullWidth}>
													<div>
														<div style={styles.addNewGroup.groupMemberNotFound}>
															<span>No member found</span>
														</div>
													</div>
												</div>
											)
										}
										return (
											<>
												<Row>
													<Col span={24}>
														<div style={styles.addNewGroup.groupDetailsDiv}>
															<h4 style={styles.fontWeightBold}>Select Project</h4>
															<Select
																showSearch
																style={styles.addNewGroup.groupType}
																placeholder="Select project"
																optionFilterProp="children"
																onChange={(value) => {
																	this.setState({ projectUrl: value })
																}}
																filterOption={(input, option) =>
																	option.children
																		.toLowerCase()
																		.indexOf(input.toLowerCase()) >= 0
																}>
																{data.getProjects.map((value) => {
																	const { url, name } = value
																	return <Option value={url}>{name}</Option>
																})}
															</Select>
														</div>
													</Col>
												</Row>
											</>
										)
									}}
								</Query>
							) : null}

							{groupMember.length ? (
								<Row>
									<Col span={24}>
										<div style={styles.addNewGroup.groupDetailsDiv}>
											<h4 style={styles.fontWeightBold}>
												No of participants : {groupMember.length}
											</h4>

											<Row style={styles.centerAlign}>
												{groupMember.map((value) => {
													const { name } = value
													return (
														<>
															<Col
																span={8}
																style={styles.addNewGroup.groupMemberNameDiv}
																title={name}>
																<Avatar>{name[0].toUpperCase()}</Avatar>
																<div style={styles.addNewGroup.groupMemberName}>
																	{name}
																</div>
															</Col>
														</>
													)
												})}
											</Row>
										</div>
									</Col>
								</Row>
							) : null}

							<Row>
								<Col span={24}>
									<div
										style={styles.addNewGroup.createNewGroup}
										onClick={() => {
											handleModalOk({
												addNewGroup,
												groupName,
												groupDesc: groupDescription,
												groupType,
												projectUrl,
												groupMember,
												groupPicture: file,
												type: 'addNewGroup',
											})
										}}>
										{loader ? (
											<div style={styles.addNewGroup.updateLoader}>
												<Loader
													type="TailSpin"
													color="#ffffff"
													height={25}
													width={25}
													radius={4}
												/>
											</div>
										) : (
											<MdCheck size={30} style={styles.addNewGroup.updateIcon} />
										)}
									</div>
								</Col>
							</Row>
						</ModalButtonContainer>
					</Modal>
				)}
			</Mutation>
		)
	}
}
export default AddNewGroup
