import gql from 'graphql-tag'

export const GET_PROJECT = gql`
	query GetProject(
		$statusVisibilty: String!
		$limit: Int!
		$offset: Int!
		$searchString: String
	) {
		GetProject(
			statusVisibilty: $statusVisibilty
			limit: $limit
			offset: $offset
			searchString: $searchString
		) {
			url
			name
		}
	}
`
export const PROJECT_STATUS_CHANGE = gql`
	mutation ProjectStatusChange($projectUrls: [String], $newStatus: String!) {
		ProjectStatusChange(projectUrls: $projectUrls, newStatus: $newStatus)
	}
`
