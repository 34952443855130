import React from 'react'
import { Breadcrumb } from 'antd'

export default function BreadcrumbModule(props) {
	const { levelOneBreadCrumb, levelTwoBreadCrumb, levelThreeBreadCrumb } = props

	return (
		<h1>
			<Breadcrumb style={{ marginLeft: 20, marginTop: 5, fontSize: 18 }}>
				<Breadcrumb.Item>{levelOneBreadCrumb}</Breadcrumb.Item>
				<Breadcrumb.Item>{levelTwoBreadCrumb}</Breadcrumb.Item>
				<Breadcrumb.Item>{levelThreeBreadCrumb}</Breadcrumb.Item>
			</Breadcrumb>
		</h1>
	)
}
