
const styles = {
  showClosedDiscussion: {
    textDecoration: 'underline',
    textAlign: 'center',
    color: ',#2c8247',
    fontWeight: '700',
    marginBottom: '10px',
    marginTop: '15px',
    cursor:'pointer',
  },
}
export default styles
