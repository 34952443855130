import React from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { Icon, Avatar } from 'antd';

//Load list of deliverables in proposal stage configuration

/**
 * @type - function
 * @param -
 * @summary -
 * @return -
 */
class DeliverableList extends React.Component {
  render() {
    const { data } = this.props;
    return [
      data.length ? (
        data.map((val) => {
          const result = JSON.stringify(val);
          const { scopeType, scopeName } = val;

          return (
            <li
              key={uniqueId()}
              data-id={result}
              style={
                scopeType === 'deliverable' || scopeType === 'milestone'
                  ? { backgroundColor: 'white' }
                  : {
                      backgroundColor: `${
                        val.status == 'success' ? '#41984a' : '#f44336'
                      }`,
                      color: 'white',
                      textAlign: 'center'
                    }
              }
            >
              {scopeType !== 'stage' ? (
                scopeType === 'milestone' ? (
                  <div>
                    <Avatar className={'stageMilestoneIcon'} size='large'>
                      {scopeType[0].toUpperCase()}
                    </Avatar>
                    <span className={'stageNameMarginLeft20'}>{scopeName}</span>
                  </div>
                ) : (
                  <div>
                    <Avatar
                      className={
                        scopeType === 'deliverable'
                          ? 'stageDeliverableIcon'
                          : 'stageSiteVisitIcon'
                      }
                      size='large'
                    >
                      {scopeType[0].toUpperCase()}
                    </Avatar>
                    <span className={'stageNameMarginLeft20'}>{scopeName}</span>
                  </div>
                )
              ) : (
                <div style={{ margin: 'auto' }}>{scopeName}</div>
              )}
            </li>
          );
        })
      ) : (
        <li key={uniqueId()} style={{ backgroundColor: 'white' }}>
          <div>
            <Avatar size='large' className={'emptyDeliverableList'}>
              <Icon
                type='exclamation-circle'
                className={'emptyDeliverableListIcon'}
              />
            </Avatar>
            <span className={'stageNameMarginLeft20'}>
              No Deliverable Found
            </span>
          </div>
        </li>
      )
    ];
  }
}

DeliverableList.propTypes = {
  data: PropTypes.array
};

export default DeliverableList;
