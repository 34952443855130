import gql from 'graphql-tag'

export const TeamHeadsList = gql`
	query teamHeadsList($enquiryId: String) {
		teamHeadsList(enquiryId: $enquiryId) {
			currentHead {
				headEmailID
				displayName
			}
			headList {
				headEmailID
				displayName
			}
		}
	}
`
