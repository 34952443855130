import gql from 'graphql-tag'

export const CHANGECOMMENTSTATUS = gql`
	mutation ChangeCommentStatus($hash: String!, $status: String!, $commentId: String!) {
		ChangeCommentStatus(hash: $hash, status: $status, commentId: $commentId) {
			commentId
		}
	}
`

export const REOPENADDCOMMENT = gql`
	mutation ReopenAddComment(
		$projectUrl: String!
		$hash: String!
		$text: String!
		$level: String!
		$type: String
		$commentType: String
		$productId: String
		$variantId: String
		$spaceId: String
		$feedId: String
		$imageId: String
		$parentId: String
		$status: String
		$mentionedTo: [String]
	) {
		ReopenAddComment(
			projectUrl: $projectUrl
			hash: $hash
			text: $text
			level: $level
			type: $type
			commentType: $commentType
			productId: $productId
			variantId: $variantId
			spaceId: $spaceId
			feedId: $feedId
			imageId: $imageId
			parentId: $parentId
			status: $status
			mentionedTo: $mentionedTo
		)
	}
`
