import React from 'react';
import image from '../../assets/404_image.png';
import styled from 'styled-components';

const Image = styled.img`
  width: 50vw;
  height: 50vh;
`;

export default function FourNotFour() {
  return <Image src={ image } />;
}
