import React from 'react'
import { GoPlus } from 'react-icons/go'
import { Button, notification, Typography } from 'antd'
import { Mutation } from 'react-apollo'
import ManualEnquiryForm from './EnquiryForm'
import { CREATE_MANUAL_ENQUIRY_MUTATION } from './mutation'
const { Text } = Typography
class ManualEnquiry extends React.Component {
	state = {
		visible: false,
	}

	showModal = () => {
		this.setState({ visible: true })
	}

	handleCancel = () => {
		this.setState({ visible: false })
	}

	handleCreate = (mutate) => {
		const { form } = this.formRef.props
		form.validateFields((err, values) => {
			if (err) {
				return
			}
			console.log(values)
			const {
				areaType,
				countryCode,
				name,
				email,
				contactNo,
				scope,
				typology,
				area,
				clientNotes,
				location,
				googlePlacesId,
				teamHead,
			} = values

			mutate({
				variables: {
					name,
					emailId: email,
					contactNo:
						countryCode
							.split('(')
							.pop()
							.replace(')', '') + contactNo,
					location,
					googlePlacesId,
					scope: scope.toString(),
					typology,
					area,
					areaType,
					teamHead,
					clientNotes,
				},
			})

			form.resetFields()
			this.setState({ visible: false })
		})
	}

	saveFormRef = (formRef) => {
		this.formRef = formRef
	}

	render() {
		const { visible } = this.state
		return (
			<div style={{ float: 'right', marginRight: 20, marginTop: -26 }}>
				<Button type="dashed" onClick={this.showModal}>
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#1890ff' }}>
						<GoPlus style={{ fontSize: 16, marginBottom: -3, marginRight: 6 }} />
						Add new enquiry
					</Text>
				</Button>
				<Mutation mutation={CREATE_MANUAL_ENQUIRY_MUTATION}>
					{(InsertManualEnquiry, { loading, error, data }) => (
						<>
							<ManualEnquiryForm
								wrappedComponentRef={this.saveFormRef}
								visible={visible}
								onCancel={this.handleCancel}
								onCreate={() => this.handleCreate(InsertManualEnquiry)}
							/>
							{loading && console.log(loading)}
							{data &&
								notification.success({
									message: `New Enquiry Created`,
									description:
										'Successfully ! Go to Opportunities Section to view the enquiry',
									style: { marginTop: 50 },
								})}
						</>
					)}
				</Mutation>
			</div>
		)
	}
}

export default ManualEnquiry
