import { Col, Input, Row, Select } from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const { Option } = Select
const { TextArea } = Input

export default function MoveToTrashComponent(props) {
	const [trashReason, setTrashReason] = useState(undefined)

	async function trashSelectProcess(value) {
		props.okButtonSet(false)
		setTrashReason(value)
	}

	return (
		<>
			<Row>
				<Col span={24}>
					<Select
						style={{ width: '-webkit-fill-available', marginBottom: 10 }}
						placeholder="Select a reason . . ."
						onChange={trashSelectProcess}
						value={trashReason}>
						<Option value="fake_enquiries">Trash / Fake Enquiry </Option>
						<Option value="not_feasible">Trash / Not Feasible </Option>
						<Option value="not_responsive">Trash / Not Responsive </Option>
						<Option value="other_reasons">Trash / Other Reasons </Option>
					</Select>

					<TextArea
						rows={2}
						autoSize={{ minRows: 2, maxRows: 3 }}
						placeholder="Enter your notes here . . ."
					/>
				</Col>
			</Row>
		</>
	)
}

// Defined PropTypes
// For easy typechecking and prop handling amongst parent and child components
MoveToTrashComponent.propTypes = {
	okButtonSet: PropTypes.func,
}

MoveToTrashComponent.defaultProps = {
	okButtonSet: PropTypes.func,
}
