/**
  Closed client tickets Component
  This component will render display closed client tickets 
* */

/*  Import Components  */
import React from 'react'
// Import required components from ANT DESIGN
import { Spin, Icon, Typography, Modal } from 'antd'

// Import required components from FontAwesome
import { FaRegCommentDots } from 'react-icons/fa'

// Query component of React Apollo to fetch data
import { Query } from 'react-apollo'
// Import Override CSS
import './index.css'
// Query to fetch closed client ticket Details
import { CLIENT_DISCUSSION } from '../query'

// Import Custom Styles
import styles from './styles'
// Import Antd CSS
import 'antd/dist/antd.css'

// Import thumbsup image
import thumbsUp from '../../../assets/thumbs_up.png'

// import utils file
import util from '../../../utils'

import ModalContentDisplay from '../modalContentDisplay'

// deconstruct Text from Typography
const { Text,Paragraph } = Typography

/**
 * This class component is used to display closed client ticket images in webapp.
 * @returns {closed client ticket component}
 */
class ClosedClientTickets extends React.Component {
	// initial states
	state = {
		ismodalopen: false, // modal visibility
		indexSet: 0, // index value state it used for find index for modal content
		selection: false,
		loadCount: 1,
	}

	/**
	 * get current selected image among all images
	 * and set it in a state
	 */
	setSelected = (index, element, flatternArray) => {
		const imageIndex = flatternArray.findIndex(
			(i) => i.commentId === element.commentId && i.key === element.key
		)
		this.setState({
			indexSet: imageIndex,
			ismodalopen: true,
			selection: true,
		})
	}

	/**
	 *  Sets the visiblity of modal to false when called
	 */
	handleCancel = () => {
		this.setState({
			ismodalopen: false,
			selection: false,
		})
	}

	/**
	 * It should be excecute first
	 * This is used for display closed client tickets photos.
	 * @returns {closed client ticket page}
	 */
	/**
	 * This function is used to display a closed client tickets photos.
	 * It contain card profile name, photo comment and Action event like comment.
	 * @returns {closed client ticket photo}
	 */

	render() {
		// Deconstructing the state
		const { ismodalopen, indexSet, selection, loadCount } = this.state
		// Deconstructing the props
		const { projectUrl } = this.props

		return (
			<Query
				query={CLIENT_DISCUSSION}
				variables={{
					projectUrl,
					status: 'resolved',
					limit: 3,
					offset: 0,
					spaceId: 'All',
				}}>
				{({
					data: closedClientTicketData,
					loading: closedClientTicketLoading,
					error: closedClientTicketError,
					fetchMore,
					refetch,
				}) => {
					if (closedClientTicketLoading) {
						// loading -> it handles a loading data status
						return (
							<Spin
								indicator={<Icon type="loading" style={styles.loadingIcon} spin />}
								style={styles.loadIconStyle}
							/>
						)
					}
					if (
						!closedClientTicketData ||
						!closedClientTicketData.ClientDiscussion ||
						closedClientTicketError
					) {
						return <span>Some error </span>
					}
					// flattern array -> Array combine for projectphotos
					const flatternArray = util.flatten(
						closedClientTicketData.ClientDiscussion.discussions
					)

					// console.log(flatternArray)

					// console.log(closedClientTicketData.ClientDiscussion)
					// console.log(closedClientTicketData.ClientDiscussion.discussions.length)
          // console.log(closedClientTicketData.ClientDiscussion.discussionCount)
					return (
						<div style={styles.closedClientTicket}>
							<div style={styles.closedClientTicketCount}>
								Closed Client Tickets (
								{closedClientTicketData.ClientDiscussion.discussionCount})
							</div>
							<div>
								{/* closed client tickets photos data looping start */}
								{closedClientTicketData.ClientDiscussion.discussions.map(
									(closedClientData, ticketIndex) => {
										const names =
											closedClientData &&
											closedClientData.childCommentsLatest.profile &&
											closedClientData.childCommentsLatest.profile.name
												? closedClientData.childCommentsLatest.profile.name
												: ''
										const name = names.split(' ')
										return (
											<div
												style={styles.closedClient}
												key={ticketIndex}
												className="closedClientHover"
												onClick={() =>
													this.setSelected(ticketIndex, closedClientData, flatternArray)
												}>
												{/* image part */}
												<div style={styles.imagePart}>
													{/* poster name display */}
													<div style={styles.posterName}>
														{util.getInitials(closedClientData.uploadedProfile.name)}
													</div>
													{/* image display */}
													<img
														style={styles.Images}
														src={
															closedClientData.thumbnailUri ||
															closedClientData.uri ||
															closedClientData.qualityUri
														}
														alt="Photos"
													/>
													{/* icon display */}
													{closedClientData.childCommentCount > 0 ? (
														<span style={styles.commentIconWithoutBackgroud}>
															<FaRegCommentDots />
														</span>
													) : null}
												</div>
												{/* content part */}
												<div style={styles.contentPart}>
													<div style={styles.parentComment}>
														<div style={styles.profile}>
															<Text style={styles.profileName}>
																{closedClientData.commentedProfile.name}
															</Text>
															<Text style={styles.profileTime}>
																{util.formatRelativetimestamp(
																	closedClientData.commentedAt
																)}
															</Text>
														</div>
														<div style={styles.ProfileText}>
															{closedClientData.commentText}
														</div>
													</div>
													{closedClientData.childCommentCount > 0 ? (
														<div style={styles.childComment}>
															<div style={styles.childProfile}>
																
																{closedClientData.childCommentsLatest.latestComment ===
																'ðŸ‘' ? (
                                  <>
																	<Text style={styles.childProfileName}>
																		{`${name[0]} : `}
																	</Text>

																	<img
																		alt="client Chat"
																		style={styles.childProfileText}
																		src={thumbsUp}
																		width="20"
																		height=""
																	/>
                                  </>
																) : (
																	<Paragraph ellipsis style={styles.childProfileText}>
                                    {`${name[0]}: ${closedClientData.childCommentsLatest.latestComment}`}
																		
																	</Paragraph>
																)}
																{/* <Text style={styles.childProfileTime}>
																	{util.formatRelativetimestamp(
																		closedClientData.childCommentsLatest.commentedAt
																	)}
																</Text> */}
															</div>
														</div>
													) : null}
												</div>
											</div>
										)
									}
								)}
							</div>
							{closedClientTicketData.ClientDiscussion.discussionCount ===
							closedClientTicketData.ClientDiscussion.discussions.length ? (
								<div style={styles.noTicketShow}>No more tickets to show :)</div>
							) : (
								<div
									style={styles.ticketShow}
									onClick={() =>
										fetchMore({
											variables: {
												offset:
													closedClientTicketData.ClientDiscussion.discussions.length,
											},
											updateQuery: (prev, { fetchMoreResult }) => {
												if (!fetchMoreResult) return prev
												this.setState({ loadCount: loadCount + 1 })
												return {
													...prev,
													ClientDiscussion: {
														...prev.ClientDiscussion,
														discussions: [
															...prev.ClientDiscussion.discussions,
															...fetchMoreResult.ClientDiscussion.discussions,
														],
													},
												}
											},
										})
									}>
									Show more
								</div>
							)}

							{/* Image modal display */}
							<Modal
								title={null}
								header={null}
								visible={ismodalopen}
								footer={null}
								onCancel={this.handleCancel}
								wrapClassName="antmodalclass"
								style={{ overflowY: 'hidden !important' }}>
								{ismodalopen ? (
									<div style={styles.fullModal}>
										<ModalContentDisplay
											indexSet={indexSet}
											status="resolved"
											loadCount={loadCount}
											userHashState={closedClientTicketData.ClientDiscussion.loggedInUser}
											flatternArray={flatternArray}
											projectUrl={projectUrl}
											handleCancel={this.handleCancel}
											selection={selection}
										/>
									</div>
								) : null}
							</Modal>
						</div>
					)
				}}
			</Query>
		)
	}
}

export default ClosedClientTickets
